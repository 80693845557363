/** @format */

import React, { createContext, useState, useEffect } from 'react';
import { Container, FormControl, TextField, Typography, InputAdornment, Stack, IconButton, Alert, Button, createTheme, ThemeProvider } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies, withCookies } from 'react-cookie';

import CONFIG from '../config/config.js';
import Logo from '../assets/icons/primary/Circle-Logo-Big.svg';
import LoginIcon from '../../src/assets/icons/primary/continue.svg';
import LoginHovIcon from '../../src/assets/icons/primary/WithHovIcon/continue-hov.svg';

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});

export const UserIdContext = createContext(null);

export const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCookie] = useCookies(['token']);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');

  const [isImageHovered, setIsImageHovered] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [environment, setEnvironment] = useState('');

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    switch (window.location.hostname) {
      case 'sales.theblindman.com':
        setEnvironment('PRODUCTION');
        document.title = 'The BlindMan';
        break;
      case 'qa.theblindman.com':
      case 'stg.theblindman.com':
        setEnvironment('STAGING');
        document.title = 'STAGING';
        break;
      default:
        setEnvironment('DEVELOPMENT');
        document.title = 'DEVELOPMENT';
        break;
    }
  }, []);

  const handleShowPassword = () => setShowPassword((prev) => !prev);

  const isValidLogin = () => {
    const emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    const passwordTester = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,20}$/;

    const username = document.getElementById('username');
    const password = document.getElementById('password');

    setEmailError(!username.value || !emailRegex.test(username.value) ? 'Please enter a valid email address.' : '');
    setPasswordError(!password.value || !passwordTester.test(password.value) ? 'Please enter a valid password.' : '');
    const isValid = username.value && emailRegex.test(username.value) && password.value && passwordTester.test(password.value);
    setDisabled(!isValid);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidLogin()) {
      return;
    }

    const formData = new FormData(e.currentTarget);
    const options = {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: formData.get('username'),
        password: formData.get('password'),
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/am/login`, options);
    const { error, ...data } = await response.json();
    if (!response.ok || error) {
      setMessage(error.message);
      return;
    }
    const { session, token, user } = data;
    sessionStorage.clear();
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user_id', user.user_id);
    sessionStorage.setItem('user', `${user.first_name} ${user.last_name}`);
    sessionStorage.setItem('userEmail', user.email);
    sessionStorage.setItem('hasAdobeToken', user.adobe_token || '');

    const expires = new Date(session.cookie.expires);
    setCookie('token', token, { path: '/', expires });

    const prevpathname = sessionStorage.getItem('pathname');
    if (!prevpathname) {
      return navigate(`/tbm/ps/dashboard`);
    }
    const id = prevpathname.substring(prevpathname.lastIndexOf('/') + 1);
    if (id === ':id') {
      navigate(`/tbm/ps/dashboard`);
    } else {
      navigate(prevpathname);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container align='center' maxWidth='sm'>
        <Typography marginTop={15} variant='h1' gutterBottom>
          SALES
        </Typography>
        <img src={Logo} className='image' alt='The BlindMan Logo' />
        {environment !== 'PRODUCTION' ? <h3 align='center'>TBM {environment} Environment</h3> : null}
      </Container>
      <Container component='form' padding={2} noValidate onSubmit={handleSubmit} align='center' maxWidth='sm' sx={{ padding: '2.5em' }}>
        {message ? <Alert severity='error'>{message}</Alert> : null}
        {location.state ? <Alert severity='error'>{location.state.error}</Alert> : null}
        <FormControl margin='dense' fullWidth>
          <TextField label='Username' error={!!emailError} helperText={emailError} id='username' type='email' name='username' autoComplete='email' autoFocus required fullWidth variant='outlined' color={emailError ? 'error' : 'primary'} />
        </FormControl>
        <FormControl margin='dense' fullWidth>
          <TextField
            label='Password'
            error={!!passwordError}
            helperText={passwordError}
            id='password'
            type={showPassword ? 'text' : 'password'}
            name='password'
            autoComplete='current-password'
            autoFocus
            required
            fullWidth
            variant='outlined'
            color={passwordError ? 'error' : 'primary'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' onClick={handleShowPassword} edge='end'>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Stack spacing={2} direction='row' justifyContent='space-between' className='margin_top'>
          <Button
            type='submit'
            onClick={isValidLogin}
            sx={{
              fontFamily: 'RobotoLight',
              fontSize: '1.25rem',
              color: '#008593',
              ':disabled': {
                color: 'rgba(0, 133, 147, .26)',
              },
            }}
            size='large'
            startIcon={<img src={LoginIcon} alt='LoginIcon' className='primary_icons_dimension_1' />}
          >
            Log In
          </Button>
          {/* <Button
            onClick={() => navigate('/forgot-password')}
            sx={{
              fontFamily: 'RobotoLight',
              fontSize: '1.5rem',
              color: '#008593',
            }}
            size='large'
          >
            Forgot Password
          </Button> */}
        </Stack>
      </Container>
    </ThemeProvider>
  );
};

export default withCookies(LoginPage);
