/** @format */

import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import Save from '../../img/save.png';
import CONFIG from '../../config/config.js';
import CancelIcon from '../../img/cancel.png';

export default function EditCustomerName(props) {
  const [token, setToken] = useState(props.token);
  const [customerFullName, setCustomerFullName] = useState(props.customerName);
  const [customerId, setCustomerId] = useState(props.customerId);
  const [isDrawerClose, setIsDrawerClose] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');

  const EditCustomerNameFun = (e) => {
    setCustomerFullName(e.target.value);
  };

  const closeDrawer = () => {
    setIsDrawerClose(false);
  };

  useEffect(() => {
    {
      window.scrollTo(0, 0);
    }
  });

  props.func(customerFullName, isDrawerClose, successMessage);

  const editCustomerNameFun = async () => {
    await fetch(`${CONFIG.API_URL}/cm/UpdateCustomerName`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        customer_id: customerId,
        full_name: customerFullName,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setIsDrawerClose(false);
          setSuccessMessage(data.message);
        } else if (data.error === true) {
          setSuccessMessage(data.message);
          setIsDrawerClose(false);
        } else {
          setSuccessMessage('');
          setIsDrawerClose(false);
        }
      });
  };

  return (
    <>
      <Box p={4} pt={0}>
        <div className='drawer_backbutton'>
          <IconButton>
            <ArrowCircleRightIcon onClick={closeDrawer} sx={{ color: 'white' }} fontSize='large' />
          </IconButton>
        </div>
        <br />
        <Stack direction='column' spacing={2}>
          <TextField className='drawer_textfieldcolor' value={customerFullName} onChange={EditCustomerNameFun} />

          {customerFullName ? (
            <>
              <Button variant='contained' onClick={editCustomerNameFun} className='edit_drawer_button'>
                <img src={Save} className='drawer_iconsize' alt='Save' />
                SAVE
              </Button>{' '}
            </>
          ) : (
            <Button variant='contained' disabled className='edit_drawer_button'>
              <img src={Save} className='drawer_iconsize' alt='Save' />
              SAVE
            </Button>
          )}

          <Button onClick={closeDrawer} variant='contained' className='edit_drawer_button'>
            <img src={CancelIcon} className='cancel_icon' alt='Cancel' />
            &nbsp;&nbsp;&nbsp;CANCEL
          </Button>
        </Stack>
      </Box>
    </>
  );
}
