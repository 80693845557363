/** @format */

import React, { forwardRef, useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import DrawerInput from './DrawerInput';
import Stack from '@mui/material/Stack';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import InputAdornment from '@mui/material/InputAdornment';
import { createTheme, ThemeProvider, Typography } from '@mui/material';
import CONFIG from '../../config/config.js';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WindowHovIcon from '../../assets/icons/primary/WithHovIcon/window-add-hov.svg';
import WindowIconAdd from '../../assets/icons/primary/window-add.svg';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import { FormControlLabel } from '@mui/material';
import notesIconSecond from '../../assets/icons/secondary/2nd-note.svg';
import NotesDrawer from '../Proposals/NotesDrawer';
import fracty from 'fracty';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import CommonDrawerInputWidth from '../WindowsManagement/CommonDrawerInputWidth';
import DrawerInputWidth from '../WindowsManagement/DrawerInputWidth';
import ErrorIcon from '@mui/icons-material/Error';
import Switch from '../Shared/Switch';

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});

const theme1 = createTheme({
  palette: {
    border: {
      main: '1px solid #000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#FAC41E',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {},
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#008593 !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'white', // disable date
            color: '#E9E9E9',
          },
        },
        dayWithMargin: {
          backgroundColor: 'white', // not selected date
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {},
      },
    },
  },
});

// Style theme to make background blur and transparent
const useStyles = makeStyles({
  paper: {
    width: '90%',
    backdropFilter: 'blur(10px) !important',
    background: 'rgba(69, 157, 166, 0.73)  !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

const min = 1;
const max = 20;

const AddWindow = ({ location, checkbuttonOption, func, defaultValuesStatus, defaultOptionValues, proposal_id, defaultOptionValuesProposalLevel }) => {
  const inputRef = useRef(null);
  const inputArr = [
    {
      type: 'notes',
      id: 1,
      value: '',
    },
  ];
  const classes = useStyles();
  const navigate = useNavigate();

  const [warn, setWarn] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [qty, setQty] = useState('');
  const [mount, setMount] = useState('');
  const [mountsurface, setMountsurface] = useState('');
  const [ladder, setLadder] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [windowName, setWindowName] = useState('');
  const [size, setSize] = useState('');

  const [hasDefaults, setHasDefaults] = useState(false);
  const setDefaults = ({ target }) => setApplyDefault(target.checked);

  const [window_width, setSizeW] = useState();
  const [window_height, setSizeH] = useState();
  const [minqty, setMinqty] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [duplicateWN, setDuplicateWN] = useState(false);
  const [duplicateW, setDuplicateW] = useState(false);
  const [open, setOpen] = useState(false);
  const [WidthSnackBar, setWidthSnackBar] = useState(false);
  const [namewarn, setNameWarn] = useState(false);
  const [disablebutton, setDisableButton] = useState(false);
  const [specificDetails, setspecificDetails] = useState(false);

  const [existingCovering, setExistingcovering] = useState('');

  const [windowqty, setWindowQty] = useState('ADD WINDOW');
  const [applyDefaultOptions, setApplyDefaultOptions] = useState(false);
  const [successmsg, setSuccessMsg] = useState(false);
  const [window_widthindecimal, setSizeWindecimal] = useState();
  const [window_heightindecimal, setSizeHindecimal] = useState();
  const [measurementType, setMeasurementType] = useState('');
  const [totalWidth, setTotalWidth] = useState('');
  const [buildType, setBuildType] = useState('');
  const [buildTypeReq, setbuildTypeReq] = useState(<span style={{ color: '#DB3131' }}>*</span>);
  const [wnstatus, setWNStatus] = useState('primary');
  const [windowdepth, setWindowDepth] = useState('');
  const [headrail, setHeadrail] = useState('');
  const [obstructions, setObstructions] = useState('');
  const [multiple, setMultiple] = useState('Single');
  const [rolldirection, setRollDirection] = useState('Standard');
  const [Typemeasurement, setTypeMeasurement] = useState([]);
  const [ExistingCoveringsData, setExistingCoveringsData] = useState([]);
  const [BuildTypeData, setBuildTypeData] = useState([]);
  const [MountSurfaceData, setMountSurfaceData] = useState([]);
  const [LadderData, setLadderData] = useState([]);
  const [WindowDepthData, setWindowDepthData] = useState([]);
  const [ObstructionsData, setObstructionsData] = useState([]);
  const [WindowSillData, setWindowSillData] = useState([]);
  const [VerticalConfigurationData, setVerticalConfigurationData] = useState([]);
  const [DeadMoldData, setDeadMoldData] = useState([]);
  const [BuildOutData, setBuildOutData] = useState([]);
  const [FillerStripData, setFillerStripData] = useState([]);
  const [SplitTiltData, setSplitTiltData] = useState([]);
  const [SplitRailData, setSplitRailData] = useState([]);
  const [TPostData, setTPostData] = useState([]);

  const [AdditionalSku, setAdditionalSku] = useState();
  let additional_sku = [];

  const [AddNote, setAddNote] = useState(inputArr);
  const [AddNoteforBuildOut, setAddNoteforBuildOut] = useState('');
  const [AddNoteforFillerStrip, setAddNoteforFillerStrip] = useState('');
  const [AddNoteforSplitTilt, setAddNoteforSplitTilt] = useState('');
  const [AddNoteforSplitRail, setAddNoteforSplitRail] = useState('');
  const [TypeHeadrail, setTypeHeadrail] = useState([]);
  const [TypeMultiple, setTypeMultiple] = useState([]);
  const [TypeRollDirection, setTypeRollDirection] = useState([]);

  const [ApplyDefault, setApplyDefault] = useState(false);
  const [Panel, setPanel] = useState('1');
  const [Windowsill, setWindowsill] = useState('');
  const [DeadMold, setDeadMold] = useState('None');
  const [Buildout, setBuildout] = useState('None');
  const [FillerStrip, setFillerStrip] = useState('None');
  const [SplitTilt, setSplitTilt] = useState('No');
  const [SplitRail, setSplitRail] = useState('No');
  const [TPost, setTPost] = useState('None');
  const [VerticalConfiguration, setVerticalConfiguration] = useState('');
  const [SectionOneTopShow, setSectionOneTopShow] = useState('');
  const [SectionTwoBottomShow, setSectionTwoBottomShow] = useState('');
  const [SectionOneTopHeigth, setSectionOneTopHeigth] = useState('');
  const [SectionTwoBottomWidth, setSectionTwoBottomWidth] = useState('');
  const [HeigthType, setHeigthType] = useState('');
  const [measurementTypeOpen, setMeasurementTypeOpen] = useState(false);
  const [existingcoveringOpen, setExistingcoveringOpen] = useState(false);
  const [WindowsillOpen, setWindowsillOpen] = useState(false);
  const [VerticalConfigurationOpen, setVerticalConfigurationOpen] = useState(false);
  const [ValanceOpen, setValanceOpen] = useState(false);
  const [HeadrailOpen, setHeadrailOpen] = useState(false);
  const [CutoutOpen, setCutoutOpen] = useState(false);
  const [BuildTypeOpen, setBuildTypeOpen] = useState(false);
  const [MountsurfaceOpen, setMountsurfaceOpen] = useState(false);
  const [LadderOpen, setLadderOpen] = useState(false);
  const [WidthType, setWidthType] = useState('');
  const [leftShadeisDrawerOpen, setLeftShadeisDrawerOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [warnQty, setwarnQty] = useState(false);
  const [othermeasurement, setOtherMeasurement] = useState('');
  const [description, setDescription] = useState('');
  const [isCommonDrawerOpen, setIsCommonDrawerOpen] = useState(false);

  // error handling states
  const [GetLookupTableDataErrorMsg, setGetLookupTableDataErrorMsg] = useState(false);
  const [GetLookupTableDataErrorMsgTxt, setGetLookupTableDataErrorMsgTxt] = useState(false);

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  const vertical = 'top';
  const horizontal = 'center';

  const [section1, setSection1] = useState('');
  const [section2, setSection2] = useState('');
  const [section3, setSection3] = useState('');
  const [section4, setSection4] = useState('');
  const [section5, setSection5] = useState('');
  const [section6, setSection6] = useState('');

  const isNumeric = (value) => !isNaN(value) && isFinite(value);

  const handleSectionValue = (value) => {
    return isNumeric(value) && value >= 0 ? fracty(value) + '"' : '';
  };

  // Process each section value
  const sec2_1 = handleSectionValue(section1);
  const sec2_2 = handleSectionValue(section2);
  const sec3_1 = handleSectionValue(section1);
  const sec3_2 = handleSectionValue(section2);
  const sec3_3 = handleSectionValue(section3);
  const sec4_1 = handleSectionValue(section1);
  const sec4_2 = handleSectionValue(section2);
  const sec4_3 = handleSectionValue(section3);
  const sec4_4 = handleSectionValue(section4);
  const sec5_1 = handleSectionValue(section1);
  const sec5_2 = handleSectionValue(section2);
  const sec5_3 = handleSectionValue(section3);
  const sec5_4 = handleSectionValue(section4);
  const sec5_5 = handleSectionValue(section5);
  const sec6_1 = handleSectionValue(section1);
  const sec6_2 = handleSectionValue(section2);
  const sec6_3 = handleSectionValue(section3);
  const sec6_4 = handleSectionValue(section4);
  const sec6_5 = handleSectionValue(section5);
  const sec6_6 = handleSectionValue(section6);

  const [ChangedSection1, setChangedSection1] = useState(false);
  const [ChangedSection2, setChangedSection2] = useState(false);
  const [ChangedSection3, setChangedSection3] = useState(false);
  const [ChangedSection4, setChangedSection4] = useState(false);
  const [ChangedSection5, setChangedSection5] = useState(false);
  const [ChangedSection6, setChangedSection6] = useState(false);

  let sectionValues = {
    section1: { value: section1, changed: ChangedSection1 },
    section2: { value: section2, changed: ChangedSection2 },
    section3: { value: section3, changed: ChangedSection3 },
    section4: { value: section4, changed: ChangedSection4 },
    section5: { value: section5, changed: ChangedSection5 },
    section6: { value: section6, changed: ChangedSection6 },
  };
  const trueSections = {}; // Initialize an empty JSON object

  // Get Token
  const token = sessionStorage.getItem('token');
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const [VerifyStatus, setVerifyStatus] = useState('false');
  const [MeasurementTypeAlert, setMeasurementTypeAlert] = useState(false);
  const [MeasurementTypeConfirm, setMeasurementTypeConfirm] = useState(false);
  const fetchdatafromCommonDrawer = (commondrawerstatus, result) => {
    setIsCommonDrawerOpen(commondrawerstatus);

    if (result === '') {
      setOtherMeasurement('');
    } else {
      setOtherMeasurement(result + '"');
    }
  };

  let StoreHeightWidth = {
    height: window_heightindecimal,
    width: window_widthindecimal,
  };

  const fetchdata = (data, drawerstatus, width, height, decimalwidth, decimalheight, result, cancelWidth, cancelHeight, CanclePassData, CancelGetDimesion, verifyStatus, BlankData, CloseResult, storeValue, onClickCancel, StoreHeightWidth) => {
    const W = width.replace(/"/g, '');

    setTotalWidth(W);
    if (BlankData !== 'blank') {
      const newValue222 = data.replace(/"/g, '');

      const newValue3333 = width.replace(/"/g, '');
      const newValue4444 = height.replace(/"/g, '');

      if (decimalwidth) {
        setSizeWindecimal(decimalwidth);
        setTotalWidth(decimalwidth);
      } else {
        setSizeWindecimal(newValue3333);
        setTotalWidth(parseFloat(newValue3333));
      }
      if (decimalheight) {
        setSizeHindecimal(decimalheight);
      } else {
        setSizeHindecimal(newValue4444);
      }
      setSizeH(height);

      setSize(data);
      setSizeW(newValue3333);

      // }
    } else {
      setSizeH(height);

      if (onClickCancel === true) {
        setSize(storeValue);
        if (StoreHeightWidth !== undefined) {
          setSizeWindecimal(StoreHeightWidth.width);
          setSizeHindecimal(StoreHeightWidth.height);
          setTotalWidth(parseFloat(StoreHeightWidth.width));
        }
      } else {
        setSize(CloseResult);
      }
      setSizeW(width);
    }

    setIsDrawerOpen(drawerstatus);
    if (verifyStatus === 'true') {
      setVerifyStatus('true');
    }
    if (headrail === '1 Section') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');
    }
    if (headrail === '2 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');
      // For 2 Sections, divide the TotalWidth by 2
      let TotalWidth = totalWidth;
      const dividedValue = TotalWidth / 2;
      setSection1(dividedValue);
      setSection2(dividedValue);
    }

    if (headrail === '3 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      if (TotalWidth) {
        const dividedValue = TotalWidth / 3;
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
      } else {
      }
    }
    if (headrail === '4 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 4;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }

    if (headrail === '5 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 5;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
        setSection5(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }

    if (headrail === '6 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 6;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
        setSection4(dividedValue);
        setSection5(dividedValue);
        setSection6(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }

    if (VerticalConfiguration === '2 Sections') {
      const distributedValue = parseFloat(window_heightindecimal) / 2;

      setSectionOneTopShow(fracty(distributedValue) + '"');
      setSectionTwoBottomShow(fracty(distributedValue) + '"');
      setSectionOneTopHeigth(distributedValue);
      setSectionTwoBottomWidth(distributedValue);
    }
  };

  function checkForFractionValue(str) {
    const regex = /\d+\s*x\s*\d+/;

    return regex.test(str);
  }

  let newValue_1;
  if (size !== undefined) {
    newValue_1 = size.replace(/"/g, '');
  }

  const containsFraction = checkForFractionValue(newValue_1);

  const [conditionExecuted, setConditionExecuted] = useState(false);

  if (!conditionExecuted && VerifyStatus === 'true' && measurementType === 'Quick') {
    if (containsFraction === true) {
      setMeasurementTypeAlert(!MeasurementTypeAlert);

      if (MeasurementTypeConfirm === true) {
        setSize(`${Math.round(window_widthindecimal)} x ${Math.round(window_heightindecimal)}`);
        setSizeHindecimal(Math.round(window_heightindecimal));
        setSizeWindecimal(Math.round(window_widthindecimal));
      }
    }

    setConditionExecuted(true);
    setMeasurementTypeConfirm(false);
  }

  const fetchdata2 = (data, drawerstatus, width, decimalwidth, StoreHeightWidth, decimalheight, result, cancelWidth, cancelHeight, CanclePassData, storedSection, onClickSectionCancel, sectionPosition) => {
    if (WidthType === 'LeftWidth') {
    }

    // To Manually Enter the Values in Window Config. Sections
    else if (WidthType === 'Section 1') {
      // Add your logic for "Section 1" here
      setSection1(decimalwidth);
      if (!sectionValues.section1.changed) {
        setSection1(decimalwidth);
        sectionValues.section1.value = decimalwidth;

        setChangedSection1(true);
      } else {
      }

      // setLeftShadeisDrawerOpen(drawerstatus);
    } else if (WidthType === 'Section 2') {
      // Add your logic for "2Section" here
      setSection2(decimalwidth);
      if (!sectionValues.section2.changed) {
        setSection2(decimalwidth);
        sectionValues.section2.value = decimalwidth;

        setChangedSection2(true);
      } else {
      }
    } else if (WidthType === 'Section 3') {
      // Add your logic for "3Section" here
      setSection3(decimalwidth);
      if (!sectionValues.section3.changed) {
        setSection3(decimalwidth);
        sectionValues.section3.value = decimalwidth;
        setChangedSection3(true);
      } else {
      }
    } else if (WidthType === 'Section 4') {
      // Add your logic for "4Section" here
      setSection4(decimalwidth);
      if (!sectionValues.section4.changed) {
        setSection4(decimalwidth);
        sectionValues.section4.value = decimalwidth;
        setChangedSection4(true);
      } else {
      }
    } else if (WidthType === 'Section 5') {
      // Add your logic for "5Section" here
      setSection5(decimalwidth);
      if (!sectionValues.section5.changed) {
        setSection5(decimalwidth);
        sectionValues.section5.value = decimalwidth;
        setChangedSection5(true);
      } else {
      }
    } else {
    }

    // Create an array to store section values
    const sections = [section1, section2, section3, section4, section5, section6];

    let totalOfTrueSections = 0; //Sum of the true sections

    // JSON to store the values of sections that are marked as 'true'

    // Loop through sectionValues and determine true sections
    for (let i = 1; i <= 6; i++) {
      const sectionKey = `section${i}`;
      if (sectionValues[sectionKey].changed) {
        totalOfTrueSections += parseFloat(sections[i - 1]);
        trueSections[`section${i}`] = sectionValues[sectionKey].value;
      }
    }

    // Calculate the total value that needs to be distributed among other sections
    const totalToDistribute = totalWidth - totalOfTrueSections;
    //Value to be distributed

    let numberOfSections;

    if (headrail === '6 Sections') {
      numberOfSections = 6;
    } else if (headrail === '5 Sections') {
      numberOfSections = 5;
    } else if (headrail === '4 Sections') {
      numberOfSections = 4;
    } else if (headrail === '3 Sections') {
      numberOfSections = 3;
    } else if (headrail === '2 Sections') {
      numberOfSections = 2;
    } else {
    }

    // Calculate the value to distribute to each section
    const remainingSections = numberOfSections - Object.keys(trueSections).length;

    const distributedValue = totalToDistribute / remainingSections;

    for (let i = 1; i <= 6; i++) {
      const sectionKey = `section${i}`;
      if (!sectionValues[sectionKey].changed) {
        if (i > numberOfSections) {
          sectionValues[sectionKey].value = '';
        } else {
          sectionValues[sectionKey].value = distributedValue;
        }
      }
    }

    setLeftShadeisDrawerOpen(drawerstatus);

    if (onClickSectionCancel === true) {
      if (sectionPosition === 'Section 1') {
        setSection1(storedSection);
      } else if (sectionPosition === 'Section 2') {
        setSection2(storedSection);
      } else if (sectionPosition === 'Section 3') {
        setSection3(storedSection);
      } else if (sectionPosition === 'Section 4') {
        setSection4(storedSection);
      } else if (sectionPosition === 'Section 5') {
        setSection5(storedSection);
      }

      // Set all the "changed" values to false in sectionValues
      for (const sectionKey in sectionValues) {
        if (sectionValues.hasOwnProperty(sectionKey)) {
          sectionValues[sectionKey].changed = false;
        }
      }
    } else {
    }
  };

  useEffect(() => {
    if (headrail === '2 Sections') {
      if (section1 > totalWidth) {
        setwarnQty(true);
        setMsg('Section 1 is greater than ' + fracty(totalWidth));
      } else if (totalWidth === '') {
      } else if (section1 === totalWidth) {
        setwarnQty(true);
        setMsg('Section 1 is equal to ' + fracty(totalWidth));
      } else {
      }
    } else if (headrail === '3 Sections') {
      // Check if section1 and section2 are not blank and are valid numbers
      if (!isNaN(section1) && !isNaN(section2)) {
        const section1Value = parseFloat(section1);
        const section2Value = parseFloat(section2);

        // Check if totalWidth is a valid number
        if (!isNaN(totalWidth)) {
          const sum = section1Value + section2Value;

          if (section1Value === 0 || isNaN(section1Value)) {
          } else if (totalWidth === '') {
          } else if (section1Value >= totalWidth) {
            setSection2('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 1 is greater than or equal to ' + fracty(totalWidth));
          } else if (section2Value >= totalWidth) {
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (sum >= totalWidth) {
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1 and 2 is greater than or equal to ' + fracty(totalWidth));
          } else {
            // Calculate section3 outside of the "else if" block
            const section3Value = totalWidth - sum;
            if (section3Value > 0) {
              setSection3(section3Value);
              // Clear any previous alert message
              setwarnQty(false);
              setMsg('');
            } else {
              setSection3('');
            }
          }
        } else {
          console.error('totalWidth is not a valid number.');
          setSection3('');
        }
      } else {
        // Handle the case where section1 or section2 is not a valid number
        console.error('Sections 1 and 2 must be valid numbers.');
      }

      // Clear section4, section5, and section6
      setSection4('');
      setSection5('');
      setSection6('');
    } else if (headrail === '4 Sections') {
      // Check if section1, section2, and section3 are not blank and are valid numbers
      if (!isNaN(section1) && !isNaN(section2) && !isNaN(section3)) {
        const section1Value = parseFloat(section1);
        const section2Value = parseFloat(section2);
        const section3Value = parseFloat(section3);

        // Check if totalWidth is a valid number
        if (!isNaN(totalWidth)) {
          const sum = section1Value + section2Value + section3Value;

          if (section1Value === 0 || isNaN(section1Value)) {
          } else if (totalWidth === '') {
          } else if (section1Value >= totalWidth) {
            setSection2('');
            setSection3('');
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 1 is greater than or equal to ' + fracty(totalWidth));
          } else if (section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section3Value >= totalWidth) {
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (sum >= totalWidth) {
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2 and 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1 and 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value >= totalWidth) {
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2 and 3 is greater than or equal to ' + fracty(totalWidth));
          } else {
            // Calculate section4 outside of the "else if" block
            const section4Value = totalWidth - sum;
            if (section4Value > 0) {
              setSection4(section4Value);
              // Clear any previous alert message
              setwarnQty(false);
              setMsg('');
            } else {
              setSection4('');
            }
          }
        } else {
          console.error('totalWidth is not a valid number.');
          setSection4('');
        }
      } else {
        // Handle the case where any of section1, section2, or section3 is not a valid number
        console.error('Sections 1, 2 and 3 must be valid numbers.');
      }

      // Clear section5 and section6
      setSection5('');
      setSection6('');
    }
    // Case for 5 Sections
    else if (headrail === '5 Sections') {
      if (!isNaN(section1) && !isNaN(section2) && !isNaN(section3) && !isNaN(section4)) {
        const section1Value = parseFloat(section1);
        const section2Value = parseFloat(section2);
        const section3Value = parseFloat(section3);
        const section4Value = parseFloat(section4);
        // Check if totalWidth is a valid number
        if (!isNaN(totalWidth)) {
          const sum = section1Value + section2Value + section3Value + section4Value; // Include section4Value in the sum

          if (section1Value === 0 || isNaN(section1Value)) {
          } else if (totalWidth === '') {
          } else if (section1Value >= totalWidth) {
            setSection2('');
            setSection3('');
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 1 is greater than or equal to ' + fracty(totalWidth));
          } else if (section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section3Value >= totalWidth) {
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section4Value >= totalWidth) {
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 4 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1 and 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value >= totalWidth) {
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2 and 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value + section4Value >= totalWidth) {
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2, 3, 4 and 5 is greater than or equal to ' + fracty(totalWidth));
          } else if (sum >= totalWidth) {
            setSection5('');
          } else {
            // Calculate section5 outside of the "else if" block
            const section5Value = totalWidth - sum;
            if (section5Value >= 0) {
              setSection5(section5Value);
            } else {
              setSection5('');
            }
          }
        } else {
          console.error('totalWidth is not a valid number.');
          setSection5('');
        }
      } else {
        // Handle the case where any of section is blank
        console.error('Sections 1, 2, 3 and 4 must not be blank.');
      }
      setSection6('');
    }
    // Case for 6 Sections
    else if (headrail === '6 Sections') {
      if (!isNaN(section1) && !isNaN(section2) && !isNaN(section3) && !isNaN(section4) && !isNaN(section5)) {
        const section1Value = parseFloat(section1);
        const section2Value = parseFloat(section2);
        const section3Value = parseFloat(section3);
        const section4Value = parseFloat(section4);
        const section5Value = parseFloat(section5);
        const totalWidthValue = parseFloat(totalWidth);

        if (!isNaN(totalWidthValue)) {
          const sum = section1Value + section2Value + section3Value + section4Value + section5Value;

          if (section1Value === 0 || isNaN(section1Value)) {
          } else if (totalWidth === '') {
          } else if (section1Value >= totalWidthValue) {
            setSection2('');
            setSection3('');
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 1 is greater than or equal to ' + fracty(totalWidth));
          } else if (section2Value >= totalWidthValue) {
            setSection3('');
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section3Value >= totalWidthValue) {
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section4Value >= totalWidthValue) {
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 4 is greater than or equal to ' + fracty(totalWidth));
          } else if (section5Value >= totalWidthValue) {
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 5 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1 and 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value >= totalWidth) {
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2 and 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value + section4Value >= totalWidth) {
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2, 3, 4 and 5 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value + section4Value + section5Value >= totalWidth) {
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2, 3, 4, 5 and 6 is greater than or equal to ' + fracty(totalWidth));
          } else if (sum >= totalWidthValue) {
            setSection6('');
          } else {
            const section6Value = totalWidthValue - sum;
            if (section6Value >= 0) {
              setSection6(section6Value);
            } else {
              setSection6('');
            }
          }
        } else {
          console.error('totalWidth is not a valid number.');
          setSection6('');
        }
      } else {
        console.error('Sections 1, 2, 3, 4 and 5 must not be blank, and they must be valid numbers.');
        setSection6('');
      }
    } else {
    }
  }, [section1, section2, section3, section4, section5, section6, totalWidth]);

  useEffect(() => {
    for (let [key, value] of Object.entries(sectionValues)) {
      if (key === 'section1') {
        setSection1(value.value);
      }
      if (key === 'section2') {
        setSection2(value.value);
      }
      if (key === 'section3') {
        setSection3(value.value);
      }
      if (key === 'section4') {
        setSection4(value.value);
      }
      if (key === 'section5') {
        setSection5(value.value);
      }
      if (key === 'section6') {
        setSection6(value.value);
      }
    }
  }, [sectionValues, totalWidth]);

  useEffect(() => {
    inputRef.current.focus();
    if (defaultValuesStatus) {
      if (measurementType !== 'Roller Shades' && defaultOptionValues.build_type === 'Fabric') {
        setBuildType('');
      } else {
        setBuildType(defaultOptionValues.build_type);
      }
    }
  }, [measurementType]);

  useEffect(() => {
    setMinqty(min);

    if (defaultValuesStatus) {
      setHasDefaults(true);
      setMeasurementType(defaultOptionValues.measurement_type);
      setBuildType(defaultOptionValues.build_type);
      setExistingcovering(defaultOptionValues.existing_covering);
      setMountsurface(defaultOptionValues.mount_surface);
      setLadder(defaultOptionValues.ladder);
      setWindowDepth(defaultOptionValues.window_depth);
      setOtherMeasurement(defaultOptionValues.window_depth_measurement + '"');
      setHeadrail(defaultOptionValues.window_configuration);
      setObstructions(defaultOptionValues.obstructions);
      setWindowsill(defaultOptionValues.window_sill);
      setVerticalConfiguration(defaultOptionValues.vertical_configuration);
    }
    if (defaultOptionValuesProposalLevel) {
      setHasDefaults(true);
      setMeasurementType(defaultOptionValuesProposalLevel.measurement_type);
      setBuildType(defaultOptionValuesProposalLevel.build_type);
      setExistingcovering(defaultOptionValuesProposalLevel.existing_covering);
      setMountsurface(defaultOptionValuesProposalLevel.mount_surface);
      setLadder(defaultOptionValuesProposalLevel.ladder);
      setWindowDepth(defaultOptionValuesProposalLevel.window_depth);
      setOtherMeasurement(defaultOptionValuesProposalLevel.window_depth_measurement + '"');
      setHeadrail(defaultOptionValuesProposalLevel.window_configuration);
      setObstructions(defaultOptionValuesProposalLevel.obstructions);
      setWindowsill(defaultOptionValuesProposalLevel.window_sill);
      setVerticalConfiguration(defaultOptionValuesProposalLevel.vertical_configuration);
    }
  }, [defaultValuesStatus, defaultOptionValuesProposalLevel]);

  useEffect(() => {
    getVerified();
    getSaleAppDropDowns();
  }, []);

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const getSaleAppDropDowns = async () => {
    const response = await fetch(`${CONFIG.API_URL}/wm/GetLookupTableData`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      token: token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();

    if (!response.ok || data.error) {
      setGetLookupTableDataErrorMsgTxt(data.error_message);
      setGetLookupTableDataErrorMsg(true);
    }
    setTypeMeasurement(data.MeasurementType);
    setExistingCoveringsData(data.ExistingCovering);
    setBuildTypeData(data.BuildType);
    setMountSurfaceData(data.MountSurface);
    setLadderData(data.Ladder);
    setWindowDepthData(data.WindowDepth);
    setObstructionsData(data.Obstructions);
    setTypeHeadrail(data.WindowConfiguration);
    setWindowSillData(data.Windowsill);
    setVerticalConfigurationData(data.VerticalConfiguration);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessMsg(false);
  };
  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNameWarn(false);
  };
  const handleValanceSelection = (e) => {
    if (e.target.value === '') {
      setspecificDetails(true);
    } else {
      setspecificDetails();
    }
    if (e.target.value === 'None') {
      setAddNote('');
      setspecificDetails(false);
    }
    setWindowDepth(e.target.value);
  };

  const [currentValue, setCurrentValue] = useState('');
  const [previousValue, setPreviousValue] = useState('');

  const handleMeasurementType = ({ target }) => {
    setMeasurementType(target.value);
    if (target.value === 'Quick') {
      setbuildTypeReq('');
    } else {
      setbuildTypeReq(<span style={{ color: '#DB3131' }}>*</span>);
    }
    setPreviousValue(currentValue);
    setCurrentValue(target.value);
    if (currentValue === 'Quick') {
      setVerifyStatus('true');
      setConditionExecuted(false);
    }

    // Blinds , Soft Shades , Roller Shades
    if (windowdepth !== '') {
      WindowDepthData.forEach((v) => {
        if (v.service_name === windowdepth) {
          setWindowDepthSku(v.sku);
        }
      });
    } else {
      setWindowDepthSku('');
    }
    if (headrail !== '') {
      TypeHeadrail.forEach((h) => {
        if (h.service_name === headrail) {
          setHeadrailSku(h.sku);
        }
      });
    } else {
      setHeadrailSku('');
    }
    if (obstructions !== '') {
      ObstructionsData.forEach((c) => {
        if (c.service_name === obstructions) {
          setObstructionsSku(c.sku);
        }
      });
    } else {
      setObstructionsSku('');
    }
    if (multiple !== '') {
      TypeMultiple.forEach((m) => {
        if (m.service_name === multiple) {
          setMultipleSKU(m.sku);
        }
      });
    } else {
      setMultipleSKU('');
    }
    if (rolldirection !== '') {
      TypeRollDirection.forEach((r) => {
        if (r.service_name === rolldirection) {
          setRollDirectionSKU(r.sku);
        }
      });
    } else {
      setRollDirectionSKU('');
    }

    // shutters

    if (VerticalConfiguration !== '') {
      VerticalConfigurationData.forEach((v) => {
        if (v.service_name === VerticalConfiguration) {
          setVerticalConfigurationSku(v.sku);
        }
      });
    } else {
      setVerticalConfigurationSku('');
    }
  };

  const [checkQuantity, setCheckQuantity] = useState(1);

  const CheckNameDuplicacywithQuantity = (qty) => {
    if (qty <= 1) {
      setWindowQty('ADD WINDOW');
    } else {
      setWindowQty('ADD WINDOWS');
    }

    setCheckQuantity(qty);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', token: token },
      body: JSON.stringify({
        address_id: location.address_id,
        customer_id: parseInt(location.customer_id),
        window_name: windowName,
        quantity: qty,
      }),
    };

    fetch(`${CONFIG.API_URL}/cm/CheckDuplicateWindow`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (data.error === true) {
          if (data.error_message === 'Window name already exists.') {
            setNameWarn(true);
            setDisableButton(true);
            setWNStatus('error');
          } else {
            setNameWarn(false);
            setDisableButton(false);
            setWNStatus('primary');
          }
        } else {
          if (data.message === 'You can continue with this window name.') {
            setDisableButton(false);
            setNameWarn(false);
            setWNStatus('primary');
          } else {
            setDisableButton(true);
            setWNStatus('error');
          }
        }
      });
  };
  const [selectedDescription, setSelectedDescription] = useState([]);
  const getMeasurementTypeSpecificFactor = () => {
    if (Buildout === 'No') {
      setAddNoteforBuildOut('');
    }
    if (FillerStrip === 'No') {
      setAddNoteforFillerStrip('');
    }
    if (SplitTilt === 'No') {
      setAddNoteforSplitTilt('');
    }
    if (SplitRail === 'No') {
      setAddNoteforSplitRail('');
    }
    return {
      WindowDepth: windowdepth,
      WindowConfiguration: Panel,
      Windowsill: Windowsill,
      VerticalConfiguration: VerticalConfiguration,
      SectionOneTop: SectionOneTopHeigth,
      SectionTwoBottom: SectionTwoBottomWidth,
      Othermeasurement: othermeasurement.replace(/"/g, ''),
    };
  };
  // Create a Window
  const CreateWindow = async () => {
    let measurement_type_specific_factor = getMeasurementTypeSpecificFactor();
    if (proposal_id) {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          token: token,
        },
        body: JSON.stringify({
          window_name: windowName,
          window_width: window_widthindecimal,
          window_height: window_heightindecimal,
          mount: mount,
          mount_surface: mountsurface,
          address_id: location.address_id,
          ladder: ladder,
          quantity: quantity,
          window_set_id: location.window_set_id,
          customer_id: location.customer_id,
          proposal_id: proposal_id,
          measurement_type: measurementType,
          install_requested: true,
          build_type: buildType,
          existing_covering: existingCovering,
          measurement_type_specific_factor: measurement_type_specific_factor,
          ApplyDefault: ApplyDefault,
          additional_sku: AdditionalSku,
          notes_data: filteredArr,
        }),
      };

      const response = await fetch(`${CONFIG.API_URL}/wm/Window`, requestOptions);
      const data = await response.json();
      if (!data.error) {
        setQty(quantity);
        checkbuttonOption(false, true, quantity);
        setNameWarn(true);
      } else {
        setNameWarn(false);
      }
    } else {
      const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          token: token,
        },
        body: JSON.stringify({
          window_name: windowName,
          window_width: window_widthindecimal,
          window_height: window_heightindecimal,
          ladder: ladder,
          mount_surface: mountsurface,
          quantity: quantity,
          window_set_id: location.window_set_id,
          address_id: location.address_id,
          sales_person_id: location.sales_person_id,
          install_requested: true,
          measurement_type: measurementType,
          build_type: buildType,
          existing_covering: existingCovering,
          measurement_type_specific_factor: measurement_type_specific_factor,
          ApplyDefault: ApplyDefault,
          additional_sku: AdditionalSku,
          notes_data: filteredArr,
        }),
      };

      // Created Window will be added to the database through Window API
      const response = await fetch(`${CONFIG.API_URL}/wm/Window`, requestOptions);
      const data = await response.json();
      if (!data.error) {
        checkbuttonOption(false, true, quantity);
      } else {
        setNameWarn(true);
      }
    }
  };

  const getSize = ({ target, nativeEvent }) => {
    var val = target.value;
    var input_char = val.charCodeAt(val.length - 1);
    if (val.includes('X') || val.includes('x')) {
      if (input_char >= 48 && input_char <= 57) {
        setSize(val);
        var width = '';
        var height = '';
        if (val.includes('x')) {
          const regex = /([^:\s]+)x([^:\s]+)/g;
          const str = val.replace(/\s+/g, '');
          let m;
          while ((m = regex.exec(str)) !== null) {
            width = m[1];
            height = m[2];
          }
          setSizeW(width);
          setSizeH(height);
        } else if (val.includes('X')) {
          const regex = /([^:\s]+)X([^:\s]+)/g;
          const str = val.replace(/\s+/g, '');
          let m;
          while ((m = regex.exec(str)) !== null) {
            width = m[1];
            height = m[2];
          }
          setSizeW(width);
          setSizeH(height);
        } else if (val.includes('*')) {
          const strpos = val.indexOf('*');
          function split(str, index) {
            const result = [str.slice(0, index), str.slice(index + 1)];

            return result;
          }
          const [width, height] = split(val, strpos);

          setSizeW(width);
          setSizeH(height);
        }
      } else {
        if (isNaN(val.charCodeAt(0))) {
          let removechar = size.slice(0, -1);
          setSize(removechar);
        }
        if (nativeEvent.inputType === 'deleteContentBackward') {
          let removechar = size.slice(0, -1);
          setSize(removechar);
        }
      }
    } else {
      if (input_char === 120 || input_char === 88 || (input_char >= 48 && input_char <= 57)) {
        setSize(val);
        var width = '';
        var height = '';
        if (val.includes('x')) {
          const regex = /([^:\s]+)x([^:\s]+)/g;
          const str = val.replace(/\s+/g, '');
          let m;
          while ((m = regex.exec(str)) !== null) {
            width = m[1];
            height = m[2];
          }
          setSizeW(width);
          setSizeH(height);
        } else if (val.includes('X')) {
          const regex = /([^:\s]+)X([^:\s]+)/g;
          const str = val.replace(/\s+/g, '');
          let m;
          while ((m = regex.exec(str)) !== null) {
            width = m[1];
            height = m[2];
          }
          setSizeW(width);
          setSizeH(height);
        } else if (val.includes('*')) {
          const strpos = val.indexOf('*');
          function split(str, index) {
            const result = [str.slice(0, index), str.slice(index + 1)];

            return result;
          }
          const [width, height] = split(val, strpos);

          setSizeW(width);
          setSizeH(height);
        }
      } else {
        if (isNaN(val.charCodeAt(0))) {
          let removechar = size.slice(0, -1);
          setSize(removechar);
        }
        if (nativeEvent.inputType === 'deleteContentBackward') {
          let removechar = size.slice(0, -1);
          setSize(removechar);
        }
      }
    }
  };

  const cancelWindow = () => checkbuttonOption(false, false);

  const checkDuplicateWindow = (quantity) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', token: token },
      body: JSON.stringify({
        customer_id: parseInt(location.customer_id),
        address_id: location.address_id,
        window_name: windowName,
        quantity: quantity,
      }),
    };
    if (quantity !== '') {
      fetch(`${CONFIG.API_URL}/cm/CheckDuplicateWindow`, requestOptions)
        .then((data) => data.json())
        .then((data) => {
          if (!data.error) {
            setDuplicateW(false);
          } else {
            setDuplicateW(true);
          }
        });
    }
  };

  const handleheadrailtype = (e) => {
    setHeadrail(e.target.value);
    if (e.target.value === '1 Section') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');
    }
    if (e.target.value === '2 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');
      // For 2 Sections, divide the TotalWidth by 2
      let TotalWidth = totalWidth;
      const dividedValue = TotalWidth / 2;
      setSection1(dividedValue);
      setSection2(dividedValue);
    }

    if (e.target.value === '3 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      if (TotalWidth) {
        const dividedValue = TotalWidth / 3;
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
      } else {
      }
    }
    if (e.target.value === '4 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 4;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }

    if (e.target.value === '5 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 5;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
        setSection5(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }

    if (e.target.value === '6 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 6;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
        setSection4(dividedValue);
        setSection5(dividedValue);
        setSection6(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }
  };

  const handleCutouts = (e) => {
    if (e.target.value === '') {
      setspecificDetails(true);
    } else {
      setspecificDetails(false);
    }
    setObstructions(e.target.value);
  };

  const handleWindowSill = (e) => {
    if (e.target.value === '') {
      setspecificDetails(true);
    } else {
      setspecificDetails(false);
    }
    setWindowsill(e.target.value);
  };

  const handleVerticalConfiguration = (e) => {
    setVerticalConfiguration(e.target.value);
    if (e.target.value === '1 Section') {
      setSectionOneTopShow('');
      setSectionTwoBottomShow('');
      setSectionOneTopHeigth('');
      setSectionTwoBottomWidth('');
    }
    if (e.target.value === '2 Sections') {
      const distributedValue = parseFloat(window_heightindecimal) / 2;

      setSectionOneTopShow(fracty(distributedValue) + '"');
      setSectionTwoBottomShow(fracty(distributedValue) + '"');
      setSectionOneTopHeigth(distributedValue);
      setSectionTwoBottomWidth(distributedValue);
    }
  };

  const [notes, setNotes] = useState(false);
  const [addWindowNotes, setAddWindowNotes] = useState('');
  const [noteId, setNoteId] = useState([]);
  const [notesContentForWindow, setNotesContentForWindow] = useState([]);
  const [eyeButtonMsg, setEyeButtonMsg] = useState(false);

  const [multipleNotes, setMultipleNotes] = useState([]);
  const [multipleNotes2, setMultipleNotes2] = useState([]);
  const [multipleNotesEye, setMultipleNotesEye] = useState([]);

  const unique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const NotesDrawerData = (notesDrawerStatus, notesMsgAlert, notesMsg, eyeButtonMsg, referenceId, noteId, noteContent, selectedDescription, multipleNotesMsg, noteContentAndMsg) => {
    setNotes(notesDrawerStatus);
    setNoteId(noteId);
    setNotesContentForWindow(noteContent);
    setEyeButtonMsg(eyeButtonMsg);
    setSelectedDescription(selectedDescription);

    if (multipleNotesMsg === true) {
      multipleNotes.push(notesContentForWindow);
      multipleNotes2.push(noteContentAndMsg);
    }
  };

  const filteredArr = multipleNotes2.filter((obj, index, self) => {
    return index === self.findIndex((o) => o.noteContent === obj.noteContent);
  });

  const filterMultipleNotes = multipleNotes.filter(unique);

  const filterMultipleNotes2 = multipleNotes2.filter(unique);

  const filterMultipleNotesEye = multipleNotesEye.filter(unique);

  const [ExistingSku, setExistingSku] = useState();
  const [BuildSku, setBuildSku] = useState();
  const [MountSku, setMountSku] = useState();
  const [LadderSku, setLadderSku] = useState();
  const [WindowDepthSku, setWindowDepthSku] = useState();
  const [HeadrailSku, setHeadrailSku] = useState();

  const [ObstructionsSku, setObstructionsSku] = useState();
  const [MultipleSKU, setMultipleSKU] = useState();
  const [BuildoutSku, setBuildoutSku] = useState();
  const [DeadMoldSku, setDeadMoldSku] = useState();
  const [FillerStripSku, setFillerStripSku] = useState();
  const [SplitRailSku, setSplitRailSku] = useState();
  const [SplitTiltSku, setSplitTiltSku] = useState();
  const [TPostSku, setTPostSku] = useState();
  const [RollDirectionSKU, setRollDirectionSKU] = useState();
  const [VerticalConfigurationSku, setVerticalConfigurationSku] = useState();

  func(ExistingSku, BuildSku, MountSku, LadderSku, WindowDepthSku, HeadrailSku, ObstructionsSku);

  useEffect(() => {
    if (existingCovering !== '') {
      ExistingCoveringsData.forEach((ec) => {
        if (ec.service_name === existingCovering) {
          setExistingSku(ec.sku);
        }
      });
    } else {
      setExistingSku('');
    }
    if (buildType !== '') {
      BuildTypeData.forEach((bt) => {
        if (bt.service_name === buildType) {
          setBuildSku(bt.sku);
        }
      });
    } else {
      setBuildSku('');
    }
    if (mountsurface !== '') {
      MountSurfaceData.forEach((ms) => {
        if (ms.service_name === mountsurface) {
          setMountSku(ms.sku);
        }
      });
    } else {
      setMountSku('');
    }
    if (ladder !== '') {
      LadderData.forEach((l) => {
        if (l.service_name === ladder) {
          setLadderSku(l.sku);
        }
      });
    } else {
      setLadderSku('');
    }
    if (headrail !== '') {
      TypeHeadrail.forEach((h) => {
        if (h.service_name === headrail) {
          setHeadrailSku(h.sku);
        }
      });
    } else {
      setHeadrailSku('');
    }
    if (windowdepth !== '') {
      WindowDepthData.forEach((v) => {
        if (v.service_name === windowdepth) {
          setWindowDepthSku(v.sku);
        }
      });
    } else {
      setWindowDepthSku('');
    }
    if (obstructions !== '') {
      ObstructionsData.forEach((c) => {
        if (c.service_name === obstructions) {
          setObstructionsSku(c.sku);
        }
      });
    } else {
      setObstructionsSku('');
    }
    if (multiple !== '') {
      TypeMultiple.forEach((m) => {
        if (m.service_name === multiple) {
          setMultipleSKU(m.sku);
        }
      });
    } else {
      setMultipleSKU('');
    }
    if (rolldirection !== '') {
      TypeRollDirection.forEach((r) => {
        if (r.service_name === rolldirection) {
          setRollDirectionSKU(r.sku);
        }
      });
    } else {
      setRollDirectionSKU('');
    }

    // shutters
    if (DeadMold !== '') {
      DeadMoldData.forEach((d) => {
        if (d.service_name === DeadMold) {
          setDeadMoldSku(d.sku);
        }
      });
    } else {
      setDeadMoldSku('');
    }

    if (Buildout !== '') {
      BuildOutData.forEach((b) => {
        if (b.service_name === Buildout) {
          setBuildoutSku(b.sku);
        }
      });
    } else {
      setBuildoutSku('');
    }

    if (FillerStrip !== '') {
      FillerStripData.forEach((f) => {
        if (f.service_name === FillerStrip) {
          setFillerStripSku(f.sku);
        }
      });
    } else {
      setFillerStripSku('');
    }

    if (SplitTilt !== '') {
      SplitTiltData.forEach((s) => {
        if (s.service_name === SplitTilt) {
          setSplitTiltSku(s.sku);
        }
      });
    } else {
      setSplitTiltSku('');
    }

    if (SplitRail !== '') {
      SplitRailData.forEach((s) => {
        if (s.service_name === SplitRail) {
          setSplitRailSku(s.sku);
        }
      });
    } else {
      setSplitRailSku('');
    }

    if (TPost !== '') {
      TPostData.forEach((t) => {
        if (t.service_name === TPost) {
          setTPostSku(t.sku);
        }
      });
    } else {
      setTPostSku('');
    }
  }, [
    existingCovering,
    buildType,
    mountsurface,
    ladder,
    headrail,
    windowdepth,
    obstructions,
    multiple,
    rolldirection,
    WindowDepthSku,
    HeadrailSku,
    ObstructionsSku,
    ExistingCoveringsData,
    BuildTypeData,
    MountSurfaceData,
    LadderData,
    WindowDepthData,
    ObstructionsData,
    TypeHeadrail,
    TypeMultiple,
    TypeRollDirection,
    DeadMold,
    Buildout,
    FillerStrip,
    SplitTilt,
    SplitRail,
    TPost,
  ]);

  useEffect(() => {
    const window_configuration = {
      section1,
      section2,
      section3,
      section4,
      section5,
      section6,
    };

    if (measurementType === 'Blinds') {
      additional_sku = [
        {
          additional_charge_sku: ExistingSku,
          sku_name: existingCovering,
          label: null,
          type: 'Existing Covering',
        },
        {
          additional_charge_sku: HeadrailSku,
          sku_name: headrail,
          label: 'null',
          type: 'Window Configuration',
          additional_detail: window_configuration,
        },
        {
          additional_charge_sku: LadderSku,
          sku_name: ladder,
          label: null,
          type: 'Ladder',
        },
        {
          additional_charge_sku: MountSku,
          sku_name: mountsurface,
          label: null,
          type: 'Mount Surface',
        },
        {
          additional_charge_sku: WindowDepthSku,
          sku_name: windowdepth,
          label: null,
          type: 'Window Depth',
          additional_detail: othermeasurement.replace(/"/g, ''),
        },
        {
          additional_charge_sku: ObstructionsSku,
          sku_name: obstructions,
          label: null,
          type: 'Obstructions',
          additional_detail: JSON.stringify(description.split(';')),
        },
        {
          additional_charge_sku: BuildSku,
          sku_name: buildType,
          label: null,
          type: 'Build Type',
        },
      ];
    } else if (measurementType === 'Soft Shades') {
      additional_sku = [
        {
          additional_charge_sku: ExistingSku,
          sku_name: existingCovering,
          label: null,
          type: 'Existing Covering',
        },
        {
          additional_charge_sku: HeadrailSku,
          sku_name: headrail,
          label: 'null',
          type: 'Window Configuration',
          additional_detail: window_configuration,
        },
        {
          additional_charge_sku: LadderSku,
          sku_name: ladder,
          label: null,
          type: 'Ladder',
        },
        {
          additional_charge_sku: MountSku,
          sku_name: mountsurface,
          label: null,
          type: 'Mount Surface',
        },
        {
          additional_charge_sku: BuildSku,
          sku_name: buildType,
          label: null,
          type: 'Build Type',
        },
        {
          additional_charge_sku: WindowDepthSku,
          sku_name: windowdepth,
          label: null,
          type: 'Window Depth',
          additional_detail: othermeasurement.replace(/"/g, ''),
        },
        {
          additional_charge_sku: ObstructionsSku,
          sku_name: obstructions,
          label: null,
          type: 'Obstructions',
          additional_detail: JSON.stringify(description.split(';')),
        },
      ];
    } else if (measurementType === 'Quick') {
      additional_sku = [
        {
          additional_charge_sku: 'S500990340',
          sku_name: '1 Section',
          label: null,
          type: 'Window Configuration',
          additional_detail: window_configuration,
        },
        {
          additional_charge_sku: ExistingSku,
          sku_name: existingCovering,
          label: null,
          type: 'Existing Covering',
        },
        {
          additional_charge_sku: LadderSku,
          sku_name: ladder,
          label: null,
          type: 'Ladder',
        },
        {
          additional_charge_sku: MountSku,
          sku_name: mountsurface,
          label: null,
          type: 'Mount Surface',
        },
        {
          additional_charge_sku: BuildSku,
          sku_name: buildType,
          label: null,
          type: 'Build Type',
        },
        {
          additional_charge_sku: WindowDepthSku,
          sku_name: windowdepth,
          label: null,
          type: 'Window Depth',
          additional_detail: othermeasurement.replace(/"/g, ''),
        },
        {
          additional_charge_sku: ObstructionsSku,
          sku_name: obstructions,
          label: null,
          type: 'Obstructions',
          additional_detail: JSON.stringify(description.split(';')),
        },
      ];
    } else if (measurementType === 'Shutters') {
      additional_sku = [
        {
          additional_charge_sku: ExistingSku,
          sku_name: existingCovering,
          label: null,
          type: 'Existing Covering',
        },
        {
          additional_charge_sku: HeadrailSku,
          sku_name: headrail,
          label: 'null',
          type: 'Window Configuration',
          additional_detail: window_configuration,
        },
        {
          additional_charge_sku: LadderSku,
          sku_name: ladder,
          label: null,
          type: 'Ladder',
        },
        {
          additional_charge_sku: MountSku,
          sku_name: mountsurface,
          label: null,
          type: 'Mount Surface',
        },
        {
          additional_charge_sku: BuildSku,
          sku_name: buildType,
          label: null,
          type: 'Build Type',
        },
        {
          additional_charge_sku: WindowDepthSku,
          sku_name: windowdepth,
          label: null,
          type: 'Window Depth',
          additional_detail: othermeasurement.replace(/"/g, ''),
        },
        {
          additional_charge_sku: ObstructionsSku,
          sku_name: obstructions,
          label: null,
          type: 'Obstructions',
          additional_detail: JSON.stringify(description.split(';')),
        },
        {
          additional_charge_sku: 'null',
          sku_name: Windowsill,
          label: null,
          type: 'Window Sill',
          additional_detail: '',
        },
        {
          additional_charge_sku: VerticalConfigurationSku,
          sku_name: VerticalConfiguration,
          label: null,
          type: 'Vertical Configuration',
          additional_detail: {
            SectionOneTop: SectionOneTopHeigth,
            SectionTwoBottom: SectionTwoBottomWidth,
          },
        },
      ];
    } else {
      additional_sku = [
        {
          additional_charge_sku: ExistingSku,
          sku_name: existingCovering,
          label: null,
          type: 'Existing Covering',
        },
        {
          additional_charge_sku: HeadrailSku,
          sku_name: headrail,
          label: 'null',
          type: 'Window Configuration',
          additional_detail: window_configuration,
        },
        {
          additional_charge_sku: LadderSku,
          sku_name: ladder,
          label: null,
          type: 'Ladder',
        },
        {
          additional_charge_sku: MountSku,
          sku_name: mountsurface,
          label: null,
          type: 'Mount Surface',
        },
        {
          additional_charge_sku: BuildSku,
          sku_name: buildType,
          label: null,
          type: 'Build Type',
        },
        {
          additional_charge_sku: MultipleSKU,
          sku_name: multiple,
          label: null,
          type: 'Multiple',
        },
        {
          additional_charge_sku: WindowDepthSku,
          sku_name: windowdepth,
          label: null,
          type: 'Window Depth',
          additional_detail: othermeasurement.replace(/"/g, ''),
        },
        {
          additional_charge_sku: ObstructionsSku,
          sku_name: obstructions,
          label: null,
          type: 'Obstructions',
          additional_detail: JSON.stringify(description.split(';')),
        },
      ];
    }

    setAdditionalSku(additional_sku);
  }, [measurementType, ExistingSku, BuildSku, MountSku, LadderSku, WindowDepthSku, HeadrailSku, ObstructionsSku, MultipleSKU, description, othermeasurement, VerticalConfigurationSku, SectionOneTopHeigth, SectionTwoBottomWidth, section1, section2, section3, section4, section5, section6]);

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered3, setIsImageHovered3] = useState(false);
  const handleHover3 = () => {
    setIsImageHovered3(!isImageHovered3);
  };

  const [NotesConfirmaAlert, setNotesConfirmaAlert] = useState(false);

  const handleWindowDepthOthermeasuere = () => {
    if (windowdepth === 'Other') {
      setIsCommonDrawerOpen(true);
    }
  };

  const [VerticalConfigurationAlert, setVerticalConfigurationAlert] = useState(false);
  const fetchdata3 = (HeigthPrint, drawerstatus, SectionTopHeigth, SectionTopHeigthWithDecimal, VerticalConfigurationSectionTopValueStatus, VCValue, result, ForCancelSectionTop1) => {
    if (VCValue === true) {
      setSectionOneTopShow(fracty(ForCancelSectionTop1) + '"');
      setSectionOneTopHeigth(ForCancelSectionTop1);
      const SectionBottomHeigth = Number(window_heightindecimal) - Number(ForCancelSectionTop1);
      setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
      setSectionTwoBottomWidth(SectionBottomHeigth);
    } else {
      if (parseFloat(window_heightindecimal) === parseFloat(SectionTopHeigth) || parseFloat(window_heightindecimal) === parseFloat(SectionTopHeigthWithDecimal) || parseFloat(window_heightindecimal) < parseFloat(SectionTopHeigth)) {
        setVerticalConfigurationAlert(true);
        setSectionTwoBottomShow('');
        setSectionOneTopShow('');
      } else {
        setSectionOneTopShow(HeigthPrint);
        if (VerticalConfigurationSectionTopValueStatus === true) {
          if (HeigthPrint.includes('/')) {
            setSectionOneTopHeigth(SectionTopHeigthWithDecimal);
            const SectionBottomHeigth = Number(window_heightindecimal) - Number(SectionTopHeigthWithDecimal);
            setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
            setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
            setSectionTwoBottomWidth(SectionBottomHeigth);
          } else {
            setSectionOneTopHeigth(SectionTopHeigth);
            const SectionBottomHeigth = Number(window_heightindecimal) - Number(SectionTopHeigth);
            if (SectionBottomHeigth.toString().includes('.')) {
              setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
            } else {
              setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
            }
            setSectionTwoBottomWidth(SectionBottomHeigth);
          }
        }
      }
    }

    setLeftShadeisDrawerOpen(drawerstatus);
  };

  useEffect(() => {
    // Check individually for each section
    if (section1 <= 0) {
      setSection1('');
    }
    if (section2 <= 0) {
      setSection2('');
    }
    if (section3 <= 0) {
      setSection3('');
    }
    if (section4 <= 0) {
      setSection4('');
    }
    if (section5 <= 0) {
      setSection5('');
    }
    if (section6 <= 0) {
      setSection6('');
    }
  }, [section1, section2, section3, section4, section5, section6]);

  useEffect(() => {
    if (warnQty === true) {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      setChangedSection1(false);
      setChangedSection2(false);
      setChangedSection3(false);
      setChangedSection4(false);
      setChangedSection5(false);
      setChangedSection6(false);
    }
  }, [warnQty, sectionValues]);
  const [editingNote, setEditingNote] = useState(false);
  const [noteDrawerDetails, setNoteDrawerDetails] = useState({});
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);

  const closeNotesDrawer = (alert) => {
    setNotesDrawerOpen(false);
    setEditingNote(false);
    setNoteDrawerDetails({});

    if (alert.message === 'Note added successfully!' || alert.message === 'Note updated successfully!') {
      // TODO: fetch notes again
    }
  };

  // const openNotesDrawer = (note) => {
  //   setEditingNote(!!note);
  //   setNoteDrawerDetails({
  //     firstName: location.first_name,
  //     lastname: location.last_name,
  //     referenceId: windowName,
  //     noteId: note ? note.id : null,
  //   });
  //   setNotesDrawerOpen(true);
  // };
  return (
    <>
      <div align='center'>
        <Stack spacing={2} sx={{ width: '100%' }}>
          {/* new  */}

          {/* GetLookupTableDataErrorMsg */}
          <Snackbar className='snackbar_bar_postition' anchorOrigin={{ vertical, horizontal }} onClose={() => setGetLookupTableDataErrorMsg(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img
                  onClick={() => {
                    setGetLookupTableDataErrorMsg(false);
                  }}
                  src={RedDrawerCancelIcon}
                  alt='CANCELICONGLT'
                  className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='CONFIRMICONGLT' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{GetLookupTableDataErrorMsgTxt}</div>
              </div>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={MeasurementTypeAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setMeasurementTypeAlert(false)}>
            <Alert className='Success mainAlert' variant='filled' icon={false}>
              <div className='alertCancel' align='right'>
                <img
                  onClick={() => {
                    setMeasurementTypeAlert(false);
                    setMeasurementType(previousValue);
                  }}
                  src={DrawerCancelIcon}
                  alt='CANCELICONQKR'
                  className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                />
              </div>

              <div className='alertMainNew'>
                <div align='left' className='confirmIconStyle'>
                  <Box className='commonDisplayStyle_1'>
                    <Box className='alertCantinueButton'>
                      <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='' />
                    </Box>
                  </Box>

                  <div
                    className='alertTextAlign'
                    style={{
                      paddingTop: 20,
                      fontSize: '20px',
                      fontFamily: 'RobotoRegular',
                    }}
                  >
                    The existing measurements will be rounded up to the nearest whole number.
                  </div>
                </div>

                <div
                  align='left'
                  className='commonDisplayStyle_2'
                  style={{
                    padding: '20px 10px 0px 24px',
                    lineHeight: '24px',
                  }}
                >
                  <Box
                    onClick={() => {
                      setMeasurementTypeConfirm(true);
                      setConditionExecuted(false);
                    }}
                    className='commonDisplayStyle_1'
                    sx={{
                      cursor: 'pointer',
                      marginBottom: '20px',
                    }}
                  >
                    <Box onClick={() => {}} className='alertCantinueButton'>
                      <img src={DrawerConinueIcon} className='primary_icons_dimension' alt='CONTINUE' />
                    </Box>
                    <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                      CONTINUE
                    </Typography>
                  </Box>

                  <Box
                    onClick={() => {
                      setMeasurementTypeAlert(false);
                      setMeasurementType(previousValue);
                    }}
                    className='commonDisplayStyle_1'
                    sx={{
                      cursor: 'pointer',
                      paddingBottom: '16px',
                      paddingLeft: '15px',
                    }}
                  >
                    <Box className='globalIconMainBox'>
                      <Box className='iconSecondaryButtonGlobalForDrawer'>
                        <img src={DrawerCancelIcon} alt='CANCELICONQKR' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                      </Box>
                      <Typography className='globalTextForDrawer'>CANCEL</Typography>
                    </Box>
                  </Box>
                </div>
              </div>
            </Alert>
          </Snackbar>
          {/* new  */}

          {/* new  */}
          <Snackbar className='snackbar_bar_postition' open={NotesConfirmaAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setNotesConfirmaAlert(false)}>
            <Alert className='Success mainAlert' variant='filled' icon={false}>
              <div className='alertCancel' align='right'>
                <img onClose={() => setNotesConfirmaAlert(false)} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>

              <div className='alertMainNew'>
                <div align='left' className='confirmIconStyle'>
                  <Box className='commonDisplayStyle_1' sx={{}}>
                    <Box className='alertCantinueButton'>
                      <img src={DrawerAlertIcon} className='primary_icons_dimension' />
                    </Box>
                  </Box>

                  <div
                    className='alertTextAlign'
                    style={{
                      paddingTop: 20,
                      fontSize: '20px',
                      fontFamily: 'RobotoRegular',
                    }}
                  >
                    Previously entered Window Notes will be deleted if Quantity is more than 1.
                  </div>
                </div>

                <div
                  align='left'
                  className='commonDisplayStyle_2'
                  style={{
                    padding: '20px 10px 0px 24px',
                    lineHeight: '24px',
                  }}
                >
                  <Box
                    onClick={() => {
                      setMultipleNotes2([]);
                      setNotesConfirmaAlert(false);
                    }}
                    className='commonDisplayStyle_1'
                    sx={{
                      cursor: 'pointer',
                      marginBottom: '20px',
                    }}
                  >
                    <Box onClick={() => {}} className='alertCantinueButton'>
                      <img src={DrawerConinueIcon} className='primary_icons_dimension' />
                    </Box>
                    <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                      CONTINUE
                    </Typography>
                  </Box>

                  <Box
                    onClick={() => {
                      setNotesConfirmaAlert(false);
                      setQuantity(1);
                    }}
                    className='commonDisplayStyle_1'
                    sx={{
                      cursor: 'pointer',
                      paddingBottom: '16px',
                      paddingLeft: '15px',
                    }}
                  >
                    <Box className='globalIconMainBox'>
                      <Box className='iconSecondaryButtonGlobalForDrawer'>
                        <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                      </Box>
                      <Typography className='globalTextForDrawer'>CANCEL</Typography>
                    </Box>
                  </Box>
                </div>
              </div>
            </Alert>
          </Snackbar>
          {/* new  */}
        </Stack>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setOpen(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img alt='CANCELICONQ' onClick={handleSuccessClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img alt='CONFIRMICONQ' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />

                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Quantity must be a number 1 - 20</div>
                </div>
              </>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={namewarn} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setNameWarn(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img alt='CANCELICONWNR' onClick={handleErrorClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img alt='CONFIRMICONWNR' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Window name already Exists.</div>
                </div>
              </>
            </Alert>
          </Snackbar>
        </Stack>

        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar className='snackbar_bar_postition' open={WidthSnackBar} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setOpen(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img
                    alt='CANCELICONWA'
                    onClick={() => {
                      setWidthSnackBar(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img alt='CONFIRMICONWA' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Width is greater !</div>
                </div>
              </>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={warnQty} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setwarnQty(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img
                    alt='CANCELICON'
                    onClick={() => {
                      setwarnQty(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img alt='CONFIRMICON' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{msg}</div>
                </div>
              </>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={invalid} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setInvalid(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img
                    alt='CANCELICONII'
                    onClick={() => {
                      setInvalid(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img alt='CONFIRMICONII' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Invalid Input !</div>
                </div>
              </>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={warnQty} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setwarnQty(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img
                    alt='CANCELICONRE'
                    onClick={() => {
                      setwarnQty(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img alt='CONFIRMICONRE' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{msg}</div>
                </div>
              </>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={invalid} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setInvalid(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img
                    alt='CANCELICOONII'
                    onClick={() => {
                      setInvalid(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img alt='CONFIRMICONII' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Invalid Input !</div>
                </div>
              </>
            </Alert>
          </Snackbar>
          <Snackbar className='snackbar_bar_postition' open={warnQty} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setwarnQty(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img
                    alt='CANCELICON'
                    onClick={() => {
                      setwarnQty(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img alt='CONFIRMICON' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{msg}</div>
                </div>
              </>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={invalid} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setInvalid(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img
                    alt='CANCELICONIIR'
                    onClick={() => {
                      setInvalid(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img alt='CONFIRMICONIIR' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Invalid Input !</div>
                </div>
              </>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={VerticalConfigurationAlert} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setVerticalConfigurationAlert(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img
                    alt='CANCELICONVC'
                    onClick={() => {
                      setVerticalConfigurationAlert(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img alt='CONFIRMICONVC' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Section 1 - Top H is greater than {fracty(window_heightindecimal)}</div>
                </div>
              </>
            </Alert>
          </Snackbar>
        </Stack>
      </div>

      <div>
        {/* Slider component for Window Size Input */}
        <Drawer
          classes={{ paper: classes.paper }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          PaperProps={{
            sx: { width: '90%' },
          }}
          anchor='right'
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(true)}
          sx={{ zIndex: '100000' }}
        >
          <DrawerInput size={size} StoreHeightWidth={StoreHeightWidth} measurement_type={measurementType} func={fetchdata} AddWindow='AddWindow' />
        </Drawer>
        <Drawer
          classes={{ paper: classes.paper }}
          PaperProps={{
            sx: { width: '90%' },
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          anchor='right'
          open={isCommonDrawerOpen}
          onClose={() => setIsCommonDrawerOpen(true)}
          sx={{ zIndex: '1000000' }}
        >
          <CommonDrawerInputWidth othermeasurement={othermeasurement} func={fetchdatafromCommonDrawer} />
        </Drawer>

        <Drawer
          classes={{ paper: classes.paper }}
          PaperProps={{
            sx: { width: '90%' },
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          anchor='right'
          open={leftShadeisDrawerOpen}
          onClose={() => setLeftShadeisDrawerOpen(true)}
          sx={{ zIndex: '100000' }}
        >
          {WidthType === 'LeftWidth' ? (
            <>
              <DrawerInputWidth measurement_type={measurementType} WidthType='LeftWidth' func={fetchdata2} AddWindow='AddWindow' />
            </>
          ) : WidthType === 'Section 1' ? (
            <>
              <DrawerInputWidth measurement_type={measurementType} section={section1} WidthType='Section 1' func={fetchdata2} AddWindow='AddWindow' />
            </>
          ) : WidthType === 'Section 2' ? (
            <>
              <DrawerInputWidth measurement_type={measurementType} section={section2} WidthType='Section 2' func={fetchdata2} AddWindow='AddWindow' />
            </>
          ) : WidthType === 'Section 3' ? (
            <>
              <DrawerInputWidth section={section3} measurement_type={measurementType} WidthType='Section 3' func={fetchdata2} AddWindow='AddWindow' />
            </>
          ) : WidthType === 'Section 4' ? (
            <>
              <DrawerInputWidth section={section4} measurement_type={measurementType} WidthType='Section 4' func={fetchdata2} AddWindow='AddWindow' />
            </>
          ) : WidthType === 'Section 5' ? (
            <>
              <DrawerInputWidth section={section5} measurement_type={measurementType} WidthType='Section 5' func={fetchdata2} AddWindow='AddWindow' />
            </>
          ) : HeigthType === 'SectionTopHeigth' || WidthType === 'SectionTopHeigth' ? (
            <>
              <DrawerInputWidth measurement_type={measurementType} SectionOneTopShow={SectionOneTopHeigth} HeigthType='SectionTopHeigth' func2={fetchdata3} AddWindow='AddWindow' />
            </>
          ) : (
            <>
              <DrawerInputWidth measurement_type={measurementType} func={fetchdata2} AddWindow='AddWindow' />
            </>
          )}
        </Drawer>

        <ThemeProvider theme={theme1}>
          <Drawer
            classes={{ paper: classes.paper }}
            PaperProps={{
              sx: { width: '90%' },
            }}
            BackdropProps={{
              classes: {
                root: classes.backDrop,
              },
            }}
            anchor='right'
            open={notesDrawerOpen}
            onClose={() => setNotesDrawerOpen(false)}
            className='drawer_Header_page'
          >
            <NotesDrawer closeDrawer={closeNotesDrawer} editing={editingNote} type='customer' details={noteDrawerDetails} />
          </Drawer>
        </ThemeProvider>

        <Box
          className='button_top_space'
          sx={{
            width: '100%',
            fontFamily: 'RobotoLight',
            display: 'flex',
            flexDirection: 'column',
          }}
          noValidate
          autoComplete='off'
        >
          <ThemeProvider theme={theme}>
            {duplicateWN ? <span style={{ color: 'red', marginTop: -200, pt: 0 }}>'{windowName}' This name has already been used</span> : ''}
            <TextField
              inputRef={inputRef}
              className='formcontrol text_inputfield'
              id='filled-basic'
              label='Window Name'
              variant='outlined'
              required
              InputProps={{
                style: { fontWeight: 'bold' },
              }}
              color={wnstatus}
              value={windowName}
              onChange={(e) => {
                CheckNameDuplicacywithQuantity(1);
                setWindowName(e.target.value);
              }}
              onKeyUp={() => {
                CheckNameDuplicacywithQuantity(1);
              }}
            />
            <FormControl onClick={() => setMeasurementTypeOpen(!measurementTypeOpen)} className='formcontrol text_selectfield'>
              <Select
                value={measurementType}
                align='left'
                size='small'
                required
                onClose={() => setMeasurementTypeOpen(false)}
                onOpen={() => setMeasurementTypeOpen(true)}
                open={measurementTypeOpen}
                onChange={(e) => handleMeasurementType(e)}
                displayEmpty
                startAdornment={
                  <InputAdornment position='start'>
                    Measurement Type
                    <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                    &nbsp;:&nbsp; {measurementType === '' ? '' : ''}
                  </InputAdornment>
                }
              >
                <MenuItem>
                  <b>─ ─</b>
                </MenuItem>
                {Typemeasurement.map((option) => (
                  <MenuItem key={option.measurement_type} value={option.measurement_type}>
                    <b style={{ color: option.color_code }}>
                      {option.measurement_type} ({option.short_code})
                    </b>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl onClick={() => setBuildTypeOpen(!BuildTypeOpen)} className='formcontrol text_selectfield'>
              <Select
                value={buildType}
                align='left'
                size='small'
                required
                onClose={() => setBuildTypeOpen(false)}
                onOpen={() => setBuildTypeOpen(true)}
                open={BuildTypeOpen}
                onChange={(e) => setBuildType(e.target.value)}
                displayEmpty
                startAdornment={
                  <InputAdornment className='cursor' position='start'>
                    Build Type &nbsp;{buildTypeReq}&nbsp;:&nbsp;
                    {buildType === '' ? '' : ''}
                  </InputAdornment>
                }
              >
                <MenuItem value={''}>
                  <b>─ ─</b>
                </MenuItem>
                {BuildTypeData.map((option) =>
                  measurementType === 'Roller Shades' ? (
                    <MenuItem
                      onClick={() => {
                        setBuildSku(option.sku);
                      }}
                      key={option.service_name}
                      value={option.service_name}
                    >
                      <b>{option.service_name}</b>{' '}
                    </MenuItem>
                  ) : option.service_name === 'Fabric' ? (
                    <span
                      style={{
                        paddingLeft: 18,
                        marginBottom: 30,
                        color: '#929292',
                      }}
                      {...(option.disabled = true)}
                    >
                      {option.service_name}
                    </span>
                  ) : (
                    <MenuItem
                      onClick={() => {
                        setBuildSku(option.sku);
                      }}
                      key={option.service_name}
                      value={option.service_name}
                    >
                      <b>{option.service_name}</b>
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <TextField
              className='text_selectfield formcontrol'
              onClick={() => setIsDrawerOpen(true)}
              id='filled-basic'
              label='Width X Height'
              variant='outlined'
              required
              value={size}
              InputProps={{
                readOnly: true,
                style: { fontWeight: 'bold' },
              }}
              onChange={(e) => {
                setSize(e.target.value);
                getSize(e.target.value);
              }}
            />
            <FormControl onClick={() => setValanceOpen(!ValanceOpen)} className='formcontrol text_selectfield'>
              <Select
                value={windowdepth}
                required
                align='left'
                size='small'
                onClose={() => setValanceOpen(false)}
                onOpen={() => setValanceOpen(true)}
                open={ValanceOpen}
                onChange={(e) => handleValanceSelection(e)}
                displayEmpty
                startAdornment={
                  <InputAdornment className='cursor' position='start'>
                    Window Depth
                    <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                    &nbsp;:&nbsp;
                  </InputAdornment>
                }
              >
                <MenuItem value={''}>
                  <b>─ ─</b>
                </MenuItem>
                {WindowDepthData.map((option) => (
                  <MenuItem
                    onClick={() => {
                      setWindowDepthSku(option.sku);
                    }}
                    key={option.service_name}
                    value={option.service_name}
                  >
                    <b>{option.service_name}</b>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {windowdepth === 'Other' ? (
              <div onClick={handleWindowDepthOthermeasuere}>
                <TextField
                  className='short_textfield formcontrol font_style_of_menuitem'
                  id='filled-basic'
                  label='Window Depth'
                  variant='outlined'
                  required
                  defaultValue='Window Depth'
                  value={othermeasurement}
                  InputProps={{
                    style: { fontWeight: 'bold', fontFamily: 'Roboto' },
                  }}
                />
              </div>
            ) : (
              ''
            )}

            {measurementType !== 'Quick' ? (
              <FormControl onClick={() => setHeadrailOpen(!HeadrailOpen)} className='formcontrol text_selectfield'>
                <Select
                  value={headrail}
                  required
                  align='left'
                  size='small'
                  onClose={() => setHeadrailOpen(false)}
                  onOpen={() => setHeadrailOpen(true)}
                  open={HeadrailOpen}
                  onChange={handleheadrailtype}
                  displayEmpty
                  startAdornment={
                    <InputAdornment className='cursor' position='start'>
                      Window Configuration
                      <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                      &nbsp;:&nbsp;
                    </InputAdornment>
                  }
                >
                  <MenuItem value={''}>
                    <b>─ ─</b>
                  </MenuItem>
                  {TypeHeadrail.map((option) => (
                    <MenuItem
                      onClick={() => {
                        setHeadrailSku(option.sku);
                      }}
                      key={option.service_name}
                      value={option.service_name}
                    >
                      <b>{option.service_name}</b>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              ''
            )}

            {measurementType !== 'Quick' && // Add this condition
              (headrail === '4 Sections' || headrail === '5 Sections' || headrail === '6 Sections' ? (
                <>
                  <Typography className='cspMeasurementAlertsText margin_top margin_bottom' sx={{ display: 'flex', alignItems: 'center' }}>
                    <ErrorIcon sx={{ color: '#aa0d0d' }} />
                    Please enter the width measurements from left to right
                  </Typography>
                </>
              ) : (
                <></>
              ))}

            {measurementType !== 'Quick' ? (
              <>
                {headrail === '2 Sections' ? (
                  <>
                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 1 - Left'
                      variant='outlined'
                      required
                      value={sec2_1 === '0"' ? section1 : sec2_1}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                      onClick={() => {
                        setWidthType('Section 1');
                        setLeftShadeisDrawerOpen(true);
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 2'
                      variant='outlined'
                      required
                      value={sec2_2 === '0"' ? section2 : sec2_2}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                        readOnly: true,
                      }}
                    />
                  </>
                ) : (
                  ''
                )}

                {/* Section -2 Ends */}

                {/* Section - 3 */}

                {headrail === '3 Sections' ? (
                  <>
                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 1 - Left'
                      variant='outlined'
                      required
                      value={sec3_1 === '0"' ? section1 : sec3_1}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                      onClick={() => {
                        setWidthType('Section 1');
                        setLeftShadeisDrawerOpen(true);
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 2'
                      variant='outlined'
                      required
                      value={sec3_2 === '0"' ? section2 : sec3_2}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                      onClick={() => {
                        setWidthType('Section 2');
                        setLeftShadeisDrawerOpen(true);
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 3 - Right'
                      variant='outlined'
                      required
                      value={sec3_3 === '0"' ? section3 : sec3_3}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                        readOnly: true,
                      }}
                    />
                  </>
                ) : (
                  ''
                )}

                {/* Section - 3 Ends */}

                {/* Section - 4 */}

                {headrail === '4 Sections' ? (
                  <>
                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 1 - Left'
                      variant='outlined'
                      required
                      value={sec4_1 === '0"' ? section1 : sec4_1}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                      onClick={() => {
                        setWidthType('Section 1');
                        setLeftShadeisDrawerOpen(true);
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 2'
                      variant='outlined'
                      required
                      value={sec4_2 === '0"' ? section2 : sec4_2}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                      onClick={() => {
                        setWidthType('Section 2');
                        setLeftShadeisDrawerOpen(true);
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 3'
                      variant='outlined'
                      required
                      value={sec4_3 === '0"' ? section3 : sec4_3}
                      onClick={() => {
                        setWidthType('Section 3');
                        setLeftShadeisDrawerOpen(true);
                      }}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 4 - Right'
                      variant='outlined'
                      required
                      value={sec4_4 === '0"' ? section4 : sec4_4}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                    />
                  </>
                ) : (
                  ''
                )}
                {/* Section - 4 Ends */}

                {/* Section - 5 */}

                {headrail === '5 Sections' ? (
                  <>
                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 1 - Left'
                      variant='outlined'
                      required
                      value={sec5_1 === '0"' ? section1 : sec5_1}
                      onClick={() => {
                        setWidthType('Section 1');
                        setLeftShadeisDrawerOpen(true);
                      }}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 2'
                      variant='outlined'
                      required
                      value={sec5_2 === '0"' ? section2 : sec5_2}
                      onClick={() => {
                        setWidthType('Section 2');
                        setLeftShadeisDrawerOpen(true);
                      }}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 3'
                      variant='outlined'
                      required
                      value={sec5_3 === '0"' ? section3 : sec5_3}
                      onClick={() => {
                        setWidthType('Section 3');
                        setLeftShadeisDrawerOpen(true);
                      }}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 4'
                      variant='outlined'
                      required
                      value={sec5_4 === '0"' ? section4 : sec5_4}
                      onClick={() => {
                        setWidthType('Section 4');
                        setLeftShadeisDrawerOpen(true);
                      }}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 5 - Right'
                      variant='outlined'
                      required
                      value={sec5_5 === '0"' ? section5 : sec5_5}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                    />
                  </>
                ) : (
                  ''
                )}
                {/* Section - 5 Ends */}

                {/* Section - 6 */}

                {headrail === '6 Sections' ? (
                  <>
                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 1 - Left'
                      variant='outlined'
                      required
                      value={sec6_1 === '0"' ? section1 : sec6_1}
                      onClick={() => {
                        setWidthType('Section 1');
                        setLeftShadeisDrawerOpen(true);
                      }}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 2'
                      variant='outlined'
                      required
                      value={sec6_2 === '0"' ? section2 : sec6_2}
                      onClick={() => {
                        setWidthType('Section 2');
                        setLeftShadeisDrawerOpen(true);
                      }}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 3'
                      variant='outlined'
                      required
                      value={sec6_3 === '0"' ? section3 : sec6_3}
                      onClick={() => {
                        setWidthType('Section 3');
                        setLeftShadeisDrawerOpen(true);
                      }}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 4'
                      variant='outlined'
                      required
                      value={sec6_4 === '0"' ? section4 : sec6_4}
                      onClick={() => {
                        setWidthType('Section 4');
                        setLeftShadeisDrawerOpen(true);
                      }}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 5'
                      variant='outlined'
                      required
                      value={sec6_5 === '0"' ? section5 : sec6_5}
                      onClick={() => {
                        setWidthType('Section 5');
                        setLeftShadeisDrawerOpen(true);
                      }}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                    />

                    <TextField
                      className='short_textfield formcontrol'
                      id='filled-basic'
                      label='Section 6 - Right'
                      variant='outlined'
                      required
                      value={sec6_6 === '0"' ? section6 : sec6_6}
                      InputProps={{
                        style: { fontWeight: 'bold' },
                      }}
                    />
                  </>
                ) : (
                  ''
                )}
                {/* Section - 6 Ends */}
              </>
            ) : (
              ''
            )}

            <>
              <hr className='button_top_space button_bottom_space' />

              <FormControl onClick={() => setExistingcoveringOpen(!existingcoveringOpen)} className='formcontrol text_selectfield'>
                <Select
                  value={existingCovering}
                  align='left'
                  size='small'
                  required
                  onClose={() => setExistingcoveringOpen(false)}
                  onOpen={() => setExistingcoveringOpen(true)}
                  open={existingcoveringOpen}
                  onChange={(e) => setExistingcovering(e.target.value)}
                  displayEmpty
                  startAdornment={
                    <InputAdornment className='cursor' position='start'>
                      Existing Coverings
                      <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                      &nbsp;:&nbsp;
                      {existingCovering === '' ? '' : ''}
                    </InputAdornment>
                  }
                >
                  <MenuItem value={''}>
                    <b>─ ─</b>
                  </MenuItem>
                  {ExistingCoveringsData.map((option) => (
                    <MenuItem
                      onClick={() => {
                        setExistingSku(option.sku);
                      }}
                      key={option.service_name}
                      value={option.service_name}
                    >
                      <b>{option.service_name}</b>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>

            <FormControl onClick={() => setMountsurfaceOpen(!MountsurfaceOpen)} className='formcontrol text_selectfield'>
              <Select
                value={mountsurface}
                align='left'
                size='small'
                required
                onClose={() => setMountsurfaceOpen(false)}
                onOpen={() => setMountsurfaceOpen(true)}
                open={MountsurfaceOpen}
                onChange={(e) => setMountsurface(e.target.value)}
                displayEmpty
                startAdornment={
                  <InputAdornment className='cursor' position='start'>
                    Mount Surface
                    <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                    &nbsp;:&nbsp;{mountsurface === '' ? '' : ''}
                  </InputAdornment>
                }
              >
                <MenuItem value={''}>
                  <b>─ ─</b>
                </MenuItem>
                {MountSurfaceData.map((option) => (
                  <MenuItem
                    onClick={() => {
                      setMountSku(option.sku);
                    }}
                    key={option.service_name}
                    value={option.service_name}
                  >
                    <b>{option.service_name}</b>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl onClick={() => setCutoutOpen(!CutoutOpen)} sx={{ marginTop: '5px !important' }} className='formcontrol text_selectfield_upper margin_top margin_bottom'>
              <Select
                value={obstructions}
                required
                align='left'
                size='small'
                onClose={() => setCutoutOpen(false)}
                onOpen={() => setCutoutOpen(true)}
                open={CutoutOpen}
                onChange={handleCutouts}
                displayEmpty
                startAdornment={
                  <InputAdornment className='cursor' position='start'>
                    Obstructions
                    <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                    &nbsp;:
                  </InputAdornment>
                }
              >
                <MenuItem value={''}>
                  <b>─ ─</b>
                </MenuItem>

                {ObstructionsData.map((option) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        setObstructionsSku(option.sku);
                      }}
                      key={option.service_name}
                      value={option.service_name}
                    >
                      <b>{option.service_name}</b>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {obstructions === 'Yes' ? (
              <TextField
                className='short_textfield formcontrol font_style_of_menuitem'
                id='filled-basic'
                label='Obstructions'
                variant='outlined'
                required
                placeholder='Description'
                value={description}
                InputProps={{
                  style: { fontWeight: 'bold', fontFamily: 'Roboto' },
                }}
                onChange={(e) => setDescription(e.target.value)}
              />
            ) : (
              ''
            )}

            <FormControl onClick={() => setLadderOpen(!LadderOpen)} className='formcontrol text_selectfield margin_top'>
              <Select
                value={ladder}
                align='left'
                size='small'
                required
                onClose={() => setLadderOpen(false)}
                onOpen={() => setLadderOpen(true)}
                open={LadderOpen}
                onChange={(e) => setLadder(e.target.value)}
                displayEmpty
                startAdornment={
                  <InputAdornment className='cursor' position='start'>
                    Ladder
                    <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                    &nbsp;:&nbsp;{ladder === '' ? '' : ''}
                  </InputAdornment>
                }
              >
                <MenuItem value={''}>
                  <b>─ ─</b>
                </MenuItem>
                {LadderData.map((option) => (
                  <MenuItem
                    onClick={() => {
                      setLadderSku(option.sku);
                    }}
                    key={option.service_name}
                    value={option.service_name}
                  >
                    <b>{option.service_name}</b>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* ─ ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ─Quick─ ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ─ */}
            {measurementType === 'Quick' ? (
              <TextField
                className='formcontrol text_selectfield'
                label='Quantity'
                required
                inputProps={{ min, max }}
                type='number'
                id='filled-basic'
                value={quantity}
                InputProps={{
                  style: { fontWeight: 'bold' },
                }}
                onKeyUp={(e) => checkDuplicateWindow(e.target.value)}
                onClick={() => setQuantity('')}
                onChange={(e) => {
                  if (filteredArr.length > 0) {
                    setNotesConfirmaAlert(true);
                  }
                  const value = parseInt(e.target.value);

                  let qtyflag;
                  isNaN(value) ? (qtyflag = true) : (qtyflag = false);
                  if (value <= 1 || qtyflag === true) {
                    setWindowQty('ADD WINDOW');
                  } else {
                    setWindowQty('ADD WINDOWS');
                  }
                  if (value >= min && value <= max) {
                    setQuantity(value);
                  } else {
                    setQuantity('');
                    setWindowQty('ADD WINDOW');
                    setWarn('true');
                  }
                }}
              />
            ) : (
              ''
            )}
            {/* ─ ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ─Quick Closed─ ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ─ */}
            {/* ─ ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ─Shutters─ ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ─ */}

            {measurementType === 'Shutters' ? (
              <>
                <FormControl onClick={() => setWindowsillOpen(!WindowsillOpen)} className='formcontrol text_selectfield'>
                  <Select
                    value={Windowsill}
                    align='left'
                    required
                    size='small'
                    onClose={() => setWindowsillOpen(false)}
                    onOpen={() => setWindowsillOpen(true)}
                    open={WindowsillOpen}
                    onChange={handleWindowSill}
                    displayEmpty
                    startAdornment={
                      <InputAdornment className='cursor' position='start'>
                        Windowsill
                        <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                        &nbsp;:&nbsp;
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={''}>
                      <b>─ ─</b>
                    </MenuItem>
                    {WindowSillData.map((option) => (
                      <MenuItem onClick={() => {}} key={option.service_name} value={option.service_name}>
                        <b>{option.service_name}</b>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl onClick={() => setVerticalConfigurationOpen(!VerticalConfigurationOpen)} className='formcontrol text_selectfield_upper margin_top margin_bottom'>
                  <Select
                    value={VerticalConfiguration}
                    required
                    align='left'
                    size='small'
                    onClose={() => setVerticalConfigurationOpen(false)}
                    onOpen={() => setVerticalConfigurationOpen(true)}
                    open={VerticalConfigurationOpen}
                    onChange={handleVerticalConfiguration}
                    displayEmpty
                    startAdornment={
                      <InputAdornment className='cursor' position='start'>
                        Vertical Configuration
                        <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                        &nbsp;:
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={''}>
                      <b>─ ─</b>
                    </MenuItem>

                    {VerticalConfigurationData.map((option) => (
                      <MenuItem
                        onClick={() => {
                          setVerticalConfigurationSku(option.sku);
                        }}
                        key={option.service_name}
                        value={option.service_name}
                      >
                        <b>{option.service_name}</b>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {VerticalConfiguration === '2 Sections' ? (
                  <>
                    {size ? (
                      <TextField
                        className='short_textfield formcontrol margin_top'
                        id='filled-basic'
                        value={SectionOneTopShow}
                        onClick={() => {
                          setSectionTwoBottomShow('');
                          setHeigthType('SectionTopHeigth');
                          setWidthType('SectionTopHeigth');
                          setLeftShadeisDrawerOpen(true);
                        }}
                        label='Section 1 - Top'
                        variant='outlined'
                        required
                        InputProps={{
                          readOnly: true,
                          style: { fontWeight: 'bold' },
                        }}
                        InputLabelProps={{
                          shrink: SectionOneTopShow !== '', // This will move the label up if there's a value
                        }}
                      />
                    ) : (
                      <TextField disabled className='short_textfield formcontrol' label='Section 1 - Top' variant='outlined' />
                    )}
                    {size ? (
                      <TextField
                        className='short_textfield formcontrol'
                        id='filled-basic'
                        value={SectionTwoBottomShow}
                        onClick={() => {}}
                        label='Section 2 - Bottom'
                        variant='outlined'
                        required
                        InputProps={{
                          readOnly: true,
                          style: { fontWeight: 'bold' },
                        }}
                        InputLabelProps={{
                          shrink: SectionTwoBottomShow !== '', // This will move the label up if there's a value
                        }}
                      />
                    ) : (
                      <TextField disabled className='short_textfield formcontrol' label='Section 2 - Bottom' variant='outlined' />
                    )}
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}

            {/* ─ ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ─Shutters Closed─ ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ─ */}
            {/* ─ ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ─Blinds─ ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ─ */}

            {measurementType === 'Blinds' || measurementType === 'Soft Shades' ? <>{measurementType === 'Blinds' ? '' : ''}</> : ''}
            {/* ─ ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ─Blinds closed─ ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ── ─ */}
          </ThemeProvider>
        </Box>
        <hr className='button_top_space button_bottom_space' />
        <FormControlLabel className='default_field_paragraph' sx={{ fontWeight: 'light', color: '#008593', margin: '0px', padding: '0px' }} control={<Switch onChange={setDefaults} style={{ m: 1 }} />} label={`${!hasDefaults ? 'Save as' : 'Update Saved'} Window Set Defaults`} />

        <hr className='button_top_space button_bottom_space' />

        {measurementType === 'Quick' &&
        windowName &&
        size &&
        existingCovering &&
        buildType &&
        (windowdepth === 'Standard' || (windowdepth === 'Other' && othermeasurement)) &&
        (obstructions === 'None' || (obstructions === 'Yes' && description)) &&
        mountsurface &&
        ladder &&
        quantity &&
        !disablebutton &&
        !duplicateWN &&
        !specificDetails ? (
          <Box sx={{ width: '100%' }}>
            <span className='button_span button_bottom_space button_margin_left' style={{ paddingBottom: '0px', marginLeft: '10px !important' }} onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
              <Box onClick={CreateWindow} className='globalIconMainBox globalMarginLeftAddWindow'>
                {isHovered ? <img alt='ADDWINDOWQKH' className='primary_icons_dimension_1' src={WindowHovIcon} /> : <img alt='ADDWINDOWQK' className='primary_icons_dimension_1' src={WindowIconAdd} />}
                <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  {windowqty}
                </Typography>
              </Box>
            </span>
          </Box>
        ) : measurementType === 'Blinds' &&
          windowName &&
          size &&
          existingCovering &&
          buildType &&
          mountsurface &&
          (obstructions === 'None' || (obstructions === 'Yes' && description)) &&
          ladder &&
          (windowdepth === 'Standard' || (windowdepth === 'Other' && othermeasurement)) &&
          headrail &&
          obstructions &&
          !disablebutton &&
          !duplicateWN &&
          !specificDetails &&
          ((headrail === '1 Section' && true) ||
            (headrail === '2 Sections' && section1 && section2) ||
            (headrail === '3 Sections' && section1 && section2 && section3) ||
            (headrail === '4 Sections' && section1 && section2 && section3 && section4) ||
            (headrail === '5 Sections' && section1 && section2 && section3 && section4 && section5) ||
            (headrail === '6 Sections' && section1 && section2 && section3 && section4 && section5 && section6)) ? (
          <Box sx={{ width: '100%' }}>
            <span className='button_span button_bottom_space button_margin_left' style={{ paddingBottom: '0px', marginLeft: '10px !important' }} onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
              <Box onClick={CreateWindow} className='globalIconMainBox globalMarginLeftAddWindow'>
                {isHovered ? <img alt='ADDWINDOWBLH' className='primary_icons_dimension_1' src={WindowHovIcon} /> : <img alt='ADDWINDOWBL' className='primary_icons_dimension_1' src={WindowIconAdd} />}
                <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  {windowqty}
                </Typography>
              </Box>
            </span>
          </Box>
        ) : measurementType === 'Soft Shades' &&
          windowName &&
          size &&
          existingCovering &&
          (obstructions === 'None' || (obstructions === 'Yes' && description)) &&
          buildType &&
          (windowdepth === 'Standard' || (windowdepth === 'Other' && othermeasurement)) &&
          mountsurface &&
          ladder &&
          headrail &&
          !disablebutton &&
          !duplicateWN &&
          !specificDetails &&
          ((headrail === '1 Section' && true) ||
            (headrail === '2 Sections' && section1 && section2) ||
            (headrail === '3 Sections' && section1 && section2 && section3) ||
            (headrail === '4 Sections' && section1 && section2 && section3 && section4) ||
            (headrail === '5 Sections' && section1 && section2 && section3 && section4 && section5) ||
            (headrail === '6 Sections' && section1 && section2 && section3 && section4 && section5 && section6)) ? (
          <Box sx={{ width: '100%' }}>
            <span className='button_span button_bottom_space button_margin_left' style={{ paddingBottom: '0px', marginLeft: '10px !important' }} onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
              <Box onClick={CreateWindow} className='globalIconMainBox globalMarginLeftAddWindow'>
                {isHovered ? <img alt='ADDWINDOWSSH' className='primary_icons_dimension_1' src={WindowHovIcon} /> : <img alt='ADDWINDOWSS' className='primary_icons_dimension_1' src={WindowIconAdd} />}
                <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  {windowqty}
                </Typography>
              </Box>
            </span>
          </Box>
        ) : measurementType === 'Roller Shades' &&
          windowName &&
          size &&
          existingCovering &&
          buildType &&
          (obstructions === 'None' || (obstructions === 'Yes' && description)) &&
          (windowdepth === 'Standard' || (windowdepth === 'Other' && othermeasurement)) &&
          mountsurface &&
          ladder &&
          rolldirection &&
          multiple &&
          !disablebutton &&
          !duplicateWN &&
          !specificDetails &&
          ((headrail === '1 Section' && true) ||
            (headrail === '2 Sections' && section1 && section2) ||
            (headrail === '3 Sections' && section1 && section2 && section3) ||
            (headrail === '4 Sections' && section1 && section2 && section3 && section4) ||
            (headrail === '5 Sections' && section1 && section2 && section3 && section4 && section5) ||
            (headrail === '6 Sections' && section1 && section2 && section3 && section4 && section5 && section6)) ? (
          <Box sx={{ width: '100%' }}>
            <span className='button_span button_bottom_space button_margin_left' style={{ paddingBottom: '0px', marginLeft: '10px !important' }} onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
              <Box onClick={CreateWindow} className='globalIconMainBox globalMarginLeftAddWindow'>
                {isHovered ? <img alt='ADDWINDOWRRH' className='primary_icons_dimension_1' src={WindowHovIcon} /> : <img alt='ADDWINDOWRR' className='primary_icons_dimension_1' src={WindowIconAdd} />}
                <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  {windowqty}
                </Typography>
              </Box>
            </span>
          </Box>
        ) : measurementType === 'Shutters' &&
          windowName &&
          size &&
          (obstructions === 'None' || (obstructions === 'Yes' && description)) &&
          existingCovering &&
          (windowdepth === 'Standard' || (windowdepth === 'Other' && othermeasurement)) &&
          (VerticalConfiguration === '1 Section' || (VerticalConfiguration === '2 Sections' && SectionOneTopShow && SectionTwoBottomShow)) &&
          buildType &&
          mountsurface &&
          ladder &&
          Windowsill &&
          Panel &&
          DeadMold &&
          Buildout &&
          FillerStrip &&
          SplitTilt &&
          SplitRail &&
          TPost &&
          !disablebutton &&
          !duplicateWN &&
          !specificDetails &&
          ((headrail === '1 Section' && true) ||
            (headrail === '2 Sections' && section1 && section2) ||
            (headrail === '3 Sections' && section1 && section2 && section3) ||
            (headrail === '4 Sections' && section1 && section2 && section3 && section4) ||
            (headrail === '5 Sections' && section1 && section2 && section3 && section4 && section5) ||
            (headrail === '6 Sections' && section1 && section2 && section3 && section4 && section5 && section6)) ? (
          <Box sx={{ width: '100%' }}>
            <span className='button_span button_bottom_space button_margin_left' style={{ paddingBottom: '0px', marginLeft: '10px !important' }} onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
              <Box onClick={CreateWindow} className='globalIconMainBox globalMarginLeftAddWindow'>
                {isHovered ? <img alt='ADDWINDOWSHH' className='primary_icons_dimension_1' src={WindowHovIcon} /> : <img alt='ADDWINDOWSH' className='primary_icons_dimension_1' src={WindowIconAdd} />}

                <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  {windowqty}
                </Typography>
              </Box>
            </span>
          </Box>
        ) : (
          <Box sx={{ width: '100%' }}>
            <span style={{ marginLeft: '10px !important' }} className='button_span_disabled button_bottom_space button_top_space button_margin_left'>
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                <img className='primary_icons_dimension_disable' src={WindowIconAdd} alt='ADDWINDOWD' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                  {windowqty}
                </Typography>
              </Box>
            </span>
          </Box>
        )}
        <hr className='button_top_space button_bottom_space' />
        <span
          className='gray_button_span'
          onClick={cancelWindow}
          style={{
            paddingBottom: '30px',
            paddingLeft: '31px',
          }}
        >
          <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered3 ? 'hovered' : ''}`} alt='edit' />
          <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover3} onMouseLeave={handleHover3}>
            CANCEL
          </Typography>
        </span>
      </div>
    </>
  );
};

export default AddWindow;
