/** @format */

import React from 'react';
import pic from '../img/1.png';
import CONFIG from '../config/config.js';
import { useNavigate } from 'react-router-dom';
const SystemInformation = () => {
  return (
    <div align='center'>
      <h2>TBM NextGen SALES App</h2>

      <img
        src={pic}
        style={{
          width: '250px',
          height: '250px',
        }}
        alt='theBlindman'
      />
      <br />

      <h2 align='center'>
        <p>The BlindMan Sale App</p>
        <b>App Version:</b>
        <span>{CONFIG.appVersion}</span>
        <br />

        <b>Build: </b>
        <span>{CONFIG.buildNumber}</span>
        <br />
        <b>App Name: </b>
        <span>{CONFIG.appCodename}</span>
      </h2>
      <br />
    </div>
  );
};

export default SystemInformation;
