/** @format */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import { createTheme, ThemeProvider } from '@mui/material';
import CONFIG from '../../config/config.js';
import uniqid from 'uniqid';
import MuiAlert from '@mui/material/Alert';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import Typography from '@mui/material/Typography';
import SaveCustomerAdd from '../../assets/icons/primary/customer-complete.svg';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import CustomerCheckIcon from '../../assets/icons/primary/WithHovIcon/customer-comp-hov.svg';

//------------------------------------------Radio---------------------------
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const CreateNewCustomer = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');

  const unique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const [getReferredData, setGetReferredData] = useState([]);
  const [putReferredData, setPutReferredData] = useState('');
  const [lastName, setLastName] = useState();
  const [husbandName, setHusbandName] = useState();
  const [wifeName, setWifeName] = useState();
  const [address, setAddress] = useState();
  const [crossStreet, setCrossStreet] = useState();
  const [zipCode, setZipCode] = useState();
  const [State, setState] = useState();
  const [city, setCity] = useState();
  const [email, setEmail] = useState();
  const [description1, setDescription1] = useState();
  const [emailDescription, setemailDescription] = useState();
  const [phoneNumber1, setPhoneNumber1] = useState();
  const [description2, setDescription2] = useState();
  const [phoneNumber2, setPhoneNumber2] = useState();

  // Error Handling
  const [customerCreated, setCustomerCreated] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  //  hover states
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isImageHovered, setIsImageHovered] = useState(false);
  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const [isImageHovered3, setIsImageHovered3] = useState(false);

  //--------------------------------------HandleChange------------------------------------
  const [showerror, setShowError] = useState(false);
  const [showbutton, setshowbutton] = useState(false);
  const [emailerror, setEmailerror] = useState(false);
  const [ziperror, setZiperror] = useState();
  const [refferedOpen, setRefferedOpen] = useState(false);
  const [Zipdata, setZipdata] = useState([]);

  const vertical = 'top';
  const horizontal = 'center';

  const handleHover = () => setIsImageHovered(!isImageHovered);
  const handleHover2 = () => setIsImageHovered2(!isImageHovered2);
  const handleHover3 = () => setIsImageHovered3(!isImageHovered3);
  const handleHoverFun = () => setIsHovered(true);
  const handleMouseLeaveFun = () => setIsHovered(false);
  const handleHoverFun2 = () => setIsHovered2(true);
  const handleMouseLeaveFun2 = () => setIsHovered2(false);

  function phoneFormat(input) {
    input = input.replace(/\D/g, '').substring(0, 10);
    if (input.length > 0) {
      input = '(' + input;
    }
    if (input.length > 3) {
      input = input.slice(0, 4) + ') ' + input.slice(4);
    }
    if (input.length > 6) {
      input = input.slice(0, 9) + '-' + input.slice(9);
    }
    return input;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getReferredByData();
  }, []);

  const getReferredByData = async () => {
    await fetch(CONFIG.API_URL + '/cm/CustomerReferedBy', {
      credentials: 'same-origin',
      method: 'GET',
      withCredentials: true,
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        let ReferedByArr = [];
        data.CustomerReferredBy.recordset.forEach((element) => {
          ReferedByArr.push(element.Method);
        });
        setGetReferredData(ReferedByArr.filter(unique));
      });
  };

  const handleReferedBy = ({ target }) => {
    setPutReferredData(target.value);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setCustomerCreated(false);
  };

  const AddCustomer = async (value) => {
    const response = await fetch(CONFIG.API_URL + '/cm/CreateNewCustomer', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        ReferedBy: putReferredData,
        Lname: lastName,
        Husband: husbandName,
        Wife: wifeName,
        Company: '',
        AddressID: uniqid(),
        Address1: address,
        Address2: crossStreet,
        City: city,
        State: State,
        zip: zipCode,
        County: '',
        EmailAddress: email,
        SaleID: '',
        PersonID: '',
        RefMode: '',
        EmailDescription: emailDescription,
        Description1: description1,
        Pnumber1: phoneNumber1,
        Description2: description2,
        Pnumber2: phoneNumber2,
        ScheduleAppointment: value,
      }),
    });
    const data = await response.json();

    if (!data.error && Zipdata) {
      setCustomerCreated(true);
      setTimeout(() => {
        navigate('/tbm/ps/customerdetails', {
          state: {
            Custdata: data.data,
            ScheduleAppointmentMsg: value ? 'Customer Created & Schedule' : null,
          },
        });
      }, 3_000);
    } else {
      setErrorMessage(data.error_message);
    }
  };

  const AddCustomerAndSchedule = async (value) => {
    const response = await fetch(CONFIG.API_URL + '/cm/CreateNewCustomer', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        ReferedBy: putReferredData,
        Lname: lastName,
        Husband: husbandName,
        Wife: wifeName,
        Company: '',
        AddressID: uniqid(),
        Address1: address,
        Address2: crossStreet,
        City: city,
        State: State,
        zip: zipCode,
        County: '',
        EmailAddress: email,
        SaleID: '',
        PersonID: '',
        RefMode: '',
        Description1: description1,
        Pnumber1: phoneNumber1,
        Description2: description2,
        Pnumber2: phoneNumber2,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error && Zipdata) {
          setCustomerCreated(true);
          setTimeout(() => {
            navigate('/tbm/ps/customerdetails', {
              state: {
                Custdata: data.data,
                ScheduleAppointmentMsg: 'Customer Created & Schedule',
              },
            });
          }, 3_000);
        } else {
          setErrorMessage(data.error_message);
        }
      });
  };

  const handleEmailChange = (e) => {
    const RegexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (e.target.value === '') {
      setTimeout(() => {
        setEmailerror('');
        setShowError(null);
      }, 2000);
      setEmailerror('Email is required..!');
      setShowError(true);
    } else if (!RegexEmail.test(e.target.value)) {
      setEmailerror('Enter Valid Email !');
      setShowError(true);
    } else {
      setEmailerror('Valid Email !');
    }
    setEmail(e.target.value);
  };

  const handleZip = (e) => {
    const CityData = [];

    fetch(`${CONFIG.API_URL}/cm/GetZipCityState`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        zip: e.target.value,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          let mainData = data.CustomerZipCityState;
          mainData.map((item) => {
            if (item.City !== null && item.City !== '' && item.City !== undefined) {
              CityData.push(JSON.stringify(item));
            }
          });
        }
        setZipdata(CityData);
      });

    const RegZip = /^[0-9]{5}$/;
    if (e.target.value === '') {
      setTimeout(() => {
        setZiperror('');
        setShowError(null);
      }, 2000);
      setZiperror('zip is required');
      setShowError(true);
      setZipdata([]);
    } else if (!RegZip.test(e.target.value)) {
      setZiperror('Enter Valid Zip Code !');
      setShowError(true);
      setZipdata([]);
    } else {
      setZiperror(null);
    }
    setZipCode(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    AddCustomer(e.target.outerText === 'SAVE & SCHEDULE APPOINTMENT');
  };

  const handleCity = (e) => {
    setshowbutton(true);
    let data = JSON.parse(e.target.value);
    setCity(data.City);
    setState(data.State);
  };

  const handleCancel = (e) => {
    navigate('/tbm/ps/searchCustomer/Customer');
  };

  return (
    <>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={customerCreated} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setCustomerCreated(false)}>
          <Alert className='Success2 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='Drawer Cancel' />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={DrawerAlertIcon} className='primary_icons_dimension alertImageMargin' alt='DrawerAlertIcon' />
              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Customer Created Successfully..!</div>
            </div>
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={errorMessage} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setErrorMessage()}>
          <Alert className='Success2 mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel_2'>
                <img
                  onClick={() => {
                    setErrorMessage();
                  }}
                  src={DrawerCancelIcon}
                  className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension alertImageMargin' alt='DrawerAlertIcon' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{errorMessage}</div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <form
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '20px',
        }}
      >
        <Typography className='search_customer_heading_1 button_bottom_space'>Add New Customer</Typography>
        <ThemeProvider theme={theme}>
          <FormControl id='ReferredByFC' onClick={() => setRefferedOpen(!refferedOpen)}>
            <Select
              id='ReferredBy'
              className='ADD_CUSTOMER_TEXTFIELD_1 '
              required
              displayEmpty
              value={putReferredData}
              onClose={() => setRefferedOpen(false)}
              onOpen={() => setRefferedOpen(true)}
              open={refferedOpen}
              onChange={(e) => handleReferedBy(e)}
              size='small'
              startAdornment={
                <InputAdornment position='start'>
                  Referred by<span style={{ color: '#DB3131' }}>*&nbsp;</span>:{putReferredData === '' ? '' : ''}
                </InputAdornment>
              }
            >
              <MenuItem value={''}>
                <b>─ ─</b>
              </MenuItem>
              {getReferredData.map((i) => (
                <MenuItem id='ReferredByDropList' key={i} value={i}>
                  <b>{i}</b>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <hr className='button_top_space button_bottom_space' />
          <TextField
            id='LastName'
            required
            value={lastName}
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            className='ADD_CUSTOMER_TEXTFIELD_1'
            label='Last Name / Company '
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            autoComplete='off'
          />
          <TextField
            id='HusbandName'
            className='ADD_CUSTOMER_TEXTFIELD'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            value={husbandName}
            label='Husband Name '
            onChange={(e) => {
              setHusbandName(e.target.value);
            }}
            autoComplete='off'
          />
          <TextField
            id='WifeName'
            className='ADD_CUSTOMER_TEXTFIELD'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            value={wifeName}
            label='Wife Name '
            onChange={(e) => {
              setWifeName(e.target.value);
            }}
            autoComplete='off'
          />
          <TextField
            id='Address'
            className='ADD_CUSTOMER_TEXTFIELD'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            value={address}
            label='Address '
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            autoComplete='off'
          />
          <TextField
            id='Address2'
            className='ADD_CUSTOMER_TEXTFIELD'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            label='Address Line 2'
            value={crossStreet}
            onChange={(e) => {
              setCrossStreet(e.target.value);
            }}
            autoComplete='off'
          />
          <TextField
            id='ZipCode'
            required
            value={zipCode}
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
              },
            }}
            className='ADD_CUSTOMER_TEXTFIELD'
            label='Zip Code'
            onChange={handleZip}
            autoComplete='off'
          />
          {showerror ? <Typography sx={{ color: '#595b5e', fontSize: '13px' }}>{ziperror}</Typography> : null}

          {Zipdata && ziperror === null ? (
            <FormControl>
              {Zipdata.length === 0 ? (
                <FormLabel>Incorrect Zip</FormLabel>
              ) : (
                <FormLabel id='demo-radio-buttons-group-label' required>
                  Choose by city
                </FormLabel>
              )}
              <RadioGroup id='CityRadio' aria-labelledby='demo-radio-buttons-group-label' required defaultValue='female' name='radio-buttons-group'>
                {Zipdata.map((item, index) => {
                  const Name = JSON.parse(item).City;
                  return <FormControlLabel id='CityRadioFCL' key={index} value={item} control={<Radio />} label={Name} onChange={handleCity} sx={{ textTransform: 'capitalize' }} />;
                })}
              </RadioGroup>
            </FormControl>
          ) : null}
          {State ? <TextField id='State' className='ADD_CUSTOMER_TEXTFIELD' disabled label='State' value={State.toUpperCase()} autoComplete='off' /> : null}
          <TextField
            id='EmailDescription'
            className='ADD_CUSTOMER_TEXTFIELD'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            value={emailDescription}
            label='Email Description '
            autoComplete='off'
            onChange={(e) => setemailDescription(e.target.value)}
          />
          <TextField
            id='Email'
            className='ADD_CUSTOMER_TEXTFIELD'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
              },
            }}
            label='Email '
            value={email}
            onChange={handleEmailChange}
            autoComplete='off'
          />
          {emailerror ? (
            <>
              <Box align='left' className=''>
                {showerror && emailerror !== 'Valid Email !' ? (
                  <Grid className='invalid_email'>
                    <ErrorRoundedIcon fontSize='medium' />
                    <Typography style={{ textAlign: 'left' }}>
                      &nbsp;
                      <span style={{ fontSize: '13px' }}>{emailerror}</span>
                    </Typography>
                  </Grid>
                ) : (
                  <Grid className='valid_email'>
                    <Typography style={{ textAlign: 'left' }}>
                      &nbsp;
                      <span style={{ fontSize: '13px' }}>Valid Email</span>
                    </Typography>
                  </Grid>
                )}
              </Box>
            </>
          ) : null}

          <TextField
            id='PhoneNumberDescription'
            className='ADD_CUSTOMER_TEXTFIELD'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            value={description1}
            label='Phone 1 Description'
            onChange={(e) => {
              setDescription1(e.target.value);
            }}
            autoComplete='off'
          />
          <TextField
            id='PhoneNumber'
            className='ADD_CUSTOMER_TEXTFIELD'
            label='Phone Number 1 '
            value={phoneNumber1}
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
              },
            }}
            onChange={(e) => {
              setPhoneNumber1(phoneFormat(e.target.value));
            }}
            autoComplete='off'
          />

          <TextField
            id='PhoneNumberDescription2'
            className='ADD_CUSTOMER_TEXTFIELD'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            label='Phone 2 Description'
            onChange={(e) => {
              setDescription2(e.target.value);
            }}
            value={description2}
            autoComplete='off'
          />
          <TextField
            id='PhoneNumber2'
            className='ADD_CUSTOMER_TEXTFIELD'
            label='Phone Number 2 '
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
              },
            }}
            onChange={(e) => setPhoneNumber2(phoneFormat(e.target.value))}
            value={phoneNumber2}
            autoComplete='off'
          />
          <hr className='button_top_space button_bottom_space' />
          {zipCode !== '' && zipCode !== undefined && zipCode !== null && ziperror === null && putReferredData !== '' && putReferredData !== undefined && putReferredData !== null && lastName !== '' && lastName !== undefined && lastName !== null && showbutton ? (
            <Button id='SaveCustomer' type='submit' value='Save' onClick={handleSubmit} className='search_details_button_1 button_bottom_space' sx={{ padding: '0px 8px !important' }} onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
              {' '}
              <Box className='globalIconMainBox globalTextDecoration'>
                {isHovered ? <img className='primary_icons_dimension_1' src={CustomerCheckIcon} alt='CustomerCheckIcon' /> : <img className='primary_icons_dimension_1' src={SaveCustomerAdd} alt='SaveCustomerAdd' />}
                <Typography id='SaveCustomerTG' sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  {' '}
                  SAVE CUSTOMER
                </Typography>
              </Box>
            </Button>
          ) : (
            <Button
              type='submit'
              className='button_bottom_space'
              disabled
              sx={{
                justifyContent: 'flex-start',
                position: 'relative',
                right: '10px',
                width: 'fit-content',
                padding: '0px 8px !important',
              }}
            >
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                <img className='primary_icons_dimension_disable' src={SaveCustomerAdd} alt='SaveCustomerAdd' />

                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                  SAVE CUSTOMER
                </Typography>
              </Box>
            </Button>
          )}

          {zipCode !== '' && zipCode !== undefined && zipCode !== null && ziperror === null && putReferredData !== '' && putReferredData !== undefined && putReferredData !== null && lastName !== '' && lastName !== undefined && lastName !== null && showbutton ? (
            <Button id='SaveCustomerAndSchedule' type='submit' value='Save & Schedule' sx={{ padding: '0px 8px !important' }} className='search_details_button_1' onClick={handleSubmit} onMouseEnter={handleHoverFun2} onMouseLeave={handleMouseLeaveFun2}>
              <Box className='globalIconMainBox globalTextDecoration'>
                {isHovered2 ? <img className='primary_icons_dimension_1' src={CustomerCheckIcon} alt='CustomerCheckIcon' /> : <img className='primary_icons_dimension_1' src={SaveCustomerAdd} alt='SaveCustomerAdd' />}

                <Typography sx={{ fontFamily: 'RobotoLight', textAlign: 'start' }} className='globalText'>
                  {' '}
                  SAVE & SCHEDULE APPOINTMENT{' '}
                </Typography>
              </Box>
            </Button>
          ) : (
            <Button
              type='submit'
              disabled
              sx={{
                justifyContent: 'flex-start',
                position: 'relative',
                right: '10px',
                padding: '0px 8px !important',
              }}
            >
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                <img className='primary_icons_dimension_disable' src={SaveCustomerAdd} alt='SaveCustomerAdd' />
                <Typography sx={{ fontFamily: 'RobotoLight', textAlign: 'start' }} className='globalTextDisabled'>
                  SAVE & SCHEDULE APPOINTMENT
                </Typography>
              </Box>
            </Button>
          )}
        </ThemeProvider>
      </form>

      <span className='gray_button_span button_top_space' onClick={handleCancel} style={{ paddingBottom: '30px', paddingLeft: '20px' }}>
        <Box className='globalIconMainBox'>
          <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered3 ? 'hovered' : ''}`} alt='CancelIcon' />
          <Typography onMouseEnter={handleHover3} onMouseLeave={handleHover3} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
            CANCEL
          </Typography>
        </Box>
      </span>
    </>
  );
};

export default CreateNewCustomer;
