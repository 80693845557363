import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, TextField, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import CONFIG from '../../config/config.js';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material';
import Grid from '@mui/material/Grid';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import SaveUser from '../../assets/icons/primary/customer.svg';
import SaveUserAdd from '../../assets/icons/primary/customer-complete.svg';
import Avatar from '@mui/material/Avatar';
import CheckIcon from '@mui/icons-material/Check';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';

import CustomerHovIcon from '../../assets/icons/primary/WithHovIcon/customer-comp-hov.svg';

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const AddNewAddress = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let locationState = [];

  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    locationState = location.state;
  }

  useState(() => {}, []);

  const [crossStreet, setCrossStreet] = useState('');
  const [Address, setAddress] = useState('');
  const [City, setCity] = useState('');
  const [State, setState] = useState('');
  const [Zip, setZip] = useState('');
  const [Description, setDescription] = useState('');
  const [errormsg, setErrorMsg] = React.useState(false);
  const [ziperror, setZiperror] = useState();
  const vertical = 'top';
  const horizontal = 'center';

  // error handling states
  const [AddUpdateCustomerAddressErrorMsg, setAddUpdateCustomerAddressErrorMsg] = useState(false);
  const [AddUpdateCustomerAddressErrorMsgTxt, setAddUpdateCustomerAddressErrorMsgTxt] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  //---------------------------------------------------hooks----------------------------------------------------------
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //------------------------------------------------handle-events & functions------------------------------------------------------
  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMsg(false);
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    AddNewAddress();
  };

  const AddNewAddress = async () => {
    const token = sessionStorage.getItem('token');
    await fetch(CONFIG.API_URL + '/cm/AddUpdateCustomerAddress', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        CustID: locationState.Custdata.custId,
        Address: Address,
        CrossStreet: crossStreet,
        City: City,
        State: State,
        Zip: Zip,
        Country: '',
        Description: Description,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.error === true) {
          setAddUpdateCustomerAddressErrorMsgTxt(data.error_message);
          setAddUpdateCustomerAddressErrorMsg(true);
        } else if (!data.error) {
          setErrorMsg(true);
          setTimeout(() => {
            navigate('/tbm/ps/CustomerAddress', {
              state: {
                Custdata: {
                  custid: locationState.Custdata.custId,
                  addressid: locationState.Custdata.addressid,
                },
              },
            });
          }, 3000);
        }
      });
  };

  const handleZipchange = (e) => {
    const RegZip = /^[0-9]{5}$/;
    if (e.target.value === '') {
      setZiperror(null);
    } else if (!RegZip.test(e.target.value)) {
      setZiperror('Invalid Zip Code');
    } else {
      setZiperror('Valid Zip');
    }
    setZip(e.target.value);
  };
  //---------------------------------------------------------------------------------------------------------------------------
  return (
    <div align='left'>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={AddUpdateCustomerAddressErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setAddUpdateCustomerAddressErrorMsg(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img
                onClick={() => {
                  setAddUpdateCustomerAddressErrorMsg(false);
                }}
                src={RedDrawerCancelIcon}
                alt='RedDrawerCancelIcon'
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{AddUpdateCustomerAddressErrorMsgTxt}</div>
            </div>
          </Alert>
        </Snackbar>

        <Snackbar className='snackbar_bar_postition' open={errormsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setErrorMsg(false)}>
          <Alert className='Success2 mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel_2'>
                <img onClick={handleErrorClose} src={DrawerCancelIcon} alt='DrawerCancelIcon' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={DrawerAlertIcon} alt='DrawerAlertIcon' className='primary_icons_dimension' style={{ margin: '0px auto' }} />

                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Customer Address Added Successfully..!</div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <br />

      <Typography className='new_customer_address'>New Customer Address</Typography>
      <ThemeProvider theme={theme}>
        <form style={{ display: 'flex', flexDirection: 'column' }} noValidate autoComplete='off'>
          <TextField
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            className='drawer_textfieldcolor_1 formcontrol text_selectfield_upper'
            label='Description'
            autoComplete='off'
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={Description}
          />
          <TextField
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            className='drawer_textfieldcolor_1 formcontrol text_selectfield_upper'
            label='Address'
            autoComplete='off'
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            value={Address}
          />
          <TextField
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            className='drawer_textfieldcolor_1 formcontrol text_selectfield_upper'
            label='Address Line 2'
            autoComplete='off'
            onChange={(e) => {
              setCrossStreet(e.target.value);
            }}
            value={crossStreet}
          />
          <TextField
            sx={{ input: { color: 'black', fontWeight: 'bold' } }}
            className='drawer_textfieldcolor_1 formcontrol text_selectfield_upper'
            label='City'
            autoComplete='off'
            onChange={(e) => {
              setCity(e.target.value);
            }}
            value={capitalizeFirstLetter(City)}
          />

          <TextField
            sx={{ input: { color: 'black', fontWeight: 'bold' } }}
            className='drawer_textfieldcolor_1 formcontrol text_selectfield_upper'
            label='State'
            autoComplete='off'
            onChange={(e) => {
              setState(e.target.value);
            }}
            value={State.toUpperCase()}
          />
          <TextField sx={{ input: { color: 'black', fontWeight: 'bold' } }} className='drawer_textfieldcolor_2 formcontrol text_selectfield_upper' label='Zip Code' required autoComplete='off' onChange={handleZipchange} value={Zip} />
          {ziperror === undefined ? (
            <></>
          ) : (
            <>
              {' '}
              {ziperror === 'Invalid Zip Code' ? (
                <Grid
                  style={{
                    display: 'flex',
                    color: '#B42A2A',
                    position: 'relative',
                  }}
                >
                  <Typography style={{ textAlign: 'left' }}>
                    &nbsp;
                    <span style={{ fontSize: '13px' }}>{ziperror}</span>
                  </Typography>
                </Grid>
              ) : (
                <Grid
                  style={{
                    display: 'flex',
                    color: 'green',
                    position: 'relative',
                  }}
                >
                  <Typography style={{ textAlign: 'left' }}>
                    &nbsp;
                    <span style={{ fontSize: '13px' }}>{ziperror}</span>
                  </Typography>
                </Grid>
              )}
            </>
          )}

          <hr className='button_top_space button_bottom_space' />
          {Zip !== '' && Zip !== undefined && Zip !== null && ziperror === 'Valid Zip' ? (
            <Box className='submitBox'>
              <Button sx={{ padding: '0px 8px !important' }} type='submit' onClick={handleSubmit} className='search_details_button submit_button_customername' onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
                {' '}
                <Box className='globalIconMainBox globalMarginLeftAddWindow_1'>
                  {isHovered ? <img className='primary_icons_dimension_1' src={CustomerHovIcon} alt='CustomerHovIcon' /> : <img className='primary_icons_dimension_1' src={SaveUserAdd} alt='SaveUserAdd' />}
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                    {' '}
                    ADD NEW ADDRESS
                  </Typography>
                </Box>
              </Button>
            </Box>
          ) : (
            <Box className='submitBox'>
              <Button sx={{ padding: '0px 8px !important' }} type='submit' disabled className='disable_submit_box_button'>
                {' '}
                <Box className='globalIconMainBox globalMarginLeftAddWindow_1'>
                  <img src={SaveUserAdd} className='primary_icons_dimension_disable' alt='SaveUserAdd' />
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                    ADD NEW ADDRESS
                  </Typography>
                </Box>
              </Button>
            </Box>
          )}
        </form>
      </ThemeProvider>

      <span
        onClick={() => {
          navigate(-1);
        }}
        className='gray_button_span button_top_space'
        style={{ paddingBottom: '30px', paddingLeft: '23px' }}
      >
        <Box className='globalIconMainBox'>
          <img src={CancelIcon} className='secondary_icons_dimension' alt='CancelIcon' />
          <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
            CANCEL
          </Typography>
        </Box>
      </span>
    </div>
  );
};

export default AddNewAddress;
