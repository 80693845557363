import { createContext, useReducer } from 'react';
import { Snackbar, Alert as MuiAlert, Button, Container, Fade, Stack, styled } from '@mui/material';
import AlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import ConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import CancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import ConfirmIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';

export const AlertContext = createContext(null);
export const AlertDispatchContext = createContext(null);

const vertical = 'top';
const horizontal = 'center';

const Alert = styled(MuiAlert)(({ theme }) => ({
  backgroundColor: '#008593',
  color: '#fff',
  fontFamily: 'RobotoLight',
  fontSize: '1rem',
}));

export function AlertProvider({ children }) {
  const [alerts, dispatch] = useReducer(alertsReducer, initialAlerts);
  const closeAlert = (event, reason, alert, next) => {
    if (alert.actions && reason === 'clickaway') {
      return;
    }
    if (event) {
      event.stopPropagation();
    }
    dispatch({
      action: 'CLEAR_ALERT',
      payload: {
        id: alert.id,
      },
    });
  };
  return (
    <AlertContext.Provider value={alerts}>
      <AlertDispatchContext.Provider value={dispatch}>
        {alerts.map((alert) => (
          <Snackbar
            key={alert.key}
            open={alert.open}
            anchorOrigin={{ vertical, horizontal }}
            sx={{
              width: 325,
              marginTop: '100px',
              margin: '100px auto 0',
              padding: '1rem',
            }}
            autoHideDuration={alert.autoHideDuration}
            TransitionComponent={Fade}
            onClose={(event, reason) => closeAlert(event, reason, alert)}
          >
            <Alert icon={false} sx={{ padding: '8px 16px 27px' }} severity={alert.severity}>
              <Stack
                spacing={0}
                justifyContent='end'
                sx={{
                  flexDirection: 'row',
                  fontSize: '1rem',
                  opacity: '0.4',
                  '&:hover': {
                    opacity: '1',
                  },
                }}
              >
                <img
                  onClick={() => dispatch({ action: 'CLEAR_ALERT', payload: { id: alert.id } })}
                  src={alert.severity === 'error' ? RedDrawerCancelIcon : CancelIcon}
                  style={{
                    cursor: 'pointer',
                  }}
                  className='drawer_secondary_icons_dimension'
                />
              </Stack>
              <Stack textAlign='center' spacing={1} sx={{ fontSize: '1rem', margin: '0 19px' }}>
                <img src={AlertIcon} className='alert-main-icon' />
                {alert.message}
                {alert.actions ? (
                  <Stack
                    spacing={2}
                    sx={{
                      marginTop: '2rem !important',
                    }}
                  >
                    {alert.actions.map((action) => (
                      <Button
                        size='small'
                        sx={{
                          paddingLeft: action.type !== 'primary' ? '20px' : '',
                          justifyContent: 'left',
                          fontFamily: 'RobotoLight',
                          color: 'white',
                          fontSize: action.type !== 'primary' ? '1rem' : '1.25rem',
                          ':disabled': {
                            color: 'rgba(0, 133, 147, .26)',
                          },
                        }}
                        onClick={action.onClick}
                        startIcon={action.startIcon}
                      >
                        {action.label}
                      </Button>
                    ))}
                  </Stack>
                ) : null}
              </Stack>
            </Alert>
          </Snackbar>
        ))}
        {children}
      </AlertDispatchContext.Provider>
    </AlertContext.Provider>
  );
}

export default AlertProvider;

const initialAlerts = [];
const defaultAlertSettings = {
  open: true,
  autoHideDuration: 5_000,
  actionable: false,
};

const alertsReducer = (state, { action, payload }) => {
  switch (action) {
    case 'OPEN_ALERT':
      return [
        ...state,
        {
          ...defaultAlertSettings,
          ...payload,
        },
      ];
    case 'CLEAR_ALERT':
      return state.filter(({ id }) => id !== payload.id);
    default:
      return state;
  }
};
