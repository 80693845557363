import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import CONFIG from '../../config/config.js';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import { createTheme, ThemeProvider } from '@mui/material';
import SaveCustomerAdd from '../../assets/icons/primary/customer-complete.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import CustomerHovIcon from '../../assets/icons//primary/WithHovIcon/customer-comp-hov.svg';

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
export default function EditCustomerEmail() {
  const location = useLocation();
  const navigate = useNavigate();

  let locationState = [];

  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    locationState = location.state;
  }

  const vertical = 'top';
  const horizontal = 'center';
  const [errormsg, setErrorMsg] = React.useState(false);
  const [showerror, setShowError] = useState(false);
  const [emailerror, setEmailerror] = useState(false);
  const [email, setEmail] = useState(locationState.email_id);
  const [description, setDescription] = useState(locationState.email_Type || 'Email');

  // error handling states
  const [errorAlert, seterrorAlert] = useState(false);
  const [errorAlertTxt, seterrorAlertTxt] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMsg(false);
    seterrorAlert(false);
  };
  const handleCancel = (e) => {
    navigate('/tbm/ps/customerdetails', {
      state: {
        Custdata: {
          custid: locationState.custid,
          Addressid: locationState.addressid,
        },
      },
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    UpdateEmailAddress();
  };

  const handleEmailChange = (e) => {
    const RegexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (e.target.value === '') {
      setTimeout(() => {
        setEmailerror(null);
        setShowError(null);
      }, 2000);
      setEmailerror('Email is required..!');
      setShowError(false);
    } else if (!RegexEmail.test(e.target.value)) {
      setEmailerror('Enter Valid Email !');
      setShowError(false);
    } else {
      setEmailerror('Valid Email !');
      setShowError(true);
    }
    setEmail(e.target.value);
  };
  const handleDiscription = (e) => {
    if (emailerror === 'Enter Valid Email !') {
      setShowError(false);
    } else {
      setShowError(true);
    }
    setDescription(e.target.value);
  };

  const UpdateEmailAddress = async () => {
    const token = sessionStorage.getItem('token');
    await fetch(CONFIG.API_URL + '/cm/UpdateCustomerEmail', {
      method: 'PUT',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        customer_id: locationState.custid,
        email: email,
        description: description,
        customer_email_ids_id: locationState.email_ids_id,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.error === true) {
          seterrorAlertTxt(data.error_message);

          seterrorAlert(true);
        } else if (!data.error) {
          setErrorMsg(true);
          setTimeout(() => {
            navigate('/tbm/ps/customerdetails', {
              state: {
                Custdata: {
                  custid: locationState.custid,
                  Addressid: locationState.addressid,
                },
              },
            });
          }, 3000);
        }
      });
  };
  useEffect(() => {
    {
      window.scrollTo(0, 0);
    }
  }, []);

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  return (
    <div align='left'>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={errormsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setErrorMsg(false)}>
          <Alert className='Success2 mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel_2'>
                <img onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' style={{ margin: '0px auto' }} />

                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Customer Email Updated Successfully..!</div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={errorAlert} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => seterrorAlert(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel_2'>
                <img onClick={handleErrorClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='RedDrawerCancelIcon' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' alt='RedExclamationIcon' />

                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{errorAlertTxt}</div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <br />
      <Typography sx={{ fontWeight: 'bold', marginBottom: '20px' }}>Edit Customer Email</Typography>
      <ThemeProvider theme={theme}>
        <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit}>
          <TextField
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            className='drawer_textfieldcolor_1 formcontrol text_selectfield_upper'
            label='Description'
            value={description}
            onChange={handleDiscription}
            autoComplete='off'
          />
          <TextField sx={{ input: { color: 'black', fontWeight: 'bold' } }} className='drawer_textfieldcolor_2 formcontrol text_selectfield_upper' label='Email' value={email} onChange={handleEmailChange} autoComplete='off' required />
          {emailerror ? (
            <>
              <Box className='button_top_space' align='left'>
                {emailerror !== 'Valid Email !' ? (
                  <Grid
                    style={{
                      display: 'flex',
                      color: '#B42A2A',
                      position: 'relative',
                    }}
                  >
                    <ErrorRoundedIcon fontSize='medium' />
                    <Typography style={{ textAlign: 'left' }}>
                      &nbsp;
                      <span style={{ fontSize: '13px' }}>{emailerror}</span>
                    </Typography>
                  </Grid>
                ) : (
                  <Grid
                    style={{
                      display: 'flex',
                      color: 'Green',
                      position: 'relative',
                    }}
                  >
                    <Typography style={{ textAlign: 'left' }}>
                      &nbsp;
                      <span style={{ fontSize: '13px' }}>Valid Email</span>
                    </Typography>
                  </Grid>
                )}
              </Box>
            </>
          ) : null}
          <hr className='button_top_space button_bottom_space' />

          {showerror && emailerror !== null ? (
            <Box className='submitBox'>
              <Button
                type='submit'
                className='search_details_button button_bottom_space'
                sx={{
                  justifyContent: 'flex-start',
                  position: 'relative',
                  right: '10px',
                  float: 'left',
                  padding: '0px 8px !important',
                }}
                onMouseEnter={handleHoverFun}
                onMouseLeave={handleMouseLeaveFun}
              >
                {' '}
                <Box className='globalIconMainBox globalDrawerText globalMarginLeftAddWindow_1'>
                  {isHovered ? <img className='primary_icons_dimension_1' src={CustomerHovIcon} alt='CustomerHovIcon' /> : <img className='primary_icons_dimension_1' src={SaveCustomerAdd} alt='SaveCustomerAdd' />}
                  <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                    {' '}
                    SAVE CUSTOMER EMAIL
                  </Typography>
                </Box>
              </Button>
            </Box>
          ) : (
            <Box className='submitBox'>
              <Button
                type='submit'
                disabled
                className='button_bottom_space'
                sx={{
                  justifyContent: 'flex-start',
                  position: 'relative',
                  right: '10px',
                  float: 'left',
                  padding: '0px 8px !important',
                }}
              >
                {' '}
                <Box className='globalIconMainBox globalMarginLeftAddWindow_1'>
                  <img className='primary_icons_dimension_disable' src={SaveCustomerAdd} al='SaveCustomerAdd' />

                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                    SAVE CUSTOMER EMAIL
                  </Typography>
                </Box>
              </Button>
            </Box>
          )}

          <span className='gray_button_span' onClick={handleCancel} style={{ paddingBottom: '30px', paddingLeft: '23px' }}>
            <Box onClick={() => handleCancel()} className='globalIconMainBox'>
              <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered2 ? 'hovered' : ''}`} alt='CancelIcon' />

              <Typography onMouseEnter={handleHover2} onMouseLeave={handleHover2} onClick={() => handleCancel()} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                CANCEL
              </Typography>
            </Box>
          </span>
        </form>
      </ThemeProvider>
    </div>
  );
}
