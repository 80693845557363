/** @format */

import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import DrawerConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import CONFIG from '../../config/config.js';
import WindowStepperIcon from '../../assets/icons/primary/window.svg';
import RemoveIcon from '@mui/icons-material/Remove';
import Stack from '@mui/material/Stack';
import { useSnackbar, SnackbarProvider } from 'notistack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useCookies } from 'react-cookie';
import Avatar from '@mui/material/Avatar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import fracty from 'fracty';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import DeleteWindowIcon from '../../assets/icons/primary/window-delete.svg';
import AddWindowIcon from '../../assets/icons/primary/window.svg';
import AddWindowIcon2 from '../../assets/icons/primary/window-delete.svg';
import AddWindowHovIcon from '../../assets/icons/primary/WithHovIcon/window-delete-hov.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import { TablePagination } from '@mui/material';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

let checkedrow = [];

export default function DeleteProposalDetails(props) {
  const [checked, setChecked] = React.useState([]);
  const [WindowSetData, setWindowSetData] = useState([]);
  const [proposalName, setProposalName] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(CONFIG.WindowsManagement.RowperPageLimit);
  const [open, setOpen] = React.useState(false);
  const [deleteDisable, setDeleteDisable] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [roomLabel, setRoomLabel] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [chooseWindowName, setChooseWindowName] = React.useState('');
  const [checkvalidate, setCheckValidation] = React.useState(true);

  // error handling states

  const [RemoveProposalWindowErrorAlert, setRemoveProposalWindowErrorAlert] = useState(false);
  const [RemoveProposalWindowErrorMsg, setRemoveProposalWindowErrorMsg] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };
  const handleHoverFun2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeaveFun2 = () => {
    setIsHovered2(false);
  };

  let commonData = {};
  const vertical = 'top';
  const horizontal = 'center';
  const windowname = [];
  let dialof_window_name;
  const outerTheme = createTheme({
    palette: {
      primary: {
        main: '#333333',
      },
    },
  });
  const deleteButtonEnable = () => {
    setDeleteItem(true);
  };

  const enableDeleteButton = () => {
    setDeleteDisable(!deleteDisable);
  };

  const handleClickOpen = () => {
    setDeleteItem(true);
  };

  const handleClose = () => {
    setDeleteItem(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  let locationState = [];
  var selectedrow = [];

  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    locationState = location.state;
    selectedrow = locationState.selectedwindows;
  }

  const [cookies, setCookie] = useCookies(['token']);

  var updatedrow = [];

  selectedrow.forEach((element) => {
    if (element.covering_status !== 'not_consider') updatedrow.push(element);
  });

  useEffect(() => {
    {
      window.scrollTo(0, 0);
    }
    getVerified();
    setProposalName(locationState.proposalName);
  }, []);

  const rows = updatedrow;
  let jsonData = new Array();

  let ShowWindowName = '';
  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };
  const handleChange = (event, i, row) => {
    setRoomLabel(row.window_name);

    if (event.target.checked === true) {
      checkedrow.push(row);

      if (checkedrow.length === updatedrow.length) {
        setOpen(true);
      }
      setSelected(checkedrow);
      checkedrow.map((element, i) => {
        dialof_window_name = element.window_name;

        jsonData.push(' ' + dialof_window_name);
        ShowWindowName = jsonData.toString();

        setChooseWindowName(ShowWindowName);
      });
    } else {
      const selectedIndex = selected.indexOf(row);

      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
        setSelected(newSelected);

        // remove windowname
        newSelected.map((element, i) => {
          dialof_window_name = element.window_name;

          jsonData.push(' ' + dialof_window_name);
          ShowWindowName = jsonData.toString();
          setChooseWindowName(ShowWindowName);
        });
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));

        setSelected(newSelected);
        // remove windowname
        newSelected.map((element, i) => {
          dialof_window_name = element.window_name;

          jsonData.push(' ' + dialof_window_name);
          ShowWindowName = jsonData.toString();
          setChooseWindowName(ShowWindowName);
        });
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        setSelected(newSelected);

        // remove windowname
        newSelected.map((element, i) => {
          dialof_window_name = element.window_name;

          jsonData.push(' ' + dialof_window_name);
          ShowWindowName = jsonData.toString();
          setChooseWindowName(ShowWindowName);
        });
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        setSelected(newSelected);

        // remove windowname
        newSelected.map((element, i) => {
          dialof_window_name = element.window_name;

          jsonData.push(' ' + dialof_window_name);
          ShowWindowName = jsonData.toString();
          setChooseWindowName(ShowWindowName);
        });
      } else {
      }

      checkedrow = newSelected;

      if (newSelected.length === 0) {
        checkedrow = [];
      }
    }
  };

  let checkedrowdata = [];
  let selectedwindowsIDs = [];
  selectedwindowsIDs = locationState.selectedwindows;
  checkedrowdata = selectedwindowsIDs;
  let totalrowdata = [];
  totalrowdata = locationState.totalselectedwindows;
  let windowsetdata = [];
  windowsetdata = locationState.customerdata;
  let result = [];
  commonData['AppliedCoveringsWindows'] = locationState.selectedwindows;
  commonData['path'] = locationState.path;
  commonData['windowsetdata'] = windowsetdata;
  commonData['proposalID'] = locationState.proposalID;

  const RemoveWindows = async () => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: locationState.proposalID,
        windows: checkedrow,
      }),
    };
    // get proposal details
    await fetch(`${CONFIG.API_URL}/pm/RemoveProposalWindow`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          navigateNextPage();
        } else {
          setRemoveProposalWindowErrorMsg(data.error_message);
          setRemoveProposalWindowErrorAlert(true);
        }
      });
  };

  const clearWindowName = () => {
    setChooseWindowName('');
    checkedrow = [];
  };

  const navigateNextPage = () => {
    setChooseWindowName('');
    checkedrow = [];
    navigate(`/tbm/ps/proposal/${params.id}/details`, {
      state: {
        selectedwindows: selectedwindowsIDs,
        customerdata: windowsetdata,
        unchecked: result,
        proposalID: locationState.proposalID,
        proposalName: locationState.proposalName,
      },
    });
  };

  // table head start

  let isItemSelected;
  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='table-headerps sticky-columnws-2 font_weight_bold'>
            {' '}
            <span style={{ paddingLeft: '32px' }}>#</span> <span style={{ paddingLeft: '14px' }}>Window Name</span>
          </TableCell>
          <TableCell className='table-headerpd_size font_weight_bold'>Size</TableCell>
          <TableCell className='table-headerpd_price font_weight_bold'>Retail</TableCell>
          <TableCell className='table-headerpd_price font_weight_bold'>Install</TableCell>
          <TableCell className='table-headerpd_price font_weight_bold'>Total</TableCell>
          <TableCell className='small_header font_weight_bold'>Bld</TableCell>
          <TableCell className='table-header0 font_weight_bold'>Covering System</TableCell>
          <TableCell className='table-headerpd_price font_weight_bold'>Note</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  // table head end

  const LongText = ({ content }) => {
    const [limit, setLimit] = useState(CONFIG.GlobalTruncateCharacterLimit);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    if (content === undefined) {
      // Data is undefined, return empty
      return null;
    }

    // Check if the window width is between 280px and 768px and content length is greater than 14
    if (windowWidth >= 280 && windowWidth <= 767 && content.length > 15) {
      const truncatedContent = content.substring(0, 15) + '...';
      return <span>{truncatedContent}</span>;
    }

    // Check if the window width is above or equal to 768
    if (windowWidth >= 768) {
      if (content.length <= limit) {
        // There is nothing more to show
        return <span>{content}</span>;
      }

      let truncatedContent = content.substring(0, limit);

      // Find the last space within the truncated content
      const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

      // Adjust the truncated content if a space was found
      if (lastSpaceIndex !== -1) {
        truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
      }

      // Add the ellipsis to the truncated content
      const toShow = truncatedContent + '...';

      return <span>{toShow}</span>;
    }

    if (content.length <= limit) {
      // There is nothing more to show
      return <span>{content}</span>;
    }

    let truncatedContent = content.substring(0, limit);

    // Find the last space within the truncated content
    const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

    // Adjust the truncated content if a space was found
    if (lastSpaceIndex !== -1) {
      truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
    }

    // Add the ellipsis to the truncated content
    const toShow = truncatedContent + '...';

    return <span>{toShow}</span>;
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <ThemeProvider theme={outerTheme}>
        <div align='center'>
          <Stack spacing={2} sx={{ width: '100%' }}>
            {/* RemoveProposalWindowErrorAlert */}
            <Snackbar
              className='snackbar_bar_postition'
              open={RemoveProposalWindowErrorAlert}
              anchorOrigin={{ vertical, horizontal }}
              // autoHideDuration={3000}
              onClose={() => setRemoveProposalWindowErrorAlert(false)}
            >
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setRemoveProposalWindowErrorAlert(false);
                    }}
                    src={RedDrawerCancelIcon}
                    alt='CANCELICONRPWA'
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img src={RedExclamationIcon} alt='CONFIRMICONRPWA' className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{RemoveProposalWindowErrorMsg}</div>
                </div>
              </Alert>
            </Snackbar>

            <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setOpen(false)}>
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img alt='CANCELICONRED' onClick={handleSuccessClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img alt='CONFIRMICONRED' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />

                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Proposal cannot have an empty windows.</div>
                </div>
              </Alert>
            </Snackbar>
          </Stack>

          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={deleteItem} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
              <Alert className='Success mainAlert' variant='filled' icon={false}>
                <div className='alertCancel' align='right'>
                  <img alt='CANCELICONDW' onClick={handleClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                </div>

                <div className='alertMainNew'>
                  <div align='left' style={{ paddingTop: 37 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'Center',
                      }}
                    >
                      <Box className='alertCantinueButton'>
                        <img alt='CONFIRMICONDW' src={DrawerAlertIcon} className='primary_icons_dimension' />
                      </Box>
                    </Box>

                    <div
                      className='alertTextAlign'
                      style={{
                        paddingTop: 20,
                        fontSize: '20px',
                        fontFamily: 'RobotoRegular',
                      }}
                    >
                      Are you sure you want to remove
                    </div>
                  </div>

                  <div
                    align='left'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '20px 10px 0px 24px',
                      justifyContent: 'center',
                      alignItems: 'start',
                      lineHeight: '24px',
                    }}
                  >
                    <Box
                      onClick={() => {
                        handleClose();
                        RemoveWindows();
                      }}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'Center',
                        cursor: 'pointer',
                        marginBottom: '20px',
                      }}
                    >
                      <Box className='alertCantinueButton'>
                        <img alt='CONTINUE' src={DrawerConinueIcon} className='primary_icons_dimension' />
                      </Box>
                      <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                        CONTINUE
                      </Typography>
                    </Box>

                    <Box
                      onClick={handleClose}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'Center',
                        cursor: 'pointer',
                        paddingBottom: '16px',
                        paddingLeft: '15px',
                      }}
                    >
                      <Box className='globalIconMainBox'>
                        <Box className='iconSecondaryButtonGlobalForDrawer'>
                          <img alt='CANCELICONDW' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                        </Box>
                        <Typography className='globalTextForDrawer'>CANCEL</Typography>
                      </Box>
                    </Box>
                  </div>
                </div>
              </Alert>
            </Snackbar>
          </Stack>
        </div>

        <Box className='button_top_space stepper_prompt_margin_bottom removeProposalWindowPromptMainBox'>
          <Stack direction='row'>
            <Box>
              <Stack direction='row' onMouseEnter={handleHoverFun2} onMouseLeave={handleMouseLeaveFun2}>
                <img alt='ADDWINDOICON' className='primary_icons_dimension_1 stepperImageMargins' src={AddWindowIcon2} />
              </Stack>
            </Box>
            <Box sx={{ borderLeft: '1px solid #D6D6D6' }} className='removeProposalWindowPromptMainBox'>
              <Typography className='stepperMainTextSize stepperSubTextStyle'>Select Windows to Remove from Proposal</Typography>
            </Box>
          </Stack>
        </Box>

        <hr />
        <Box className='proposal_table_main_box'>
          <Box className='proposal_table_sub_box'>
            {/* table start  */}

            <Box className='table-box'>
              <TableContainer sx={{ borderBottom: '1px solid #D6D6D6', textAlign: 'left' }} className='table_padding_bottom'>
                <Table className='proposal_table' aria-labelledby='tableTitle' size='small'>
                  <EnhancedTableHead rowCount={rows.length} />
                  <TableBody>
                    {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row) => {
                      return (
                        <TableRow hover role='checkbox' tabIndex={-1} key={row.window_id} style={{ padding: 0 }}>
                          {row.covering_status === 'not_applied' ? (
                            <>
                              <TableCell
                                sx={{
                                  color: 'red',
                                  fontSize: '100%',
                                  borderBottom: 'none',
                                  display: 'flex',
                                  width: '100%',
                                }}
                                className='table-content sticky-column'
                                align='left'
                              >
                                <Box sx={{ display: 'flex' }}>
                                  <Checkbox
                                    sx={{
                                      zoom: 0.8,
                                      paddingTop: '1px',
                                    }}
                                    checked={checked[WindowSetData.indexOf(row) + 1]}
                                    onChange={(e) => {
                                      handleChange(e, WindowSetData.indexOf(row) + 1, row);
                                    }}
                                    onClick={enableDeleteButton}
                                  />
                                  <Box sx={{ width: '20px', color: 'black' }}>{row.covering_status !== 'not_consider' ? <>{rows.indexOf(row) + 1}</> : ''}</Box>
                                  &nbsp;&nbsp;
                                  <span className='AddingsalesOrderWindowName'>
                                    <LongText content={row.window_name} />
                                  </span>
                                </Box>
                              </TableCell>
                              <TableCell sx={{ color: 'red', whiteSpace: 'nowrap' }} className='table-content' align='left'>
                                <>
                                  {fracty(row.proposal_window_width)}" X {fracty(row.proposal_window_height)}"
                                </>
                              </TableCell>
                              <TableCell sx={{ color: 'red' }} className='small_cell' align='left'>
                                ─ ─
                              </TableCell>
                              <TableCell sx={{ color: 'red' }} className='small_cell' align='left'>
                                <>─ ─</>
                              </TableCell>{' '}
                              <TableCell sx={{ color: 'red' }} className='small_cell' align='left'>
                                <>─ ─</>
                              </TableCell>{' '}
                              <TableCell sx={{ color: 'red' }} className='small_cell' align='left'>
                                <>─ ─</>
                              </TableCell>{' '}
                              <TableCell sx={{ color: 'red' }} className='small_cell' align='left'>
                                <>─ ─</>
                              </TableCell>{' '}
                              <TableCell sx={{ color: 'red' }} className='small_cell' align='left'>
                                <>─ ─</>
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell sx={{ color: '#008593' }} className='table-content sticky-column' align='left'>
                                <Box sx={{ display: 'flex' }}>
                                  <Checkbox
                                    sx={{
                                      zoom: 0.8,
                                      paddingTop: '1px',
                                    }}
                                    checked={checked[WindowSetData.indexOf(row) + 1]}
                                    onChange={(e) => {
                                      handleChange(e, WindowSetData.indexOf(row) + 1, row);
                                    }}
                                    onClick={enableDeleteButton}
                                  />
                                  <Box sx={{ width: '20px', color: 'black' }}>{row.covering_status !== 'not_consider' ? <>{rows.indexOf(row) + 1}</> : ''}</Box>
                                  &nbsp;&nbsp;
                                  <span className='AddingsalesOrderWindowName'>
                                    <LongText content={row.window_name} />
                                  </span>
                                </Box>
                              </TableCell>
                              <TableCell className='table-content' align='left'>
                                <>
                                  {fracty(row.proposal_window_width)}" X {fracty(row.proposal_window_height)}"
                                  <span className={row.proposal_measurement_type.replace(/ /g, '')}>
                                    {row.proposal_measurement_type === 'Quick'
                                      ? '(' + 'QK' + ')'
                                      : row.proposal_measurement_type === 'Shutters'
                                      ? '(' + 'SH' + ')'
                                      : row.proposal_measurement_type === 'Blinds'
                                      ? '(' + 'BL' + ')'
                                      : '(' +
                                        row.proposal_measurement_type
                                          .split(' ')
                                          .map((word) => word[0])
                                          .join('') +
                                        ')'}
                                  </span>{' '}
                                </>
                              </TableCell>
                              <TableCell className='table-content' align='left'>
                                <>${row.suggested_price}</>
                              </TableCell>
                              <TableCell className='table-content-after-material' align='left'>
                                <>${row.covering_install_price}</>
                              </TableCell>
                              <TableCell className='table-content-after-material' align='left'>
                                <>${row.proposal_total_with_install}</>
                              </TableCell>
                              <TableCell className='small_cell' align='left'>
                                <>&nbsp;&nbsp;{row.build_type[0]}</>
                              </TableCell>
                              <TableCell className='table-content-second2' align='left'>
                                <>
                                  {row.material_code.toString().replace(/,/g, ', ')}, {row.build_type}, {row.existing_covering}, {row.mount_surface}, {row.ladder}
                                </>
                              </TableCell>
                              <TableCell className='table-content-after-material small_cell' align='left'>
                                &nbsp;&nbsp; {row.notes_count < 1 ? '' : row.notes_count}
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  <TablePagination className='search_table_dimension_for_pagination' rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]} count={rows.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                </Table>
              </TableContainer>
            </Box>

            {/* table end  */}
          </Box>
        </Box>

        <>
          {checkedrow.length > 0 && checkedrow.length !== updatedrow.length && checkvalidate ? (
            <Box display='flex' alignItems='center' justifyContent='center'>
              <span className='button_span button_top_space button_bottom_space' onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
                <Box
                  onClick={() => {
                    handleClickOpen();
                    deleteButtonEnable();
                  }}
                  className='globalIconMainBox globalMarginLeftAddWindow'
                >
                  {isHovered ? <img alt='REMOVEWINDOWSH' className='primary_icons_dimension_1' src={AddWindowHovIcon} /> : <img alt='REMOVEWINDOWS' className='primary_icons_dimension_1' src={AddWindowIcon2} />}
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                    REMOVE WINDOWS
                  </Typography>
                </Box>
              </span>
            </Box>
          ) : (
            <Box display='flex' alignItems='center' justifyContent='center'>
              <span className='button_span button_top_space button_bottom_space'>
                <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                  <img alt='REMOVEWINDOWSD' src={DeleteWindowIcon} className='primary_icons_dimension_disable' />

                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                    REMOVE WINDOWS
                  </Typography>
                </Box>
              </span>
            </Box>
          )}
          <hr />
          <Box display='flex' alignItems='center' justifyContent='center' className='apply_Changes_Box'>
            <span
              style={{ marginLeft: '19px', paddingBottom: '30px' }}
              className='gray_button_span button_top_space'
              onClick={() => {
                clearWindowName();
                navigateNextPage();
              }}
            >
              <Box className='globalIconMainBox'>
                <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered2 ? 'hovered' : ''}`} alt='cancel' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover2} onMouseLeave={handleHover2}>
                  CANCEL
                </Typography>
              </Box>
            </span>
          </Box>
        </>
      </ThemeProvider>
    </>
  );
}
