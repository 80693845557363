import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PDFDownloadLink, PDFViewer, StyleSheet } from '@react-pdf/renderer';
import DownloadIcon from '@mui/icons-material/Download';
import { createTheme, Button } from '@mui/material';

import CONFIG from '../../config/config.js';
import Pdf from './Pdf';

let filename;

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
});

const PdfPage = () => {
  const token = sessionStorage.getItem('token');
  const location = useLocation();

  const [file, setFile] = useState('');
  const [content, setContent] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  useEffect(() => {
    getData();
  }, [content]);

  useEffect(() => {
    if (file) {
      uploadFile(file);
    }
  }, [file]);

  const uploadFile = (file) => {
    const blobToBase64 = (blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      return new Promise((resolve) => (reader.onloadend = () => resolve(reader.result)));
    };

    if (file) {
      setFile(file);
      blobToBase64(file).then((res) => setContent(res));
    }
  };

  const download = {
    textDecoration: 'none',
    fontfamily: 'Roboto-Light.woff',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2,
    paddingRight: 2,
    marginTop: '4%',
    marginBottom: '2%',
    fontSize: '100%',
    backgroundColor: '#008593',
    borderRadius: '25px',
    '&:hover': {
      backgroundColor: '#008593',
      textDecoration: 'none',
    },
  };

  const getData = async () => {
    let id = location.state.data.proposalID;

    const requestOptions = {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/Proposal/${id}`, requestOptions);
    const data = await response.json();

    if (response.ok && !data.error) {
    }
  };

  return isMobile ? (
    <div style={{ textAlign: 'center' }}>
      <span> Download Proposal by clicking the below button</span>
      <br />
      <PDFDownloadLink style={{ textDecoration: 'none' }} document={<Pdf data={location.state.data} />} fileName={filename}>
        {({ blob, url, loading, error }) =>
          loading ? (
            'Loading...'
          ) : (
            <Button sx={download} variant='contained' size='large' startIcon={<DownloadIcon />}>
              {' '}
              Download Proposal
            </Button>
          )
        }
      </PDFDownloadLink>
    </div>
  ) : (
    <PDFViewer
      style={{
        width: styles.viewer.width,
        height: styles.viewer.height,
        marginLeft: '-14%',
        marginRight: '-14%',
      }}
    >
      <Pdf data={location.state.data} />
    </PDFViewer>
  );
};
export default PdfPage;
