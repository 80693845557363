import React from "react";
import font from "../../../font/Roboto-Light.woff";

import { Font, StyleSheet, View, Text } from "@react-pdf/renderer";
import CTWTabledata from "./CTSOTabledata";

Font.register({
  family: "RobotoLight",
  src: font,
});

// Create styles
const styles = StyleSheet.create({
  section_row: {
    flexDirection: "row",
    paddingTop: 5,
    paddingLeft: 10,
    width: "100%",
  },
  section_col: {
    flexDirection: "col",
  },

  image: {
    width: 75,
    height: 70,
    border: "1px solid #000",
  },
  headerverticalline: {
    borderLeft: "1.1px solid #9AE2E3",
    height: "60px",
    marginTop: 8,
    marginLeft: 10,
    marginRight: 10,
  },
  text: {
    paddingTop: 10,
    paddingLeft: 5,
    fontFamily: "RobotoLight",
    fontSize: "22px",
    color: "#FFFBEF",
    textAlign: "justify",
  },
  subtext: {
    paddingLeft: 5,
    fontFamily: "RobotoLight",
    fontSize: "14px",
    color: "#FFFBEF",
    textAlign: "justify",
  },
  headingText: {
    fontSize: 8,
    padding: 2,
  },
  row: {
    flexDirection: "row",
    paddingTop: 10,
  },
  left: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 340,
  },
  right: {
    padding: 5,
    flexShrink: 1,
    flexGrow: 2,
  },
  rowd: {
    flexDirection: "row",
  },
  leftd: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 350,
  },
  rightd: {
    padding: 5,
    flexShrink: 1,
    flexGrow: 2,
  },
});

const CTSOBody = (props) => (
  <View style={styles.section_row_body}>
    <CTWTabledata { ...props } />
  </View>
);

export default CTSOBody;
