import React from "react";
import { Grid } from "@mui/material";

import CustomerDetails from "./CustomerDetails";
import MarketingMessage from "./MarketingMessage";

const SubHeader = ({ date, customer, proposal, customStyles }) => {
  return (
    <Grid container direction="row" style={customStyles.section_row_1st}>
      <CustomerDetails
        date={date}
        customer={customer}
        proposal={proposal}
        customStyles={customStyles}
      />
      <Grid md={1} />
      <MarketingMessage customStyles={customStyles} />
    </Grid>
  );
};
export default SubHeader;
