/** @format */

import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import Drawer from '@mui/material/Drawer';
import DrawerInput from './DrawerInput';
import { makeStyles } from '@mui/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { createTheme, ThemeProvider } from '@mui/material';
import CONFIG from '../../config/config.js';
import { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import numericQuantity from 'numeric-quantity';

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});

// Style theme to make background blur and transparent
const useStyles = makeStyles({
  paper: {
    width: '90%',
    backdropFilter: 'blur(10px) !important',
    background: 'rgba(69, 157, 166, 0.73)  !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

const min = 1;
const max = 20;

//Mout Surface Select Options
const MS = [
  {
    value: 'n/a',
    label: 'N/A',
  },
  {
    value: 'Tile',
    label: 'Tile',
  },
  {
    value: 'Cement',
    label: 'Cement',
  },
  {
    value: 'Plaster',
    label: 'Plaster',
  },
  {
    value: 'Steel',
    label: 'Steel',
  },
  {
    value: 'Door',
    label: 'Door',
  },
];

// Mount select options
const M = [
  {
    value: 'Inside',
    label: 'Inside',
  },
  {
    value: 'Outside',
    label: 'Outside',
  },
];

// Ladder Select Options
const LT = [
  {
    value: 'n/a',
    label: 'N/A',
  },
  {
    value: "2'",
    label: 'Ladder 2',
  },
  {
    value: "8'",
    label: 'Ladder 8',
  },
  {
    value: "15'",
    label: 'Ladder 15',
  },
  {
    value: "25'",
    label: 'Ladder 25',
  },
];

//Styles defined
const textfield = {
  my: '3%',
  width: '100%',
  textAlign: 'left',
  fontFamily: 'TextaRegular',
};

const AddWindowSet = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = props.data;
  const [open, setOpen] = React.useState(false);
  const [mount, setMount] = useState('');
  const [mountsurface, setMountsurface] = useState('');
  const [ladder, setLadder] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [windowsetName, setWindowSetName] = useState('');
  const [windowName, setWindowName] = useState('');
  const [size, setSize] = useState('');
  const [window_width, setSizeW] = useState();
  const [window_height, setSizeH] = useState();
  const [quantity, setQuantity] = useState();
  const [mountOpen, setMountOpen] = useState(false);
  const [mountSurfaceOpen, setMountSurfaceOpen] = useState(false);
  const [ladderOpen, setLadderOpen] = useState(false);

  useEffect(() => {
    const wn = `WINDOW SET ${location.rowlength + 1}`;
    setWindowSetName(wn);
  });
  useEffect(() => {
    getVerified();
  }, []);
  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const fetchdata = (data, drawerstatus, width, length) => {
    setSizeW(width);
    setSizeH(length);
    setSize(data);
    setIsDrawerOpen(drawerstatus);
  };
  const cancelWindowSet = () => {
    props.checkbuttonOption(false, false);
  };

  //Create WindowSet
  const CreateWindow = async () => {
    // Get Token
    const token = sessionStorage.getItem('token');

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', token: token },
      body: JSON.stringify({
        window_name: windowName,
        window_width: numericQuantity(window_width),
        window_height: numericQuantity(window_width),
        mount: mount,
        ladder: ladder,
        mountsurface: mountsurface,
        window_set_name: windowsetName,
        address_id: location.windowsetdata.address_id,
        customer_id: location.windowsetdata.customer_id,
        sales_person_id: location.windowsetdata.sales_person_id,
        install_requested: true,
      }),
    };

    // Created WindowSet will be added to the database through WindowSet API
    await fetch(`${CONFIG.API_URL}/wm/WindowSet`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          props.checkbuttonOption(false, true);
        }
      });
  };

  return (
    <>
      {/* Drawer */}
      <Drawer
        classes={{ paper: classes.paper }}
        PaperProps={{
          sx: { width: '90%' },
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        anchor='right'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(true)}
        sx={{ zIndex: '100000' }}
      >
        <DrawerInput func={fetchdata} />
      </Drawer>
      <div align='center'>
        {props.data.showprompt === 1 ? (
          <Box>
            <Typography
              sx={{
                background: '#2596be',
                fontSize: '150%',
                px: '1%',
                color: 'white',
              }}
              variant='contained'
            >
              Add the first window to create a new window set
            </Typography>
          </Box>
        ) : (
          ''
        )}
        <hr />
        <Box component='form' sx={{ width: '100%', px: '5%', fontFamily: 'TextaRegular' }} noValidate autoComplete='off'>
          <ThemeProvider theme={theme}>
            <TextField sx={textfield} id='filled-basic' label='Window Name' variant='outlined' required value={windowName} onChange={(e) => setWindowName(e.target.value)} />

            <TextField
              sx={textfield}
              onClick={() => setIsDrawerOpen(true)}
              id='filled-basic'
              label='Width X Height'
              variant='outlined'
              required
              value={size}
              InputProps={{
                readOnly: true,
              }}
              onChange={(e) => setSize(e.target.value)}
            />

            <FormControl onClick={() => setMountOpen(!mountOpen)} className='formcontrol text_selectfield'>
              <Select
                value={mount}
                required
                onChange={(e) => setMount(e.target.value)}
                align='left'
                onClose={() => setMountOpen(false)}
                onOpen={() => setMountOpen(true)}
                open={mountOpen}
                options={M}
                displayEmpty
                startAdornment={
                  <InputAdornment position='start'>
                    Mount<span style={{ color: '#DB3131' }}>*</span>:
                  </InputAdornment>
                }
              >
                <MenuItem value={''}>
                  <b>─ ─</b>
                </MenuItem>
                {M.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl onClick={() => setMountSurfaceOpen(!mountSurfaceOpen)} className='formcontrol text_selectfield'>
              <Select
                required
                value={mountsurface}
                onChange={(e) => setMountsurface(e.target.value)}
                align='left'
                options={MS}
                onClose={() => setMountSurfaceOpen(false)}
                onOpen={() => setMountSurfaceOpen(true)}
                open={mountSurfaceOpen}
                displayEmpty
                startAdornment={
                  <InputAdornment position='start'>
                    Mount Surface<span style={{ color: '#DB3131' }}>*</span>:
                  </InputAdornment>
                }
              >
                <MenuItem value={''}>
                  <b>─ ─</b>
                </MenuItem>
                {MS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl onClick={() => setLadderOpen(!ladderOpen)} className='formcontrol text_selectfield'>
              <Select
                value={ladder}
                required
                onChange={(e) => setLadder(e.target.value)}
                align='left'
                onClose={() => setLadderOpen(false)}
                onOpen={() => setLadderOpen(true)}
                open={ladderOpen}
                options={ladder}
                displayEmpty
                startAdornment={
                  <InputAdornment position='start'>
                    Ladder<span style={{ color: '#DB3131' }}>*</span>:
                  </InputAdornment>
                }
              >
                <MenuItem value={''}>
                  <b>─ ─</b>
                </MenuItem>
                {LT.map((ladder) => (
                  <MenuItem key={ladder.value} value={ladder.value}>
                    {ladder.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              sx={textfield}
              label='Quantity'
              required
              inputProps={{ min, max }}
              type='number'
              id='filled-basic'
              value={quantity}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                if (value >= min && value <= max) {
                  setQuantity(value);
                } else {
                  setQuantity('');
                  setOpen('true');
                }
              }}
            />
          </ThemeProvider>
        </Box>

        {/* Button */}
        <Button
          variant='contained'
          disabled={!windowName || !size || !mount || !mountsurface || !ladder}
          sx={{
            backgroundColor: '#008593',
            borderRadius: '25px',
            '&:hover': {
              backgroundColor: '#008593 !important',
            },
          }}
          startIcon={<AddIcon sx={{ fontSize: '5.0rem' }} />}
          onClick={CreateWindow} //CreateWindow function will be called on Button Click
        >
          CREATE WINDOW SET
        </Button>
        <Button
          variant='contained'
          sx={{
            color: '#666666',
            backgroundColor: '#ffffff',
            borderRadius: '25px',
            my: 2,
            mx: 2,
            '&:hover': {
              backgroundColor: 'white',
            },
          }}
          onClick={cancelWindowSet} //cancelWindowSet function will be called on Button Click
        >
          {<CancelIcon />} CANCEL
        </Button>
      </div>
    </>
  );
};
export default AddWindowSet;
