import React, { useEffect, useState } from 'react';
import Roboto from '../../font/Roboto-Bold.woff';
import RobotoLight from '../../font/Roboto-Light.woff';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';

Font.register([
  {
    family: 'Roboto',
    src: Roboto,
  },
  {
    family: 'RobotoLight',
    src: RobotoLight,
  },
]);
// Create styles
const styles = StyleSheet.create({
  section_col: {
    paddingTop: 5,
    flexDirection: 'col',
  },
  section_col_service: {
    paddingTop: 0,
    flexDirection: 'col',
    textAlign: 'right',
  },
  section_middlecol_service: {
    paddingTop: 0,
    paddingLeft: 5,
    paddingRight: 5,
    flexDirection: 'col',
  },
  section_val: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  row: {
    paddingTop: 5,
    paddingLeft: 10,
    flexFlow: 'row nowrap',
    width: 50,
    borderRight: '0.25px solid #000',
  },
  section_col_key_services: {
    width: 750,
    paddingTop: 5,

    flexDirection: 'col',
  },
  section_col_key_proposal: {
    paddingTop: 5,
    paddingRight: 10,
    paddingLeft: 30,
    flexDirection: 'col',
  },
  section_col_key_services_price: {
    width: 150,
    paddingTop: 5,
    paddingLeft: 420,
    flexDirection: 'col',
  },
  window_details: {
    width: 180,
    display: 'flex',
    paddingTop: 5,
    marginLeft: 15,
    flexDirection: 'col',
    borderRight: '0.25px solid #000',
  },
  covering_details: {
    width: 470,
    paddingTop: 5,
    marginLeft: 15,
    flexDirection: 'col',
    borderRight: '0.25px solid #000',
  },
  table_section_col1_key: {
    width: 250,
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
    flexDirection: 'col',
  },
  table_section_col2_key: {
    width: 220,
    paddingTop: 5,
    paddingLeft: 30,
    flexDirection: 'col',
  },
  window_total: {
    width: 100,
    paddingTop: 5,
    flexDirection: 'col',
    textAlign: 'right',
    borderRight: '0.25px solid #000',
  },
  section_col_val: {
    paddingTop: 3.5,
    flexDirection: 'col',
  },
  section_row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  section_table_row: {
    flexFlow: 'row wrap',
  },
  section_table_row_service: {
    display: 'flex',
    flexDirection: 'row',
  },
  tablerowText: {
    marginTop: -2,
    paddingLeft: 2,
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: '700',
    lineHeight: '1.5px',
    color: '#000',
    textAlign: 'justify',
  },
  tablerowTextMaterial: {
    marginTop: -2,
    paddingLeft: 5,
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: '1.5px',
    color: '#000',
    textAlign: 'justify',
  },
  section_col_body: {
    display: 'flex',
    flexDirection: 'col',
  },
  section_space: {
    lineHeight: '1.5px',
    paddingTop: 5,
  },
  headingText: {
    fontFamily: 'RobotoLight',
    fontSize: '8px',
    lineHeight: '2.5px',
    color: '#000',
    textAlign: 'justify',
  },
  headingTextProposalkeys: {
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: 1.5,
    color: '#000',
    textAlign: 'justify',
  },
  subheadingTextProposalkeys: {
    width: '85%',
    paddingLeft: 10,
    paddingTop: 1,
    paddingBottom: 2,
    fontFamily: 'RobotoLight',
    fontSize: '8px',
    lineHeight: 1.5,
    color: '#000',
    textAlign: 'justify',
  },
  headingTextProposalval: {
    display: 'flex',
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: 1.5,
    color: '#000',
    textAlign: 'right',
  },
  headingTextProposalvalue: {
    display: 'flex',
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: 1.5,
    color: '#000',
    textAlign: 'right',
  },
  subheadingTextProposalval: {
    display: 'flex',

    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: 1.5,
    color: '#000',
  },
  headingTextProposalfinalvalT: {
    fontWeight: 900,
    fontSize: '10px',
    lineHeight: '1.5',
    color: '#000',
    textAlign: 'right',
  },
  headingTextProposalfinalvalB: {
    paddingTop: 5,
    fontWeight: 900,
    fontSize: '10px',
    lineHeight: 1.5,
    color: '#000',
    textAlign: 'justify',
  },
  headingTextProposalT: {
    fontSize: '10px',
    fontWeight: 900,
    paddingBottom: 5,
    color: '#000',
    textAlign: 'justify',
  },
  headingTextProposalB: {
    fontSize: '10px',
    fontWeight: 'bold',
    paddingTop: 5,
    color: '#000',
    textAlign: 'justify',
  },
  rowText: {
    paddingTop: 5,
    paddingLeft: 40,
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: '1.5px',
    color: '#000',
    textAlign: 'justify',
  },
  horizontalborder: {
    width: '100%',
    height: '1px',
    flexDirection: 'row',
    backgroundColor: '#BCBDBD',
  },
  horizontalblackborder: {
    paddingTop: '5px',
    width: '100%',
    height: '4px',
    flexDirection: 'row',
    backgroundColor: '#2D3030',
  },
  h2: {
    display: 'block',
    fontSize: 30,
    marginTop: 1,
    marginBottom: 1,
    marginLeft: 0,
    marginRight: 0,
    padding: 10,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    flexGrow: 1,
  },
  left: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 495,
  },
  right: {
    flexShrink: 1,
    flexGrow: 2,
  },
});

function PdfTableData({ windows, proposalDetails }) {
  const [Accessoriescharge, setAccessoriesCharge] = useState([]);
  const [Servicescharge, setServicesCharge] = useState([]);

  useEffect(() => {
    if (proposalDetails.proposal_level_accessory_service_charges) {
      const additionalCharge = proposalDetails.proposal_level_accessory_service_charges;
      const accessoriesDetails = [];
      const serviceDetails = [];

      additionalCharge.forEach((charge) => {
        if (charge.charge_type === 'X') {
          accessoriesDetails.push(charge);
        }
        if (charge.charge_type === 'S') {
          serviceDetails.push(charge);
        }
      });

      setAccessoriesCharge(accessoriesDetails);
      setServicesCharge(serviceDetails);
    }
  }, []);

  return (
    <View style={styles.section_col_body}>
      {windows.map((window, i) => {
        return (
          <>
            <View key={i} style={styles.section_row}>
              {/* Row */}
              <View style={styles.row}>
                <Text style={styles.headingText}>#{i + 1} </Text>
              </View>

              {/* Window Details */}
              <View style={styles.window_details}>
                <View style={styles.section_table_row}>
                  <Text style={styles.headingText}>Window Name:</Text>
                  <Text style={styles.tablerowText}>{window.window_name}</Text>
                </View>
                <View style={styles.section_table_row}>
                  <Text style={styles.headingText}>Measurement Type:</Text>
                  <Text style={styles.tablerowText}>{window.measurement_type}</Text>
                </View>
                <View style={styles.section_table_row}>
                  <Text style={styles.headingText}>Window Size:</Text>
                  <Text style={styles.tablerowText}>
                    {Math.round(window.proposal_window_width)}” X {Math.round(window.proposal_window_height)}”{' '}
                  </Text>
                </View>
                <View style={styles.section_table_row}>
                  <Text style={styles.headingText}>Depth:</Text>
                  <Text style={styles.tablerowText}>{window.proposal_measurement_type_specific_factor.WindowDepth}</Text>
                </View>
                {window.proposal_additional_sku.map((data, i) => (
                  <View key={i} style={styles.section_table_row}>
                    <Text style={styles.headingText}>{data.type}:</Text>
                    <Text style={styles.tablerowText}>{data.sku_name}</Text>
                  </View>
                ))}
              </View>

              {/* Covering Details */}
              <View style={styles.covering_details}>
                <View>
                  <View style={styles.section_table_row}>
                    <Text style={styles.headingText}>Material:</Text>
                    <Text style={styles.tablerowTextMaterial}>{window.material_code.toString().replace(/,/g, ', ')}</Text>
                  </View>
                  <View style={styles.section_table_row}>
                    <Text style={styles.headingText}>SKU:</Text>
                    <Text style={styles.tablerowText}>{window.sku.toString().replace(/,/g, ', ')}</Text>
                  </View>
                </View>
              </View>

              {/* Charges */}
              <View style={styles.window_total}>
                <View style={styles.section_table_row}>
                  <Text style={styles.tablerowText}>${parseFloat(window.retail_price).toFixed(2)}</Text>
                </View>
              </View>
            </View>
            <View key={i} style={styles.horizontalborder}>
              &nbsp;
            </View>
          </>
        );
      })}
      <View style={styles.horizontalborder}>&nbsp;</View>
      <View style={styles.section_space}>&nbsp;</View>
      <View style={styles.horizontalblackborder}>&nbsp;</View>

      <View style={styles.section_row}>
        <View style={styles.section_col_key_proposal}>
          <View style={styles.section_table_row_service}>
            <View style={styles.left}>
              <View style={styles.section_col_service}>
                <Text style={styles.headingTextProposalT}>COVERINGS SUBTOTAL :</Text>
              </View>
            </View>
            <View style={styles.right}>
              <View style={styles.section_val}>
                <Text style={styles.headingTextProposalfinalvalT}>$ {parseFloat(proposalDetails.proposal_retail_price).toFixed(2)}</Text>
              </View>
            </View>
          </View>
          {Accessoriescharge.length > 0 ? (
            <View style={styles.section_table_row_service}>
              <View style={styles.left}>
                <View style={styles.section_col_service}>
                  <Text style={styles.headingTextProposalkeys}>Accessories :</Text>
                </View>
              </View>
              <View style={styles.right}>
                <View style={styles.section_val}>
                  <Text style={styles.headingTextProposalfinalvalT}>&nbsp;</Text>
                </View>
              </View>
            </View>
          ) : null}
          {Accessoriescharge.length > 0 ? (
            <View style={styles.section_table_row_service}>
              <View style={styles.left}>
                <View style={styles.section_col_service}>
                  <View>
                    {Accessoriescharge.map((accessory, index) => (
                      <Text style={styles.subheadingTextProposalkeys}>{accessory.charge_description} :</Text>
                    ))}
                  </View>
                </View>
              </View>
              <View style={styles.right}>
                <View style={styles.section_val}>
                  <View style={{ dispaly: 'flex', alignItems: 'flex-end' }}>
                    {Accessoriescharge.map((accessory, index) => (
                      <Text style={styles.subheadingTextProposalval}> ${parseFloat(accessory.charge).toFixed(2)}</Text>
                    ))}
                  </View>
                </View>
              </View>
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );
}

export default PdfTableData;
