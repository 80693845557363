import React, { } from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { Box, Typography } from "@mui/material";
import WindowSetCheckIcon from '../../assets/icons/primary/windowset-complete.svg'
import WindowCheckIcon from '../../assets/icons/primary/window-confirm.svg'
import filterAddIcon from "../../assets/icons/primary/inst-step3.svg";
import Grid from "@mui/material/Grid";

const EditLinesAddWindowStepper = (props) => {

  //  hover states

  return (
    <div
      className="edit_lines_addwindow_stepper_maindiv"

      align="center"
    >
      <Grid container spacing={2}>
        <Stack className="ProposalStepperStack">
          <Box className="stepperMainBox">
            {props.data === "EditLinesAddWindowStepper" ? (
              <Box>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Box>
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}

                    >
                      <Typography
                        sx={{ fontSize: "32px" }}
                        className="ProposalstepperMainTextSize"
                      >
                        STEP 1 :
                      </Typography>


                      <img alt="WINDOWSETCHECKICON"
                        className="primary_icons_dimension_1 stepperImageMargins"
                        src={WindowSetCheckIcon} />
                    </Stack>
                  </Box>
                  <Box
                    sx={{ borderLeft: "1px solid #D6D6D6", marginLeft: "10px" }}
                    className=""
                  >
                    <Typography className="ProposalstepperMainTextSize ProposalstepperSubTextStyle ">
                      Select Windows to
                    </Typography>
                    <Typography className="ProposalstepperMainTextSize ProposalstepperSubTextStyle">
                      Add to this Proposal
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            ) : (
              <Box>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Box>
                    <Stack
                      className="stepperDisable"
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                    >
                      <Typography
                        sx={{ fontSize: "32px" }}
                        className="ProposalstepperMainTextSize"
                      >
                        STEP 1 :
                      </Typography>
                      <img
                        src={WindowSetCheckIcon}
                        className="primary_icons_dimension_1 stepperImageMargins"
                        alt="WINDOWSETCHECKICOND"
                      />

                    </Stack>
                  </Box>
                  <Box
                    sx={{ borderLeft: "1px solid #D6D6D6", marginLeft: "10px" }}
                    className="stepperDisable"
                  >
                    <Typography className="ProposalstepperMainTextSize ProposalstepperSubTextStyle">
                      Select Windows to
                    </Typography>
                    <Typography className="ProposalstepperMainTextSize ProposalstepperSubTextStyle">
                      Add to this Proposal
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            )}
          </Box>

          <Box className="stepperMainBox">
            {props.data === "ApplyCovering" ? (
              <Box>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Box>
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}

                    >
                      <Typography className="ProposalstepperMainTextSize">
                        STEP 2 :
                      </Typography>



                      <img alt="WINDOWCHECKICON"
                        className="primary_icons_dimension_1 stepperImageMargins"
                        src={WindowCheckIcon} />
                    </Stack>
                  </Box>
                  <Box
                    sx={{ borderLeft: "1px solid #D6D6D6", marginLeft: "10px" }}
                    className=""
                  >
                    <Typography className="ProposalstepperMainTextSize ProposalstepperSubTextStyle">
                      Select Windows
                    </Typography>
                    <Typography className="ProposalstepperMainTextSize ProposalstepperSubTextStyle">
                      To Apply Coverings
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            ) : (
              <Box>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Box>
                    <Stack
                      className="stepperDisable"
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                    >
                      <Typography className="ProposalstepperMainTextSize">
                        STEP 2 :
                      </Typography>
                      <img
                        src={WindowCheckIcon}
                        className="primary_icons_dimension_1 stepperImageMargins"
                        alt="WINDOWCHECKICOND"
                      />

                    </Stack>
                  </Box>
                  <Box
                    sx={{ borderLeft: "1px solid #D6D6D6", marginLeft: "10px" }}
                    className="stepperDisable"
                  >
                    <Typography className="ProposalstepperMainTextSize ProposalstepperSubTextStyle">
                      Select Windows
                    </Typography>
                    <Typography className="ProposalstepperMainTextSize ProposalstepperSubTextStyle">
                      To Apply Coverings
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            )}
          </Box>

          <Box className="stepperMainBox">

            <Box>
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Box>
                  <Stack
                    className="stepperDisable"
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    <Typography className="ProposalstepperMainTextSize">
                      STEP 3 :
                    </Typography>

                    <img src={filterAddIcon} className="primary_icons_dimension_1 stepperImageMargins" alt="FILTERADDICON" />
                  </Stack>
                </Box>
                <Box
                  sx={{ borderLeft: "1px solid #D6D6D6", marginLeft: "10px" }}
                  className="stepperDisable"
                >
                  <Typography className="ProposalstepperMainTextSize ProposalstepperSubTextStyle">
                    {" "}
                    Apply Filters for
                  </Typography>
                  <Typography className="ProposalstepperMainTextSize ProposalstepperSubTextStyle">
                    Covering Options
                  </Typography>
                </Box>
              </Stack>
            </Box>

          </Box>
        </Stack>
      </Grid>
    </div>
  );
};

export default EditLinesAddWindowStepper;
