import React from 'react';
import { Link } from 'react-router-dom';
import fracty from 'fracty';
import { TableContainer, Table as MuiTable, TableBody, Typography } from '@mui/material';
import LongText from '../LongText';
import Table, { formatLabel } from '../Shared/Table';

export function RollerShades({ windows }) {
  const rows = windows.map((window) => {
    const { sales_order_measurement_type, sku_details, sales_order_specific_factor } = window;
    const measurementType =
      sales_order_measurement_type === 'Quick'
        ? '(Q)'
        : sales_order_measurement_type === 'Shutters'
        ? '(SH)'
        : sales_order_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          sales_order_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';

    const base = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence + 1 > 9 ? '20px' : '30px' }}>{+window.item_sequence + 1}</span>
          <LongText>{window.window_name}</LongText>
        </>
      ),
      size: (
        <>
          {`${fracty(window.sales_order_window_width)}" X ${fracty(window.sales_order_window_height)}"`} <span className={sales_order_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      fabric: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.value_1,
      direction: liftSystemData.value_2,
    };
    const color = { color: sales_order_specific_factor['Material Color']?.name };

    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ value_1, value_2 }) => ({ [value_2]: value_1 }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...base,
        ...materials,
        ...liftSystem,
        ...color,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    align: 'center',
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Roller Shade
      </Typography>
      <TableContainer>
        <MuiTable>
          <Table.Header columns={columns} />
          <TableBody>
            {rows.map((window) => (
              <Table.Row columns={columns} row={window} key={window.window_id} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  );
}

export function Shutters({ windows }) {
  const rows = windows.map((window) => {
    const { sales_order_measurement_type, sku_details, sales_order_specific_factor } = window;
    const measurementType =
      sales_order_measurement_type === 'Quick'
        ? '(Q)'
        : sales_order_measurement_type === 'Shutters'
        ? '(SH)'
        : sales_order_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          sales_order_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';

    const base = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence + 1 > 9 ? '20px' : '30px' }}>{+window.item_sequence + 1}</span>
          <LongText>{window.window_name}</LongText>
        </>
      ),
      size: (
        <>
          {`${fracty(window.sales_order_window_width)}" X ${fracty(window.sales_order_window_height)}"`} <span className={sales_order_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      fabric: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.value_1,
      direction: liftSystemData.value_2,
    };
    const color = { color: sales_order_specific_factor['Material Color']?.name };
    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ value_1, value_2 }) => ({ [value_2]: value_1 }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...base,
        ...materials,
        ...liftSystem,
        ...color,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    align: 'center',
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Shutters
      </Typography>
      <TableContainer>
        <MuiTable>
          <Table.Header columns={columns} />
          <TableBody>
            {rows.map((window, index) => (
              <Table.Row columns={columns} row={window} key={index} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  );
}

export function Blinds({ windows }) {
  const rows = windows.map((window) => {
    const { sales_order_measurement_type, sku_details, sales_order_specific_factor } = window;
    const measurementType =
      sales_order_measurement_type === 'Quick'
        ? '(Q)'
        : sales_order_measurement_type === 'Shutters'
        ? '(SH)'
        : sales_order_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          sales_order_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';

    const base = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence + 1 > 9 ? '20px' : '30px' }}>{+window.item_sequence + 1}</span>
          <LongText>{window.window_name}</LongText>
        </>
      ),
      size: (
        <>
          {`${fracty(window.sales_order_window_width)}" X ${fracty(window.sales_order_window_height)}"`} <span className={sales_order_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      fabric: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.value_1,
      direction: liftSystemData.value_2,
    };

    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    const color = { color: sales_order_specific_factor['Material Color']?.name };
    return optionsData
      .map(({ value_1, value_2 }) => ({ [value_2]: value_1 }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...base,
        ...materials,
        ...liftSystem,
        ...color,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    align: 'center',
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Blinds
      </Typography>
      <TableContainer>
        <MuiTable>
          <Table.Header columns={columns} />
          <TableBody>
            {rows.map((window, index) => (
              <Table.Row columns={columns} row={window} key={index} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  );
}

export function SoftShades({ windows }) {
  const rows = windows.map((window) => {
    const { sales_order_measurement_type, sku_details, sales_order_specific_factor } = window;
    const measurementType =
      sales_order_measurement_type === 'Quick'
        ? '(Q)'
        : sales_order_measurement_type === 'Shutters'
        ? '(SH)'
        : sales_order_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          sales_order_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';

    const base = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence + 1 > 9 ? '20px' : '30px' }}>{+window.item_sequence + 1}</span>
          <LongText>{window.window_name}</LongText>
        </>
      ),
      size: (
        <>
          {`${fracty(window.sales_order_window_width)}" X ${fracty(window.sales_order_window_height)}"`} <span className={sales_order_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      fabric: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.value_1,
      direction: liftSystemData.value_2,
    };
    const color = { color: sales_order_specific_factor['Material Color']?.name };

    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ value_1, value_2 }) => ({ [value_2]: value_1 }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...base,
        ...materials,
        ...liftSystem,
        ...color,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    align: 'center',
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Soft Shades
      </Typography>
      <TableContainer>
        <MuiTable>
          <Table.Header columns={columns} />
          <TableBody>
            {rows.map((window, index) => (
              <Table.Row columns={columns} row={window} key={index} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  );
}

const SalesOrderProductTables = {
  Blinds,
  RollerShades,
  Shutters,
  SoftShades,
};

export default SalesOrderProductTables;
