import React, { useEffect, useState } from 'react';
import './Header.css';
import FileUpload from './FileUploadImporter';
import CONFIG from '../../../config/config.js';
import Header from '../JsonImporter/Header';
import { useNavigate } from 'react-router-dom';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { Helmet } from 'react-helmet';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAlert from '@mui/material/Alert';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import HeaderLogoIcon from '../../../assets/icons/secondary/hdft-TBM-logo.svg';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import headerBack from '../../../assets/icons/primary/header-return.svg';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import pricing_option from './csv_files/pricing_option.csv';
import pricing_lift_system from './csv_files/pricing_lift_system.csv';
import limitation_covering_system from './csv_files/limitation_covering_system.csv';
import limitation_exclusion_covering_system from './csv_files/limitation_exclusion_covering_system.csv';
import Snackbar from '@mui/material/Snackbar';
import { toast } from 'react-toastify';
import DrawerCancelIcon from '../../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerConinueIcon from '../../../assets/icons/drawer-alert/drw-continue.svg';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
const vertical = 'top';
const horizontal = 'center';
const drawerWidth = 350;
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const ListItems = [
  {
    fileName: 'pricing_lift_system.csv',
    filePath: pricing_lift_system,
  },
  {
    fileName: 'pricing_option.csv',
    filePath: pricing_option,
  },
  {
    fileName: 'limitation_covering_system.csv',
    filePath: limitation_covering_system,
  },
  {
    fileName: 'limitation_exclusion_covering_system.csv',
    filePath: limitation_exclusion_covering_system,
  },
];
const handleDownloadClick = (filepath, fileName) => {
  const link = document.createElement('a');
  link.href = filepath;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const JsonImporter = () => {
  const token = sessionStorage.getItem('token');

  const navigate = useNavigate();

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const handleClose = () => {
    setDeleteItem(false);
  };

  const handlenavigateclose = async () => {
    await fetch(`${CONFIG.API_URL}/pm/ClearRedis`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.error === true) {
          toast.error(data.error_message);
          setDeleteItem(false);
        } else if (!data.error) {
          toast.success(data.message);
          setDeleteItem(false);
          sessionStorage.clear();
          sessionStorage.clear();
        }
      });
  };
  const handleRedisClear = async () => {
    setDeleteItem(true);
  };

  useEffect(() => {
    getVerified();
  }, []);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [deleteItem, setDeleteItem] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
      <Snackbar className='snackbar_bar_postition' open={deleteItem} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
        <Alert className='Success' variant='filled' icon={false} severity='warning'>
          <div className='alertMsgCancelICon' align='right'>
            <img onClick={handleClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
          </div>
          <div align='center'>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'Center',
                paddingBottom: '10px',
              }}
            >
              <Box className='alertCantinueButton'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' />
              </Box>
            </Box>
            <div align='center' style={{ paddingLeft: '1%', paddingRight: '1%' }}>
              <span style={{ fontSize: '20px' }}> {'Are you sure want to clear the Redis & Materialized View ? it will Distroy the session !'}</span>
              <div
                align='left'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Box>
                  <Button type='submit' onClick={handlenavigateclose}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'Center',
                        cursor: 'pointer',
                        marginBottom: '10px',
                        marginLeft: '6px',
                      }}
                    >
                      <Box className='alertCantinueButton'>
                        <img src={DrawerConinueIcon} className='primary_icons_dimension' />
                      </Box>
                      <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                        CONTINUE
                      </Typography>
                    </Box>
                  </Button>
                </Box>
                <Box className='gray_button_span button_top_space' style={{ paddingBottom: '30px', paddingLeft: '23px' }} onClick={handleClose}>
                  <Box className='globalIconMainBox'>
                    <Box className='iconSecondaryButtonGlobalForDrawer'>
                      <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                    </Box>
                    <Typography className='globalTextForDrawer'>CANCEL</Typography>
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        </Alert>
      </Snackbar>
      <Helmet>
        {' '}
        <link rel='stylesheet' href='https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css' integrity='sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T' crossorigin='anonymous' />{' '}
      </Helmet>

      <div
        dangerouslySetInnerHTML={{
          __html: ` <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
    integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
    crossorigin="anonymous"></script>`,
        }}
      ></div>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position='fixed' className='jsonconverter_header' open={open}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100vw',
              }}
            >
              <Typography onClick={handleDrawerOpen} sx={{ paddingLeft: '10px' }}>
                <img onClick={() => navigate('/tbm/ps/dashboard')} src={HeaderLogoIcon} alt='Logo' className='logout_icon_dimension drawer_cancel_cursor' />
              </Typography>
              <Typography sx={{ paddingTop: '8px', fontSize: '17px' }}>TBM-DB-TOOLS</Typography>
              <Typography
                className='return_to_dashboard'
                onClick={() => {
                  navigate(-1);
                }}
                sx={{
                  cursor: 'pointer',
                }}
                variant='h6'
                noWrap
                component='div'
              >
                <Typography sx={{ paddingLeft: '10px' }}>
                  <img onClick={() => navigate(-1)} src={headerBack} alt='Logo' className='dbtools_backbuttton ' />
                </Typography>
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant='persistent'
          anchor='left'
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold', paddingLeft: '20px' }}>Sample Files</Typography>
          </List>
          <Divider />
          <List>
            {ListItems.map((text) => {
              return (
                <ListItem disablePadding onClick={() => handleDownloadClick(text.filePath, text.fileName)}>
                  <ListItemButton>
                    <ListItemIcon>
                      <SimCardDownloadIcon />
                    </ListItemIcon>
                    <ListItemText primary={text.fileName} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Divider />

          <List>
            {['Json Converter'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <Button
                    onClick={() => {
                      navigate(`/dbtools/jsonconverter`);
                    }}
                    variant='contained'
                  >
                    <ListItemText primary={text} />
                  </Button>
                </ListItemButton>
              </ListItem>
            ))}
          </List>

          <button className='btn btn-success' style={{ width: '50%', marginLeft: '16px' }} onClick={handleRedisClear}>
            Refresh Materialized View & Clear Redis
          </button>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />

          <div>
            <h4 className='display-4 text-center mb-4'>
              <Header />
            </h4>
            <FileUpload />
          </div>
        </Main>
      </Box>
    </div>
  );
};

export default JsonImporter;
