/** @format */

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import CONFIG from '../../config/config.js';
import 'moment-timezone';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ErrorIcon from '@mui/icons-material/Error';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import SaveChanges from '../../assets/icons/primary/save.svg';
import SaveChangesHovIcon from '../../assets/icons/primary/WithHovIcon/save-hov.svg';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import EditWindowName from './EditWindowName';
import DrawerInput from '../WindowsManagement/DrawerInput';
import DrawerInputWidth from '../WindowsManagement/DrawerInputWidth';
import fracty from 'fracty';
import { createTheme, ThemeProvider } from '@mui/material';
import NotesDrawer from '../Proposals/NotesDrawer';
import NoteMiscIcon from '../../assets/icons/misc/misc-note.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';
import CommonDrawerInputWidth from './CommonDrawerInputWidth';

// Styles

// Alert
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const vertical = 'top';
const horizontal = 'center';

// Style theme to make background blur and transparent
const useStyles = makeStyles({
  paper: {
    width: '90%',

    background: 'rgba(69, 157, 166, 70%)  !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});
const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#333333',
    },
  },
});

const Window = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const params = location.state.row_id;
  const customerdetail = location.state.data;

  const [windowData, setWindowData] = useState([]);
  const [windowName, setWindowName] = useState();
  const [customerID, setCustomerID] = useState();
  const [addressID, setAddressID] = useState();
  const [open, setOpen] = useState(false);
  const [openupdatewindowname, setUpdateWindownameOpen] = useState(false);
  const [window_width, setSizeW] = useState();
  const [window_height, setSizeH] = useState();
  const [window_widthindecimal, setSizeWindecimal] = useState();
  const [window_heightindecimal, setSizeHindecimal] = useState();
  const [leftshade_size, setLeftshade_size] = useState('');
  const [rightshade_size, setRightshade_size] = useState('');
  const [centershade_size, setCentershade_size] = useState('');
  const [leftshade_widthindecimal, setLeftshade_widthindecimal] = useState('');
  const [rightshade_widthindecimal, setRightshade_widthindecimal] = useState('');
  const [centershade_widthindecimal, setCentershade_widthindecimal] = useState('');
  const [totalWidth, setTotalWidth] = useState('');
  const [minqty, setMinqty] = useState(1);

  const [MultipleSKU, setMultipleSKU] = useState();
  const [size, setSize] = useState();

  const [mountsurface, setMountsurface] = useState('');
  const [ladder, setLadder] = useState('');
  const [valuechangeflag, setValueChanged] = useState(false);

  const [windowId, setWindowId] = useState();
  const [roomLabel, setRoomLabel] = useState();
  const [successmessage, setSuccessMessage] = useState('');
  const [classtype, setClassType] = useState('');
  const [existingCovering, setExistingCovering] = useState('');
  const [measurementType, setMeasurementType] = useState('');
  const [buildType, setBuildType] = useState('');
  const [buildTypeReq, setbuildTypeReq] = useState(
    <>
      <span style={{ color: '#DB3131' }}>*</span>&nbsp;
    </>
  );

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNoDrawerOpen, setIsNoDrawerOpen] = useState(false);
  const [isCommonDrawerOpen, setIsCommonDrawerOpen] = useState(false);
  const [windowdepth, setWindowDepth] = useState('');
  const [headrail, setHeadrail] = useState('1 Section');
  const [obstructions, setObstructions] = useState('None');
  const [multiple, setMultiple] = useState('Single');

  const [rolldirection, setRollDirection] = useState('Standard');

  const [Typemeasurement, setTypeMeasurement] = useState([]);
  const [WindowDepthData, setWindowDepthData] = useState([]);
  const [RollDirectionSKU, setRollDirectionSKU] = useState();

  const [AddNote, setAddNote] = useState('');
  const [AddNoteforBuildOut, setAddNoteforBuildOut] = useState('');
  const [AddNoteforFillerStrip, setAddNoteforFillerStrip] = useState('');
  const [AddNoteforSplitTilt, setAddNoteforSplitTilt] = useState('');
  const [AddNoteforSplitRail, setAddNoteforSplitRail] = useState('');

  const [ExistingCoveringsData, setExistingCoveringsData] = useState([]);
  const [BuildTypeData, setBuildTypeData] = useState([]);
  const [MountSurfaceData, setMountSurfaceData] = useState([]);
  const [LadderData, setLadderData] = useState([]);
  const [ObstructionsData, setObstructionsData] = useState([]);
  let additional_sku = [];
  const [TypeHeadrail, setTypeHeadrail] = useState([]);
  const [TypeMultiple, setTypeMultiple] = useState([]);
  const [TypeRollDirection, setTypeRollDirection] = useState([]);
  const [Panel, setPanel] = useState('1');
  const [Windowsill, setWindowsill] = useState('Yes');
  const [DeadMold, setDeadMold] = useState('None');
  const [Buildout, setBuildout] = useState('None');
  const [FillerStrip, setFillerStrip] = useState('None');

  const [SplitTilt, setSplitTilt] = useState('No');
  const [SplitRail, setSplitRail] = useState('No');
  const [TPost, setTPost] = useState('None');
  const [VerticalConfiguration, setVerticalConfiguration] = useState('1 Section');
  const [SectionOneTopShow, setSectionOneTopShow] = useState('');
  const [SectionTwoBottomShow, setSectionTwoBottomShow] = useState('');
  const [SectionOneTopHeigth, setSectionOneTopHeigth] = useState('');
  const [SectionTwoBottomWidth, setSectionTwoBottomWidth] = useState('');
  const [HeigthType, setHeigthType] = useState('');
  const [WindowSillData, setWindowSillData] = useState([]);
  const [VerticalConfigurationData, setVerticalConfigurationData] = useState([]);

  const [measurementTypeOpen, setMeasurementTypeOpen] = useState(false);
  const [existingcoveringOpen, setExistingcoveringOpen] = useState(false);
  const [WindowsillOpen, setWindowsillOpen] = useState(false);
  const [VerticalConfigurationOpen, setVerticalConfigurationOpen] = useState(false);
  const [ValanceOpen, setValanceOpen] = useState(false);
  const [HeadrailOpen, setHeadrailOpen] = useState(false);
  const [CutoutOpen, setCutoutOpen] = useState(false);
  const [BuildTypeOpen, setBuildTypeOpen] = useState(false);
  const [MountsurfaceOpen, setMountsurfaceOpen] = useState(false);
  const [LadderOpen, setLadderOpen] = useState(false);
  const [leftShadeisDrawerOpen, setLeftShadeisDrawerOpen] = useState(false);
  const [WidthType, setWidthType] = useState('');
  const [msg, setMsg] = useState('');
  const [warnQty, setwarnQty] = useState(false);
  const [invalid, setInvalid] = React.useState(false);
  const [othermeasurement, setOtherMeasurement] = useState('');
  const [description, setDescription] = useState('');

  // error handling states
  const [GetLookupTableDataErrorMsg, setGetLookupTableDataErrorMsg] = useState(false);
  const [GetLookupTableDataErrorMsgTxt, setGetLookupTableDataErrorMsgTxt] = useState('');

  const [UpdateWindowErrorMsg, setUpdateWindowErrorMsg] = useState(false);
  const [UpdateWindowErrorMsgTxt, setUpdateWindowErrorMsgTxt] = useState(false);

  const [WindowErrorMsg, setWindowErrorMsg] = useState(false);
  const [WindowApiErrMsgTxt, setWindowApiErrMsgTxt] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  const [section1, setSection1] = useState('');
  const [section2, setSection2] = useState('');
  const [section3, setSection3] = useState('');
  const [section4, setSection4] = useState('');
  const [section5, setSection5] = useState('');
  const [section6, setSection6] = useState('');

  const isNumeric = (value) => !isNaN(value) && isFinite(value);

  const handleSectionValue = (value) => {
    return isNumeric(value) && value >= 0 ? fracty(value) + '"' : '';
  };

  // Process each section value
  const sec2_1 = handleSectionValue(section1);
  const sec2_2 = handleSectionValue(section2);
  const sec3_1 = handleSectionValue(section1);
  const sec3_2 = handleSectionValue(section2);
  const sec3_3 = handleSectionValue(section3);
  const sec4_1 = handleSectionValue(section1);
  const sec4_2 = handleSectionValue(section2);
  const sec4_3 = handleSectionValue(section3);
  const sec4_4 = handleSectionValue(section4);
  const sec5_1 = handleSectionValue(section1);
  const sec5_2 = handleSectionValue(section2);
  const sec5_3 = handleSectionValue(section3);
  const sec5_4 = handleSectionValue(section4);
  const sec5_5 = handleSectionValue(section5);
  const sec6_1 = handleSectionValue(section1);
  const sec6_2 = handleSectionValue(section2);
  const sec6_3 = handleSectionValue(section3);
  const sec6_4 = handleSectionValue(section4);
  const sec6_5 = handleSectionValue(section5);
  const sec6_6 = handleSectionValue(section6);

  const [ChangedSection1, setChangedSection1] = useState(false);
  const [ChangedSection2, setChangedSection2] = useState(false);
  const [ChangedSection3, setChangedSection3] = useState(false);
  const [ChangedSection4, setChangedSection4] = useState(false);
  const [ChangedSection5, setChangedSection5] = useState(false);
  const [ChangedSection6, setChangedSection6] = useState(false);

  let sectionValues = {
    section1: { value: section1, changed: ChangedSection1 },
    section2: { value: section2, changed: ChangedSection2 },
    section3: { value: section3, changed: ChangedSection3 },
    section4: { value: section4, changed: ChangedSection4 },
    section5: { value: section5, changed: ChangedSection5 },
    section6: { value: section6, changed: ChangedSection6 },
  };
  const trueSections = {}; // Initialize an empty JSON object
  //--------------------------------------------------------------------//

  const address_id = location.state.data.address_id;
  useEffect(() => {
    getWindow();
    getNotes();
  }, []);

  let customer_Id;

  useEffect(() => {
    setTotalWidth(window_widthindecimal);
    getVerified();
    getSaleAppDropDowns();
  }, []);

  useEffect(() => {
    if (existingCovering !== '') {
      ExistingCoveringsData.forEach((m) => {
        if (m.service_name === existingCovering) {
          setExistingSku(m.sku);
        }
      });
    } else {
      setExistingSku('');
    }

    if (buildType !== '') {
      BuildTypeData.forEach((m) => {
        if (m.service_name === buildType) {
          setBuildSku(m.sku);
        }
      });
    } else {
      setBuildSku('');
    }

    if (mountsurface !== '') {
      MountSurfaceData.forEach((m) => {
        if (m.service_name === mountsurface) {
          setMountSku(m.sku);
        }
      });
    } else {
      setMountSku('');
    }

    if (ladder !== '') {
      LadderData.forEach((m) => {
        if (m.service_name === ladder) {
          setLadderSku(m.sku);
        }
      });
    } else {
      setLadderSku('');
    }

    if (rolldirection !== '') {
      TypeRollDirection.forEach((r) => {
        if (r.service_name === rolldirection) {
          setRollDirectionSKU(r.sku);
        }
      });
    } else {
      setRollDirectionSKU('');
    }

    if (multiple !== '') {
      TypeMultiple.forEach((m) => {
        if (m.service_name === multiple) {
          setMultipleSKU(m.sku);
        }
      });
    } else {
      setMultipleSKU('');
    }

    if (headrail !== '') {
      TypeHeadrail.forEach((h) => {
        if (h.service_name === headrail) {
          setHeadrailSku(h.sku);
        }
      });
    } else {
      setHeadrailSku('');
    }

    if (obstructions !== '') {
      ObstructionsData.forEach((c) => {
        if (c.service_name === obstructions) {
          setObstructionsSku(c.sku);
        }
      });
    } else {
      setObstructionsSku('');
    }

    if (windowdepth !== '') {
      WindowDepthData.forEach((v) => {
        if (v.service_name === windowdepth) {
          setWindowDepthSku(v.sku);
        }
      });
    } else {
      setWindowDepthSku('');
    }

    if (VerticalConfiguration !== '') {
      VerticalConfigurationData.forEach((v) => {
        if (v.service_name === VerticalConfiguration) {
          setVerticalConfigurationSku(v.sku);
        }
      });
    } else {
      setVerticalConfigurationSku('');
    }
  }, [existingCovering, buildType, mountsurface, ladder, rolldirection, multiple, headrail, windowdepth, obstructions, DeadMold, Buildout, FillerStrip, SplitRail, SplitTilt, TPost, VerticalConfiguration]);

  // ]);
  const [VerifyStatus, setVerifyStatus] = useState('false');
  // Get Token
  const token = sessionStorage.getItem('token');
  const fetchdatafromCommonDrawer = (commondrawerstatus, result) => {
    setIsCommonDrawerOpen(commondrawerstatus);

    if (result === '') {
      setOtherMeasurement('');
    } else {
      setOtherMeasurement(result + '"');
    }
  };

  let StoreHeightWidth = {
    height: window_heightindecimal,
    width: window_widthindecimal,
  };
  const fetchdata = (data, drawerstatus, width, height, decimalwidth, decimalheight, result, cancelWidth, cancelHeight, CanclePassData, CancelGetDimesion, verifyStatus, BlankData, CloseResult, storeValue, onClickCancel, StoreHeightWidth) => {
    const W = width.replace(/"/g, '');
    setTotalWidth(W);

    if (BlankData !== 'blank') {
      const newValue222 = data.replace(/"/g, '');

      const newValue3333 = width.replace(/"/g, '');
      const newValue4444 = height.replace(/"/g, '');

      if (decimalwidth) {
        setSizeWindecimal(decimalwidth);
        setTotalWidth(decimalwidth);
      } else {
        setSizeWindecimal(newValue3333);
        setTotalWidth(parseFloat(newValue3333));
      }
      if (decimalheight) {
        setSizeHindecimal(decimalheight);
      } else {
        setSizeHindecimal(newValue4444);
      }
      setSizeH(height);

      setSize(data);
      setSizeW(newValue3333);
    } else if (BlankData === 'blank') {
      const newValue222 = CloseResult.replace(/"/g, '');

      const newValue3333 = width.replace(/"/g, '');
      const newValue4444 = height.replace(/"/g, '');

      setSizeWindecimal(cancelWidth);
      setSizeHindecimal(cancelHeight);

      setSizeH(height);

      if (onClickCancel === true) {
        setSize(storeValue);
        setSizeWindecimal(StoreHeightWidth.width);
        setSizeHindecimal(StoreHeightWidth.height);
        setTotalWidth(parseFloat(StoreHeightWidth.width));
      } else {
        setSize(CloseResult);
      }
      setSizeW(newValue3333);
    } else {
      setSize(data);
      setSizeW(width);
    }
    setIsNoDrawerOpen(drawerstatus);
    if (verifyStatus === 'true') {
      setVerifyStatus('true');
    }

    if (headrail === '1 Section') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');
    }
    if (headrail === '2 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');
      // For 2 Sections, divide the TotalWidth by 2
      let TotalWidth = totalWidth;
      const dividedValue = TotalWidth / 2;
      setSection1(dividedValue);
      setSection2(dividedValue);
    }
    if (headrail === '3 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      if (TotalWidth) {
        const dividedValue = TotalWidth / 3;
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
      } else {
      }
    }
    if (headrail === '4 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 4;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }
    if (headrail === '5 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 5;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
        setSection5(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }
    if (headrail === '6 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 6;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
        setSection4(dividedValue);
        setSection5(dividedValue);
        setSection6(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }
    if (VerticalConfiguration === '2 Sections') {
      const distributedValue = parseFloat(window_heightindecimal) / 2;

      setSectionOneTopShow(fracty(distributedValue) + '"');
      setSectionTwoBottomShow(fracty(distributedValue) + '"');
      setSectionOneTopHeigth(distributedValue);
      setSectionTwoBottomWidth(distributedValue);
    }
  };

  const getSaleAppDropDowns = async () => {
    const response = await fetch(`${CONFIG.API_URL}/wm/GetLookupTableData`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      token: token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      setGetLookupTableDataErrorMsgTxt(data.error_message);
      return setGetLookupTableDataErrorMsg(true);
    }
    setTypeMeasurement(data.MeasurementType);

    setExistingCoveringsData(data.ExistingCovering);
    setWindowDepthData(data.WindowDepth);
    setBuildTypeData(data.BuildType);
    setMountSurfaceData(data.MountSurface);
    setLadderData(data.Ladder);
    setObstructionsData(data.Obstructions);
    setTypeHeadrail(data.WindowConfiguration);
    setWindowSillData(data.Windowsill);
    setVerticalConfigurationData(data.VerticalConfiguration);
  };

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const getWindow = async () => {
    const response = await fetch(`${CONFIG.API_URL}/wm/Window/${params}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!data.error) {
      setTotalWidth(data.window.window_width);

      setWindowData(data.window);
      if (data.window.window_id) {
        setWindowId(data.window.window_id);
      }
      setRoomLabel(data.window.window_name);
      if (data.window.measurement_type === 'Quick') {
        setMinqty(data.window.window_quantity);
      }

      setCustomerID(location.state.data.customer_id);
      setAddressID(location.state.data.address_id);

      setWindowName(data.window.window_name);

      let actual_width = [];
      let actual_height = [];

      if (Number.isInteger(data.window.window_width)) {
        actual_width = data.window.window_width;
        setSizeW(actual_width);
        setSizeWindecimal(actual_width); // add
      } else {
        actual_width = data.window.window_width.toString().split('.');
        setSizeW(parseInt(actual_width[0]) + parseFloat('.' + actual_width[1]));
        setSizeWindecimal(parseInt(actual_width[0]) + parseFloat('.' + actual_width[1])); // add
      }

      if (Number.isInteger(data.window.window_height)) {
        actual_height = data.window.window_height;
        setSizeH(actual_height);
        setSizeHindecimal(actual_height); // add
      } else {
        actual_height = data.window.window_height.toString().split('.');
        let newheight = parseInt(actual_height[0]) + parseFloat('.' + actual_height[1]);

        setSizeH(newheight);
        setSizeHindecimal(newheight); // add
      }

      if (actual_width.length === 2 && actual_height.length === 2) {
        setSize(parseInt(actual_width[0]) + ' ' + fracty(parseFloat('.' + actual_width[1])) + '"' + ' x ' + parseInt(actual_height[0]) + ' ' + fracty(parseFloat('.' + actual_height[1])) + '"');
      } else if (actual_width.length === undefined && actual_height.length === 2) {
        setSize(actual_width + ' "' + ' x ' + parseInt(actual_height[0]) + ' ' + fracty(parseFloat('.' + actual_height[1])) + '"');
      } else if (actual_width.length === 2 && actual_height.length === undefined) {
        setSize(parseInt(actual_width[0]) + ' ' + fracty(parseFloat('.' + actual_width[1])) + '"' + ' x ' + actual_height + '"');
      } else {
        setSize(actual_width + '"' + ' x ' + actual_height + '"');
      }
      setMountsurface(data.window.mount_surface);
      setLadder(data.window.ladder);
      setExistingCovering(data.window.existing_covering);
      setBuildType(data.window.build_type);

      setMeasurementType(data.window.measurement_type);

      var mtf = data.window.measurement_type_specific_factor;

      var addtionalSKUDetails = [];

      switch (data.window.measurement_type) {
        case 'Blinds':
          addtionalSKUDetails = data.window.additional_sku;
          setAddNote(mtf.AddNote);
          if (mtf.WindowDepth === 'Other') {
            setOtherMeasurement(mtf.Othermeasurement + '"');
          } else if (mtf.WindowDepth === 'Standard') {
            setOtherMeasurement('');
          }
          // Convert LeftShade to decimal if it's not in decimal format
          if (!Number.isInteger(mtf.LeftShade)) {
            const decimalLeftShade = fracty(mtf.LeftShade);
            setLeftshade_size(decimalLeftShade + '"');
            setLeftshade_widthindecimal(mtf.LeftShade);
          } else {
            setLeftshade_size(mtf.LeftShade + '"');
          }

          // Convert RightShade to decimal if it's not in decimal format
          if (!Number.isInteger(mtf.RightShade)) {
            const decimalRightShade = fracty(mtf.RightShade);
            setRightshade_size(decimalRightShade + '"');
            setRightshade_widthindecimal(mtf.RightShade);
          } else {
            setRightshade_size(mtf.RightShade + '"');
          }

          // Convert CenterShade to decimal if it's not in decimal format
          if (!Number.isInteger(mtf.CenterShade)) {
            const decimalCenterShade = fracty(mtf.CenterShade);
            setCentershade_size(decimalCenterShade + '"');
            setCentershade_widthindecimal(mtf.CenterShade);
          } else {
            setCentershade_size(mtf.CenterShade + '"');
          }
          addtionalSKUDetails.forEach((ele) => {
            if (ele.type === 'Existing Covering') {
              setExistingCovering(ele.sku_name);
              setExistingSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Build Type') {
              setBuildType(ele.sku_name);
              setBuildSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Mount Surface') {
              setMountsurface(ele.sku_name);
              setMountSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Ladder') {
              setLadder(ele.sku_name);
              setLadderSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Window Depth') {
              setWindowDepth(ele.sku_name);
              setWindowDepthSku(ele.additional_charge_sku);
              if (ele.sku_name === 'Other') {
                setOtherMeasurement(ele.additional_detail + '"');
              } else if (ele.sku_name === 'Standard') {
                setOtherMeasurement('');
              }
            }

            if (ele.type === 'Window Configuration') {
              const additionalDetailJSON = JSON.parse(ele.additional_detail);
              setHeadrail(ele.sku_name);
              setHeadrailSku(ele.additional_charge_sku);
              setSection1(additionalDetailJSON.section1);
              setSection2(additionalDetailJSON.section2);
              setSection3(additionalDetailJSON.section3);
              setSection4(additionalDetailJSON.section4);
              setSection5(additionalDetailJSON.section5);
              setSection6(additionalDetailJSON.section6);
            }

            if (ele.type === 'Obstructions') {
              setObstructions(ele.sku_name);
              setObstructionsSku(ele.additional_charge_sku);
              if (ele.sku_name === 'Yes') {
                setDescription(JSON.parse(ele.additional_detail).join('; '));
              } else if (ele.sku_name === 'None') {
                setDescription('');
              }
            }
          });
          break;
        case 'Soft Shades':
          addtionalSKUDetails = data.window.additional_sku;

          // Convert LeftShade to decimal if it's not in decimal format
          if (!Number.isInteger(mtf.LeftShade)) {
            const decimalLeftShade = fracty(mtf.LeftShade);
            setLeftshade_size(decimalLeftShade);
            setLeftshade_widthindecimal(mtf.LeftShade);
          } else {
            setLeftshade_size(mtf.LeftShade);
          }

          // Convert RightShade to decimal if it's not in decimal format
          if (!Number.isInteger(mtf.RightShade)) {
            const decimalRightShade = fracty(mtf.RightShade);
            setRightshade_size(decimalRightShade);
            setRightshade_widthindecimal(mtf.RightShade);
          } else {
            setRightshade_size(mtf.RightShade);
          }

          // Convert CenterShade to decimal if it's not in decimal format
          if (!Number.isInteger(mtf.CenterShade)) {
            const decimalCenterShade = fracty(mtf.CenterShade);
            setCentershade_size(decimalCenterShade);
            setCentershade_widthindecimal(mtf.CenterShade);
          } else {
            setCentershade_size(mtf.CenterShade);
          }
          addtionalSKUDetails.forEach((ele) => {
            if (ele.type === 'Existing Covering') {
              setExistingCovering(ele.sku_name);
              setExistingSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Build Type') {
              setBuildType(ele.sku_name);
              setBuildSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Mount Surface') {
              setMountsurface(ele.sku_name);
              setMountSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Ladder') {
              setLadder(ele.sku_name);
              setLadderSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Headrail') {
              setHeadrail(ele.sku_name);
              setHeadrailSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Window Depth') {
              setWindowDepth(ele.sku_name);
              setWindowDepthSku(ele.additional_charge_sku);
              if (ele.sku_name === 'Other') {
                setOtherMeasurement(ele.additional_detail + '"');
              } else if (ele.sku_name === 'Standard') {
                setOtherMeasurement('');
              }
            }

            if (ele.type === 'Window Configuration') {
              const additionalDetailJSON = JSON.parse(ele.additional_detail);
              setHeadrail(ele.sku_name);
              setHeadrailSku(ele.additional_charge_sku);
              setSection1(additionalDetailJSON.section1);
              setSection2(additionalDetailJSON.section2);
              setSection3(additionalDetailJSON.section3);
              setSection4(additionalDetailJSON.section4);
              setSection5(additionalDetailJSON.section5);
              setSection6(additionalDetailJSON.section6);
            }

            if (ele.type === 'Obstructions') {
              setObstructions(ele.sku_name);
              setObstructionsSku(ele.additional_charge_sku);
              if (ele.sku_name === 'Yes') {
                setDescription(JSON.parse(ele.additional_detail).join('; '));
              } else if (ele.sku_name === 'None') {
                setDescription('');
              }
            }
          });
          break;
        case 'Roller Shades':
          addtionalSKUDetails = data.window.additional_sku;

          addtionalSKUDetails.forEach((ele) => {
            if (ele.type === 'Existing Covering') {
              setExistingCovering(ele.sku_name);
              setExistingSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Build Type') {
              setBuildType(ele.sku_name);
              setBuildSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Mount Surface') {
              setMountsurface(ele.sku_name);
              setMountSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Headrail') {
              setHeadrail(ele.sku_name);
              setHeadrailSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Ladder') {
              setLadder(ele.sku_name);
              setLadderSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Multiple') {
              setMultiple(ele.sku_name);
              setMultipleSKU(ele.additional_charge_sku);
            }
            if (ele.type === 'Window Configuration') {
              const additionalDetailJSON = JSON.parse(ele.additional_detail);
              setHeadrail(ele.sku_name);
              setHeadrailSku(ele.additional_charge_sku);
              setSection1(additionalDetailJSON.section1);
              setSection2(additionalDetailJSON.section2);
              setSection3(additionalDetailJSON.section3);
              setSection4(additionalDetailJSON.section4);
              setSection5(additionalDetailJSON.section5);
              setSection6(additionalDetailJSON.section6);
            }

            if (ele.type === 'Window Depth') {
              setWindowDepth(ele.sku_name);
              setWindowDepthSku(ele.additional_charge_sku);
              if (ele.sku_name === 'Other') {
                setOtherMeasurement(ele.additional_detail + '"');
              } else if (ele.sku_name === 'Standard') {
                setOtherMeasurement('');
              }
            }
            if (ele.type === 'Obstructions') {
              setObstructions(ele.sku_name);
              setObstructionsSku(ele.additional_charge_sku);
              if (ele.sku_name === 'Yes') {
                setDescription(JSON.parse(ele.additional_detail).join('; '));
              } else if (ele.sku_name === 'None') {
                setDescription('');
              }
            }
          });

          break;
        case 'Quick':
          addtionalSKUDetails = data.window.additional_sku;

          addtionalSKUDetails.forEach((ele) => {
            if (ele.type === 'Existing Covering') {
              setExistingCovering(ele.sku_name);
              setExistingSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Build Type') {
              setBuildType(ele.sku_name);
              setBuildSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Mount Surface') {
              setMountsurface(ele.sku_name);
              setMountSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Ladder') {
              setLadder(ele.sku_name);
              setLadderSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Window Depth') {
              setWindowDepth(ele.sku_name);
              setWindowDepthSku(ele.additional_charge_sku);
              if (ele.sku_name === 'Other') {
                setOtherMeasurement(ele.additional_detail + '"');
              } else if (ele.sku_name === 'Standard') {
                setOtherMeasurement('');
              }
            }
            if (ele.type === 'Obstructions') {
              setObstructions(ele.sku_name);
              setObstructionsSku(ele.additional_charge_sku);
              if (ele.sku_name === 'Yes') {
                setDescription(JSON.parse(ele.additional_detail).join('; '));
              } else if (ele.sku_name === 'None') {
                setDescription('');
              }
            }
          });
          break;
        case 'Shutters':
          setPanel(data.window.measurement_type_specific_factor.WindowConfiguration);
          setWindowsill(data.window.measurement_type_specific_factor.Windowsill);
          setAddNoteforBuildOut(mtf.AddNoteforBuildOut);
          setAddNoteforFillerStrip(mtf.AddNoteforFillerStrip);
          setAddNoteforSplitRail(mtf.AddNoteforSplitRail);
          setAddNoteforSplitTilt(mtf.AddNoteforSplitTilt);

          addtionalSKUDetails = data.window.additional_sku;

          addtionalSKUDetails.forEach((ele) => {
            if (ele.type === 'Existing Covering') {
              setExistingCovering(ele.sku_name);
              setExistingSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Build Type') {
              setBuildType(ele.sku_name);
              setBuildSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Mount Surface') {
              setMountsurface(ele.sku_name);
              setMountSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Headrail') {
              setHeadrail(ele.sku_name);
              setHeadrailSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Ladder') {
              setLadder(ele.sku_name);
              setLadderSku(ele.additional_charge_sku);
            }
            if (ele.type === 'Vertical Configuration') {
              setVerticalConfiguration(ele.sku_name);
              setVerticalConfigurationSku(ele.additional_charge_sku);
              if (ele.sku_name === '2 Sections') {
                let SectionsValue = JSON.parse(ele.additional_detail);
                // top height
                setSectionOneTopHeigth(SectionsValue.SectionOneTop);
                setSectionOneTopShow(fracty(SectionsValue.SectionOneTop) + '"');
                // bottom height
                setSectionTwoBottomWidth(SectionsValue.SectionTwoBottom);
                setSectionTwoBottomShow(fracty(SectionsValue.SectionTwoBottom) + '"');
              }
            }
            if (ele.type === 'Window Depth') {
              setWindowDepth(ele.sku_name);
              setWindowDepthSku(ele.additional_charge_sku);
              if (ele.sku_name === 'Other') {
                setOtherMeasurement(ele.additional_detail + '"');
              } else if (ele.sku_name === 'Standard') {
                setOtherMeasurement('');
              }
            }
            if (ele.type === 'Window Configuration') {
              const additionalDetailJSON = JSON.parse(ele.additional_detail);
              setHeadrail(ele.sku_name);
              setHeadrailSku(ele.additional_charge_sku);
              setSection1(additionalDetailJSON.section1);
              setSection2(additionalDetailJSON.section2);
              setSection3(additionalDetailJSON.section3);
              setSection4(additionalDetailJSON.section4);
              setSection5(additionalDetailJSON.section5);
              setSection6(additionalDetailJSON.section6);
            }
            if (ele.type === 'Obstructions') {
              setObstructions(ele.sku_name);
              setObstructionsSku(ele.additional_charge_sku);
              if (ele.sku_name === 'Yes') {
                setDescription(JSON.parse(ele.additional_detail).join('; '));
              } else if (ele.sku_name === 'None') {
                setDescription('');
              }
            }
          });

          break;
      }
    } else {
      setWindowApiErrMsgTxt(data.error_message);
      setWindowErrorMsg(true);
    }
  };

  customer_Id = location.state.customer_id;

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUpdateWindownameOpen(false);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const checkSize = (newval) => {
    let actual_width = [];
    let actual_height = [];
    if (newval !== size) {
      setValueChanged(true);
    }
    setSize(newval);
    let val = newval;
    var width = '';
    var height = '';

    if (val.includes('x')) {
      let actual_size = [];
      actual_size = val.split('x');
      width = actual_size[0];
      height = actual_size[1];

      if (!((actual_size[0] + '').indexOf('/') > -1)) {
        actual_width = width;
        setSizeW(actual_width);
        setSizeWindecimal(actual_width); // add
      } else {
        actual_width = width.split(' ');

        var fractionalnos = actual_width[1].split('/');
        var fractiontodecimal = parseInt(actual_width[0]) + parseFloat(fractionalnos[0] / fractionalnos[1]);
        setSizeW(fractiontodecimal);
        setSizeWindecimal(fractiontodecimal); // add
      }
      if (!((actual_size[1] + '').indexOf('/') > -1)) {
        actual_height = height;
        setSizeH(actual_height);
        setSizeHindecimal(actual_height); // add
      } else {
        actual_height = height.split(' ');

        var fractionalnos = actual_height[2].split('/');
        var fractiontodecimal = parseInt(actual_height[1]) + parseFloat(fractionalnos[0] / fractionalnos[1]);
        setSizeH(fractiontodecimal);
        setSizeHindecimal(fractiontodecimal); // add
      }
    } else if (val.includes('X')) {
      let actual_size = [];
      actual_size = val.split('x');
      width = actual_size[0];
      height = actual_size[1];
      setSizeW(width);
      setSizeWindecimal(width); // add
      setSizeH(height);
      setSizeHindecimal(height); // add
    } else if (val.includes('*')) {
      const strpos = val.indexOf('*');

      function split(str, index) {
        const result = [str.slice(0, index), str.slice(index + 1)];

        return result;
      }
      const [width, height] = split(val, strpos);

      setSizeW(width);
      setSizeWindecimal(width); // add
      setSizeH(height);
      setSizeHindecimal(height); // add
    }
  };

  const handleMeasurementType = (e) => {
    setMeasurementType(e.target.value);
    if (e.target.value === 'Quick') {
      setbuildTypeReq('');
    } else {
      setbuildTypeReq(
        <>
          <span style={{ color: '#DB3131' }}>*</span>&nbsp;
        </>
      );
    }
    if (e.target.value !== 'Roller Shades' && buildType === 'Fabric') {
      setBuildType('');
    } else {
      setBuildType(buildType);
    }
    if (windowdepth !== '') {
      WindowDepthData.forEach((v) => {
        if (v.service_name === windowdepth) {
          setWindowDepthSku(v.sku);
        }
      });
    } else {
      setExistingSku('');
    }
    if (rolldirection !== '') {
      TypeRollDirection.forEach((r) => {
        if (r.service_name === rolldirection) {
          setRollDirectionSKU(r.sku);
        }
      });
    }

    if (multiple !== '') {
      TypeMultiple.forEach((m) => {
        if (m.service_name === multiple) {
          setMultipleSKU(m.sku);
        }
      });
    }
    if (headrail !== '') {
      TypeHeadrail.forEach((h) => {
        if (h.service_name === headrail) {
          setHeadrailSku(h.sku);
        }
      });
    } else {
      setHeadrailSku('');
    }
    if (windowdepth !== '') {
      WindowDepthData.forEach((v) => {
        if (v.valance_return === windowdepth) {
          setWindowDepthSku(v.sku);
        }
      });
    } else {
      setWindowDepthSku('');
    }
    if (obstructions !== '') {
      ObstructionsData.forEach((c) => {
        if (c.service_name === obstructions) {
          setObstructionsSku(c.sku);
        }
      });
    } else {
      setObstructionsSku('');
    }
    if (multiple !== '') {
      TypeMultiple.forEach((m) => {
        if (m.multiple === multiple) {
          setMultipleSKU(m.sku);
        }
      });
    } else {
      setMultipleSKU('');
    }
    if (rolldirection !== '') {
      TypeRollDirection.forEach((r) => {
        if (r.roll_direction === rolldirection) {
          setRollDirectionSKU(r.sku);
        }
      });
    } else {
      setRollDirectionSKU('');
    }

    // shutters

    if (VerticalConfiguration !== '') {
      VerticalConfigurationData.forEach((v) => {
        if (v.service_name === VerticalConfiguration) {
          setVerticalConfigurationSku(v.sku);
        }
      });
    } else {
      setVerticalConfigurationSku('');
    }
  };

  const handleExistingCovering = (e) => {
    setValueChanged(true);
    setExistingCovering(e.target.value);
  };

  const handleVerticalConfiguration = (e) => {
    setVerticalConfiguration(e.target.value);

    if (e.target.value === '1 Section') {
      setSectionOneTopShow('');
      setSectionTwoBottomShow('');
      setSectionOneTopHeigth('');
      setSectionTwoBottomWidth('');
    }

    if (e.target.value === '2 Sections') {
      const distributedValue = parseFloat(window_heightindecimal) / 2;

      setSectionOneTopShow(fracty(distributedValue) + '"');
      setSectionTwoBottomShow(fracty(distributedValue) + '"');
      setSectionOneTopHeigth(distributedValue);
      setSectionTwoBottomWidth(distributedValue);
    }
  };

  // Update the window details
  const SaveWindowChanges = async () => {
    let ActualbuildType = '';

    if (measurementType !== 'Roller Shades' && buildType === 'Fabric') {
      ActualbuildType = '';
      setBuildType('');
    } else {
      ActualbuildType = buildType;
      setBuildType(buildType);
    }
    let measurement_type_specific_factor = {};
    switch (measurementType) {
      case 'Blinds':
        measurement_type_specific_factor = {
          WindowDepth: windowdepth,
          WindowConfiguration: headrail,
          LeftShade: leftshade_widthindecimal,
          CenterShade: centershade_widthindecimal,
          RightShade: rightshade_widthindecimal,
          Obstructions: obstructions,
          Description: description,
          Othermeasurement: othermeasurement.replace(/"/g, ''),
        };
        break;
      case 'Quick':
        measurement_type_specific_factor = {
          WindowDepth: windowdepth,
          Obstructions: obstructions,
          Othermeasurement: othermeasurement.replace(/"/g, ''),
        };
        break;
      case 'Soft Shades':
        measurement_type_specific_factor = {
          WindowConfiguration: headrail,
          WindowDepth: windowdepth,
          LeftShade: leftshade_widthindecimal,
          CenterShade: centershade_widthindecimal,
          RightShade: rightshade_widthindecimal,
          Othermeasurement: othermeasurement.replace(/"/g, ''),
          Obstructions: obstructions,
          Description: description,
        };
        break;
      case 'Roller Shades':
        measurement_type_specific_factor = {
          WindowDepth: windowdepth,
          WindowConfiguration: multiple,
          Othermeasurement: othermeasurement.replace(/"/g, ''),
          Obstructions: obstructions,
          Description: description,
        };
        break;
      case 'Shutters':
        if (SplitTilt === 'No') {
          setAddNoteforSplitTilt('');
        }
        if (SplitRail === 'No') {
          setAddNoteforSplitRail('');
        }
        measurement_type_specific_factor = {
          WindowDepth: windowdepth,
          WindowConfiguration: Panel,
          Windowsill: Windowsill,
          VerticalConfiguration: VerticalConfiguration,
          SectionOneTop: SectionOneTopHeigth,
          SectionTwoBottom: SectionTwoBottomWidth,
          Othermeasurement: othermeasurement.replace(/"/g, ''),
        };
        break;
      default:
        measurement_type_specific_factor = {};
        break;
    }
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        window_width: window_widthindecimal,
        window_height: window_heightindecimal,
        build_type: ActualbuildType,
        mount_surface: mountsurface,
        ladder: ladder,
        window_id: windowData.window_id,
        customer_id: customerID,
        address_id: addressID,
        existing_covering: existingCovering,
        measurementType: measurementType,
        window_name: windowName,
        existing_covering: existingCovering,
        measurementType: measurementType,
        window_name: windowName,
        measurement_type_specific_factor: measurement_type_specific_factor,
        additional_sku: additional_sku,
      }),
    };

    //Window Set will be updated
    await fetch(`${CONFIG.API_URL}/wm/Window`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setOpen(true);
          setValueChanged(false);
          setTimeout(function () {
            navigate(`/tbm/ps/windowset/${location.state.windowSetID}`, {
              state: {
                windowSetId: location.state.row_id,
              },
            });
          }, 2000);
        } else {
          setUpdateWindowErrorMsgTxt(data.error_message);
          setUpdateWindowErrorMsg(true);
        }
      });
  };

  const windowNameUpdateDrawer = (name, drawerstatus, message) => {
    setIsDrawerOpen(drawerstatus);

    if (message === 'Window is updated successfully!') {
      setUpdateWindownameOpen(true);
      setClassType('Success2 mainAlert');
      setSuccessMessage('Window name is updated successfully!');
      setWindowName(name);
    } else if (message === 'Window name already exists.') {
      setUpdateWindownameOpen(true);
      setClassType('Error mainAlert');
      setSuccessMessage('Window name already exists.');
    } else {
      setUpdateWindownameOpen(false);
      setClassType('');
    }

    // Window name is already exists
  };

  const handleheadrailtype = (e) => {
    setHeadrail(e.target.value);
    if (e.target.value === '1 Section') {
      setLeftshade_size('');
      setRightshade_size('');
      setCentershade_size('');
      setLeftshade_widthindecimal('');
      setRightshade_widthindecimal('');
      setCentershade_widthindecimal('');
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');
    }
    if (e.target.value === '2 Sections') {
      setLeftshade_size('');
      setRightshade_size('');
      setCentershade_size('');
      setLeftshade_widthindecimal('');
      setRightshade_widthindecimal('');
      setCentershade_widthindecimal('');
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');
      // For 2 Sections, divide the TotalWidth by 2
      let TotalWidth = totalWidth;
      const dividedValue = TotalWidth / 2;
      setLeftshade_size(fracty(dividedValue) + '"');
      setRightshade_size(fracty(dividedValue) + '"');
      setRightshade_widthindecimal(dividedValue);
      setLeftshade_widthindecimal(dividedValue);
      setSection1(dividedValue);
      setSection2(dividedValue);
    }

    if (e.target.value === '3 Sections') {
      setLeftshade_size('');
      setRightshade_size('');
      setCentershade_size('');
      setLeftshade_widthindecimal('');
      setRightshade_widthindecimal('');
      setCentershade_widthindecimal('');
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      if (TotalWidth) {
        const dividedValue = TotalWidth / 3;
        setCentershade_size(fracty(dividedValue) + '"');
        setLeftshade_size(fracty(dividedValue) + '"');
        setRightshade_size(fracty(dividedValue) + '"');
        setRightshade_widthindecimal(dividedValue);
        setLeftshade_widthindecimal(dividedValue);
        setCentershade_widthindecimal(dividedValue);
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
      } else {
      }
    }
    if (e.target.value === '4 Sections') {
      setLeftshade_size('');
      setRightshade_size('');
      setCentershade_size('');
      setLeftshade_widthindecimal('');
      setRightshade_widthindecimal('');
      setCentershade_widthindecimal('');
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 4;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }

    if (e.target.value === '5 Sections') {
      setLeftshade_size('');
      setRightshade_size('');
      setCentershade_size('');
      setLeftshade_widthindecimal('');
      setRightshade_widthindecimal('');
      setCentershade_widthindecimal('');
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 5;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
        setSection5(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }

    if (e.target.value === '6 Sections') {
      setLeftshade_size('');
      setRightshade_size('');
      setCentershade_size('');
      setLeftshade_widthindecimal('');
      setRightshade_widthindecimal('');
      setCentershade_widthindecimal('');
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 6;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
        setSection4(dividedValue);
        setSection5(dividedValue);
        setSection6(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }
  };

  const [ExistingSku, setExistingSku] = useState();
  const [BuildSku, setBuildSku] = useState();
  const [MountSku, setMountSku] = useState();
  const [LadderSku, setLadderSku] = useState();
  const [WindowDepthSku, setWindowDepthSku] = useState();

  const [HeadrailSku, setHeadrailSku] = useState();
  const [ObstructionsSku, setObstructionsSku] = useState();

  const [VerticalConfigurationSku, setVerticalConfigurationSku] = useState();

  let window_configuration = {
    section1,
    section2,
    section3,
    section4,
    section5,
    section6,
  };

  if (measurementType === 'Blinds') {
    additional_sku = [
      {
        additional_charge_sku: ExistingSku,
        sku_name: existingCovering,
        label: null,
        type: 'Existing Covering',
      },
      {
        additional_charge_sku: HeadrailSku,
        sku_name: headrail,
        label: 'null',
        type: 'Window Configuration',
        additional_detail: window_configuration,
      },
      {
        additional_charge_sku: LadderSku,
        sku_name: ladder,
        label: null,
        type: 'Ladder',
      },
      {
        additional_charge_sku: MountSku,
        sku_name: mountsurface,
        label: null,
        type: 'Mount Surface',
      },
      {
        additional_charge_sku: WindowDepthSku,
        sku_name: windowdepth,
        label: null,
        type: 'Window Depth',
        additional_detail: othermeasurement.replace(/"/g, ''),
      },
      {
        additional_charge_sku: ObstructionsSku,
        sku_name: obstructions,
        label: null,
        type: 'Obstructions',
        additional_detail: JSON.stringify(description.split(';')),
      },
      {
        additional_charge_sku: BuildSku,
        sku_name: buildType,
        label: null,
        type: 'Build Type',
      },
    ];
  } else if (measurementType === 'Soft Shades') {
    additional_sku = [
      {
        additional_charge_sku: ExistingSku,
        sku_name: existingCovering,
        label: null,
        type: 'Existing Covering',
      },
      {
        additional_charge_sku: HeadrailSku,
        sku_name: headrail,
        label: 'null',
        type: 'Window Configuration',
        additional_detail: window_configuration,
      },
      {
        additional_charge_sku: LadderSku,
        sku_name: ladder,
        label: null,
        type: 'Ladder',
      },
      {
        additional_charge_sku: MountSku,
        sku_name: mountsurface,
        label: null,
        type: 'Mount Surface',
      },
      {
        additional_charge_sku: BuildSku,
        sku_name: buildType,
        label: null,
        type: 'Build Type',
      },
      {
        additional_charge_sku: WindowDepthSku,
        sku_name: windowdepth,
        label: null,
        type: 'Window Depth',
        additional_detail: othermeasurement.replace(/"/g, ''),
      },
      {
        additional_charge_sku: ObstructionsSku,
        sku_name: obstructions,
        label: null,
        type: 'Obstructions',
        additional_detail: JSON.stringify(description.split(';')),
      },
    ];
  } else if (measurementType === 'Quick') {
    additional_sku = [
      {
        additional_charge_sku: ExistingSku,
        sku_name: existingCovering,
        label: null,
        type: 'Existing Covering',
      },
      {
        additional_charge_sku: LadderSku,
        sku_name: ladder,
        label: null,
        type: 'Ladder',
      },
      {
        additional_charge_sku: MountSku,
        sku_name: mountsurface,
        label: null,
        type: 'Mount Surface',
      },
      {
        additional_charge_sku: BuildSku,
        sku_name: buildType,
        label: null,
        type: 'Build Type',
      },
      {
        additional_charge_sku: WindowDepthSku,
        sku_name: windowdepth,
        label: null,
        type: 'Window Depth',
        additional_detail: othermeasurement.replace(/"/g, ''),
      },
      {
        additional_charge_sku: ObstructionsSku,
        sku_name: obstructions,
        label: null,
        type: 'Obstructions',
        additional_detail: JSON.stringify(description.split(';')),
      },
    ];
  } else if (measurementType === 'Shutters') {
    additional_sku = [
      {
        additional_charge_sku: ExistingSku,
        sku_name: existingCovering,
        label: null,
        type: 'Existing Covering',
      },
      {
        additional_charge_sku: HeadrailSku,
        sku_name: headrail,
        label: 'null',
        type: 'Window Configuration',
        additional_detail: window_configuration,
      },
      {
        additional_charge_sku: LadderSku,
        sku_name: ladder,
        label: null,
        type: 'Ladder',
      },
      {
        additional_charge_sku: MountSku,
        sku_name: mountsurface,
        label: null,
        type: 'Mount Surface',
      },
      {
        additional_charge_sku: BuildSku,
        sku_name: buildType,
        label: null,
        type: 'Build Type',
      },
      {
        additional_charge_sku: WindowDepthSku,
        sku_name: windowdepth,
        label: null,
        type: 'Window Depth',
        additional_detail: othermeasurement.replace(/"/g, ''),
      },
      {
        additional_charge_sku: ObstructionsSku,
        sku_name: obstructions,
        label: null,
        type: 'Obstructions',
        additional_detail: JSON.stringify(description.split(';')),
      },
      {
        additional_charge_sku: 'null',
        sku_name: Windowsill,
        label: null,
        type: 'Window Sill',
        additional_detail: '',
      },
      {
        additional_charge_sku: VerticalConfigurationSku,
        sku_name: VerticalConfiguration,
        label: null,
        type: 'Vertical Configuration',
        additional_detail: {
          SectionOneTop: SectionOneTopHeigth,
          SectionTwoBottom: SectionTwoBottomWidth,
        },
      },
    ];
  } else {
    additional_sku = [
      {
        additional_charge_sku: ExistingSku,
        sku_name: existingCovering,
        label: null,
        type: 'Existing Covering',
      },
      {
        additional_charge_sku: HeadrailSku,
        sku_name: headrail,
        label: 'null',
        type: 'Window Configuration',
        additional_detail: window_configuration,
      },
      {
        additional_charge_sku: LadderSku,
        sku_name: ladder,
        label: null,
        type: 'Ladder',
      },
      {
        additional_charge_sku: MountSku,
        sku_name: mountsurface,
        label: null,
        type: 'Mount Surface',
      },
      {
        additional_charge_sku: BuildSku,
        sku_name: buildType,
        label: null,
        type: 'Build Type',
      },
      {
        additional_charge_sku: MultipleSKU,
        sku_name: multiple,
        label: null,
        type: 'Multiple',
      },

      {
        additional_charge_sku: WindowDepthSku,
        sku_name: windowdepth,
        label: null,
        type: 'Window Depth',
        additional_detail: othermeasurement.replace(/"/g, ''),
      },
      {
        additional_charge_sku: ObstructionsSku,
        sku_name: obstructions,
        label: null,
        type: 'Obstructions',
        additional_detail: JSON.stringify(description.split(';')),
      },
    ];
  }

  useEffect(() => {}, [additional_sku]);

  const [notesContent, setnotesContent] = useState([]);
  const [EditNotes, setEditNote] = useState('');
  const [notes, setNotes] = useState(false);
  const [notesData, setNotesData] = useState('');
  const [notesDataDescription, setNotesDataDescription] = useState([]);
  const [noteTypeId, setNoteTypeId] = useState('');
  const [noteId, setNoteId] = useState([]);
  const [notesContentForWindow, setNotesContentForWindow] = useState([]);
  const [eyeButtonMsg, setEyeButtonMsg] = useState(false);

  const NotesDrawerData = (notesDrawerStatus, notesMsgAlert, notesMsg, eyeButtonMsg, referenceId, noteId, noteContent) => {
    setNotes(notesDrawerStatus);
    setEyeButtonMsg(eyeButtonMsg);

    if (notesMsg === 'Note added successfully!' || notesMsg === 'Note updated successfully!') {
      getNotes();
    }
    setNoteId(noteId);
    setNotesContentForWindow(noteContent);
    setEyeButtonMsg(eyeButtonMsg);
  };

  const NotesCustomerDetails = {
    firstName: location.state.data.first_name,
    lastname: location.state.data.last_name,
    customerId: location.state.data.customer_id,
    proposalId: '',
    windowSetId: '',
    WindowSetName: '',
    WindowId: location.state.row_id,
    windowName: windowName,
  };

  const [valueValue, setValueValue] = useState();

  const EditNotesCustomerDetails = {
    firstName: location.state.data.first_name,
    lastname: location.state.data.last_name,
    customerId: location.state.data.customer_id,
    proposalId: '',
    windowSetId: '',
    WindowSetName: '',
    WindowdId: location.state.row_id,
    notesContent: notesData,
    noteId: noteId,
    noteTypeId: noteTypeId,
    eyeButtonMsg: eyeButtonMsg,
    notesDataDescription: notesDataDescription,
    valueValue: valueValue,
    windowName: windowName,
  };

  const getNotes = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    await fetch(`${CONFIG.API_URL}/cm/Notes/${location.state.row_id}`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setnotesContent(data.Notes);
        }
      });
  };

  const LongText = ({ content }) => {
    const [limit, setLimit] = useState(CONFIG.GlobalTruncateCharacterLimit);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    if (content === undefined) {
      // Data is undefined, return empty
      return null;
    }

    // Check if the window width is between 280px and 768px and content length is greater than 14
    if (windowWidth >= 280 && windowWidth <= 767 && content.length > 15) {
      const truncatedContent = content.substring(0, 15) + '...';
      return <span>{truncatedContent}</span>;
    }

    // Check if the window width is above or equal to 768
    if (windowWidth >= 768) {
      if (content.length <= limit) {
        // There is nothing more to show
        return <span>{content}</span>;
      }

      let truncatedContent = content.substring(0, limit);

      // Find the last space within the truncated content
      const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

      // Adjust the truncated content if a space was found
      if (lastSpaceIndex !== -1) {
        truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
      }

      // Add the ellipsis to the truncated content
      const toShow = truncatedContent + '...';

      return <span>{toShow}</span>;
    }

    if (content.length <= limit) {
      // There is nothing more to show
      return <span>{content}</span>;
    }

    let truncatedContent = content.substring(0, limit);

    // Find the last space within the truncated content
    const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

    // Adjust the truncated content if a space was found
    if (lastSpaceIndex !== -1) {
      truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
    }

    // Add the ellipsis to the truncated content
    const toShow = truncatedContent + '...';

    return <span>{toShow}</span>;
  };

  const fetchdata2 = (data, drawerstatus, width, decimalwidth, StoreHeightWidth, decimalheight, result, cancelWidth, cancelHeight, CanclePassData, storedSection, onClickSectionCancel, sectionPosition) => {
    if (WidthType === 'LeftWidth') {
    }

    // To Manually Enter the Values in Window Config. Sections
    else if (WidthType === 'Section 1') {
      // Add your logic for "Section 1" here
      setSection1(decimalwidth);
      if (!sectionValues.section1.changed) {
        setSection1(decimalwidth);
        sectionValues.section1.value = decimalwidth;

        setChangedSection1(true);
      } else {
      }

      // setLeftShadeisDrawerOpen(drawerstatus);
    } else if (WidthType === 'Section 2') {
      // Add your logic for "2Section" here
      setSection2(decimalwidth);
      if (!sectionValues.section2.changed) {
        setSection2(decimalwidth);
        sectionValues.section2.value = decimalwidth;

        setChangedSection2(true);
      } else {
      }
    } else if (WidthType === 'Section 3') {
      // Add your logic for "3Section" here
      setSection3(decimalwidth);
      if (!sectionValues.section3.changed) {
        setSection3(decimalwidth);
        sectionValues.section3.value = decimalwidth;
        setChangedSection3(true);
      } else {
      }
    } else if (WidthType === 'Section 4') {
      // Add your logic for "4Section" here
      setSection4(decimalwidth);
      if (!sectionValues.section4.changed) {
        setSection4(decimalwidth);
        sectionValues.section4.value = decimalwidth;
        setChangedSection4(true);
      } else {
      }
    } else if (WidthType === 'Section 5') {
      // Add your logic for "5Section" here
      setSection5(decimalwidth);
      if (!sectionValues.section5.changed) {
        setSection5(decimalwidth);
        sectionValues.section5.value = decimalwidth;
        setChangedSection5(true);
      } else {
      }
    } else {
    }

    // Create an array to store section values
    const sections = [section1, section2, section3, section4, section5, section6];

    let totalOfTrueSections = 0; //Sum of the true sections

    // JSON to store the values of sections that are marked as 'true'

    // Loop through sectionValues and determine true sections
    for (let i = 1; i <= 6; i++) {
      const sectionKey = `section${i}`;
      if (sectionValues[sectionKey].changed) {
        totalOfTrueSections += parseFloat(sections[i - 1]);
        trueSections[`section${i}`] = sectionValues[sectionKey].value;
      }
    }

    // Calculate the total value that needs to be distributed among other sections
    const totalToDistribute = totalWidth - totalOfTrueSections;
    //Value to be distributed

    let numberOfSections;

    if (headrail === '6 Sections') {
      numberOfSections = 6;
    } else if (headrail === '5 Sections') {
      numberOfSections = 5;
    } else if (headrail === '4 Sections') {
      numberOfSections = 4;
    } else if (headrail === '3 Sections') {
      numberOfSections = 3;
    } else if (headrail === '2 Sections') {
      numberOfSections = 2;
    } else {
    }

    // Calculate the value to distribute to each section
    const remainingSections = numberOfSections - Object.keys(trueSections).length;

    const distributedValue = totalToDistribute / remainingSections;

    for (let i = 1; i <= 6; i++) {
      const sectionKey = `section${i}`;
      if (!sectionValues[sectionKey].changed) {
        if (i > numberOfSections) {
          sectionValues[sectionKey].value = '';
        } else {
          sectionValues[sectionKey].value = distributedValue;
        }
      }
    }

    setLeftShadeisDrawerOpen(drawerstatus);

    if (onClickSectionCancel === true) {
      if (sectionPosition === 'Section 1') {
        setSection1(storedSection);
      } else if (sectionPosition === 'Section 2') {
        setSection2(storedSection);
      } else if (sectionPosition === 'Section 3') {
        setSection3(storedSection);
      } else if (sectionPosition === 'Section 4') {
        setSection4(storedSection);
      } else if (sectionPosition === 'Section 5') {
        setSection5(storedSection);
      }

      // Set all the "changed" values to false in sectionValues
      for (const sectionKey in sectionValues) {
        if (sectionValues.hasOwnProperty(sectionKey)) {
          sectionValues[sectionKey].changed = false;
        }
      }
    } else {
    }
  };

  useEffect(() => {
    if (headrail === '2 Sections') {
      if (section1 > totalWidth) {
        setwarnQty(true);
        setMsg('Section 1 is greater than ' + fracty(totalWidth));
      } else if (totalWidth === '') {
      } else if (section1 === totalWidth) {
        setwarnQty(true);
        setMsg('Section 1 is equal to ' + fracty(totalWidth));
      } else {
      }
    } else if (headrail === '3 Sections') {
      // Check if section1 and section2 are not blank and are valid numbers
      if (!isNaN(section1) && !isNaN(section2)) {
        const section1Value = parseFloat(section1);
        const section2Value = parseFloat(section2);

        // Check if totalWidth is a valid number
        if (!isNaN(totalWidth)) {
          const sum = section1Value + section2Value;

          if (section1Value === 0 || isNaN(section1Value)) {
          } else if (totalWidth === '') {
          } else if (section1Value >= totalWidth) {
            setSection2('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 1 is greater than or equal to ' + fracty(totalWidth));
          } else if (section2Value >= totalWidth) {
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (sum >= totalWidth) {
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1 and 2 is greater than or equal to ' + fracty(totalWidth));
          } else {
            // Calculate section3 outside of the "else if" block
            const section3Value = totalWidth - sum;
            if (section3Value > 0) {
              setSection3(section3Value);
              // Clear any previous alert message
              setwarnQty(false);
              setMsg('');
            } else {
              setSection3('');
            }
          }
        } else {
          console.error('totalWidth is not a valid number.');
          setSection3('');
        }
      } else {
        // Handle the case where section1 or section2 is not a valid number
        console.error('Sections 1 and 2 must be valid numbers.');
      }

      // Clear section4, section5, and section6
      setSection4('');
      setSection5('');
      setSection6('');
    } else if (headrail === '4 Sections') {
      // Check if section1, section2, and section3 are not blank and are valid numbers
      if (!isNaN(section1) && !isNaN(section2) && !isNaN(section3)) {
        const section1Value = parseFloat(section1);
        const section2Value = parseFloat(section2);
        const section3Value = parseFloat(section3);

        // Check if totalWidth is a valid number
        if (!isNaN(totalWidth)) {
          const sum = section1Value + section2Value + section3Value;

          if (section1Value === 0 || isNaN(section1Value)) {
          } else if (totalWidth === '') {
          } else if (section1Value >= totalWidth) {
            setSection2('');
            setSection3('');
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 1 is greater than or equal to ' + fracty(totalWidth));
          } else if (section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section3Value >= totalWidth) {
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (sum >= totalWidth) {
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2 and 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1 and 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value >= totalWidth) {
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2 and 3 is greater than or equal to ' + fracty(totalWidth));
          } else {
            // Calculate section4 outside of the "else if" block
            const section4Value = totalWidth - sum;
            if (section4Value > 0) {
              setSection4(section4Value);
              // Clear any previous alert message
              setwarnQty(false);
              setMsg('');
            } else {
              setSection4('');
            }
          }
        } else {
          console.error('totalWidth is not a valid number.');
          setSection4('');
        }
      } else {
        // Handle the case where any of section1, section2, or section3 is not a valid number
        console.error('Sections 1, 2 and 3 must be valid numbers.');
      }

      // Clear section5 and section6
      setSection5('');
      setSection6('');
    }
    // Case for 5 Sections
    else if (headrail === '5 Sections') {
      if (!isNaN(section1) && !isNaN(section2) && !isNaN(section3) && !isNaN(section4)) {
        const section1Value = parseFloat(section1);
        const section2Value = parseFloat(section2);
        const section3Value = parseFloat(section3);
        const section4Value = parseFloat(section4);
        // Check if totalWidth is a valid number
        if (!isNaN(totalWidth)) {
          const sum = section1Value + section2Value + section3Value + section4Value; // Include section4Value in the sum

          if (section1Value === 0 || isNaN(section1Value)) {
          } else if (totalWidth === '') {
          } else if (section1Value >= totalWidth) {
            setSection2('');
            setSection3('');
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 1 is greater than or equal to ' + fracty(totalWidth));
          } else if (section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section3Value >= totalWidth) {
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section4Value >= totalWidth) {
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 4 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1 and 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value >= totalWidth) {
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2 and 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value + section4Value >= totalWidth) {
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2, 3, 4 and 5 is greater than or equal to ' + fracty(totalWidth));
          } else if (sum >= totalWidth) {
            setSection5('');
          } else {
            // Calculate section5 outside of the "else if" block
            const section5Value = totalWidth - sum;
            if (section5Value >= 0) {
              setSection5(section5Value);
            } else {
              setSection5('');
            }
          }
        } else {
          console.error('totalWidth is not a valid number.');
          setSection5('');
        }
      } else {
        // Handle the case where any of section is blank
        console.error('Sections 1, 2, 3 and 4 must not be blank.');
      }
      setSection6('');
    }
    // Case for 6 Sections
    else if (headrail === '6 Sections') {
      if (!isNaN(section1) && !isNaN(section2) && !isNaN(section3) && !isNaN(section4) && !isNaN(section5)) {
        const section1Value = parseFloat(section1);
        const section2Value = parseFloat(section2);
        const section3Value = parseFloat(section3);
        const section4Value = parseFloat(section4);
        const section5Value = parseFloat(section5);
        const totalWidthValue = parseFloat(totalWidth);

        if (!isNaN(totalWidthValue)) {
          const sum = section1Value + section2Value + section3Value + section4Value + section5Value;

          if (section1Value === 0 || isNaN(section1Value)) {
          } else if (totalWidth === '') {
          } else if (section1Value >= totalWidthValue) {
            setSection2('');
            setSection3('');
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 1 is greater than or equal to ' + fracty(totalWidth));
          } else if (section2Value >= totalWidthValue) {
            setSection3('');
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section3Value >= totalWidthValue) {
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section4Value >= totalWidthValue) {
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 4 is greater than or equal to ' + fracty(totalWidth));
          } else if (section5Value >= totalWidthValue) {
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 5 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1 and 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value >= totalWidth) {
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2 and 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value + section4Value >= totalWidth) {
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2, 3, 4 and 5 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value + section4Value + section5Value >= totalWidth) {
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2, 3, 4, 5 and 6 is greater than or equal to ' + fracty(totalWidth));
          } else if (sum >= totalWidthValue) {
            setSection6('');
          } else {
            const section6Value = totalWidthValue - sum;
            if (section6Value >= 0) {
              setSection6(section6Value);
            } else {
              setSection6('');
            }
          }
        } else {
          console.error('totalWidth is not a valid number.');
          setSection6('');
        }
      } else {
        console.error('Sections 1, 2, 3, 4 and 5 must not be blank, and they must be valid numbers.');
        setSection6('');
      }
    } else {
    }
  }, [section1, section2, section3, section4, section5, section6, totalWidth]);

  useEffect(() => {
    for (let [key, value] of Object.entries(sectionValues)) {
      if (key === 'section1') {
        setSection1(value.value);
      }
      if (key === 'section2') {
        setSection2(value.value);
      }
      if (key === 'section3') {
        setSection3(value.value);
      }
      if (key === 'section4') {
        setSection4(value.value);
      }
      if (key === 'section5') {
        setSection5(value.value);
      }
      if (key === 'section6') {
        setSection6(value.value);
      }
    }
  }, [sectionValues, totalWidth]);

  useEffect(() => {
    // Check individually for each section
    if (section1 <= 0) {
      setSection1('');
    }
    if (section2 <= 0) {
      setSection2('');
    }
    if (section3 <= 0) {
      setSection3('');
    }
    if (section4 <= 0) {
      setSection4('');
    }
    if (section5 <= 0) {
      setSection5('');
    }
    if (section6 <= 0) {
      setSection6('');
    }
  }, [section1, section2, section3, section4, section5, section6]);

  useEffect(() => {
    if (warnQty === true) {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      setChangedSection1(false);
      setChangedSection2(false);
      setChangedSection3(false);
      setChangedSection4(false);
      setChangedSection5(false);
      setChangedSection6(false);
    }
  }, [warnQty, sectionValues]);

  const [VerticalConfigurationAlert, setVerticalConfigurationAlert] = useState(false);
  const fetchdata3 = (HeigthPrint, drawerstatus, SectionTopHeigth, SectionTopHeigthWithDecimal, VerticalConfigurationSectionTopValueStatus, VCValue, result, ForCancelSectionTop1) => {
    if (VCValue === true) {
      setSectionOneTopShow(fracty(ForCancelSectionTop1) + '"');
      setSectionOneTopHeigth(ForCancelSectionTop1);
      const SectionBottomHeigth = Number(window_heightindecimal) - Number(ForCancelSectionTop1);
      setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
      setSectionTwoBottomWidth(SectionBottomHeigth);
    } else {
      if (parseFloat(window_heightindecimal) === parseFloat(SectionTopHeigth) || parseFloat(window_heightindecimal) === parseFloat(SectionTopHeigthWithDecimal) || parseFloat(window_heightindecimal) < parseFloat(SectionTopHeigth)) {
        setVerticalConfigurationAlert(true);
        setSectionTwoBottomShow('');
        setSectionOneTopShow('');
      } else {
        setSectionOneTopShow(HeigthPrint);
        if (VerticalConfigurationSectionTopValueStatus === true) {
          if (HeigthPrint.includes('/')) {
            setSectionOneTopHeigth(SectionTopHeigthWithDecimal);
            const SectionBottomHeigth = Number(window_heightindecimal) - Number(SectionTopHeigthWithDecimal);
            setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
            setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
            setSectionTwoBottomWidth(SectionBottomHeigth);
          } else {
            setSectionOneTopHeigth(SectionTopHeigth);
            const SectionBottomHeigth = Number(window_heightindecimal) - Number(SectionTopHeigth);
            if (SectionBottomHeigth.toString().includes('.')) {
              setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
            } else {
              setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
            }
            setSectionTwoBottomWidth(SectionBottomHeigth);
          }
        }
      }
    }
    setLeftShadeisDrawerOpen(drawerstatus);
  };

  const handleWindowDepthOthermeasuere = () => {
    if (windowdepth === 'Other') {
      setIsCommonDrawerOpen(true);
    }
  };

  const [editingNote, setEditingNote] = useState(false);
  const [noteDrawerDetails, setNoteDrawerDetails] = useState({});
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);

  const closeNotesDrawer = (alert) => {
    setNotesDrawerOpen(false);
    setEditingNote(false);
    setNoteDrawerDetails({});
    if (alert.error) {
      setUpdateWindowErrorMsgTxt(alert.message);
      setUpdateWindowErrorMsg(true);
      return;
    }
    getNotes();
  };

  const openNotesDrawer = (note) => {
    setEditingNote(!!note);
    setNoteDrawerDetails({
      referenceId: windowId,
      name: windowName,
      noteId: note ? note.id : null,
    });
    setNotesDrawerOpen(true);
  };
  return (
    <div>
      {/* Alert */}

      <Drawer
        classes={{ paper: classes.paper }}
        PaperProps={{
          sx: { width: '90%' },
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        anchor='right'
        open={notesDrawerOpen}
        onClose={() => setNotesDrawerOpen(false)}
        className='drawer_Header_page'
      >
        <NotesDrawer closeDrawer={closeNotesDrawer} editing={editingNote} type='window' details={noteDrawerDetails} />
      </Drawer>

      {/* window details update message */}
      <div align='center'>
        {/* Error handling Alerts start */}
        <Stack spacing={2}>
          {/* GetLookupTableDataErrorMsg */}
          <Snackbar className='snackbar_bar_postition' open={GetLookupTableDataErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setGetLookupTableDataErrorMsg(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img
                  onClick={() => {
                    setGetLookupTableDataErrorMsg(false);
                  }}
                  src={RedDrawerCancelIcon}
                  alt='CANCELICONGLT'
                  className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='CONFIRMICONGLT' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{GetLookupTableDataErrorMsgTxt}</div>
              </div>
            </Alert>
          </Snackbar>

          {/* WindowErrorMsg */}
          <Snackbar className='snackbar_bar_postition' open={WindowErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setWindowErrorMsg(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img
                  onClick={() => {
                    setWindowErrorMsg(false);
                  }}
                  src={RedDrawerCancelIcon}
                  alt='CANCELICONREDW'
                  className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='CONFIRMICONREDW' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{WindowApiErrMsgTxt}</div>
              </div>
            </Alert>
          </Snackbar>

          {/* UpdateWindowErrorMsg */}
          <Snackbar className='snackbar_bar_postition' open={UpdateWindowErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setUpdateWindowErrorMsg(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img
                  onClick={() => {
                    setUpdateWindowErrorMsg(false);
                  }}
                  src={RedDrawerCancelIcon}
                  alt='CANCELICONREDUW'
                  className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='CONFIRMICONREDUW' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{UpdateWindowErrorMsgTxt}</div>
              </div>
            </Alert>
          </Snackbar>
        </Stack>

        <Stack spacing={2}>
          <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={2000} onClose={() => setOpen(false)}>
            <Alert variant='filled' className='Success2 mainAlert' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img alt='CANCELICONWU' onClick={handleClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img alt='CONFIRMICONWU' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Window is updated successfully!</div>
                </div>
              </>
            </Alert>
          </Snackbar>
        </Stack>
        {/* window details update message */}

        {/* window name update message */}
        <Stack spacing={2} sx={{ paddingLeft: '20%' }}>
          <Snackbar className='snackbar_bar_postition' open={openupdatewindowname} anchorOrigin={{ vertical, horizontal }} autoHideDuration={2000} onClose={() => setUpdateWindownameOpen(false)}>
            {classtype === 'Success2 mainAlert' ? (
              <Alert className={classtype} variant='filled' icon={false}>
                <>
                  <div className='alertCancel_2'>
                    <img alt='CANCELICONSUWN' onClick={handleSuccessClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img alt='CONFIRMICONSUWN' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />
                    <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{successmessage}</div>
                  </div>
                </>
              </Alert>
            ) : (
              <Alert className={classtype} variant='filled' icon={false}>
                <>
                  <div className='alertCancel_2'>
                    <img alt='CANCELICONREDUWN' onClick={handleSuccessClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img alt='CONFIRMICONREDUWN' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                    <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{successmessage}</div>
                  </div>
                </>
              </Alert>
            )}
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={warnQty} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setwarnQty(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img
                    alt='CANCELICON1'
                    onClick={() => {
                      setwarnQty(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img alt='COFIRMICON1' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{msg}</div>
                </div>
              </>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={invalid} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setInvalid(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setInvalid(false);
                    }}
                    alt='CANCELICONII'
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img alt='CONFIRMICONII' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Invalid Input !</div>
                </div>
              </>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={VerticalConfigurationAlert} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setVerticalConfigurationAlert(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img
                    alt='CANCELICONWC'
                    onClick={() => {
                      setVerticalConfigurationAlert(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img alt='CONFIRMICONWC' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Section 1 - Top H is greater than {fracty(window_heightindecimal)}</div>
                </div>
              </>
            </Alert>
          </Snackbar>
        </Stack>
        {/* window name update message */}

        {/* Room name */}
        <h2 className='header_top_margin clickable_header common_line_height_css_1' onClick={() => setIsDrawerOpen(true)}>
          <LongText content={windowName} />
        </h2>

        <h3
          className='clickable_header_1 all_pages_h2_header button_top_space common_line_height_css button_bottom_space'
          style={{ textTransform: 'capitalize' }}
          onClick={() => {
            navigate(`/tbm/ps/customerdetails`, {
              state: { Custdata: { custid: customerID, Addressid: addressID } },
            });
          }}
        >
          <LongText content={`${customerdetail.last_name} | ${customerdetail.city}`} />
        </h3>

        <Box sx={{ position: 'relative', float: 'right', top: '-55px' }}>
          <img alt='NOTESICON' src={NoteMiscIcon} className='misc_icons_dimensions' onClick={() => openNotesDrawer()} />
        </Box>
      </div>

      <ThemeProvider theme={outerTheme}>
        <div align='left'>
          {notesContent.map((note, index) => (
            <Box key={note.id} sx={{ display: 'flex' }}>
              {note.visible ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : null}{' '}
              <Box>
                <Typography className='' onClick={() => openNotesDrawer(note)}>
                  {' '}
                  <span
                    style={{
                      color: '#008593',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                    }}
                  >
                    Note #{index + 1} :
                  </span>{' '}
                  {note.content}
                </Typography>
              </Box>
            </Box>
          ))}
        </div>
      </ThemeProvider>

      <hr className='button_top_space button_bottom_space ' />
      <Box align='center'>
        <Typography color='text.secondary'>
          <Drawer
            classes={{ paper: classes.paper }}
            PaperProps={{
              sx: { width: '90%' },
            }}
            BackdropProps={{
              classes: {
                root: classes.backDrop,
              },
            }}
            anchor='right'
            open={isNoDrawerOpen}
            onClose={() => setIsNoDrawerOpen(true)}
            sx={{ zIndex: '100000' }}
          >
            <DrawerInput size={size} StoreHeightWidth={StoreHeightWidth} measurement_type={measurementType} func={fetchdata} AddWindow='AddWindow' />
          </Drawer>
          <Drawer
            classes={{ paper: classes.paper }}
            PaperProps={{
              sx: { width: '90%' },
            }}
            BackdropProps={{
              classes: {
                root: classes.backDrop,
              },
            }}
            anchor='right'
            open={isCommonDrawerOpen}
            onClose={() => setIsCommonDrawerOpen(true)}
            sx={{ zIndex: '1000000' }}
          >
            <CommonDrawerInputWidth othermeasurement={othermeasurement} func={fetchdatafromCommonDrawer} />
          </Drawer>
          <Drawer
            PaperProps={{
              sx: { width: '90%' },
            }}
            classes={{ paper: classes.paper }}
            BackdropProps={{
              classes: {
                root: classes.backDrop,
              },
            }}
            anchor='right'
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(true)}
            sx={{ zIndex: '100000' }}
          >
            <EditWindowName windowName={windowName} token={token} customer_id={customerID} address_id={addressID} window_id={windowId} window_name={roomLabel} func={windowNameUpdateDrawer} />
          </Drawer>

          <Drawer
            classes={{ paper: classes.paper }}
            PaperProps={{
              sx: { width: '90%' },
            }}
            BackdropProps={{
              classes: {
                root: classes.backDrop,
              },
            }}
            anchor='right'
            open={leftShadeisDrawerOpen}
            onClose={() => setLeftShadeisDrawerOpen(true)}
            sx={{ zIndex: '100000' }}
          >
            {WidthType === 'LeftWidth' ? (
              <>
                <DrawerInputWidth measurement_type={measurementType} WidthType='LeftWidth' func={fetchdata2} AddWindow='AddWindow' />
              </>
            ) : WidthType === 'Section 1' ? (
              <>
                <DrawerInputWidth measurement_type={measurementType} section={section1} WidthType='Section 1' func={fetchdata2} AddWindow='AddWindow' />
              </>
            ) : WidthType === 'Section 2' ? (
              <>
                <DrawerInputWidth measurement_type={measurementType} section={section2} WidthType='Section 2' func={fetchdata2} AddWindow='AddWindow' />
              </>
            ) : WidthType === 'Section 3' ? (
              <>
                <DrawerInputWidth section={section3} measurement_type={measurementType} WidthType='Section 3' func={fetchdata2} AddWindow='AddWindow' />
              </>
            ) : WidthType === 'Section 4' ? (
              <>
                <DrawerInputWidth section={section4} measurement_type={measurementType} WidthType='Section 4' func={fetchdata2} AddWindow='AddWindow' />
              </>
            ) : WidthType === 'Section 5' ? (
              <>
                <DrawerInputWidth section={section5} measurement_type={measurementType} WidthType='Section 5' func={fetchdata2} AddWindow='AddWindow' />
              </>
            ) : HeigthType === 'SectionTopHeigth' || WidthType === 'SectionTopHeigth' ? (
              <>
                <DrawerInputWidth measurement_type={measurementType} SectionOneTopShow={SectionOneTopHeigth} HeigthType='SectionTopHeigth' func2={fetchdata3} AddWindow='AddWindow' />
              </>
            ) : (
              <>
                <DrawerInputWidth measurement_type={measurementType} func={fetchdata2} AddWindow='AddWindow' />
              </>
            )}
          </Drawer>
        </Typography>
        <div align='left' style={{ display: 'flex', flexDirection: 'column' }} className='font_style_of_menuitem'>
          <FormControl onClick={() => setMeasurementTypeOpen(!measurementTypeOpen)} className='formcontrol text_selectfield_upper margin_bottom '>
            <Select
              value={measurementType}
              align='left'
              size='small'
              required
              onClose={() => setMeasurementTypeOpen(false)}
              onOpen={() => setMeasurementTypeOpen(true)}
              open={measurementTypeOpen}
              onChange={(e) => handleMeasurementType(e)}
              displayEmpty
              startAdornment={
                <InputAdornment position='start'>
                  Measurement Type
                  <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                  &nbsp;:&nbsp; {measurementType === '' ? '' : ''}
                </InputAdornment>
              }
            >
              <MenuItem value={''}>
                <b>─ ─</b>
              </MenuItem>
              {Typemeasurement.map((option) => (
                <MenuItem key={option.measurement_type} value={option.measurement_type}>
                  <b className='font_style_of_menuitem' style={{ color: option.color_code }}>
                    {option.measurement_type} ({option.short_code})
                  </b>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            className='text_selectfield formcontrol'
            id='filled-basic'
            label='Width x Height'
            required
            InputProps={{
              readOnly: true,
              style: { fontWeight: 'bold', fontFamily: 'Roboto' },
            }}
            variant='outlined'
            defaultValue='Width x Height'
            value={size}
            onClick={() => setIsNoDrawerOpen(true)}
            onChange={(e) => checkSize(e.target.value)}
          />
          <FormControl onClick={() => setValanceOpen(!ValanceOpen)} className='formcontrol text_selectfield'>
            <Select
              align='left'
              size='small'
              onClose={() => setValanceOpen(false)}
              onOpen={() => setValanceOpen(true)}
              open={ValanceOpen}
              onChange={(e) => setWindowDepth(e.target.value)}
              displayEmpty
              value={windowdepth}
              startAdornment={
                <InputAdornment className='cursor' position='start'>
                  Window Depth
                  <span s>&nbsp;</span>&nbsp;:
                </InputAdornment>
              }
            >
              <MenuItem value={''}>
                <b>─ ─</b>
              </MenuItem>
              {WindowDepthData.map((option) => (
                <MenuItem onClick={() => setWindowDepthSku(option.sku)} key={option.service_name} value={option.service_name}>
                  <b className='font_style_of_menuitem'>{option.service_name}</b>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {windowdepth === 'Other' ? (
            <div onClick={handleWindowDepthOthermeasuere}>
              <TextField
                className='short_textfield formcontrol font_style_of_menuitem'
                id='filled-basic'
                label='Window Depth'
                variant='outlined'
                required
                placeholder='Other Measurement'
                value={othermeasurement}
                InputProps={{
                  style: { fontWeight: 'bold', fontFamily: 'Roboto' },
                }}
              />
            </div>
          ) : null}

          {measurementType !== 'Quick' ? (
            <FormControl onClick={() => setHeadrailOpen(!HeadrailOpen)} className='formcontrol text_selectfield_upper margin_top margin_bottom'>
              <Select
                value={headrail}
                required
                align='left'
                size='small'
                onClose={() => setHeadrailOpen(false)}
                onOpen={() => setHeadrailOpen(true)}
                open={HeadrailOpen}
                onChange={(e) => handleheadrailtype(e)}
                displayEmpty
                startAdornment={
                  <InputAdornment className='cursor' position='start'>
                    Window Configuration
                    <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                    &nbsp;:
                  </InputAdornment>
                }
              >
                <MenuItem value={''}>
                  <b>─ ─</b>
                </MenuItem>
                {TypeHeadrail.map((option) => (
                  <MenuItem
                    onClick={() => {
                      setHeadrailSku(option.sku);
                    }}
                    key={option.service_name}
                    value={option.service_name}
                  >
                    <b className='font_style_of_menuitem'>{option.service_name}</b>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            ''
          )}

          {measurementType !== 'Quick' && // Add this condition
            (headrail === '4 Sections' || headrail === '5 Sections' || headrail === '6 Sections' ? (
              <>
                <Typography className='cspMeasurementAlertsText margin_top margin_bottom' sx={{ display: 'flex', alignItems: 'center' }}>
                  <ErrorIcon sx={{ color: '#aa0d0d' }} />
                  Please enter the width measurements from left to right
                </Typography>
              </>
            ) : (
              <></>
            ))}

          {measurementType !== 'Quick' ? (
            <>
              {headrail === '2 Sections' ? (
                <>
                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 1 - Left'
                    variant='outlined'
                    required
                    value={sec2_1 === '0"' ? section1 : sec2_1}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                    onClick={() => {
                      setWidthType('Section 1');
                      setLeftShadeisDrawerOpen(true);
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 2'
                    variant='outlined'
                    required
                    value={sec2_2 === '0"' ? section2 : sec2_2}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                      readOnly: true,
                    }}
                  />
                </>
              ) : (
                ''
              )}

              {/* Section -2 Ends */}

              {/* Section - 3 */}

              {headrail === '3 Sections' ? (
                <>
                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 1 - Left'
                    variant='outlined'
                    required
                    value={sec3_1 === '0"' ? section1 : sec3_1}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                    onClick={() => {
                      setWidthType('Section 1');
                      setLeftShadeisDrawerOpen(true);
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 2'
                    variant='outlined'
                    required
                    value={sec3_2 === '0"' ? section2 : sec3_2}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                    onClick={() => {
                      setWidthType('Section 2');
                      setLeftShadeisDrawerOpen(true);
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 3 - Right'
                    variant='outlined'
                    required
                    value={sec3_3 === '0"' ? section3 : sec3_3}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                      readOnly: true,
                    }}
                  />
                </>
              ) : (
                ''
              )}

              {/* Section - 3 Ends */}

              {/* Section - 4 */}

              {headrail === '4 Sections' ? (
                <>
                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 1 - Left'
                    variant='outlined'
                    required
                    value={sec4_1 === '0"' ? section1 : sec4_1}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                    onClick={() => {
                      setWidthType('Section 1');
                      setLeftShadeisDrawerOpen(true);
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 2'
                    variant='outlined'
                    required
                    value={sec4_2 === '0"' ? section2 : sec4_2}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                    onClick={() => {
                      setWidthType('Section 2');
                      setLeftShadeisDrawerOpen(true);
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 3'
                    variant='outlined'
                    required
                    value={sec4_3 === '0"' ? section3 : sec4_3}
                    onClick={() => {
                      setWidthType('Section 3');
                      setLeftShadeisDrawerOpen(true);
                    }}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 4 - Right'
                    variant='outlined'
                    required
                    value={sec4_4 === '0"' ? section4 : sec4_4}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                  />
                </>
              ) : (
                ''
              )}
              {/* Section - 4 Ends */}

              {/* Section - 5 */}

              {headrail === '5 Sections' ? (
                <>
                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 1 - Left'
                    variant='outlined'
                    required
                    value={sec5_1 === '0"' ? section1 : sec5_1}
                    onClick={() => {
                      setWidthType('Section 1');
                      setLeftShadeisDrawerOpen(true);
                    }}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 2'
                    variant='outlined'
                    required
                    value={sec5_2 === '0"' ? section2 : sec5_2}
                    onClick={() => {
                      setWidthType('Section 2');
                      setLeftShadeisDrawerOpen(true);
                    }}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 3'
                    variant='outlined'
                    required
                    value={sec5_3 === '0"' ? section3 : sec5_3}
                    onClick={() => {
                      setWidthType('Section 3');
                      setLeftShadeisDrawerOpen(true);
                    }}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 4'
                    variant='outlined'
                    required
                    value={sec5_4 === '0"' ? section4 : sec5_4}
                    onClick={() => {
                      setWidthType('Section 4');
                      setLeftShadeisDrawerOpen(true);
                    }}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 5 - Right'
                    variant='outlined'
                    required
                    value={sec5_5 === '0"' ? section5 : sec5_5}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                  />
                </>
              ) : (
                ''
              )}
              {/* Section - 5 Ends */}

              {/* Section - 6 */}

              {headrail === '6 Sections' ? (
                <>
                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 1 - Left'
                    variant='outlined'
                    required
                    value={sec6_1 === '0"' ? section1 : sec6_1}
                    onClick={() => {
                      setWidthType('Section 1');
                      setLeftShadeisDrawerOpen(true);
                    }}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 2'
                    variant='outlined'
                    required
                    value={sec6_2 === '0"' ? section2 : sec6_2}
                    onClick={() => {
                      setWidthType('Section 2');
                      setLeftShadeisDrawerOpen(true);
                    }}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 3'
                    variant='outlined'
                    required
                    value={sec6_3 === '0"' ? section3 : sec6_3}
                    onClick={() => {
                      setWidthType('Section 3');
                      setLeftShadeisDrawerOpen(true);
                    }}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 4'
                    variant='outlined'
                    required
                    value={sec6_4 === '0"' ? section4 : sec6_4}
                    onClick={() => {
                      setWidthType('Section 4');
                      setLeftShadeisDrawerOpen(true);
                    }}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 5'
                    variant='outlined'
                    required
                    value={sec6_5 === '0"' ? section5 : sec6_5}
                    onClick={() => {
                      setWidthType('Section 5');
                      setLeftShadeisDrawerOpen(true);
                    }}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                  />

                  <TextField
                    className='short_textfield formcontrol'
                    id='filled-basic'
                    label='Section 6 - Right'
                    variant='outlined'
                    required
                    value={sec6_6 === '0"' ? section6 : sec6_6}
                    InputProps={{
                      style: { fontWeight: 'bold' },
                    }}
                  />
                </>
              ) : (
                ''
              )}
              {/* Section - 6 Ends */}
            </>
          ) : (
            ''
          )}

          <hr className='button_top_space button_bottom_space' />

          <FormControl onClick={() => setExistingcoveringOpen(!existingcoveringOpen)} className='formcontrol text_selectfield'>
            <Select
              value={existingCovering}
              align='left'
              size='small'
              required
              onClose={() => setExistingcoveringOpen(false)}
              onOpen={() => setExistingcoveringOpen(true)}
              open={existingcoveringOpen}
              onChange={handleExistingCovering}
              displayEmpty
              startAdornment={
                <InputAdornment className='cursor' position='start'>
                  Existing Coverings
                  <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                  &nbsp;:&nbsp;
                  {existingCovering === '' ? '' : ''}
                </InputAdornment>
              }
            >
              <MenuItem value={''}>
                <b>─ ─</b>
              </MenuItem>
              {ExistingCoveringsData.map((option) => (
                <MenuItem
                  onClick={() => {
                    setExistingSku(option.sku);
                  }}
                  key={option.service_name}
                  value={option.service_name}
                >
                  <b className='font_style_of_menuitem'>{option.service_name}</b>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl onClick={() => setBuildTypeOpen(!BuildTypeOpen)} className='formcontrol text_selectfield'>
            <Select
              value={buildType}
              align='left'
              size='small'
              required
              onClose={() => setBuildTypeOpen(false)}
              onOpen={() => setBuildTypeOpen(true)}
              open={BuildTypeOpen}
              onChange={(e) => setBuildType(e.target.value)}
              displayEmpty
              startAdornment={
                <InputAdornment className='cursor' position='start'>
                  Build Type &nbsp;{buildTypeReq}&nbsp;:&nbsp;
                  {buildType === '' ? '' : ''}
                </InputAdornment>
              }
            >
              <MenuItem value={''}>
                <b>─ ─</b>
              </MenuItem>
              {BuildTypeData.map((option) =>
                measurementType === 'Roller Shades' ? (
                  <MenuItem
                    onClick={() => {
                      setBuildSku(option.sku);
                    }}
                    key={option.service_name}
                    value={option.service_name}
                  >
                    <b className='font_style_of_menuitem'>{option.service_name}</b>{' '}
                  </MenuItem>
                ) : option.service_name === 'Fabric' ? (
                  <span
                    style={{
                      paddingLeft: 18,
                      marginBottom: 30,
                      color: '#929292',
                    }}
                    {...(option.disabled = true)}
                  >
                    {option.service_name}
                  </span>
                ) : (
                  <MenuItem
                    onClick={() => {
                      setBuildSku(option.sku);
                    }}
                    key={option.value}
                    value={option.service_name}
                  >
                    <b className='font_style_of_menuitem'>{option.service_name}</b>
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <FormControl onClick={() => setMountsurfaceOpen(!MountsurfaceOpen)} className='formcontrol text_selectfield'>
            <Select
              value={mountsurface}
              align='left'
              size='small'
              required
              onClose={() => setMountsurfaceOpen(false)}
              onOpen={() => setMountsurfaceOpen(true)}
              open={MountsurfaceOpen}
              onChange={(e) => setMountsurface(e.target.value)}
              displayEmpty
              startAdornment={
                <InputAdornment className='cursor' position='start'>
                  Mount Surface
                  <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                  &nbsp;:&nbsp;{mountsurface === '' ? '' : ''}
                </InputAdornment>
              }
            >
              <MenuItem value={''}>
                <b>─ ─</b>
              </MenuItem>
              {MountSurfaceData.map((mountsurface) => {
                return (
                  <MenuItem
                    onClick={() => {
                      setMountSku(mountsurface.sku);
                    }}
                    key={mountsurface.service_name}
                    value={mountsurface.service_name}
                  >
                    <b className='font_style_of_menuitem'>{mountsurface.service_name}</b>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl onClick={() => setCutoutOpen(!CutoutOpen)} className='formcontrol text_selectfield margin_top'>
            <Select
              value={obstructions}
              required
              align='left'
              size='small'
              onClose={() => setCutoutOpen(false)}
              onOpen={() => setCutoutOpen(true)}
              open={CutoutOpen}
              onChange={(e) => setObstructions(e.target.value)}
              displayEmpty
              startAdornment={
                <InputAdornment className='cursor' position='start'>
                  Obstructions
                  <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                  &nbsp;:
                </InputAdornment>
              }
            >
              <MenuItem value={''}>
                <b>─ ─</b>
              </MenuItem>

              {ObstructionsData.map((option) => {
                return (
                  <MenuItem
                    onClick={() => {
                      setObstructionsSku(option.sku);
                    }}
                    key={option.service_name}
                    value={option.service_name}
                  >
                    <b className='font_style_of_menuitem'>{option.service_name}</b>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {obstructions === 'Yes' ? (
            <TextField
              className='short_textfield formcontrol font_style_of_menuitem'
              id='filled-basic'
              label='Obstructions'
              variant='outlined'
              required
              placeholder='Description'
              value={description}
              InputProps={{
                style: { fontWeight: 'bold', fontFamily: 'Roboto' },
              }}
              onChange={(e) => setDescription(e.target.value)}
            />
          ) : (
            ''
          )}
          <FormControl onClick={() => setLadderOpen(!LadderOpen)} className='formcontrol text_selectfield'>
            <Select
              value={ladder}
              align='left'
              size='small'
              required
              onClose={() => setLadderOpen(false)}
              onOpen={() => setLadderOpen(true)}
              open={LadderOpen}
              onChange={(e) => setLadder(e.target.value)}
              displayEmpty
              startAdornment={
                <InputAdornment className='cursor' position='start'>
                  Ladder
                  <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                  &nbsp;:&nbsp;{ladder === '' ? '' : ''}
                </InputAdornment>
              }
            >
              <MenuItem value={''}>
                <b>─ ─</b>
              </MenuItem>
              {LadderData.map((ladder) => {
                return (
                  <MenuItem
                    onClick={() => {
                      setLadderSku(ladder.sku);
                    }}
                    key={ladder.service_name}
                    value={ladder.service_name}
                  >
                    <b className='font_style_of_menuitem'>{ladder.service_name}</b>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <div align='left' style={{ display: 'flex', flexDirection: 'column' }}>
            {measurementType === 'Shutters' ? (
              <>
                <hr className='button_top_space button_bottom_space' />
                <FormControl onClick={() => setWindowsillOpen(!WindowsillOpen)} className='formcontrol text_selectfield'>
                  <Select
                    value={Windowsill}
                    required
                    align='left'
                    size='small'
                    onClose={() => setWindowsillOpen(false)}
                    onOpen={() => setWindowsillOpen(true)}
                    open={WindowsillOpen}
                    onChange={(e) => setWindowsill(e.target.value)}
                    displayEmpty
                    startAdornment={
                      <InputAdornment className='cursor' position='start'>
                        Windowsill
                        <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                        &nbsp;: {Windowsill ? '' : ''}
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={''}>
                      <b>─ ─</b>
                    </MenuItem>
                    <MenuItem value='Yes'>
                      {' '}
                      <b className='font_style_of_menuitem'>Yes</b>
                    </MenuItem>
                    <MenuItem value='No'>
                      {' '}
                      <b className='font_style_of_menuitem'>No</b>
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl onClick={() => setVerticalConfigurationOpen(!VerticalConfigurationOpen)} className='formcontrol text_selectfield_upper margin_top margin_bottom'>
                  <Select
                    value={VerticalConfiguration}
                    required
                    align='left'
                    size='small'
                    onClose={() => setVerticalConfigurationOpen(false)}
                    onOpen={() => setVerticalConfigurationOpen(true)}
                    open={VerticalConfigurationOpen}
                    onChange={(e) => {
                      handleVerticalConfiguration(e);
                    }}
                    displayEmpty
                    startAdornment={
                      <InputAdornment className='cursor' position='start'>
                        Vertical Configuration
                        <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                        &nbsp;:
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={''}>
                      <b>─ ─</b>
                    </MenuItem>
                    {VerticalConfigurationData.map((option) => (
                      <MenuItem
                        onClick={() => {
                          setVerticalConfigurationSku(option.sku);
                        }}
                        key={option.service_name}
                        value={option.service_name}
                      >
                        <b className='font_style_of_menuitem'>{option.service_name}</b>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {VerticalConfiguration === '2 Sections' ? (
                  <>
                    {size ? (
                      <TextField
                        className='short_textfield formcontrol margin_top font_style_of_menuitem'
                        id='filled-basic'
                        value={SectionOneTopShow}
                        onClick={() => {
                          setSectionTwoBottomShow('');
                          setHeigthType('SectionTopHeigth');
                          setWidthType('SectionTopHeigth');
                          setLeftShadeisDrawerOpen(true);
                        }}
                        label='Section 1 - Top'
                        variant='outlined'
                        required
                        InputProps={{
                          readOnly: true,
                          style: { fontWeight: 'bold' },
                        }}
                        InputLabelProps={{
                          shrink: SectionOneTopShow !== '', // This will move the label up if there's a value
                        }}
                      />
                    ) : (
                      <TextField disabled className='short_textfield formcontrol' label='Section 1 - Top' variant='outlined' />
                    )}
                    {size ? (
                      <TextField
                        className='short_textfield formcontrol font_style_of_menuitem'
                        id='filled-basic'
                        value={SectionTwoBottomShow}
                        onClick={() => {}}
                        label='Section 2 - Bottom'
                        variant='outlined'
                        required
                        InputProps={{
                          readOnly: true,
                          style: { fontWeight: 'bold' },
                        }}
                        InputLabelProps={{
                          shrink: SectionTwoBottomShow !== '', // This will move the label up if there's a value
                        }}
                      />
                    ) : (
                      <TextField disabled className='short_textfield formcontrol' label='Section 2 - Bottom' variant='outlined' />
                    )}
                  </>
                ) : null}
              </>
            ) : null}
          </div>
          <div align='left' style={{ display: 'flex', flexDirection: 'column' }}></div>
        </div>
      </Box>

      <Box>
        <>
          {' '}
          <hr className='button_top_space button_bottom_space' />
          {measurementType &&
          size &&
          existingCovering &&
          buildType &&
          mountsurface &&
          ladder &&
          (windowdepth === 'Standard' || (windowdepth === 'Other' && othermeasurement)) &&
          headrail &&
          (obstructions === 'None' || (obstructions === 'Yes' && description)) &&
          Windowsill &&
          (VerticalConfiguration === '1 Section' || (VerticalConfiguration === '2 Sections' && SectionOneTopShow && SectionTwoBottomShow)) &&
          ((headrail === '1 Section' && true) ||
            (headrail === '2 Sections' && section1 && section2) ||
            (headrail === '3 Sections' && section1 && section2 && section3) ||
            (headrail === '4 Sections' && section1 && section2 && section3 && section4) ||
            (headrail === '5 Sections' && section1 && section2 && section3 && section4 && section5) ||
            (headrail === '6 Sections' && section1 && section2 && section3 && section4 && section5 && section6)) ? (
            <span className='button_span button_bottom_space ' onClick={SaveWindowChanges} onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                {isHovered ? <img alt='SAVECHANGESH' className='primary_icons_dimension_1' src={SaveChangesHovIcon} /> : <img alt='SAVECHANGES' className='primary_icons_dimension_1' src={SaveChanges} />}
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  SAVE CHANGES
                </Typography>
              </Box>
            </span>
          ) : (
            <span className='button_span_disabled button_bottom_space '>
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                <img className='primary_icons_dimension_disable' src={SaveChanges} alt='SAVECHANGESD' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                  SAVE CHANGES
                </Typography>
              </Box>
            </span>
          )}
          <span className='gray_button_span button_top_space' style={{ paddingBottom: '30px', paddingLeft: '21px' }}>
            <img src={CancelIcon} className='secondary_icons_dimension' alt='CANCEL' onClick={() => navigate(-1)} />
            <span onClick={() => navigate(-1)} className='gray_button_text_1'>
              CANCEL
            </span>
          </span>
        </>
      </Box>
      <br />
    </div>
  );
};
export default Window;
