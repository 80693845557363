import { createContext, useReducer, useState, useEffect } from 'react';
import CONFIG from '../../config/config.js';

export const WindowContext = createContext(null);
export const WindowDispatchContext = createContext(null);

export function WindowProvider({ children }) {
  const [state, dispatch] = useReducer(windowReducer, initialWindowState);
  const [options, setOptions] = useState({});

  useEffect(() => {
    getWindowOptions();
  }, []);

  const getWindowOptions = async () => {
    const token = sessionStorage.getItem('token');
    const options = {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      token: token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/wm/GetLookupTableData`, options);
    const { error, error_message, ...data } = await response.json();
    setOptions(data);
  };

  return (
    <WindowContext.Provider value={{ windowDetails: state, options }}>
      <WindowDispatchContext.Provider value={dispatch}>{children}</WindowDispatchContext.Provider>
    </WindowContext.Provider>
  );
}

export default WindowProvider;

const initialWindowState = {
  ids: '',
  measurementType: '',
  width: '',
  height: '',
  size: '',
  windowDepth: '',
  windowDepthMeasurement: '',
  existingCoverings: '',
  ladder: '',
  mountSurface: '',
  windowConfiguration: '',
  sections: {
    section_1: 0,
    section_2: 0,
    section_3: 0,
    section_4: 0,
    section_5: 0,
    section_6: 0,
  },
  obstructions: '',
  obstructionsDetails: '',
  buildType: '',
  windowSill: {
    sku: '',
    service_name: '',
  },
  verticalConfigurations: '',
  verticalSections: {
    SectionOneTop: 0,
    SectionTwoBottom: 0,
  },
};

const windowReducer = (state = initialWindowState, { action, payload }) => {
  switch (action) {
    case 'SET_NAME':
      return state;
    case 'SET_MEASUREMENT_TYPE':
      if (payload === 'Quick') {
        const width = Math.ceil(state.width);
        const height = Math.ceil(state.height);
        return {
          ...state,
          measurementType: payload,
          size: `${width}" X ${height}"`,
          width,
          height,
        };
      }
      return {
        ...state,
        measurementType: payload,
      };
    case 'SET_SIZE':
      return {
        ...state,
        size: payload.size,
        width: payload.width,
        height: payload.height,
      };
    case 'SET_WINDOW_DEPTH':
      return {
        ...state,
        windowDepth: payload,
        windowDepthMeasurement: 0,
      };
    case 'SET_DEPTH_MEASUREMENT':
      return {
        ...state,
        windowDepthMeasurement: payload,
      };
    case 'SET_WINDOW_CONFIGURATION':
      return {
        ...state,
        windowConfiguration: payload,
      };
    case 'SET_SECTION_MEASUREMENT':
      return {
        ...state,
        sections: {
          ...state.sections,
          ...payload,
        },
      };
    case 'SET_EXISTING_COVERINGS':
      return {
        ...state,
        existingCoverings: payload,
      };
    case 'SET_BUILD_TYPE':
      return {
        ...state,
        buildType: payload,
      };
    case 'SET_MOUNT_SURFACE':
      return {
        ...state,
        mountSurface: payload,
      };
    case 'SET_OBSTRUCTIONS':
      return {
        ...state,
        obstructions: payload,
      };
    case 'SET_OBSTRUCTIONS_DETAILS':
      return {
        ...state,
        obstructionsDetails: payload,
      };
    case 'SET_LADDER':
      return {
        ...state,
        ladder: payload,
      };
    case 'SET_WINDOW_SILL':
      return {
        ...state,
        windowSill: payload,
      };
    case 'SET_VERTICAL_CONFIGURATION':
      return {
        ...state,
        verticalConfigurations: payload,
        verticalSections: {
          SectionOneTop: payload.service_name === '2 Sections' ? state.height / 2 : '',
          SectionTwoBottom: payload.service_name === '2 Sections' ? state.height / 2 : '',
        },
      };
    case 'SET_TOP':
      return {
        ...state,
        verticalSections: {
          SectionOneTop: payload,
          SectionTwoBottom: state.height - payload,
        },
      };
    case 'SET_BOTTOM':
      return {
        ...state,
        verticalSections: {
          SectionOneTop: state.height - payload,
          SectionTwoBottom: payload,
        },
      };
    case 'SET_ALL':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
