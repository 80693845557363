import React from 'react';
import { Box, Grid } from '@mui/material';
import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  notes_section: {
    padding: "1em 0",
  },
  note_title: {
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "13px",
    color: "#333",
    textTransform: "capitalize",
    padding: ".5em 0",
  },
  note_content: {
    display: "list-item",
    fontSize: 11,
    fontWeight: "bolder",
    color: "#797979",
    padding: ".25em 0"
  },
  hr: {
    marginTop: 5,
  }
});

function Notes({ notes }) {
  return (
    <>
      { notes.customer.length ? (
        <NoteSection notes={notes.customer} title="Customer Notes" />
      ): null}
      { notes.proposal.length ? (
        <NoteSection notes={notes.proposal} title="Proposal Notes" />
      ) : null}
      { notes.proposal_details.length ? (
        <NoteSection notes={notes.proposal_details} title="Proposal Detail Notes" />
      ) : null}
      { notes.windowSet.length ? (
        <NoteSection notes={notes.windowSet} title="Window Set Notes" />
      ) : null} 
      { notes.windows.length ? (
        <NoteSection notes={notes.windows} title="Window Notes" />
      ) : null}
      
      <hr className="greyout" />
    </>
  );
}

const NoteSection = ({notes, title}) => {
  return (
  <Grid container direction="row" spacing={1} style={styles.notes_section} >
    <Grid xs={.5} />
    <Grid xs={11.5} style={styles.note_title}>{title} : </Grid>
    {notes.map((note, index) => (
      <>
        <Grid key={index} xs={.75} />
        <Grid key={index} xs={10.25} md={10.5} style={styles.note_content} >{note.content}</Grid>
        <Grid key={index+1} xs={1} md={0.5}/>
      </>
    ))}
  </Grid>
);}

export default Notes;