/** @format */

import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import CONFIG from '../../config/config.js';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import SelectIcon from '../../assets/icons/secondary/2nd-confirm.svg';
import ShowProposalName from './ShowProposalName';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ContinueIcon from '../../assets/icons/primary/continue.svg';
import ContinueHovIcon from '../../assets/icons/primary/WithHovIcon/continue-hov.svg';
import Grid from '@mui/material/Grid';
import EditLinesAddWindowStepper from '../Proposals/EditLinesAddWindowStepper';

import fracty from 'fracty';
import { TablePagination } from '@mui/material';
const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#333333',
    },
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
// table heading

export default function EditingProposalSelectWindowToAddWindows() {
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(CONFIG.WindowsManagement.RowperPageLimit);
  const [windowsselected, setWindowsSelected] = useState([]);
  const [showSA, setShowSA] = React.useState(true);
  const [checkedrowdata, setCheckedRowData] = useState([]);
  const [windowsallreadySelected, setWindowsallreadySelected] = useState([]);
  const [windowsremaining, setWindowsremaining] = useState([]);
  let errordata;
  const vertical = 'top';
  const horizontal = 'center';

  // Error Handling
  const [ProposalDetailsErrorMsg, setProposalDetailsErrorMsg] = useState('');
  const [ProposalDetailsErrorAlert, setProposalDetailsErrorAlert] = useState(false);

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  let totalwindows;
  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    totalwindows = location.state.totalwindows;
  }

  let proposaldetails = {
    customerdata: location.state.customerdata,
    proposal_id: location.state.proposal_id,
    proposal_name: location.state.proposal_name,
  };
  let coveringdetails = location.state.coveringdetails;

  useEffect(() => {
    {
      window.scrollTo(0, 0);
    }
    getVerified();
  }, []);
  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };
  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='table-headerppsw sticky-column'>
            <span style={{ paddingLeft: '28px' }}>#</span> <span style={{ paddingLeft: '16px' }}>Window Name</span>{' '}
          </TableCell>

          <TableCell className='table-headersw1'>Price</TableCell>
          <TableCell className='table-header-cd-proposal2'>Size</TableCell>
          <TableCell className='table-header_material'>Covering System</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  useEffect(() => {
    getData();
    setProposalName(location.state.proposal_name);
  }, []);
  const rows = windowsselected;
  const customerdata = location.state.customerdata;
  const [checkboxflag, setCheckboxFlag] = useState(true);
  const [proposalName, setProposalName] = useState();
  const [selectedRow, setSelectedRow] = React.useState([]);

  // To get proposal details
  const getData = async () => {
    await fetch(`${CONFIG.API_URL}/pm/ProposalDetails/${location.state.proposal_id}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        errordata = data.error;

        if (!data.error) {
          let selectedrows = [];
          let allreadyselectedwindows = [];
          let remainingwindows = [];
          data.proposal_details.forEach((element) => {
            if (element.window_selected === true) {
              selectedrows.push(element);
            }
            if (element.window_selected === true && element.covering_status === true) {
              allreadyselectedwindows.push(element);
            } else if (element.window_selected === true && element.covering_status === 'not_consider') {
              remainingwindows.push(element);
            }
          });

          setWindowsallreadySelected(allreadyselectedwindows);
          setWindowsremaining(remainingwindows);
          setWindowsSelected(selectedrows);

          setCheckedRowData(remainingwindows);
        } else {
          setProposalDetailsErrorMsg(data.error_message);
          setProposalDetailsErrorAlert(true);
        }
      });
  };

  // unchecked the checkbox
  const cancelWindowSelection = () => {
    setCheckboxFlag(true);
    setSelectedRow([]);
    setSelected([]);
    setShowSA(true);
    setCheckedRowData(windowsremaining);
  };

  const handleSelectAllClick = (event) => {
    if (event === true) {
      const newSelecteds = rows.map((n) => n);

      setSelected(newSelecteds);
      setSelectedRow(newSelecteds);
      setCheckedRowData([]);
    } else {
      setSelectedRow([]);
      setSelected([]);
      setCheckedRowData(windowsremaining);
    }
  };

  const handleClick = (event, row) => {
    let flag;
    if (event.target.checked === true) {
      flag = false;
    }
    if (flag === true) {
      const selectedIndex = selected.indexOf(row);

      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      setSelectedRow(newSelected);
      setSelected(newSelected);
    } else {
      const selectedIndex = selected.indexOf(row);
      const selectedActualIndex = windowsselected.indexOf(row);

      windowsselected[selectedActualIndex].selected = false;

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      let selectedwindows = windowsremaining.filter((val) => !newSelected.includes(val));

      if (selectedwindows.length < windowsselected.length) {
        setShowSA(false);
      }
      if (newSelected.length === 0) {
        setShowSA(true);
      }
      setCheckedRowData(selectedwindows);
      setSelected(newSelected);
    }
  };

  const isSelected = (row) => selected.indexOf(row) === -1;

  const onSelectAllClick = () => {
    setShowSA(true);
    handleSelectAllClick(false);
  };
  const onDeSelectAllClick = () => {
    setShowSA(false);
    handleSelectAllClick(true);
  };

  const LongText = ({ content }) => {
    const [limit, setLimit] = useState(CONFIG.GlobalTruncateCharacterLimit);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    if (content === undefined) {
      // Data is undefined, return empty
      return null;
    }

    // Check if the window width is between 280px and 768px and content length is greater than 14
    if (windowWidth >= 280 && windowWidth <= 767 && content.length > 15) {
      const truncatedContent = content.substring(0, 15) + '...';
      return <span>{truncatedContent}</span>;
    }

    // Check if the window width is above or equal to 768
    if (windowWidth >= 768) {
      if (content.length <= limit) {
        // There is nothing more to show
        return <span>{content}</span>;
      }

      let truncatedContent = content.substring(0, limit);

      // Find the last space within the truncated content
      const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

      // Adjust the truncated content if a space was found
      if (lastSpaceIndex !== -1) {
        truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
      }

      // Add the ellipsis to the truncated content
      const toShow = truncatedContent + '...';

      return <span>{toShow}</span>;
    }

    if (content.length <= limit) {
      // There is nothing more to show
      return <span>{content}</span>;
    }

    let truncatedContent = content.substring(0, limit);

    // Find the last space within the truncated content
    const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

    // Adjust the truncated content if a space was found
    if (lastSpaceIndex !== -1) {
      truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
    }

    // Add the ellipsis to the truncated content
    const toShow = truncatedContent + '...';

    return <span>{toShow}</span>;
  };

  return (
    <>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={ProposalDetailsErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setProposalDetailsErrorAlert(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img
                onClick={() => {
                  setProposalDetailsErrorAlert(false);
                }}
                src={RedDrawerCancelIcon}
                alt='CANCELICONREDPDA'
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={RedExclamationIcon} alt='CONFIRMICONREDPDA' className='primary_icons_dimension alertImageMargin' />
              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{ProposalDetailsErrorMsg}</div>
            </div>
          </Alert>
        </Snackbar>
      </Stack>

      <ThemeProvider theme={outerTheme}>
        <ShowProposalName proposaldetails={proposaldetails} />

        <div className='button_top_space' align='center' style={{ marginBottom: '10px' }}>
          <EditLinesAddWindowStepper data={'ApplyCovering'} />
        </div>

        <>
          {!showSA ? (
            <>
              <span className='gray_button_span' onClick={onSelectAllClick} style={{ paddingBottom: '20px', paddingLeft: '22px' }}>
                <Box className='globalIconMainBox'>
                  <img alt='SELECTALL' src={SelectIcon} className='secondary_icons_dimension' />
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                    SELECT ALL
                  </Typography>
                </Box>
              </span>
            </>
          ) : (
            <>
              <span className='gray_button_span' onClick={onDeSelectAllClick} style={{ paddingBottom: '20px', paddingLeft: '22px' }}>
                <Box className='globalIconMainBox'>
                  <img alt='DESELECTALL' src={CancelIcon} className='secondary_icons_dimension' />
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                    DESELECT ALL
                  </Typography>
                </Box>
              </span>
            </>
          )}
        </>

        <hr />
        <Box className='table-box'>
          <TableContainer sx={{ borderBottom: '1px solid #D6D6D6' }} className='table_padding_bottom'>
            <Table className='table-dimension-apply-coverings' aria-labelledby='tableTitle' size='small'>
              <EnhancedTableHead numSelected={selected.length} onSelectAllClick={handleSelectAllClick} rowCount={rows.length} />
              <TableBody>
                {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover onClick={(event) => handleClick(event, row)} role='checkbox' aria-checked={isItemSelected} tabIndex={-1} key={row.window_name}>
                      {row.covering_status === 'not_consider' ? (
                        <>
                          <TableCell
                            className='table-content sticky-column'
                            align='left'
                            sx={{
                              fontSize: '100%',
                              borderBottom: 'none',
                              display: 'flex',
                              width: '100%',
                            }}
                          >
                            <Box sx={{ display: 'flex' }}>
                              <Checkbox
                                sx={{
                                  zoom: 0.8,
                                  paddingTop: '0px',
                                  paddingLeft: '0px !important',
                                }}
                                color='primary'
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                              <Box sx={{ width: '20px' }}>{windowsselected.indexOf(row) + 1}</Box>
                              &nbsp; &nbsp;
                              <LongText content={row.window_name} />
                            </Box>
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            ─ ─
                          </TableCell>

                          <TableCell className='table-content' align='left'>
                            {fracty(row.proposal_window_width)}" X {fracty(row.proposal_window_height)}"
                            <span className={row.measurement_type.replace(/ /g, '')}>
                              {row.measurement_type === 'Quick'
                                ? '(' + 'QK' + ')'
                                : row.measurement_type === 'Shutters'
                                ? '(' + 'SH' + ')'
                                : row.measurement_type === 'Blinds'
                                ? '(' + 'BL' + ')'
                                : '(' +
                                  row.measurement_type
                                    .split(' ')
                                    .map((word) => word[0])
                                    .join('') +
                                  ')'}
                            </span>
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            ─ ─
                          </TableCell>
                        </>
                      ) : row.covering_status === 'not_applied' ? (
                        <>
                          <TableCell
                            className='table-content sticky-column'
                            align='left'
                            sx={{
                              fontSize: '100%',
                              borderBottom: 'none',
                              display: 'flex',
                              width: '100%',
                            }}
                          >
                            <Box sx={{ display: 'flex' }}>
                              <Checkbox
                                sx={{
                                  [`&, &.${checkboxClasses.checked}`]: {
                                    color: '#ADADAD',
                                    zoom: 0.8,
                                    paddingTop: '0px',
                                    paddingLeft: '0px !important',
                                  },
                                }}
                                checked={row.window_selected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                              <Box sx={{ width: '20px' }}>{windowsselected.indexOf(row) + 1}</Box>
                              &nbsp; &nbsp;
                              <LongText content={row.window_name} />
                            </Box>
                          </TableCell>
                          <TableCell align='left' className='table-content' sx={{ color: 'red' }}>
                            ─ ─
                          </TableCell>
                          <TableCell align='left' className='table-content' sx={{ color: 'red' }}>
                            {fracty(row.proposal_window_width)}" X {fracty(row.proposal_window_height)}"{' '}
                          </TableCell>
                          <TableCell align='left' sx={{ color: 'red' }} className='table-content-secondsw'>
                            ─ ─
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell
                            className='table-content sticky-column'
                            align='left'
                            sx={{
                              fontSize: '100%',
                              borderBottom: 'none',
                              display: 'flex',
                            }}
                          >
                            <Box sx={{ display: 'flex' }}>
                              <Checkbox
                                sx={{
                                  [`&, &.${checkboxClasses.checked}`]: {
                                    color: '#ADADAD',
                                    zoom: 0.8,
                                    paddingTop: '0px',
                                    paddingLeft: '0px !important',
                                  },
                                }}
                                checked={row.window_selected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                              <Box sx={{ width: '20px' }}>{windowsselected.indexOf(row) + 1}</Box>
                              &nbsp; &nbsp;
                              <LongText content={row.window_name} />
                            </Box>
                          </TableCell>
                          <TableCell align='left' className='table-content'>
                            ${row.suggested_price}
                          </TableCell>
                          <TableCell align='left' className='table-content'>
                            {fracty(row.proposal_window_width)}" X {fracty(row.proposal_window_height)}"{' '}
                            <span className={row.measurement_type.replace(/ /g, '')}>
                              {row.measurement_type === 'Quick'
                                ? '(' + 'QK' + ')'
                                : row.measurement_type === 'Shutters'
                                ? '(' + 'SH' + ')'
                                : row.measurement_type === 'Blinds'
                                ? '(' + 'BL' + ')'
                                : '(' +
                                  row.measurement_type
                                    .split(' ')
                                    .map((word) => word[0])
                                    .join('') +
                                  ')'}
                            </span>
                          </TableCell>
                          <TableCell align='left' className='table-content-second2'>
                            <>
                              {row.material_code.toString().replace(/,/g, ', ')}, {row.build_type}, {row.existing_covering}, {row.mount_surface}, {row.ladder}
                            </>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]}
              component='div'
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={(_, newPageprp) => {
                setPage(newPageprp);
              }}
              onRowsPerPageChange={({ target }) => {
                setRowsPerPage(parseInt(target.value, 10));
                setPage(0);
              }}
            />
          </TableContainer>
        </Box>
        <hr />

        <Box>
          {checkedrowdata.length > 0 ? (
            <>
              {' '}
              <span
                onClick={() =>
                  navigate(`/tbm/ps/proposalapplycovering/${params.id}`, {
                    state: {
                      selectedwindows: checkedrowdata,
                      customerdata: customerdata,
                      proposalID: location.state.proposal_id,
                      proposalName: location.state.proposal_name,
                      totalselectedwindows: rows,
                      totalwindows: totalwindows,
                      coveringdetails: coveringdetails,
                      path: 'EPSW',
                    },
                  })
                }
                className='button_span proposal_select_window_continue_paddting_bottom button_top_space'
                onMouseEnter={handleHoverFun}
                onMouseLeave={handleMouseLeaveFun}
              >
                <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                  {isHovered ? <img alt='CONTINUEH' className='primary_icons_dimension_1' src={ContinueHovIcon} /> : <img alt='CONTINUE' className='primary_icons_dimension_1' src={ContinueIcon} />}
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                    CONTINUE
                  </Typography>
                </Box>
              </span>
              <hr />
              <span className='gray_button_span button_top_space' style={{ paddingBottom: '30px', paddingLeft: '22px' }}>
                <Box onClick={cancelWindowSelection} className='globalIconMainBox'>
                  <img alt='CANCEL' src={CancelIcon} className='secondary_icons_dimension' />
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                    CANCEL
                  </Typography>
                </Box>
              </span>
            </>
          ) : (
            <>
              <span className='button_span_disabled proposal_select_window_continue_paddting_bottom button_top_space'>
                <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                  <img alt='CONTINUE' src={ContinueIcon} className='primary_icons_dimension_disable' />
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                    CONTINUE
                  </Typography>
                </Box>
              </span>

              <hr />

              <span className='gray_button_span button_top_space' style={{ paddingBottom: '30px', paddingLeft: '22px' }}>
                <Box onClick={cancelWindowSelection} className='globalIconMainBox'>
                  <img alt='CANCEL' src={CancelIcon} className='secondary_icons_dimension' />
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                    CANCEL
                  </Typography>
                </Box>
              </span>
            </>
          )}
        </Box>
      </ThemeProvider>
    </>
  );
}
