/** @format */

import React, { useState, useEffect, forwardRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import EditLinesIcon from '../../assets/icons/secondary/2nd-edit.svg';
import PreviewIcon from '../../assets/icons/secondary/2nd-visability.svg';
import Drawer from '@mui/material/Drawer';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import CONFIG from '../../config/config.js';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ProposalTable from './ProposalTable';
import SaveIcon from '../../assets/icons/secondary/2nd-save.svg';
import AddItemsIcon from '../../assets/icons/primary/proposal-add.svg';
import AddItemsHovIcon from '../../assets/icons/primary/WithHovIcon/proposal-add-hov.svg';
import ShareIcon from '../../assets/icons/primary/share.svg';
import ShareHovIcon from '../../assets/icons/primary/WithHovIcon/share-hov.svg';
import CTSOIcon from '../../assets/icons/primary/proposal.svg';
import CTSOHovIcon from '../../assets/icons/primary/WithHovIcon/proposal-hov.svg';
import AddOutlinedIcon from '../../assets/icons/secondary/2nd-add.svg';
import ShowProposalName from './ShowProposalName';
import ProposalAlertMessage from './ProposalAlertMessages';
import PriceDrawer from '../WindowsManagement/PriceDrawer';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import ShareProposal from './shareproposal';
import Grid from '@mui/material/Grid';
import NotesDrawer from './NotesDrawer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { createTheme, TextField, ThemeProvider } from '@mui/material';
import NoteMiscIcon from '../../assets/icons/misc/misc-note.svg';
import SecretIcon from '../../assets/icons/secondary/secret.svg';
import ErrorIcon from '../../assets/icons/misc/stop.svg';
import WarningIcon from '../../assets/icons/misc/alert.svg';

import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import AddOnDrawer from '../Shared/drawers/AddOn';
import InformationAlerts from '../Shared/InfoAlerts.jsx';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const theme = createTheme({
  palette: {
    border: {
      main: '1px solid #000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#FAC41E',
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "green", // date picker textfield style
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#008593 !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'white', // disable date
            color: '#E9E9E9',
          },
        },
        dayWithMargin: {
          backgroundColor: 'white', // not selected date
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "blue" //calender icon & month change arrow icon style
        },
      },
    },
  },
});

let AppliedCoveringsWindows = [];
let SelectedWindows = [];

const remeasureOptions = [
  {
    value: 'None',
    label: 'None',
  },
  {
    value: 'Office Remeasure',
    label: 'Office Remeasure',
  },
  {
    value: 'Self Remeasure',
    label: 'Self Remeasure',
  },
];

const useStyles = makeStyles({
  paper: {
    width: '90%',
    background: 'rgba(69, 157, 166, 0.70)  !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

export default function ProposalDetails() {
  const params = useParams();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  if (location.state === null) {
    navigate('/pagenotfound');
  }

  let coveringdetails = location.state.coveringdetails;
  const selectedJsonData = location.state.jsonData;
  const token = sessionStorage.getItem('token');

  let alertmessageDetails = {};

  const [editchecked, setEditchecked] = useState(false);
  const [editDisappear, setEditDisappear] = useState(true);
  const [shareProposal, setShareProposal] = useState(true);
  const [convertToOrder, setConvertToOrder] = useState(true);
  const [addEditButton, setAddEditButton] = useState(false);
  const [checkboxflag, setCheckboxFlag] = useState(true);
  const [proposalName, setProposalName] = useState(location.state?.proposalName);
  const [finalTotal, setFinalTotal] = useState('');
  const [discount, setDiscount] = useState(0);
  const [discountDollar, setDiscountDollar] = useState('');
  const [commission, setCommission] = useState('');
  const [installationCost, setInstallationCost] = useState('');
  const [proposalRetailPrice, setProposalRetailPrice] = useState('');
  const [accessoriesCharge, setAccessoriesCharge] = useState([]);
  const [servicesCharge, setServicesCharge] = useState([]);
  const [addtionalCharge, setAddtionalCharge] = useState([]);
  const [tax, setTax] = useState(0);
  const [skuDetails, setSkuDetails] = useState('');
  const [extraCharges, setExtraCharges] = useState('');
  const [duplicateSuccessMessage, setDuplicateSuccessMessage] = useState(null);
  const [hasSalesOrder, setHasSalesOrder] = useState(false);
  const [showDiscount, setShowDiscount] = useState(true);
  const [coveringsApplied, setCoveringsAppliedWindows] = useState([]);
  const [labels, setLabels] = useState([]);
  const [windowsSentforCoverings, setWindowsSentForCoverings] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [remeasure, setRemeasure] = useState('');
  const [savedRemeasure, setSavedRemeasure] = useState('');
  const [isAddItemDrawerOpen, setIsAddItemDrawerOpen] = useState(false);
  const [isDrawerOpenforShareProposal, setIsDrawerOpenforShareProposal] = useState(false);
  const [windowWithQuick, setWindowWithQuick] = useState();
  const [coveringsAppliedWindowsWithQuick, setCoveringsAppliedWindowsWithQuick] = useState([]);
  // const [proposalAcceptanceStatus, setProposalAcceptanceStatus] = useState('');
  const [shareableLink, setShareableLink] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessageForMail, setSuccessMessageForMail] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [notesSuccessMessage, setNotesSuccessMessage] = useState(false);
  const [notesAlertMessage, setNotesAlertMessage] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [navigateSalesOrder, setNavigateSalesOrder] = useState([]);
  const [selectedWindows, setSelectedWindows] = useState([]);
  const [remeasureOpen, setRemeasureOpen] = useState(false);
  const [installAccessory, setInstallAccessory] = useState('');
  const [proposalId, setProposalID] = useState(location.state.proposalID);
  const [alertForConvertToSalesOrder, setAlertForConvertToSalesOrder] = useState(false);
  const [alertMessageForConvertToSalesOrder, setAlertMessageForConvertToSalesOrder] = useState('');

  // error handling states
  const [duplicateProposalErrorAlert, setDuplicateProposalErrorAlert] = useState(false);
  const [duplicateProposalErrorMessage, setDuplicateProposalErrorMessage] = useState('');

  const [proposalByProposalIdErrorAlert, setProposalByProposalIdErrorAlert] = useState(false);
  const [coveringProposalDetailsErrorAlert, setCoveringProposalDetailsErrorAlert] = useState(false);
  const [customerErrorAlert, setCustomerErrorAlert] = useState(false);
  const [customerErrorMessage, setCustomerErrorMessage] = useState('');

  const [coveringProposalDetailsErrorMessage, setCoveringProposalDetailsErrorMessage] = useState('');
  const [proposalByProposalIdErrorMessage, setProposalByProposalIdErrorMessage] = useState('');

  const [file, setFile] = useState('');
  const [content, setContent] = useState([]);

  //  hover states
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);

  const handleHoverFun = () => setIsHovered(true);

  const handleMouseLeaveFun = () => setIsHovered(false);

  const handleHoverFun2 = () => setIsHovered2(true);

  const handleMouseLeaveFun2 = () => setIsHovered2(false);

  const handleHoverFun3 = () => setIsHovered3(true);

  const handleMouseLeaveFun3 = () => setIsHovered3(false);

  let filename;

  const unique = (value, index, self) => self.indexOf(value) === index;

  const vertical = 'top';
  const horizontal = 'center';

  let proposaldetails = {
    customerdata: location.state.customerdata,
    proposal_id: location.state.proposalID,
    proposal_name: location.state.proposalName,
    // proposal_acceptance_status: proposalAcceptanceStatus,
  };

  if (location.state.successwindownames || location.state.failurewindownames) {
    const { windowsselectednotnull, successwindownames, failurewindownames } = location.state;
    alertmessageDetails['successwindownames'] = windowsselectednotnull.filter((win) => successwindownames.includes(win.window_name)).map((win) => win.window_name);
    alertmessageDetails['failurewindownames'] = windowsselectednotnull.filter((win) => failurewindownames.includes(win.window_name)).map((win) => win.window_name);
    alertmessageDetails['key'] = location.key;
    alertmessageDetails['alerts'] = location.state.alerts;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    initialize();
  }, []);

  const initialize = async () => {
    await getVerified();
    await getProposalByProposalId();
    await getCoveringDetails();
    await getNotes();
  };

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDuplicateSuccessMessage(null);
  };

  const handleNotesClose = () => {
    setSuccessMessageForMail(false);
    setNotesSuccessMessage(false);
  };

  const duplicateProposal = async () => {
    const options = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/duplicateProposal/${location.state.proposalID}`, options);
    const data = await response.json();

    if (!response.ok && data.error) {
      setErrorMessage(data.error_message);
    }
    setDuplicateSuccessMessage('Duplicate Proposal Created!');
  };

  const saveProposal = async (path) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: location.state.proposalID,
        remeasure,
        remeasure_instructions: remeasureInstructions,
      }),
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/SaveProposal`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      setErrorMessage(data.error_message);
      return;
    }

    setEditchecked(false);
    setEditDisappear(true);
    setAddEditButton(false);
    setCheckboxFlag(true);

    if (path === 'remeasure') {
      setRemeasureInstructions('');
      return initialize();
    }
    navigate(`/tbm/ps/customerdetails`, {
      state: {
        Custdata: { custid: customerId, Addressid: customerAddress },
      },
    });
  };

  const getProposalByProposalId = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/ProposalByProposalId/${location.state.proposalID}`, requestOptions);

    const { error, error_message, proposal, ...data } = await response.json();
    if (!response.ok || error) {
      setProposalByProposalIdErrorMessage(error_message);
      setProposalByProposalIdErrorAlert(true);
      return;
    }
    setProposalName(proposal.proposal_name);
    setHasSalesOrder(data.has_sales_order);
    setInstallAccessory(proposal.install_requested);
    setCustomerId(proposal.customer_id);
    setCustomerAddress(proposal.address_id);
    setSelectedWindows(data.proposal_details);
    setNavigateSalesOrder(data.proposal_details);
    // setProposalAcceptanceStatus(proposal.adobe_sign_status === 'ACCEPTED_BY_SALES_REP' ? 'ACCEPTED_BY_SALES_REP' : proposal.adobe_sign_status === 'ACCEPTED_BY_CUSTOMER' ? 'ACCEPTED_BY_CUSTOMER' : '');

    let additionalCharge = data.proposal_level_accessory_service_charges;
    let accessoriesDetails = [];
    let serviceDetails = [];
    let additionalPriceData = [];

    additionalCharge.forEach((charge) => {
      if (charge.charge_type === 'X') {
        accessoriesDetails.push(charge);
      }
      if (charge.charge_type === 'S') {
        serviceDetails.push(charge);
      }
      if (charge.charge_type === 'AD') {
        additionalPriceData.push(charge);
      }
    });

    setAddtionalCharge(additionalPriceData);
    setAccessoriesCharge(accessoriesDetails);
    setServicesCharge(serviceDetails);
    setFinalTotal(proposal.final_total);
    setShowDiscount(proposal.show_disc);
    setDiscount(+proposal.discount);
    setDiscountDollar(proposal.discount_dollar);
    setCommission(proposal.commission);
    setTax(proposal.sales_tax);
    setInstallationCost(+proposal.install_total + +proposal.additional_install_service_charge);
    setProposalRetailPrice(proposal.retail_total);

    setSkuDetails(data.SKUDetails);
    setExtraCharges({ proposal, ...data });
    setSavedRemeasure(proposal.remeasure);

    if (proposal.remeasure) {
      setRemeasure(proposal.remeasure);
    }

    setLabels(labels.filter(unique));
    AppliedCoveringsWindows = [];
    SelectedWindows = [];

    if (location.state.path === 'reorder') {
      AppliedCoveringsWindows = location.state.windowsselectednotnull;
      setExtraCharges(location.state.extraCharges);
    } else {
      AppliedCoveringsWindows = data.proposal_details;
      SelectedWindows = data.proposal_details.map(({ covering_status }) => covering_status === 'applied');
    }
  };

  const handleAddEditItems = () => {
    setIsAddItemDrawerOpen(true);
  };

  const handleShareProposal = () => {
    setIsDrawerOpenforShareProposal(true);
  };

  useEffect(() => {
    setProposalID(location.state.proposalID);

    if (location.state.path === 'reorder') {
      setExtraCharges(location.state.extraCharges);
    }

    setProposalName(location.state.proposalName);
    setCoveringsAppliedWindows(AppliedCoveringsWindows);
    getProposalCoveringDetails();

    if (!location.state) {
      initialize();
    }
  }, [remeasure]);

  let windowsetdata = location.state.customerdata;

  const commonData = {
    id: location.state.proposalID,
    path: location.state.path,
    SkuDetails: skuDetails,
    AppliedCoveringsWindows: AppliedCoveringsWindows,
    selectedJsonData: selectedJsonData,
    windowsetdata: windowsetdata,
    extraCharges: extraCharges,
  };

  const getProposalCoveringDetails = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    await fetch(`${CONFIG.API_URL}/pm/CoveringProposalDetails/${location.state.proposalID}`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (data.error) {
          setCoveringProposalDetailsErrorMessage(data.error_message);
          setCoveringProposalDetailsErrorAlert(true);
          return;
        }

        const coveringswithquick = data.covering_proposal_details.filter(({ covering_status, proposal_measurement_type }) => covering_status === 'applied' && proposal_measurement_type === 'Quick');

        setCoveringsAppliedWindowsWithQuick(coveringswithquick);
        setWindowsSentForCoverings(data.covering_proposal_details.filter(({ covering_status }) => covering_status === 'applied'));

        setWindowWithQuick(coveringswithquick.length);
        setErrorMessage(coveringswithquick.length && remeasure === 'None');
        setShareProposal(data.covering_proposal_details.filter(({ covering_status }) => covering_status === 'applied'));
        setConvertToOrder(data.covering_proposal_details.every(({ confirm_to_sales_order }) => confirm_to_sales_order));
      });
  };

  const navigateNextPage = () => {
    navigate(`/tbm/ps/editmode/${params.id}`, {
      state: {
        data: commonData,
        coveringdetails: coveringdetails,
        selectedwindows: windowsSentforCoverings,
        customerdata: windowsetdata,
        totalwindows: location.state.totalwindows || AppliedCoveringsWindows,
        proposalID: location.state.proposalID || location.state.proposal_id,
        proposalName: proposalName || location.state.proposal_name,
      },
    });
  };

  const fetchdata = (drawerstatus) => {
    if (!drawerstatus) {
      initialize();
    }

    setIsDrawerOpen(drawerstatus);
  };

  const handleAccessoriesDrawerClosed = (savedstatus) => {
    setIsAddItemDrawerOpen(false);
    if (savedstatus) {
      initialize();
    }
  };

  const [notesContent, setnotesContent] = useState([]);

  const [eyeButtonMsg, setEyeButtonMsg] = useState(false);
  const [notesData, setNotesData] = useState('');
  const [noteTypeId, setNoteTypeId] = useState('');
  const [noteId, setNoteId] = useState();
  const notesDataDescription = [];

  const [valueValue, setValueValue] = useState();

  const fetchDeliveryDrawerData = (drawerstatus, sendstatus, mailStatus, successMsg, successOpen, proposalStatus) => {
    setIsDrawerOpenforShareProposal(drawerstatus);

    if (mailStatus === true) {
      setSuccessMessageForMail(true);
      setSuccessMessage(successMsg);
      // setProposalAcceptanceStatus(proposalStatus);
    }
  };

  const saveRemeasure = async ({ target }) => {
    const path = 'remeasure';
    setWindowWithQuick(coveringsAppliedWindowsWithQuick.length > 0 && remeasure === 'None');
    setErrorMessage(coveringsAppliedWindowsWithQuick.length > 0 && remeasure === 'None');
    if (target.name === 'remeasure') {
      setRemeasure(target.value);
      if (!target.value || target.value === 'None') {
        deleteRemeasureNote(notesContent.find(({ content }) => content.includes('REMEASURE --'))?.id);
      }
    } else {
      saveProposal(path);
    }
  };

  const GetSalesOrderName = async () => {
    const response = await fetch(`${CONFIG.API_URL}/so/ConvertToSalesOrder/${proposalId}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      setAlertForConvertToSalesOrder(true);
      setAlertMessageForConvertToSalesOrder(data.error_message);
      return;
    }

    let confirmall = data.SalesOrderDetails.every(({ sales_order_status }) => sales_order_status);

    if (confirmall) {
      navigate('/tbm/ps/finalizesalesorder', {
        state: {
          SalesOrder: data.SalesOrder,
          SalesOrderDetails: data.SalesOrderDetails,
          ProposalDetails: navigateSalesOrder,
          remeasure: savedRemeasure,
          customerId: customerId,
          addressId: customerAddress,
          customerDetails: proposaldetails,
          data: {
            Labels: labels,
            Proposaldetails: location.state,
            shareablelink: shareableLink,
            route: 'shareproposal',
            content: content,
          },
          salesOrderId: data.SalesOrder.sales_order_id,
        },
      });
    } else {
      navigate(`/tbm/ps/salesorderaddingwindows`, {
        state: {
          SalesOrder: data.SalesOrder,
          SalesOrderDetails: data.SalesOrderDetails,
          ProposalDetails: navigateSalesOrder,
          remeasure: savedRemeasure,
          customerId: customerId,
          addressId: customerAddress,
          customerDetails: proposaldetails,
          data: {
            Labels: labels,
            Proposaldetails: location.state,
            shareablelink: shareableLink,
            route: 'shareproposal',
            content: content,
          },
        },
      });
    }
  };

  const previewProposal = () =>
    navigate(`/tbm/ps/previewproposal/${proposalId}`, {
      state: {
        acceptedby: 'ACCEPTED_BY_SALES_REP',
      },
    });

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  const [isImageHovered3, setIsImageHovered3] = useState(false);
  const handleHover3 = () => {
    setIsImageHovered3(!isImageHovered3);
  };

  const [isImageHovered4, setIsImageHovered4] = useState(false);
  const handleHover4 = () => {
    setIsImageHovered4(!isImageHovered4);
  };

  const [isImageHovered5, setIsImageHovered5] = useState(false);
  const handleHover5 = () => {
    setIsImageHovered5(!isImageHovered5);
  };

  const [isImageHovered6, setIsImageHovered6] = useState(false);
  const handleHover6 = () => {
    setIsImageHovered6(!isImageHovered6);
  };

  const [isImageHovered7, setIsImageHovered7] = useState(false);
  const handleHover7 = () => {
    setIsImageHovered7(!isImageHovered7);
  };

  const closeDuplicateAlert = () => {
    setDuplicateSuccessMessage(null);
    navigate(`/tbm/ps/customerdetails`, {
      state: {
        Custdata: { custid: customerId, Addressid: customerAddress },
      },
    });
  };

  const [emailArray, setemailArray] = useState([]);

  const GetCustomerEmailFun = async () => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        customer_id: location.state.customerdata.customer_id,
        address_id: location.state.customerdata.address_id,
      }),
    };
    await fetch(`${CONFIG.API_URL}/cm/Customer`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (data.error) {
          setCustomerErrorMessage(data.error_message);
          setCustomerErrorAlert(true);
          return;
        }

        data.customer[0].email.map((ele) => {
          emailArray.push(ele.email_id);
        });
      });
  };

  const fetchProposalTableData = (error) => {
    if (error === 'Servererror: invalid input syntax for type uuid: "undefined"') {
      navigate('/pagenotfound');
    }
  };

  const [editingNote, setEditingNote] = useState(false);
  const [noteDrawerDetails, setNoteDrawerDetails] = useState({});
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);

  const closeNotesDrawer = async (alert) => {
    setNotesDrawerOpen(false);
    setEditingNote(false);
    setNoteDrawerDetails({});

    if (alert.success) {
      await getNotes();
    }
  };

  const deleteRemeasureNote = async (id) => {
    if (!id) {
      return;
    }
    const options = {
      method: 'DELETE',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/note/${id}`, options);
    const { error, message } = await response.json();
    if (!response.ok || error) {
      return;
    }
    await getNotes();
  };

  const getNotes = async () => {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/Notes/${proposalId}`, options);
    const data = await response.json();
    if (!response.ok || data.error) {
      return;
    }
    setnotesContent(data.Notes);
  };

  const openNotesDrawer = (note) => {
    setEditingNote(!!note);
    setNoteDrawerDetails({
      name: proposalName,
      referenceId: proposalId,
      noteId: note ? note.id : null,
    });
    setNotesDrawerOpen(true);
  };

  const [windows, setWindows] = useState([]);
  const [TotalWindowsForApplyingCovering, setTotalWindowsForApplyingCovering] = useState([]);
  const [CoveringProposalDetailsErrorMsg, setCoveringProposalDetailsErrorMsg] = useState('');

  const getCoveringDetails = async (status) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    // get proposal details
    const response = await fetch(`${CONFIG.API_URL}/pm/CoveringProposalDetails/${commonData.id}`, requestOptions);
    const data = await response.json();

    let CoveringSelectedWindows = [];
    if (!response.ok || data.error) {
      setWindows([]);
      setCoveringProposalDetailsErrorMsg(data.error_message);
      setCoveringProposalDetailsErrorAlert(true);
      fetchProposalTableData(data.error_message);
    }
    setTotalWindowsForApplyingCovering(data.covering_proposal_details);

    if (status === 'success') {
      data.covering_proposal_details.forEach((coveringProposalDetail) => {
        if (coveringProposalDetail.covering_status === 'applied') {
          CoveringSelectedWindows.push(coveringProposalDetail);
        }
      });
    }

    setWindows(data.covering_proposal_details.filter(({ covering_status }) => covering_status !== 'not_consider'));
  };

  const [remeasureInstructions, setRemeasureInstructions] = useState('');

  const hasRemeasureIssues = () => {
    if (coveringsAppliedWindowsWithQuick.length > 0 && remeasure === 'None') {
      return true;
    }
    if (remeasure !== 'None' && !notesContent.some(({ content }) => content.includes('REMEASURE --'))) {
      return true;
    }
    return false;
  };

  return (
    <ThemeProvider theme={theme}>
      {location.state.successwindownames || location.state.failurewindownames ? <ProposalAlertMessage data={alertmessageDetails} /> : null}
      <Drawer
        classes={{ paper: classes.paper }}
        PaperProps={{
          sx: { width: '90%' },
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        anchor='right'
        open={notesDrawerOpen}
        onClose={() => setNotesDrawerOpen(false)}
        className='drawer_Header_page'
      >
        <NotesDrawer closeDrawer={closeNotesDrawer} editing={editingNote} type='proposal' details={noteDrawerDetails} />
      </Drawer>

      {/* DuplicateProposalErrorAlert */}
      <Snackbar className='snackbar_bar_postition' open={duplicateProposalErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setDuplicateProposalErrorAlert(false)}>
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img
              onClick={() => {
                setDuplicateProposalErrorAlert(false);
              }}
              src={RedDrawerCancelIcon}
              alt='CANCELDPA'
              className='drawer_secondary_icons_dimension drawer_cancel_cursor'
            />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img src={RedExclamationIcon} alt='CONFIRMICONDPA' className='primary_icons_dimension alertImageMargin' />
            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{duplicateProposalErrorMessage}</div>
          </div>
        </Alert>
      </Snackbar>

      {/* ProposalByProposalIdErrorAlert */}
      <Snackbar className='snackbar_bar_postition' open={proposalByProposalIdErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setProposalByProposalIdErrorAlert(false)}>
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img
              onClick={() => {
                setProposalByProposalIdErrorAlert(false);
              }}
              src={RedDrawerCancelIcon}
              alt='CANCELICONPBPA'
              className='drawer_secondary_icons_dimension drawer_cancel_cursor'
            />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img src={RedExclamationIcon} alt='CONFIRMICONPBPA' className='primary_icons_dimension alertImageMargin' />
            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{proposalByProposalIdErrorMessage}</div>
          </div>
        </Alert>
      </Snackbar>

      {/* CoveringProposalDetailsErrorAlert */}
      <Snackbar className='snackbar_bar_postition' open={coveringProposalDetailsErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setCoveringProposalDetailsErrorAlert(false)}>
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img
              onClick={() => {
                setCoveringProposalDetailsErrorAlert(false);
              }}
              src={RedDrawerCancelIcon}
              alt='CANCELICONCPDA'
              className='drawer_secondary_icons_dimension drawer_cancel_cursor'
            />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img src={RedExclamationIcon} alt='CONFIRMICONCPDA' className='primary_icons_dimension alertImageMargin' />
            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{coveringProposalDetailsErrorMessage}</div>
          </div>
        </Alert>
      </Snackbar>

      {/* CustomerErrorAlert */}
      <Snackbar className='snackbar_bar_postition' open={customerErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setCustomerErrorAlert(false)}>
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img
              onClick={() => {
                setCustomerErrorAlert(false);
              }}
              src={RedDrawerCancelIcon}
              alt='CANCELICONCA'
              className='drawer_secondary_icons_dimension drawer_cancel_cursor'
            />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img src={RedExclamationIcon} alt='CONFIRMICONCA' className='primary_icons_dimension alertImageMargin' />
            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{customerErrorMessage}</div>
          </div>
        </Alert>
      </Snackbar>

      {/* Cancel Alert */}
      <Snackbar className='snackbar_bar_postition' open={successMessageForMail} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5_000} onClose={() => setSuccessMessageForMail(false)}>
        <Alert className='Success2 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img alt='CANCELICON' onClick={handleNotesClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img alt='CONFIRMICON' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />

            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{successMessage}</div>
          </div>
        </Alert>
      </Snackbar>

      {/* Convert To SO Alert */}
      <Snackbar className='snackbar_bar_postition' open={alertForConvertToSalesOrder} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setAlertForConvertToSalesOrder(false)}>
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <>
            <div className='alertCancel_2'>
              <img
                alt='CANCELICONCTSO'
                onClick={() => {
                  setAlertForConvertToSalesOrder(false);
                }}
                src={RedDrawerCancelIcon}
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img alt='CONFIRMICONCTSO' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />

              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{alertMessageForConvertToSalesOrder}</div>
            </div>
          </>
        </Alert>
      </Snackbar>

      <Snackbar className='snackbar_bar_postition' open={CoveringProposalDetailsErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setCoveringProposalDetailsErrorAlert(false)}>
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img
              onClick={() => {
                setCoveringProposalDetailsErrorAlert(false);
              }}
              src={RedDrawerCancelIcon}
              alt='CANCELICONCPD'
              className='drawer_secondary_icons_dimension drawer_cancel_cursor'
            />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img src={RedExclamationIcon} alt='CONFIRMICONCPD' className='primary_icons_dimension alertImageMargin' />
            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{CoveringProposalDetailsErrorMsg}</div>
          </div>
        </Alert>
      </Snackbar>

      <div align='center'>
        <Stack spacing={2} className='snack_Bar_Proposal_Details_Page'>
          <Snackbar className='snackbar_bar_postition' open={!!duplicateSuccessMessage} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5_000} onClose={closeDuplicateAlert}>
            <Alert className='Success2 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img alt='Close Icon' onClick={closeDuplicateAlert} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img alt='' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{duplicateSuccessMessage}</div>
              </div>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={notesSuccessMessage} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setNotesSuccessMessage(false)}>
            <Alert className='Success2 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img alt='CANCELICONNOTES' onClick={handleNotesClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img alt='CONFIRMICONNOTES' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />

                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{notesAlertMessage}</div>
              </div>
            </Alert>
          </Snackbar>
        </Stack>
      </div>

      <ShowProposalName proposaldetails={proposaldetails} disabled={hasSalesOrder} />

      {!hasSalesOrder ? (
        <img
          alt='NOTES'
          style={{
            width: '20px',
            color: '#008593',
            cursor: 'pointer',
            position: 'relative',
            float: 'right',
            top: '-55px',
          }}
          src={NoteMiscIcon}
          className='misc_icons_dimensions'
          onClick={() => openNotesDrawer()}
        />
      ) : null}

      <Box sx={{ marginBottom: '20px', marginTop: '20px' }}>
        {notesContent.map((note, index) => (
          <Box key={note.id} sx={{ display: 'flex' }}>
            {note.visible ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : null}{' '}
            <Box>
              <Typography className='' onClick={() => openNotesDrawer(note)}>
                {' '}
                <span
                  style={{
                    color: '#008593',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                >
                  Note #{index + 1} :
                </span>{' '}
                {note.content}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>

      <hr style={{ width: '100%' }} />

      <ProposalTable func={fetchProposalTableData} windows={windows} totalWindows={TotalWindowsForApplyingCovering} commonData={commonData} disabled={hasSalesOrder} />

      {!hasSalesOrder ? (
        <>
          <hr className='button_top_space' />

          <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page'>
            <span style={{ paddingLeft: '22px' }} className='gray_button_span button_top_space button_bottom_space' onClick={navigateNextPage}>
              <Box className='globalIconMainBox'>
                <img src={EditLinesIcon} className={`secondary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} />
                <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  {' '}
                  EDIT LINES
                </Typography>
              </Box>
            </span>
          </Box>
        </>
      ) : null}

      {!hasSalesOrder ? (
        <Stack sx={{ width: '100%' }}>
          <hr />
          <FormControl onClick={() => setRemeasureOpen(!remeasureOpen)} className='formcontrol text_selectfield_copy_upper font_style_of_menuitem button_top_space'>
            <Select
              size='small'
              MenuProps={{
                style: { zIndex: 10000000 },
              }}
              onClose={() => setRemeasureOpen(false)}
              onOpen={() => setRemeasureOpen(true)}
              name='remeasure'
              open={remeasureOpen}
              value={remeasure}
              onChange={saveRemeasure}
              onBlur={() => saveProposal('remeasure')}
              displayEmpty
              startAdornment={
                <InputAdornment className='font_style_of_menuitem' position='start'>
                  Remeasure<span style={{ color: '#DB3131' }}>&nbsp;*</span>&nbsp;:
                  {remeasure === '' ? '' : ''}
                </InputAdornment>
              }
            >
              <MenuItem name={'remeasure'} value={''}>
                <b>─ ─</b>
              </MenuItem>
              {remeasureOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <b className='font_style_of_menuitem'>{option.label}</b>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {remeasure && remeasure !== 'None' && !notesContent.some(({ content }) => content.includes('REMEASURE -- ')) ? (
            <TextField
              className='short_textfield formcontrol font_style_of_menuitem'
              sx={{
                margin: '5px 0 5px 20%',
                width: '30%',
              }}
              id='filled-basic'
              label='Remeasure Instructions'
              variant='outlined'
              required
              name='remeasure_instructions'
              onChange={({ target }) => setRemeasureInstructions(target.value)}
              onBlur={() => saveProposal('remeasure')}
              value={remeasureInstructions}
              InputProps={{
                style: { fontWeight: 'bold', fontFamily: 'Roboto' },
              }}
            />
          ) : null}
          {errorMessage || (!notesContent.some(({ content }) => content.includes('REMEASURE -- ')) && remeasure && remeasure !== 'None' && !remeasureInstructions) ? (
            <InformationAlerts
              startIcon={WarningIcon}
              alerts={[
                { key: 'remeasure', text: 'Cannot move forward until Quick measurements are resolved', active: errorMessage },
                { key: 'remeasure-instructions', text: 'Remeasure Instructions are required', active: !notesContent.some(({ content }) => content.includes('REMEASURE -- ')) && remeasure !== 'None' && !remeasureInstructions },
              ]}
            />
          ) : null}
        </Stack>
      ) : null}

      <hr className='button_top_space' />

      {!hasSalesOrder ? (
        proposalRetailPrice ? (
          <Box className='details_Main_Box_Proposal_Details_Page'>
            <Box className='details_Sub_Box_Proposal_Details_Page'>
              <Box sx={{ cursor: 'pointer' }} onClick={() => handleAddEditItems()} className='cust_Type_DC_Installation_Removelfee_Additional_Tax_Box_Second' onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
                <Box className='globalIconMainBox globalMarginLeftAddWindow globalTextDecoration'>
                  {isHovered ? <img alt='Add Items' className='primary_icons_dimension_1' src={AddItemsHovIcon} /> : <img alt='Add Items' className='primary_icons_dimension_1' src={AddItemsIcon} />}
                  <Typography onMouseEnter={handleHover2} onMouseLeave={handleHover2} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                    ADD ITEMS
                  </Typography>
                </Box>
              </Box>

              <Box className='cust_Type_DC_Installation_Removelfee_Additional_Tax_Box_Third'>
                <Box
                  onClick={() => setIsDrawerOpen(true)}
                  sx={{
                    width: '40px',
                    height: '20px',
                    paddingTop: '0px',
                    cursor: 'pointer',
                  }}
                >
                  <Box className='globalIconMainBox'>
                    <img alt='Ellipsis' src={SecretIcon} className='secondary_icons_dimension' />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className='details_Main_Box_Proposal_Details_Page '>
            <Box className='details_Sub_Box_Proposal_Details_Page'>
              <Box sx={{ cursor: 'pointer' }} className='cust_Type_DC_Installation_Removelfee_Additional_Tax_Box_Second'>
                <Box className='globalIconMainBox globalMarginLeftAddWindow globalTextDecoration'>
                  <img alt='ADDITEMSD' className='primary_icons_dimension_disable' src={AddItemsIcon} />
                  <Typography onMouseEnter={handleHover2} onMouseLeave={handleHover2} sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled '>
                    ADD ITEMS
                  </Typography>
                </Box>
              </Box>
              <Box className='cust_Type_DC_Installation_Removelfee_Additional_Tax_Box_Third'>
                <Box
                  sx={{
                    width: '40px',
                    height: '20px',
                    paddingTop: '0px',
                    cursor: 'pointer',
                  }}
                >
                  <Box className='primary_icons_dimension_disable'>
                    <img alt='SECRETICOND' src={SecretIcon} className='secondary_icons_dimension' />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )
      ) : null}

      <hr />
      <Box>
        <Grid container direction='row' spacing={0} className='section_row_1st'>
          <Grid item xs={9} md={9}>
            <div className='boldheadingText Coverings_Subtotal_Proposal_Details_Page button_top_space common_line_height_css'>COVERINGS SUBTOTAL : </div>
          </Grid>
          <Grid item xs={3} md={3}>
            <span className='boldheadingText Coverings_Subtotal_Proposal_Details_Page price button_top_space common_line_height_css'>{proposalRetailPrice ? `$${parseFloat(proposalRetailPrice).toFixed(2)}` : <Skeleton style={{ width: 50 }} />}</span>
          </Grid>
        </Grid>

        <Grid container direction='row' spacing={0} className='section_row_1st'>
          <Grid item xs={9} md={9}>
            <div className='rowTotalforproposal common_line_height_css common_text_height_css'>Installation : </div>
          </Grid>
          <Grid item xs={3} md={3}>
            <span className='rowTotalforproposal price common_line_height_css common_text_height_css'> {installationCost ? '$' + parseFloat(installationCost).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
          </Grid>
        </Grid>

        {accessoriesCharge.length > 0 ? (
          <Grid container direction='row' spacing={0} className='section_row_1st'>
            <Grid item xs={9} md={9}>
              <div className='rowTotalforproposal common_text_height_css common_text_height_css'>Accessories : </div>
              {accessoriesCharge.map((accessory, index) => (
                <div className='subAccessoriesAndServices common_line_height_css common_text_height_css'>{accessory.quantity > 1 ? `${accessory.charge_description} X ${accessory.quantity}` : accessory.charge_description}</div>
              ))}
            </Grid>

            <Grid item xs={3} md={3}>
              <div className='rowTotalforproposal'>&nbsp;</div>
              {accessoriesCharge.map((accessory, index) => (
                <div className='rowTotalforproposalforAccandserv common_line_height_css common_text_height_css'>${(+accessory.charge).toFixed(2)}</div>
              ))}
            </Grid>
          </Grid>
        ) : null}

        {servicesCharge.length ? (
          <Grid container direction='row' spacing={0} className='section_row_1st'>
            <Grid item xs={9} md={9}>
              <div className='rowTotalforproposal common_line_height_css common_text_height_css'>Services : </div>
              {servicesCharge.map((service) => (
                <div className='subAccessoriesAndServices common_line_height_css common_text_height_css'>{service.quantity > 1 ? `${service.charge_description} X ${service.quantity}` : service.charge_description}</div>
              ))}
            </Grid>
            <Grid item xs={3} md={3}>
              <div className='rowTotalforproposal'>&nbsp;</div>
              {servicesCharge.map((service) => (
                <div className='rowTotalforproposalforAccandserv common_line_height_css common_text_height_css'>${parseFloat(service.charge).toFixed(2)}</div>
              ))}
            </Grid>
          </Grid>
        ) : null}

        {addtionalCharge.length ? (
          <Grid container direction='row' spacing={0} className='section_row_1st'>
            <Grid item xs={9} md={9}>
              <div className='rowTotalforproposal common_line_height_css common_text_height_css'>Addtional Charge : </div>
              {addtionalCharge.map((charge) => (
                <div key={charge.proposal_id} className='subAccessoriesAndServices common_line_height_css common_text_height_css'>
                  {charge.charge_description}
                </div>
              ))}
            </Grid>
            <Grid
              xs={3}
              md={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <div className='rowTotalforproposal'>&nbsp;</div>
              {addtionalCharge.map((charge) => (
                <div key={charge.proposal_id} className='rowTotalforproposalforAccandserv common_line_height_css common_text_height_css' style={{ textAlign: 'right' }}>
                  ${parseFloat(charge.charge).toFixed(2)}
                </div>
              ))}
            </Grid>
          </Grid>
        ) : null}
        {+discount > 0 && showDiscount ? (
          <Grid container direction='row' spacing={0} className='section_row_1st'>
            <Grid item xs={9} md={9}>
              <div className='rowTotalforproposal common_line_height_css common_text_height_css'>Discount ({discount}%) : </div>
            </Grid>
            <Grid item xs={3} md={3}>
              <span className='rowTotalforproposal price common_line_height_css common_text_height_css'>{discountDollar ? '- $' + parseFloat(discountDollar).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
            </Grid>
          </Grid>
        ) : null}

        <Grid container direction='row' spacing={0} className='section_row_1st'>
          <Grid item xs={9} md={9}>
            <div className='rowTotalforproposal common_line_height_css common_text_height_css'>Tax : </div>
          </Grid>
          <Grid item xs={3} md={3}>
            <span className='rowTotalforproposal price common_line_height_css common_text_height_css'>{tax ? '$' + parseFloat(tax).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
          </Grid>
        </Grid>

        <Grid container direction='row' spacing={0} className='section_row_1st'>
          <Grid item xs={9} md={9}>
            <div className='boldheadingText Coverings_Subtotal_Proposal_Details_Page button_bottom_space common_line_height_css common_text_height_css'>PROPOSAL TOTAL :</div>
          </Grid>
          <Grid item xs={3} md={3}>
            <span className='boldheadingText Coverings_Subtotal_Proposal_Details_Page price common_line_height_css common_text_height_css'> {finalTotal ? '$' + parseFloat(finalTotal).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
          </Grid>
        </Grid>
      </Box>
      <hr />

      <Drawer
        classes={{ paper: classes.paper }}
        PaperProps={{
          sx: { width: '50vw' },
        }}
        anchor='right'
        open={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(true);
        }}
        className='drawer_Header_page'
      >
        <PriceDrawer func={fetchdata} data={extraCharges} />
      </Drawer>

      <AddOnDrawer isOpen={isAddItemDrawerOpen} close={handleAccessoriesDrawerClosed} proposalId={location.state.proposalID} labels={labels} />

      <Drawer
        PaperProps={{
          sx: { width: '90%' },
        }}
        classes={{ paper: classes.paper }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        anchor='right'
        open={isDrawerOpenforShareProposal}
        onClose={() => setIsDrawerOpenforShareProposal(true)}
        className='drawer_Header_page'
      >
        <ShareProposal
          func={fetchDeliveryDrawerData}
          data={{
            Labels: labels,
            Proposaldetails: location.state,
            shareablelink: shareableLink,
            route: 'shareproposal',
            content: content,
            emailArray: emailArray,
          }}
        />
      </Drawer>

      {!hasSalesOrder ? (
        <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page '>
          <span style={{ paddingLeft: '22px' }} className='gray_button_span button_top_space' onClick={saveProposal}>
            <Box onClick={saveProposal} className='globalIconMainBox'>
              <img alt='SAVEPROPOSAL' src={SaveIcon} className={`secondary_icons_dimension ${isImageHovered3 ? 'hovered' : ''}`} />
              <Typography onMouseEnter={handleHover3} onMouseLeave={handleHover3} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                {' '}
                SAVE PROPOSAL{' '}
              </Typography>
            </Box>
          </span>
        </Box>
      ) : null}

      <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page '>
        <span style={{ paddingLeft: '22px' }} className='gray_button_span button_top_space' onClick={previewProposal}>
          <Box className='globalIconMainBox'>
            <img alt='PREVIEWPROPOSAL' src={PreviewIcon} className={`secondary_icons_dimension ${isImageHovered4 ? 'hovered' : ''}`} />
            <Typography onMouseEnter={handleHover4} onMouseLeave={handleHover4} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
              {' '}
              PREVIEW PROPOSAL{' '}
            </Typography>
          </Box>
        </span>
      </Box>

      <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page '>
        <span style={{ paddingLeft: '22px' }} className='gray_button_span button_top_space' onClick={duplicateProposal}>
          <Box className='globalIconMainBox'>
            <img alt='DUPLICATEPROPOSAL' src={AddOutlinedIcon} className={`secondary_icons_dimension ${isImageHovered5 ? 'hovered' : ''}`} />
            <Typography onMouseEnter={handleHover5} onMouseLeave={handleHover5} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
              {' '}
              DUPLICATE PROPOSAL{' '}
            </Typography>
          </Box>
        </span>
      </Box>

      <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page'>
        <span className='button_span button_top_space' onMouseEnter={handleHoverFun2} onMouseLeave={handleMouseLeaveFun2}>
          <Box onClick={handleShareProposal} className='globalIconMainBox globalMarginLeftAddWindow'>
            {isHovered2 ? <img alt='SHAREPROPOSALH' className='primary_icons_dimension_1' src={ShareHovIcon} /> : <img alt='SHAREPROPOSAL' className='primary_icons_dimension_1' src={ShareIcon} />}
            <Typography onMouseEnter={handleHover6} onMouseLeave={handleHover6} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
              SHARE PROPOSAL
            </Typography>
          </Box>
        </span>
      </Box>
      {!(convertToOrder && remeasure) ? (
        <InformationAlerts
          startIcon={WarningIcon}
          alerts={[
            { key: 'convert-to-order', text: 'All windows must be confirmed for Sales Order', active: !convertToOrder },
            { key: 'remeasure', text: 'Remeasure must be selected', active: !remeasure },
            // { key: 'proposal-acceptance', text: 'Proposal must be accepted before conversion to sales order', active: !proposalAcceptanceStatus },
          ]}
        />
      ) : null}


      {!hasSalesOrder ? (
        convertToOrder  && !hasRemeasureIssues() ? (
          <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page '>
            <span onClick={GetSalesOrderName} className='button_span button_top_space ' onMouseEnter={handleHoverFun3} onMouseLeave={handleMouseLeaveFun3}>
              <Box className='globalIconMainBox globalMarginLeftAddWindow page_margin_bottom'>
                {isHovered3 ? <img alt='CONVERTTOSALESORDERH' className='primary_icons_dimension_1' src={CTSOHovIcon} /> : <img alt='CONVERTTOSALESORDER' className='primary_icons_dimension_1' src={CTSOIcon} />}
                <Typography onMouseEnter={handleHover7} onMouseLeave={handleHover7} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  CONVERT TO SALES ORDER
                </Typography>
              </Box>
            </span>
          </Box>
        ) : (
          <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page '>
            <span className='button_span_disabled button_top_space '>
              <Box className='globalIconMainBox globalMarginLeftAddWindow page_margin_bottom'>
                <img className='primary_icons_dimension_disable' src={CTSOIcon} alt='ctsoicon' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                  CONVERT TO SALES ORDER
                </Typography>
              </Box>
            </span>
          </Box>
        )
      ) : null}
    </ThemeProvider>
  );
}
