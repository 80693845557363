import { Link } from 'react-router-dom';
import fracty from 'fracty';
import { TableContainer, Table as MuiTable, TableBody, Typography } from '@mui/material';
import LongText from '../LongText';
import Table, { formatLabel } from '../Shared/Table';

export function RollerShades({ windows, state, disabled }) {
  const rows = windows.map((window) => {
    const { proposal_measurement_type, sku_details } = window;
    const measurementType =
      proposal_measurement_type === 'Quick'
        ? '(Q)'
        : proposal_measurement_type === 'Shutters'
        ? '(SH)'
        : proposal_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          proposal_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';

    const baseWindow = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence > 9 ? '20px' : '30px' }}>{+window.item_sequence}</span>
          {!disabled ? (
            <Link
              className={window.covering_status === 'applied' ? 'link3' : 'red-link-for-covering_not_applied ellipsis2'}
              to='/tbm/ps/editingwindowproposal'
              state={{
                ...state,
                covering_status: window.covering_status,
                row_id: window.window_id,
                window_name: window.window_name,
              }}
            >
              <LongText>{window.window_name}</LongText>
            </Link>
          ) : (
            <LongText>{window.window_name}</LongText>
          )}
        </>
      ),
      size: (
        <>
          {`${fracty(window.proposal_window_width)}" X ${fracty(window.proposal_window_height)}"`} <span className={proposal_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      material: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.value_1,
      direction: liftSystemData.value_2,
    };

    const color = { color: window.proposal_specific_factor['Material Color']?.name };
    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ value_1, value_2 }) => ({ [value_2]: value_1 }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...baseWindow,
        ...materials,
        ...liftSystem,
        ...color,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    align: 'center',
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Roller Shades
      </Typography>
      <TableContainer>
        <MuiTable className='proposal_table' aria-labelledby='tableTitle' size='small'>
          <Table.Header columns={columns} />
          <TableBody>
            {rows.map((row) => (
              <Table.Row key={row.id} columns={columns} row={row} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  );
}

export function Shutters({ windows, state, disabled }) {
  const rows = windows.map((window) => {
    const { proposal_measurement_type, sku_details } = window;
    const measurementtype =
      proposal_measurement_type === 'Quick'
        ? '(Q)'
        : proposal_measurement_type === 'Shutters'
        ? '(SH)'
        : proposal_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          proposal_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';

    const baseWindow = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence > 9 ? '20px' : '30px' }}>{+window.item_sequence}</span>
          {!disabled ? (
            <Link
              className={window.covering_status === 'applied' ? 'link3' : 'red-link-for-covering_not_applied ellipsis2'}
              to='/tbm/ps/editingwindowproposal'
              state={{
                ...state,
                covering_status: window.covering_status,
                row_id: window.window_id,
                window_name: window.window_name,
              }}
            >
              <LongText>{window.window_name}</LongText>
            </Link>
          ) : (
            <LongText>{window.window_name}</LongText>
          )}
        </>
      ),
      size: (
        <>
          {`${fracty(window.proposal_window_width)}" X ${fracty(window.proposal_window_height)}"`} <span className={proposal_measurement_type.replace(/ /g, '')}>{measurementtype}</span>
        </>
      ),
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      material: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.value_1,
      direction: liftSystemData.value_2,
    };
    const color = { color: window.proposal_specific_factor['Material Color']?.name };
    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ value_1, value_2 }) => ({ [value_2]: value_1 }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...baseWindow,
        ...materials,
        ...liftSystem,
        ...color,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Shutters
      </Typography>
      <TableContainer>
        <MuiTable className='proposal_table' aria-labelledby='tableTitle' size='small'>
          <Table.Header columns={columns} />
          <TableBody>
            {rows.map((row) => (
              <Table.Row key={row.id} columns={columns} row={row} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  );
}

export function Blinds({ windows, state, disabled }) {
  const rows = windows.map((window) => {
    const { proposal_measurement_type, sku_details } = window;
    const measurementType =
      proposal_measurement_type === 'Quick'
        ? '(Q)'
        : proposal_measurement_type === 'Shutters'
        ? '(SH)'
        : proposal_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          proposal_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';
    const baseWindow = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence > 9 ? '20px' : '30px' }}>{+window.item_sequence}</span>
          {!disabled ? (
            <Link
              className={window.covering_status === 'applied' ? 'link3' : 'red-link-for-covering_not_applied ellipsis2'}
              to='/tbm/ps/editingwindowproposal'
              state={{
                ...state,
                covering_status: window.covering_status,
                row_id: window.window_id,
                window_name: window.window_name,
              }}
            >
              <LongText>{window.window_name}</LongText>
            </Link>
          ) : (
            <LongText>{window.window_name}</LongText>
          )}
        </>
      ),
      size: (
        <>
          {`${fracty(window.proposal_window_width)}" X ${fracty(window.proposal_window_height)}"`} <span className={proposal_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      fabric: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.value_1,
      direction: liftSystemData.value_2,
    };

    const color = { color: window.proposal_specific_factor['Material Color']?.name };

    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ value_1, value_2 }) => ({ [value_2]: value_1 }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...baseWindow,
        ...materials,
        ...liftSystem,
        ...color,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    // width: "auto",
    label: formatLabel(key),
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Blinds
      </Typography>
      <TableContainer>
        <MuiTable className='proposal_table' aria-labelledby='tableTitle' size='small'>
          <Table.Header columns={columns} />
          <TableBody>
            {rows.map((row) => (
              <Table.Row key={row.id} columns={columns} row={row} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  );
}

export function SoftShades({ windows, state, disabled }) {
  const rows = windows.map((window) => {
    const { proposal_measurement_type, sku_details } = window;
    const measurementType =
      proposal_measurement_type === 'Quick'
        ? '(Q)'
        : proposal_measurement_type === 'Shutters'
        ? '(SH)'
        : proposal_measurement_type === 'Blinds'
        ? '(BL)'
        : '(' +
          proposal_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('') +
          ')';
    const baseWindow = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence > 9 ? '20px' : '30px' }}>{+window.item_sequence}</span>
          {!disabled ? (
            <Link
              className={window.covering_status === 'applied' ? 'link3' : 'red-link-for-covering_not_applied ellipsis2'}
              to='/tbm/ps/editingwindowproposal'
              state={{
                ...state,
                covering_status: window.covering_status,
                row_id: window.window_id,
                window_name: window.window_name,
              }}
            >
              <LongText>{window.window_name}</LongText>
            </Link>
          ) : (
            <LongText>{window.window_name}</LongText>
          )}
        </>
      ),
      size: (
        <>
          {`${fracty(window.proposal_window_width)}" X ${fracty(window.proposal_window_height)}"`} <span className={proposal_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
    };

    const materialData = sku_details.filter((sku) => sku.sku_type === 'M')[0];
    const materials = {
      brand: materialData.brand,
      label: materialData.label,
      product: materialData.product,
      type: materialData.type,
      fabric: materialData.fabric,
      opacity: materialData.opacity,
    };

    const liftSystemData = sku_details.filter((sku) => sku.sku_type === 'L')[0];
    const liftSystem = {
      lift: liftSystemData.value_1,
      direction: liftSystemData.value_2,
    };

    const color = { color: window.proposal_specific_factor['Material Color']?.name };

    const optionsData = sku_details.filter((sku) => sku.sku_type === 'P');
    return optionsData
      .map(({ value_1, value_2 }) => ({ [value_2]: value_1 }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {
        ...baseWindow,
        ...materials,
        ...liftSystem,
        ...color,
      });
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    // width: "auto",
    label: formatLabel(key),
    dataKey: key,
  }));

  return (
    <>
      <Typography variant='h6' sx={{ paddingTop: '1em', paddingBottom: '1em' }}>
        Soft Shades
      </Typography>
      <TableContainer>
        <MuiTable className='proposal_table' aria-labelledby='tableTitle' size='small'>
          <Table.Header columns={columns} />

          <TableBody>
            {rows.map((row) => (
              <Table.Row key={row.id} columns={columns} row={row} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
    </>
  );
}

const ProductTables = {
  Blinds,
  Shutters,
  RollerShades,
  SoftShades,
};

export default ProductTables;
