import { Box, Grid } from '@mui/material';

const Charges = ({ charges, customStyles }) => {
  return (
    <Box style={{ padding: '1em 0' }}>
      {/* Coverings Subtotal */}
      <Grid container direction='row' spacing={0} style={customStyles.section_row_1st}>
        <Grid xs={6} />
        <Grid xs={3} style={customStyles.boldheadingText}>
          COVERINGS SUBTOTAL :
        </Grid>
        <Grid xs={1} md={0.75} />
        <Grid xs={2} md={1.75}>
          <span className='price' style={customStyles.boldheadingText}>
            {charges.retailPrice ? `$${charges.retailPrice.toFixed(2)}` : null}
          </span>
        </Grid>
        <Grid xs={1} md={0.25} />
      </Grid>
      {/* Installation */}
      <Grid container direction='row' spacing={0} style={customStyles.section_row_1st}>
        <Grid xs={6} />
        <Grid xs={3}>
          <div style={customStyles.rowTotalforproposal}>Installation :</div>
        </Grid>
        <Grid xs={1} md={1.35} />
        <Grid xs={2} md={1}>
          <span className='rowTotalforproposalforAccandserv'>{charges.installation ? `$${charges.installation.toFixed(2)}` : null}</span>
        </Grid>
      </Grid>
      {/* Accessories */}
      {charges.accessories.length ? (
        <Grid container direction='row' spacing={0} className='section_row_1st'>
          <Grid xs={6} />
          <Grid xs={3} style={customStyles.rowTotalforproposal}>
            Accessories :
            {charges.accessories.map((accessory, index) => (
              <div key={index} className='subAccessoriesAndServices'>
                {accessory.charge_description}
              </div>
            ))}
          </Grid>

          <Grid xs={2.75} md={2.5}>
            <div style={customStyles.rowTotalforproposal}>&nbsp;</div>
            {charges.accessories.map((accessory, index) => (
              <div key={index} className='rowTotalforproposalforAccandserv'>
                ${parseFloat(accessory.charge).toFixed(2)}
              </div>
            ))}
          </Grid>
        </Grid>
      ) : null}
      {/* Services */}
      {charges.services.length ? (
        <Grid container direction='row' spacing={0} className='section_row_1st'>
          <Grid xs={6} />
          <Grid xs={3}>
            <div style={customStyles.rowTotalforproposal}>Services : </div>
            {charges.services.map((service, index) => (
              <div key={index} className='subAccessoriesAndServices'>
                {service.charge_description}
              </div>
            ))}
          </Grid>
          <Grid xs={2.75} md={2.5}>
            <div style={customStyles.rowTotalforproposal}>&nbsp;</div>
            {charges.services.map((service, index) => (
              <div key={index} className='rowTotalforproposalforAccandserv'>
                ${(+service.charge).toFixed(2)}
              </div>
            ))}
          </Grid>
        </Grid>
      ) : null}
      {/* Additional Charges */}
      {charges.additionalCharges.length ? (
        <Grid container direction='row' spacing={0} className='section_row_1st'>
          <Grid xs={6} />
          <Grid xs={3}>
            <div style={customStyles.rowTotalforproposal}>Additional Charges : </div>
            {charges.additionalCharges.map((charge, index) => (
              <div key={index} className='subAccessoriesAndServices'>
                {charge.charge_description}
              </div>
            ))}
          </Grid>
          <Grid xs={2.75} md={2.5}>
            <div style={customStyles.rowTotalforproposal}>&nbsp;</div>
            {charges.additionalCharges.map((charge, index) => (
              <div key={index} className='rowTotalforproposalforAccandserv'>
                ${(+charge.charge).toFixed(2)}
              </div>
            ))}
          </Grid>
        </Grid>
      ) : null}
      {/* Discount */}
      {charges.show_discount && charges.discount.percentage ? (
        <Grid container direction='row' spacing={0} style={customStyles.section_row_1st}>
          <Grid xs={6} />
          <Grid xs={3}>
            <div style={customStyles.rowTotalforproposal}>Discount ({charges.discount.percentage}%) :</div>
          </Grid>
          <Grid xs={1} md={0.75} />
          <Grid xs={2} md={1.75}>
            <span className='rowTotalforproposalforAccandserv' style={customStyles.rowTextforproposalfordesktop}>
              {charges.discount.total ? `- $${charges.discount.total}` : null}
            </span>
          </Grid>
        </Grid>
      ) : null}
      {/* Tax */}
      <Grid container direction='row' spacing={0} style={customStyles.section_row_1st}>
        <Grid xs={6} />
        <Grid xs={3}>
          <div style={customStyles.rowTotalforproposal}>Tax : </div>
        </Grid>
        <Grid xs={1} md={1.59} />
        <Grid xs={2} md={1}>
          <span className='rowTotalforproposalforAccandserv'>${charges.tax ? charges.tax : '0.00'}</span>
        </Grid>
      </Grid>
      {/* Proposal Total */}
      <Grid container direction='row' spacing={0} style={customStyles.section_row_1st}>
        <Grid xs={6} />
        <Grid xs={3}>
          <div style={customStyles.boldheadingText}>PROPOSAL TOTAL :</div>
        </Grid>
        <Grid xs={1} md={0.75} />
        <Grid xs={2} md={1.75}>
          <span className='price' style={customStyles.boldheadingText}>
            {charges.total ? `$${charges.total.toFixed(2)}` : null}
          </span>
        </Grid>
        <Grid xs={1} md={0.5} />
      </Grid>
    </Box>
  );
};
export default Charges;
