import { Box } from '@mui/system';
import { Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  paddingRight: theme.spacing(0.5),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

export function WindowDetails({ windowDetails }) {
  return (
    <Box className='show_Sku_Sub_Box_Proposal_Apply_Covering_Page'>
      <Grid
        item
        xs={12}
        display={{
          xs: 'block',
          lg: 'block',
          md: 'block',
          sm: 'block',
        }}
      >
        <h3 className='proposal_result_header'>Window Details :</h3>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={0} columns={2}>
            <Grid item xs={2} sm={1}>
              <Item>
                {windowDetails.measurementType ? (
                  <>
                    Measurement Type :{' '}
                    <Typography variant='h7' className='proposal_result_header'>
                      {windowDetails.measurementType}
                    </Typography>
                    <br />
                  </>
                ) : null}
                {windowDetails.size ? (
                  <>
                    Width X Height :{' '}
                    <Typography variant='h7' className='proposal_result_header'>
                      {windowDetails.size}
                    </Typography>
                    <br />
                  </>
                ) : null}
                {windowDetails.windowDepth ? (
                  <>
                    Window Depth :{' '}
                    <Typography variant='h7' className='proposal_result_header'>
                      {windowDetails.windowDepth.service_name === 'Standard' ? windowDetails.windowDepth.service_name : `${windowDetails.windowDepthMeasurement}"`}
                    </Typography>
                    <br />
                  </>
                ) : null}
                {windowDetails.measurementType !== 'Quick' && windowDetails.windowConfiguration ? (
                  <>
                    Window Configuration :{' '}
                    <Typography variant='h7' className='proposal_result_header'>
                      {windowDetails.windowConfiguration.service_name}
                    </Typography>
                    <br />
                  </>
                ) : null}
                {windowDetails.mountSurface ? (
                  <>
                    Mount Surface :{' '}
                    <Typography variant='h7' className='proposal_result_header'>
                      {windowDetails.mountSurface.service_name}
                    </Typography>
                    <br />
                  </>
                ) : null}
              </Item>
            </Grid>
            <Grid item xs={2} sm={1}>
              <Item>
                {windowDetails.buildType ? (
                  <>
                    Build Type :{' '}
                    <Typography variant='h7' className='proposal_result_header'>
                      {windowDetails.buildType.service_name}
                    </Typography>
                    <br />
                  </>
                ) : null}
                {windowDetails.obstructions ? (
                  <>
                    Obstructions :{' '}
                    <Typography variant='h7' className='proposal_result_header'>
                      {windowDetails.obstructions.service_name === 'None' ? windowDetails.obstructions.service_name : windowDetails.obstructionsDetails}
                    </Typography>
                    <br />
                  </>
                ) : null}
                {windowDetails.ladder ? (
                  <>
                    Ladder :{' '}
                    <Typography variant='h7' className='proposal_result_header'>
                      {windowDetails.ladder.service_name}
                    </Typography>
                    <br />
                  </>
                ) : null}
                {windowDetails.measurementType === 'Shutters' && windowDetails.windowSill ? (
                  <>
                    Window Sill :{' '}
                    <Typography variant='h7' className='proposal_result_header'>
                      {windowDetails.windowSill.service_name}
                    </Typography>
                    <br />
                  </>
                ) : null}
                {windowDetails.measurementType === 'Shutters' && windowDetails.verticalConfigurations ? (
                  <>
                    Vertical Configuration :{' '}
                    <Typography variant='h7' className='proposal_result_header'>
                      {windowDetails.verticalConfigurations.service_name}
                    </Typography>
                    <br />
                  </>
                ) : null}
              </Item>
            </Grid>

            <Grid item xs={3} sm={1}>
              <Item></Item>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
}

export default WindowDetails;
