/** @format */

import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import CONFIG from '../../config/config.js';
import Stack from '@mui/material/Stack';
import BackspaceOutlinedIcon from '../../img/backspace2.png';
import { useNavigate } from 'react-router-dom';
import Delete from '../../img/delete-key-symbol2.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerMeasurementIcon from '../../assets/icons/drawer-alert/drw-measurement.svg';
import DrawerCloseIcon from '../../assets/icons/drawer-alert/close.svg';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

export default function DrawerInputWidth(props) {
  const navigate = useNavigate();
  const [isDrawerClose, setIsDrawerClose] = useState(true);
  const [result, setResult] = useState('');
  const [print, setPrint] = useState('');
  const [disable, setdisabled] = useState(true);
  const [width, setWidth] = useState('');
  const [decimalwidth, setDecimalWidth] = useState('');
  const [viewbuttons, setViewbuttons] = useState(false);
  const [buttons, setButtons] = useState(false);
  const [match, setMatch] = useState(true);
  const [match1, setMatch1] = useState(false);
  const [CanclePassData, setCancelPassData] = useState(false);
  const [CancelGetDimesion, setCancelGetDimension] = useState('');
  const [cancelWidth, setcancelWidth] = useState('');
  const [cancelHeight, setcancelHeight] = useState('');
  const [HeigthType, setHeigthType] = useState(props.HeigthType);
  const [HeigthPrint, setHeigthPrint] = useState('');
  const [SectionTopHeigth, setSectionTopHeigth] = useState('');
  const [SectionTopHeigthWithDecimal, setSectionTopHeigthWithDecimal] = useState('');
  const [VerticalConfigurationSectionTopValueStatus, setVerticalConfigurationSectionTopValueStatus] = useState(false);
  const [limitDigit, setLimitDigit] = useState(false);
  const [limitFractionDigit, setLimitFractionDigit] = useState(false);
  const [VCValue, setVCValue] = useState(false);

  const [ForCancelSectionTop1, setForCancelSectionTop1] = useState('');
  const [ForCancelSectionTop2, setForCancelSectionTop2] = useState('');

  const [storedSection, setStoredSection] = useState('');
  const [onClickSectionCancel, setOnClickSectionCancel] = useState(false);
  const [sectionPosition, setSectionPosition] = useState('');

  if (props.HeigthType === 'SectionTopHeigth') {
    props.func2(HeigthPrint, isDrawerClose, SectionTopHeigth, SectionTopHeigthWithDecimal, VerticalConfigurationSectionTopValueStatus, VCValue, result.replace(/"/g, ''), ForCancelSectionTop1, ForCancelSectionTop2);
  } else {
    props.func(print.replace(/"/g, ''), isDrawerClose, width.replace(/"/g, ''), decimalwidth, result, cancelWidth, cancelHeight, CanclePassData, CancelGetDimesion, VerticalConfigurationSectionTopValueStatus, storedSection, onClickSectionCancel, sectionPosition);
  }

  useEffect(() => {
    if (result.toString().length >= 4) {
      setLimitDigit(true);
    } else {
      setLimitDigit(false);
    }

    // For Fraction
    if (result.toString().length >= 6) {
      setLimitFractionDigit(true);
    } else {
      setLimitFractionDigit(false);
    }

    if (result.toString().length === 0) {
      setLimitFractionDigit(true);
    }

    if (result.toString().length > 1) {
      setMatch1(true);
    } else {
      setMatch1(false);
    }
  }, [result]);

  const handleClick = (e) => {
    // On clicking x button once, it'll be disabled
    if (
      e.target.name === '1/4' ||
      e.target.name === '1/2' ||
      e.target.name === '3/4' ||
      e.target.name === '1/8' ||
      e.target.name === '3/8' ||
      e.target.name === '5/8' ||
      e.target.name === '7/8' ||
      e.target.name === '1/16' ||
      e.target.name === '3/16' ||
      e.target.name === '5/16' ||
      e.target.name === '7/16' ||
      e.target.name === '9/16' ||
      e.target.name === '11/16' ||
      e.target.name === '13/16' ||
      e.target.name === '15/16'
    ) {
      let newval = ' ' + e.target.name;

      if (props.HeigthType === 'SectionTopHeigth') {
        if (result.concat(newval).includes('"')) {
          const replaceResult = result.replace(/"/g, '');

          newval = replaceResult.concat(newval);
          setResult(newval + '"');
        } else {
          newval = e.target.name;
          setResult(result.concat(e.target.name) + '"');
        }

        setMatch(false);
      } else {
        if (result.concat(newval).includes('"')) {
          const replaceResult = result.replace(/"/g, '');

          newval = replaceResult.concat(newval);
          setResult(newval + '"');
        } else {
          newval = e.target.name;
          setResult(result.concat(e.target.name) + '"');
        }

        setMatch(false);
      }

      const pattern3 = /\d+\s*\d+\/\d+/;

      const hasFraction = pattern3.test(result);

      if (hasFraction === true) {
        setMatch(true);
      } else {
        setMatch(false);
      }
    } else {
      if (e.target.name === 'x') {
        const pattern = /^(?:\d{1,3})(?: (?:\d\/\d)?)?$/;

        let newval = e.target.name;

        setResult(result.concat(newval));

        if (pattern.test(result.concat(newval))) {
          setMatch(false);
        } else {
          setMatch(true);
        }
      } else {
        const pattern = /^(?:\d{1,3})(?: (?:\d\/\d)?)?$/;

        // for add inches start

        if (props.HeigthType === 'SectionTopHeigth') {
          let newval;

          newval = e.target.name;
          if (result.concat(e.target.name).includes('"')) {
            const replaceResult = result.replace(/"/g, '');

            newval = replaceResult.concat(newval);
            setResult(newval + '"');
          } else {
            newval = e.target.name;
            setResult(result.concat(e.target.name) + '"');
          }

          setMatch(false);
        } else {
          let newval;

          newval = e.target.name;
          if (result.concat(e.target.name).includes('"')) {
            const replaceResult = result.replace(/"/g, '');

            newval = replaceResult.concat(newval);
            setResult(newval + '"');
          } else {
            newval = e.target.name;
            setResult(result.concat(e.target.name) + '"');
          }

          setMatch(false);
        }
        // for add inches end
      }
    }

    if (e.target.name === 'x') {
      setdisabled(true);

      if (result.includes('/')) {
        var newStr = result.split(' ');

        var fractionalnos = newStr[1].split('/');
        var fractiontodecimal = parseInt(newStr[0]) + parseFloat(fractionalnos[0] / fractionalnos[1]);

        setWidth(result);

        setDecimalWidth(fractiontodecimal);
      } else {
        setWidth(result);
        setDecimalWidth('');
      }
    }

    // Check if data contains a fraction value or not
    if (result.includes('/')) {
      // Count the occurrences of fractions in data using a regular expression
      const fractionCount = (result.match(/\d+\/\d+/g) || []).length;

      if (fractionCount > 0) {
        setMatch(true);
      } else {
      }
    } else {
    }
  };

  const backspace1 = () => {
    const lastSpaceIndex = result.lastIndexOf(' ');
    let lengthOfSubstring;
    if (lastSpaceIndex !== -1) {
      const substringAfterLastSpace = result.substring(lastSpaceIndex + 1);
      lengthOfSubstring = substringAfterLastSpace.length;
    }

    let val = result.slice(0, -2);

    if (result.includes('/')) {
      if (lengthOfSubstring === 3) {
        val = result.slice(0, -4);

        setResult(val + '"');
      } else if (lengthOfSubstring === 4) {
        val = result.slice(0, -5);

        setResult(val + '"');
      } else if (lengthOfSubstring === 5) {
        val = result.slice(0, -6);

        setResult(val + '"');
      } else if (lengthOfSubstring === 6) {
        val = result.slice(0, -7);

        setResult(val + '"');
      }
    } else {
      if (val.includes('"')) {
        const replaceResult = val.replace(/"/g, '');

        setResult(val + '"');
      } else if (val === '') {
        setResult(val);
      } else {
        setResult(val + '"');
      }
    }
  };

  // Pass data from slider to Window - Width x Height, Input field
  const printClick = (e) => {
    var string = result;

    if (string.includes('x')) {
      let removeX = string.replace('x', '');
      string = removeX.replace(/\s/g, '');
    }
    if (string.includes('/')) {
      var newStr = string.split(' ');

      let newStr1 = newStr[1];

      if (newStr1.includes('"')) {
        newStr1 = newStr1.replace(/"/g, '');
      } else {
        newStr1 = newStr1;
      }

      var fractionalnos = newStr1.split('/');
      var fractiontodecimal = parseInt(newStr[0]) + parseFloat(fractionalnos[0] / fractionalnos[1]);

      setWidth(string);

      setDecimalWidth(fractiontodecimal);
    } else {
      setWidth(result);
      setDecimalWidth(result.replace(/"/g, ''));
    }
    if (string[0] === ' ') {
      var newStr = string.split(' ');

      const fractionString = newStr[1];
      const [numeratorStr, denominatorStr] = fractionString.split('/');
      const numerator = parseFloat(numeratorStr);
      const denominator = parseFloat(denominatorStr);

      if (denominator === 0) {
      } else {
        const decimalValue = numerator / denominator;

        setWidth(newStr[1]);
        setDecimalWidth(decimalValue);
      }
    } else {
    }

    setWidth(string);
    setPrint(result);
    setIsDrawerClose(false);

    if (HeigthType === 'SectionTopHeigth') {
      var string = result;
      if (string.includes('/')) {
        var newStr = string.split(' ');

        let newStr1 = newStr[1];

        if (newStr1.includes('"')) {
          newStr1 = newStr1.replace(/"/g, '');
        } else {
          newStr1 = newStr1;
        }

        var fractionalnos = newStr1.split('/');
        var fractiontodecimal = parseInt(newStr[0]) + parseFloat(fractionalnos[0] / fractionalnos[1]);

        if (newStr[0] === '') {
          setSectionTopHeigthWithDecimal(parseFloat(fractionalnos[0] / fractionalnos[1]));
        } else {
          setSectionTopHeigthWithDecimal(fractiontodecimal);
        }
        setSectionTopHeigth(result.replace(/"/g, ''));
      } else {
        setSectionTopHeigth(result.replace(/"/g, ''));
      }

      setHeigthPrint(result);
      setVerticalConfigurationSectionTopValueStatus(true);
    }
  };

  const closeDrawer = (e) => {
    setOnClickSectionCancel(true);

    setIsDrawerClose(false);
    setDecimalWidth('');
    setWidth('');
    setPrint('');
    setVCValue(true);
  };

  React.useEffect(() => {
    if (props.size) {
      let sampledata = props.size;

      setResult(sampledata);

      setCancelGetDimension(props.size.replace(/"/g, '"'));
    } else if (props.SectionOneTopShow) {
      //
      setForCancelSectionTop1(props.SectionOneTopShow);
    }

    const pattern = /^(\d{1,3})\s+(\d\/\d{1}\s+)?$/;

    if (pattern.test(props.size)) {
      // If the value matches the pattern, return the number 2
      setMatch(true);
    }

    if (props.section) {
      const sectionvalue = props.section;

      setStoredSection(sectionvalue);
      // This will print the updated value in the next render
    }

    if (props.WidthType) {
      const sectionNumber = props.WidthType;

      setSectionPosition(sectionNumber);
    }

    getVerified();
  }, [result, match, CancelGetDimesion]);
  // Get Token

  const [isConditionMet, setisConditionMet] = useState();

  useEffect(() => {
    conditionFun();
  }, []);

  useEffect(() => {}, [storedSection]);

  const [ResultPlaceHolderValue, setResultPlaceHolderValue] = useState('');

  useEffect(() => {
    if (props.HeigthType === 'SectionTopHeigth') {
      setResultPlaceHolderValue('Height');
    } else {
      setResultPlaceHolderValue('Width');
    }
  }, []);

  const conditionFun = () => {
    if (props.measurement_type === 'Quick') {
      setisConditionMet(false);
    } else {
      setisConditionMet(true);
    }
  };

  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const valueValidation = (e) => {
    const pattern = /^(\d{1,3})\s+(\d\/\d{1}\s+)?/;

    const resultNumeric = e.target.value.replace(/^([^0-9])/g, '');

    const newValue = resultNumeric.replace(/x/g, ' $& ');
    const newValueValue = newValue.replace(/\s+/g).trim();

    if (pattern.test(newValueValue) || pattern.test(result)) {
      setMatch(true);
    } else {
      setMatch(false);
    }
    setResult(newValueValue);
  };

  const showbuttons = () => {
    setViewbuttons(true);
    setButtons(true);
  };

  const hidebuttons = () => {
    setViewbuttons(false);
    setButtons(false);
  };
  return (
    <Box>
      <Box sx={{ flexGrow: 1, paddingTop: '10px' }} align='right'>
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>

          <Grid item xs={10} md={10} sm={10}>
            <img onClick={closeDrawer} src={DrawerCloseIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='closeDrawer' />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Box>
      <Box p={3} textAlign='center' paddingTop={0}>
        {props.measurement_type === 'Quick' || props.measurement_type === '' ? <h2 className='add_specific_fontcolor button_bottom_space'>Other Measurement</h2> : <h2 className='add_specific_fontcolor button_bottom_space'>Other Measurement</h2>}
        {/*------------------------ Input field---------------------------- */}
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}></Grid>
            <Grid item xs={12} md={6} sm={6}>
              <Stack className='drawer_input_width_height_text'>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {' '}
                  {ResultPlaceHolderValue === 'Width' ? <span>WIDTH</span> : <span style={{ opacity: '40%' }}>WIDTH</span>}
                  {ResultPlaceHolderValue === 'Height' ? <span>HEIGHT</span> : <span style={{ opacity: '40%' }}>HEIGHT</span>}
                </Box>

                <TextField
                  // className="drawer_input_result_textfield"
                  id='filled-basic'
                  placeholder={ResultPlaceHolderValue}
                  inputProps={{
                    className: 'width_and_height_header',
                  }}
                  value={result}
                  onChange={valueValidation}
                  variant='outlined'
                />
              </Stack>
              <Grid item xs={3}></Grid>
            </Grid>
          </Grid>
        </Box>

        <hr className='button_top_space button_bottom_space' />
        {/* Buttons arranged in stack 7-9 */}
        {/* ------------------------Calulator_Buttons---------------------------- */}

        <Box sx={{ flexFlow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}></Grid>

            <Grid item xs={12} md={6} sm={6}>
              <Box sx={{ marginBottom: '20px !important' }} className='calculator_drawer_digit_box_2'>
                <Box className={isConditionMet ? 'calculator_drawer_digit_box_1' : 'calculator_drawer_digit_box'}>
                  <Stack direction='row' spacing={4} className={isConditionMet ? 'button_margin_top_calculator' : 'button_margin_top_calculator_1'}>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='7'>
                      7
                    </Button>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='8'>
                      8
                    </Button>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='9'>
                      9
                    </Button>
                  </Stack>

                  {/* Buttons arranged in stack 4-6 */}
                  <Stack direction='row' spacing={4} className={isConditionMet ? 'button_margin_top_calculator' : 'button_margin_top_calculator_1'}>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='4'>
                      4
                    </Button>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='5'>
                      5
                    </Button>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='6'>
                      6
                    </Button>
                  </Stack>

                  {/* Buttons arranged in stack 1-3 */}
                  <Stack direction='row' spacing={4} className={isConditionMet ? 'button_margin_top_calculator' : 'button_margin_top_calculator_1'}>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='1'>
                      1
                    </Button>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='2'>
                      2
                    </Button>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='3'>
                      3
                    </Button>
                  </Stack>

                  {/* Buttons arranged in stack 0 - x */}
                  <Stack direction='row' spacing={4} className={isConditionMet ? 'button_margin_top_calculator' : 'button_margin_top_calculator_1'}>
                    <Button disabled={limitDigit} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='0' onClick={handleClick}>
                      0
                    </Button>

                    <Button
                      disabled={disable}
                      className='drawer_input_buttons_style_1 button_margin_calculator'
                      name='x'
                      style={{ opacity: '40%' }} // Set opacity to 40%
                    >
                      <img
                        src={Delete}
                        name='x'
                        className='buttonIcon'
                        style={{
                          width: '40px',
                          height: '40px',
                          position: 'absolute',
                          opacity: '40%', // Set opacity to 40%
                        }}
                        alt='delete'
                      />
                    </Button>

                    <Button className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator ' : 'drawer_input_buttons_style'} onClick={backspace1} id='backspace'>
                      <img
                        src={BackspaceOutlinedIcon}
                        className='buttonIcon'
                        style={{
                          width: '45px',
                          height: '40px',
                          position: 'absolute',
                        }}
                        alt='backspace'
                      />
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <hr className='button_top_space' />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              {props.measurement_type === 'Quick' || props.measurement_type === '' ? (
                ''
              ) : (
                <div>
                  {/* Buttons arranged in stack 3/4 - 1/4 */}
                  <div style={{ paddingTop: '20px', paddingBottom: '20px' }} className='measurement_fraction_main'>
                    <span>
                      <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_input_buttons_style2' name='1/4' sx={{ margin: '10px' }}>
                        1/4
                      </Button>
                      <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_input_buttons_style2' name='1/2' sx={{ margin: '10px' }}>
                        1/2
                      </Button>

                      <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_input_buttons_style2' name='3/4' sx={{ margin: '10px' }}>
                        3/4
                      </Button>
                    </span>
                    <span style={{ marginLeft: '60px' }}></span>

                    {/* Buttons arranged in stack 1/8 - 7/8 */}
                    <span>
                      <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_input_buttons_style2' name='1/8' sx={{ margin: '10px' }}>
                        1/8
                      </Button>
                      <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_input_buttons_style2' name='3/8' sx={{ margin: '10px' }}>
                        3/8
                      </Button>
                      <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_input_buttons_style2' name='5/8' sx={{ margin: '10px' }}>
                        5/8
                      </Button>
                      <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_input_buttons_style2' name='7/8' sx={{ margin: '10px' }}>
                        7/8
                      </Button>
                    </span>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Box>

        <hr className='' />
        {/* ---------------------------Fraction_buttons------------------------ */}
        {props.measurement_type === 'Quick' || props.measurement_type === '' ? (
          ''
        ) : (
          <>
            <Box sx={{ padding: '20px 8px 20px 8px !important' }} className='drawer_iconbutton' aria-label='edit'>
              {' '}
              1/16ths
              {viewbuttons ? <ArrowDropUpIcon onClick={hidebuttons} fontSize='medium' sx={{ position: 'relative', top: '5px' }} /> : <ArrowDropDownIcon onClick={showbuttons} fontSize='medium' sx={{ position: 'relative', top: '5px' }} />}
            </Box>
            {buttons ? (
              <>
                <Box sx={{ flexGrow: 1 }} className=''>
                  <span>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='1/16'>
                      1/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='3/16'>
                      3/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='5/16'>
                      5/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='7/16'>
                      7/16
                    </Button>
                  </span>

                  <span>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='9/16'>
                      9/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='11/16'>
                      11/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='13/16'>
                      13/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='15/16'>
                      15/16
                    </Button>
                  </span>
                </Box>
              </>
            ) : (
              ' '
            )}
            <hr className='' />
          </>
        )}

        {match1 ? (
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={3}></Grid>

              <Grid item xs={12} md={6} sm={6}>
                <span onClick={printClick} className='drawer_gray_button_span button_top_space' style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
                  <Box className='globalIconMainBox globalDrawerText'>
                    <img src={DrawerMeasurementIcon} className='primary_icons_dimension' alt='' />
                    <Typography
                      sx={{
                        fontFamily: 'RobotoLight',
                        textAlign: 'start',
                        width: '290px',
                      }}
                      className='drawerGlobalText'
                    >
                      {props.measurement_type === 'Quick' || props.measurement_type === '' ? 'APPLY QUICK MEASURE' : 'APPLY FINAL MEASURE'}
                    </Typography>
                  </Box>
                </span>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={3}></Grid>

              <Grid item xs={12} md={6} sm={6}>
                <span className='drawer_gray_button_span button_top_space' style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
                  <Box className='globalIconMainBox'>
                    <img src={DrawerMeasurementIcon} className='primary_icons_dimension_disable' alt='disablePrimaryIcon' />
                    <Typography
                      sx={{
                        fontFamily: 'RobotoLight',
                        textAlign: 'start',
                        width: '290px',
                      }}
                      className='drawerGlobalTextDisable'
                    >
                      {props.measurement_type === 'Quick' || props.measurement_type === '' ? 'APPLY QUICK MEASURE' : 'APPLY FINAL MEASURE'}
                    </Typography>
                  </Box>
                </span>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </Box>
        )}

        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={3}></Grid>

            <Grid item xs={12} md={6} sm={6}>
              <span className='drawer_gray_button_span button_top_space' onClick={closeDrawer} style={{ paddingBottom: '30px', paddingLeft: '14px' }}>
                <Box className='globalIconMainBox'>
                  <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension' alt='drawerCancelIcon' />
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
                    CANCEL
                  </Typography>
                </Box>
              </span>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
