import React from "react";
import { Box } from "@mui/material";

const Label = ({ label, customStyles, children }) => (
  <Box style={customStyles.section_row_1st}>
    <span style={customStyles.headingText}>{label}:</span>{" "}
    <span style={customStyles.rowTextCopy}>{children}</span>
  </Box>
);

export default Label;