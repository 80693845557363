/** @format */

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';
import AddIcon from '../../assets/icons/secondary/2nd-add.svg';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import ReturnIcon from '../../assets/icons/primary/return.svg';
import EditMiscIcon from '../../assets/icons/misc/misc-edit.svg';
import EditCancelIcon from '../../assets/icons/misc/2drw-cancel.svg';
import CONFIG from '../../config/config.js';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';
import DrawerConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import MuiAlert from '@mui/material/Alert';
import ReturnHovIcon from '../../assets/icons/primary/WithHovIcon/return-hov.svg';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function EditCustomerPhoneEmail() {
  const location = useLocation();
  const navigate = useNavigate();
  let locationState = [];
  let StoreEmailAddressVal = [];
  let StoreEmailAddressLenght;

  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    locationState = location.state;
    StoreEmailAddressVal = location.state.emailAddress;
    StoreEmailAddressLenght = location.state.emailAddress.length;
  }

  const [showPhoneNumber, setShowPhoneNumber] = React.useState(locationState.phoneno);
  const [phoneNumbers, setphoneNumbers] = React.useState(locationState.PhoneNo);

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  //---------------------------------------------------------------React-hooks----------------------------------
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //----------------------------------------------------------HandleEvents & Functions--------------------------------
  const handleCancel = (e) => {
    navigate('/tbm/ps/customerdetails', {
      state: {
        Custdata: {
          custid: locationState.custid,
          Addressid: locationState.addressid,
        },
      },
    });
  };
  function phoneFormat(input) {
    input = input.replace(/\D/g, '').substring(0, 10); //Strip everything but 1st 10 digits
    var size = input.length;
    if (size > 0) {
      input = '(' + input;
    }
    if (size > 3) {
      input = input.slice(0, 4) + ') ' + input.slice(4);
    }
    if (size > 6) {
      input = input.slice(0, 9) + '-' + input.slice(9);
    }
    return input;
  }

  const token = sessionStorage.getItem('token');
  const [open, setOpen] = React.useState(false);
  const [EmailDeleteAlert, setEmailDeleteAlert] = React.useState(false);
  const [deleteSuccessMsg, setDeleteSuccessMsg] = useState('');
  const [deleteEmailSuccessMsg, setDeleteEmailSuccessMsg] = useState('');
  const [ConfirmationMsg, setConfirmationMsg] = useState(false);
  const [EmailConfirmationMsg, setEmailConfirmationMsg] = useState(false);
  const [storePhoneId, setStorePhoneId] = useState();
  const [storeEmailId, setStoreEmailId] = useState();

  // error handling states
  const [DeleteCustomerPhoneErrorMsg, setDeleteCustomerPhoneErrorMsg] = useState(false);
  const [DeleteCustomerEmailErrorMsg, setDeleteCustomerEmailErrorMsg] = useState(false);

  const [DeleteCustomerEmailErrorMsgTxt, setDeleteCustomerEmailErrorMsgTxt] = useState('');

  const [DeleteCustomerPhoneErrorMsgTxt, setDeleteCustomerPhoneErrorMsgTxt] = useState('');

  const vertical = 'top';
  const horizontal = 'center';

  const DeletePhoneNumberFun = async (PhoneID) => {
    await fetch(CONFIG.API_URL + '/cm/DeleteCustomerPhone', {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        PhoneID: storePhoneId,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.message === 'Customer phone is deleted successfully!') {
          setDeleteSuccessMsg(data.message);
          setConfirmationMsg(false);

          setOpen(true);
          setTimeout(() => {
            navigate('/tbm/ps/customerdetails', {
              state: {
                Custdata: {
                  custid: locationState.custid,
                  Addressid: locationState.addressid,
                },
              },
            });
          }, [3000]);
        } else {
          setDeleteCustomerPhoneErrorMsgTxt(data.error_message);
          setDeleteCustomerPhoneErrorMsg(true);
        }
      });
  };

  const DeleteEmailIdFun = async () => {
    await fetch(CONFIG.API_URL + '/cm/DeleteCustomerEmail', {
      method: 'DELETE',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        customer_id: locationState.custid,
        customer_email_ids_id: storeEmailId,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.message === 'Customer email is deleted successfully!') {
          setDeleteEmailSuccessMsg(data.message);
          setEmailConfirmationMsg(false);
          setEmailDeleteAlert(true);
          setTimeout(() => {
            navigate('/tbm/ps/customerdetails', {
              state: {
                Custdata: {
                  custid: locationState.custid,
                  Addressid: locationState.addressid,
                },
              },
            });
          }, [3000]);
        } else {
          setDeleteCustomerEmailErrorMsgTxt(data.error_message);
          setDeleteCustomerEmailErrorMsg(true);
        }
      });
  };

  const DeleteWindowConfirmationFun = () => {
    setConfirmationMsg(true);
  };

  const DeleteEmailConfirmationFun = () => {
    setEmailConfirmationMsg(true);
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  const [isImageHovered3, setIsImageHovered3] = useState(false);
  const handleHover3 = () => {
    setIsImageHovered3(!isImageHovered3);
  };

  const [isImageHovered4, setIsImageHovered4] = useState(false);
  const handleHover4 = () => {
    setIsImageHovered4(!isImageHovered4);
  };

  //----------------------------------------------------------------------------------------------------------------------
  return (
    <div>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={DeleteCustomerPhoneErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setDeleteCustomerPhoneErrorMsg(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img
                onClick={() => {
                  setDeleteCustomerPhoneErrorMsg(false);
                }}
                src={RedDrawerCancelIcon}
                alt='RedDrawerCancelIcon'
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{DeleteCustomerPhoneErrorMsgTxt}</div>
            </div>
          </Alert>
        </Snackbar>
        <Snackbar className='snackbar_bar_postition' open={DeleteCustomerEmailErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setDeleteCustomerEmailErrorMsg(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img
                onClick={() => {
                  setDeleteCustomerEmailErrorMsg(false);
                }}
                src={RedDrawerCancelIcon}
                alt=''
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{DeleteCustomerEmailErrorMsgTxt}</div>
            </div>
          </Alert>
        </Snackbar>

        <Snackbar className='snackbar_bar_postition' open={ConfirmationMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setConfirmationMsg(false)}>
          <Alert className='Success mainAlert' variant='filled' icon={false}>
            <div className='alertCancel' align='right'>
              <img onClose={() => setConfirmationMsg(false)} src={DrawerCancelIcon} alt='DrawerCancelIcon' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
            </div>

            <div className='alertMainNew'>
              <div align='left' className='confirmIconStyle'>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'Center',
                  }}
                >
                  <Box className='alertCantinueButton'>
                    <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='DrawerAlertIcon' />
                  </Box>
                </Box>

                <div className='edit_cusomer_phone_email_popup_alert'>Are you sure you want to delete this phone number? This action cannot be undone</div>
              </div>

              <div
                align='left'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px 10px 0px 24px',
                  justifyContent: 'center',
                  alignItems: 'start',
                  lineHeight: '24px',
                }}
              >
                <Box
                  onClick={() => {
                    DeletePhoneNumberFun();
                  }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'Center',
                    cursor: 'pointer',
                    marginBottom: '20px',
                  }}
                >
                  <Box onClick={() => {}} className='alertCantinueButton'>
                    <img src={DrawerConinueIcon} className='primary_icons_dimension' alt='DrawerConinueIcon' />
                  </Box>
                  <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                    CONTINUE
                  </Typography>
                </Box>

                <Box
                  onClick={() => {
                    setConfirmationMsg(false);
                  }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'Center',
                    cursor: 'pointer',
                    paddingBottom: '16px',
                    paddingLeft: '15px',
                  }}
                >
                  <Box className='globalIconMainBox'>
                    <Box className='iconSecondaryButtonGlobalForDrawer'>
                      <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
                    </Box>
                    <Typography className='globalTextForDrawer'>CANCEL</Typography>
                  </Box>
                </Box>
              </div>
            </div>
          </Alert>
        </Snackbar>

        <Snackbar className='snackbar_bar_postition' open={EmailConfirmationMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setEmailConfirmationMsg(false)}>
          <Alert className='Success mainAlert' variant='filled' icon={false}>
            <div className='alertCancel' align='right'>
              <img
                onClick={() => {
                  setEmailConfirmationMsg(false);
                }}
                src={DrawerCancelIcon}
                alt='DrawerCancelIcon'
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>

            <div className='alertMainNew'>
              <div align='left' className='confirmIconStyle'>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'Center',
                  }}
                >
                  <Box className='alertCantinueButton'>
                    <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='DrawerAlertIcon' />
                  </Box>
                </Box>

                <div className='edit_cusomer_phone_email_popup_alert'>Are you sure you want to delete this email id? This action cannot be undone</div>
              </div>

              <div
                align='left'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '20px 10px 0px 24px',
                  justifyContent: 'center',
                  alignItems: 'start',
                  lineHeight: '24px',
                }}
              >
                <Box
                  onClick={() => {
                    DeleteEmailIdFun();
                  }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'Center',
                    cursor: 'pointer',
                    marginBottom: '20px',
                  }}
                >
                  <Box onClick={() => {}} className='alertCantinueButton'>
                    <img src={DrawerConinueIcon} className='primary_icons_dimension' alt='DrawerConinueIcon' />
                  </Box>
                  <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                    CONTINUE
                  </Typography>
                </Box>

                <Box
                  onClick={() => {
                    setEmailConfirmationMsg(false);
                  }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'Center',
                    cursor: 'pointer',
                    paddingBottom: '16px',
                    paddingLeft: '15px',
                  }}
                >
                  <Box className='globalIconMainBox'>
                    <Box className='iconSecondaryButtonGlobalForDrawer'>
                      <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
                    </Box>
                    <Typography className='globalTextForDrawer'>CANCEL</Typography>
                  </Box>
                </Box>
              </div>
            </div>
          </Alert>
        </Snackbar>
      </Stack>

      <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setOpen(false)}>
        <Alert className='Success mainAlert' variant='filled' icon={false}>
          <>
            <div className='alertCancel_2'>
              <img
                onClick={() => {
                  setOpen(false);
                }}
                src={DrawerCancelIcon}
                alt='DrawerConfirmIcon'
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' alt='DrawerConfirmIcon' />

              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{deleteSuccessMsg}</div>
            </div>
          </>
        </Alert>
      </Snackbar>

      <Snackbar className='snackbar_bar_postition' open={EmailDeleteAlert} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setEmailDeleteAlert(false)}>
        <Alert className='Success mainAlert' variant='filled' icon={false}>
          <>
            <div className='alertCancel_2'>
              <img
                onClick={() => {
                  setEmailDeleteAlert(false);
                }}
                src={DrawerCancelIcon}
                alt='DrawerCancelIcon'
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' alt='DrawerConfirmIcon' />

              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{deleteEmailSuccessMsg}</div>
            </div>
          </>
        </Alert>
      </Snackbar>

      <br />
      <Box>
        <Typography className='font_weight_bold button_bottom_space'>Customer Phone Numbers</Typography>
        <Box className='edit_phone_email_box'>
          {!showPhoneNumber ? null : (
            <>
              {phoneNumbers.map((item) => {
                return (
                  <>
                    <Box className='edit_phone_email_box_3' key={item.CustID}>
                      {showPhoneNumber === 'NA' ? null : (
                        <Typography sx={{ textTransform: 'capitalize' }}>
                          {item.Type || 'Mobile'} : {phoneFormat(item['#'])}
                        </Typography>
                      )}

                      {showPhoneNumber === 'NA' ? null : (
                        <Box className='editIconCancelIcon'>
                          <Box>
                            <img
                              onClick={() => {
                                navigate('/tbm/ps/editcustomerphonenumber', {
                                  state: {
                                    phoneno: item['#'],
                                    PhoneID: item.PhoneID,
                                    CustId: item.CustID,
                                    Description: item.Type,
                                    addressid: locationState.addressid,
                                  },
                                });
                              }}
                              src={EditMiscIcon}
                              alt='EditMiscIcon'
                              className='misc_icons_dimensions'
                            />{' '}
                          </Box>
                          <Box>
                            <img
                              src={EditCancelIcon}
                              alt='EditCancelIcon'
                              onClick={() => {
                                DeleteWindowConfirmationFun();
                                setStorePhoneId(item.PhoneID);
                              }}
                              className='misc_icons_dimensions'
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </>
                );
              })}
            </>
          )}
          <Box
            onClick={() =>
              navigate('/tbm/ps/addnewphonenumber', {
                state: {
                  phoneno: locationState.phoneno,
                  custId: locationState.custid,
                  addressid: locationState.addressid,
                },
              })
            }
            display='flex'
            alignItems='center'
            justifyContent='center'
            className='all_Button_Style_Proposal_Details_Page button_top_space'
          >
            <span style={{ paddingLeft: '7px' }} className='gray_button_span'>
              <Box
                onClick={() =>
                  navigate('/tbm/ps/AddNewPhoneNumber', {
                    state: {
                      phoneno: locationState.phoneno,
                      custId: locationState.custid,
                      addressid: locationState.addressid,
                    },
                  })
                }
                className='box_outside_icons'
              >
                <img src={AddIcon} className={`secondary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} alt='AddIcon' />
              </Box>
              <span className='gray_button_text_1' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                {' '}
                ADD NEW PHONE
              </span>
            </span>
          </Box>
        </Box>
      </Box>
      <hr className='button_bottom_space button_top_space' />
      <Box>
        <Typography className='font_weight_bold button_bottom_space'>Customer Emails</Typography>
        {StoreEmailAddressLenght === 0 ? (
          <>
            <Box>
              <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page button_top_space'>
                <span style={{ paddingLeft: '23px' }} className='gray_button_span'>
                  <Box className='box_outside_icons'>
                    <img src={AddIcon} className={`secondary_icons_dimension ${isImageHovered2 ? 'hovered' : ''}`} alt='AddIcon' />
                  </Box>
                  <span
                    onClick={() =>
                      navigate('/tbm/ps/AddNewEmail', {
                        state: {
                          custid: locationState.custid,
                          addressid: locationState.addressid,
                          email_id: locationState.email_id,
                        },
                      })
                    }
                    className='gray_button_text_1'
                    onMouseEnter={handleHover2}
                    onMouseLeave={handleHover2}
                  >
                    {' '}
                    ADD NEW EMAIL
                  </span>
                </span>
              </Box>
            </Box>
          </>
        ) : (
          <Box className='edit_phone_email_box'>
            {StoreEmailAddressVal.map((item) => {
              return (
                <Box className='edit_phone_email_box_3'>
                  <Typography>
                    <span style={{ textTransform: 'capitalize' }}>{item.email_type || 'Email'}</span> : {item.email_id || 'NA'}
                  </Typography>
                  <Box className='editIconCancelIcon'>
                    <Box sx={{ width: 'fit-content' }}>
                      {' '}
                      <img
                        onClick={() => {
                          navigate('/tbm/ps/editcustomeremail', {
                            state: {
                              custid: locationState.custid,
                              addressid: locationState.addressid,
                              email_id: item.email_id,
                              email_ids_id: item.customer_email_ids_id,
                              email_Type: item.email_type,
                            },
                          });
                        }}
                        src={EditMiscIcon}
                        className='misc_icons_dimensions'
                        alt='EditMiscIcon'
                      />
                    </Box>
                    <Box>
                      <img
                        onClick={() => {
                          setStoreEmailId(item.customer_email_ids_id);
                          DeleteEmailConfirmationFun();
                        }}
                        src={EditCancelIcon}
                        className='misc_icons_dimensions'
                        alt='EditCancelIcon'
                      />
                    </Box>
                  </Box>
                </Box>
              );
            })}

            <Box
              onClick={() =>
                navigate('/tbm/ps/AddNewEmail', {
                  state: {
                    custid: locationState.custid,
                    addressid: locationState.addressid,
                    email_id: locationState.email_id,
                    email_ids_id: StoreEmailAddressVal[0].customer_email_ids_id,
                  },
                })
              }
              display='flex'
              alignItems='center'
              justifyContent='center'
              className='all_Button_Style_Proposal_Details_Page button_top_space'
            >
              <span style={{ paddingLeft: '7px' }} className='gray_button_span'>
                <img src={AddIcon} className='secondary_icons_dimension' alt='AddIcon' />
                <span className='gray_button_text_1'> ADD NEW EMAIL</span>
              </span>
            </Box>
          </Box>
        )}
      </Box>
      <hr className='button_top_space' />

      <Button
        type='submit'
        onClick={handleCancel}
        className='search_details_button button_bottom_space'
        sx={{
          justifyContent: 'flex-start',
          position: 'relative',
          right: '10px',
          marginTop: '20px',
          float: 'left',
          padding: '0px 16px !important',
        }}
        onMouseEnter={handleHoverFun}
        onMouseLeave={handleMouseLeaveFun}
      >
        {' '}
        <Box className='globalIconMainBox globalDrawerText'>
          {isHovered ? <img className='primary_icons_dimension_1' src={ReturnHovIcon} alt='ReturnHovIcon' /> : <img className='primary_icons_dimension_1' src={ReturnIcon} alt='ReturnIcon' />}
          <Typography className='globalText' sx={{ textAlign: 'start' }} onMouseEnter={handleHover4} onMouseLeave={handleHover4}>
            RETURN TO CUSTOMER DETAILS
          </Typography>
        </Box>
      </Button>

      <span className='gray_button_span' onClick={handleCancel} style={{ paddingBottom: '30px', paddingLeft: '22px' }}>
        <Box className='globalIconMainBox'>
          <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered3 ? 'hovered' : ''}`} alt='CancelIcon' />
          <Typography onMouseEnter={handleHover3} onMouseLeave={handleHover3} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
            CANCEL
          </Typography>
        </Box>
      </span>
    </div>
  );
}
