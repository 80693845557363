/** @format */

import React, { forwardRef, useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Box, Checkbox, Stack, Snackbar, Alert as MuiAlert } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import fracty from 'fracty';

import ShowProposalName from './ShowProposalName';
import LongText from '../LongText';
import DrawerConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import CONFIG from '../../config/config.js';
import SelectIcon from '../../assets/icons/secondary/2nd-confirm.svg';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import AddMultipleLineIcon2 from '../../assets/icons/primary/windowset-edit.svg';
import AddMultipleLineHovIcon from '../../assets/icons/primary/WithHovIcon/windowset-edit-hov.svg';
import InformationAlerts from '../Shared/InfoAlerts.jsx';
import WarningIcon from '../../assets/icons/misc/alert.svg';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function BatchEditProposalDetails() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');

  if (!location.state) {
    navigate('/pagenotfound');
  }

  const vertical = 'top';
  const horizontal = 'center';

  let proposaldetails = {
    customerdata: location.state.customerdata,
    proposal_id: location.state.proposalID,
    proposal_name: location.state.proposalName,
  };

  const [checkedrow, setCheckedRow] = useState([]);
  const [WindowSetData, setWindowSetData] = useState([]);
  const [open, setOpen] = useState(false);
  const [editDisable, setEditDisable] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [selected, setSelected] = useState([]);
  const [showSA, setShowSA] = useState(true);
  const [isImageHovered8, setIsImageHovered8] = useState(false);

  //  hover states
  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => setIsHovered(true);
  const handleMouseLeaveFun = () => setIsHovered(false);

  const outerTheme = createTheme({
    palette: {
      primary: {
        main: '#333333',
      },
    },
  });

  const handleTextClick8 = () => setIsImageHovered8(false);

  const batchEdit = () =>
    navigate(`/tbm/ps/batcheditingwindowcovering`, {
      state: {
        path: 'Batch-Edit',
        proposaldetails: proposaldetails,
        selectedwindows: checkedrow,
      },
    });

  const enableEditButton = () => setEditDisable(!editDisable);

  const handleClose = () => setEditItem(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!location.state) {
      navigate('/pagenotfound');
    } else {
      setWindowSetData(location.state.selectedwindows);
      setSelected(location.state.selectedwindows);
    }
    getVerified();
  }, []);

  const handleSuccessClose = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  useEffect(() => setCheckedRow(WindowSetData.filter((object) => !selected.includes(object))), [WindowSetData, selected]);

  const onSelectAllClick = () => {
    setShowSA(false);
    handleSelectAllClick(true);
  };

  const onDeSelectAllClick = () => {
    setShowSA(true);
    handleSelectAllClick(false);
  };

  const handleSelectAllClick = (event) => {
    if (event === false) {
      const newSelecteds = WindowSetData.map((n) => n);
      setSelected(newSelecteds);
      newSelecteds.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);
        if (idx >= 0) {
          WindowSetData[idx].selected = false;
        }
      });
      return;
    } else {
      const newSelecteds = WindowSetData.map((n) => n);
      newSelecteds.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);
        if (idx >= 0) {
          WindowSetData[idx].selected = true;
        }
      });
      setSelected([]);
    }
  };

  const isSelected = (row) => selected.indexOf(row) !== -1;

  const handleClick = (event, row) => {
    if (event.target.checked !== true) {
      setShowSA(true);
      const selectedIndex = selected.indexOf(row);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      newSelected.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);
        if (idx >= 0) {
          WindowSetData[idx].selected = true;
        }
      });
      for (var i = 0; i < newSelected.length; i++) {
        if (WindowSetData[i].selected === newSelected[i].selected) {
        }
      }

      if (WindowSetData.length === newSelected.length) {
        setShowSA(false);
      }
      setSelected(newSelected);
    } else {
      const selectedIndex = selected.indexOf(row);
      const selectedActualIndex = WindowSetData.indexOf(row);
      WindowSetData[selectedActualIndex].selected = false;

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      if (WindowSetData.length > newSelected.length) {
        setShowSA(true);
      }
      setSelected(newSelected);
    }
  };

  const RemoveWindows = async () => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: location.state.proposalID,
        windows: checkedrow,
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/pm/RemoveProposalWindow`, requestOptions);
    const data = await response.json();
    navigateNextPage();
  };

  const navigateNextPage = () => {
    checkedrow = [];
    navigate(`/tbm/ps/proposal/${params.id}/details`, {
      state: {
        selectedwindows: location.state.selectedwindows,
        customerdata: location.state.customerdata,
        unchecked: [],
        proposalID: location.state.proposalID,
        proposalName: location.state.proposalName,
      },
    });
  };

  const WindowSetHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell className='table-headerps sticky-columnws-2 font_weight_bold'>
          {' '}
          <span style={{ paddingLeft: '32px' }}>#</span> <span style={{ paddingLeft: '14px' }}>Window Name</span>
        </TableCell>
        <TableCell className='table-headerpd_size font_weight_bold'>Size</TableCell>
        <TableCell className='table-headerpd_price font_weight_bold'>Retail</TableCell>
        <TableCell className='table-headerpd_price font_weight_bold'>Install</TableCell>
        <TableCell className='table-headerpd_price font_weight_bold'>Total</TableCell>
        <TableCell className='small_header font_weight_bold'>Bld</TableCell>
        <TableCell className='table-header0 font_weight_bold'>Covering System</TableCell>
        <TableCell className='table-headerpd_price font_weight_bold'>Note</TableCell>
      </TableRow>
    </TableHead>
  );

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => setIsImageHovered(!isImageHovered);

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => setIsImageHovered2(!isImageHovered2);

  return (
    <>
      <ThemeProvider theme={outerTheme}>
        <div align='center'>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setOpen(false)}>
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img onClick={handleSuccessClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />

                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Proposal cannot have an empty windows.</div>
                </div>
              </Alert>
            </Snackbar>
          </Stack>

          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={editItem} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
              <Alert className='Success mainAlert' variant='filled' icon={false}>
                <div className='alertCancel' align='right'>
                  <img onClick={handleClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                </div>

                <div className='alertMainNew'>
                  <div align='left' style={{ paddingTop: 37 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'Center',
                      }}
                    >
                      <Box className='alertCantinueButton'>
                        <img src={DrawerAlertIcon} className='primary_icons_dimension' />
                      </Box>
                    </Box>

                    <div
                      className='alertTextAlign'
                      style={{
                        paddingTop: 20,
                        fontSize: '20px',
                        fontFamily: 'RobotoRegular',
                      }}
                    >
                      Are you sure you want to remove
                    </div>
                  </div>

                  <div
                    align='left'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '20px 10px 0px 24px',
                      justifyContent: 'center',
                      alignItems: 'start',
                      lineHeight: '24px',
                    }}
                  >
                    <Box
                      onClick={() => {
                        handleClose();
                        RemoveWindows();
                      }}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'Center',
                        cursor: 'pointer',
                        marginBottom: '20px',
                      }}
                    >
                      <Box className='alertCantinueButton'>
                        <img src={DrawerConinueIcon} className='primary_icons_dimension' />
                      </Box>
                      <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                        CONTINUE
                      </Typography>
                    </Box>

                    <Box
                      onClick={handleClose}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'Center',
                        cursor: 'pointer',
                        paddingBottom: '16px',
                        paddingLeft: '15px',
                      }}
                    >
                      <Box className='globalIconMainBox'>
                        <Box className='iconSecondaryButtonGlobalForDrawer'>
                          <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                        </Box>
                        <Typography className='globalTextForDrawer'>CANCEL</Typography>
                      </Box>
                    </Box>
                  </div>
                </div>
              </Alert>
            </Snackbar>
          </Stack>
        </div>

        <ShowProposalName proposaldetails={proposaldetails} />

        <Box
          className='button_top_space'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '30px',
          }}
        >
          <Stack direction='row'>
            <Box>
              <Stack direction='row'>
                <img className='primary_icons_dimension_1 stepperImageMargins' src={AddMultipleLineIcon2} />
              </Stack>
            </Box>
            <Box
              sx={{
                borderLeft: '1px solid #D6D6D6',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className=''
            >
              <Typography className='stepperMainTextSize stepperSubTextStyle'>Select Windows To Edit Coverings</Typography>
            </Box>
          </Stack>
        </Box>

        <hr />

        <Box className='proposal_table_main_box'>
          <Box className='proposal_table_sub_box'>
            {/* table start  */}

            <span style={{ marginLeft: '16px' }} className='gray_button_span button_top_space'>
              <Box onClick={showSA ? onSelectAllClick : onDeSelectAllClick} className='globalIconMainBox'>
                <img src={showSA ? SelectIcon : CancelIcon} className={`secondary_icons_dimension ${isImageHovered8 ? 'hovered' : ''}`} alt='' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onClick={handleTextClick8}>
                  {showSA ? 'SELECT ALL' : 'DESELECT ALL'}
                </Typography>
              </Box>
            </span>

            <Box className='table-box'>
              <TableContainer sx={{ borderBottom: '1px solid #D6D6D6', textAlign: 'left' }} className='table_padding_bottom'>
                <Table className='proposal_table' aria-labelledby='tableTitle' size='small'>
                  <WindowSetHeader numSelected={selected.length} onSelectAllClick={handleSelectAllClick} rowCount={WindowSetData.length} />
                  <TableBody>
                    {WindowSetData.map((row) => (
                      <TableRow hover role='checkbox' onClick={(event) => handleClick(event, row)} tabIndex={-1} key={row.window_id} style={{ padding: 0 }}>
                        {row.covering_status === 'not_applied' ? (
                          <>
                            <TableCell
                              sx={{
                                color: 'red',
                                fontSize: '100%',
                                borderBottom: 'none',
                                display: 'flex',
                                width: '100%',
                              }}
                              className='table-content sticky-column'
                              align='left'
                            >
                              <Box sx={{ display: 'flex' }}>
                                <Checkbox
                                  sx={{
                                    zoom: 0.8,
                                    paddingTop: '1px',
                                  }}
                                  checked={!isSelected(row)}
                                  onClick={enableEditButton}
                                />
                                <Box sx={{ width: '20px', color: 'black' }}>{row.covering_status !== 'not_consider' ? WindowSetData.indexOf(row) + 1 : null}</Box>
                                &nbsp;&nbsp;
                                <span className='AddingsalesOrderWindowName'>
                                  <LongText>{row.window_name}</LongText>
                                </span>
                              </Box>
                            </TableCell>
                            <TableCell sx={{ color: 'red', whiteSpace: 'nowrap' }} className='table-content' align='left'>
                              <>
                                {fracty(row.proposal_window_width)}" X {fracty(row.proposal_window_height)}"
                              </>
                            </TableCell>
                            <TableCell sx={{ color: 'red' }} className='small_cell' align='left'>
                              ─ ─
                            </TableCell>
                            <TableCell sx={{ color: 'red' }} className='small_cell' align='left'>
                              <>─ ─</>
                            </TableCell>{' '}
                            <TableCell sx={{ color: 'red' }} className='small_cell' align='left'>
                              <>─ ─</>
                            </TableCell>{' '}
                            <TableCell sx={{ color: 'red' }} className='small_cell' align='left'>
                              <>─ ─</>
                            </TableCell>{' '}
                            <TableCell sx={{ color: 'red' }} className='small_cell' align='left'>
                              <>─ ─</>
                            </TableCell>{' '}
                            <TableCell sx={{ color: 'red' }} className='small_cell' align='left'>
                              <>─ ─</>
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell sx={{ color: '#008593' }} className='table-content sticky-column' align='left'>
                              <Box sx={{ display: 'flex' }}>
                                <Checkbox
                                  sx={{
                                    zoom: 0.8,
                                    paddingTop: '1px',
                                  }}
                                  checked={!isSelected(row)}
                                  onClick={enableEditButton}
                                />
                                <Box sx={{ width: '20px', color: 'black' }}>{row.covering_status !== 'not_consider' ? <>{WindowSetData.indexOf(row) + 1}</> : ''}</Box>
                                &nbsp;&nbsp;
                                <span className='AddingsalesOrderWindowName'>
                                  <LongText>{row.window_name}</LongText>
                                </span>
                              </Box>
                            </TableCell>
                            <TableCell className='table-content' align='left'>
                              <>
                                {fracty(row.proposal_window_width)}" X {fracty(row.proposal_window_height)}"
                                <span className={row.proposal_measurement_type.replace(/ /g, '')}>
                                  {row.proposal_measurement_type === 'Quick'
                                    ? '(' + 'QK' + ')'
                                    : row.proposal_measurement_type === 'Shutters'
                                    ? '(' + 'SH' + ')'
                                    : row.proposal_measurement_type === 'Blinds'
                                    ? '(' + 'BL' + ')'
                                    : '(' +
                                      row.proposal_measurement_type
                                        .split(' ')
                                        .map((word) => word[0])
                                        .join('') +
                                      ')'}
                                </span>{' '}
                              </>
                            </TableCell>
                            <TableCell className='table-content' align='left'>
                              <>${row.suggested_price}</>
                            </TableCell>
                            <TableCell className='table-content-after-material' align='left'>
                              <>${row.covering_install_price}</>
                            </TableCell>
                            <TableCell className='table-content-after-material' align='left'>
                              <>${row.proposal_total_with_install}</>
                            </TableCell>
                            <TableCell className='small_cell' align='left'>
                              <>&nbsp;&nbsp;{row.build_type[0]}</>
                            </TableCell>
                            <TableCell className='table-content-second2' align='left'>
                              <>
                                {row.material_code.toString().replace(/,/g, ', ')}, {row.build_type}, {row.existing_covering}, {row.mount_surface}, {row.ladder}
                              </>
                            </TableCell>
                            <TableCell className='table-content-after-material small_cell' align='left'>
                              &nbsp;&nbsp; {row.notes_count < 1 ? '' : row.notes_count}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>

        {checkedrow.length <= 1 ? <InformationAlerts startIcon={WarningIcon} alerts={[{ key: 'selected-window-count', text: 'Must have more than 1 window selected', active: checkedrow.length <= 1 }]} /> : null}
        <Box display='flex' alignItems='center' justifyContent='center'>
          <span className='button_span button_top_space button_bottom_space' onMouseEnter={checkedrow.length > 1 ? handleHoverFun : null} onMouseLeave={checkedrow.length > 1 ? handleMouseLeaveFun : null}>
            <Box onClick={checkedrow.length > 1 ? batchEdit : null} className={`globalIconMainBox globalMarginLeftAddWindow ${!checkedrow.length > 1 && 'disabled'}`}>
              <img className={checkedrow.length > 1 ? 'primary_icons_dimension_1' : 'primary_icons_dimension_disable'} src={checkedrow.length > 1 && isHovered ? AddMultipleLineHovIcon : AddMultipleLineIcon2} />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className={checkedrow.length > 1 ? 'globalText' : 'globalTextDisabled'} onMouseEnter={checkedrow.length > 1 ? handleHover : null} onMouseLeave={checkedrow.length > 1 ? handleHover : null}>
                EDIT MULTIPLE WINDOWS
              </Typography>
            </Box>
          </span>
        </Box>

        <hr />

        <Box display='flex' alignItems='center' justifyContent='center' className='apply_Changes_Box' onClick={() => navigate(-1)} onMouseEnter={handleHover2} onMouseLeave={handleHover2}>
          <span style={{ marginLeft: '19px', paddingBottom: '30px' }} className='gray_button_span button_top_space'>
            <Box className='globalIconMainBox'>
              <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered2 ? 'hovered' : ''}`} alt='cancel' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                CANCEL
              </Typography>
            </Box>
          </span>
        </Box>
      </ThemeProvider>
    </>
  );
}
