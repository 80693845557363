/** @format */

import React from 'react';
import { Page, View, Document, StyleSheet } from '@react-pdf/renderer';

import Header from './Header';
import PdfSubHeader from './PdfSubHeader';
import PdfBody from './PdfBody';

const styles = StyleSheet.create({
  page: {
    padding: 10,
    flexDirection: 'col',
  },
  section_col: {
    paddingTop: 10,
    flexDirection: 'row',
  },
  section_col_key: {
    paddingTop: 10,
    flexDirection: 'col',
  },
  section_col_val: {
    paddingTop: 3.5,
    flexDirection: 'col',
  },
  section_row: {
    flexDirection: 'row',
    paddingTop: 15,
  },
  section_col_body: {
    flexDirection: 'col',
  },
  headingText: {
    paddingLeft: 5,
    fontFamily: 'RobotoLight',
    fontSize: '8px',
    lineHeight: '2.5px',
    color: '#000',
    textAlign: 'justify',
  },
  rowText: {
    paddingTop: 5,
    paddingLeft: 40,
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: '1.5px',
    color: '#000',
    textAlign: 'justify',
  },
  box: {
    flexDirection: 'col',
    backgroundColor: '#EDECEB',
    padding: 5,
    marginLeft: 120,
    marginTop: -12,
    width: '100%',
  },
  heading: {
    width: '60%',
    padding: 20,
    fontSize: '22px',
    color: '#007383',
    fontFamily: 'RobotoLight',
    lineHeight: 1,
  },
  p: {
    width: '95%',
    marginTop: -5,
    paddingLeft: 20,
    paddingRight: 10,
    fontFamily: 'RobotoLight',
    fontSize: '11px',
    lineHeight: '1.8',
    color: '#2D3030',
    textAlign: 'justify',
  },
  horizontalborder: {
    width: '100%',
    height: '4px',
    flexDirection: 'row',
    backgroundColor: '#686A65',
  },
  horizontalblackborder: {
    width: '100%',
    height: '12px',
    flexDirection: 'row',
    backgroundColor: '#2D3030',
  },
});

const Pdf = ({ date, data: { customerDetails, proposalDetails, proposalWindows, charges } }) => {
  const name = customerDetails.first_name === 'NA' ? customerDetails.last_name : `${customerDetails.first_name} ${customerDetails.wife === 'NA' ? '' : `& ${customerDetails.wife}`} ${customerDetails.last_name}`;
  return (
    <Document>
      <Page size='A4' dpi='75' orientation='portrait' style={styles.page}>
        <Header name={name} />
        <PdfSubHeader date={date} name={name} customer={customerDetails} sales_rep={proposalDetails.sales_rep} proposal_no={proposalDetails.proposal_display_id} />
        <View style={styles.horizontalborder}>&nbsp;</View>
        <View style={styles.horizontalblackborder}>&nbsp;</View>
        <PdfBody data={proposalWindows} proposaldetails={proposalDetails} />
      </Page>
    </Document>
  );
};
export default Pdf;
