import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import { Stack, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/system";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CheckIcon from "@mui/icons-material/Check";
import { useLocation, useNavigate } from "react-router-dom";
import ConvertToSalesIcon from "../../img/items.png";

export default function SalesOrderConfirm() {
  const location = useLocation();
  const navigate = useNavigate();
  const [accordianopen, setAccordianOpen] = useState(
    new Array(location.state.ProposalDetails.length).fill(false)
  );
  const [savedremeasure, setsavedRemeasure] = useState(
    location.state.remeasure
  );
  const [selected, setSelected] = React.useState([]);
  const [showSA, setShowSA] = React.useState(true);
  const [checkedrowdata, setCheckedRowData] = useState([]);
  const [WindowSetData, setWindowSetData] = useState(
    location.state.ProposalDetails
  );
  const [windowDetails, setWindowDetails] = useState([]);
  const [selectedSalesOrderWindowDetails, setSelectedSalesOrderWindowDetails] =
    useState(location.state.SelectedWindowDetails);

  const accordianOpenCloseFun = (index, status) => {
    const updateAccordianState = accordianopen.map((item, position) =>
      position === index ? !item : item
    );
    setAccordianOpen(updateAccordianState);
    
  };

  let isItemSelected;

  const handleClick = (event, row) => {
    if (event.target.checked === true) {
      setShowSA(true);
      const selectedIndex = selected.indexOf(row);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      newSelected.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);
        if (idx >= 0) {
          WindowSetData[idx].selected = true;
        }
      });
      
      for (var i = 0; i < newSelected.length; i++) {
        if (WindowSetData[i].selected === newSelected[i].selected) {

        }
      }

      setWindowDetails(newSelected);
      if (WindowSetData.length === newSelected.length) {
        setShowSA(false);
      }
      setCheckedRowData(newSelected);
      setSelected(newSelected);
    } else {
      const selectedIndex = selected.indexOf(row);
      const selectedActualIndex = WindowSetData.indexOf(row);
      WindowSetData[selectedActualIndex].selected = false;

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      if (WindowSetData.length > newSelected.length) {
        setShowSA(true);
      }
      setCheckedRowData(newSelected);
      setSelected(newSelected);
    }
  };

  return (
    <div align="center">
      <div className="heading-box">
        <h2 className="clickable_header all_pages_h2_header">
          {location.state.SalesOrderName}
        </h2>

        <h3
          className="clickable_header all_pages_h2_header"
          style={{ textTransform: "capitalize" }}
        >
          {location.state.CustomerDetails.customerdata.last_name} |{" "}
          {location.state.CustomerDetails.customerdata.city}
        </h3>
      </div>
      <hr />
      <Stack direction="column" sx={{ display: "flex" }}>
        {selectedSalesOrderWindowDetails.map((ele, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;
          return (
            <>
              {ele.sales_order_status === true ? (
                <>
                  <Box
                    id={index}
                    sx={{ display: "flex", width: "100%", marginTop: "5px" }}
                  >
                    <Box
                      onClick={(event) => handleClick(event, ele)}
                      aria-checked={isItemSelected}
                      sx={{ width: "90%", display: "flex" }}
                    >
                      <Typography
                        sx={{
                          width: "80%",
                          marginTop: "0px",
                          textAlign: "start",
                          marginLeft: "10px",
                        }}
                      >
                        Window Name:{" "}
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {ele.window_name}
                        </span>
                      </Typography>
                    </Box>

                    {accordianopen[index] ? (
                      <ArrowDropDownIcon
                        onClick={() => {
                          accordianOpenCloseFun(index);
                        }}
                        sx={{ width: "10%", marginTop: "0px" }}
                      />
                    ) : (
                      <ArrowDropUpIcon
                        onClick={() => {
                          accordianOpenCloseFun(index);
                        }}
                        sx={{ width: "10%", marginTop: "0px" }}
                      />
                    )}
                  </Box>
                  {!accordianopen[index] ? (
                    <Box>
                      <Typography
                        sx={{
                          paddingTop: "-10px",
                          textAlign: "start",
                          display: "flex",
                        }}
                      >
                        <Box sx={{ paddingLeft: "5px" }}>
                          <CheckIcon
                            sx={{ color: "#019A65", fontWeight: "bold" }}
                          />
                        </Box>
                        <Box sx={{ paddingLeft: "10px" }}>Confirmed</Box>
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ textAlign: "start", paddingLeft: "30px" }}>
                      <Box>
                        <Typography className="convert_sales_order_window_list">
                          Measurement Type :{" "}
                          <span className="font_weight_bold">
                            {ele.measurement_type}
                          </span>
                        </Typography>
                        <Typography className="convert_sales_order_window_list">
                          Width X Heigth :{" "}
                          <span className="font_weight_bold">
                            {ele.proposal_window_width} X{" "}
                            {ele.proposal_window_height}{" "}
                          </span>
                        </Typography>
                        <Typography className="convert_sales_order_window_list">
                          Remeasure :{" "}
                          <span className="font_weight_bold">
                            {savedremeasure}
                          </span>
                        </Typography>
                        <Typography className="convert_sales_order_window_list">
                          Existing Coverings :{" "}
                          <span className="font_weight_bold">
                            {ele.existing_covering}
                          </span>
                        </Typography>
                        <Typography className="convert_sales_order_window_list">
                          Build Type :{" "}
                          <span className="font_weight_bold">
                            {ele.build_type}
                          </span>
                        </Typography>
                        <Typography className="convert_sales_order_window_list">
                          Mount Surface :{" "}
                          <span className="font_weight_bold">
                            {ele.mount_surface}
                          </span>
                        </Typography>
                        <Typography className="convert_sales_order_window_list">
                          Ladder :{" "}
                          <span className="font_weight_bold">{ele.ladder}</span>
                        </Typography>
                        <Typography className="convert_sales_order_window_list">
                          Window Configuration :{" "}
                          <span className="font_weight_bold">
                            {ele.measurement_type_specific_factor.HeadrailType}
                          </span>
                        </Typography>
                        <Typography className="convert_sales_order_window_list">
                          Obstructions :{" "}
                          <span className="font_weight_bold">None</span>
                        </Typography>
                        <Typography className="convert_sales_order_window_list">
                          Valance Style :{" "}
                          <span className="font_weight_bold">None</span>
                        </Typography>
                        <Typography className="convert_sales_order_window_list">
                          Covering :{" "}
                          <span className="font_weight_bold">
                            {ele.material_code}
                          </span>
                        </Typography>
                      </Box>
                      <Box></Box>
                    </Box>
                  )}
                </>
              ) : (
                ""
              )}
            </>
          );
        })}
      </Stack>
      <hr />
      <span
        className="button_span button_top_space button_bottom_space"
        onClick={() => {
          navigate("/tbm/ps/finalizesalesorder", {
            state: {
              SalesOrderWindows: selectedSalesOrderWindowDetails,
              SalesOrderName: location.state.SalesOrderName,
              CustomerDetails: location.state.CustomerDetails,
              salesOrderId: location.state.salesOrderId,
            },
          });
        }}
      >
        <Fab className="fab_button" aria-label="back">
          <img
            src={ConvertToSalesIcon}
            style={{
              width: "35px",
              height: "30px",
              position: "absolute",
            }}
            alt="Logo"
          />
          <Avatar color="primary" className="badgecontent">
            <AddIcon />
          </Avatar> 
        </Fab>
        <span className="button_text">CONFIRM & ADD TO S.O.</span>
      </span>
    </div>
  );
}
