import { useNavigate } from 'react-router-dom';
import { Button, Container, Stack, Typography } from '@mui/material';
import Cookies from 'universal-cookie';

import Drawer from './Drawer';
import Header from './Header';
import SettingsIcon from '../../../assets/icons/primary/settings.svg';
import DBIcon from '../../../assets/icons/primary/DB_Tools.svg';
import LogoutIcon from '../../../assets/icons/drawer-alert/drw-logout.svg';
import config from '../../../config/config.js';

export default function UserDrawer({ open, close }) {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const USER_NAME = sessionStorage.getItem('user');
  const logout = () => {
    fetch(`${config.API_URL}/am/Logout`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((data) => data.ok && data.json())
      .then(() => {
        cookies.remove('token'); // Remove token from cookies
        cookies.remove('access_token'); // Remove access_token from cookies
        cookies.remove('refresh_token'); // Remove refresh_token from cookies
        cookies.remove('connect.sid'); // Remove connect.sid from cookies
        sessionStorage.clear();
        navigate('/login'); // Navigate to Login page
      });
  };

  const goToSettings = () => {
    navigate('/tbm/ps/settings');
    close();
  };

  const goToDbTools = () => {
    navigate('/tbm/ps/dbtools');
    close();
  };

  return (
    <Drawer open={open} onClose={close}>
      <Header closeDrawer={close} />
      <Container>
        <Stack sx={{ color: 'white' }} direction='column' spacing={1}>
          <p>
            <b>{config.appCodename}</b>
          </p>
          <p>App Version : {config.appVersion}</p>
          <p>Build # : {config.buildNumber}</p>
        </Stack>
        <Stack sx={{ color: 'white' }} mt={1} direction='column' spacing={1}>
          <Button sx={{ justifyContent: 'left', color: 'white' }} onClick={goToSettings} startIcon={<img className='primary_icons_dimension_1' alt='Settings' src={SettingsIcon} />}>
            <Typography sx={{ fontFamily: 'Roboto Light', fontSize: '1.125rem', marginLeft: '.75rem' }}>Settings</Typography>
          </Button>
          {USER_NAME === 'Tiffani Howell' || USER_NAME === 'Joshua Dix' || USER_NAME === 'Brian Wolsey' ? (
            <Button sx={{ justifyContent: 'left', color: 'white' }} onClick={goToDbTools} startIcon={<img className='primary_icons_dimension_1' alt='Database Tools' src={DBIcon} />}>
              <Typography sx={{ fontFamily: 'RobotoLight', fontSize: '1.125rem', marginLeft: '.75rem' }}>DB Tools</Typography>
            </Button>
          ) : null}
          <Button sx={{ justifyContent: 'left', color: 'white' }} onClick={logout} startIcon={<img className='primary_icons_dimension' src={LogoutIcon} alt='Sign out' />}>
            <Typography sx={{ fontFamily: 'RobotoLight', fontSize: '1.125rem', marginLeft: '.75rem' }}>Sign Out</Typography>
          </Button>
        </Stack>
      </Container>
    </Drawer>
  );
}
