import React from "react";
import { StyleSheet } from "@react-pdf/renderer";

import proposalComplete from "../../assets/icons/primary/proposal-complete.svg";

const styles = StyleSheet.create({
  main_view: {
    marginLeft: "100px",
    marginRight: "100px",
  },
  first_para: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "45px",
    flexDirection: "column",
    padding: "2rem 0 0"
  },
  first_text: {
    margin: "0",
    fontSize: "30px",
  },
  para_normal: {
    fontSize: "18px",
    marginTop: "5px",
  },
  para_bold: {
    fontWeight: 600,
    fontSize: "18px",
    marginTop: "5px",
  },
  img: {
    width: "35px",
    height: "30px",
    position: "absolute",
  },
  second_para: {
    marginTop: "50px",
    width: "93%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingLeft: "30px",
    paddingRight: "30px",
  },
});

export default ({ sales_rep }) => (
  <>
    <div style={styles.first_para}>
      <p style={styles.first_text}>THANK YOU FOR</p>
      <p style={styles.first_text}>ACCEPTING OUR PROPOSAL!</p>
    </div>
    <div style={styles.main_view}>
      <div style={styles.second_para}>
        <img src={proposalComplete} className="mail_primary_icons_dimensions" alt="Proposal Completed" />
      </div>
      <div style={styles.second_para}>
        <p style={styles.para_bold}>
          Your sales representative will be notified that you've accepted this
          proposal and will process with the sales order and payment request.
        </p>

        <p style={styles.para_normal}>
          If you have any questions please contact your sales representative{" "}
          <b>{sales_rep}</b> or The Blindman office at: 801 692-2490
        </p>
      </div>
    </div>
  </>
);
