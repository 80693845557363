/** @format */

import React, { useEffect, useState } from 'react';
import font from '../../../font/Roboto-Light.woff';

import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import Window from './Window';

Font.register({
  family: 'RobotoLight',
  src: font,
});

const styles = StyleSheet.create({
  section_col: {
    paddingTop: 5,
    flexDirection: 'col',
  },
  section_col_service: {
    paddingTop: 0,
    flexDirection: 'col',
    textAlign: 'right',
  },
  section_middlecol_service: {
    paddingTop: 0,
    paddingLeft: 5,
    paddingRight: 5,
    flexDirection: 'col',
  },
  section_val: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  section_col_key: {
    paddingTop: 5,
    paddingLeft: 10,
    flexDirection: 'col',
  },
  section_col_key_services: {
    width: 750,
    paddingTop: 5,
    flexDirection: 'col',
  },
  section_col_key_proposal: {
    paddingTop: 5,
    paddingRight: 10,
    paddingLeft: 30,
    flexDirection: 'col',
  },
  section_col_key_services_price: {
    width: 150,
    paddingTop: 5,
    paddingLeft: 420,
    flexDirection: 'col',
  },
  table_section_col_key: {
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: 'col',
  },
  table_section_col1_key: {
    paddingTop: 5,
    paddingLeft: 5,
    flexDirection: 'col',
    flexGrow: 1,
  },
  table_section_col2_key: {
    paddingTop: 5,
    paddingLeft: 5,
    flexDirection: 'col',
    flexGrow: 1,
  },
  table_section_col3_key: {
    width: 90,
    paddingTop: 5,
    paddingLeft: 5,
    flexDirection: 'col',
  },
  section_col_val: {
    paddingTop: 3.5,

    flexDirection: 'col',
  },
  section_col_val_1: {
    flexDirection: 'col',
  },
  section_row: {
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
  },
  section_table_row: {
    flexDirection: 'row',
  },
  section_table_row_service: {
    display: 'flex',
    flexDirection: 'row',
  },
  tablerowText: {
    marginTop: -2,
    paddingLeft: 2,
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: '1.5px',
    color: '#000',
    textAlign: 'justify',
  },
  tablerowTextMaterial: {
    marginTop: -2,
    paddingLeft: 5,
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: '1.5px',
    color: '#000',
    textAlign: 'justify',
  },
  tablerowTextMaterial_1: {
    marginTop: -2,
    paddingLeft: 0,
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: '1.5px',
    color: '#000',
    textAlign: 'justify',
  },
  section_col_body: {
    flexDirection: 'col',
  },
  section_space: {
    lineHeight: '1.5px',
    paddingTop: 5,
  },
  headingText: {
    fontFamily: 'RobotoLight',
    fontSize: '8px',
    lineHeight: '2.5px',
    color: '#000',
    textAlign: 'justify',
  },
  headingText_1: {
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: '2.5px',
    color: '#000',
    textAlign: 'justify',
    fontWeight: 'bold',
  },
  headingTextProposalkeys: {
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: 1.5,
    color: '#000',
    textAlign: 'justify',
  },
  subheadingTextProposalkeys: {
    width: '60%',
    paddingLeft: 10,
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: 1.5,
    color: '#000',
    textAlign: 'justify',
  },
  headingTextProposalval: {
    display: 'flex',
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: 1.5,
    color: '#000',
    textAlign: 'right',
  },
  headingTextProposalvalue: {
    display: 'flex',
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: 1.5,
    color: '#000',
    textAlign: 'right',
  },
  subheadingTextProposalval: {
    display: 'flex',

    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: 1.5,
    color: '#000',
  },
  headingTextProposalfinalvalT: {
    fontWeight: 900,
    fontSize: '10px',
    lineHeight: '1.5',
    color: '#000',
    textAlign: 'right',
  },
  headingTextProposalfinalvalB: {
    paddingTop: 5,
    fontWeight: 900,
    fontSize: '10px',
    lineHeight: 1.5,
    color: '#000',
    textAlign: 'justify',
  },
  headingTextProposalT: {
    fontSize: '10px',
    fontWeight: 900,
    paddingBottom: 5,
    color: '#000',
    textAlign: 'justify',
  },
  headingTextProposalT1: {
    fontSize: '8px',
    fontWeight: 400,
    paddingBottom: 5,
    color: '#000',
    textAlign: 'justify',
  },
  headingTextProposalB: {
    fontSize: '10px',
    fontWeight: 'bold',
    paddingTop: 5,
    color: '#000',
    textAlign: 'justify',
  },
  rowText: {
    paddingTop: 5,
    paddingLeft: 40,
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: '1.5px',
    color: '#000',
    textAlign: 'justify',
  },
  rowTextBr: {
    paddingTop: 5,
    paddingLeft: 40,
    fontFamily: 'RobotoLight',
    fontSize: '10px',
    lineHeight: '1.5px',
    color: '#000',
    textAlign: 'justify',
  },
  horizontalthinborder: {
    width: '100%',
    height: '1px',
    flexDirection: 'row',
    backgroundColor: '#D6D6D6',
  },
  horizontalborder: {
    width: '100%',
    height: '2px',
    flexDirection: 'row',
    backgroundColor: '#7E7E7E',
    paddingTop: '2px',
  },
  horizontalblackborder: {
    paddingTop: '5px',
    width: '100%',
    height: '4px',
    flexDirection: 'row',
    backgroundColor: '#2D3030',
  },
  h2: {
    display: 'block',
    fontSize: 30,
    marginTop: 1,
    marginBottom: 1,
    marginLeft: 0,
    marginRight: 0,
    padding: 10,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    flexGrow: 1,
  },
  left: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 495,
  },
  right: {
    flexShrink: 1,
    flexGrow: 2,
  },
  padding_notes: {
    marginBottom: '5px',
    marginTop: '5px',
  },
  verticalLine: {
    borderLeft: '1px solid #d6d6d6',
    marginLeft: 0,
  },
  verticalLine_1: {
    borderLeft: '1px solid #d6d6d6',
    marginLeft: 10,
  },
});

function CTSOTabledata({ data, notes }) {
  const { customerNotes, salesOrderNotes, salesOrderDetailsNotes, proposalNotes, windowSetNotes, proposalDetailsNotes, windowNotes } = notes;
  const { salesOrder, windows } = data;
  const [Accessoriescharge, setAccessoriesCharge] = useState([]);
  const [Servicescharge, setServicesCharge] = useState([]);
  const [AdditionalCharge, setAdditionalCharge] = useState([]);
  useEffect(() => {
    const additionalCharge = data.accessoryServiceCharges;

    setAccessoriesCharge(additionalCharge.filter((charge) => charge.charge_type === 'X'));
    setServicesCharge(additionalCharge.filter((charge) => charge.charge_type === 'S'));
    setAdditionalCharge(additionalCharge.filter((charge) => charge.charge_type === 'AD'));
  }, []);

  return (
    <View style={styles.section_col_body}>
      {/*   */}
      {windows.map((window, index) => (
        <>
          <View key={index}>
            <Window row={index + 1} window={window} />
            <View style={styles.horizontalthinborder} />
          </View>
          <View style={styles.section_space} />
        </>
      ))}

      {customerNotes.length ? (
        <>
          <View>
            <Text style={styles.headingTextProposalT}>Customer Notes</Text>
            <View>
              {customerNotes.map((note, index) =>
                note.visible ? (
                  <Text style={styles.headingTextProposalT1}>
                    Note #{index + 1} : {note.content}
                  </Text>
                ) : null
              )}
            </View>
          </View>
          <View style={styles.padding_notes} />
        </>
      ) : null}

      {salesOrderNotes.length ? (
        <>
          <View>
            <Text style={styles.headingTextProposalT}>Sales Order Notes</Text>
            <View>
              {salesOrderNotes.map((note, index) =>
                note.visible ? (
                  <Text style={styles.headingTextProposalT1}>
                    Note #{index + 1} : {note.content}
                  </Text>
                ) : null
              )}
            </View>
          </View>
          <View style={styles.padding_notes} />
        </>
      ) : null}

      {salesOrderDetailsNotes.length ? (
        <>
          <View>
            <Text style={styles.headingTextProposalT}>Sales Order Details Notes</Text>
            <View>
              {salesOrderDetailsNotes.map((note, index) =>
                note.visible ? (
                  <Text style={styles.headingTextProposalT1}>
                    Note #{index + 1} : {note.content}
                  </Text>
                ) : null
              )}
            </View>
          </View>
          <View style={styles.padding_notes} />
        </>
      ) : null}

      {proposalNotes.length ? (
        <>
          <View>
            <Text style={styles.headingTextProposalT}>Proposal Notes</Text>
            <View>
              {proposalNotes.map((note, index) =>
                note.visible ? (
                  <Text style={styles.headingTextProposalT1}>
                    Note #{index + 1} : {note.content}
                  </Text>
                ) : null
              )}
            </View>
          </View>
          <View style={styles.padding_notes} />
        </>
      ) : null}

      {proposalDetailsNotes.length ? (
        <>
          <View>
            <Text style={styles.headingTextProposalT}>Proposal Detail Notes</Text>
            <View>
              {proposalDetailsNotes.map((note, index) =>
                note.visible ? (
                  <Text style={styles.headingTextProposalT1}>
                    Note #{index + 1} : {note.content}
                  </Text>
                ) : null
              )}
            </View>
          </View>
          <View style={styles.padding_notes} />
        </>
      ) : null}

      {windowSetNotes.length ? (
        <>
          <View>
            <Text style={styles.headingTextProposalT}>Window Set Notes</Text>
            <View>
              {windowSetNotes.map((note, index) =>
                note.visible ? (
                  <Text style={styles.headingTextProposalT1}>
                    Note #{index + 1} : {note.content}
                  </Text>
                ) : null
              )}
            </View>
          </View>
          <View style={styles.padding_notes} />
        </>
      ) : null}

      {windowNotes.length ? (
        <View>
          <Text style={styles.headingTextProposalT}>Window Notes</Text>
          <View>
            {windowNotes.map((note, index) =>
              note.visible ? (
                <Text style={styles.headingTextProposalT1}>
                  Note #{index + 1} : {note.content}
                </Text>
              ) : null
            )}
          </View>
        </View>
      ) : null}

      <View style={styles.section_row}>
        <View style={styles.section_col_key_proposal}>
          <View style={styles.section_table_row_service}>
            <View style={styles.left}>
              <View style={styles.section_col_service}>
                <Text style={styles.headingTextProposalT}>COVERINGS SUBTOTAL :</Text>
              </View>
            </View>
            <View style={styles.right}>
              <View style={styles.section_val}>
                <Text style={styles.headingTextProposalfinalvalT}>${parseFloat(salesOrder.retail_total_with_install).toFixed(2)}</Text>
              </View>
            </View>
          </View>
          <View style={styles.section_table_row_service}>
            <View style={styles.left}>
              <View style={styles.section_col_service}>
                <Text style={styles.headingTextProposalkeys}>Installation :</Text>
              </View>
            </View>
            <View style={styles.right}>
              <View style={styles.section_val}>
                <Text style={styles.headingTextProposalvalue}>${(parseFloat(salesOrder.sales_order_install_total) + parseFloat(salesOrder.additional_install_service_charge)).toFixed(2)}</Text>
              </View>
            </View>
          </View>

          {Accessoriescharge.length > 0 ? (
            <>
              <View style={styles.section_table_row_service}>
                <View style={styles.left}>
                  <View style={styles.section_col_service}>
                    <Text style={styles.headingTextProposalkeys}>Accessories :</Text>
                  </View>
                </View>
                <View style={styles.right}>
                  <View style={styles.section_val}>
                    <Text style={styles.headingTextProposalfinalvalT}>&nbsp;</Text>
                  </View>
                </View>
              </View>
              <View style={styles.section_table_row_service}>
                <View style={styles.left}>
                  <View style={styles.section_col_service}>
                    <View>
                      {Accessoriescharge.map((accessory, index) => (
                        <Text key={index} style={styles.subheadingTextProposalkeys}>
                          {accessory.quantity > 1 ? `${accessory.charge_description} X ${accessory.quantity}` : accessory.charge_description} :
                        </Text>
                      ))}
                    </View>
                  </View>
                </View>
                <View style={styles.right}>
                  <View style={styles.section_val}>
                    <View style={{ dispaly: 'flex', alignItems: 'flex-end' }}>
                      {Accessoriescharge.map((accessory, index) => (
                        <Text key={index} style={styles.subheadingTextProposalval}>
                          {' '}
                          ${parseFloat(accessory.charge).toFixed(2)}
                        </Text>
                      ))}
                    </View>
                  </View>
                </View>
              </View>
            </>
          ) : null}
          {Servicescharge.length > 0 ? (
            <>
              <View style={styles.section_table_row_service}>
                <View style={styles.left}>
                  <View style={styles.section_col_service}>
                    <Text style={styles.headingTextProposalkeys}>Services :</Text>
                  </View>
                </View>
                <View style={styles.right}>
                  <View style={styles.section_val}>
                    <Text style={styles.headingTextProposalfinalvalT}>&nbsp;</Text>
                  </View>
                </View>
              </View>
              <View style={styles.section_table_row_service}>
                <View style={styles.left}>
                  <View style={styles.section_col_service}>
                    <View>
                      {Servicescharge.map((service, index) => (
                        <Text key={index} style={styles.subheadingTextProposalkeys}>
                          {service.quantity > 1 ? `${service.charge_description} X ${service.quantity}` : service.charge_description} :
                        </Text>
                      ))}
                    </View>
                  </View>
                </View>
                <View style={styles.right}>
                  <View style={styles.section_val}>
                    <View style={{ dispaly: 'flex', alignItems: 'flex-end' }}>
                      {Servicescharge.map((accessory, index) => (
                        <Text key={index} style={styles.subheadingTextProposalval}>
                          {' '}
                          ${parseFloat(accessory.charge).toFixed(2)}
                        </Text>
                      ))}
                    </View>
                  </View>
                </View>
              </View>
            </>
          ) : null}
          {AdditionalCharge.length > 0 ? (
            <>
              <View style={styles.section_table_row_service}>
                <View style={styles.left}>
                  <View style={styles.section_col_service}>
                    <Text style={styles.headingTextProposalkeys}>Additional Charge :</Text>
                  </View>
                </View>
                <View style={styles.right}>
                  <View style={styles.section_val}>
                    <Text style={styles.headingTextProposalfinalvalT}>&nbsp;</Text>
                  </View>
                </View>
              </View>
              <View style={styles.section_table_row_service}>
                <View style={styles.left}>
                  <View style={styles.section_col_service}>
                    <View>
                      {AdditionalCharge.map((additionalCharge, index) => (
                        <Text key={index} style={styles.subheadingTextProposalkeys}>
                          {additionalCharge.charge_description} :
                        </Text>
                      ))}
                    </View>
                  </View>
                </View>
                <View style={styles.right}>
                  <View style={styles.section_val}>
                    <View style={{ dispaly: 'flex', alignItems: 'flex-end' }}>
                      {AdditionalCharge.map((accessory, index) => (
                        <Text key={index} style={styles.subheadingTextProposalval}>
                          {' '}
                          ${parseFloat(accessory.charge).toFixed(2)}
                        </Text>
                      ))}
                    </View>
                  </View>
                </View>
              </View>
            </>
          ) : null}

          {salesOrder.show_disc && salesOrder.sales_order_discount > 0 ? (
            <View style={styles.section_table_row_service}>
              <View style={styles.left}>
                <View style={styles.section_col_service}>
                  <Text style={styles.headingTextProposalkeys}>Discount ({salesOrder.sales_order_discount}%) :</Text>
                </View>
              </View>
              <View style={styles.right}>
                <View style={styles.section_val}>
                  <Text style={styles.headingTextProposalval}>${parseFloat(salesOrder.sales_order_discount_dollar).toFixed(2)}</Text>
                </View>
              </View>
            </View>
          ) : null}
          <View style={styles.section_table_row_service}>
            <View style={styles.left}>
              <View style={styles.section_col_service}>
                <Text style={styles.headingTextProposalkeys}>Tax :</Text>
              </View>
            </View>
            <View style={styles.right}>
              <View style={styles.section_val}>
                <Text style={styles.headingTextProposalval}>${parseFloat(salesOrder.sales_order_sales_tax).toFixed(2)}</Text>
              </View>
            </View>
          </View>
          <View style={styles.section_table_row_service}>
            <View style={styles.left}>
              <View style={styles.section_col_service}>
                <View style={styles.section_table_row_service}>
                  <Text style={styles.headingTextProposalB}>SALES ORDER TOTAL:</Text>
                </View>
              </View>
            </View>
            <View style={styles.right}>
              <View style={styles.section_val}>
                <Text style={styles.headingTextProposalfinalvalB}>${salesOrder.sales_order_final_total}</Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.section_space} />
      <View style={styles.section_space} />
      <View style={styles.horizontalborder} />
    </View>
  );
}

export default CTSOTabledata;
