import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import HeaderLogoIcon2 from "../img/1.png";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: "60px",
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const drawerWidth = 350;

export default function PageNotFound() {
  const navigate = useNavigate();

  return (
    <div>
      <Box
        className="pageNotFoundPageMainBox"
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Item>
            <img
              src={HeaderLogoIcon2}
              className="pageNotFoundImage"
              alt="HeaderLogoIcon2"
            />
            <Typography
              className="pageNotFoundText404"
              sx={{ fontWeight: "bold", marginRight: "20px" }}
            >
              404
            </Typography>

            <Typography
              className="pageNotFoundText"
              sx={{ fontWeight: "bold" }}
            >
              Page Not Found!
            </Typography>

            <Box>
              <Button
                className="pageNotFoundPageButton"
                onClick={() => navigate("/tbm/ps/dashboard")}
                variant="contained"
              >
                {" "}
                BACK TO HOME
              </Button>
            </Box>
          </Item>
        </Box>
      </Box>
    </div>
  );
}
