/** @format */

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MiscCheckIcon from '../../assets/icons/misc/misc-confirm.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import ContinueIcon from '../../assets/icons/primary/continue.svg';
import ContinueHovIcon from '../../assets/icons/primary/WithHovIcon/continue-hov.svg';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import fracty from 'fracty';
import Checkbox from '@mui/material/Checkbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CONFIG from '../../config/config.js';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import MuiAlert from '@mui/material/Alert';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import SalesOrderStepper from './SalesOrderStepper';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const TurquoiseTooltipforNotes = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#faf3dd',
    color: '#000000',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 300,
    maxHeight: 300,
    textAlign: 'left',
    align: 'left',
    border: '1px solid #000000',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#f2f3f2',
      border: '1px solid #000000',
    },
    top: '100%', // Position the arrow at the bottom of the tooltip box
    right: '80', // Position the arrow at the right side of the tooltip box
    marginLeft: '18px',
  },
}));

export default function SalesOrderAddingWindows() {
  const outerTheme = createTheme({
    palette: {
      primary: {
        main: '#333333',
      },
    },
  });
  const location = useLocation();
  const navigate = useNavigate();

  let locationState = [];
  let CheckedStateArray = [];
  let lastName;
  let city;
  let SODetailsVal = [];
  let SODetailsLength;

  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    locationState = location.state;
    CheckedStateArray = location.state.SalesOrderDetails.length;
    city = location.state.customerDetails.customerdata.city;
    lastName = location.state.customerDetails.customerdata.last_name;
    SODetailsVal = location.state.SalesOrderDetails;
    SODetailsLength = location.state.SalesOrderDetails.length;
  }

  const vertical = 'top';
  const horizontal = 'center';
  const [SalesOrderID, setSalesOrderID] = useState();
  const [SalesOrderName, setSalesOrderName] = useState();

  const [appliedcoveringswindows, setAppliedCoveringsWindows] = useState(locationState.SalesOrderDetails);
  const [checkedrowdata, setCheckedRowData] = useState([]);
  const [selected, setSelected] = React.useState([]);
  const [WindowSetData, setWindowSetData] = useState(SODetailsVal);
  const [showSA, setShowSA] = React.useState(true);
  const [windowDetails, setWindowDetails] = useState([]);
  const [windowItem, setWindowItems] = useState(new Array(CheckedStateArray).fill(false));
  let rows = WindowSetData;
  const [checkedState, setCheckedState] = useState(new Array(CheckedStateArray).fill(false));
  const isSelected = (row) => selected.indexOf(row) !== -1;
  const [CoveringCategory, setCoveringCategory] = useState();
  const [TotalWindowsInSalesOrder, setTotalWindowsInSalesOrder] = useState([]);
  const [salesOrderWindows, setSalesOrderWindows] = useState([]);

  // error handling states

  const [SalesOrderDetailsErrorAlert, setSalesOrderDetailsErrorAlert] = useState(false);
  const [UpdateSalesOrderErrorAlert, setUpdateSalesOrderErrorAlert] = useState(false);
  const [UpdateSalesOrderErrorMsg, setUpdateSalesOrderErrorMsg] = useState('');
  const [SalesOrderDetailsErrorMsg, setSalesOrderDetailsErrorMsg] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  let isItemSelected;
  const token = sessionStorage.getItem('token');

  const [isImageHovered7, setIsImageHovered7] = useState(false);
  const handleHover7 = () => {
    setIsImageHovered7(!isImageHovered7);
  };

  const handleTextClick7 = () => {
    setIsImageHovered7(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    rows = WindowSetData;
    setAppliedCoveringsWindows(WindowSetData);

    let newArryforSalesorderStatus = [];

    rows.forEach((element) => {
      if (element.sales_order_status) {
        newArryforSalesorderStatus.push(element.sales_order_status);
      } else {
        newArryforSalesorderStatus.push(element.sales_order_status);
      }
    });

    setCheckedState(newArryforSalesorderStatus);
    setWindowItems(newArryforSalesorderStatus);
  }, [WindowSetData]);

  const GetSalesOrderWindows = async (SalesOrderID) => {
    const response = await fetch(`${CONFIG.API_URL}/so/salesorderdetails/${SalesOrderID}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      setSalesOrderDetailsErrorMsg(data.error_message);
      setSalesOrderDetailsErrorAlert(true);
    }
    setTotalWindowsInSalesOrder(data.SalesOrderDetails);
    setWindowSetData(data.SalesOrderDetails);
    let selectedWindowsforSalesOrder = [];
    data.SalesOrderDetails.forEach((SalesOrderWindows) => {
      if (SalesOrderWindows.window_selected === true && SalesOrderWindows.covering_type === locationState.CoveringCategory) {
        selectedWindowsforSalesOrder.push(SalesOrderWindows);
      }
    });
    setSalesOrderWindows(selectedWindowsforSalesOrder);
    const allEqual = (arr) => arr.every((val) => val.covering_type === arr[0].covering_type);
    const checkCoveringType = allEqual(selectedWindowsforSalesOrder);

    setSelected(selectedWindowsforSalesOrder);
  };

  const UpdateSalesOrder = async () => {
    const response = await fetch(`${CONFIG.API_URL}/so/UpdateSalesOrder`, {
      method: 'PUT',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        sales_order_id: SalesOrderID,
        windows: checkedrowdata,
      }),
    });
    const data = await response.json();
    if (data.error) {
      setUpdateSalesOrderErrorMsg(data.error_message);
      setUpdateSalesOrderErrorAlert(true);
      return;
    }
    navigate('/tbm/ps/salesorder', {
      state: {
        ProposalDetails: locationState.ProposalDetails,
        customerDetails: locationState.customerDetails,
        SalesOrder: locationState.SalesOrder,
        SalesOrderDetails: data.SalesOrderDetails,
        windowDetails: windowDetails,
        checkedrowdata: checkedrowdata,
        CoveringCategory: CoveringCategory,
        data: locationState.data,
        remeasure: locationState.remeasure,
        customerId: locationState.customerId,
        addressId: locationState.addressId,
        salesOrderId: SalesOrderID,
        salesordername: SalesOrderName,
      },
    });
  };

  const handleOnChange = (position, e, row) => {
    var checker;

    let checkstatus = e.target.checked;
    let allWindowStatus;

    const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));

    setCheckedState(updatedCheckedState);

    const allEqual = (arr) => arr.every((val) => val === false);
    checker = (arr1) => arr1.every((v) => v === false);

    allWindowStatus = checker(updatedCheckedState);
    let CoveringCategory = row.covering_type;
    let cin = row.sku;
    let allwindowCheckStatus = allEqual(updatedCheckedState);

    setCoveringCategory(CoveringCategory);
    let similarArrIndex = [];
    let dissimilarArrIndex = [];

    appliedcoveringswindows.forEach((windows, index) => {
      if (windows.sku === cin) {
        similarArrIndex.push(index);
      } else {
        dissimilarArrIndex.push(index);
      }
    });

    let arr = windowItem;

    if (checkstatus) {
      dissimilarArrIndex.forEach((selectedposition) => {
        for (var i = 0; i < arr.length; i++) {
          if (i === selectedposition) arr[i] = true;
        }

        setWindowItems(arr);
      });
      setShowSA(true);
      const selectedIndex = selected.indexOf(row);

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      newSelected.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = true;
        }
      });

      for (var i = 0; i < newSelected.length; i++) {
        if (WindowSetData[i].selected === newSelected[i].selected) {
        }
      }

      setWindowDetails(newSelected);
      if (WindowSetData.length === newSelected.length) {
        setShowSA(false);
      }
      setCheckedRowData(newSelected);
      setSelected(newSelected);
    } else {
      const selectedIndex = selected.indexOf(row);
      const selectedActualIndex = WindowSetData.indexOf(row);

      WindowSetData[selectedActualIndex].selected = false;

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      if (WindowSetData.length > newSelected.length) {
        setShowSA(true);
      }

      setCheckedRowData(newSelected);
      setSelected(newSelected);
    }

    if (!checkstatus && allWindowStatus === true) {
      dissimilarArrIndex.forEach((selectedposition) => {
        let arr = windowItem;
        for (var i = 0; i < arr.length; i++) {
          if (i === selectedposition) arr[i] = false;
        }

        setWindowItems(arr);
      });
    }
    if (allwindowCheckStatus) {
      if (!checkstatus && allWindowStatus === false) {
        let newArrforWindowItem = [];
        WindowSetData.forEach((element) => {
          if (element.sales_order_status) {
            newArrforWindowItem.push(element.sales_order_status);
          } else {
            newArrforWindowItem.push(element.sales_order_status);
          }
        });
        let checkboxstatus = [];
        similarArrIndex.forEach((ele) => {
          checkboxstatus.push(windowItem[ele]);
        });
        let checker = (arr) => arr.every((v) => v === false);

        setWindowItems(newArrforWindowItem);
      }
    }
  };

  const handleSelectAllClick = (event) => {
    if (event === true) {
      const newSelecteds = rows.map((n) => n);

      setSelected(newSelecteds);
      setWindowDetails(newSelecteds);
      setCheckedRowData(newSelecteds);
      newSelecteds.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = true;
        }
      });
      return;
    } else {
      const newSelecteds = rows.map((n) => n);

      newSelecteds.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = false;
        }
      });
      setCheckedRowData([]);
      setSelected([]);
    }
  };

  function WindowTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='table-headerps sticky-columnws-2 font_weight_bold'>
            <span style={{ marginLeft: '35px' }}>#</span>
            <span style={{ marginLeft: '30px' }}>Window Name</span>
          </TableCell>
          <TableCell className='small_header'>
            <CheckCircleIcon />
          </TableCell>
          <TableCell className='table-headerpd_size font_weight_bold'>Size</TableCell>
          <TableCell className='table-headerpd_price2 font_weight_bold'>Retail</TableCell>
          <TableCell className='table-headerpd_price2 font_weight_bold'>Install</TableCell>
          <TableCell className='table-headerpd_price2 font_weight_bold'>Total</TableCell>
          <TableCell className='small_header font_weight_bold'>Bld</TableCell>
          <TableCell className='table-header0 font_weight_bold'>Covering System</TableCell>
          <TableCell className='table-headerpd_price3 font_weight_bold'>Note</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  useEffect(() => {
    if (location.state === null) {
    } else {
      if (locationState.salesOrderId) {
        GetSalesOrderWindows(locationState.salesOrderId);
      } else {
        GetSalesOrderWindows(locationState.SalesOrder[0].sales_order_id);
      }

      if (locationState.salesOrderId) {
        setSalesOrderID(locationState.salesOrderId);
      } else {
        setSalesOrderID(locationState.SalesOrder[0].sales_order_id);
      }

      if (locationState.salesordername) {
        setSalesOrderName(locationState.salesordername);
      } else {
        setSalesOrderName(locationState.SalesOrder[0].sales_order_name);
      }
    }
  }, []);

  const LongText = ({ content }) => {
    const [limit, setLimit] = useState(CONFIG.GlobalTruncateCharacterLimit);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    if (content === undefined) {
      // Data is undefined, return empty
      return null;
    }

    // Check if the window width is between 280px and 768px and content length is greater than 14
    if (windowWidth >= 280 && windowWidth <= 767 && content.length > 15) {
      const truncatedContent = content.substring(0, 15) + '...';
      return <span>{truncatedContent}</span>;
    }

    // Check if the window width is above or equal to 768
    if (windowWidth >= 768) {
      if (content.length <= limit) {
        // There is nothing more to show
        return <span>{content}</span>;
      }

      let truncatedContent = content.substring(0, limit);

      // Find the last space within the truncated content
      const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

      // Adjust the truncated content if a space was found
      if (lastSpaceIndex !== -1) {
        truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
      }

      // Add the ellipsis to the truncated content
      const toShow = truncatedContent + '...';

      return <span>{toShow}</span>;
    }

    if (content.length <= limit) {
      // There is nothing more to show
      return <span>{content}</span>;
    }

    let truncatedContent = content.substring(0, limit);

    // Find the last space within the truncated content
    const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

    // Adjust the truncated content if a space was found
    if (lastSpaceIndex !== -1) {
      truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
    }

    // Add the ellipsis to the truncated content
    const toShow = truncatedContent + '...';

    return <span>{toShow}</span>;
  };

  const [isImageHovered, setIsImageHovered] = useState(false);

  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [CancelAlert, setCancelAlert] = useState(false);

  const ConfirmAlertFun = () => {
    setCancelAlert(false);
    navigate(`/tbm/ps/customerdetails`, {
      state: {
        Custdata: {
          custid: locationState.customerId,
          Addressid: locationState.addressId,
        },
      },
    });
  };

  return (
    <div align='center'>
      <ThemeProvider theme={outerTheme}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          {/* SalesOrderDetailsErrorAlert */}
          <Snackbar className='snackbar_bar_postition' open={SalesOrderDetailsErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setSalesOrderDetailsErrorAlert(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img
                  onClick={() => {
                    setSalesOrderDetailsErrorAlert(false);
                  }}
                  src={RedDrawerCancelIcon}
                  alt='RedDrawerCancelIcon'
                  className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{SalesOrderDetailsErrorMsg}</div>
              </div>
            </Alert>
          </Snackbar>

          {/* UpdateSalesOrderErrorAlert */}
          <Snackbar className='snackbar_bar_postition' open={UpdateSalesOrderErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setUpdateSalesOrderErrorAlert(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img
                  onClick={() => {
                    setUpdateSalesOrderErrorAlert(false);
                  }}
                  src={RedDrawerCancelIcon}
                  alt='RedDrawerCancelIcon'
                  className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{UpdateSalesOrderErrorMsg}</div>
              </div>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={CancelAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setCancelAlert(false)}>
            <Alert className='Success mainAlert' variant='filled' icon={false}>
              <div className='alertCancel' align='right'>
                <img
                  onClick={() => {
                    setCancelAlert(false);
                  }}
                  src={DrawerCancelIcon}
                  alt='DrawerCancelIcon'
                  className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                />
              </div>

              <div className='alertMainNew'>
                <div align='left' style={{ paddingTop: 37 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'Center',
                    }}
                  >
                    <Box className='alertCantinueButton'>
                      <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='DrawerAlertIcon' />
                    </Box>
                  </Box>

                  <div
                    className='alertTextAlign'
                    style={{
                      paddingTop: 20,
                      fontSize: '20px',
                      fontFamily: 'RobotoRegular',
                    }}
                  >
                    This Sales Order has not been completed. Leaving will save it as a work in progress in the Customer Details section
                  </div>
                </div>

                <div
                  align='left'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px 10px 0px 24px',
                    justifyContent: 'center',
                    alignItems: 'start',
                    lineHeight: '24px',
                  }}
                >
                  <Box
                    onClick={() => {
                      ConfirmAlertFun();
                    }}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'Center',
                      cursor: 'pointer',
                      marginBottom: '20px',
                    }}
                  >
                    <Box onClick={() => {}} className='alertCantinueButton'>
                      <img src={DrawerConinueIcon} className='primary_icons_dimension' alt='DrawerConinueIcon' />
                    </Box>
                    <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                      CONTINUE
                    </Typography>
                  </Box>

                  <Box
                    onClick={() => {
                      setCancelAlert(false);
                    }}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'Center',
                      cursor: 'pointer',
                      paddingBottom: '16px',
                      paddingLeft: '15px',
                    }}
                  >
                    <Box className='globalIconMainBox'>
                      <Box className='iconSecondaryButtonGlobalForDrawer'>
                        <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
                      </Box>
                      <Typography className='globalTextForDrawer'>CANCEL</Typography>
                    </Box>
                  </Box>
                </div>
              </div>
            </Alert>
          </Snackbar>
        </Stack>

        <div className='heading-box-1'>
          <h2 className='clickable_header all_pages_h2_header'>
            <LongText content={SalesOrderName} />
          </h2>

          <h3
            className='clickable_header_1 all_pages_h2_header button_top_space common_line_height_css'
            style={{ textTransform: 'capitalize' }}
            onClick={() => {
              setCancelAlert(true);
            }}
          >
            <LongText content={`${lastName} | ${city}`} />
          </h3>
        </div>

        <div className='button_top_space' align='center' style={{ marginBottom: '10px' }}>
          <SalesOrderStepper stepperData='ConvertingSalesOrder' />
        </div>

        <hr className='button_bottom_space button_top_space' />
        <Box className='table-box-1'>
          <TableContainer sx={{ borderBottom: '1px solid #D6D6D6', textAlign: 'left' }} className='table_padding_bottom'>
            <Table className='sales-order-adding-windows-table' aria-labelledby='tableTitle' size='small'>
              <WindowTableHead onSelectAllClick={handleSelectAllClick} rowCount={SODetailsLength} />
              <TableBody>
                {rows.map((row, index) => {
                  isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover className='rowhover' role='checkbox' aria-checked={isItemSelected} tabIndex={-1} key={row.window_id}>
                      <TableCell className='table-content-apply-coveringo  sticky-column' align='left'>
                        {' '}
                        <Box
                          sx={{
                            width: '100% !important',
                            color: '#008593',
                            cursor: 'pointer',
                          }}
                          className='ellipsis2'
                        >
                          {' '}
                          <span
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              verticalAlign: 'middle', // Add verticalAlign property
                            }}
                          >
                            <Checkbox
                              sx={{ zoom: 0.8, paddingTop: '0px' }}
                              color='primary'
                              checked={isItemSelected || row.sales_order_status}
                              disabled={windowItem[index] || row.sales_order_status}
                              onChange={(e) => handleOnChange(index, e, row)}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />

                            <span
                              style={{
                                paddingRight: `${rows.indexOf(row) + 1 > 9 ? '20px' : '30px'}`,
                              }}
                              className='AddingsalesOrderWindowName'
                            >
                              {rows.indexOf(row) + 1}
                            </span>
                          </span>
                          <span className='AddingsalesOrderWindowName'>
                            <LongText content={row.window_name} />
                          </span>
                        </Box>
                      </TableCell>
                      <TableCell className='small_cell font_weight_bold'>
                        {row.sales_order_status === true ? (
                          <>
                            <img src={MiscCheckIcon} className='misc_icons_dimensions' alt='MiscCheckIcon' />
                          </>
                        ) : (
                          <span sx={{ marginLeft: '18px' }}></span>
                        )}
                      </TableCell>

                      <TableCell className='table-content' align='left'>
                        {fracty(row.window_width)}" x {fracty(row.window_height)}"{' '}
                        <span className={row.sales_order_measurement_type.replace(/ /g, '')}>
                          {row.sales_order_measurement_type === 'Quick'
                            ? '(' + 'QK' + ')'
                            : row.sales_order_measurement_type === 'Shutters'
                            ? '(' + 'SH' + ')'
                            : row.sales_order_measurement_type === 'Blinds'
                            ? '(' + 'BL' + ')'
                            : '(' +
                              row.sales_order_measurement_type
                                .split(' ')
                                .map((word) => word[0])
                                .join('') +
                              ')'}
                        </span>
                      </TableCell>
                      <TableCell className='table-content-after-material' align='left'>
                        <>${Number(row.suggested_price).toFixed(2)}</>
                      </TableCell>

                      <TableCell className='table-content-after-material' align='left'>
                        <>${Number(row.covering_install_price).toFixed(2)}</>
                      </TableCell>

                      <TableCell className='table-content-after-material' align='left'>
                        <>${Number(row.proposal_total_with_install).toFixed(2)}</>
                      </TableCell>
                      <TableCell className='table-content' align='left'>
                        &nbsp;&nbsp;{row.build_type[0]}
                      </TableCell>

                      <TableCell className='table-content-second2' align='left'>
                        <>
                          {row.material_code.toString().replace(/,/g, ', ')}, {row.build_type}, {row.existing_covering}, {row.mount_surface}, {row.ladder}
                        </>
                      </TableCell>
                      <TableCell className='table-content-after-material small_cell2' align='left'>
                        {row.notes_count === '0' ? <></> : <span style={{ paddingLeft: '8px' }}>{row.notes_count}</span>}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <hr />

        {checkedrowdata.length > 0 ? (
          <Box>
            <span
              className='button_span button_top_space button_bottom_space_salesorderaddingwindow'
              onClick={() => {
                UpdateSalesOrder();
              }}
              onMouseEnter={handleHoverFun}
              onMouseLeave={handleMouseLeaveFun}
            >
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                {isHovered ? <img className='primary_icons_dimension_1' src={ContinueHovIcon} alt='ContinueHovIcon' /> : <img className='primary_icons_dimension_1' src={ContinueIcon} alt='ContinueIcon' />}
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                  CONTINUE
                </Typography>
              </Box>
            </span>
          </Box>
        ) : (
          <span className='button_span_disabled button_top_space button_bottom_space'>
            <Box className='globalIconMainBox globalMarginLeftAddWindow'>
              <img src={ContinueIcon} alt='ContinueIcon' className='primary_icons_dimension_disable' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                CONTINUE
              </Typography>
            </Box>
          </span>
        )}

        <span className='gray_button_span button_top_space' style={{ paddingBottom: '30px', paddingLeft: '22px' }}>
          <Box onClick={() => navigate(-1)} className='globalIconMainBox'>
            <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered7 ? 'hovered' : ''}`} alt='CancelIcon' />
            <Typography onMouseEnter={handleHover7} onMouseLeave={handleHover7} onClick={handleTextClick7} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
              CANCEL
            </Typography>
          </Box>
        </span>
        <hr />
      </ThemeProvider>
    </div>
  );
}
