/** @format */

import React, { useState } from 'react';
import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import { TextField } from '@mui/material';
import CONFIG from '../../config/config.js';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerSaveIcon from '../../assets/icons/drawer-alert/drw-save.svg';
import DrawerCloseIcon from '../../assets/icons/drawer-alert/close.svg';
const EditProposalWindowName = ({ window_name, window_id, customer_id, address_id, token, closeDrawer }) => {
  const [windowName, setWindowName] = useState(window_name);
  const [isOpen, setIsOpen] = useState(true);
  const [message, setMessage] = useState('');

  const EditProposalWindowName = (e) => {
    setWindowName(e.target.value);
  };

  const editWindowName = async () => {
    const response = await fetch(`${CONFIG.API_URL}/wm/UpdateWindowName`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        window_id: window_id,
        window_name: windowName,
        customer_id: customer_id,
        address_id: address_id,
      }),
    });
    const data = await response.json();

    if (data.error) {
      setMessage(data.message);
      setIsOpen(false);
      return;
    }
    setIsOpen(false);
    setMessage(data.message);

    closeDrawer(windowName, false, data.message);
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  return (
    <Box p={4} pt={2} className='sideDrawer'>
      <div align='right' className='drawer_backbutton'>
        <img alt='CLOSEDRAWER' src={DrawerCloseIcon} onClick={() => closeDrawer(windowName, false, message)} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
      </div>
      <Stack direction='column' spacing={0}>
        <TextField value={windowName} className='notes_textfield' onChange={EditProposalWindowName} />
        <span
          className='drawer_gray_button_span'
          onClick={windowName ? editWindowName : null}
          style={{
            paddingBottom: '0px',
            paddingLeft: '0px',
            paddingTop: '0px !important',
          }}
          onMouseEnter={windowName ? handleHover : null}
          onMouseLeave={windowName ? handleHover : null}
        >
          <Box className='globalIconMainBox globalDrawerText'>
            <img alt='SAVE' src={DrawerSaveIcon} className={`primary_icons_dimension ${!windowName ? 'primary_icons_dimension_disable' : ''} ${isImageHovered ? 'hovered' : ''}`} />
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
              SAVE
            </Typography>
          </Box>
        </span>

        <span className='drawer_gray_button_span button_top_space' onClick={() => closeDrawer(windowName, !isOpen, message)} style={{ paddingBottom: '30px', paddingLeft: '14px' }}>
          <Box className='globalIconMainBox'>
            <img alt='CANCEL' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension' />
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
              CANCEL
            </Typography>
          </Box>
        </span>
      </Stack>
    </Box>
  );
};

export default EditProposalWindowName;
