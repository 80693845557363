/** @format */

import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ModeEditIcon from '../../img/edit.png';
import Box from '@mui/material/Box';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import CTSOIcon from '../../assets/icons/primary/proposal.svg';
import Edit from '../../img/edit.png';
import notesIcon from '../../img/notesIcon.png';
import NoteMiscIcon from '../../assets/icons/misc/misc-note.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CONFIG from '../../config/config.js';
import PreviewIcon from '../../assets/icons/secondary/2nd-visability.svg';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import Grid from '@mui/material/Grid';
import { PDFDownloadLink, PDFViewer, StyleSheet } from '@react-pdf/renderer';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Stack from '@mui/material/Stack';
import { useSnackbar, SnackbarProvider } from 'notistack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { createTheme, Divider, ThemeProvider } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import ProposalTable from './ProposalTable';
import Fab from '@mui/material/Fab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Save from '../../img/save-icon.png';
import NotesDrawer from './NotesDrawer';
import ShareIcon from '../../assets/icons/primary/share.svg';
import WO from '../../img/ctw.png';
import WOD from '../../img/ctw-disable.png';
import { useCookies } from 'react-cookie';
import ShowProposalName from './ShowProposalName';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Items from '../../img/items.png';
import CircleIcon from '@mui/icons-material/Circle';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Drawer from '@mui/material/Drawer';
import PriceDrawer from '../WindowsManagement/PriceDrawer';
import { makeStyles } from '@mui/styles';
import AddOutlinedIcon from '../../img/add.png';
import InstallAccessoriesDrawer from './InstallAccessoriesDrawer';
import { TextField } from '@mui/material';
import ShareProposal from './shareproposal';
// import CTW from "../ConvertToWorkOrder/CTW";
import EditLinesIcon from '../../assets/icons/secondary/2nd-edit.svg';
import AddItemsIcon from '../../assets/icons/primary/pdf-proposal.svg';
import SaveIcon from '../../assets/icons/secondary/2nd-save.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

let AppliedCoveringsWindows = [];
let SelectedWindows = [];
const drawerTheme = createTheme({
  palette: {
    border: {
      main: '1px solid #000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#FAC41E',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "green", // date picker textfield style
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#008593 !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'white', // disable date
            color: '#E9E9E9',
          },
        },
        dayWithMargin: {
          backgroundColor: 'white', // not selected date
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "blue" //calender icon & month change arrow icon style
        },
      },
    },
  },
});
const RM = [
  {
    value: 'None',
    label: 'None',
  },
  {
    value: 'Office Remeasure',
    label: 'Office Remeasure',
  },
  {
    value: 'Self Remeasure',
    label: 'Self Remeasure',
  },
];

const marginBottom = { marginBottom: '5%', padding: '0' };
const useStyles = makeStyles({
  paper: {
    width: '90%',
    // backdropFilter: "blur(10px) !important",
    background: 'rgba(69, 157, 166, 70%)  !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

export default function DuplicateProposalDetails(props) {
  const location = useLocation();
  const [editchecked, setEditchecked] = useState(false);
  const [editDisappear, setEditDisappear] = useState(true);
  const [shareProposal, setShareProposal] = useState(true);
  const [convertToOrder, setConvertToOrder] = useState(true);
  const [addEditButton, setAddEditButton] = useState(false);
  const [checkboxflag, setCheckboxFlag] = useState(true);
  const [WindowSetData, setWindowSetData] = useState([]);
  const [proposalName, setProposalName] = useState();
  const [proposalID, setProposalID] = useState();
  const [TotalCost, setTotalCost] = useState('');
  const [Discount, setDiscount] = useState('');
  const [Commission, setCommission] = useState('');
  const [InstallationCost, setInstallationCost] = useState('');
  const [ProposalRetailPrice, setProposalRetailPrice] = useState('');
  const [Tax, setTax] = useState('');
  const [skuDetails, setskuDetails] = useState('');
  const [extraCharges, setExtraCharges] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(CONFIG.WindowsManagement.RowperPageLimit);
  const [open, setOpen] = React.useState(false);
  const [path, setPath] = React.useState(false);
  const [coveringsApplied, setCoveringsAppliedWindows] = useState([]);
  const [cookies, setCookie] = useCookies(['token']);
  const [existingcovering, setExistingcovering] = useState('');
  const [isDrawerOpenSecond, setIsDrawerOpenSecond] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [DiscountDollar, setDiscountDollar] = useState('');
  const [UpdateProposalNameStatus, setUpdateProposalNameStatus] = useState(false);
  const [Labels, setLabels] = useState([]);
  const [Accessoriescharge, setAccessoriesCharge] = useState([]);
  const [Servicescharge, setServicesCharge] = useState([]);
  const [remeasure, setRemeasure] = useState('');
  const [coveringsAppliedWindowswithQuick, setcoveringsAppliedWindowswithQuick] = useState([]);
  const [WindowwithQuick, setWindowwithQuick] = useState();
  const [errormsg, setErrorMsg] = React.useState(false);

  const [isDrawerOpenforShareProposal, setIsDrawerOpenforShareProposal] = useState(false);
  const [shareablelink, setShareableLink] = useState('');
  const [content, setContent] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [emailid, setEmailID] = useState(location.state.customerdata.email_id);
  const [file, setFile] = useState('');
  const [successmsgformail, setSuccessMsgForMail] = React.useState(false);
  const [successmsg, setSuccessMsg] = React.useState('');
  const [agreementStatus, setAgreementStatus] = useState();
  const [savedremeasure, setsavedRemeasure] = useState('');
  const [navigatesalesorder, setNavigateSalesOrder] = useState([]);
  const [proposalAcceptanceStatus, setProposalAcceptanceStatus] = useState();
  const [remeasureOpen, setRemeasureOpen] = useState(false);
  const [selectedWindows, setSelectedWindows] = useState([]);
  const [notes, setNotes] = useState(false);
  const [notesSuccessMsg, setNotesSuccessMsg] = React.useState(false);
  const [notesAlertMsg, setNotesAlertMsg] = useState('');

  // Error Handling
  const [ConvertToSalesOrderApiErrorAlert, setConvertToSalesOrderApiErrorAlert] = useState(false);
  const [ProposalByProposalIdApiErrorAlert, setProposalByProposalIdApiErrorAlert] = useState(false);
  const [CoveringProposalDetailsApiErrorAlert, setCoveringProposalDetailsApiErrorAlert] = useState(false);

  const [ConvertToSalesOrderApiErrorMsg, setConvertToSalesOrderApiErrorMsg] = useState('');
  const [ProposalByProposalIdApiErrorMsg, setProposalByProposalIdApiErrorMsg] = useState('');
  const [CoveringProposalDetailsApiErrorMsg, setCoveringProposalDetailsApiErrorMsg] = useState('');

  let filename;

  let commonData = {
    AppliedCoveringsWindows: AppliedCoveringsWindows,
    selectedJsonData: selectedJsonData,
    path: location.state.path,
    windowsetdata: windowsetdata,
    proposalID: location.state.proposalID,
    extraCharges: extraCharges,
  };
  const unique = (value, index, self) => {
    return self.indexOf(value) === index;
  };
  let proposaldetails = {};
  const vertical = 'top';
  const horizontal = 'center';
  const windowname = [];
  const classes = useStyles();

  const params = useParams();

  const navigate = useNavigate();
  let coveringdetails = location.state.coveringdetails;

  if (location.state.proposalName === proposalName) {
  } else {
  }
  const selectedJsonData = location.state.jsonData;
  useEffect(() => {
    {
      window.scrollTo(0, 0);
    }
    getVerified();
    setProposalID(location.state.proposalID);
    setProposalName(location.state.proposalName);
  }, []);
  if (location.state.path === 'DuplicateProposal') {
    proposaldetails = {
      customerdata: location.state.customerdata,
      proposal_id: params.id,
      proposal_name: location.state.proposalName,
      UpdateProposalNameStatus: UpdateProposalNameStatus,
    };
  } else {
    proposaldetails = {
      customerdata: location.state.customerdata,
      proposal_id: location.state.proposalID,
      proposal_name: location.state.proposalName,
      UpdateProposalNameStatus: UpdateProposalNameStatus,
      proposal_acceptance_status: proposalAcceptanceStatus,
    };
  }

  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const handleAddEditItems = () => {
    setIsDrawerOpenSecond(true);
  };
  const SaveProposal = async (path, remeasureval) => {
    let remeasureselected = path === 'remeasure' ? remeasureval : remeasure;
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: location.state.proposalID,
        remeasure: remeasureselected,
      }),
    };
    // Created Proposal will be added to the database through CreateProposal API
    await fetch(`${CONFIG.API_URL}/pm/SaveProposal`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setEditchecked(false);
          setEditDisappear(true);
          setAddEditButton(false);
          setCheckboxFlag(true);

          if (path !== 'remeasure') {
            navigate(`/tbm/ps/customerdetails`, {
              state: {
                Custdata: {
                  custid: location.state.customerdata.customer_id,
                  Addressid: location.state.customerdata.address_id,
                },
              },
            });
          } else {
            ProposalByProposal();
          }
        }
      });
  };

  const handleRemeasure = (e) => {
    setRemeasure(e.target.value);

    if (coveringsAppliedWindowswithQuick.length > 0 && e.target.value === 'None') {
      setWindowwithQuick(true);
      setErrorMsg(true);
    } else {
      setWindowwithQuick(false);
      let path = 'remeasure';
      let remeasureval = e.target.value;
      SaveProposal(path, remeasureval);
    }
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessMsgForMail(false);
  };

  const handleNotificationClick = () => {
    setOpen(true);
  };

  const ProposalByProposal = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };
    // Created Proposal will be added to the database through CreateProposal API
    await fetch(`${CONFIG.API_URL}/pm/ProposalByProposalId/${params.id}`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setNavigateSalesOrder(data.proposal_details);
          setSelectedWindows(data.proposal_details);
          setnotesContent(data.proposal_notes);

          if (data.proposal.adobe_sign_status === 'ACCEPTED_BY_SALES_REP') {
            setProposalAcceptanceStatus('ACCEPTED_BY_SALES_REP');
          } else if (data.proposal.adobe_sign_status === 'ACCEPTED_BY_CUSTOMER') {
            setProposalAcceptanceStatus('ACCEPTED_BY_CUSTOMER');
          } else if (data.proposal.adobe_sign_status === 'OUT_FOR_ACCEPTANCE') {
            setProposalAcceptanceStatus('OUT_FOR_ACCEPTANCE');
          } else {
            setProposalAcceptanceStatus('');
          }

          setTotalCost(data.proposal.sub_total);
          setDiscount(data.proposal.discount);
          setDiscountDollar(data.proposal.discount_dollar);
          setCommission(data.proposal.commission);
          setTax(data.proposal.sales_tax);
          setInstallationCost(data.proposal.install_total);
          setProposalRetailPrice(data.proposal.proposal_retail_price);
          setskuDetails(data.SKUDetails);
          setExtraCharges(data);
          let additionalCharge = data.proposal_level_accessory_service_charges;

          let accessoriesDetails = [];
          let serviceDetails = [];

          additionalCharge.forEach((charge) => {
            if (charge.charge_type === 'X') {
              accessoriesDetails.push(charge);
            }
            if (charge.charge_type === 'S') {
              serviceDetails.push(charge);
            }
          });
          setAccessoriesCharge(accessoriesDetails);
          setServicesCharge(serviceDetails);

          setsavedRemeasure(data.proposal.remeasure);
          if (data.proposal.remeasure) {
            setRemeasure(data.proposal.remeasure);
          }
          var allwindows = [];
          var windows = [];
          var labels = [];
          data.proposal_details.forEach((element) => {
            allwindows.push(element.sku_details);
          });
          allwindows.forEach((window) => {
            windows.push(...window);
          });

          windows.forEach((val) => {
            labels.push(val.label);
          });

          setLabels(labels.filter(unique));

          AppliedCoveringsWindows = [];
          SelectedWindows = [];
          if (location.state.path === 'reorder') {
            AppliedCoveringsWindows = [];
            AppliedCoveringsWindows = location.state.windowsselectednotnull;
            setExtraCharges(location.state.extraCharges);
          } else {
            AppliedCoveringsWindows = data.proposal_details;
            data.proposal_details.forEach((element) => {
              if (element.covering_status === 'applied') {
                SelectedWindows.push(element);
              }
            });
          }
        } else {
          setProposalByProposalIdApiErrorMsg(data.error_message);
          setProposalByProposalIdApiErrorAlert(true);
        }
      });
  };

  useEffect(() => {
    ProposalByProposal();

    if (location.state.path === 'reorder') {
      AppliedCoveringsWindows = [];
      AppliedCoveringsWindows = location.state.windowsselectednotnull;
      setExtraCharges(location.state.extraCharges);
    }

    setCoveringsAppliedWindows(AppliedCoveringsWindows);
    getProposalCoveringDetails();
  }, [proposalName]);

  const getProposalCoveringDetails = async (status) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };

    // get proposal details
    await fetch(CONFIG.API_URL + `/pm/CoveringProposalDetails/${location.state.proposalID}`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          let Windows_not_applied = [];
          let coveringswithquick = [];

          data.covering_proposal_details.forEach((i) => {
            if (i.covering_status === 'applied' && i.measurement_type === 'Quick') {
              coveringswithquick.push(i);
            }
          });
          data.covering_proposal_details.forEach((i) => {
            if (i.covering_status === 'not_applied') {
              Windows_not_applied.push(i);
            }
          });
          setcoveringsAppliedWindowswithQuick(coveringswithquick);

          if (Windows_not_applied.length === 0) {
            setShareProposal(true);
            setConvertToOrder(true);
          } else {
            setShareProposal(false);
            setConvertToOrder(false);
          }
        } else {
          setCoveringProposalDetailsApiErrorMsg(data.error_message);
          setCoveringProposalDetailsApiErrorAlert(true);
        }
      });
  };

  const previewProposal = () => {
    navigate(`/tbm/ps/previewproposal/${proposalId}`, {
      state: {
        acceptedby: 'ACCEPTED_BY_SALES_REP',
      },
    });
  };

  const handleShareProposal = () => {
    setIsDrawerOpenforShareProposal(true);
  };

  const fetchDeliveryDrawerData = (drawerstatus, sendstatus, mailStatus, successMsg, successOpen) => {
    setIsDrawerOpenforShareProposal(drawerstatus);
    if (mailStatus === true) {
      setSuccessMsgForMail(true);
      setSuccessMsg(successMsg);
    }
  };

  const uploadFile = (file) => {
    const blobToBase64 = (blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };
    if (file !== null) {
      setFile(file);
      blobToBase64(file).then((res) => {
        setContent(res);
      });
    }
  };

  let selectedwindowsIDs = [];
  selectedwindowsIDs = AppliedCoveringsWindows;
  let windowsetdata = [];
  windowsetdata = location.state.customerdata;

  const navigateNextPage = () => {
    navigate(`/tbm/ps/editmode/${params.id}`, {
      state: {
        data: commonData,
        coveringdetails: coveringdetails,
        selectedwindows: selectedwindowsIDs || SelectedWindows,
        customerdata: windowsetdata,
        totalwindows: location.state.totalwindows || AppliedCoveringsWindows,
        proposalID: location.state.proposalID || location.state.proposal_id,
        proposalName: location.state.proposalName || location.state.proposal_name,
      },
    });
  };

  const fetchdata = (drawerstatus) => {
    if (!drawerstatus) {
      ProposalByProposal();
    }
    setIsDrawerOpen(drawerstatus);
  };

  const fetchDrawerData = (drawerstatus, savedstatus) => {
    setIsDrawerOpenSecond(drawerstatus);
    if (savedstatus === true) {
      ProposalByProposal();
    }
  };

  const [notesContent, setnotesContent] = useState([]);

  const NotesDrawerData = (notesDrawerStatus, notesMsgAlert, notesMsg) => {
    setNotes(notesDrawerStatus);
    setNotesSuccessMsg(notesMsgAlert);
    setNotesAlertMsg(notesMsg);
    if (notesMsg === 'Note added successfully!' || notesMsg === 'Note updated successfully!') {
      ProposalByProposal();
    }
  };

  const [eyeButtonMsg, setEyeButtonMsg] = useState(false);
  const [EditNotes, setEditNote] = useState('');
  const [notesData, setNotesData] = useState('');
  const [noteTypeId, setNoteTypeId] = useState('');
  const [noteId, setNoteId] = useState();
  const [notesDataDescription, setNotesDataDescription] = useState([]);

  const NotesCustomerDetails = {
    firstName: location.state.customerdata.first_name,
    lastname: location.state.customerdata.last_name,
    customerId: location.state.customerdata.customer_id,
    proposalId: location.state.proposalID,
    windowSetId: '',
    proposalName: location.state.proposalName,
  };
  const [valueValue, setValueValue] = useState();

  const EditNotesCustomerDetails = {
    firstName: location.state.customerdata.first_name,
    lastname: location.state.customerdata.last_name,
    customerId: location.state.customerdata.customer_id,
    proposalId: '',
    windowSetId: '',
    proposalName: location.state.proposalName,
    notesContent: notesData,
    noteId: noteId,
    noteTypeId: noteTypeId,
    eyeButtonMsg: eyeButtonMsg,
    notesDataDescription: notesDataDescription,
    valueValue: valueValue,
  };
  const [proposalId, setProposalId] = useState(location.state.proposalID);

  const GetSalesOrderName = async () => {
    await fetch(`${CONFIG.API_URL}/so/ConvertToSalesOrder/${proposalId}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          navigate(`/tbm/ps/salesorderaddingwindows`, {
            state: {
              SalesOrder: data.SalesOrder,
              SalesOrderDetails: data.SalesOrderDetails,
              ProposalDetails: navigatesalesorder,
              remeasure: savedremeasure,
              customerId: location.state.customerdata.customer_id,
              addressId: location.state.customerdata.address_id,
              customerDetails: proposaldetails,
              data: {
                Labels: Labels,
                Proposaldetails: location.state,
                shareablelink: shareablelink,
                route: 'shareproposal',
                content: content,
              },
            },
          });
        } else {
          setConvertToSalesOrderApiErrorMsg(data.error_message);
          setConvertToSalesOrderApiErrorAlert(true);
        }
      });
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  const [isImageHovered3, setIsImageHovered3] = useState(false);
  const handleHover3 = () => {
    setIsImageHovered3(!isImageHovered3);
  };

  const [isImageHovered4, setIsImageHovered4] = useState(false);
  const handleHover4 = () => {
    setIsImageHovered4(!isImageHovered4);
  };

  const [isImageHovered5, setIsImageHovered5] = useState(false);
  const handleHover5 = () => {
    setIsImageHovered5(!isImageHovered5);
  };

  const [isImageHovered6, setIsImageHovered6] = useState(false);
  const handleHover6 = () => {
    setIsImageHovered6(!isImageHovered6);
  };

  const ProposalTablefetchdata = (Error) => {
    if (Error === 'Servererror: invalid input syntax for type uuid: "undefined"') {
      navigate('/pagenotfound');
    }
  };

  const [editingNote, setEditingNote] = useState(false);
  const [noteDrawerDetails, setNoteDrawerDetails] = useState({});
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);
  const openNotesDrawer = (note) => {
    setEditingNote(!!note);
    setNoteDrawerDetails({
      firstName: location.state.customerdata.first_name,
      lastName: location.state.customerdata.last_name,
      referenceId: proposalId,
      noteId: note ? note.id : null,
    });
    setNotesDrawerOpen(true);
  };

  const closeNotesDrawer = (alert) => {
    setNotesDrawerOpen(false);
    setEditingNote(false);
    setNoteDrawerDetails({});

    if (alert.message === 'Note added successfully!' || alert.message === 'Note updated successfully!') {
      // TODO: fetch notes again
    }
  };

  return (
    <>
      <ThemeProvider theme={drawerTheme}>
        <Drawer
          classes={{ paper: classes.paper }}
          PaperProps={{
            sx: { width: '90%' },
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          anchor='right'
          open={notesDrawerOpen}
          onClose={() => setNotesDrawerOpen(false)}
          className='drawer_Header_page'
        >
          <NotesDrawer closeDrawer={closeNotesDrawer} editing={editingNote} type='customer' details={noteDrawerDetails} />
        </Drawer>
      </ThemeProvider>

      <Snackbar
        className='snackbar_bar_postition'
        open={ConvertToSalesOrderApiErrorAlert}
        anchorOrigin={{ vertical, horizontal }}
        // autoHideDuration={3000}
        onClose={() => setConvertToSalesOrderApiErrorAlert(false)}
      >
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img
              onClick={() => {
                setConvertToSalesOrderApiErrorAlert(false);
              }}
              src={RedDrawerCancelIcon}
              alt=''
              className='drawer_secondary_icons_dimension drawer_cancel_cursor'
            />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img src={RedExclamationIcon} alt='' className='primary_icons_dimension alertImageMargin' />
            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{ConvertToSalesOrderApiErrorMsg}</div>
          </div>
        </Alert>
      </Snackbar>

      <Snackbar
        className='snackbar_bar_postition'
        open={ProposalByProposalIdApiErrorAlert}
        anchorOrigin={{ vertical, horizontal }}
        // autoHideDuration={3000}
        onClose={() => setProposalByProposalIdApiErrorAlert(false)}
      >
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img
              onClick={() => {
                setProposalByProposalIdApiErrorAlert(false);
              }}
              src={RedDrawerCancelIcon}
              alt=''
              className='drawer_secondary_icons_dimension drawer_cancel_cursor'
            />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img src={RedExclamationIcon} alt='' className='primary_icons_dimension alertImageMargin' />
            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{ProposalByProposalIdApiErrorMsg}</div>
          </div>
        </Alert>
      </Snackbar>

      <Snackbar
        className='snackbar_bar_postition'
        open={CoveringProposalDetailsApiErrorAlert}
        anchorOrigin={{ vertical, horizontal }}
        // autoHideDuration={3000}
        onClose={() => setCoveringProposalDetailsApiErrorAlert(false)}
      >
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img
              onClick={() => {
                setCoveringProposalDetailsApiErrorAlert(false);
              }}
              src={RedDrawerCancelIcon}
              alt=''
              className='drawer_secondary_icons_dimension drawer_cancel_cursor'
            />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img src={RedExclamationIcon} alt='' className='primary_icons_dimension alertImageMargin' />
            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{CoveringProposalDetailsApiErrorMsg}</div>
          </div>
        </Alert>
      </Snackbar>

      <Snackbar className='snackbar_bar_postition' open={successmsgformail} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setSuccessMsgForMail(false)}>
        <Alert className='Success' variant='filled' icon={false}>
          <>
            <div className='alertMsgCancelICon' align='right'>
              <img onClick={handleSuccessClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
            </div>
            <div align='center' style={{ paddingTop: 30 }}>
              <img src={DrawerConfirmIcon} className='primary_icons_dimension' />
              <div align='center' style={{ paddingTop: 10 }}>
                {successmsg}
              </div>
            </div>
          </>
        </Alert>
      </Snackbar>
      <ShowProposalName proposaldetails={proposaldetails} />
      <Box sx={{ position: 'relative', float: 'right', top: '-55px' }}>
        <img src={NoteMiscIcon} className='misc_icons_dimensions' onClick={() => openNotesDrawer()} />
      </Box>

      <Box sx={{ marginBottom: '20px', marginTop: '20px' }}>
        {notesContent.map((note, index) => (
          <Box key={note.id} sx={{ display: 'flex' }}>
            {note.visible ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : null}{' '}
            <Box>
              <Typography className='' onClick={() => openNotesDrawer(note)}>
                {' '}
                <span
                  style={{
                    color: '#008593',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                >
                  Note #{index + 1} :
                </span>{' '}
                {note.content}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>

      <hr className='button_top_space' />
      <ProposalTable func={ProposalTablefetchdata} commonData={commonData} />
      <hr className='button_top_space' />

      <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page'>
        <span style={{ paddingLeft: '22px' }} className='gray_button_span button_top_space button_bottom_space' onClick={navigateNextPage}>
          <Box className='globalIconMainBox'>
            <img src={EditLinesIcon} className={`secondary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} />
            <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
              {' '}
              EDIT LINES
            </Typography>
          </Box>
        </span>
      </Box>

      <hr />

      <FormControl onClick={() => setRemeasureOpen(!remeasureOpen)} className='formcontrol text_selectfield_copy_upper font_style_of_menuitem button_bottom_space button_top_space'>
        <Select
          size='small'
          MenuProps={{
            style: { zIndex: 10000000 },
          }}
          onClose={() => setRemeasureOpen(false)}
          onOpen={() => setRemeasureOpen(true)}
          open={remeasureOpen}
          value={remeasure}
          onChange={handleRemeasure}
          displayEmpty
          startAdornment={
            <InputAdornment className='font_style_of_menuitem' position='start'>
              Remeasure<span style={{ color: '#DB3131' }}>&nbsp;*</span>&nbsp;:
              {remeasure === '' ? '' : ''}
            </InputAdornment>
          }
        >
          <MenuItem value={''}>
            <b>─ ─</b>
          </MenuItem>
          {RM.map((option) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                <b className='font_style_of_menuitem'>{option.label}</b>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <hr className='' />
      <Box className='details_Main_Box_Proposal_Details_Page'>
        <Box className='details_Sub_Box_Proposal_Details_Page'>
          <Box onClick={() => handleAddEditItems()} className='cust_Type_DC_Installation_Removelfee_Additional_Tax_Box_Second'>
            <Box className='globalIconMainBox globalMarginLeftAddWindow globalTextDecoration'>
              <img className={`primary_icons_dimension ${isImageHovered2 ? 'hovered' : ''}`} src={AddItemsIcon} />
              <Avatar color='primary' className='globalBadgeContent'>
                <AddIcon className='globalAddContent' />
              </Avatar>
              <Typography onMouseEnter={handleHover2} onMouseLeave={handleHover2} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                ADD ITEMS
              </Typography>
            </Box>
          </Box>
          <Box className='cust_Type_DC_Installation_Removelfee_Additional_Tax_Box_Third'>
            <Box onClick={() => setIsDrawerOpen(true)} sx={{ width: '40px', height: '20px', paddingTop: '0px' }}>
              <Fab className='graybutton' aria-label='back'>
                <CircleIcon sx={{ fontSize: '12px' }} />
                <CircleIcon sx={{ fontSize: '12px' }} />
                <CircleIcon sx={{ fontSize: '12px' }} />
              </Fab>
            </Box>
          </Box>
        </Box>
      </Box>

      <hr />

      <Box>
        <Grid container direction='row' spacing={0} className=''>
          <Grid xs={9} md={9}>
            <div className='boldheadingText Coverings_Subtotal_Proposal_Details_Page button_top_space'>COVERINGS SUBTOTAL : </div>
          </Grid>
          <Grid xs={3} md={3}>
            <span className='boldheadingText Coverings_Subtotal_Proposal_Details_Page price button_top_space'>{ProposalRetailPrice ? '$' + parseFloat(ProposalRetailPrice).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
          </Grid>
        </Grid>
        {Accessoriescharge.length > 0 ? (
          <Grid container direction='row' spacing={0} className='section_row_1st'>
            <Grid xs={9} md={9}>
              <div className='rowTotalforproposal'>Accessories : </div>
              {Accessoriescharge.map((accessory, index) => {
                return <div className='subAccessoriesAndServices'>{accessory.charge_description}</div>;
              })}
            </Grid>

            <Grid xs={3} md={3}>
              <div className='rowTotalforproposal'>&nbsp;</div>
              {Accessoriescharge.map((accessory, index) => {
                return <div className='rowTotalforproposalforAccandserv'>${parseFloat(accessory.charge).toFixed(2)}</div>;
              })}
            </Grid>
          </Grid>
        ) : (
          ''
        )}

        <Grid container direction='row' spacing={0} className='section_row_1st'>
          <Grid xs={9} md={9}>
            <div className='rowTotalforproposal'>Installation : </div>
          </Grid>
          <Grid xs={3} md={3}>
            <span className='rowTotalforproposal price '> {InstallationCost ? '$' + parseFloat(InstallationCost).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
          </Grid>
        </Grid>

        <Grid container direction='row' spacing={0} className='section_row_1st'>
          <Grid xs={9} md={9}>
            <div className='rowTotalforproposal'>Discount ({Discount}%) : </div>
          </Grid>
          <Grid xs={3} md={3}>
            <span className='rowTotalforproposal price'>{DiscountDollar ? '- $' + parseFloat(DiscountDollar).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
          </Grid>
        </Grid>

        {Servicescharge.length > 0 ? (
          <Grid container direction='row' spacing={0} className='section_row_1st'>
            <Grid xs={9} md={9}>
              <div className='rowTotalforproposal'>Services : </div>
              {Servicescharge.map((service, index) => {
                return <div className='subAccessoriesAndServices'>{service.charge_description}</div>;
              })}
            </Grid>
            <Grid xs={3} md={3}>
              <div className='rowTotalforproposal'>&nbsp;</div>
              {Servicescharge.map((service, index) => {
                return <div className='rowTotalforproposalforAccandserv'>${parseFloat(service.charge).toFixed(2)}</div>;
              })}
            </Grid>
          </Grid>
        ) : (
          ''
        )}

        <Grid container direction='row' spacing={0} className='section_row_1st'>
          <Grid xs={9} md={9}>
            <div className='rowTotalforproposal'>Tax : </div>
          </Grid>
          <Grid xs={3} md={3}>
            <span className='rowTotalforproposal price'>{Tax ? '$' + parseFloat(Tax).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
          </Grid>
        </Grid>

        <Grid container direction='row' spacing={0} className='section_row_1st'>
          <Grid xs={9} md={9}>
            <div className='boldheadingText Coverings_Subtotal_Proposal_Details_Page button_bottom_space'>PROPOSAL TOTAL :</div>
          </Grid>
          <Grid xs={3} md={3}>
            <span className='boldheadingText Coverings_Subtotal_Proposal_Details_Page price'> {TotalCost ? '$' + parseFloat(TotalCost).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
          </Grid>
        </Grid>
      </Box>

      <hr className='' />

      <Drawer
        classes={{ paper: classes.paper }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        PaperProps={{
          sx: { width: '90%' },
        }}
        anchor='right'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(true)}
        className='drawer_Header_page'
      >
        <PriceDrawer func={fetchdata} data={extraCharges} duplicateproposalID={params.id} />
      </Drawer>

      <Drawer
        PaperProps={{
          sx: { width: '90%' },
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        classes={{ paper: classes.paper }}
        anchor='right'
        open={isDrawerOpenSecond}
        onClose={() => setIsDrawerOpenSecond(true)}
        className='drawer_Header_page'
      >
        <InstallAccessoriesDrawer
          func={fetchDrawerData}
          data={{
            Labels: Labels,
            ProposalID: location.state.proposalID,
            selectedwindows: selectedWindows,
          }}
        />
      </Drawer>

      <Drawer
        PaperProps={{
          sx: { width: '90%' },
        }}
        classes={{ paper: classes.paper }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        anchor='right'
        open={isDrawerOpenforShareProposal}
        onClose={() => setIsDrawerOpenforShareProposal(true)}
        className='drawer_Header_page'
      >
        <ShareProposal
          func={fetchDeliveryDrawerData}
          data={{
            Labels: Labels,
            Proposaldetails: location.state,
            shareablelink: shareablelink,
            route: 'shareproposal',
            content: content,
          }}
        />
      </Drawer>

      <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page '>
        <span style={{ paddingLeft: '22px' }} className='gray_button_span button_top_space' onClick={SaveProposal}>
          <Box className='globalIconMainBox'>
            <img src={SaveIcon} className={`secondary_icons_dimension ${isImageHovered3 ? 'hovered' : ''}`} />
            <Typography onMouseEnter={handleHover3} onMouseLeave={handleHover3} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
              {' '}
              SAVE PROPOSAL{' '}
            </Typography>
          </Box>
        </span>
      </Box>

      <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page '>
        <span style={{ paddingLeft: '22px' }} className='gray_button_span button_top_space' onClick={previewProposal}>
          <Box className='globalIconMainBox'>
            <img src={PreviewIcon} className={`secondary_icons_dimension ${isImageHovered4 ? 'hovered' : ''}`} />
            <Typography onMouseEnter={handleHover4} onMouseLeave={handleHover4} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
              {' '}
              PREVIEW PROPOSAL{' '}
            </Typography>
          </Box>
        </span>
      </Box>

      <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page'>
        <span className='button_span button_top_space'>
          <Box onClick={handleShareProposal} className='globalIconMainBox globalMarginLeftAddWindow'>
            <img className={`primary_icons_dimension ${isImageHovered5 ? 'hovered' : ''}`} src={ShareIcon} />
            <Typography onMouseEnter={handleHover5} onMouseLeave={handleHover5} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
              SHARE PROPOSAL
            </Typography>
          </Box>
        </span>
      </Box>

      {convertToOrder && !WindowwithQuick && remeasure && proposalAcceptanceStatus ? (
        <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page'>
          <span className='button_span button_top_space button_bottom_space' onClick={GetSalesOrderName}>
            <Box className='globalIconMainBox globalMarginLeftAddWindow'>
              <img className={`primary_icons_dimension ${isImageHovered6 ? 'hovered' : ''}`} src={CTSOIcon} />
              <Typography onMouseEnter={handleHover6} onMouseLeave={handleHover6} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                CONVERT TO SALES ORDER
              </Typography>
            </Box>
          </span>
        </Box>
      ) : (
        <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page'>
          <span className='button_span_disabled button_top_space button_bottom_space'>
            <Box className='globalIconMainBox globalMarginLeftAddWindow'>
              <img className='primary_icons_dimension_disable' src={CTSOIcon} />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                CONVERT TO SALES ORDER
              </Typography>
            </Box>
          </span>
        </Box>
      )}
      {editDisappear ? <Box display='flex' alignItems='center' justifyContent='center' className='margin_Bottom_duplicate_Proposal_Details'></Box> : null}

      {/* {!isLoading ? (
        <PDFDownloadLink
          style={{ textDecoration: "none" }}
          document={<CTW data={commonData} />}
          fileName={filename}
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : uploadFile(blob)
          }
        </PDFDownloadLink>
      ) : (
        ""
      )} */}
    </>
  );
}
