/** @format */

import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import CONFIG from '../../config/config.js';
import Drawer from '@mui/material/Drawer';
import EditProposalName from './EditProposalName';
import { makeStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import io from 'socket.io-client';

import LongText from '../LongText.jsx';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';

const ENDPOINT = CONFIG.ENDPOINT;
const TurquoiseTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0ca8b0',
    color: '#ffffff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#0ca8b0',
      border: '1px solid #0ca8b0',
    },
  },
}));
const TurquoiseTooltipforSalesRep = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#808080',
    color: '#ffffff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#808080',
      border: '1px solid #808080',
    },
  },
}));
const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  paper: {
    width: '90%',
    background: 'rgba(69, 157, 166, 70%)  !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
const ShowProposalName = (props) => {
  let LastName = '';
  let CityName = '';
  let PropsProposalID = '';
  let PropsProposalName = '';
  let PropsCustomerID = '';
  let PropsAddressID = '';
  let PropsProposalAcceptanceStatus = '';
  if (props.proposaldetails.proposal_id === undefined) {
  } else {
    LastName = props.proposaldetails.customerdata.last_name;
    CityName = props.proposaldetails.customerdata.city;
    PropsProposalID = props.proposaldetails.proposal_id;
    PropsProposalName = props.proposaldetails.proposal_name;
    PropsCustomerID = props.proposaldetails.customerdata.customer_id;
    PropsAddressID = props.proposaldetails.customerdata.address_id;
    PropsProposalAcceptanceStatus = props.proposaldetails.proposal_acceptance_status;
  }

  const [proposaldetails, setProposalDetails] = useState('');
  const [proposalID, setProposalID] = React.useState(PropsProposalID);
  const [proposalName, setProposalName] = React.useState(PropsProposalName);
  const [customerID, setCustomerID] = useState(PropsCustomerID);
  const [addressID, setAddressID] = useState(PropsAddressID);
  const [isProposalDrawerOpen, setIsProposalDrawerOpen] = useState(false);
  const token = sessionStorage.getItem('token');
  const classes = useStyles();
  const navigate = useNavigate();
  const [palertmessage, setPAlertMessage] = useState('');
  const [pclasstype, setPClassType] = useState('');
  const [open, setOpen] = React.useState(false);
  const vertical = 'top';
  const horizontal = 'center';
  const [proposalAcceptanceStatus, setProposalAcceptanceStatus] = useState(PropsProposalAcceptanceStatus);
  const iconclasses = makeStyles(iconStyles)();

  // Error Handling
  const [ProposalByProposalIdErrorAlert, setProposalByProposalIdErrorAlert] = useState(false);
  const [ProposalByProposalIdErrorMsg, setProposalByProposalIdErrorMsg] = useState('');

  useEffect(() => {
    const socket = io(ENDPOINT);

    socket.on('FromAPI', (data) => {
      ProposalByProposal();
      if (proposalID === data.ProposalStatus.proposal_id) {
        setProposalAcceptanceStatus(data.ProposalStatus.status);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const ProposalNameUpdateDrawer = (name, drawerstatus, message, status) => {
    setIsProposalDrawerOpen(drawerstatus);
    setPAlertMessage(message);
    if (message === 'Proposal name is updated successfully!') {
      ProposalByProposal();
      setProposalName(name);
      setOpen(true);
      setProposalName(name);
      setPClassType('Success2 mainAlert');
    } else if (message === 'Proposal name already exists!.') {
      setOpen(true);
      setPClassType('Error mainAlert');
    } else {
      setOpen(false);
      setPClassType('');
    }
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const ProposalByProposal = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };
    // Created Proposal will be added to the database through CreateProposal API
    await fetch(CONFIG.API_URL + `/pm/ProposalByProposalId/${props.proposaldetails.proposal_id}`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setProposalName(data.proposal.proposal_name);
          setProposalID(data.proposal.proposal_id);
          setProposalDetails({
            proposal_id: data.proposal.proposal_id,
            proposal_name: data.proposal.proposal_name,
          });
          if (data.proposal.adobe_sign_status === 'ACCEPTED_BY_SALES_REP') {
            setProposalAcceptanceStatus('ACCEPTED_BY_SALES_REP');
          } else if (data.proposal.adobe_sign_status === 'ACCEPTED_BY_CUSTOMER') {
            setProposalAcceptanceStatus('ACCEPTED_BY_CUSTOMER');
          } else if (data.proposal.adobe_sign_status === 'OUT_FOR_ACCEPTANCE') {
            setProposalAcceptanceStatus('OUT_FOR_ACCEPTANCE');
          } else {
            setProposalAcceptanceStatus('');
          }
        } else {
          setProposalByProposalIdErrorMsg(data.error_message);
          setProposalByProposalIdErrorAlert(true);
        }
      });
  };

  useEffect(() => {
    ProposalByProposal();

    if (props.proposaldetails.UpdateProposalNameStatus === true) {
    }
  }, [props.proposaldetails]);

  function iconStyles() {
    return {
      salesrepIcon: {
        paddingTop: 5,
        color: '#808080',
      },
      customerIcon: {
        paddingTop: 5,
        color: '#0CA8B0',
      },
      proposalsentIcon: {
        paddingTop: 5,
        color: '#0CA8B0',
      },
    };
  }

  return (
    <div align='center'>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' anchorOrigin={{ vertical, horizontal }} onClose={() => setProposalByProposalIdErrorAlert(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img
                onClick={() => {
                  setProposalByProposalIdErrorAlert(false);
                }}
                src={RedDrawerCancelIcon}
                alt='CANCELICONRED'
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={RedExclamationIcon} alt='CONFIRMICONRED' className='primary_icons_dimension alertImageMargin' />
              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{ProposalByProposalIdErrorMsg}</div>
            </div>
          </Alert>
        </Snackbar>

        <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setOpen(false)}>
          {pclasstype === 'Success2 mainAlert' ? (
            <Alert className={pclasstype} variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img alt='CANCELICON' onClick={handleSuccessClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img alt='CONFIRMICON' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />

                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{palertmessage}</div>
              </div>
            </Alert>
          ) : (
            <Alert className={pclasstype} variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img alt='CANCELICONREDA' onClick={handleSuccessClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img alt='CONFIRMICONREDA' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />

                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{palertmessage}</div>
              </div>
            </Alert>
          )}
        </Snackbar>
      </Stack>
      <div className='heading-box-1'>
        <h2 className={`${!props.disabled ? 'clickable_header' : null} all_pages_h2_header`} onClick={() => (!props.disabled ? setIsProposalDrawerOpen(true) : null)}>
          {proposalAcceptanceStatus === 'OUT_FOR_ACCEPTANCE' ? (
            <TurquoiseTooltip title='Proposal has sent' placement='top' TransitionComponent={Zoom} arrow>
              <StarOutlineIcon className={iconclasses.proposalsentIcon} />
            </TurquoiseTooltip>
          ) : null}
          {proposalAcceptanceStatus === 'ACCEPTED_BY_SALES_REP' ? (
            <TurquoiseTooltipforSalesRep title='Sales Rep has accepted on behalf of Customer' placement='top' TransitionComponent={Zoom} arrow>
              <StarIcon className={iconclasses.salesrepIcon} />
            </TurquoiseTooltipforSalesRep>
          ) : null}
          {proposalAcceptanceStatus === 'ACCEPTED_BY_CUSTOMER' ? (
            <TurquoiseTooltip title='All Good! Customer has accepted Proposal' placement='top' TransitionComponent={Zoom} arrow>
              <StarIcon className={iconclasses.customerIcon} />
            </TurquoiseTooltip>
          ) : null}{' '}
          <LongText>{proposalName}</LongText>
        </h2>

        <Drawer
          PaperProps={{
            sx: { width: '90%' },
          }}
          classes={{ paper: classes.paper }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          anchor='right'
          open={isProposalDrawerOpen}
          onClose={() => setIsProposalDrawerOpen(true)}
          sx={{ zIndex: '100000' }}
        >
          <EditProposalName proposalname={proposalName} proposaldetails={proposaldetails} func={ProposalNameUpdateDrawer} />
        </Drawer>

        <h3
          className='clickable_header_1 all_pages_h2_header button_top_space common_line_height_css'
          style={{ textTransform: 'capitalize' }}
          onClick={() => {
            navigate(`/tbm/ps/customerdetails`, {
              state: { Custdata: { custid: customerID, Addressid: addressID } },
            });
          }}
        >
          <LongText>{`${LastName} | ${CityName}`}</LongText>
        </h3>
      </div>
    </div>
  );
};

export default ShowProposalName;
