/** @format */

import React, { useState, useEffect } from 'react';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import CONFIG from '../../config/config.js';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import fracty from 'fracty';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import DeleteWindowIcon from '../../assets/icons/primary/window-delete.svg';
import DeleteWindowHovIcon from '../../assets/icons/primary/WithHovIcon/window-delete-hov.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import DrawerConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '../../assets/icons/misc/alert.svg';
import LongText from '../LongText';
import { TablePagination } from '@mui/material';
import InformationAlerts from '../Shared/InfoAlerts.jsx';

const TurquoiseTooltipforNotes = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#faf3dd',
    color: '#000000',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 300,
    maxHeight: 300,
    textAlign: 'left',
    align: 'left',
    border: '1px solid #000000',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#f2f3f2',
      border: '1px solid #000000',
    },
    top: '100%', // Position the arrow at the bottom of the tooltip box
    right: '100', // Position the arrow at the right side of the tooltip box
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
const checkedrow = [];

export default function DeleteWindow() {
  const outerTheme = createTheme({
    palette: {
      primary: {
        main: '#333333',
      },
    },
  });
  const [checked, setChecked] = useState([]);
  const [WindowSetData, setWindowSetData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);
  const [emptyWindowSetError, setEmptyWindowSetError] = useState(false);
  const [DeleteProposalAlert, setDeleteProposalAlert] = useState(false);
  const [DeleteSuccessAlert, setDeleteSuccessAlert] = useState(false);
  const [deleteDisable, setDeleteDisable] = useState(false);
  const [deleteItem, setDeleteItem] = useState(false);
  const [selected, setSelected] = useState([]);
  const [chooseWindowName, setChooseWindowName] = useState('');
  const [checkvalidate, setCheckValidation] = useState(true);
  const [notesContent, setnotesContent] = useState([]);

  // error handling
  const [WindowSetApiErr, setWindowSetApiErr] = useState(false);
  const [WindowSetApiErrMsg, setWindowSetApiErrMsg] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  let commonData = {};
  const vertical = 'top';
  const horizontal = 'center';
  let dialof_window_name;

  const enableDeleteButton = () => {
    setDeleteDisable(!deleteDisable);
  };

  const handleClose = () => {
    setDeleteItem(false);
  };

  const params = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  let locationState = [];
  var selectedrow = [];
  let WindowSetName = '';
  let LastName = '';
  let CityName = '';

  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    locationState = location.state;
    selectedrow = locationState.totalwindows;
    WindowSetName = locationState.customerdata.window_set_name;
    LastName = locationState.customerdata.last_name;
    CityName = locationState.customerdata.city;
  }

  var updatedrow = [];
  selectedrow.forEach((element) => {
    if (element.covering_status !== 'not_consider') updatedrow.push(element);
  });

  useEffect(() => {
    {
      window.scrollTo(0, 0);
    }
    getVerified();
    getWindowSet();
  }, []);

  const rows = updatedrow;
  let jsonData = new Array();

  let ShowWindowName = '';

  const token = sessionStorage.getItem('token');

  const getWindowSet = async () => {
    await fetch(`${CONFIG.API_URL}/wm/window-set/${params.id}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setnotesContent(data.notes);
        } else {
          setWindowSetApiErrMsg(data.error_message);
          setWindowSetApiErr(true);
        }
      });
  };

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const handleChange = (event, i, row) => {
    if (event.target.checked) {
      checkedrow.push(row);
      setEmptyWindowSetError(checkedrow.length === updatedrow.length);
      setSelected(checkedrow);
      checkedrow.map((element, i) => {
        dialof_window_name = element.window_name;

        jsonData.push(' ' + dialof_window_name);
        ShowWindowName = jsonData.toString();

        setChooseWindowName(ShowWindowName);
      });
    } else {
      const selectedIndex = selected.indexOf(row);

      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
        setSelected(newSelected);

        // remove windowname
        newSelected.map((element, i) => {
          dialof_window_name = element.window_name;

          jsonData.push(' ' + dialof_window_name);
          ShowWindowName = jsonData.toString();
          setChooseWindowName(ShowWindowName);
        });
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));

        setSelected(newSelected);
        // remove windowname
        newSelected.map((element, i) => {
          dialof_window_name = element.window_name;

          jsonData.push(' ' + dialof_window_name);
          ShowWindowName = jsonData.toString();
          setChooseWindowName(ShowWindowName);
        });
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        setSelected(newSelected);

        // remove windowname
        newSelected.map((element, i) => {
          dialof_window_name = element.window_name;

          jsonData.push(' ' + dialof_window_name);
          ShowWindowName = jsonData.toString();
          setChooseWindowName(ShowWindowName);
        });
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        setSelected(newSelected);

        // remove windowname
        newSelected.map((element, i) => {
          dialof_window_name = element.window_name;

          jsonData.push(' ' + dialof_window_name);
          ShowWindowName = jsonData.toString();
          setChooseWindowName(ShowWindowName);
        });
      }
    }
  };

  let checkedrowdata = [];
  let selectedwindowsIDs = [];
  selectedwindowsIDs = locationState.selectedwindows;
  checkedrowdata = selectedwindowsIDs;
  let totalrowdata = [];
  totalrowdata = locationState.totalselectedwindows;
  let windowsetdata = [];
  windowsetdata = locationState.customerdata;
  commonData['AppliedCoveringsWindows'] = locationState.selectedwindows;
  commonData['path'] = locationState.path;
  commonData['windowsetdata'] = windowsetdata;
  commonData['proposalID'] = locationState.proposalID;

  const [ExistingSku, setExistingSku] = useState();
  const [BuildSku, setBuildSku] = useState();
  const [MountSku, setMountSku] = useState();
  const [LadderSku, setLadderSku] = useState();
  const [WindowDepthSku, setWindowDepthSku] = useState('S500470');
  const [HeadrailSku, setHeadrailSku] = useState('S500190');
  const [ObstructionsSku, setObstructionsSku] = useState('S500540');

  const additional_sku_data = {
    ExistingSku: ExistingSku,
    BuildSku: BuildSku,
    MountSku: MountSku,
    LadderSku: LadderSku,
    WindowDepthSku: WindowDepthSku,
    HeadrailSku: HeadrailSku,
    ObstructionsSku: ObstructionsSku,
  };

  // table head start

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='table-headerppsw sticky-column'>
            <span style={{ marginLeft: '30px' }}>#</span> <span style={{ marginLeft: '17px' }}>Window Name</span>
          </TableCell>
          <TableCell className='table-headersw3'>Size</TableCell>
          <TableCell className='table-headersw3'>Bld</TableCell>
          <TableCell className='table-headersw3'>Surface</TableCell>
          <TableCell className='table-headersw3'>Ladder</TableCell>
          <TableCell className='table-headersw3 ellipsis2'>Ex. Cover</TableCell>
          <TableCell className='table-header4'>Note</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  // table head end

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  const NotesCustomerDetails = {};

  const [deleteWindowConfirm, setDeleteWindowConfirm] = useState(false);

  const deleteWindow = async () => {
    const options = {
      method: 'DELETE',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        windows: selected.map(({ window_id }) => window_id),
        window_set_id: locationState.customerdata.window_set_id,
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/wm/DeleteWindow`, options);
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error_message === "Window can't be deleted due to window set is used for existing proposal!") {
        setDeleteWindowConfirm(false);
        setDeleteProposalAlert(true);
      }
      return;
    }
    setDeleteWindowConfirm(false);
    setDeleteSuccessAlert(true);
    setTimeout(() => {
      navigate(`/tbm/ps/windowset/${locationState.customerdata.window_set_id}`, {
        state: {
          coveringdetails: locationState.coveringdetails,
          addition_sku_data: undefined,
          data: NotesCustomerDetails,
          windowSetId: locationState.customerdata.window_set_id,
        },
      });
    }, [3000]);
  };

  return (
    <>
      <ThemeProvider theme={outerTheme}>
        <div align='center'>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={WindowSetApiErr} anchorOrigin={{ vertical, horizontal }} onClose={() => setWindowSetApiErr(false)}>
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    alt='CANCELICONWSA'
                    onClick={() => {
                      setWindowSetApiErr(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img alt='CONFIRMICONWSA' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{WindowSetApiErrMsg}</div>
                </div>
              </Alert>
            </Snackbar>

            <Snackbar className='snackbar_bar_postition' open={deleteWindowConfirm} anchorOrigin={{ vertical, horizontal }} onClose={() => setDeleteWindowConfirm(false)}>
              <Alert className='Success mainAlert' variant='filled' icon={false}>
                <div className='alertCancel' align='right'>
                  <img
                    alt='CANCELICONWD'
                    onClick={() => {
                      setDeleteWindowConfirm(false);
                    }}
                    src={DrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>

                <div className='alertMainNew'>
                  <div align='left' style={{ paddingTop: 37 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'Center',
                      }}
                    >
                      <Box className='alertCantinueButton'>
                        <img alt='CONFIRMICONWD' src={DrawerAlertIcon} className='primary_icons_dimension' />
                      </Box>
                    </Box>

                    <div
                      className='alertTextAlign'
                      style={{
                        paddingTop: 20,
                        fontSize: '20px',
                        fontFamily: 'RobotoRegular',
                      }}
                    >
                      Are you sure you want to Remove the window ?
                    </div>
                  </div>

                  <div
                    align='left'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '20px 10px 0px 24px',
                      justifyContent: 'center',
                      alignItems: 'start',
                      lineHeight: '24px',
                    }}
                  >
                    <Box
                      onClick={() => {
                        deleteWindow();
                      }}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'Center',
                        cursor: 'pointer',
                        marginBottom: '20px',
                      }}
                    >
                      <Box
                        onClick={() => {
                          deleteWindow();
                        }}
                        className='alertCantinueButton'
                      >
                        <img alt='CONTINUE' src={DrawerConinueIcon} className='primary_icons_dimension' />
                      </Box>
                      <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                        CONTINUE
                      </Typography>
                    </Box>

                    <Box
                      onClick={() => {
                        setDeleteWindowConfirm(false);
                      }}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'Center',
                        cursor: 'pointer',
                        paddingBottom: '16px',
                        paddingLeft: '15px',
                      }}
                    >
                      <Box className='globalIconMainBox'>
                        <Box className='iconSecondaryButtonGlobalForDrawer'>
                          <img alt='CANCELICONWDA' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                        </Box>
                        <Typography className='globalTextForDrawer'>CANCEL</Typography>
                      </Box>
                    </Box>
                  </div>
                </div>
              </Alert>
            </Snackbar>
          </Stack>

          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={emptyWindowSetError} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setEmptyWindowSetError(false)}>
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    alt='CANCELICONWSEA'
                    onClick={() => {
                      setEmptyWindowSetError(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img alt='CONFIRMICONWSEA' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Window Set can't be empty.</div>
                </div>
              </Alert>
            </Snackbar>

            <Snackbar className='snackbar_bar_postition' open={DeleteProposalAlert} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setDeleteProposalAlert(false)}>
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    alt='CANCELICONWDIP'
                    onClick={() => {
                      setDeleteProposalAlert(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img alt='CONFIRMICONWDIP' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Window can't be deleted due to window set is used for existing proposal!</div>
                </div>
              </Alert>
            </Snackbar>

            <Snackbar className='snackbar_bar_postition' open={DeleteSuccessAlert} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setDeleteSuccessAlert(false)}>
              <Alert className='Success2 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    alt='CANCELICONWDS'
                    onClick={() => {
                      setDeleteSuccessAlert(false);
                    }}
                    src={DrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img alt='CONFIRMICONWDS' src={DrawerAlertIcon} className='primary_icons_dimension alertImageMargin' />

                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Windows deleted successfully!</div>
                </div>
              </Alert>
            </Snackbar>

            <Snackbar className='snackbar_bar_postition' open={deleteItem} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
              <Alert className='Success' variant='filled' icon={false} severity='warning'>
                <div className='alertMsgCancelICon' align='right'>
                  <img alt='CANCELICONC' onClick={handleClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                </div>

                <div align='center' style={{ paddingTop: 20 }}>
                  <img alt='CONFIRMICONC' src={DrawerAlertIcon} className='primary_icons_dimension' />
                  <div align='center' style={{ paddingLeft: '1%', paddingRight: '1%' }}>
                    <span style={{ fontSize: '20px' }}> {'Are you sure you want to remove'} </span>
                    <br />
                    <div style={{ fontSize: '20px' }}>
                      {checkedrow.length === 1 ? `${checkedrow.length} Window` : `${checkedrow.length} Windows`}
                      <br />
                      {chooseWindowName}
                    </div>
                    <br />
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Button
                        className='small_buttons'
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        Yes
                      </Button>{' '}
                      <Button className='small_buttons' onClick={handleClose}>
                        No
                      </Button>
                    </div>
                  </div>
                </div>
              </Alert>
            </Snackbar>
          </Stack>
        </div>

        <div className='heading-box-1'>
          <h2 style={{ textAlign: 'center' }} className='clickable_header all_pages_h2_header'>
            {WindowSetName}
          </h2>

          <h3
            className='clickable_header_1 all_pages_h2_header center button_top_space common_line_height_css'
            style={{
              textTransform: 'capitalize',
              textAlign: 'center !important',
            }}
            onClick={() => {
              navigate(`/tbm/ps/customerdetails`, {
                state: {
                  Custdata: {
                    custid: locationState.customerdata.customer_id,
                    Addressid: locationState.customerdata.address_id,
                  },
                },
              });
            }}
          >
            <LongText content={`${LastName} | ${CityName}`} />
          </h3>
        </div>
        <div className='button_top_space' align='left' style={{ marginBottom: '0px' }}>
          {notesContent.map((ele, index) => {
            return (
              <Box className='' sx={{ display: 'flex' }}>
                {ele.note_additional_data.hidden === true ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : ''}{' '}
                <Box>
                  <Typography className=''>
                    {' '}
                    <span
                      style={{
                        color: '#008593',
                        cursor: 'pointer',
                        fontWeight: 'bold',
                      }}
                    >
                      Note #{index + 1} :
                    </span>{' '}
                    {ele.note_content}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </div>

        <hr className='button_top_space button_bottom_space' />

        <Box className='proposal_table_main_box'>
          <Box className='proposal_table_sub_box'>
            {/* table start  */}

            <Box className='table-box'>
              <TableContainer sx={{ borderBottom: '1px solid #D6D6D6', textAlign: 'left' }} className='table_padding_bottom'>
                <Table className='windowset-table-dimension' aria-labelledby='tableTitle' size='small'>
                  <EnhancedTableHead rowCount={rows.length} />
                  <TableBody>
                    {(rowsPerPage > -1 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((window) => (
                      <TableRow
                        sx={{
                          opacity: window.in_proposal ? 0.4 : 1,
                          color: '#333',
                        }}
                        hover
                        role='checkbox'
                        tabIndex={-1}
                        key={window.window_id}
                        style={{ padding: 0 }}
                      >
                        <TableCell className='table-content-apply-coveringo sticky-columnws-1' align='left'>
                          <Box
                            className='ellipsis2'
                            sx={{
                              width: '100% !important',
                              display: 'flex',
                            }}
                          >
                            <Checkbox
                              sx={{
                                zoom: 0.8,
                                paddingTop: '1px',
                                color: '#333',
                                '&.Mui-disabled': {
                                  color: '#333',
                                },
                              }}
                              disabled={window.in_proposal}
                              checked={checked[WindowSetData.indexOf(window) + 1]}
                              onChange={(e) => {
                                handleChange(e, WindowSetData.indexOf(window) + 1, window);
                              }}
                              onClick={enableDeleteButton}
                            />
                            <Box
                              sx={{
                                width: '20px',
                              }}
                            >
                              {window.covering_status !== 'not_consider' ? <>{rows.indexOf(window) + 1}</> : ''}
                            </Box>
                            &nbsp;&nbsp;
                            <LongText>{window.window_name}</LongText>
                          </Box>
                        </TableCell>

                        <TableCell className='table-content ellipsis2' align='left'>
                          {fracty(window.window_width)}" x {fracty(window.window_height)}"{' '}
                          <span className={window.measurement_type.replace(/ /g, '')}>
                            {window.measurement_type === 'Quick'
                              ? '(' + 'QK' + ')'
                              : window.measurement_type === 'Shutters'
                              ? '(' + 'SH' + ')'
                              : window.measurement_type === 'Blinds'
                              ? '(' + 'BL' + ')'
                              : '(' +
                                window.measurement_type
                                  .split(' ')
                                  .map((word) => word[0])
                                  .join('') +
                                ')'}
                          </span>
                        </TableCell>

                        <TableCell className='table-content' align='left'>
                          {window.build_type}
                        </TableCell>
                        <TableCell className='table-content' align='left'>
                          {window.mount_surface}
                        </TableCell>
                        <TableCell className='table-content' align='left'>
                          {!isNaN(window.ladder) ? window.ladder + "'" : window.ladder}
                        </TableCell>
                        <TableCell className='table-content' align='left'>
                          {window.existing_covering}
                        </TableCell>
                        <TableCell className='table-content' align='left'>
                          {window.notes.length > 0 && (
                            <TurquoiseTooltipforNotes
                              title={
                                <Typography>
                                  {window.notes.map((note, index) => (
                                    <div key={note.id}>
                                      {index > 0 && <br />}
                                      <b>Note:</b> {note.content}
                                    </div>
                                  ))}
                                </Typography>
                              }
                              placement='bottom-end'
                              TransitionComponent={Zoom}
                              arrow
                            >
                              <Link
                                className='link'
                                to='/tbm/ps/window'
                                state={{
                                  data: windowsetdata,
                                  row_id: window.window_id,
                                  customer_id: windowsetdata.customer_id,
                                  additional_sku_data: additional_sku_data,
                                }}
                              >
                                {window.notes_count === '0' ? <></> : <span style={{ paddingLeft: '5px' }}>{window.notes_count}</span>}
                              </Link>
                            </TurquoiseTooltipforNotes>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                sx={{
                  width: '100%',
                  minWidth: 5,
                  mx: 0,
                  px: 0,
                  textAlign: 'center',
                }}
                rowsPerPageOptions={[5, 10, 20, 25, { value: -1, label: 'All' }]}
                component='div'
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(_, newPageprp) => setPage(newPageprp)}
                onRowsPerPageChange={({ target }) => {
                  setRowsPerPage(target.value);
                  setPage(0);
                }}
              />
            </Box>

            {/* table end  */}
          </Box>
        </Box>

        <InformationAlerts icon={WarningIcon} alerts={[{ key: 'delete-windows', text: 'Window Sets and Windows that have associated Proposals cannot be deleted', active: true }]} />

        {checkedrow.length > 0 && checkedrow.length !== updatedrow.length && checkvalidate ? (
          <Box display='flex' alignItems='center' justifyContent='center'>
            <span className='button_span button_top_space button_bottom_space' onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
              <Box onClick={() => setDeleteWindowConfirm(true)} className='globalIconMainBox globalMarginLeftAddWindow'>
                {isHovered ? <img alt='REMOVEWINDOWSH' className='primary_icons_dimension_1' src={DeleteWindowHovIcon} /> : <img alt='REMOVEWINDOWS' className='primary_icons_dimension_1' src={DeleteWindowIcon} />}

                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                  REMOVE WINDOWS
                </Typography>
              </Box>
            </span>
          </Box>
        ) : (
          <Box display='flex' alignItems='center' justifyContent='center'>
            <span className='button_span button_top_space button_bottom_space'>
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                <img alt=' REMOVEWINDOWSD' src={DeleteWindowIcon} className='primary_icons_dimension_disable' />

                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                  REMOVE WINDOWS
                </Typography>
              </Box>
            </span>
          </Box>
        )}

        <Box display='flex' alignItems='center' justifyContent='center' className='apply_Changes_Box'>
          <span
            style={{ marginLeft: '19px' }}
            className='gray_button_span'
            onClick={() => {
              navigate(-1);
            }}
          >
            <Box className='globalIconMainBox'>
              <img alt='CANCEL' src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered2 ? 'hovered' : ''}`} />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover2} onMouseLeave={handleHover2}>
                CANCEL
              </Typography>
            </Box>
          </span>
        </Box>
      </ThemeProvider>
    </>
  );
}
