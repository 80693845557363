import React, { forwardRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import CONFIG from '../../config/config.js';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import moment from 'moment/moment';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import EditCustomerName from '../Customer/EditCustomerName';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { createTheme, ThemeProvider } from '@mui/material';
import ChangeAddress from '../../assets/icons/secondary/2nd-address.svg';
import NotesDrawer from '../Proposals/NotesDrawer';
import NoteMiscIcon from '../../assets/icons/misc/misc-note.svg';
import EditMiscIcon from '../../assets/icons/misc/misc-edit.svg';
import PhoneMiscIcon from '../../assets/icons/misc/misc-phone.svg';
import LocationMiscIcon from '../../assets/icons/misc/misc-address.svg';
import EmailMiscIcon from '../../assets/icons/misc/misc-email.svg';
import 'moment-timezone';

import WorkOrderDetails from './WorkOrderDetails';
import WorkOrderProvider, { WorkOrderContext } from '../Shared/WorkOrderProvider';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const theme = createTheme({
  palette: {
    border: {
      main: '1px solid #000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#FAC41E',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "green", // date picker textfield style
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#008593 !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'white', // disable date
            color: '#E9E9E9',
          },
        },
        dayWithMargin: {
          backgroundColor: 'white', // not selected date
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "blue" //calender icon & month change arrow icon style
        },
      },
    },
  },
});

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  paper: {
    background: 'rgba(69, 157, 166, 70%)  !important',
  },
  customBadge: {
    backgroundColor: '#FAC41E',
    border: '1px solid black',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
  StaticDatePicker: {
    height: '360px',
  },
  DatePicker: {
    height: '360px',
  },
});

const SalesOrderDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { workOrderDetails } = useContext(WorkOrderContext);

  let Address_Id;
  let CustomerVal;
  let LnameVal;
  let FnameVal;
  let EmailVal;
  let StateNameVal;
  let cityVal;
  let ZipVal;
  let AddressLineVal;
  let SalesOrderId;

  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    Address_Id = location.state.Custdata.Addressid;
    CustomerVal = location.state.Custdata.customerId;
    LnameVal = location.state.Custdata.LName;
    FnameVal = location.state.Custdata.Husband;
    EmailVal = location.state.Custdata.emailaddress;
    StateNameVal = location.state.Custdata.state;
    cityVal = location.state.Custdata.City;
    ZipVal = location.state.Custdata.zip;
    AddressLineVal = location.state.Custdata.Address1;
    SalesOrderId = location.state.Custdata.Sales_Order_Id;
  }

  const [WindowSetData, setWindowSetData] = useState([CONFIG.WindowsManagement.WindowSetLimit]);
  const [proposaldata, setProposalData] = useState([]);
  const [windowsetdatalength, setWindowSetDataLength] = useState([]);
  const [customer, setCustomerData] = useState([]);
  const [customerInfo, setCustomerInfo] = useState([]);
  const [PrimaryAddress, setPrimaryAddress] = useState([]);
  const [singleAddress, setSingleAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [emailLength, setemailLength] = useState();
  const [email, setEmail] = useState();
  const [emailAddress, setEmailAddress] = useState();
  const [Address, setAddress] = useState([]);
  const [City, setCustCity] = useState([]);
  const [fullname, setFullName] = useState();
  const [firstName, setFirstName] = useState('');
  const [wifename, setWifeName] = useState('');
  const [lastname, setLastName] = useState('');
  const [phoneno, setPhoneNo] = useState();
  const [AddtionalNumber, setAddtionalNumber] = useState();
  const [AnotherPhoneNumber, setAnotherPhoneNumber] = useState();
  const [PhoneNumberOne, setPhoneNumberOne] = useState();
  const [PhoneNumberTwo, setPhoneNumberTwo] = useState();
  const [viewwindowset, setViewWindowSet] = useState(true);
  const [senddatatonewWindow, setSendDatatoNewWindow] = useState('');
  const [alertmessage, setAlertMessage] = useState('');
  const [buttonNameFlag, setButtonNameFlag] = useState(false);
  const [showtabledata, setShowTableData] = useState(false);
  const [viewTableFlag, setViewTableFlag] = useState(false);
  const [viewButtonFlag, setViewButtonFlag] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [successmessage, setSuccessMessage] = useState('');
  const [classtype, setClassType] = useState('');
  const [customerId, setCustomerId] = useState(location.state.custid);
  const [salesOrderInfo, setSalesOrderInfo] = useState([]);

  // appointment module
  const [openDropDown, setOpenDropDown] = useState(true);
  const [showName, setShowName] = useState('');
  const [PhoneNumbers, setPhoneNumbers] = useState();
  const [SalesOrderData, setSalesOrderData] = useState([]);
  const [openCloseSalesOrder, setOpenCloseSalesOrder] = useState(false);
  const [openSelectTimeDuration, setOpenSelectTimeDuration] = useState(false);

  const [notesContent, setnotesContent] = useState([]);
  const [InstallData, setInstallData] = useState([]);
  const [InstallerData, setInstallerData] = useState([]);

  const [getSalesRepAppointment, setGetSalesRepAppointment] = useState([]);
  const [appointmentData, setAppointmentData] = useState(getSalesRepAppointment);
  const [fullyear, setFullYear] = useState([]);

  const [addressID, setAddressID] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    setViewTableFlag(false);
    getVerified();
    getCustomerDetails();
  }, []);

  const vertical = 'top';
  const horizontal = 'center';

  const token = sessionStorage.getItem('token');

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const getCustomerDetails = async () => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        customer_id: CustomerVal,
        address_id: Address_Id,
        f_name: FnameVal,
        l_name: LnameVal,
        email_id: EmailVal,
        state_name: StateNameVal,
        city: cityVal,
        zip: ZipVal,
        address_line: AddressLineVal,
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/Customer`, requestOptions);
    const data = await response.json();
    if (!data.error) {
      const { customer } = data;
      setnotesContent(data.customer_notes);
      setCustomerInfo(customer);
      setSingleAddress(customer);
      setSelectedAddress(customer);

      setemailLength(customer.email.length);
      setEmailAddress(customer.email);
      setEmail(customer.email_id);
      setPrimaryAddress(customer.another_address[0]);
      setPhoneNo(customer.mobile);
      setAddtionalNumber(customer.another_phone_numbers);

      setPhoneNumberOne(customer.another_phone_numbers[0]);
      setAnotherPhoneNumber(customer.another_phone_numbers.length);
      setPhoneNumberTwo(customer.another_phone_numbers[1]);
      setViewButtonFlag(true);
      setAddress(customer.another_address);

      setFirstName(customer.first_name);
      setWifeName(customer.wife);
      setLastName(customer.last_name);

      setAddressID(customer.address_id);

      setShowName(customer.first_name);
      setPhoneNumbers(customer.another_phone_numbers);

      setCustomerId(customer.customer_id);

      // If token is not valid, screen will redirect to Login page
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Unauthorized Please Login' },
        });
      }
      // If token is valid then data will pass
      else {
        if (data.customer_windowsets.length > 0) {
          setShowTableData(true);
          setViewWindowSet(false);
          setWindowSetData(data.customer_windowsets);
          setSalesOrderData(data.customer_sales_order_list);
          setWindowSetDataLength(data.customer_windowsets);
          setCustomerData(customer);

          setCustCity(customer.city);

          setProposalData(data.customer_proposal_list);
          setFullName(customer.first_name + ' ' + customer.last_name);
          setFirstName(customer.first_name);

          setWifeName(customer.wife);

          setLastName(customer.last_name);

          if (WindowSetData.length < 5) {
            setAlertMessage(false);
          } else {
            setAlertMessage(true);
          }
          if (WindowSetData.length === 0) {
            setButtonNameFlag(false);
            setViewTableFlag(false);
          } else {
            setButtonNameFlag(true);
            setViewTableFlag(true);
          }
          setSendDatatoNewWindow({
            showprompt: data.customer_windowsets.length,
            windowsetdata: data.customer_windowsets[0],
            rowlength: data.customer_windowsets.length,
          });
        } else {
          setFullName(customer.first_name + ' ' + customer.last_name);
          setCustomerData(customer);
          setWindowSetData(data.customer_windowsets);

          if (WindowSetData && location.state.ScheduleAppointmentMsg === 'Customer Created & Schedule') {
            setViewWindowSet(false);
            setOpenDropDown(false);
            setOpenSelectTimeDuration(true);
          } else if (WindowSetData) {
            setViewWindowSet(true);
          } else {
            setViewWindowSet(false);
          }
          setSendDatatoNewWindow({
            showprompt: 0,
            windowsetdata: customer,
            rowlength: 0,
          });
        }
      }
    }
  };

  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function normalize(phone) {
    phone = phone.replace(/[^\d]/g, '');
    return phone.length === 10 ? phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : null;
  }

  const classes = useStyles();

  const customerNameUpdateDrawer = (name, drawerstatus, message) => {
    setIsDrawerOpen(drawerstatus);
    setSuccessMessage(message);
    if (message === 'Customer name is updated successfully!') {
      setOpen(true);
      setClassType('Success mainAlert');
      setFullName(name);
    } else {
      setOpen(false);
      setClassType('');
    }
  };

  const [isImageHovered, setIsImageHovered] = useState(false);

  const handleHover = () => setIsImageHovered(!isImageHovered);

  const dateSold = moment(workOrderDetails.invoiceData.DateSold);
  const dateSoldMonth = dateSold.format('MMMM');
  const dateSoldDate = dateSold.format('DD');
  const dateSoldYear = dateSold.format('YYYY');

  const [editingNote, setEditingNote] = useState(false);
  const [noteDrawerDetails, setNoteDrawerDetails] = useState({});
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);

  const closeNotesDrawer = (alert) => {
    setNotesDrawerOpen(false);
    setEditingNote(false);
    setNoteDrawerDetails({});

    if (alert === 'Note added successfully!' || alert === 'Note updated successfully!') {
      // TODO: fetch notes again
      // getNotesByRefId();
    }
  };

  const openNotesDrawer = (note) => {
    setEditingNote(!!note);
    setNoteDrawerDetails({
      firstName,
      lastname,
      customerId: customerId,
      noteId: note ? note.id : null,
    });
    setNotesDrawerOpen(true);
  };

  return (
    <div style={{ paddingBottom: '10px' }}>
      <div align='center'>
        <Stack spacing={2} sx={{ paddingLeft: '20%' }}>
          <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setOpen(false)}>
            {classtype === 'Success' ? (
              <Alert className={classtype} variant='filled' icon={false}>
                <div className='alertCancel'>
                  <IconButton color='inherit' onClick={handleClose}>
                    <CancelRoundedIcon />
                  </IconButton>
                </div>
                <div className='alertMain'>
                  <CheckCircleIcon sx={{ fontSize: 40 }} />

                  <div align='center' style={{ paddingTop: 10 }}>
                    {successmessage}
                  </div>
                </div>
              </Alert>
            ) : (
              <Alert className={classtype} variant='filled' icon={false}>
                <div className='alertCancel'>
                  <IconButton color='inherit' onClick={handleClose}>
                    <CancelRoundedIcon />
                  </IconButton>
                </div>
                <div className='alertMain'>
                  <CheckCircleIcon sx={{ fontSize: 40 }} />
                  <div align='center' style={{ paddingTop: 10 }}>
                    {successmessage}
                  </div>
                </div>
              </Alert>
            )}
          </Snackbar>
        </Stack>
      </div>

      <ThemeProvider theme={theme}>
        <Container style={{ padding: 0 }} fixed>
          <Box className='customer_details'>
            <Box component='div' className='customer_details_margin'>
              {/* Customer Name */}
              {wifename || (lastname && fullname) ? (
                <Box className='Customer_name_box'>
                  <Box sx={{ marginBottom: '15px !important' }} className='Customer_fullname'>
                    {showName === 'NA' ? (wifename === 'NA' ? lastname : wifename + ' ' + lastname) : fullname}
                  </Box>
                  <Box className='edit_icon_customername'>
                    {showName ? (
                      <>
                        <img src={NoteMiscIcon} alt='NoteMiscIcon' onClick={() => NoteMiscIcon()} className='misc_icons_dimensions' />

                        <img
                          sx={{ marginTop: '10px !important' }}
                          alt='EditMiscIcon'
                          onClick={() => {
                            navigate('/tbm/ps/editcustomerfirstlastname', {
                              state: {
                                firstName: firstName,
                                lastname: lastname,
                                wifename: wifename,
                                customer_id: customer.customer_id,
                                address_id: addressID,
                              },
                            });
                          }}
                          src={EditMiscIcon}
                          className='misc_icons_dimensions marginLeftCustomerInfo'
                        />
                      </>
                    ) : (
                      <>
                        <img src={EditMiscIcon} className='misc_icons_dimensions' alt='EditMiscIcon' />
                      </>
                    )}
                  </Box>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ marginBottom: '15px !important' }} className='clickable_header'>
                    <span>
                      <Skeleton width={160} height={25} />
                    </span>
                  </Typography>
                  <span>
                    <img src={EditMiscIcon} className='misc_icons_dimensions' alt='EditMiscIcon' />
                  </span>
                </Box>
              )}
              <Drawer
                PaperProps={{
                  sx: { width: '90% !important' },
                }}
                classes={{ paper: classes.paper }}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop,
                  },
                }}
                anchor='right'
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(true)}
                sx={{ zIndex: '100000' }}
              >
                <EditCustomerName customerName={fullname} customerId={customerId} token={token} func={customerNameUpdateDrawer} />
              </Drawer>

              {/* notes  */}

              <Drawer
                classes={{ paper: classes.paper }}
                PaperProps={{
                  sx: { width: '90%' },
                }}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop,
                  },
                }}
                anchor='right'
                open={notesDrawerOpen}
                onClose={() => setNotesDrawerOpen(false)}
                className='drawer_Header_page'
              >
                <NotesDrawer closeDrawer={closeNotesDrawer} editing={editingNote} type='salesOrderDetails' details={noteDrawerDetails} />
              </Drawer>

              {/* Customer ID */}
              <Typography className='customer_id_1 button_bottom_space' gutterBottom>
                Customer ID : {customer.customer_id || <Skeleton style={{ width: 100 }} />}
              </Typography>

              <Box sx={{ marginBottom: '20px' }}>
                {notesContent.map((note, index) => (
                  <Box sx={{ display: 'flex' }}>
                    {note.visibl ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : null}{' '}
                    <Box>
                      <Typography onClick={() => openNotesDrawer(note)}>
                        {' '}
                        <span
                          style={{
                            color: '#008593',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                        >
                          Note #{index + 1} :
                        </span>{' '}
                        {note.note_content}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <hr className='button_bottom_space' />

          {/* address */}
          {selectedAddress && selectedAddress.is_current ? (
            <Paper className='customer_details_paper'>
              <Stack direction='row' alignItems='center' gap={2} className='Customer_Address_stack'>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  {' '}
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {' '}
                    <img src={LocationMiscIcon} className='misc_icons_dimensions' alt='LocationMiscIcon' />{' '}
                    {selectedAddress.zip ? (
                      <Box className='customer_address' sx={{ marginLeft: '6px' }}>
                        {' '}
                        <Box style={{ textTransform: 'capitalize' }} className='address'>
                          {' '}
                          <Box>
                            {' '}
                            {selectedAddress.zip ? <>#{selectedAddress.address_sequence} </> : null}
                            <span style={{ textTransform: 'Capitalize' }}>{selectedAddress.zip ? <>{selectedAddress.address_description || 'Address'}</> : null}</span> {selectedAddress.zip ? ':' : null}
                          </Box>{' '}
                          <Box>{selectedAddress.address_line}</Box>
                          <Box> {selectedAddress.address_second_line} </Box>{' '}
                        </Box>{' '}
                        <Box>
                          {' '}
                          <Box style={{ textTransform: 'capitalize' }}>
                            {' '}
                            {selectedAddress.city}
                            {selectedAddress.city ? ',' : ''} <span style={{ textTransform: 'Uppercase' }}>{selectedAddress.state}</span>
                            {selectedAddress.state ? ' ' : ' '} {selectedAddress.zip}
                          </Box>{' '}
                        </Box>{' '}
                      </Box>
                    ) : (
                      <Box sx={{ marginLeft: '6px' }}>
                        <Skeleton width='100px' />
                      </Box>
                    )}
                  </Box>{' '}
                  <Box>
                    {' '}
                    <Box className='edit_address_icon'>
                      {' '}
                      {selectedAddress.city ? (
                        <>
                          {' '}
                          <img
                            src={EditMiscIcon}
                            alt='EditMiscIcon'
                            className='misc_icons_dimensions'
                            onClick={() => {
                              navigate('/tbm/ps/editcustomeraddress', {
                                state: {
                                  anotherAddress: customerInfo,
                                  Address1: selectedAddress.address_line,
                                  city: selectedAddress.city,
                                  state: selectedAddress.state,
                                  zip: selectedAddress.zip,
                                  country: selectedAddress.country,
                                  custid: selectedAddress.customer_id,
                                  addressid: selectedAddress.address_id,
                                  email: selectedAddress.email_id,
                                  Discription: selectedAddress.address_description,
                                  Address2: selectedAddress.address_second_line,
                                  is_billing: selectedAddress.is_billing,
                                },
                              });
                            }}
                          />{' '}
                        </>
                      ) : (
                        <>
                          <img src={EditMiscIcon} className='misc_icons_dimensions' alt='EditMiscIcon' />
                        </>
                      )}
                    </Box>{' '}
                  </Box>{' '}
                </Box>
              </Stack>
            </Paper>
          ) : (
            <Paper className='customer_details_paper'>
              <Stack direction='row' alignItems='center' gap={1}>
                <img src={LocationMiscIcon} className='misc_icons_dimensions' />{' '}
                <Typography sx={{ textTransform: 'capitalize' }} className='customer_address'>
                  <Skeleton width='100px' />
                </Typography>
                <span>
                  <img src={EditMiscIcon} className='misc_icons_dimensions' alt='EditMiscIcon' />
                </span>
              </Stack>
            </Paper>
          )}

          {selectedAddress ? (
            <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page '>
              <span style={{ paddingLeft: '15px' }} className='gray_button_span button_top_space'>
                <Box className='globalIconMainBox'>
                  <img src={ChangeAddress} className={`secondary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} alt='ChangeAddress' />
                  <Typography
                    onClick={() => {
                      navigate('/tbm/ps/CustomerAddress', {
                        state: {
                          Custdata: {
                            anotherAddress: customerInfo,
                            address_line: customer.address_line,
                            city: customer.city,
                            state: customer.state,
                            zip: customer.zip,
                            country: customer.country,
                            custid: customer.customer_id,
                            addressid: customer.address_id,
                            email: customer.email,
                            f_name: customer.first_name,
                            l_name: customer.last_name,
                            email_id: customer.email_id,
                          },
                        },
                      });
                    }}
                    sx={{ fontFamily: 'RobotoLight' }}
                    className='globalText'
                    onMouseEnter={handleHover}
                    onMouseLeave={handleHover}
                  >
                    CHANGE ADDRESS
                  </Typography>
                </Box>
              </span>
            </Box>
          ) : null}
          <hr className='button_bottom_space button_top_space' />

          {/* Phone */}
          <Box>
            {/* Phone no. */}
            {!PhoneNumberOne ? (
              <Paper className='customer_details_paper'>
                <Stack direction='row' alignItems='center' gap={1}>
                  <img src={PhoneMiscIcon} className='misc_icons_dimensions' alt='PhoneMiscIcon' />
                  <Typography sx={{ textTransform: 'capitalize' }} className='customer_address'>
                    {AnotherPhoneNumber === 0 ? (
                      <Typography sx={{ textTransform: 'capitalize' }} className='customer_address'>
                        Phone :{' '}
                        <a style={{ textDecoration: 'none', color: '#3594AE' }} href={'tel:+1' + ''} target='_blank'>
                          NA
                        </a>
                      </Typography>
                    ) : (
                      <Skeleton width='100px' />
                    )}
                  </Typography>
                  <span>
                    <>
                      {AnotherPhoneNumber === 0 ? (
                        <>
                          <img
                            src={EditMiscIcon}
                            alt='EditMiscIcon'
                            className='misc_icons_dimensions'
                            onClick={() => {
                              navigate('/tbm/ps/editcustomerphoneemail', {
                                state: {
                                  phoneno: customer.mobile,
                                  emailAddress: customer.email,
                                  email_id: customer.email_id,
                                  custid: customerId,
                                  PhoneNo: PhoneNumbers,
                                  addressid: addressID,
                                },
                              });
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={EditMiscIcon}
                            alt='EditMiscIcon'
                            className='misc_icons_dimensions'
                            onClick={() => {
                              navigate('/tbm/ps/editcustomerphoneemail', {
                                state: {
                                  phoneno: customer.mobile,
                                  emailAddress: customer.email,
                                  email_id: customer.email_id,
                                  custid: customerId,
                                  PhoneNo: PhoneNumbers,
                                  addressid: addressID,
                                },
                              });
                            }}
                          />
                        </>
                      )}
                    </>
                  </span>
                </Stack>
              </Paper>
            ) : (
              <Paper className='customer_details_paper'>
                <Stack direction='row' alignItems='center' gap={1} sx={{ paddingBottom: '10px' }}>
                  <img src={PhoneMiscIcon} className='misc_icons_dimensions' alt='PhoneMiscIcon' />
                  <Typography sx={{ textTransform: 'capitalize' }} className='customer_Phone'>
                    {' '}
                    {PhoneNumberOne.Type || 'Phone'} :{' '}
                    <a style={{ textDecoration: 'none', color: '#3594AE' }} href={'tel:+1' + PhoneNumberOne['#']} target='_blank'>
                      {PhoneNumberOne ? (
                        <>
                          <>+1 {normalize(PhoneNumberOne['#'])} </>
                        </>
                      ) : (
                        'NA'
                      )}
                    </a>
                  </Typography>
                  <span>
                    <>
                      <img
                        src={EditMiscIcon}
                        alt='EditMiscIcon'
                        className='misc_icons_dimensions'
                        onClick={() => {
                          navigate('/tbm/ps/editcustomerphoneemail', {
                            state: {
                              phoneno: customer.mobile,
                              email_id: customer.email_id,
                              EMAIL: emailAddress,
                              custid: customerId,
                              emailAddress: customer.email,
                              PhoneNo: PhoneNumbers,
                              addressid: addressID,
                            },
                          });
                        }}
                      />
                    </>
                  </span>
                </Stack>
              </Paper>
            )}
          </Box>

          {/* Address */}
          <Box>
            {!PhoneNumberTwo ? null : (
              <>
                <Paper className='customer_details_paper'>
                  <Stack direction='row' alignItems='center' gap={1}>
                    <Typography
                      sx={{
                        textTransform: 'capitalize',
                        marginLeft: '32px',
                        position: 'relative',
                        bottom: '10px',
                      }}
                      className='customer_Phone'
                    >
                      {' '}
                      {PhoneNumberTwo.Type || 'Phone'} :{' '}
                      <a style={{ textDecoration: 'none', color: '#3594AE' }} href={'tel:+1' + PhoneNumberTwo['#']} target='_blank'>
                        {PhoneNumberOne ? (
                          <>
                            <>+1 {normalize(PhoneNumberTwo['#'])} </>
                          </>
                        ) : (
                          'NA'
                        )}
                      </a>
                    </Typography>
                  </Stack>
                </Paper>
              </>
            )}

            {/* ---------------------------------------------------- */}
            {/* Email */}
            <Box sx={{ marginTop: '10px !important' }}>
              {emailAddress === undefined ? (
                <Paper className='customer_details_paper_Email'>
                  <Stack direction='row' alignItems='center' gap={1}>
                    <img src={EmailMiscIcon} className='misc_icons_dimensions' />

                    <Typography className='customer_address'>
                      <Skeleton width='100px' />
                    </Typography>
                  </Stack>
                </Paper>
              ) : (
                <>
                  {emailLength === 0 ? (
                    <Paper className='customer_details_paper_Email'>
                      <Stack direction='row' alignItems='center' gap={1}>
                        <img src={EmailMiscIcon} className='misc_icons_dimensions' alt='EmailMiscIcon' />
                        <Typography className='customer_address'>
                          {' '}
                          Email : <span className='customer_email'>NA</span>
                        </Typography>
                      </Stack>
                    </Paper>
                  ) : (
                    <>
                      <>
                        {emailAddress.slice(0, 2).map((item, index) => {
                          return (
                            <Paper className='customer_details_paper'>
                              <Stack direction='row' alignItems='center' gap={1}>
                                {index / 2 ? (
                                  <span style={{ marginLeft: '23px' }}></span>
                                ) : (
                                  <>
                                    <img src={EmailMiscIcon} className='misc_icons_dimensions' alt='EmailMiscIcon' />
                                  </>
                                )}

                                <Typography className='customer_address_email'>
                                  {' '}
                                  <span style={{ textTransform: 'capitalize' }}>{item.email_type === '' ? 'Email' : item.email_type}</span>:{' '}
                                  <span className='customer_email'>
                                    {' '}
                                    <a
                                      style={{
                                        textDecoration: 'none',
                                        color: '#3594AE',
                                      }}
                                      href={'mailto:' + item.email_id}
                                      target='_blank'
                                    >
                                      {item.email_id === '' ? 'NA' : item.email_id}
                                    </a>
                                  </span>
                                </Typography>
                              </Stack>
                            </Paper>
                          );
                        })}
                      </>
                    </>
                  )}
                </>
              )}
            </Box>
          </Box>

          <hr className='button_top_space button_bottom_space' />

          <Typography className='sales_order_Details_Text_Style sales_order_Details_Text_Style_font mt all_pages_h2_header button_bottom_space'>
            WORK ORDER #:{' '}
            {location.state.Custdata.Invoice ? (
              location.state.Custdata.Invoice.split(', ').map((invoice, index) => (
                <span key={index} style={{ fontWeight: 'normal', marginLeft: '2em', display: 'list-item', listStyle: 'none' }}>
                  {invoice}
                </span>
              ))
            ) : (
              <Skeleton style={{ width: 100 }} />
            )}
          </Typography>

          <Typography>
            <span className='sales_order_Details_Text_Style'>Date Sold: </span>
            {workOrderDetails.invoiceData ? (
              <>
                {moment(workOrderDetails.invoiceData.DateSold).format('MMMM')} {moment(workOrderDetails.invoiceData.DateSold).format('DD')}, {moment(workOrderDetails.invoiceData.DateSold).format('YYYY')}
              </>
            ) : (
              <Skeleton style={{ width: 100 }} />
            )}
          </Typography>
          <Typography>
            <span className='sales_order_Details_Text_Style'>Sales Rep:</span> {location.state.Custdata.Sales_rep !== undefined ? location.state.Custdata.Sales_rep : <Skeleton style={{ width: 100 }} />}
          </Typography>
          <Typography className='sales_order_Details_Text_Style'>
            Installation Date:{' '}
            {workOrderDetails.installation.length ? (
              workOrderDetails.installation.map(({ ScheduledDate }) => {
                const date = moment(ScheduledDate);
                const formattedMonth = date.format('MMMM');
                const formattedDate = date.format('DD');
                const formattedYear = date.format('YYYY');

                return (
                  <span style={{ fontWeight: 'normal', marginLeft: '2em', display: 'list-item' }}>
                    {formattedMonth} {formattedDate} {formattedYear}
                  </span>
                );
              })
            ) : (
              <Skeleton style={{ width: 100 }} />
            )}
          </Typography>
          <Typography>
            {workOrderDetails.installation.length ? (
              <>
                <span className='sales_order_Details_Text_Style'>Installer : </span>
                {workOrderDetails.installation.map(({ full_name }) => (
                  <Typography
                    component='span'
                    style={{
                      fontWeight: 'normal',
                      marginLeft: '2em',
                      display: 'list-item',
                    }}
                  >
                    {full_name}
                  </Typography>
                ))}
              </>
            ) : (
              <Skeleton style={{ width: 100 }} />
            )}
          </Typography>
          <Typography>
            <span className='sales_order_Details_Text_Style'>Total : </span>
            {`$ ${workOrderDetails.invoiceData.reduce((acc, { TotalInvoice }) => acc + TotalInvoice, 0).toFixed(2)}`}
          </Typography>
          <Typography>
            <span className='sales_order_Details_Text_Style'>Amount Paid : </span>
            {`$ ${workOrderDetails.invoiceData.reduce((acc, { SumDeposit }) => acc + SumDeposit, 0).toFixed(2)}`}
          </Typography>
          <Typography>
            <span className='sales_order_Details_Text_Style'>Balance : </span>
            {`$ ${workOrderDetails.invoiceData.reduce((acc, { Balance }) => acc + Balance, 0).toFixed(2)}`}
          </Typography>

          <hr className='button_top_space button_bottom_space' />

          <WorkOrderDetails />
          {/* <hr className='button_top_space button_bottom_space' />

      {blindsData.length ? <ProductionStatus title='BLINDS' data={blindsData} /> : null}
      {shuttersOrderData.length ? <ProductionStatus title='SHUTTERS' data={shuttersOrderData} /> : null}
      {rollerShadesData.length ? <ProductionStatus title='ROLLER SHADES' data={rollerShadesData} /> : null}
      {softShadesData.length ? <ProductionStatus title='SOFT SHADES' data={softShadesData} /> : null} */}
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default SalesOrderDetails;
