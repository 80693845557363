/** @format */

import React, { forwardRef, useContext, useState, useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TableCell from '@mui/material/TableCell';
import CancelIconforWB from '../../img/cancelforWhitebackground.png';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import CONFIG from '../../config/config.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import 'moment-timezone';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import ReorderWindowIcon from '../../assets/icons/primary/window-move.svg';
import RerderWindowHovIcon from '../../assets/icons/primary/WithHovIcon/move-hov.svg';
import Drawer from '@mui/material/Drawer';
import EditWindowSetName from './EditWindowSetName';
import { SnackbarContext } from '../../App';
import fracty from 'fracty';
import NotesDrawer from '../Proposals/NotesDrawer';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import LongText from '../LongText';
import { TablePagination } from '@mui/material';

let updatedrow = [];

const TurquoiseTooltipforNotes = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#faf3dd',
    color: '#000000',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 300,
    maxHeight: 300,
    textAlign: 'left',
    align: 'left',
    border: '1px solid #000000',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#f2f3f2',
      border: '1px solid #000000',
    },
    top: '100%', // Position the arrow at the bottom of the tooltip box
    right: '100', // Position the arrow at the right side of the tooltip box
  },
}));

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  paper: {
    width: '90%',
    background: 'rgba(69, 157, 166, 70%)  !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

const theme1 = createTheme({
  palette: {
    border: {
      main: '1px solid #000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#FAC41E',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "green", // date picker textfield style
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#008593 !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'white', // disable date
            color: '#E9E9E9',
          },
        },
        dayWithMargin: {
          backgroundColor: 'white', // not selected date
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "blue" //calender icon & month change arrow icon style
        },
      },
    },
  },
});
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const ReOrderWindowSet = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const classes = useStyles();
  const contextvalue = useContext(SnackbarContext);

  const outerTheme = createTheme({
    palette: {
      primary: {
        main: '#333333',
      },
    },
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);

  if (!location.state) {
    navigate('/pagenotfound');
  } else {
    updatedrow = location.state.totalwindows;
  }

  let coveringdetails = location.state.coveringdetails;

  const [WindowSetName, setWindowSetName] = useState('');
  const [windowsetdata, setWindowsetdata] = useState([]);
  const [customerID, setCustomerID] = useState(0);
  const [addressID, setAddressID] = useState(0);
  const [open, setOpen] = useState(false);
  const [ConfirmAlert, setConfirmAlert] = useState(false);

  const [isWSDrawerOpen, setIsWSDrawerOpen] = useState(false);
  const [isAddNoteDrawerOpen, setIsAddNoteDrawerOpen] = useState(false);
  const [alertmessage, setAlertMessage] = useState('');
  const [classtype, setClassType] = useState('');
  const [applyWindowLine, setApplyWindowLine] = useState(false);
  const [WindowSetData, setWindowSetData] = useState([]);

  // error handling states
  const [WindowSetErrorMsg, setWindowSetErrorMsg] = useState(false);
  const [ReorderWindowsinWindowSetErrorMsg, setReorderWindowsinWindowSetErrorMsg] = useState(false);
  const [ReorderWindowsinWindowSetErrorMsgTxt, setReorderWindowsinWindowSetErrorMsgTxt] = useState('');

  const [WindowSetApiErrMsg, setWindowSetApiErrMsg] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  useEffect(() => {
    {
      window.scrollTo(0, 0);
    }

    if (location.state.length) {
      navigate('/pagenotfound');
    } else {
      setWindowSetData(location.state.totalwindows);
      setWindowSetName(location.state.customerdata.window_set_name);
      setWindowsetdata(location.state.customerdata);
      setWindowSetID(location.state.customerdata.window_set_id);
      setCustomerID(location.state.customerdata.customer_id);
      setAddressID(location.state.customerdata.address_id);

      if (location.state.addition_sku_data !== undefined) {
        setExistingSku(location.state.addition_sku_data.ExistingSku);
      }
      if (location.state.addition_sku_data !== undefined) {
        setBuildSku(location.state.addition_sku_data.BuildSku);
      }
      if (location.state.addition_sku_data !== undefined) {
        setMountSku(location.state.addition_sku_data.MountSku);
      }
      if (location.state.addition_sku_data !== undefined) {
        setLadderSku(location.state.addition_sku_data.LadderSku);
      }
      if (location.state.addition_sku_data !== undefined) {
        setWindowDepthSku(location.state.addition_sku_data.WindowDepthSku);
      }
      if (location.state.addition_sku_data !== undefined) {
        setHeadrailSku(location.state.addition_sku_data.HeadrailSku);
      }
      if (location.state.addition_sku_data !== undefined) {
        setObstructionsSku(location.state.addition_sku_data.ObstructionsSku);
      }

      getWindowSet();
    }
    getVerified();
    ChangeValue();
  }, []);

  const ChangeValue = () => {
    setTimeout(function () {
      contextvalue.setOpensnackbar(false);
    }, 5000);
  };

  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };
  const vertical = 'top';
  const horizontal = 'center';

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='table-headerps font_weight_bold'>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </TableCell>
          <TableCell className='table-headerwsname1 sticky-columnws-1_1'>
            <span>#</span>
            <span style={{ marginLeft: '30px' }}>Window Name</span>
          </TableCell>
          <TableCell className='table-headersw3'>Size</TableCell>
          <TableCell className='table-headersw3'>Bld</TableCell>
          <TableCell className='table-headersw3'>Surface</TableCell>
          <TableCell className='table-headersw3'>Ladder</TableCell>
          <TableCell className='table-headersw3 ellipsis2'>Ex. Cover</TableCell>
          <TableCell className='table-header4'>Note</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const WindowSetNameUpdateDrawer = (name, drawerstatus, message, status) => {
    setIsWSDrawerOpen(drawerstatus);
    setAlertMessage(message);
    if (message === 'Window set name is updated successfully!') {
      setWindowSetName(name);
      setOpen(true);
      setClassType('Success mainAlert');
    } else if (message === 'Window set name is already exists.') {
      setOpen(true);
      setClassType('Error mainAlert');
    } else {
      setOpen(false);
      setClassType('');
    }
  };

  const AddNoteDrawerFun = (drawerstatus) => {
    setIsAddNoteDrawerOpen(drawerstatus);
  };

  const [windowSetID, setWindowSetID] = useState();

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  const [ExistingSku, setExistingSku] = useState();
  const [BuildSku, setBuildSku] = useState();
  const [MountSku, setMountSku] = useState();
  const [LadderSku, setLadderSku] = useState();
  const [WindowDepthSku, setWindowDepthSku] = useState('S500470');
  const [HeadrailSku, setHeadrailSku] = useState('S500190');
  const [ObstructionsSku, setObstructionsSku] = useState('S500540');

  const additional_sku_data = {
    ExistingSku: ExistingSku,
    BuildSku: BuildSku,
    MountSku: MountSku,
    LadderSku: LadderSku,
    WindowDepthSku: WindowDepthSku,
    HeadrailSku: HeadrailSku,
    ObstructionsSku: ObstructionsSku,
  };

  const [EditNotes, setEditNote] = useState('');
  const [notes, setNotes] = useState(false);

  const [notesData, setNotesData] = useState('');
  const [noteTypeId, setNoteTypeId] = useState('');

  const [windowSetNotes, setWindowSetNotes] = useState([]);

  const [noteId, setNoteId] = useState([]);
  const [notesContentForWindow, setNotesContentForWindow] = useState([]);
  const [eyeButtonMsg, setEyeButtonMsg] = useState(false);

  const [notesDataDescription, setNotesDataDescription] = useState([]);

  const NotesDrawerData = (notesDrawerStatus, notesMsgAlert, notesMsg, eyeButtonMsg, referenceId, noteId, noteContent) => {
    setNotes(notesDrawerStatus);
    setEyeButtonMsg(eyeButtonMsg);

    if (notesMsg === 'Note added successfully!' || notesMsg === 'Note updated successfully!') {
      getAllNotes();
    }
    setNoteId(noteId);
    setNotesContentForWindow(noteContent);
    setEyeButtonMsg(eyeButtonMsg);
  };

  const getAllNotes = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/Notes/${windowSetID}`, requestOptions);
    const data = await response.json();
    if (!data.error) {
      setWindowSetNotes(data.Notes);
    }
  };

  const getWindowSet = async () => {
    const response = await fetch(CONFIG.API_URL + `/wm/window-set/${location.state.customerdata.window_set_id}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      setWindowSetApiErrMsg(data.error_message);
      setWindowSetErrorMsg(true);
      return;
    }
    setWindowSetNotes(data.notes);
  };

  const onDragEnd = (e) => {
    if (!e.destination) {
      return;
    }
    let tempData = Array.from(WindowSetData);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setWindowSetData(tempData);

    if (location.state.totalwindows === WindowSetData) {
      setApplyWindowLine(true);
    }
  };

  const reorderWindows = async () => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        window_set_id: location.state.customerdata.window_set_id,
        windows: WindowSetData,
      }),
    };
    // Created Proposal will be added to the database through CreateProposal API
    const response = await fetch(`${CONFIG.API_URL}/wm/reorder-windows`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      setReorderWindowsinWindowSetErrorMsgTxt(data.error_message);
      setReorderWindowsinWindowSetErrorMsg(true);
      return;
    }
    navigate(`/tbm/ps/windowset/${params.id}`, {
      state: {
        coveringdetails: coveringdetails,
        customerdata: windowsetdata,
        totalwindows: WindowSetData,
      },
    });
  };

  const handleClose = () => {
    setConfirmAlert(false);
  };

  const [editingNote, setEditingNote] = useState(false);
  const [noteDrawerDetails, setNoteDrawerDetails] = useState({});
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);

  const closeNotesDrawer = (alert) => {
    setNotesDrawerOpen(false);
    setEditingNote(false);
    setNoteDrawerDetails({});

    if (alert.message === 'Note added successfully!' || alert.message === 'Note updated successfully!') {
      // TODO: fetch notes again
    }
  };

  const openNotesDrawer = (note) => {
    setEditingNote(!!note);
    setNoteDrawerDetails({
      firstName,
      lastName,
      referenceId: '',
      noteId: note ? note.id : null,
    });
    setNotesDrawerOpen(true);
  };

  return (
    <div className='padding_bottom_class'>
      {/* WindowSetErrorMsg */}
      <Snackbar className='snackbar_bar_postition' open={WindowSetErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setWindowSetErrorMsg(false)}>
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img
              onClick={() => {
                setWindowSetErrorMsg(false);
              }}
              src={RedDrawerCancelIcon}
              alt='CANCELICONWS'
              className='drawer_secondary_icons_dimension drawer_cancel_cursor'
            />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img src={RedExclamationIcon} alt='CONFIRMICONWS' className='primary_icons_dimension alertImageMargin' />
            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{WindowSetApiErrMsg}</div>
          </div>
        </Alert>
      </Snackbar>

      {/* ReorderWindowsinWindowSetErrorMsg */}
      <Snackbar className='snackbar_bar_postition' open={ReorderWindowsinWindowSetErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setReorderWindowsinWindowSetErrorMsg(false)}>
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img
              onClick={() => {
                setReorderWindowsinWindowSetErrorMsg(false);
              }}
              src={RedDrawerCancelIcon}
              alt='CANCELICONWNWS'
              className='drawer_secondary_icons_dimension drawer_cancel_cursor'
            />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img src={RedExclamationIcon} alt='CONFIRMICONRWNWS' className='primary_icons_dimension alertImageMargin' />
            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{ReorderWindowsinWindowSetErrorMsgTxt}</div>
          </div>
        </Alert>
      </Snackbar>

      <Snackbar className='snackbar_bar_postition' open={ConfirmAlert} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
        <Alert className='Success mainAlert' variant='filled' icon={false}>
          <div className='alertCancel' align='right'>
            <img onClick={handleClose} src={DrawerCancelIcon} alt='CANCELICONCA' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
          </div>

          <div className='alertMainNew'>
            <div align='left' style={{ paddingTop: 37 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'Center',
                }}
              >
                <Box className='alertCantinueButton'>
                  <img src={DrawerAlertIcon} alt='CONFIRMICON' className='primary_icons_dimension' />
                </Box>
              </Box>

              <div
                style={{
                  paddingTop: 20,
                  paddingLeft: '26px',
                  fontSize: '20px',
                  fontFamily: 'RobotoRegular',
                }}
              >
                Are you sure you want to Reorder the windows ?
              </div>
            </div>

            <div
              align='left'
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px 10px 0px 24px',
                justifyContent: 'center',
                alignItems: 'start',
                lineHeight: '24px',
              }}
            >
              <Box
                onClick={() => {
                  reorderWindows();
                }}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'Center',
                  cursor: 'pointer',
                  marginBottom: '20px',
                }}
              >
                <Box className='alertCantinueButton'>
                  <img src={DrawerConinueIcon} className='primary_icons_dimension' alt='CONTINUE' />
                </Box>
                <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                  CONTINUE
                </Typography>
              </Box>

              <Box
                onClick={handleClose}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'Center',
                  cursor: 'pointer',
                  paddingBottom: '16px',
                  paddingLeft: '15px',
                }}
              >
                <Box className='globalIconMainBox'>
                  <Box className='iconSecondaryButtonGlobalForDrawer'>
                    <img src={DrawerCancelIcon} alt='CANCELICON' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </Box>
                  <Typography className='globalTextForDrawer'>CANCEL</Typography>
                </Box>
              </Box>
            </div>
          </div>
        </Alert>
      </Snackbar>
      <ThemeProvider theme={theme1}>
        <Drawer
          classes={{ paper: classes.paper }}
          PaperProps={{
            sx: { width: '90%' },
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          anchor='right'
          open={notesDrawerOpen}
          onClose={() => setNotesDrawerOpen(false)}
          className='drawer_Header_page'
        >
          <NotesDrawer closeDrawer={closeNotesDrawer} editing={editingNote} type='WindowSet' details={noteDrawerDetails} />
        </Drawer>
      </ThemeProvider>
      <ThemeProvider theme={outerTheme}>
        <div align='center'>
          <div className='heading-box-1'>
            <h2 className='clickable_header all_pages_h2_header ' onClick={() => setIsWSDrawerOpen(true)}>
              <LongText>{WindowSetName}</LongText>
            </h2>

            <h3
              className='clickable_header_1 all_pages_h2_header center button_top_space common_line_height_css'
              style={{
                textTransform: 'capitalize',
                textAlign: 'center !important',
              }}
              onClick={() => {
                navigate(`/tbm/ps/customerdetails`, {
                  state: {
                    Custdata: { custid: customerID, Addressid: addressID },
                  },
                });
              }}
            >
              <LongText>{`${windowsetdata.last_name} | ${windowsetdata.city}`}</LongText>
            </h3>
          </div>
        </div>

        <Box sx={{ marginBottom: '20px', marginTop: '20px' }}>
          {windowSetNotes.map((note, index) => (
            <Box key={note.id} sx={{ display: 'flex' }}>
              {note.visible ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : null}{' '}
              <Box>
                <Typography className='' onClick={() => openNotesDrawer(note)}>
                  {' '}
                  <span
                    style={{
                      color: '#008593',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                    }}
                  >
                    Note #{index + 1} :
                  </span>{' '}
                  {note.content}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Drawer
          PaperProps={{
            sx: { width: '90%' },
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          classes={{ paper: classes.paper }}
          anchor='right'
          open={isWSDrawerOpen}
          onClose={() => setIsWSDrawerOpen(true)}
          sx={{ zIndex: '100000' }}
        >
          <EditWindowSetName windowdetails={windowsetdata} func={WindowSetNameUpdateDrawer} />
        </Drawer>

        <hr className='button_bottom_space button_top_space' />
        <Box className='table-box-1'>
          <TableContainer sx={{ borderBottom: '1px solid #D6D6D6' }} className='table_padding_bottom'>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='Table'>
                {(provided) => (
                  <Table className='windowset-table-dimension' aria-labelledby='tableTitle' size='small' {...provided.droppableProps} ref={provided.innerRef}>
                    <EnhancedTableHead rowCount={WindowSetData.length} />
                    <TableBody>
                      {(rowsPerPage > 0 ? WindowSetData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : WindowSetData).map((row, index) => (
                        <Draggable key={row.window_id} draggableId={row.window_id} index={index}>
                          {(provided) => (
                            <TableRow hover className='rowhover' role='checkbox' tabIndex={-1} key={row.window_id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <TableCell sx={{ color: '#008593' }} className='table-content sticky-columnws-1_1' align='left'>
                                <Box sx={{ display: 'flex' }}>
                                  <DragHandleIcon
                                    onClick={() => {}}
                                    sx={{
                                      color: '#2596be',
                                      paddingTop: '0px',
                                    }}
                                  />{' '}
                                </Box>
                              </TableCell>

                              <TableCell className='table-contentwsidwithcheckbox_2 sticky-columnws-1' align='left'>
                                {' '}
                                <span className='ellipsis2'>
                                  <span
                                    style={{
                                      paddingRight: `${WindowSetData.indexOf(row) + 1 > 9 ? '20px' : '28px'}`,
                                    }}
                                  >
                                    {WindowSetData.indexOf(row) + 1}
                                  </span>
                                  <LongText>{row.window_name}</LongText>
                                </span>
                              </TableCell>
                              <TableCell className='table-content ellipsis2' align='left'>
                                {fracty(row.window_width)}" x {fracty(row.window_height)}"{' '}
                                <span className={row.measurement_type.replace(/ /g, '')}>
                                  {row.measurement_type === 'Quick'
                                    ? '(' + 'QK' + ')'
                                    : row.measurement_type === 'Shutters'
                                    ? '(' + 'SH' + ')'
                                    : row.measurement_type === 'Blinds'
                                    ? '(' + 'BL' + ')'
                                    : '(' +
                                      row.measurement_type
                                        .split(' ')
                                        .map((word) => word[0])
                                        .join('') +
                                      ')'}
                                </span>
                              </TableCell>
                              <TableCell className='table-content' align='left'>
                                {row.build_type}
                              </TableCell>
                              <TableCell className='table-content' align='left'>
                                {row.mount_surface}
                              </TableCell>
                              <TableCell className='table-content' align='left'>
                                {!isNaN(row.ladder) ? row.ladder + "'" : row.ladder}
                              </TableCell>
                              <TableCell className='table-content' align='left'>
                                {row.existing_covering}
                              </TableCell>
                              <TableCell className='table-content' align='left'>
                                {row.notes.length > 0 && (
                                  <TurquoiseTooltipforNotes
                                    title={
                                      <>
                                        <Typography>
                                          {row.notes.map((note) => (
                                            <div key={note.id}>
                                              {index > 0 && <br />}
                                              <b>Note:</b> {note.content}
                                            </div>
                                          ))}
                                        </Typography>
                                      </>
                                    }
                                    placement='bottom-end'
                                    TransitionComponent={Zoom}
                                    arrow
                                  >
                                    <Link
                                      className='link'
                                      to='/tbm/ps/window'
                                      state={{
                                        data: windowsetdata,
                                        row_id: row.window_id,
                                        customer_id: windowsetdata.customer_id,
                                        additional_sku_data: additional_sku_data,
                                      }}
                                    >
                                      {row.notes_count === '0' ? <></> : <span style={{ paddingLeft: '5px' }}>{row.notes_count}</span>}
                                    </Link>
                                  </TurquoiseTooltipforNotes>
                                )}
                              </TableCell>
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Droppable>
            </DragDropContext>
          </TableContainer>
          <TablePagination
            sx={{
              mx: 0,
              px: 0,
              textAlign: 'center',
            }}
            rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]}
            component='div'
            count={WindowSetData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPageprp) => {
              setPage(newPageprp);
            }}
            onRowsPerPageChange={({ target }) => {
              setRowsPerPage(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>

        {applyWindowLine ? (
          <Box display='flex' alignItems='center' justifyContent='center' className='apply_Changes_Box'>
            <span
              className='button_span button_top_space button_bottom_space'
              onClick={() => {
                setConfirmAlert(true);
              }}
              onMouseEnter={handleHoverFun}
              onMouseLeave={handleMouseLeaveFun}
            >
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                {isHovered ? <img alt='APPLYCHANGESH' className='primary_icons_dimension_1' src={RerderWindowHovIcon} /> : <img alt='APPLYCHANGES' className='primary_icons_dimension_1' src={ReorderWindowIcon} />}

                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                  APPLY CHANGES
                </Typography>
              </Box>
            </span>
          </Box>
        ) : (
          <Box display='flex' alignItems='center' justifyContent='center' className='apply_Changes_Box'>
            <span className='button_span_disabled button_top_space button_bottom_space'>
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                <img className='primary_icons_dimension_disable' src={ReorderWindowIcon} alt='addwindow' />

                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                  REORDER WINDOWS
                </Typography>
              </Box>
            </span>
          </Box>
        )}
        <hr />

        <Box sx={{ marginLeft: '15px' }}>
          <Box display='flex' alignItems='center' justifyContent='center' sx={{ marginLeft: '0px' }} className='add_Window_Button_margin_Bottom'>
            <span className='gray_button_span button_top_space'>
              <Box
                onClick={() => {
                  navigate(`/tbm/ps/windowset/${params.id}`, {
                    state: {
                      coveringdetails: coveringdetails,
                      customerdata: windowsetdata,
                      totalwindows: WindowSetData,
                    },
                  });
                }}
                className='globalIconMainBox'
              >
                <Box className='iconSecondaryButtonGlobal'>
                  <img alt='CANCEL' src={CancelIconforWB} className='globalCancelimg' />
                </Box>
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  CANCEL
                </Typography>
              </Box>
            </span>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
};
