/** @format */

import React, { useState, useEffect } from 'react';
import CONFIG from '../../config/config.js';
import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import Fab from '@mui/material/Fab';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerSaveIcon from '../../assets/icons/drawer-alert/drw-save.svg';
import DrawerCloseIcon from '../../assets/icons/drawer-alert/close.svg';
import FormHelperText from '@mui/material/FormHelperText';

const EC = [
  {
    value: 'None',
    label: 'None',
  },
  {
    value: 'Customer Removal',
    label: 'Customer Removal',
  },
  {
    value: 'Remove Blinds & haul away',
    label: 'Remove Blinds & haul away',
  },
  {
    value: 'Remove Blinds & leave',
    label: 'Remove Blinds & leave',
  },
  {
    value: 'Specialty Removal',
    label: 'Specialty Removal',
  },
];

const Install = [
  {
    value: 'Yes',
    label: 'Yes',
  },
  {
    value: 'No',
    label: 'No',
  },
];

const AdditionalCharge = [
  {
    value: 'Yes',
    label: 'Yes',
  },
  {
    value: 'No',
    label: 'No',
  },
];

export default function InstallAccessoriesDrawer(props) {
  const existingAccessories = [];
  const existingCustomColor = [];
  const existingTripCharges = [];
  const existingRepairCharges = [];

  const [showCustomeColor, setShowCustomeColor] = useState(false);
  const [InstallValue, setInstallValue] = useState('');
  const [hasAdditionalCharge, setHasAdditionalCharge] = useState('No');
  const [addedAccessories, setAddedAccessories] = useState([]);
  const [addedTripCharges, setAddedTripCharges] = useState([]);
  const [additionalPricing, setAdditionalPricing] = useState([]);
  const [addedColors, setAddedColors] = useState([]);
  const [accessoryList, setAccessoryList] = useState([]);
  const [customColorlist, setCustomColorList] = useState([]);
  const [TripChargelist, setTripChargeList] = useState([]);
  const [InstallOpen, setInstallOpen] = useState(false);
  const [isAdditionalChargeOpen, setIsAdditionalChargeOpen] = useState(false);
  const [addedAdditionalCharges, setAddedAdditionalCharges] = useState([]);
  const [chargeList, setChargeList] = useState([{}]);
  const [accessoryOpenIndex, setAccessoryOpenIndex] = useState(-1);
  const [tripOpenIndex, settripOpenIndex] = useState(-1);
  const [colorOpenIndex, setcolorOpenIndex] = useState(-1);
  const [isImageHovered, setIsImageHovered] = useState(false);
  const [noteValue, setNoteValue] = useState(false);

  // Get Token
  const token = sessionStorage.getItem('token');

  //Get ProposalDetails
  const getProposalByProposalId = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };
    // Created Proposal will be added to the database through CreateProposal API
    await fetch(`${CONFIG.API_URL}/pm/ProposalByProposalId/${props.data.ProposalID}`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setInstallValue(data.proposal.install_requested ? 'Yes' : 'No');
          const additional = data.proposal_level_accessory_service_charges;

          setAddedAdditionalCharges(additional);
          setAdditionalPricing(additional.length ? additional.filter((charge) => charge.charge_type === 'AD') : []);
        }
      });
  };

  //Get Accessories/Services
  const getAccessoriesServiceList = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        label: props.data.Labels,
        proposal_id: props.data.ProposalID,
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/pm/GetAccessoryServiceList`, requestOptions);
    const { error, AccessoryList, ServiceList, ExistingAccessoryList, ExistingServiceList } = await response.json();
    if (!response.ok || error) {
      return;
    }
    const accessories = AccessoryList.map((element) => element);
    const customcolor = ServiceList.filter(({ type }) => type === 'Custom Color');
    const tripcharge = ServiceList.filter(({ type }) => type === 'Trip Charge');
    const repairCharges = ServiceList.filter(({ type }) => type === 'Repair Charge');

    accessories.unshift({ accessory_name: '─ ─', sku: '' });
    customcolor.unshift({ service_name: '─ ─', sku: '' });
    repairCharges.unshift({ service_name: '─ ─', sku: '' });
    tripcharge.unshift({ service_name: '─ ─', sku: '' });

    setAccessoryList(accessories);
    setTripChargeList(tripcharge);
    setCustomColorList(customcolor);

    setAddedAccessories(ExistingAccessoryList.map(({ sku }) => sku));
    setAddedColors(ExistingServiceList.filter(({ type }) => type === 'Custom Color').map(({ sku }) => sku));
    setAddedTripCharges(ExistingServiceList.filter(({ type }) => type === 'Trip Charge').map(({ sku }) => sku));
  };

  const closeDrawer = (isSaved = false) => {
    props.onClose(isSaved);
  };

  useEffect(() => {
    getProposalByProposalId();
    getAccessoriesServiceList();
    setShowCustomeColor(props.data.selectedwindows.some((ele) => ele.covering_type === 'Shutters'));
  }, []);

  const handleHover = () => setIsImageHovered(!isImageHovered);

  const toggleAccessoryOpen = (index) => setAccessoryOpenIndex(accessoryOpenIndex === index ? -1 : index);

  const handleAccessoryChange = (event, index) => {
    const updatedAccessoryList = [...addedAccessories];
    updatedAccessoryList[index] = event.target.value;

    const filteredAccessoryList = updatedAccessoryList.filter((elem) => elem !== '');
    setAddedAccessories(filteredAccessoryList);
  };

  const AdditionalAccessory = () => setAddedAccessories([...addedAccessories, '']);

  const toggleTripOpen = (index) => settripOpenIndex(tripOpenIndex === index ? -1 : index);

  const handleTripChange = ({ target }, index) => {
    const updatedTripList = [...addedTripCharges];
    updatedTripList[index] = target.value;

    const filteredAccessoryList = updatedTripList.filter((elem) => elem !== '');
    setAddedTripCharges(filteredAccessoryList);
  };

  const AdditionalTrip = () => {
    setAddedTripCharges([...addedTripCharges, '']);
  }; // Add an empty string to the Trip list

  const toggleColorOpen = (index) => {
    setcolorOpenIndex(colorOpenIndex === index ? -1 : index);
  };

  const handleColorChange = (event, index) => {
    const updatedColorList = [...addedColors];
    updatedColorList[index] = event.target.value;

    const filteredAccessoryList = updatedColorList.filter((elem) => elem !== '');
    setAddedColors(filteredAccessoryList);
  };

  const AddtionalColorCharge = () => setAddedColors([...addedColors, '']); // Add an empty string to the Trip list

  useEffect(() => {
    setHasAdditionalCharge(additionalPricing.length === 0 ? 'No' : 'Yes');

    let Additionpricingjson = [];
    additionalPricing.forEach((element) =>
      Additionpricingjson.push({
        additional_charge: element.charge,
        additional_note: element.charge_description,
      })
    );

    setChargeList(Additionpricingjson);
  }, [additionalPricing]);

  function hasDuplicatesCaseSensitive(array) {
    const encounteredElements = {};

    for (const element of array) {
      if (typeof element === 'string') {
        const elementInLowerCase = element.toLowerCase();

        if (encounteredElements[elementInLowerCase]) {
          return true;
        }

        encounteredElements[elementInLowerCase] = true;
      }
    }

    return false;
  }

  useEffect(() => {
    let chargeData = chargeList.filter((ele) => ele.additional_note !== '').map((ele) => ele.additional_note);

    const result = hasDuplicatesCaseSensitive(chargeData);
    setNoteValue(result);
  }, [chargeList]);

  const handleChargeChange = (index, field, value) => {
    const updatedChargeList = [...chargeList];
    updatedChargeList[index][field] = value;
    setChargeList(updatedChargeList);
  };

  const handleAddCharge = () => setChargeList([...chargeList, { additional_charge: '', additional_note: '' }]);

  const handleAdddtionalCharge = (e) => {
    setHasAdditionalCharge(e.target.value);
    setChargeList([...chargeList, { additional_charge: '', additional_note: '' }]);
  };

  const saveAccessoriesServiceList = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: props.data.ProposalID,
        accessories: addedAccessories,
        services: [...addedColors, ...addedTripCharges],
        installation: InstallValue,
        additional_charge_option: hasAdditionalCharge,
        additional_data: chargeList,
      }),
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/UpdateProposalAccessoriesServices`, requestOptions);
    const data = await response.json();
    if (!data.error) {
      closeDrawer(true);
    } else {
      console.error(data.error);
    }
  };

  return (
    <Box p={2} pt={2} className='sideDrawer'>
      <div align='right'>
        <img src={DrawerCloseIcon} onClick={closeDrawer} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='cancel' />
      </div>
      <Box
        mt={0}
        sx={{
          padding: '0px 8px 0px 8px !important',
          display: 'flex',
          flexDirection: 'column',
        }}
        textAlign='center'
        paddingTop={1}
      >
        <FormControl onClick={() => setInstallOpen(!InstallOpen)} className='formcontrol drawer_textfield'>
          <Select
            MenuProps={{
              style: { zIndex: 10000000 },
            }}
            onChange={(e) => setInstallValue(e.target.value)}
            displayEmpty
            align='left'
            size='small'
            onClose={() => setInstallOpen(false)}
            onOpen={() => setInstallOpen(true)}
            open={InstallOpen}
            select
            value={InstallValue}
            startAdornment={
              <InputAdornment className='color cursor' position='start'>
                Install : &nbsp;
              </InputAdornment>
            }
          >
            {Install.map((element) => {
              return (
                <MenuItem key={element.value} value={element.value}>
                  <b>{element.label}</b>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {/* -------------------------------------------------------------- */}
        {accessoryList.length > 1 ? (
          <>
            {addedAccessories.map((item, i) => (
              <FormControl onClick={() => toggleAccessoryOpen(i)} className='formcontrol drawer_textfield' key={i}>
                <Select
                  MenuProps={{
                    style: { zIndex: 10000000 },
                  }}
                  required
                  value={item}
                  name={i}
                  align='left'
                  onChange={(event) => handleAccessoryChange(event, i)}
                  options={EC}
                  size='small'
                  onClose={() => toggleAccessoryOpen(i)}
                  onOpen={() => toggleAccessoryOpen(i)}
                  open={accessoryOpenIndex === i}
                  startAdornment={
                    <InputAdornment className='color cursor' position='start'>
                      Accessory : &nbsp;
                    </InputAdornment>
                  }
                >
                  {accessoryList.map((option) => (
                    <MenuItem key={option.sku} value={option.sku} disabled={addedAccessories.includes(option.sku)}>
                      <b>{option.accessory_name}</b>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))}
            {addedAccessories.length === accessoryList.length - 1 ? null : (
              <Box sx={{ textAlign: 'start', width: 'fit-Content' }} onClick={AdditionalAccessory}>
                <Fab className='install_addtional_price_Add_button' aria-label='back'>
                  <AddOutlinedIcon className='icons' />
                </Fab>
                {addedAccessories.length === 0 ? <span style={{ color: 'white', cursor: 'pointer' }}>ADD ACCESSORY</span> : <span style={{ color: 'white', cursor: 'pointer' }}>ADD ANOTHER ACCESSORY</span>}
              </Box>
            )}
          </>
        ) : null}

        {showCustomeColor ? (
          <>
            {addedColors.map((item, i) => {
              return (
                <FormControl onClick={() => toggleColorOpen(i)} className='formcontrol drawer_textfield' key={i}>
                  <Select
                    MenuProps={{
                      style: { zIndex: 10000000 },
                    }}
                    onClose={() => toggleColorOpen(i)}
                    onOpen={() => toggleColorOpen(i)}
                    open={colorOpenIndex === i}
                    value={item}
                    align='left'
                    name={i}
                    type={item.type}
                    onChange={(event) => handleColorChange(event, i)}
                    displayEmpty
                    size='small'
                    startAdornment={
                      <InputAdornment className='color cursor' position='start'>
                        Custom Color : &nbsp;
                      </InputAdornment>
                    }
                  >
                    {customColorlist.map((option) => (
                      <MenuItem key={option} value={option.sku} disabled={addedColors.includes(option.sku)}>
                        <b>{option.service_name}</b>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            })}
          </>
        ) : null}
        {showCustomeColor ? (
          addedColors.length === customColorlist.length - 1 ? null : (
            <Box sx={{ textAlign: 'start', width: 'fit-Content' }} onClick={AddtionalColorCharge}>
              <Fab className='graybutton_add_color' aria-label='back'>
                <AddOutlinedIcon className='icons' />
              </Fab>
              {addedColors.length === 0 ? <span style={{ color: 'white', cursor: 'pointer' }}>ADD COLOR</span> : <span style={{ color: 'white', cursor: 'pointer' }}>ADD ANOTHER COLOR</span>}
            </Box>
          )
        ) : null}

        {addedTripCharges.map((item, i) => {
          return (
            <FormControl onClick={() => toggleTripOpen(i)} className='formcontrol drawer_textfield' key={i}>
              <Select
                MenuProps={{
                  style: { zIndex: 10000000 },
                }}
                onClose={() => toggleTripOpen(i)}
                onOpen={() => toggleTripOpen(i)}
                open={tripOpenIndex === i}
                value={item}
                align='left'
                name={i}
                type={item.type}
                onChange={(event) => handleTripChange(event, i)}
                displayEmpty
                size='small'
                startAdornment={
                  <InputAdornment className='color cursor' position='start'>
                    Trip Charge : &nbsp;
                  </InputAdornment>
                }
              >
                {TripChargelist.map((option) => (
                  <MenuItem key={option} value={option.sku} disabled={addedTripCharges.includes(option.sku)}>
                    <b>{option.service_name}</b>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        })}
        {addedTripCharges.length === TripChargelist.length - 1 ? null : (
          <Box sx={{ textAlign: 'start', width: 'fit-Content' }} onClick={AdditionalTrip}>
            <Fab className='install_addtional_price_Add_button' aria-label='back'>
              <AddOutlinedIcon className='icons' />
            </Fab>
            {addedTripCharges.length === 0 ? <span style={{ color: 'white', cursor: 'pointer' }}>ADD TRIP CHARGE</span> : <span style={{ color: 'white', cursor: 'pointer' }}>ADD ANOTHER TRIP CHARGE</span>}
          </Box>
        )}

        <hr className='button_top_space button_bottom_space' />

        <FormControl onClick={() => setIsAdditionalChargeOpen(!isAdditionalChargeOpen)} className='formcontrol drawer_textfield_1'>
          <Select
            MenuProps={{
              style: { zIndex: 10000000 },
            }}
            onClose={() => setIsAdditionalChargeOpen(false)}
            onOpen={() => setIsAdditionalChargeOpen(true)}
            open={isAdditionalChargeOpen}
            value={hasAdditionalCharge}
            onChange={handleAdddtionalCharge}
            displayEmpty
            size='small'
            align='left'
            startAdornment={
              <InputAdornment className='color cursor' position='start'>
                Additional Charge : &nbsp;
              </InputAdornment>
            }
          >
            {AdditionalCharge.map((element) => (
              <MenuItem key={element.value} value={element.value}>
                <b>{element.label}</b>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {addedAdditionalCharges !== undefined ? (
          <>
            {hasAdditionalCharge === 'Yes' ? (
              <>
                {chargeList.map((charge, index) => {
                  return (
                    <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                      <TextField
                        type='number'
                        className='short_textfield formcontrol'
                        label={charge.additional_charge ? 'Charge : $' : 'Charge : $ 0.00'}
                        id={`charge-${index}`}
                        variant='filled'
                        value={charge.additional_charge}
                        required
                        onChange={(e) => handleChargeChange(index, 'additional_charge', e.target.value)}
                        InputProps={{
                          style: { fontWeight: 'bold' },
                        }}
                      />
                      <TextField
                        error={noteValue}
                        type='text'
                        className='short_textfield formcontrol'
                        label='Note'
                        id={`note-${index}`}
                        variant='filled'
                        required
                        value={charge.additional_note}
                        onChange={(e) => {
                          handleChargeChange(index, 'additional_note', e.target.value);
                        }}
                        InputProps={{
                          style: { fontWeight: 'bold' },
                        }}
                      />
                    </div>
                  );
                })}
                {noteValue === true ? <FormHelperText className='addtional_note_error'>Note already exists !</FormHelperText> : null}
              </>
            ) : null}
          </>
        ) : (
          ''
        )}
        {hasAdditionalCharge === 'Yes' ? (
          <Box sx={{ textAlign: 'start', width: 'fit-Content' }} onClick={handleAddCharge}>
            <Fab className='graybutton' aria-label='back'>
              <AddOutlinedIcon className='icons' />
            </Fab>
            <span style={{ color: 'white', cursor: 'pointer' }}>ADD ANOTHER CHARGE</span>
          </Box>
        ) : null}
        <hr className='button_top_space button_bottom_space' />
      </Box>

      <Stack direction='column' sx={{ marginLeft: '10px' }} spacing={2}>
        {InstallValue && noteValue !== true ? (
          <span className='drawer_gray_button_span ' onClick={saveAccessoriesServiceList} style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
            <Box className='globalIconMainBox globalDrawerText'>
              <img src={DrawerSaveIcon} className={`primary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} alt='save' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                SAVE
              </Typography>
            </Box>
          </span>
        ) : (
          <span className='drawer_gray_button_span  primary_icons_dimension_disable' style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
            <Box className='globalIconMainBox globalDrawerText'>
              <img src={DrawerSaveIcon} className='primary_icons_dimension_disable' alt='save' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalTextDisable' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                SAVE
              </Typography>
            </Box>
          </span>
        )}

        <span className='drawer_gray_button_span button_top_space' onClick={closeDrawer} style={{ paddingBottom: '30px', paddingLeft: '14px' }}>
          <Box className='globalIconMainBox'>
            <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension' alt='cancel' />
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
              CANCEL
            </Typography>
          </Box>
        </span>
      </Stack>
    </Box>
  );
}
