import { useContext, useState, useEffect } from 'react';
import { Container, TextField, Typography } from '@mui/material';
import fracty from 'fracty';

import DropDown from './DropDown';
import Measurements from './Measurements';
import MeasurementDrawer from './drawers/Measurement';
import CONFIG from '../../config/config.js';
import { WindowContext, WindowDispatchContext } from './WindowProvider';

export function WindowOptions() {
  const dispatch = useContext(WindowDispatchContext);
  const { windowDetails, options } = useContext(WindowContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerLabel, setLabel] = useState('');
  const [numberOfSections, setNumberOfSections] = useState(1);

  const openMeasurementDrawer = ({ target }) => {
    setLabel(target.id);
    setOpenDrawer(true);
  };

  useEffect(() => {
    if (windowDetails.windowConfiguration.sku !== '' && options.windowConfiguration?.length) {
      setNumberOfSections(options.WindowConfiguration.find(({ sku }) => sku === windowDetails.windowConfiguration.sku).service_name[0]);
    }
  }, [windowDetails.windowConfiguration]);

  return (
    <Container
      sx={{
        '&.MuiContainer-root': {
          padding: '0',
        },
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
      }}
    >
      <MeasurementDrawer isOpen={openDrawer} window={windowDetails} onClose={() => setOpenDrawer(false)} save={dispatch} label={drawerLabel} />
      <DropDown options={options.MeasurementType} type={'measurement_type'} value={windowDetails.measurementType} onChange={dispatch} disabled={windowDetails.measurementType === 'Multiple'} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Measurement Type' />
      {/* <>
        <Grid
          item
          xs={0}
          sm={12}
          display={{
            xs: 'none',
            sm: 'block',
          }}
        >
          <Grid style={{ display: 'flex', color: '#B42A2A' }}>
            <ErrorRoundedIcon fontSize='medium' />
            <Typography style={{ textAlign: 'left' }}>
              &nbsp;
              <span>This new Measurement Type does not match the previous type(s).Please confirm the measurements submitted match this type.</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={0}
          display={{
            xs: 'block',
            sm: 'none',
          }}
        >
          <Grid style={{ display: 'flex', color: '#FFF' }} className='warning-box'>
            <ErrorRoundedIcon fontSize='medium' />
            <Typography style={{ textAlign: 'left' }}>
              &nbsp;
              <span>This new Measurement Type does not match the previous type(s).Please confirm the measurements submitted match this type.</span>
            </Typography>
          </Grid>
        </Grid>
      </> */}
      <Measurements label={'Width X Height'} disabled={windowDetails.size === 'Multiple'} value={windowDetails.size} onClick={openMeasurementDrawer} />
      <DropDown options={options.WindowDepth} value={windowDetails.windowDepth.sku} onChange={dispatch} disabled={windowDetails.windowDepth?.service_name === 'Multiple'} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Window Depth' />
      {windowDetails.windowDepth?.service_name === 'Other' ? (
        <Measurements
          label={'Depth'}
          value={`${fracty(windowDetails.windowDepthMeasurement)}"`}
          sx={{
            marginLeft: '20%',
            marginBottom: '5px',
            marginTop: '5px',
            width: '80%',
          }}
          onClick={openMeasurementDrawer}
        />
      ) : null}
      {!(windowDetails.measurementType === 'Quick' || windowDetails.measurementType === '') ? (
        <DropDown options={options.WindowConfiguration} value={windowDetails.windowConfiguration.sku} disabled={windowDetails.windowConfiguration?.service_name === 'Multiple'} onChange={dispatch} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Window Configuration' />
      ) : null}
      {numberOfSections > 1
        ? Array.from({ length: numberOfSections }, (_, index) => `Section ${index + 1}`).map((label) => (
            <Measurements
              label={label}
              value={`${fracty(windowDetails.sections[label.toLowerCase().split(' ').join('')])}"`}
              sx={{
                marginLeft: '20%',
                marginBottom: '5px',
                marginTop: '5px',
                width: '80%',
              }}
              onClick={openMeasurementDrawer}
            />
          ))
        : null}

      <hr className='top_bottom_space' />

      <DropDown options={options.ExistingCovering} value={windowDetails.existingCoverings.sku} onChange={dispatch} disabled={windowDetails.existingCoverings?.service_name === 'Multiple'} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Existing Coverings' />
      <DropDown options={options.BuildType} value={windowDetails.buildType.sku} onChange={dispatch} disabled={windowDetails.buildType?.service_name === 'Multiple'} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Build Type' />
      <DropDown options={options.MountSurface} value={windowDetails.mountSurface.sku} onChange={dispatch} disabled={windowDetails.mountSurface?.service_name === 'Multiple'} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Mount Surface' />
      <DropDown options={options.Obstructions} value={windowDetails.obstructions.sku} onChange={dispatch} disabled={windowDetails.obstructions?.service_name === 'Multiple'} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Obstructions' />
      {windowDetails.obstructions?.service_name === 'Yes' ? (
        <>
          <TextField
            id='Obstructions Details'
            label='Obstruction Details'
            variant='outlined'
            required
            onChange={({ target }) =>
              dispatch({
                action: 'SET_OBSTRUCTIONS_DETAILS',
                payload: target.value,
              })
            }
            value={windowDetails.obstructionsDetails}
            InputProps={{
              style: {
                fontWeight: 'bold',
                color: windowDetails.size === 'Multiple' ? '#B53030' : 'inherit',
                fontFamily: 'Roboto',
              },
            }}
            sx={{
              marginLeft: '20%',
              marginBottom: '5px',
              marginTop: '5px',
              width: '80%',
              '.MuiInputBase-input': {
                WebkitTextFillColor: 'inherit',
                cursor: 'pointer',
              },
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#B53030',
                cursor: 'not-allowed',
              },
            }}
          />
          {/* <Typography sx={{ marginLeft: '20%', widht: '80%' }}>Seperate out each obsturction via a ';'</Typography> */}
        </>
      ) : null}
      <DropDown options={options.Ladder} value={windowDetails.ladder.sku} onChange={dispatch} disabled={windowDetails.ladder?.service_name === 'Multiple'} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Ladder' />
      {windowDetails.measurementType === 'Shutters' ? (
        <>
          <DropDown options={options.Windowsill} value={windowDetails.windowSill.sku} onChange={dispatch} disabled={windowDetails.windowSill === 'Multiple'} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Window Sill' />
          <DropDown options={options.VerticalConfiguration} value={windowDetails.verticalConfigurations?.sku} onChange={dispatch} disabled={windowDetails.verticalConfiguration === 'Multiple'} startAdornment={<span style={{ color: '#DB3131' }}>&nbsp;*</span>} label='Vertical Configuration' />
          {windowDetails.verticalConfigurations?.service_name === '2 Sections' ? (
            <>
              <Measurements
                label={'Section One Top'}
                value={`${fracty(windowDetails.verticalSections.SectionOneTop)}"`}
                onClick={openMeasurementDrawer}
                sx={{
                  marginLeft: '20%',
                  marginBottom: '5px',
                  marginTop: '5px',
                  width: '80%',
                }}
              />
              <Measurements
                label={'Section Towo Bottom'}
                value={`${fracty(windowDetails.verticalSections.SectionTwoBottom)}"`}
                disabled={true}
                sx={{
                  marginLeft: '20%',
                  marginBottom: '5px',
                  marginTop: '5px',
                  width: '80%',
                }}
              />
            </>
          ) : null}
        </>
      ) : null}
    </Container>
  );
}

export default WindowOptions;
