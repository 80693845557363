import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import CONFIG from '../../config/config.js';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import searchIcon from '../../assets/icons/primary/search.svg';
import searchHovIcon from '../../assets/icons/primary/WithHovIcon/search-hov.svg';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0!important', // Remove padding
  },
}));

const SearchCustomerByDetails = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [pNumber, setPNumber] = useState('');
  const [searchString, setSearchString] = useState('');
  const vertical = 'top';
  const horizontal = 'center';

  const [warnmsg, setWarnmsg] = React.useState(false);

  const [isImageHovered, setIsImageHovered] = useState(false);

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const handleWarnClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setWarnmsg(false);
  };

  const SearchCustomer = async () => {
    await fetch(`${CONFIG.API_URL}/cm/SearchCustomersBySelectedField`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        firstname: firstName,
        lname: lastName,
        address: address,
        city: city,
        zip: zipCode,
        PNumber: pNumber,
        SearchString: searchString,
        email: email,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          navigate('/tbm/ps/searchCustomer/searchresult', {
            state: { data: data },
          });
        } else if (data.error_message === 'Mssql is disconnected!') {
          navigate('/tbm/ps/searchCustomer/searchresult', {
            state: { data: data.error_message },
          });
        } else {
          navigate('/tbm/ps/searchCustomer/searchresult', {
            state: { data: false },
          });
        }
      });
  };
  function Search() {
    if (firstName || lastName || email || address || city || zipCode || pNumber || searchString) {
      SearchCustomer();
    } else {
      setWarnmsg(true);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar
          className='snackbar_bar_postition'
          open={warnmsg}
          anchorOrigin={{ vertical, horizontal }}
          onClose={() => {
            setWarnmsg(false);
          }}
        >
          <Alert className='Success2 mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel_2'>
                <img onClick={handleWarnClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' style={{ textAlign: 'center', margin: '0px auto' }} alt='DrawerAlertIcon' />

                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Please fill details in any fields !</div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <br />
      <Stack spacing={2} sx={{ width: '100%', flexDirection: 'column' }}>
        <Typography className='search_customer_heading_1 button_bottom_space common_line_height_css'>New Customer Search</Typography>
        <Typography sx={{ paddingBottom: '10px !important' }} className='paragraph common_line_height_css'>
          Note : You must search for Flagged Houses before adding a new customer!
        </Typography>
        <Typography className='paragraph common_line_height_css'>• For Flagged House searches, Use house number and zip code only.</Typography>
        <Typography className='paragraph common_line_height_css common_text_height_css'>• Searched text will autofill on Add Customer</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '20px',
          }}
        >
          <TextField
            id='HouseNumber'
            autoComplete='off'
            className='ADD_CUSTOMER_TEXTFIELD_1'
            label='Search House Number'
            name='SearchHouseNumber'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
              },
            }}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
          <TextField
            autoComplete='off'
            className='ADD_CUSTOMER_TEXTFIELD'
            label='Search Zip Code'
            name='SearchZipCode'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
              },
            }}
            onChange={(e) => {
              setZipCode(e.target.value);
            }}
          />
          <hr className='button_top_space button_bottom_space' />
          <Typography sx={{ padding: 0 }} className='search_customer_heading_1 button_bottom_space common_line_height_css'>
            Existing Customer Search
          </Typography>
          <Typography className='paragraph common_line_height_css'>• ONLY use this Search if you KNOW the customer is in our system.</Typography>
          <Typography sx={{}} className='paragraph button_bottom_space common_line_height_css common_text_height_css'>
            {' '}
            • For Existing Customer searches, it is not necessary to type out full words or use every field.{' '}
          </Typography>

          <TextField
            autoComplete='off'
            className='ADD_CUSTOMER_TEXTFIELD_1'
            label='Search By First Name'
            name='SearchByFirstName'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <TextField
            autoComplete='off'
            className='ADD_CUSTOMER_TEXTFIELD'
            label='Search By Last Name'
            name='SearchByLastName'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
          <TextField
            autoComplete='off'
            className='ADD_CUSTOMER_TEXTFIELD'
            label='Search By Address'
            name='SearchByAddress'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
          <TextField
            autoComplete='off'
            className='ADD_CUSTOMER_TEXTFIELD'
            label='Search By Cross Street'
            name='SearchByCrossStreet'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
          <TextField
            autoComplete='off'
            className='ADD_CUSTOMER_TEXTFIELD'
            label='Search By City'
            name='SearchByCity'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
          <TextField
            autoComplete='off'
            className='ADD_CUSTOMER_TEXTFIELD'
            label='Search By Zip Code'
            name='SearchByZipCode'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
              },
            }}
            onChange={(e) => {
              setZipCode(e.target.value);
            }}
          />
          <TextField
            autoComplete='off'
            className='ADD_CUSTOMER_TEXTFIELD'
            label='Search By Phone'
            name='SearchByPhone'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
              },
            }}
            onChange={(e) => {
              setPNumber(e.target.value);
            }}
          />
          <TextField
            autoComplete='off'
            className='ADD_CUSTOMER_TEXTFIELD'
            label='Search By Email'
            name='SearchByEmail'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
              },
            }}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <hr className='button_top_space button_bottom_space' />

          <Typography className='search_customer_heading_invioce button_bottom_space common_line_height_css'>Work Order Search</Typography>
          <Typography className='paragraph common_line_height_css'>• To search by work order, Please input full work order number. </Typography>
          <Typography sx={{}} className='paragraph button_bottom_space common_line_height_css common_text_height_css'>
            • Do not search any other field while searching by work order.{' '}
          </Typography>

          <TextField
            autoComplete='off'
            className='ADD_CUSTOMER_TEXTFIELD_1'
            label='8-digit Work Order #'
            name='8digitinvoice#'
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
              },
              marginBottom: '20px',
            }}
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
          />
        </Box>

        <hr />

        <section className='padding_bottom_class' style={{ display: 'flex', marginTop: '0px' }}>
          <Button
            id='SearchCustomer'
            sx={{ padding: '0px 8px' }}
            className='search_details_button button_top_space '
            onClick={() => {
              Search();
            }}
            onMouseEnter={handleHoverFun}
            onMouseLeave={handleMouseLeaveFun}
          >
            <Box className='globalIconMainBox globalTextDecoration'>
              {/* <img
                className={`primary_icons_dimension ${isImageHovered ? "hovered" : ""
                  }`}
                src={searchIcon}
                alt="searchIcon"
              /> */}

              {isHovered ? <img className='primary_icons_dimension_1' src={searchHovIcon} alt='searchHovIcon' /> : <img className='primary_icons_dimension_1' src={searchIcon} alt='searchIcon' />}

              <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} className='globalText'>
                SEARCH / ADD CUSTOMER
              </Typography>
            </Box>
          </Button>
        </section>
      </Stack>
    </>
  );
};

export default SearchCustomerByDetails;
