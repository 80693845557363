import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, TableContainer, Table as MuiTable, TableBody, TablePagination, Tooltip, Typography, Zoom } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CONFIG from '../../config/config.js';
import fracty from 'fracty';

import LongText from '../LongText';
import MiscCheckIcon from '../../assets/icons/misc/misc-confirm.svg';
import Table, { formatLabel } from '../Shared/Table';

const TurquoiseTooltipforNotes = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#faf3dd',
    color: '#000000',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 300,
    maxHeight: 300,
    textAlign: 'left',
    align: 'left',
    border: '1px solid #000000',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#f2f3f2',
      border: '1px solid #000000',
    },
    top: '100%', // Position the arrow at the bottom of the tooltip box
    right: '100', // Position the arrow at the right side of the tooltip box
  },
}));

export function WindowsTable({ windows, state, disabled }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);

  const rows = windows.map((window) => {
    const { proposal_measurement_type_specific_factor, proposal_measurement_type, proposal_additional_sku } = window;

    const measurementType =
      proposal_measurement_type === 'Quick'
        ? '(Q)'
        : proposal_measurement_type === 'Shutters'
        ? '(SH)'
        : proposal_measurement_type === 'Blinds'
        ? '(BL)'
        : `(${proposal_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('')})`;

    const baseWindow = {
      id: window.window_id,
      name: (
        <>
          <span style={{ paddingRight: +window.item_sequence > 9 ? '20px' : '30px' }}>{+window.item_sequence}</span>
          {!disabled ? (
            <Link
              className={window.covering_status === 'applied' ? 'link3' : 'red-link-for-covering_not_applied ellipsis2'}
              to='/tbm/ps/editingwindowproposal'
              state={{
                ...state,
                covering_status: window.covering_status,
                row_id: window.window_id,
                window_name: window.window_name,
              }}
            >
              <LongText>{window.window_name}</LongText>
            </Link>
          ) : (
            <LongText>{window.window_name}</LongText>
          )}
        </>
      ),
      applied: window.confirm_to_sales_order ? (
        <img
          src={MiscCheckIcon}
          alt='Confirmed to Sales Order'
          style={{
            width: '1rem',
          }}
        />
      ) : null,
      size: (
        <>
          {`${fracty(window.proposal_window_width)}" X ${fracty(window.proposal_window_height)}"`} <span className={proposal_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
      category: window.covering_type,
      retail: `$${(+window.suggested_price).toFixed(2)}`,
      install: `$${(+window.covering_install_price + +window.proposal_additional_sku_price).toFixed(2)}`,
      total: `$${(+window.total_covering_price_with_install).toFixed(2)}`,
      build: window.build_type,
      depth: proposal_measurement_type_specific_factor.WindowDepth,
      mount: window.mount_surface,
      obst:
        proposal_additional_sku.find((sku) => sku.type === 'Obstructions').sku_name === 'Yes' ? (
          <TurquoiseTooltipforNotes title={<Typography>{JSON.parse(proposal_additional_sku.find((sku) => sku.type === 'Obstructions').additional_detail).join(';')}</Typography>} placement='bottom-end' TransitionComponent={Zoom} arrow>
            <Typography className={'link ellipsis'}>Y</Typography>
          </TurquoiseTooltipforNotes>
        ) : (
          'N'
        ),
      ladder: window.ladder,
      exstCov: window.existing_covering,
      config: proposal_measurement_type_specific_factor.WindowConfiguration || `- -`,
      notes: window.proposal_detail_notes?.length ? (
        <TurquoiseTooltipforNotes
          title={
            <Typography>
              {window.proposal_detail_notes.map((note, index) => (
                <div key={note.id}>
                  {index > 0 && <br />}
                  <b>Note:</b> {note.content}
                </div>
              ))}
            </Typography>
          }
          placement='bottom-end'
          TransitionComponent={Zoom}
          arrow
        >
          <Typography className={'link ellipsis'}>{window.proposal_detail_notes.length}</Typography>
        </TurquoiseTooltipforNotes>
      ) : null,
    };

    return {
      ...baseWindow,
    };
  });
  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    dataKey: key,
  }));

  return (
    <Box>
      <TableContainer
        sx={{
          width: {
            xl: 'fit-content',
          },
        }}
      >
        <MuiTable className='proposal_table' aria-labelledby='tableTitle' size='small'>
          <Table.Header columns={columns} />
          <TableBody>
            {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((window) => (
              <Table.Row key={window.id} columns={columns} row={window} />
            ))}
          </TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]}
        component='div'
        count={windows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPageprp) => {
          setPage(newPageprp);
        }}
        onRowsPerPageChange={({ target }) => {
          setRowsPerPage(parseInt(target.value, 10));
          setPage(0);
        }}
      />
    </Box>
  );
}

export default WindowsTable;
