import React, { useState, useEffect } from 'react';
import CONFIG from '../config/config.js';

const LongText = ({ children }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const limit = CONFIG.GlobalTruncateCharacterLimit;
  const mobileBreakpoint = 280;
  const mobileContentLimit = 15;

  const isMobile = windowWidth >= mobileBreakpoint && windowWidth <= 767;
  const isDesktop = windowWidth >= 768;
  const isContentLong = children?.length > limit;

  const truncateContent = (text, length) => {
    if (text?.length <= length) {
      return text;
    }

    let truncatedText = text?.substring(0, length);
    const lastSpaceIndex = truncatedText?.lastIndexOf(' ');

    if (lastSpaceIndex !== -1) {
      truncatedText = truncatedText?.substring(0, lastSpaceIndex);
    }

    return truncatedText + '...';
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (children === undefined) {
    return null;
  }

  if (isMobile && isContentLong) {
    return <>{truncateContent(children, mobileContentLimit)}</>;
  }

  if (isDesktop) {
    return <>{truncateContent(children, limit)}</>;
  }

  return <>{truncateContent(children, limit)}</>;
};

export default LongText;
