import React from "react";
import { Grid, Box } from "@mui/material";

const MarketingMessage = ({ customStyles }) => (
  <Grid item md={6} style={customStyles.box}>
    <Box sx={{ paddingLeft: "25px" }}>
      <div style={customStyles.heading}>
        THE BLINDMAN <br />
        DIFFERENCE
      </div>
      <div style={customStyles.p}>
        You can trust The Blindman for innovative, technically
        <br /> advanced, and efficient window covering solutions. From the very
        first blind we built in 1990, our quality craftsmanship and unmatched
        customer service have helped us become Utah’s largest custom window
        covering manufacturer.
        <br />
        &nbsp;
        <br />{" "}
      </div>
    </Box>
  </Grid>
);

export default MarketingMessage;
