import { useEffect } from 'react';
import WCPPage from '../ProposalForCustomer';
import { useLocation } from 'react-router-dom';
export default function PreviewProposal() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <WCPPage />;
}
