/** @format */

import { forwardRef, useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Drawer, Grid, Snackbar, Alert as MuiAlert, Tooltip, Typography, Zoom } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import Skeleton from 'react-loading-skeleton';
import { PDFDownloadLink } from '@react-pdf/renderer';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';

import SalesOrderShareSO from './SalesOrderShareSO';
import SaveIcon from '../../assets/icons/secondary/2nd-save.svg';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import CONFIG from '../../config/config.js';
import Pdf from './ConvertToSalesOrderPdf/Pdf';
import ShareIcon from '../../assets/icons/primary/share.svg';
import ShareHovIcon from '../../assets/icons/primary/WithHovIcon/share-hov.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import NoteMiscIcon from '../../assets/icons/misc/misc-note.svg';
import NotesDrawer from '../Proposals/NotesDrawer';
import LongText from '../LongText';
import SalesOrderTable from './SalesOrderTable';
import { AlertDispatchContext } from '../Shared/Alerts.jsx';
import ContinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const TurquoiseTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0ca8b0',
    color: '#ffffff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#0ca8b0',
      border: '1px solid #0ca8b0',
    },
  },
}));

const useStyles = makeStyles({
  paper: {
    width: '90%',
    background: 'rgba(69, 157, 166, 0.70)  !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

let filename;

const combineArrays = (data) => {
  return Object.values(data).flat();
};

export default function FinalizeSalesOrder() {
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatchAlert = useContext(AlertDispatchContext);

  if (!location.state) {
    navigate('/pagenotfound');
  }

  let lastName = location.state.customerDetails.customerdata.last_name;
  let city = location.state.customerDetails.customerdata.city;

  const token = sessionStorage.getItem('token');
  const [commonData, setCommonData] = useState({
    id: location.state.salesOrderId,
    path: 'salesOrder',
    customerDetails: location.state.customerDetails,
  });
  const [showDiscount, setShowDiscount] = useState(true);
  const [isDrawerOpenforSalesOrderShareSO, setisDrawerOpenforSalesOrderShareSO] = useState(false);
  const [successmsgformail, setSuccessMsgForMail] = useState(false);
  const [successmsg, setSuccessMsg] = useState('');
  const [salesOrderWindows, setSalesOrderWindows] = useState([]);
  const [SalesOrderName, setSalesOrderName] = useState('');
  const [salesOrderId, setSalesOrderId] = useState(location.state.salesOrderId);
  const [customerId, setCustomerId] = useState();
  const [addressId, setAddressId] = useState();
  const [SalesOrderDetail, setSalesOrderDetail] = useState({});
  const [SalesOrderLevelAccessoryServiceCharges, setSalesOrderLevelAccessoryServiceCharges] = useState();
  const [ProposalRetailPrice, setProposalRetailPrice] = useState('');
  const [Accessoriescharge, setAccessoriesCharge] = useState([]);
  const [Servicescharge, setServicesCharge] = useState([]);
  const [AdditionalCharges, setAdditionalCharges] = useState([]);
  const [InstallationCost, setInstallationCost] = useState('');
  const [discountDollar, setDiscountDollar] = useState('');
  const [Tax, setTax] = useState('');
  const [TotalCost, setTotalCost] = useState('');
  const [loading, setLoading] = useState(true);
  const [file, setFile] = useState('');
  const [fileContent, setFileContent] = useState([]);
  const [SalesOrderAcceptanceStatus, setSalesOrderAcceptanceStatus] = useState();
  const [agreementStatus, setAgreementStatus] = useState();
  const iconclasses = makeStyles(iconStyles)();
  const [allNotes, setAllNotes] = useState({
    customerNotes: [],
    salesOrderNotes: [],
    salesOrderDetailsNotes: [],
    proposalNotes: [],
    windowSetNotes: [],
    proposalDetailsNotes: [],
    windowNotes: [],
  });

  // error handling states
  const [AdobeSignCheckStatusErrorAlert, setAdobeSignCheckStatusErrorAlert] = useState(false);
  const [AdobeSignCheckStatusErrorMsg, setAdobeSignCheckStatusErrorMsg] = useState('');
  const [SalesOrderDetailsErrorAlert, setSalesOrderDetailsErrorAlert] = useState(false);
  const [GetAllNotesApiErr, setGetAllNotesApiErr] = useState(false);
  const [GetAllNotesApiErrMsg, setGetAllNotesApiErrMsg] = useState('');
  const [SalesOrderDetailsErrorMsg, setSalesOrderDetailsErrorMsg] = useState('');

  //  hover states
  const [isHovered, setIsHovered] = useState(false);
  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  function iconStyles() {
    return {
      customerIcon: {
        paddingTop: 5,
        color: '#0CA8B0',
      },
      proposalsentIcon: {
        paddingTop: 5,
        color: '#0CA8B0',
      },
    };
  }
  const [disableSend, setDisableSend] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!sessionStorage.getItem('hasAdobeToken')) {
      dispatchAlert({
        action: 'OPEN_ALERT',
        payload: {
          id: 'link-adobe-account',
          autoHideDuration: null,
          message: 'You do not have an Adobe Sign account linked to your profile. Please link your account to continue.',
          severity: 'info',
          actions: [
            {
              label: 'Link Account',
              type: 'primary',
              startIcon: <img src={ContinueIcon} className='primary_icons_dimension' />,
              onClick: () => {
                const REDIRECT_URI = `${window.location.origin}/tbm/ps/dashboard`;
                const { REACT_APP_CLIENT_ID, REACT_APP_SCOPE } = process.env;
                window.location.href = `https://theblindman.na1.echosign.com/public/oauth/v2?redirect_uri=${REDIRECT_URI}&response_type=code&client_id=${REACT_APP_CLIENT_ID}&scope=${REACT_APP_SCOPE}`;
              },
            },
            {
              label: 'Cancel',
              type: 'secondary',
              startIcon: <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension' />,
              onClick: () =>
                dispatchAlert({
                  action: 'CLEAR_ALERT',
                  payload: { id: 'link-adobe-account' },
                }),
            },
          ],
        },
      });
    } else {
      setDisableSend(false);
    }

    init();

    const timer = setTimeout(() => setLoading(false), 1_000);

    return () => clearTimeout(timer);
  }, []);

  const init = async () => {
    await getSalesOrder();
    await getNotes();
    await getNotesByReferenceId();
  };

  const getAgreementStatus = async (id) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        agreementID: id,
        sales_order_id: salesOrderId,
        adobe_refresh_token: sessionStorage.getItem('adobe_refresh_token'),
      }),
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/adobesigncheckstatus`, requestOptions);
    const data = await response.json();
    if (!data.error) {
      setAgreementStatus(data.data.adobe_sign_status);
      if (data.data.adobe_sign_status === 'COMPLETED') {
        navigate('/tbm/ps/salesorderdetails', {
          state: {
            Custdata: {
              Addressid: location.state.customerDetails.customerdata.address_id,
              customerId: location.state.customerDetails.customerdata.customer_id,
              Sales_Order_Id: location.state.salesOrderId,
              Invoice: data.invoices,
              Sales_rep: null,
            },
          },
        });
      }
    } else {
      setAdobeSignCheckStatusErrorMsg(data.error_message);
      setAdobeSignCheckStatusErrorAlert(true);
    }
  };

  const getNotes = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/so/GetAllNotes/${salesOrderId}`, requestOptions);
    const { error, error_message, ...data } = await response.json();
    if (response.ok && !error) {
      setAllNotes(data);
      setNotesContent([...combineArrays(data), ...notesContent]);
    } else {
      setGetAllNotesApiErrMsg(error_message);
      setGetAllNotesApiErr(true);
    }
  };

  const getSalesOrder = async () => {
    const response = await fetch(`${CONFIG.API_URL}/so/SalesOrderDetails/${salesOrderId}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const { error, error_message, SalesOrderLevelAccessoryServiceCharges, customer, SalesOrder, SalesOrderDetails } = await response.json();
    if (!response.ok || error) {
      setSalesOrderDetailsErrorMsg(error_message);
      setSalesOrderDetailsErrorAlert(true);
      return;
    }

    if (SalesOrder.adobe_sign_id !== null) {
      getAgreementStatus(SalesOrder.adobe_sign_id);
    }
    setAccessoriesCharge(SalesOrderLevelAccessoryServiceCharges.filter((charge) => charge.charge_type === 'X'));
    setServicesCharge(SalesOrderLevelAccessoryServiceCharges.filter((charge) => charge.charge_type === 'S'));
    setAdditionalCharges(SalesOrderLevelAccessoryServiceCharges.filter((charge) => charge.charge_type === 'AD'));
    setCustomerId(SalesOrder.customer_id);
    setAddressId(SalesOrder.address_id);
    setSalesOrderWindows(SalesOrderDetails);
    setSalesOrderDetail(SalesOrder);
    setSalesOrderLevelAccessoryServiceCharges(SalesOrderLevelAccessoryServiceCharges);
    setSalesOrderName(SalesOrder.sales_order_name);
    setSalesOrderAcceptanceStatus(SalesOrder.adobe_sign_status);
    setProposalRetailPrice(SalesOrder.retail_total_with_install);
    setInstallationCost((+SalesOrder.additional_install_service_charge + +SalesOrder.sales_order_install_total).toFixed(2));
    setDiscountDollar(SalesOrder.sales_order_discount_dollar);
    setShowDiscount(SalesOrder.show_disc);
    setTax(SalesOrder.sales_order_sales_tax);
    setTotalCost(SalesOrder.sales_order_final_total);

    filename = `Sales Order for ${customer.first_name} ${customer.last_name}.pdf`;

    setCommonData({
      id: location.state.salesOrderId,
      path: 'salesOrder',
      SalesOrder: SalesOrder,
      SalesOrderDetails: SalesOrderDetails,
      SalesOrderLevelAccessoryServiceCharges: SalesOrderLevelAccessoryServiceCharges,
      customerDetails: location.state.customerDetails,
      Notes: allNotes,
    });
  };

  const fetchDeliveryDrawerData = (drawerstatus, mailStatus, successMsg) => {
    setisDrawerOpenforSalesOrderShareSO(drawerstatus);
    if (mailStatus === true) {
      setSuccessMsgForMail(true);
      setSuccessMsg(successMsg);
    }
  };

  const handleShareSO = async () => {
    setisDrawerOpenforSalesOrderShareSO(true);
  };

  const uploadFile = (file) => {
    const blobToBase64 = (blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };
    if (file !== null) {
      setFile(file);
      blobToBase64(file).then((res) => {
        setFileContent(res);
      });
    }
  };

  const vertical = 'top';
  const horizontal = 'center';

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessMsgForMail(false);
  };

  const generateSalesOrderPdf = () => {
    const { salesOrderId, ProposalDetails, remeasure, data } = location.state;
    const customerdata = location.state.customerDetails.customerdata;
    const { customer_id, address_id } = customerdata;

    const state = {
      path: 'PreviewSalesOrder',
      salesOrderId: salesOrderId,
      customerId: customer_id,
      customer: customerdata,
      addressId: address_id,
      salesOrder: SalesOrderDetail,
      accessoryServiceCharges: SalesOrderLevelAccessoryServiceCharges,
      windows: salesOrderWindows,
      proposalDetails: ProposalDetails,
      remeasure: remeasure,
      data: data,
      notes: {
        customerNotes: allNotes.customerNotes.filter((note) => note.visible),
        salesOrderNotes: allNotes.salesOrderNotes.filter((note) => note.visible),
        salesOrderDetailsNotes: allNotes.salesOrderDetailsNotes.filter((note) => note.visible),
        proposalNotes: allNotes.proposalNotes.filter((note) => note.visible),
        proposalDetailsNotes: allNotes.proposalDetailsNotes.filter((note) => note.visible),
        windowSetNotes: allNotes.windowSetNotes.filter((note) => note.visible),
        windowNotes: allNotes.windowNotes.filter((note) => note.visible),
      },
    };
    navigate(`/tbm/ps/generate-salesorder-pdf`, { state });
  };

  const [isImageHovered, setIsImageHovered] = useState(false);

  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);

  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  const [isImageHovered3, setIsImageHovered3] = useState(false);

  const handleHover3 = () => {
    setIsImageHovered3(!isImageHovered3);
  };

  const [isImageHovered4, setIsImageHovered4] = useState(false);

  const handleHover4 = () => {
    setIsImageHovered4(!isImageHovered4);
  };

  const NavigateToCustomerDetails = (e) => {
    navigate('/tbm/ps/customerdetails', {
      state: {
        Custdata: {
          custid: location.state.customerDetails.customerdata.customer_id,
          Addressid: location.state.customerDetails.customerdata.address_id,
        },
      },
    });
  };

  const [editingNote, setEditingNote] = useState(false);
  const [noteDrawerDetails, setNoteDrawerDetails] = useState({});
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);
  const [notesContent, setNotesContent] = useState([]);

  const closeNotesDrawer = (alert) => {
    setNotesDrawerOpen(false);
    setEditingNote(false);
    setNoteDrawerDetails({});

    if (alert.message === 'Note added successfully!' || alert.message === 'Note updated successfully!') {
      getNotesByReferenceId();
    }
  };
  const getNotesByReferenceId = async () => {
    const referenceId = salesOrderId;
    await fetch(`${CONFIG.API_URL}/cm/Notes/${referenceId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setNotesContent(data.Notes);
        }
      });
  };
  const openNotesDrawer = (note) => {
    setEditingNote(!!note);
    setNoteDrawerDetails({
      name: SalesOrderName,
      referenceId: salesOrderId,
      noteId: note ? note.id : null,
    });
    setNotesDrawerOpen(true);
  };

  return (
    <>
      <div align='center'>
        <Snackbar className='snackbar_bar_postition' open={GetAllNotesApiErr} anchorOrigin={{ vertical, horizontal }} onClose={() => setGetAllNotesApiErr(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img
                onClick={() => {
                  setGetAllNotesApiErr(false);
                }}
                src={RedDrawerCancelIcon}
                alt='RedDrawerCancelIcon'
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{GetAllNotesApiErrMsg}</div>
            </div>
          </Alert>
        </Snackbar>

        {/* Adobe Sign Check Status Error Alert */}
        <Snackbar className='snackbar_bar_postition' open={AdobeSignCheckStatusErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setAdobeSignCheckStatusErrorAlert(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img
                onClick={() => {
                  setAdobeSignCheckStatusErrorAlert(false);
                }}
                src={RedDrawerCancelIcon}
                alt='RedDrawerCancelIcon'
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={RedExclamationIcon} alt='' className='primary_icons_dimension alertImageMargin' />
              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{AdobeSignCheckStatusErrorMsg}</div>
            </div>
          </Alert>
        </Snackbar>

        {/* Sales Order Details Error Alert */}
        <Snackbar className='snackbar_bar_postition' open={SalesOrderDetailsErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setSalesOrderDetailsErrorAlert(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img
                onClick={() => {
                  setSalesOrderDetailsErrorAlert(false);
                }}
                src={RedDrawerCancelIcon}
                alt='RedDrawerCancelIcon'
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{SalesOrderDetailsErrorMsg}</div>
            </div>
          </Alert>
        </Snackbar>

        <Snackbar className='snackbar_bar_postition' open={successmsgformail} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setSuccessMsgForMail(false)}>
          <Alert className='Success2 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img onClick={handleSuccessClose} src={DrawerCancelIcon} alt='DrawerCancelIcon' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' alt='DrawerConfirmIcon' />

              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{successmsg}</div>
            </div>
          </Alert>
        </Snackbar>

        <Drawer
          PaperProps={{
            sx: { width: '90%' },
          }}
          classes={{ paper: classes.paper }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          anchor='right'
          open={isDrawerOpenforSalesOrderShareSO}
          onClose={() => setisDrawerOpenforSalesOrderShareSO(true)}
          className='drawer_Header_page'
        >
          <SalesOrderShareSO
            func={fetchDeliveryDrawerData}
            data={{
              SalesOrder: SalesOrderDetail,
              SalesOrderDetails: salesOrderWindows,
              SalesOrderLevelAccessoryServiceCharges: SalesOrderLevelAccessoryServiceCharges,
              customerDetails: location.state.customerDetails,
              salesOrderId: location.state.salesOrderId,
              filename: filename,
              filecontent: fileContent,
            }}
          />
        </Drawer>

        <Drawer
          classes={{ paper: classes.paper }}
          PaperProps={{
            sx: { width: '90%' },
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          anchor='right'
          open={notesDrawerOpen}
          onClose={() => setNotesDrawerOpen(false)}
          className='drawer_Header_page'
        >
          <NotesDrawer closeDrawer={closeNotesDrawer} editing={editingNote} type='sales_order_line_item' details={noteDrawerDetails} />
        </Drawer>

        <div className='heading-box'>
          <h2 className='clickable_header all_pages_h2_header'>
            {SalesOrderAcceptanceStatus === 'OUT_FOR_ACCEPTANCE' ? (
              <TurquoiseTooltip title='Sales Order has sent' placement='top' TransitionComponent={Zoom} arrow>
                <StarOutlineIcon className={iconclasses.proposalsentIcon} />
              </TurquoiseTooltip>
            ) : null}
            {SalesOrderAcceptanceStatus === 'ACCEPTED' || SalesOrderAcceptanceStatus === 'COMPLETED' ? (
              <TurquoiseTooltip title='All Good! Customer has approved Sales Order' placement='top' TransitionComponent={Zoom} arrow>
                <StarIcon className={iconclasses.customerIcon} />
              </TurquoiseTooltip>
            ) : null}{' '}
            <LongText>{SalesOrderName}</LongText>
          </h2>

          <h3
            className='clickable_header_1 all_pages_h2_header button_top_space common_line_height_css'
            style={{ textTransform: 'capitalize' }}
            onClick={() => {
              navigate(`/tbm/ps/customerdetails`, {
                state: {
                  Custdata: {
                    custid: customerId,
                    Addressid: addressId,
                  },
                },
              });
            }}
          >
            <LongText>{`${lastName} | ${city}`}</LongText>
          </h3>
          <img
            alt='NOTES'
            style={{
              width: '20px',
              color: '#008593',
              cursor: 'pointer',
              position: 'relative',
              float: 'right',
              top: '-55px',
            }}
            src={NoteMiscIcon}
            className='misc_icons_dimensions'
            onClick={() => openNotesDrawer()}
          />
        </div>
      </div>

      <Box sx={{ marginBottom: '20px', marginTop: '20px' }}>
        {notesContent.map((note, index) => (
          <Box key={note.id} sx={{ display: 'flex' }}>
            {note.visible ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : null}{' '}
            <Box>
              <Typography className='' onClick={() => openNotesDrawer(note)}>
                {' '}
                <span
                  style={{
                    color: '#008593',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                >
                  Note #{index + 1} :
                </span>{' '}
                {note.content}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>

      <hr className='button_bottom_space' />

      <SalesOrderTable salesOrderWindows={salesOrderWindows} />

      <Box sx={{ textAlign: 'start' }}>
        <Grid container direction='row' spacing={0} className='button_top_space '>
          <Grid xs={9} md={9}>
            <div className='boldheadingText Coverings_Subtotal_Proposal_Details_Page common_line_height_css'>COVERINGS SUBTOTAL : </div>
          </Grid>
          <Grid xs={3} md={3}>
            <span className='boldheadingText Coverings_Subtotal_Proposal_Details_Page price common_line_height_css '>{ProposalRetailPrice ? '$' + parseFloat(ProposalRetailPrice).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
          </Grid>
        </Grid>

        <Grid container direction='row' spacing={0} className='section_row_1st'>
          <Grid xs={9} md={9}>
            <div className='rowTotalforproposal common_line_height_css common_text_height_css'>Installation : </div>
          </Grid>
          <Grid xs={3} md={3}>
            <span className='rowTotalforproposal price common_line_height_css common_text_height_css'> {InstallationCost ? '$' + parseFloat(InstallationCost).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
          </Grid>
        </Grid>

        {Accessoriescharge.length > 0 ? (
          <Grid container direction='row' spacing={0} className='section_row_1st'>
            <Grid xs={9} md={9}>
              <div className='rowTotalforproposal common_text_height_css'>Accessories : </div>
              {Accessoriescharge.map((accessory, index) => {
                return <div className='subAccessoriesAndServices common_line_height_css common_text_height_css'>{accessory.charge_description}</div>;
              })}
            </Grid>

            <Grid xs={3} md={3}>
              <div className='rowTotalforproposal'>&nbsp;</div>
              {Accessoriescharge.map((accessory, index) => {
                return <div className='rowTotalforproposalforAccandserv common_line_height_css common_text_height_css'>${parseFloat(accessory.charge).toFixed(2)}</div>;
              })}
            </Grid>
          </Grid>
        ) : null}

        {Servicescharge.length > 0 ? (
          <Grid container direction='row' spacing={0} className='section_row_1st'>
            <Grid xs={9} md={9}>
              <div className='rowTotalforproposal common_line_height_css common_text_height_css'>Services : </div>
              {Servicescharge.map((service) => (
                <div key={service.sku} className='subAccessoriesAndServices common_line_height_css common_text_height_css'>
                  {service.quantity > 1 ? `${service.charge_description} X ${service.quantity}` : service.charge_description}
                </div>
              ))}
            </Grid>
            <Grid xs={3} md={3}>
              <div className='rowTotalforproposal'>&nbsp;</div>
              {Servicescharge.map((service) => (
                <div key={service.sku} className='rowTotalforproposalforAccandserv common_line_height_css common_text_height_css'>
                  ${parseFloat(service.charge).toFixed(2)}
                </div>
              ))}
            </Grid>
          </Grid>
        ) : null}

        {AdditionalCharges.length > 0 ? (
          <Grid container direction='row' spacing={0} className='section_row_1st'>
            <Grid xs={9} md={9}>
              <div className='rowTotalforproposal common_line_height_css common_text_height_css'>Additional Charges : </div>
              {AdditionalCharges.map((charge, index) => {
                return (
                  <div key={charge.sku} className='subAccessoriesAndServices common_line_height_css common_text_height_css'>
                    {charge.charge_description}
                  </div>
                );
              })}
            </Grid>
            <Grid xs={3} md={3}>
              <div className='rowTotalforproposal'>&nbsp;</div>
              {AdditionalCharges.map((charge, index) => {
                return (
                  <div key={charge.sku} className='rowTotalforproposalforAccandserv common_line_height_css common_text_height_css'>
                    ${parseFloat(charge.charge).toFixed(2)}
                  </div>
                );
              })}
            </Grid>
          </Grid>
        ) : null}

        {showDiscount && discountDollar > 0 ? (
          <Grid container direction='row' spacing={0} className='section_row_1st'>
            <Grid xs={9} md={9}>
              <div className='rowTotalforproposal common_line_height_css common_text_height_css'>Discount : </div>
            </Grid>
            <Grid xs={3} md={3}>
              <span className='rowTotalforproposal price common_line_height_css common_text_height_css'>{discountDollar ? '- $' + parseFloat(discountDollar).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
            </Grid>
          </Grid>
        ) : null}

        <Grid container direction='row' spacing={0} className='section_row_1st'>
          <Grid xs={9} md={9}>
            <div className='rowTotalforproposal common_line_height_css common_text_height_css'>Tax : </div>
          </Grid>
          <Grid xs={3} md={3}>
            <span className='rowTotalforproposal price common_line_height_css common_text_height_css'>{Tax ? '$' + parseFloat(Tax).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
          </Grid>
        </Grid>
        <Grid container direction='row' spacing={0} className='section_row_1st'>
          <Grid xs={9} md={9}>
            <div className='boldheadingText Coverings_Subtotal_Proposal_Details_Page common_line_height_css common_text_height_css'>SALES ORDER TOTAL :</div>
          </Grid>
          <Grid xs={3} md={3}>
            <span className='boldheadingText Coverings_Subtotal_Proposal_Details_Page price common_line_height_css common_text_height_css'> {TotalCost ? `$${TotalCost}` : <Skeleton style={{ width: 50 }} />}</span>
          </Grid>
        </Grid>
      </Box>

      <hr className='button_top_space button_bottom_space' />

      {/* <span style={{ paddingLeft: '22px' }} className='gray_button_span'>
          <Box
            onClick={(e) => {
              generateSalesOrderPdf(e);
            }}
            className='globalIconMainBox'
          >
            <img src={PreviewIcon} className={`secondary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} alt='PreviewIcon' />
            <Typography sx={{ fontFamily: 'Roboto Light' }} className='globalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
              PREVIEW SALES ORDER
            </Typography>
          </Box>
        </span> */}

      <span onClick={NavigateToCustomerDetails} style={{ paddingLeft: '22px' }} className='gray_button_span button_top_space'>
        <Box className='globalIconMainBox'>
          <img src={SaveIcon} className={`secondary_icons_dimension ${isImageHovered2 ? 'hovered' : ''}`} alt='SaveIcon' />
          <Typography sx={{ fontFamily: 'Roboto Light' }} className='globalText' onMouseEnter={handleHover2} onMouseLeave={handleHover2}>
            {' '}
            SAVE SALES ORDER
          </Typography>
        </Box>
      </span>

      <span className='gray_button_span button_top_space' onClick={NavigateToCustomerDetails} style={{ paddingBottom: '20px', paddingLeft: '22px' }}>
        <Box className='globalIconMainBox'>
          <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered3 ? 'hovered' : ''}`} alt='CancelIcon' />
          <Typography onMouseEnter={handleHover3} onMouseLeave={handleHover3} sx={{ fontFamily: 'Roboto Light' }} className='globalText'>
            CANCEL
          </Typography>
        </Box>
      </span>

      <hr />

      <Button
        disabled={disableSend}
        sx={{ justifyContent: 'left', margin: '20px 0 90px 0' }}
        startIcon={isHovered ? <img className={`primary_icons_dimension_1${disableSend ? '_disabled' : ''}`} src={ShareHovIcon} alt='ShareHovIcon' /> : <img className={`primary_icons_dimension_1${disableSend ? '_disabled' : ''}`} src={ShareIcon} alt='ShareIcon' />}
        onClick={handleShareSO}
        onMouseEnter={handleHoverFun}
        onMouseLeave={handleMouseLeaveFun}
      >
        <Typography
          sx={{
            opacity: disableSend ? 0.5 : 1,
          }}
          className='globalText'
          onMouseEnter={handleHover4}
          onMouseLeave={handleHover4}
        >
          {loading ? 'Loading Sales Order...' : 'Send Sales Order To Customer'}
        </Typography>
        <PDFDownloadLink
          style={{ textDecoration: 'none' }}
          document={
            <Pdf
              state={{
                path: 'PreviewSalesOrder',
                customerId: location.state.customerDetails.customerdata.customer_id,
                customer: location.state.customerDetails.customerdata,
                addressId: location.state.customerDetails.customerdata.address_id,
                salesOrderId: location.state.salesOrderId,
                salesOrder: SalesOrderDetail,
                accessoryServiceCharges: SalesOrderLevelAccessoryServiceCharges || [],
                windows: salesOrderWindows,
                proposalDetails: location.state.ProposalDetails,
                remeasure: location.state.remeasure,
                data: location.state.data,
                notes: allNotes,
              }}
              customer={location.state.customerDetails.customerdata}
              notes={allNotes}
            />
          }
          fileName={filename}
        >
          {({ blob, url, loading, error }) => (!loading ? uploadFile(blob) : null)}
        </PDFDownloadLink>
      </Button>
    </>
  );
}
