import '../../index.css';

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl, FormLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import SaveEmailIcon from '../../assets/icons/drawer-alert/drw-save.svg';
import CONFIG from '../../config/config.js';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerSendIcon from '../../assets/icons/drawer-alert/drw-send.svg';
import DrawerEditIcon from '../../assets/icons/drawer-alert/2drw-edit.svg';
import DrawerAddIcon from '../../assets/icons/drawer-alert/2drw-add.svg';
import DrawerCloseIcon from '../../assets/icons/drawer-alert/close.svg';
import DrawerCheckIcon from '../../assets/icons/drawer-alert/2nd-enter.svg';

export default function SalesOrderShareSO(props) {
  const navigate = useNavigate();
  let customerEmailID = props.data.customerDetails.customerdata.email_id;
  let SalesOrderID = props.data.SalesOrder.sales_order_id;
  let Notes = 'NA';

  const inputEmails = [
    {
      type: 'email-id',
      id: 0,
      value: customerEmailID,
    },
  ];

  const inputNotes = [
    {
      type: 'email-id',
      id: 0,
      value: Notes,
    },
  ];

  const [EmailIds, setEmailIds] = useState();
  const [NotesIds, setNotesIds] = useState(inputNotes);
  const [NewEmail, setNewEmail] = useState();
  const [ShowEmailInputfield, setShowEmailInputfield] = useState(false);
  const [NotesTextField, setNotesTextField] = useState(false);
  const [NewNotes, setNewNotes] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [IsDeliveryDrawerClose, setIsDeliveryDrawerClose] = useState(true);
  const [isSend, setIsSend] = useState(false);
  const [NotesTextFieldValue, setNotesTextFieldValue] = useState('');
  const [emailError, setEmailError] = useState(true);
  const [successMsg, setSuccessMsg] = useState('');
  const [mailStatus, setmailStatus] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const CheckedArray = [];
  const [sendingButtonMsg, setsendingButtonMsg] = useState(false);
  let isItemSelected;

  const isSelected = (row) => {
    return selected === row.value;
  };
  const token = sessionStorage.getItem('token');

  const closeDrawer = (e) => {
    setIsDeliveryDrawerClose(false);
  };

  const AdditionalEmails = () => {
    setShowEmailInputfield(true);
  };

  const AdditionalNotes = () => {
    setNotesTextField(true);
  };

  const handleChangeEmail = (e) => {
    e.preventDefault();
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError(false);
      setNewEmail(e.target.value);
    } else {
      setEmailError(true);
    }

    setAddNewEmailInCustomer(e.target.value);
  };

  const handleChangeNotes = (e) => {
    e.preventDefault();
    setNewNotes(e.target.value);
    setNotesTextFieldValue(e.target.value);
  };

  const AddEmail = () => {
    setShowEmailInputfield(false);
    AddNewEmailInCustomerFun();
    setEmailError(true);
    setNewEmail('');
    setEmailIds((s) => {
      const lastId = s[s.length - 1].id;
      return [
        ...s,
        {
          type: 'email-id',
          id: lastId + 1,
          value: NewEmail,
        },
      ];
    });
  };

  const AddNotes = () => {
    setNotesTextField(false);
    setNewNotes('');
    setNotesIds((s) => {
      const lastId = s[s.length - 1].id;
      return [
        ...s,
        {
          type: 'email-id',
          id: lastId + 1,
          value: NewNotes,
        },
      ];
    });
  };

  const handleCheckboxChange = (event, emailjson) => {
    const email = emailjson.value;

    if (event.target.checked) {
      setSelected(email);
    } else {
      setSelected();
    }

    setRecipientEmail(email);
  };

  const SendEmail = async () => {
    setRecipientEmail([]);
    setsendingButtonMsg(true);

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        adobe_refresh_token: sessionStorage.getItem('adobe_refresh_token'),
        file: props.data.filecontent,
        filename: props.data.filename,
        filetype: 'application-pdf',
        EmailIDs: [recipientEmail],
        salesOrderID: SalesOrderID,
        notes: NotesTextFieldValue,
      }),
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/SalesOrderOutForSignature`, requestOptions);
    const data = await response.json();
    if (!data.error) {
      setsendingButtonMsg(false);
      setmailStatus(true);
      setSuccessMsg(data.message);
      setSuccessOpen(true);
      setIsDeliveryDrawerClose(false);
      setIsSend(true);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    GetCustomerEmailFun();
  }, []);

  const SaveButtonNotes = () => (
    <IconButton>
      <img className='drawer_iconsize2' src={SaveEmailIcon} onClick={AddNotes} alt='save email icon' />
    </IconButton>
  );

  props.func(IsDeliveryDrawerClose, isSend, mailStatus, successMsg, successOpen);

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [AddNewEmailInCustomer, setAddNewEmailInCustomer] = useState('');

  const AddNewEmailInCustomerFun = async () => {
    await fetch(CONFIG.API_URL + '/cm/AddCustomerEmail', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        customer_id: props.data.SalesOrder.customer_id,
        description: '',
        email: AddNewEmailInCustomer,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.message === 'Customer Email is added successfully!') {
          GetCustomerEmailFun(true);
        }
      });
  };

  const GetCustomerEmailFun = async (emailData) => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        customer_id: props.data.customerDetails.customerdata.customer_id,
        address_id: props.data.customerDetails.customerdata.address_id,
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/Customer`, requestOptions);
    const data = await response.json();
    if (!data.error) {
      if (data.customer.email.length === 0) {
        setEmailIds(inputEmails);
      } else {
        const AddNewField = data.customer.email.map((ele, i) => {
          return {
            ...ele,
            type: 'email-id',
            id: i,
            value: ele.email_id,
          };
        });
        setEmailIds(AddNewField);
        if (emailData === true) {
          if (AddNewField.length === 1) {
            const lastItem = AddNewField[0];
            setSelected([lastItem]);
            setRecipientEmail([lastItem]);
          } else {
            const lastItem = AddNewField[AddNewField.length - 1];
            CheckedArray.push(lastItem);

            setSelected(CheckedArray);
            setRecipientEmail(CheckedArray);
          }
        }
      }
    }
  };

  return (
    <Box p={2} pt={0} className='mainDrawerDiv'>
      <div align='right' style={{ paddingTop: '10px' }}>
        <img src={DrawerCloseIcon} onClick={closeDrawer} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCloseIcon' />
      </div>

      <Box p={3} textAlign='center' paddingTop={1}>
        <Typography
          sx={{
            color: 'white',
            display: 'flex',
          }}
        >
          SELECT DELIVERY METHOD
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            color: 'white',
          }}
        >
          {EmailIds !== undefined ? (
            <FormControl
              sx={{
                marginLeft: '1rem',
                alignItems: 'start',
              }}
            >
              <FormLabel
                sx={{
                  color: 'white',
                  '&.Mui-focused': {
                    color: 'white',
                  },
                }}
                id='email'
              >
                Email :
              </FormLabel>
              <RadioGroup aria-labelledby='demo-radio-buttons-group-label' name='radio-buttons-group'>
                {EmailIds.map((item, i) => (
                  <>
                    {item.value === 'NA' ? (
                      <>
                        <Typography
                          sx={{
                            marginTop: '10px',
                            fontFamily: 'RobotoLight',
                            fontSize: '18px',
                          }}
                          align='left'
                        >
                          No email on file
                        </Typography>
                      </>
                    ) : (
                      <FormControlLabel
                        sx={{
                          color: 'white',
                          marginLeft: '.25rem',
                          paddingBottom: '0.3125rem',
                        }}
                        control={
                          <Radio
                            key={i}
                            style={{ color: 'white' }}
                            name='email'
                            checked={isSelected(item)}
                            onClick={(e) => {
                              handleCheckboxChange(e, item);
                            }}
                          />
                        }
                        label={item.value}
                      />
                    )}
                  </>
                ))}
              </RadioGroup>
            </FormControl>
          ) : null}
        </Box>

        <span
          onClick={AdditionalEmails}
          className='drawer_gray_button_span'
          style={{
            paddingBottom: '20px',
            paddingLeft: '14px',
            paddingTop: '10px',
          }}
        >
          {EmailIds !== undefined ? (
            <>
              {EmailIds[0].value === 'NA' ? (
                <Box className='globalIconMainBox'>
                  <img src={DrawerAddIcon} className='drawer_secondary_icons_dimension' alt='DrawerAddIcon' />
                  <Typography sx={{ fontFamily: 'RobotoLight', textAlign: 'start' }} className='drawerGlobalText'>
                    ADD EMAIL
                  </Typography>
                </Box>
              ) : (
                <Box className='globalIconMainBox'>
                  <img src={DrawerAddIcon} className='drawer_secondary_icons_dimension' alt='DrawerAddIcon' />
                  <Typography sx={{ fontFamily: 'RobotoLight', textAlign: 'start' }} className='drawerGlobalText'>
                    ADD ANOTHER EMAIL
                  </Typography>
                </Box>
              )}
            </>
          ) : null}
        </span>

        <Box sx={{ marginLeft: '20px' }} className='price_drawer_box_3'>
          {ShowEmailInputfield ? (
            <>
              <TextField
                align='start'
                className='email_short_textfield formcontrol'
                label={emailError ? <span style={{ color: '#AA0D0D' }}>Please Enter a Valid Email</span> : 'Add Email'}
                variant='filled'
                id={EmailIds.length + 1}
                InputProps={{
                  style: { fontWeight: 'bold' },
                }}
                onChange={handleChangeEmail}
              />
              {emailError ? (
                <img src={DrawerCheckIcon} style={{ marginLeft: '20px' }} className='secondary_icons_dimension_disable' alt='DrawerCheckIcon' />
              ) : (
                <img src={DrawerCheckIcon} onClick={AddEmail} style={{ marginLeft: '20px', cursor: 'pointer' }} className='drawer_secondary_icons_dimension' alt='DrawerCheckIcon' />
              )}
            </>
          ) : (
            ''
          )}
        </Box>

        {/*
        <Box className="price_drawer_box">
          <Box sx={{ display: "flex" }}>
            {customerMobileNo === "NA" ? (
              <Typography
                sx={{
                  marginBottom: "6px",
                  fontFamily: "RobotoLight",
                  fontSize: "18px",
                }}
              >
                No phone number on file
              </Typography>
            ) : (
              <FormControlLabel
                control={<Checkbox style={{ color: "white" }} />}
                label={<span>SMS Text # : {customerMobileNo}</span>}
              />
            )}
          </Box>
        </Box>

        <span
          className="drawer_gray_button_span"
          style={{ paddingBottom: "20px", paddingLeft: "14px" }}
        >
          <Box className="globalIconMainBox">
            <img
              src={DrawerAddIcon}
              className="drawer_secondary_icons_dimension"
              alt="DrawerAddIcon"
            />
            <Typography
              sx={{ fontFamily: "RobotoLight", textAlign: "start" }}
              className="drawerGlobalText"
            >
              {customerMobileNo === "NA" ? (
                <>ADD SMS Text #</>
              ) : (
                <>ADD ANOTHER SMS Text #</>
              )}
            </Typography>
          </Box>
        </span>
        */}

        <hr className='' />

        <span
          className='drawer_gray_button_span button_top_space'
          onClick={() => {
            navigate(`/tbm/ps/customerdetails`, {
              state: {
                Custdata: {
                  custid: props.data.customerDetails.customerdata.customer_id,
                  Addressid: props.data.customerDetails.customerdata.address_id,
                },
              },
            });
          }}
          style={{ paddingBottom: '20px', paddingLeft: '14px' }}
        >
          <Box className='globalIconMainBox'>
            <img src={DrawerEditIcon} className='drawer_secondary_icons_dimension' alt='DrawerEditIcon' />
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
              EDIT CUSTOMER INFO
            </Typography>
          </Box>
        </span>

        <hr className='' />

        <span onClick={AdditionalNotes} className='drawer_gray_button_span button_top_space' style={{ paddingBottom: '20px', paddingLeft: '14px' }}>
          <Box className='globalIconMainBox'>
            <img src={DrawerAddIcon} className='drawer_secondary_icons_dimension' alt='DrawerAddIcon' />
            <Typography sx={{ fontFamily: 'RobotoLight', textAlign: 'start' }} className='drawerGlobalText'>
              ADD NOTE TO CUSTOMER EMAIL
            </Typography>
          </Box>
        </span>

        <Box sx={{ marginLeft: '15px' }} className='price_drawer_box_1 '>
          {NotesTextField ? (
            <>
              <TextField
                className='email_short_textfield formcontrol button_bottom_space'
                variant='filled'
                id={NotesIds.length + 1}
                InputProps={{
                  style: { fontWeight: 'bold' },
                }}
                onChange={handleChangeNotes}
              />
              <SaveButtonNotes />
            </>
          ) : (
            ''
          )}
        </Box>

        <hr />
      </Box>
      <Stack direction='column' sx={{ paddingLeft: '24px' }} spacing={2}>
        {recipientEmail.length ? (
          <span className='drawer_gray_button_span ' onClick={SendEmail} style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
            <Box className='globalIconMainBox globalDrawerText'>
              <img src={DrawerSendIcon} className={`primary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} alt='DrawerSendIcon' />
              <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
                SEND
              </Typography>
            </Box>
          </span>
        ) : (
          <span className='drawer_gray_button_span' style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
            <Box className='globalIconMainBox '>
              <img src={DrawerSendIcon} className='primary_icons_dimension_disable' alt='DrawerSendIcon' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalTextDisable'>
                {sendingButtonMsg ? <>SENDING...</> : <>SEND</>}
              </Typography>
            </Box>
          </span>
        )}

        <span className='drawer_gray_button_span button_top_space' onClick={closeDrawer} style={{ paddingBottom: '30px', paddingLeft: '14px' }}>
          <Box className='globalIconMainBox'>
            <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension' alt='DrawerCancelIcon' />
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
              CANCEL
            </Typography>
          </Box>
        </span>
      </Stack>
    </Box>
  );
}
