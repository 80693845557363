/** @format */

import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CONFIG from '../../config/config.js';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ShowProposalName from './ShowProposalName';
import fracty from 'fracty';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import ReorderWindowIcon from '../../assets/icons/primary/window-move.svg';
import RerderWindowHovIcon from '../../assets/icons/primary/WithHovIcon/move-hov.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import { TablePagination } from '@mui/material';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
const vertical = 'top';
const horizontal = 'center';
let checkedrow = [];
let updatedrow = [];

export default function ReOrderProposalDetails(props) {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    updatedrow = location.state.selectedwindows;
  }

  let proposaldetails = {
    customerdata: location.state.customerdata,
    proposal_id: location.state.proposalID,
    proposal_name: location.state.proposalName,
  };
  let coveringdetails = location.state.coveringdetails;
  const [proposalName, setProposalName] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(CONFIG.WindowsManagement.RowperPageLimit);
  const [open, setOpen] = React.useState(false);
  const [applyWindowLine, setApplyWindowLine] = useState(false);
  const [tabledata, setTableData] = useState({ items: updatedrow });

  // error handling states

  const [WindowReorderErrorAlert, setWindowReorderErrorAlert] = useState(false);
  const [WindowReorderErrorMsg, setWindowReorderErrorMsg] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };
  const handleHoverFun2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeaveFun2 = () => {
    setIsHovered2(false);
  };

  const params = useParams();

  // Get Token
  const token = sessionStorage.getItem('token');
  useEffect(() => {
    checkedrow = [];
    setProposalName(location.state.proposalName);
  }, []);

  useEffect(() => {
    getVerified();
  }, []);
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const rows = tabledata.items;

  const onDragEnd = (e) => {
    if (!e.destination) {
      return;
    }
    const { items } = tabledata;

    const sorted = reorder(items, e.source.index, e.destination.index);

    setTableData({ items: sorted });
    if (updatedrow === tabledata.items) {
      setApplyWindowLine(true);
    }
  };
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const LongText = ({ content }) => {
    const [limit, setLimit] = useState(CONFIG.GlobalTruncateCharacterLimit);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    if (content === undefined) {
      // Data is undefined, return empty
      return null;
    }

    // Check if the window width is between 280px and 768px and content length is greater than 14
    if (windowWidth >= 280 && windowWidth <= 767 && content.length > 15) {
      const truncatedContent = content.substring(0, 15) + '...';
      return <span>{truncatedContent}</span>;
    }

    // Check if the window width is above or equal to 768
    if (windowWidth >= 768) {
      if (content.length <= limit) {
        // There is nothing more to show
        return <span>{content}</span>;
      }

      let truncatedContent = content.substring(0, limit);

      // Find the last space within the truncated content
      const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

      // Adjust the truncated content if a space was found
      if (lastSpaceIndex !== -1) {
        truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
      }

      // Add the ellipsis to the truncated content
      const toShow = truncatedContent + '...';

      return <span>{toShow}</span>;
    }

    if (content.length <= limit) {
      // There is nothing more to show
      return <span>{content}</span>;
    }

    let truncatedContent = content.substring(0, limit);

    // Find the last space within the truncated content
    const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

    // Adjust the truncated content if a space was found
    if (lastSpaceIndex !== -1) {
      truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
    }

    // Add the ellipsis to the truncated content
    const toShow = truncatedContent + '...';

    return <span>{toShow}</span>;
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow style={{ margin: '0px', fontWeight: 'bold' }}>
          <TableCell className='table-headerps sticky-columnws-1 font_weight_bold'>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </TableCell>
          <TableCell className='table-headerps sticky-columnws-2 font_weight_bold'>
            <span style={{ marginLeft: '30px' }}>#</span>
            <span style={{ marginLeft: '30px' }}>Window Name</span>
          </TableCell>

          <TableCell className='small_header'>
            <CheckCircleIcon />
          </TableCell>

          <TableCell className='table-headerpd_size font_weight_bold'>Size</TableCell>
          <TableCell className='table-headerpd_price font_weight_bold'>Retail</TableCell>
          <TableCell className='table-headerpd_price font_weight_bold'>Install</TableCell>
          <TableCell className='table-headerpd_price font_weight_bold'>Total</TableCell>
          <TableCell className='small_header font_weight_bold'>Bld</TableCell>
          <TableCell className='table-headerpd_size font_weight_bold' sx={{ whiteSpace: 'nowrap' }}>
            Covering System
          </TableCell>
          <TableCell className='table-headerpd_size font_weight_bold'>Note</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  let windowsetdata = [];
  windowsetdata = location.state.customerdata;
  let result = [];

  // Navigate proposalDetails page after reorder the table
  const navigateNextPage = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: location.state.proposalID,
        windows: tabledata.items,
      }),
    };
    // Created Proposal will be added to the database through CreateProposal API
    await fetch(`${CONFIG.API_URL}/pm/WindowReorder`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          navigate(`/tbm/ps/proposal/${params.id}/details`, {
            state: {
              selectedwindows: tabledata.items,
              windowsselectednotnull: tabledata.items,
              customerdata: windowsetdata,
              path: 'reorder',
              proposalID: location.state.proposalID,
              proposalName: location.state.proposalName,
              totalwindows: location.state.totalwindows,
              coveringdetails: coveringdetails,
              extraCharges: location.state.data.extraCharges,
            },
          });
        } else {
          setWindowReorderErrorMsg(data.error_message);
          setWindowReorderErrorAlert(true);
        }
      });
  };

  // Navigate proposalDetails page after cancel
  const cancelandnavigateNextPage = () => {
    navigate(`/tbm/ps/proposal/${params.id}/details`, {
      state: {
        selectedwindows: location.state.selectedwindows,
        customerdata: windowsetdata,
        unchecked: result,
        proposalID: location.state.proposalID,
        proposalName: location.state.proposalName,
      },
    });
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  return (
    <>
      <Stack spacing={2} className='snack_Bar_Proposal_Details_Page'>
        {/* WindowReorderErrorAlert */}
        <Snackbar className='snackbar_bar_postition' open={WindowReorderErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setWindowReorderErrorAlert(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img
                onClick={() => {
                  setWindowReorderErrorAlert(false);
                }}
                src={RedDrawerCancelIcon}
                alt='CANCELICONWRA'
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={RedExclamationIcon} alt='CONFIRMICONWRA' className='primary_icons_dimension alertImageMargin' />
              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{WindowReorderErrorMsg}</div>
            </div>
          </Alert>
        </Snackbar>

        <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={2000} onClose={() => setOpen(false)}>
          <Alert onClose={handleClose} severity='success' className='snack_Bar_Proposal_Details_Page'>
            Duplicate Proposal created
          </Alert>
        </Snackbar>
      </Stack>
      <ShowProposalName proposaldetails={proposaldetails} />
      <Box className='button_top_space stepper_prompt_margin_bottom removeProposalWindowPromptMainBox'>
        <Stack direction='row'>
          <Box>
            <Stack direction='row' onMouseEnter={handleHoverFun2} onMouseLeave={handleMouseLeaveFun2}>
              <img className='primary_icons_dimension_1 stepperImageMargins' src={ReorderWindowIcon} />
            </Stack>
          </Box>
          <Box
            sx={{
              borderLeft: '1px solid #D6D6D6',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className=''
          >
            <Typography className='stepperMainTextSize stepperSubTextStyle'>Reorder Windows to Desired Position</Typography>
          </Box>
        </Stack>
      </Box>
      <hr className='button_top_space' />

      <Box className='reorder_Main_Box'>
        <Box className='reorder_Sub_Box'>
          <Box className='table-box'>
            <TableContainer sx={{ borderBottom: '1px solid #D6D6D6', textAlign: 'left' }} className='table_padding_bottom'>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='Table'>
                  {(provided) => (
                    <Table className='proposal_table' aria-labelledby='tableTitle' size='small' {...provided.droppableProps} ref={provided.innerRef}>
                      <EnhancedTableHead rowCount={rows.length} />

                      <TableBody>
                        {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row, index) => {
                          return (
                            <Draggable key={row.window_id} draggableId={row.window_id} index={index}>
                              {(provided) => (
                                <TableRow hover role='checkbox' tabIndex={-1} key={row.window_id} style={{ padding: 0 }} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                  {row.covering_status === 'not_applied' ? (
                                    <>
                                      <TableCell sx={{ color: '#008593' }} className='table-content sticky-column' align='left'>
                                        <Box sx={{ display: 'flex' }}>
                                          <DragHandleIcon
                                            onClick={() => {}}
                                            sx={{
                                              color: '#2596be',
                                              paddingTop: '0px',
                                            }}
                                          />{' '}
                                        </Box>
                                      </TableCell>

                                      <TableCell className='table-contentid sticky-columnws-2' align='left'>
                                        {' '}
                                        <span
                                          style={{
                                            paddingRight: `${rows.indexOf(row) + 1 > 9 ? '20px' : '30px'}`,
                                            color: 'red',
                                          }}
                                        >
                                          {rows.indexOf(row) + 1}
                                        </span>
                                        <span
                                          style={{
                                            color: 'red',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          <LongText content={row.window_name} />
                                        </span>
                                      </TableCell>

                                      <TableCell className='small_cell font_weight_bold'>
                                        {row.sales_order_status === true ? (
                                          <CheckIcon
                                            fontSize='small'
                                            sx={{
                                              padding: '0px !important',
                                              marginTop: '0px',
                                              color: '#019A65',
                                              paddingTop: '0px',
                                            }}
                                          />
                                        ) : (
                                          <span sx={{ marginLeft: '18px' }}></span>
                                        )}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          color: 'red',
                                          whiteSpace: 'nowrap',
                                        }}
                                        className='table-contentid'
                                        align='left'
                                      >
                                        <>
                                          {fracty(row.proposal_window_width)}" X {fracty(row.proposal_window_height)}"{' '}
                                          <span className={row.proposal_measurement_type.replace(/ /g, '')}>
                                            {row.proposal_measurement_type === 'Quick'
                                              ? '(' + 'QK' + ')'
                                              : row.proposal_measurement_type === 'Shutters'
                                              ? '(' + 'SH' + ')'
                                              : row.proposal_measurement_type === 'Blinds'
                                              ? '(' + 'BL' + ')'
                                              : '(' +
                                                row.proposal_measurement_type
                                                  .split(' ')
                                                  .map((word) => word[0])
                                                  .join('') +
                                                ')'}
                                          </span>
                                        </>
                                      </TableCell>
                                      <TableCell sx={{ color: 'red' }} className='table-content-after-material' align='left'>
                                        <>─ ─</>
                                      </TableCell>
                                      <TableCell sx={{ color: 'red' }} className='table-content-after-material' align='left'>
                                        <>─ ─</>
                                      </TableCell>

                                      <TableCell sx={{ color: 'red' }} className='table-content-after-material' align='left'>
                                        <>─ ─</>
                                      </TableCell>
                                      <TableCell sx={{ color: 'red' }} className='table-content-after-material' align='left'>
                                        <>─ ─</>
                                      </TableCell>
                                      <TableCell sx={{ color: 'red' }} className='table-content-after-material' align='left'>
                                        <>─ ─</>
                                      </TableCell>
                                    </>
                                  ) : (
                                    <>
                                      <TableCell sx={{ color: '#008593' }} className='table-content sticky-column' align='left'>
                                        <Box sx={{ display: 'flex' }}>
                                          <DragHandleIcon
                                            onClick={() => {}}
                                            sx={{
                                              color: '#2596be',
                                              paddingTop: '0px',
                                            }}
                                          />{' '}
                                        </Box>
                                      </TableCell>

                                      <TableCell className='table-contentid sticky-columnws-2' align='left'>
                                        {' '}
                                        <span
                                          style={{
                                            paddingRight: `${rows.indexOf(row) + 1 > 9 ? '20px' : '30px'}`,
                                          }}
                                        >
                                          {rows.indexOf(row) + 1}
                                        </span>
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                          <LongText content={row.window_name} />
                                        </span>
                                      </TableCell>
                                      <TableCell className='small_cell font_weight_bold'>
                                        {row.sales_order_status === true ? (
                                          <CheckIcon
                                            fontSize='small'
                                            sx={{
                                              padding: '0px !important',
                                              marginTop: '0px',
                                              color: '#019A65',
                                              paddingTop: '0px',
                                            }}
                                          />
                                        ) : (
                                          <span sx={{ marginLeft: '18px' }}></span>
                                        )}
                                      </TableCell>

                                      <TableCell className='table-content' align='left'>
                                        <>
                                          {fracty(row.proposal_window_width)}" X {fracty(row.proposal_window_height)}"{' '}
                                          <span className={row.proposal_measurement_type.replace(/ /g, '')}>
                                            {row.proposal_measurement_type === 'Quick'
                                              ? '(' + 'QK' + ')'
                                              : row.proposal_measurement_type === 'Shutters'
                                              ? '(' + 'SH' + ')'
                                              : row.proposal_measurement_type === 'Blinds'
                                              ? '(' + 'BL' + ')'
                                              : '(' +
                                                row.proposal_measurement_type
                                                  .split(' ')
                                                  .map((word) => word[0])
                                                  .join('') +
                                                ')'}
                                          </span>
                                        </>
                                      </TableCell>

                                      <TableCell className='table-content-after-material' align='left'>
                                        <>${row.suggested_price}</>
                                      </TableCell>

                                      <TableCell className='table-content-after-material' align='left'>
                                        <>${row.covering_install_price}</>
                                      </TableCell>

                                      <TableCell className='table-content-after-material' align='left'>
                                        <>${row.proposal_total_with_install}</>
                                      </TableCell>
                                      <TableCell className='small_cell' align='left'>
                                        <>&nbsp;&nbsp;{row.build_type[0]}</>
                                      </TableCell>

                                      <TableCell className='table-content-second2' align='left'>
                                        <>
                                          {row.material_code.toString().replace(/,/g, ', ')}, {row.build_type}, {row.existing_covering}, {row.mount_surface}, {row.ladder}
                                        </>
                                      </TableCell>
                                      <TableCell className='table-content-after-material small_cell' align='left'>
                                        <>
                                          &nbsp;&nbsp;
                                          {row.notes_count === '0' ? <></> : <>{row.notes_count}</>}
                                        </>
                                      </TableCell>
                                    </>
                                  )}
                                </TableRow>
                              )}
                            </Draggable>
                          );
                        })}
                      </TableBody>
                    </Table>
                  )}
                </Droppable>
              </DragDropContext>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]}
                component='div'
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(_, newPageprp) => {
                  setPage(newPageprp);
                }}
                onRowsPerPageChange={({ target }) => {
                  setRowsPerPage(parseInt(target.value, 10));
                  setPage(0);
                }}
              />
            </TableContainer>
          </Box>
        </Box>
      </Box>

      {applyWindowLine ? (
        <Box display='flex' alignItems='center' justifyContent='center' className='apply_Changes_Box'>
          <span className='button_span button_top_space button_bottom_space' onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
            <Box
              onClick={() => {
                navigateNextPage();
              }}
              className='globalIconMainBox globalMarginLeftAddWindow'
            >
              {isHovered ? <img alt='APPLYCHANGESH' className='primary_icons_dimension_1' src={RerderWindowHovIcon} /> : <img alt='APPLYCHANGES' className='primary_icons_dimension_1' src={ReorderWindowIcon} />}

              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                APPLY CHANGES
              </Typography>
            </Box>
          </span>
        </Box>
      ) : (
        <Box display='flex' alignItems='center' justifyContent='center' className='apply_Changes_Box'>
          <span className='button_span_disabled button_top_space button_bottom_space'>
            <Box className='globalIconMainBox globalMarginLeftAddWindow'>
              <img className='primary_icons_dimension_disable' src={ReorderWindowIcon} alt='addwindow' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                REORDER WINDOWS
              </Typography>
            </Box>
          </span>
        </Box>
      )}
      <hr />
      <Box display='flex' alignItems='center' justifyContent='center' className='apply_Changes_Box'>
        <span style={{ marginLeft: '14px', paddingBottom: '30px' }} onClick={cancelandnavigateNextPage} className='gray_button_span button_top_space'>
          <Box className='globalIconMainBox'>
            <img alt='CANCEL' src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered2 ? 'hovered' : ''}`} />
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover2} onMouseLeave={handleHover2}>
              CANCEL
            </Typography>
          </Box>
        </span>
      </Box>
    </>
  );
}
