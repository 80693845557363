import React from "react";
import font from "../../../font/Roboto-Light.woff";
import fontr from "../../../font/Roboto-Regular.woff";
import fontb from "../../../font/Roboto-Bold.woff";

import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
// import jsPDF from 'jspdf';
Font.register({
  family: "RobotoLight",
  src: font,
});
Font.register({
  family: "Roboto-Regular.woff",
  src: fontr,
});
Font.register({
  family: "Roboto-Bold.woff",
  src: fontb,
});
// Create styles
const styles = StyleSheet.create({
  section_row_body: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 15,
  },
  section_row_bullets: {
    flexDirection: "row",
    paddingTop: 4,
    paddingRight: 5,
  },

  headingText: {
    fontSize: 18,
    color: "#333333",
    paddingTop: "1px",
    // paddingLeft: "10px",
    fontFamily: "RobotoLight",
    fontWeight: "400",
  },

  paraheading: {
    fontSize: 13,
    color: "#2C2E35",
    paddingTop: 8,
    fontFamily: "RobotoLight",
  },
  headingButton: {
    color: "gray",
    border: "1.5px solid #85837D",
    paddingTop: "4.5px",
    width: 65,
    height: 25,
    fontSize: "12px",
    textAlign: "center",
    backgroundColor: "#FAC41E",
    fontFamily: "RobotoLight",
    fontWeight: "400",
  },
  firstBlock: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "10px",
  },
  secondBlock: {
    border: "2px solid #2D3030",
    marginTop: "15px",
  },
  firstSection: {
    backgroundColor: "#2D3030",
    color: "white",
    fontSize: 22,
    paddingTop: 3,
    paddinBottom: 4,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
  },
  secondSection: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "15px",
  },
  secondBlockImage: {
    width: "20%",
  },
  description: {
    color: "#2C2E35",
    width: "100%",
    fontSize: "11px",
    paddingTop: 5,
    lineHeight: 1.1,
  },
  secondBlockDescription: {
    width: "80%",
    fontSize: "10px",
    paddingTop: "15px",
  },
  image: {
    width: 140,
    height: 120,
    paddingLeft: "10px",
    paddingRight: "25px",
    paddingTop: "15px",
  },
  image2: {
    width: 55,
    height: 35,
    paddingLeft: 15,
  },
  padding: {
    paddingTop: 5,
    fontFamily: "RobotoLight",
  },
  boldheadingwithpadding: {
    paddingTop: 5,
    fontFamily: "Roboto-Bold.woff",
    color: "#000",
  },
  break: {
    backgroundColor: "black",
    height: 5,
    marginTop: 5,
  },
  breakCopy: {
    backgroundColor: "black",
    height: 1,
    marginTop: "4px",
  },
  fontFamily: {
    paddingTop: 0,
    fontFamily: "RobotoLight",
    fontWeight: "400",
  },
  bullets: {
    paddingLeft: 15,
    color: "#2C2E35",
  },
  bulletspoint: {
    paddingLeft: 0,
    color: "#2C2E35",
  },
  bulletspointcontent: {
    paddingLeft: 0,
    color: "#2C2E35",
    fontFamily: "RobotoLight",
  },
  bulletscontent: {
    fontFamily: "RobotoLight",
    paddingLeft: 15,
    width: "98%",
  },
  paddingstyle: {
    fontFamily: "Roboto-Bold.woff",
  },
  boldheading: {
    fontFamily: "Roboto-Bold.woff",
    color: "#000",
  },
  horizontalborder: {
    width: "100%",
    height: "2px",
    flexDirection: "row",
    backgroundColor: "#7E7E7E",
  },
  section_space: {
    lineHeight: "1.5px",
    paddingTop: 5,
  },
  textField: {
    width: "100%",
    height: 20,
    borderWidth: 1,
    borderColor: "#ccc",
    padding: 5,
  },
});

export default function CustomerPurchaseAgreement() {
  return (
    <>
      <View style={styles.section_row_body}>
        <View style={styles.firstBlock}>
          <Text style={styles.headingText}>
            CUSTOMER PURCHASE AGREEMENT & INFORMATION
          </Text>
        </View>
        <View>
          <Text style={styles.paraheading}>PURCHASE AGREEMENT</Text>
        </View>
        <View style={styles.description}>
          <Text style={styles.fontFamily}>
            By signing the customer authorization, I authorize The Blindman Inc.
            (TBM) to proceed with the manufacture of this non-cancel-able,
            custom order. I further acknowledge if I elect to finance my
            purchase of custom window coverings from TBM, or through one of its
            authorized affiliates, TBM honors my right to cancel this contract
            up until midnight on the third business day following the signing of
            this contract. I understand that my order will be built to the exact
            specifications written on the opposite side of this page (with the
            exception of shutters that may be altered at a re-measure). All
            products will be manufactured and installed to TBM company
            standards. No additional products or services are guaranteed unless
            written and agreed upon on this original work order.{" "}
          </Text>
          <Text style={styles.padding}>
            All balances not paid at installation are subject to 1½% monthly
            interest charge plus all collection fees deemed necessary to collect
            this account. I hereby grant a purchase money security interest in
            the afore mentioned property and agree that until I have paid the
            unpaid balance, in full, that said property shall remain as personal
            property of The Blindman Inc. not- withstanding any installation,
            connection or attachments to realty.
          </Text>
          <Text style={styles.boldheadingwithpadding}>
            Purchase agreement,Writtern Warrany and Product Safety Notice are
            detailed in this document.No other contracts or warranties are given
            or implied.
          </Text>
          <Text style={styles.padding}>&nbsp;</Text>
        </View>
        <View>
          <Text style={styles.paraheading}>
            THE BLINDMAN INSTALLATION POLICY
          </Text>
        </View>
        <View style={styles.description}>
          <Text style={styles.fontFamily}>
            Projected installation dates are an estimate based on current
            installations. However due to unforeseen circumstances projected
            dates may change. Jobs are processed and installed in the order they
            are received. Orders will be held for lack of payment or signature.
            Rushed jobs are discouraged but can be done. A 10% surcharge per
            week of time shortened will be charged to any rush order. TBM
            factory will determine time frame and ability to perform on all rush
            orders.
          </Text>
          <View style={styles.section_row_bullets}>
            <Text style={styles.bullets}> • </Text>
            <Text style={styles.bulletscontent}>
              TBM requires all windows to be clear of all previous brackets at
              time of scheduled install. If there are brackets that have not
              been removed, our installer will remove them at a charge of up to
              $20.00 per window. TBM will not assume respon-sibility for any
              holes or damage caused or exposed by the removal of existing
              blinds.
            </Text>
          </View>
          <View style={styles.section_row_bullets}>
            <Text style={styles.bullets}> • </Text>
            <Text style={styles.bulletscontent}>
              All furniture or household items need to be removed in a 3’ radius
              from in front of all windows that will be receiving new window
              coverings. In the event that a TBM installer is required to move
              personal property, TBM assumes no responsibil-ity if damage
              occurs. If property to be moved is substantial, installation may
              be rescheduled and a trip charge charged for a follow up
              appointment. It is the customer’s responsibility to have windows
              ready for installation.
            </Text>
          </View>
          <View style={styles.section_row_bullets}>
            <Text style={styles.bullets}> • </Text>
            <Text style={styles.bulletscontent}>
              Repairs of existing blinds may not be done at the time of the new
              order scheduled installation unless it is requested and also paid
              for ahead of time.
            </Text>
          </View>
          <View style={styles.section_row_bullets}>
            <Text style={styles.bullets}> • </Text>
            <Text style={styles.bulletscontent}>
              All balances owed to TBM are required at time of installation.
            </Text>
          </View>
        </View>
        <View>
          <Text style={styles.paraheading}>
            THE BLINDMAN CUSTOM PAINT AND STAIN POLICY
          </Text>
        </View>
        <View style={styles.description}>
          <View style={styles.section_row_bullets}>
            <Text style={styles.bulletspointcontent}>
              <Text style={styles.boldheading}>Paint –</Text> The choice of a
              custom color requires you, the customer, to supply a painted
              sample of the color to be matched.
            </Text>
          </View>
          <View style={styles.section_row_bullets}>
            <Text style={styles.bulletspointcontent}>
              <Text style={styles.boldheading}>Stain –</Text> Custom stain
              samples must be applied to the exact species of wood to be used in
              your order. At your request your sales person will supply you with
              a raw wood sample for you to stain and return to TBM as your
              “stain match sample”.
            </Text>
          </View>
          <View style={styles.section_row_bullets}>
            <Text style={styles.bulletspointcontent}>
              <Text style={styles.boldheading}>Full custom match –</Text> TBM
              goes to great effort to acquire the best possible stain and paint
              matches. It is understood that it is not always possible to
              achieve a “perfect match” with all colors due to dye-lots, sheen
              differences and fading or yellowing of existing paint and stain.
              TBM will work to match to the sample you have supplied. TBM cannot
              use paint or stain supplied by the custom-er, we use special
              formulations to achieve our finishes. You are responsible to make
              sure that the sample you supply is the exact color you want to
              have matched. TBM will have your sample professionally matched and
              you will be shown a sample before work will proceed.
            </Text>
          </View>
          <View style={styles.section_row_bullets}>
            <Text style={styles.bulletspointcontent}>
              <Text style={styles.boldheading}>Semi-custom match –</Text>{" "}
              Semi-custom matches are produced the same as TBM standard colors
              and will be finished to match our semi-custom deck of colors.
              Customer will not be shown a sample prior to production.
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.horizontalborder}>&nbsp;</View>
      <View></View>
    </>
  );
}
