import React from 'react';
import { Box, Grid, Divider } from '@mui/material';

import Label from './Label';
import ProductDescription from './ProductDescription';

const WindowDetails = ({ row, window, customStyles }) => {
  const { window_name, proposal_measurement_type, proposal_window_width, proposal_window_height, proposal_measurement_type_specific_factor, proposal_additional_sku, material_code, suggested_price: total, sku, sku_details, ...rest } = window;
  return (
    <Grid container direction='column' alignContent='center' columnSpacing={0} style={customStyles.section_row_1st}>
      {/* Row # Column */}
      <Grid item xs={1} sm={0.5}>
        <Box display='flex' justifyContent='center' style={customStyles.contentCenter}>
          #{row}
        </Box>
      </Grid>

      {/* Window Details Column */}
      <Grid item sm={3} sx={{ borderRight: '1px solid #d6d6d6', marginRight: '5px' }}>
        <Box display='block' justifyContent='center'>
          <Label label='Window Name' customStyles={customStyles}>
            {window_name}
          </Label>
          <Label label='Measurement Type' customStyles={customStyles}>
            {proposal_measurement_type}
          </Label>
          <Label label='Window Size' customStyles={customStyles}>
            {Math.ceil(proposal_window_width)}" X {Math.ceil(proposal_window_height)}"
          </Label>
          <Label label='Depth' customStyles={customStyles}>
            {proposal_measurement_type_specific_factor.WindowDepth}
          </Label>
          {proposal_additional_sku.map((data, i) => (
            <Label key={i} label={data.type} customStyles={customStyles}>
              {data.sku_name}
            </Label>
          ))}
        </Box>
      </Grid>

      <Divider style={customStyles.headerverticallinefortable} orientation='vertical' flexItem />

      {/* Covering System Column */}
      <Grid item sm={6.5} sx={{ borderRight: '1px solid #d6d6d6', marginRight: '5px' }}>
        <ProductDescription description={sku_details} customStyles={customStyles} />
      </Grid>

      <Divider style={customStyles.headerverticallinefortable} orientation='vertical' flexItem />

      {/* Price Column */}
      <Grid item xs={2} md={1.5} sm={1.5}>
        <Box display='flex' justifyContent='right' style={customStyles.rowTotalforproposal}>
          $ {parseFloat(total).toFixed(2)}
        </Box>
      </Grid>
    </Grid>
  );
};

export default WindowDetails;
