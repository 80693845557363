/** @format */

import React, { useState } from 'react';
import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import { TextField } from '@mui/material';
import CONFIG from '../../config/config.js';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerSaveIcon from '../../assets/icons/drawer-alert/drw-save.svg';
import DrawerCloseIcon from '../../assets/icons/drawer-alert/close.svg';

const EditWindowName = (props) => {
  const navigate = useNavigate();

  const [windowName, setWindowName] = useState(props.windowName);
  const [windowId, setWindowId] = useState(props.window_id);
  const [customerId, setCustomerId] = useState(props.customer_id);
  const [addressId, setAddressId] = useState(props.address_id);
  const [token, setToken] = useState(props.token);
  const [isDrawerClose, setIsDrawerClose] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');

  const EditWindowNameFun = (e) => setWindowName(e.target.value);

  const closeDrawer = () => setIsDrawerClose(false);

  props.func(windowName, isDrawerClose, successMessage);

  const editWindowNameFun = async () => {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        window_id: windowId,
        window_name: windowName,
        customer_id: customerId,
        address_id: addressId,
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/wm/UpdateWindowName`, options);
    const data = await response.json();
    if (!data.error) {
      setIsDrawerClose(false);
      setSuccessMessage(data.message);
    } else if (data.error === true) {
      setSuccessMessage(data.message);
      setIsDrawerClose(false);
    } else {
      setSuccessMessage('');
      setIsDrawerClose(false);
    }
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => setIsImageHovered(!isImageHovered);

  return (
    <Box p={4} pt={2} className='sideDrawer'>
      <div align='right' className='drawer_backbutton'>
        <img alt='CANCELDRAWER' src={DrawerCloseIcon} onClick={closeDrawer} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
      </div>
      <Stack direction='column' spacing={0}>
        <TextField className='notes_textfield' value={windowName} onChange={EditWindowNameFun} />

        {windowName ? (
          <span className='drawer_gray_button_span' onClick={editWindowNameFun} style={{ paddingBottom: '0px', paddingLeft: '0px', paddingTop: '0px !important' }}>
            <Box className='globalIconMainBox globalDrawerText'>
              <img alt='SAVE' src={DrawerSaveIcon} className={`primary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                SAVE
              </Typography>
            </Box>
          </span>
        ) : (
          <span className='drawer_gray_button_span' style={{ paddingBottom: '0px', paddingLeft: '0px', paddingTop: '0px !important' }}>
            <Box className='globalIconMainBox '>
              <img alt='SAVED' src={DrawerSaveIcon} className='primary_icons_dimension_disable' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
                SAVE
              </Typography>
            </Box>
          </span>
        )}

        <span className='drawer_gray_button_span button_top_space' onClick={closeDrawer} style={{ paddingBottom: '30px', paddingLeft: '14px' }}>
          <Box className='globalIconMainBox'>
            <img alt='CANCEL' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension' />
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
              CANCEL
            </Typography>
          </Box>
        </span>
      </Stack>
    </Box>
  );
};

export default EditWindowName;
