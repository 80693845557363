import { Container, Button, Typography } from '@mui/material';
import DrawerCancelIcon from '../../../assets/icons/drawer-alert/2drw-cancel.svg';

export default function Footer({ cancel, children }) {
  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', paddingTop: '10px' }}>
      {children}

      <Button onClick={cancel} sx={{ justifyContent: 'left', marginLeft: '15px' }}>
        <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension' alt='drawerCancelIcon' />
        <Typography
          sx={{
            marginLeft: '30px',
            fontFamily: 'RobotoLight',
          }}
          className='drawerGlobalText'
        >
          CANCEL
        </Typography>
      </Button>
    </Container>
  );
}
