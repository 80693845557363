import React from "react";
import {Grid, Divider } from "@mui/material";

import logo from "../../img/1.png";
import window from "../../img/Window.png";

const Header = ({ style, children }) => (
  <Grid container direction="column" style={style.section_rowd}>
    <Grid item md={1.5}>
      <img style={style.image} src={logo} alt="" />
    </Grid>
    <Divider
      style={style.headerverticalline}
      orientation="vertical"
      flexItem
    />
    <Grid item md={8} style={{ paddingTop: 10, paddingLeft: 10 }}>
      <div style={style.text}>WINDOW COVERING PROPOSAL</div>
      <div style={style.subtext}>FOR {children}</div>
    </Grid>
    <Grid md={2}>
      <img style={style.windowimage} src={window} alt="Image of 3 Windows with different coverings" />
    </Grid>
  </Grid>
);

export default Header;