/** @format */

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CONFIG from '../../config/config.js';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Stack, Chip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HeaderLogoIcon from '../../assets/icons/secondary/hdft-TBM-logo.svg';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import headerBack from '../../assets/icons/primary/header-return.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import '../../index.css';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  width: 300,
  color: theme.palette.text.secondary,
}));

const vertical = 'top';
const horizontal = 'center';

export default function PricingLookup() {
  const [enterSkuData, setEnterSkuData] = useState([]);
  const [height, setHeight] = useState('');
  const [miles, setMiles] = useState('');
  const [width, setWidth] = useState('');
  const [showPriceTable, setShowPriceTable] = useState(false);
  const [showSkuTable, setShowSkuTable] = useState(false);
  const [getSku, setGetSku] = useState(false);
  const [getPrice, setGetPrice] = useState();
  const [getPriceType, setGetPriceType] = useState();
  const [Active, setActive] = useState('');
  const [getBrand, setGetBrand] = useState('');
  const [getProduct, setGetProduct] = useState('');
  const [getType, setGetType] = useState('');
  const [getLabel, setGetLabel] = useState('');
  const [getCategory, setGetCategory] = useState('');
  const [showWidthHeightInput, setWidthHeightInput] = useState(true);
  const [showWidthInput, setWidthInput] = useState(false);
  const [showHeightInput, setHeightInput] = useState(false);
  const [showMilesInput, setMilesInput] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState('');

  const [gettingPrice, setGettingPrice] = useState(true);
  const [showPriceTypeInService, setShowPriceTypeInService] = useState(false);
  const [blankSpace, setBlankSpace] = useState(true);
  const navigate = useNavigate();
  const [errormsg, setErrorMsg] = useState(false);
  const [success, setSuccess] = useState(false);
  const margintp = {
    marginTop: '4%',
    marginLeft: '10px',
    backgroundColor: 'white',
    input: { textAlign: 'center' },
  };

  useEffect(() => {
    getVerified();
  }, []);
  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };
  //get token from local
  const localtoken = sessionStorage.getItem('Localtoken');
  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };
  const getInputSKUWidthHeight = (e) => {
    setWidth('');
    setHeight('');
    setMiles('');
    setEnterSkuData(e.target.value);
    var matches = e.target.value.match(/\b(\w)/g);

    if (matches !== null && (matches[0] === 'S' || matches[0] === 'X')) {
      setWidthHeightInput(true);
      setShowPriceTypeInService(true);
    }
    if (matches !== null && (matches[0] === 'M' || e.target.value === 'A504012' || e.target.value === 'L504008')) {
      setWidthHeightInput(false);

      setWidthInput(false);
    } else {
      setWidthHeightInput(true);
    }

    if (matches !== null && e.target.value === 'S1003') {
      setWidthHeightInput(true);
      setMilesInput(true);
    } else {
      setMilesInput(false);
    }

    if (
      matches !== null &&
      (e.target.value === 'A504004' ||
        e.target.value === 'A504006' ||
        e.target.value === 'A504007' ||
        e.target.value === 'A504008' ||
        e.target.value === 'A504009' ||
        e.target.value === 'A504010' ||
        e.target.value === 'A504011' ||
        e.target.value === 'P504001' ||
        e.target.value === 'P504013' ||
        e.target.value === 'L504006' ||
        e.target.value === 'L504007' ||
        e.target.value === 'L5040025' ||
        e.target.value === 'L504026' ||
        e.target.value === 'L504027' ||
        e.target.value === 'L504028')
    ) {
      setWidthInput(true);
      setShowPriceTypeInService(false);
    } else {
      setWidthInput(false);
    }
  };
  // Get sku discription
  const getData = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: localtoken,
      },
      body: JSON.stringify({
        sku: enterSkuData,
        per_value: miles,
        width: width,
        height: height,
      }),
    };
    await fetch(`${CONFIG.API_URL}/pm/SKUPricing/`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setSuccess(true);
          setGetPrice(data.price);

          setGetBrand(data.SKUDetails[0].brand);
          setGetProduct(data.SKUDetails[0].product);
          setGetCategory(data.SKUDetails[0].sku_name);

          switch (data.SKUDetails[0].sku_type) {
            case 'S':
              setGetType('Service');
              break;
            case 'M':
              setGetType('Material');
              break;
            case 'X':
              setGetType('Accessories');
              break;
            case 'A':
              setGetType('Attribute');
              break;
            case 'B':
              setGetType('BPL');
              break;
            case 'L':
              setGetType('Lift System');
              break;
            case 'P':
              setGetType('Option');
              break;

            default:
              setGetType();
              break;
          }
          setGetLabel(data.SKUDetails[0].label);
          setGetSku(data.SKUDetails[0].sku);
          setGetPriceType(data.PriceType);

          setActive(data.SKUDetails[0].active);

          setShowPriceTable(true);
        } else {
          setErrorMsg(true);
          setShowPriceTable(false);
          setErrorMessage(data.error_message);
        }
      });
    setShowSkuTable(true);
  };

  useEffect(() => {}, []);

  const fillBlankSpace = () => {
    setBlankSpace(false);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMsg(false);
    setSuccess(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  return (
    <>
      <div align='center'>
        <Stack spacing={14} sx={{ width: '100%' }}>
          <Snackbar className='snackbar_bar_postition' open={errormsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setErrorMsg(false)}>
            <Alert className='Error mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel'>
                  <img alt='CANCELICONRED' onClick={handleErrorClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                </div>
                <div className='alertMain'>
                  <img alt='CONFIRMICONRED' src={RedExclamationIcon} className='primary_icons_dimension' />

                  <div align='center' style={{ paddingTop: 10 }}>
                    {ErrorMessage}
                  </div>
                </div>
              </>
            </Alert>
          </Snackbar>
        </Stack>
        <Stack spacing={14} sx={{ width: '100%' }}>
          <Snackbar className='snackbar_bar_postition' open={success} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setSuccess(false)}>
            <Alert className='Success mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel'>
                  <img alt='CANCELICON' onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                </div>
                <div className='alertMain'>
                  <img alt='CONFIRMICON' src={DrawerAlertIcon} className='primary_icons_dimension' />

                  <div align='center' style={{ paddingTop: 10 }}>
                    Success !
                  </div>
                </div>
              </>
            </Alert>
          </Snackbar>
        </Stack>
        <AppBar className='jsonconverter_header'>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ paddingLeft: '10px' }}>
              <img onClick={() => navigate('/tbm/ps/dashboard')} src={HeaderLogoIcon} alt='Logo' className='logout_icon_dimension drawer_cancel_cursor' />
            </Typography>
            <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
              TBM-DB-TOOLS
            </Typography>

            <Typography sx={{ paddingLeft: '10px' }}>
              <img onClick={() => navigate(-1)} src={headerBack} alt='Logo' className='dbtools_backbuttton ' />
            </Typography>
          </Toolbar>
        </AppBar>
        <br />
        <br />
        <br />
        <h2 style={{ color: 'black', fontWeight: 'bold' }}>PRICING LOOKUP</h2>
        {gettingPrice ? (
          <Box className='sku_Input_Box_First'>
            <TextField sx={margintp} label='Enter SKU' id='outlined-size-small' size='small' onInput={toInputUppercase} onChange={getInputSKUWidthHeight} onKeyPress={(e) => e.key === 'Enter' && getData()} />
            {!showWidthHeightInput ? (
              <>
                <TextField className='input_width_Pricing_page' sx={margintp} label='Enter Width In Inches' id='outlined-size-small' size='small' value={width} onChange={(e) => setWidth(e.target.value)} />
                <TextField className='input_Height_Pricing_page' sx={margintp} label='Enter Height In Inches' id='outlined-size-small' size='small' value={height} onChange={(e) => setHeight(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && getData()} />
              </>
            ) : (
              ''
            )}

            {/* width */}
            {showWidthInput ? (
              <>
                <TextField className='input_width_Pricing_page' sx={margintp} label='Enter Width In Inches' id='outlined-size-small' size='small' value={width} onChange={(e) => setWidth(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && getData()} />
              </>
            ) : (
              ''
            )}
            {/* width */}

            {/* height  */}
            {showHeightInput ? (
              <>
                <TextField className='input_Height_Pricing_page' sx={margintp} label='Enter Height In Inches' id='outlined-size-small' size='small' value={height} onChange={(e) => setHeight(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && getData()} />
              </>
            ) : (
              ''
            )}

            {/* height  */}

            {showMilesInput ? <TextField sx={margintp} label='Enter Miles' id='outlined-size-small' size='small' value={miles} onChange={(e) => setMiles(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && getData()} /> : ''}
            <br />
            <Button
              className='get_Pricing_Button'
              onClick={() => {
                getData();
                setGettingPrice(false);
                fillBlankSpace();
              }}
              variant='contained'
            >
              Get Pricing
            </Button>
          </Box>
        ) : (
          <Box className='sku_Input_Box'>
            <TextField sx={margintp} label='Enter SKU' id='outlined-size-small' size='small' onInput={toInputUppercase} onChange={getInputSKUWidthHeight} onKeyPress={(e) => e.key === 'Enter' && getData()} />
            {!showWidthHeightInput ? (
              <>
                <TextField sx={margintp} className='input_width_Pricing_page' label='Enter Width In Inches' id='outlined-size-small' size='small' value={width} onChange={(e) => setWidth(e.target.value)} />
                <TextField sx={margintp} className='input_Height_Pricing_page' label='Enter Height In Inches' id='outlined-size-small' size='small' value={height} onChange={(e) => setHeight(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && getData()} />
              </>
            ) : (
              ''
            )}

            {/* width */}
            {showWidthInput ? (
              <>
                <TextField className='input_width_Pricing_page' sx={margintp} label='Enter Width In Inches' id='outlined-size-small' size='small' value={width} onChange={(e) => setWidth(e.target.value)} />
              </>
            ) : (
              ''
            )}
            {/* width */}

            {/* height  */}

            {showHeightInput ? (
              <>
                <TextField className='input_Height_Pricing_page' sx={margintp} label='Enter Height In Inches' id='outlined-size-small' size='small' value={height} onChange={(e) => setHeight(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && getData()} />
              </>
            ) : (
              ''
            )}

            {/* height  */}

            {showMilesInput ? <TextField sx={margintp} label='Enter Miles' id='outlined-size-small' size='small' onChange={(e) => setMiles(e.target.value)} onKeyPress={(e) => e.key === 'Enter' && getData()} /> : ''}
            <br />
            <Button
              className='get_Pricing_Button'
              onClick={() => {
                getData();
                setGettingPrice(false);
                fillBlankSpace();
              }}
              variant='contained'
            >
              Get Pricing
            </Button>
          </Box>
        )}
        {showPriceTable ? <Typography className='sku_Details_Header'> SKU Price</Typography> : ''}
        {showPriceTable ? (
          <Box className='sku_Price_Main_Box'>
            <Box className='sku_Price_Sub_Box'>
              <Item>
                Price:{' '}
                <span
                  style={{
                    fontWeight: 'bold',
                    color: 'black',
                    fontSize: '15px',
                  }}
                  className=''
                >
                  {' '}
                  {getPriceType ? <>(+)</> : ''} ${getPrice}&nbsp;&nbsp;&nbsp;
                </span>
                {getPriceType ? (
                  <>
                    {showPriceTypeInService ? (
                      <>
                        PriceType:{' '}
                        <span style={{ fontWeight: 'bold', color: 'black' }} className='brand_Product_Label_Category_Font'>
                          {getPriceType}
                        </span>
                      </>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}
              </Item>
            </Box>
          </Box>
        ) : null}
        {showPriceTable ? <Typography className='sku_Details_Header'> SKU Details</Typography> : ''}
        {showPriceTable ? (
          <>
            <Box align='start' className='sku_Details_Main_Box'>
              {getSku ? (
                <Box className='sku_Details_Sub_Box'>
                  <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={1}>
                    <Item className='sku_type_status_brand_product_label_Pricing_page'>
                      SKU:{<br />} <span className='brand_Product_Label_Category_Font'>{getSku}</span>
                    </Item>
                    <Item className='sku_type_status_brand_product_label_Pricing_page'>
                      Type:{<br />} <span className='brand_Product_Label_Category_Font'>{getType}</span>
                    </Item>
                    <Item className='sku_type_status_brand_product_label_Pricing_page'>
                      {Active === true ? (
                        <>
                          Status:
                          <span>
                            <Chip size='small' label='Active' color='success' />
                          </span>
                        </>
                      ) : (
                        <>
                          Status:
                          <span>
                            <Chip size='small' label='Inactive' color='error' />
                          </span>
                        </>
                      )}
                    </Item>
                  </Stack>
                </Box>
              ) : (
                ''
              )}
              <Box className='sku_Details_Box_Item'>
                {getBrand ? (
                  <>
                    <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={1}>
                      {getBrand ? (
                        <Item className='sku_type_status_brand_product_label_Pricing_page'>
                          Brand: <span className='brand_Product_Label_Category_Font'>{getBrand}</span>
                        </Item>
                      ) : (
                        ''
                      )}

                      {getProduct ? (
                        <Item className='sku_type_status_brand_product_label_Pricing_page'>
                          Product: <span className='brand_Product_Label_Category_Font'>{getProduct}</span>
                        </Item>
                      ) : (
                        ''
                      )}
                      {getLabel ? (
                        <Item className='sku_type_status_brand_product_label_Pricing_page'>
                          Label: <span className='brand_Product_Label_Category_Font'>{getLabel}</span>
                        </Item>
                      ) : (
                        ''
                      )}
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={1} mt={0}>
                      {getLabel === null ? (
                        ''
                      ) : (
                        <Item className='category_width_Pricing_Page'>
                          Label: <span className='brand_Product_Label_Category_Font'>{getLabel}</span>
                        </Item>
                      )}
                    </Stack>
                  </>
                )}

                <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={1} mt={2}>
                  {getCategory === null ? (
                    ''
                  ) : (
                    <Item className='category_width_Pricing_Page'>
                      Description: <span className='brand_Product_Label_Category_Font'>{getCategory}</span>
                    </Item>
                  )}
                </Stack>
              </Box>
            </Box>
            <Box className='bottom_Space'>{''}</Box>
          </>
        ) : (
          <Box className='blank_Space_Box_Width_Height_Pricing_Page'></Box>
        )}
      </div>
    </>
  );
}
