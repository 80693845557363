/** @format */

import React, { forwardRef, useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import CONFIG from '../../config/config.js';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import SelectIcon from '../../assets/icons/secondary/2nd-confirm.svg';
import ContinueIcon from '../../assets/icons/primary/continue.svg';
import ContinueHovIcon from '../../assets/icons/primary/WithHovIcon/continue-hov.svg';
import Snackbar from '@mui/material/Snackbar';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import { makeStyles } from '@mui/styles';
import MuiAlert from '@mui/material/Alert';
import ShowProposalName from './ShowProposalName';
import checkicon from '../../img/checkicon.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import fracty from 'fracty';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ProposalsStepper from './ProposalsStepper';
import { TablePagination } from '@mui/material';
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
const TurquoiseTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0ca8b0',
    color: '#ffffff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 90,
    textAlign: 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#0ca8b0',
      border: '1px solid #0ca8b0',
    },
  },
}));

const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#333333',
    },
  },
});
const innerTheme = createTheme({
  palette: {
    primary: {
      main: '#ADADAD',
    },
  },
});
const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  paper: {
    width: '50%',
    // backdropFilter: "blur(10px) !important",
    background: 'rgba(69, 157, 166, 70%)  !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

const unique = (value, index, self) => {
  return self.indexOf(value) === index;
};

const vertical = 'top';
const horizontal = 'center';

export default function ProposalSelectWindow() {
  const token = sessionStorage.getItem('token');

  const classes = useStyles();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [selected, setSelected] = useState([]);
  const [reselected, setReSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);
  const [windowsselected, setWindowsSelected] = useState([]);
  const [showSA, setShowSA] = useState(true);
  const [selectedval, setSelectedval] = useState(true);
  const [checkedrowdata, setCheckedRowData] = useState([]);
  const [removereapplyCoveringsWindows, setRemoveReapplyCoveringsWindows] = useState([]);
  const [addreapplyCoveringsWindows, setAddReapplyCoveringsWindows] = useState([]);
  const [windowsallreadySelected, setWindowsallreadySelected] = useState([]);
  const [windowsremaining, setWindowsremaining] = useState([]);
  const [windowReselected, setWindowReSelected] = useState(false);
  const [selectedwindowLength, setSelectedWindowLength] = useState();
  const [isreappliedwindowsSelected, setIsReappliedWindowsSelected] = useState(false);
  const [reselectedwindow, setReSelectedWindow] = useState([]);
  const [tablewidth, setTableWidth] = useState();

  // error handling states
  const [ProposalByProposalIdErrorAlert, setProposalByProposalIdErrorAlert] = useState(false);
  const [ProposalDetailsErrorAlert, setProposalDetailsErrorAlert] = useState(false);

  const [ProposalDetailsErrorMsg, setProposalDetailsErrorMsg] = useState('');
  const [ProposalByProposalIdErrorMsg, setProposalByProposalIdErrorMsg] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  let overall_selected_windows = removereapplyCoveringsWindows;
  if (location.state === null) {
    navigate('/pagenotfound');
  }

  let proposaldetails = {
    customerdata: location.state.customerdata,
    proposal_id: location.state.proposalID,
    proposal_name: location.state.proposalName,
  };

  const AllReadySelectedWindows = {
    backgroundColor: '#FFF',
    fontSize: '100%',
  };

  let coveringdetails = location.state.coveringdetails;
  const totalwindows = location.state.totalwindows;

  useEffect(() => {
    getVerified();
  }, []);

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };
  let isItemSelected;
  let isReItemSelected;

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='table-headerppsw sticky-column'>
            <span style={{ marginLeft: '30px' }}>#</span> <span style={{ marginLeft: '26px' }}>Window Name</span>
          </TableCell>

          <TableCell className='table-headersw1'>Price</TableCell>
          <TableCell className='table-header-cd-proposal2'>Size</TableCell>
          <TableCell className='table-header_material'>Covering System</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const [showProposalName, setShowProposalName] = useState();

  const ProposalByProposal = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };
    // Created Proposal will be added to the database through CreateProposal API
    await fetch(`${CONFIG.API_URL}/pm/ProposalByProposalId/${location.state.proposalID}`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setShowProposalName(data.proposal.proposal_name);
        } else {
          setProposalByProposalIdErrorMsg(data.error_message);
          setProposalByProposalIdErrorAlert(true);
        }
      });
  };

  useEffect(() => {
    ProposalByProposal();
    getData();
  }, []);
  let max = '';

  if (max) {
    max = max * 100 + '%';
  }
  const customerdata = location.state.customerdata;

  const [proposalName, setProposalName] = useState();
  const [selectedRow, setSelectedRow] = useState([]);

  // To get proposal details
  const getData = async () => {
    await fetch(`${CONFIG.API_URL}/pm/ProposalDetails/${location.state.proposalID}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          let selectedrows = [];
          let allreadyselectedwindows = [];
          let remainingwindows = [];
          data.proposal_details.forEach((element) => {
            if (element.window_selected === true) {
              selectedrows.push(element);
            }
            if (element.window_selected === true && element.covering_status === true) {
              allreadyselectedwindows.push(element);
            } else if (element.window_selected === true && element.covering_status === 'not_consider') {
              remainingwindows.push(element);
            }
          });
          const reSelectedWindows = [];
          data.proposal_details.forEach((element) => {
            if (element.covering_selected === true) {
              reSelectedWindows.push(element);
            }
          });
          setReSelected(reSelectedWindows);

          setWindowsallreadySelected(allreadyselectedwindows);
          setWindowsremaining(remainingwindows);
          setWindowsSelected(selectedrows);
          setCheckedRowData(remainingwindows);
          max = selectedrows[0].window_name.length;
          selectedrows.forEach((element) => {
            if (element.window_name.length > max) max = element.window_name.length;
          });

          let widthfortable = max + 10 + 'px';
          setTableWidth(widthfortable);
        } else {
          setProposalDetailsErrorMsg(data.error_message);
          setProposalDetailsErrorAlert(true);
        }
      });
  };

  const handleSelectAllClick = (event) => {
    if (event === true) {
      setSelectedval(false);

      let notappliedarray = [];
      windowsselected.forEach((element) => {
        if (element.covering_selected === false) {
          notappliedarray.push(element);
        }
      });

      const newSelecteds = windowsselected.map((n) => n);
      setSelected(notappliedarray);
      setSelectedRow(newSelecteds);

      setCheckedRowData([]);
    } else {
      setSelectedval(true);

      const newSelecteds = [];

      setCheckedRowData(windowsremaining);
      setSelectedRow([]);
      setSelected([]);
    }
  };
  const handleClick = (event, row) => {
    let flag;
    if (event.target.checked === true) {
      flag = false;
    }
    if (flag === true) {
      const selectedIndex = selected.indexOf(row);

      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      if (newSelected.length === windowsselected.length) {
        setShowSA(true);
      }

      setCheckedRowData(newSelected);
      setSelected(newSelected);
    } else {
      const selectedIndex = selected.indexOf(row);
      const selectedActualIndex = windowsselected.indexOf(row);
      windowsselected[selectedActualIndex].selected = false;

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }
      let selectedwindows = windowsremaining.filter((val) => !newSelected.includes(val));
      if (newSelected.length === 0) {
        setShowSA(true);
      }

      if (event.target.checked === true && row.covering_selected === true) {
        setWindowReSelected(false);

        let onlyreappliedwindows = [];
        let arrayflag;
        let count = 0;
        newSelected.forEach((element) => {
          if (element.covering_selected === true) {
            count++;
            if (count < newSelected.length) {
              arrayflag = true;
            } else {
              arrayflag = false;
            }
          }
        });
        if (arrayflag === true) {
          newSelected.forEach((window) => {
            if (window.covering_selected === true) {
              onlyreappliedwindows.push(window);
            }
          });

          setAddReapplyCoveringsWindows(onlyreappliedwindows);
        } else {
          setAddReapplyCoveringsWindows(newSelected);
        }
      }

      setCheckedRowData(selectedwindows);
      setRemoveReapplyCoveringsWindows(selectedwindows);
      setSelected(newSelected);
      if (event.target.checked === false && row.covering_selected === true) {
        setCheckedRowData([]);
        setWindowReSelected(true);
        let remove = overall_selected_windows.filter((element) => element.window_id !== row.window_id);
        setRemoveReapplyCoveringsWindows(remove.filter(unique));
      }
    }
  };

  useEffect(() => {
    if (windowReselected) {
      setCheckedRowData(removereapplyCoveringsWindows);
    } else {
      var selectedwindow = addreapplyCoveringsWindows.filter(unique);

      checkedrowdata.push(...selectedwindow.filter(unique));
    }

    var selectedwindows = checkedrowdata.filter(unique).length;
    setSelectedWindowLength(selectedwindows);
  }, [windowReselected, addreapplyCoveringsWindows, removereapplyCoveringsWindows, windowsselected]);

  let isSelected = (row) => selected.indexOf(row) === -1;
  let isReselected = (row) => selected.indexOf(row) === -1;

  let ReappliedArray = [];
  var item;
  const reApply = (event, row) => {
    setIsReappliedWindowsSelected({ id: row, check: event.target.checked });
    ReappliedArray.push({ id: row.window_id, check: event.target.checked });
    setReSelectedWindow(row);

    for (var i = 0; i < ReappliedArray.length; i++) {
      if (ReappliedArray[i].id === row.window_id) {
        item = ReappliedArray[i].check;
      }
    }
  };

  const onSelectAllClick = () => {
    setShowSA(true);
    handleSelectAllClick(false);
  };
  const onDeSelectAllClick = () => {
    setShowSA(false);
    handleSelectAllClick(true);
  };

  const continueToProposalApplyCoveringFun = () => {
    navigate(`/tbm/ps/proposalapplycovering/${params.id}`, {
      state: {
        selectedwindows: checkedrowdata,
        customerdata: customerdata,
        proposalID: location.state.proposalID,
        proposalName: location.state.proposalName,
        totalselectedwindows: windowsselected,
        totalwindows: totalwindows,
        coveringdetails: coveringdetails,
        path: location.state.path,
      },
    });
  };

  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    marginTop: 4,
    marginLeft: 3,
    marginRight: 2.5,
    width: 18,
    height: 18,
    backgroundColor: '#ADADAD',
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#ADADAD',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      marginLeft: 2,
      marginTop: 1.5,
      fontWeight: 'bold',
      width: 14,
      height: 14,
      backgroundImage: `url(${checkicon})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#ADADAD',
    },
  });
  function BpCheckbox(props) {
    return (
      <Checkbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
        }}
        disableRipple
        color='default'
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ 'aria-label': 'Checkbox demo' }}
        {...props}
      />
    );
  }

  const LongText = ({ content }) => {
    const [limit, setLimit] = useState(CONFIG.GlobalTruncateCharacterLimit);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    if (content === undefined) {
      // Data is undefined, return empty
      return null;
    }

    // Check if the window width is between 280px and 768px and content length is greater than 14
    if (windowWidth >= 280 && windowWidth <= 767 && content.length > 15) {
      const truncatedContent = content.substring(0, 15) + '...';
      return <span>{truncatedContent}</span>;
    }

    // Check if the window width is above or equal to 768
    if (windowWidth >= 768) {
      if (content.length <= limit) {
        // There is nothing more to show
        return <span>{content}</span>;
      }

      let truncatedContent = content.substring(0, limit);

      // Find the last space within the truncated content
      const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

      // Adjust the truncated content if a space was found
      if (lastSpaceIndex !== -1) {
        truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
      }

      // Add the ellipsis to the truncated content
      const toShow = truncatedContent + '...';

      return <span>{toShow}</span>;
    }

    if (content.length <= limit) {
      // There is nothing more to show
      return <span>{content}</span>;
    }

    let truncatedContent = content.substring(0, limit);

    // Find the last space within the truncated content
    const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

    // Adjust the truncated content if a space was found
    if (lastSpaceIndex !== -1) {
      truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
    }

    // Add the ellipsis to the truncated content
    const toShow = truncatedContent + '...';

    return <span>{toShow}</span>;
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  const [isImageHovered3, setIsImageHovered3] = useState(false);
  const handleHover3 = () => {
    setIsImageHovered3(!isImageHovered3);
  };

  const windowDetails = {
    totalselectedwindows: '',
    selectedwindows: '',
    totalwindows: '',
  };

  return (
    <div align='center'>
      {/* ProposalByProposalIdErrorAlert */}
      <Snackbar className='snackbar_bar_postition' open={ProposalByProposalIdErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setProposalByProposalIdErrorAlert(false)}>
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img
              onClick={() => {
                setProposalByProposalIdErrorAlert(false);
              }}
              src={RedDrawerCancelIcon}
              alt='CANCELICONPBPA'
              className='drawer_secondary_icons_dimension drawer_cancel_cursor'
            />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img src={RedExclamationIcon} alt='CONFIRMICONPBPA' className='primary_icons_dimension alertImageMargin' />
            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{ProposalByProposalIdErrorMsg}</div>
          </div>
        </Alert>
      </Snackbar>

      {/* ProposalDetailsErrorAlert */}
      <Snackbar className='snackbar_bar_postition' open={ProposalDetailsErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setProposalDetailsErrorAlert(false)}>
        <Alert className='Error1 mainAlert' variant='filled' icon={false}>
          <div className='alertCancel_2'>
            <img
              onClick={() => {
                setProposalDetailsErrorAlert(false);
              }}
              src={RedDrawerCancelIcon}
              alt='CANCELICONPDA'
              className='drawer_secondary_icons_dimension drawer_cancel_cursor'
            />
          </div>
          <div className='alertMain_2_4 confirmIconStyle1'>
            <img src={RedExclamationIcon} alt='CONFIRMICONPDA' className='primary_icons_dimension alertImageMargin' />
            <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{ProposalDetailsErrorMsg}</div>
          </div>
        </Alert>
      </Snackbar>

      <ThemeProvider theme={outerTheme}>
        <ShowProposalName sx={{ marginBottom: '20px !important' }} proposaldetails={proposaldetails} />

        <div align='center' style={{ marginBottom: '30px', paddingTop: '20px !impotant' }} className='button_top_space_1_1'>
          <ProposalsStepper windowDetails={windowDetails} stepperData='SelectWindowToApplyCovering' />
        </div>

        {!showSA ? (
          <span style={{ marginLeft: '16px' }} className='gray_button_span button_bottom_space'>
            <Box onClick={onSelectAllClick} className='globalIconMainBox'>
              <img alt='SELECTALL' src={SelectIcon} className={`secondary_icons_dimension ${isImageHovered3 ? 'hovered' : ''}`} />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover3} onMouseLeave={handleHover3}>
                SELECT ALL
              </Typography>
            </Box>
          </span>
        ) : (
          <span style={{ marginLeft: '16px' }} className='gray_button_span button_bottom_space'>
            <Box onClick={onDeSelectAllClick} className='globalIconMainBox'>
              <img alt='DESELECTALL' src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered3 ? 'hovered' : ''}`} />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover3} onMouseLeave={handleHover3}>
                DESELECT ALL
              </Typography>
            </Box>
          </span>
        )}
        {max}
        <hr className='button_bottom_space' />
        <Box className='table-box'>
          <TableContainer sx={{ borderBottom: '1px solid #D6D6D6', textAlign: 'left' }} className='table_padding_bottom'>
            <Table className='table-dimension-apply-coverings' aria-labelledby='tableTitle' size='small'>
              <EnhancedTableHead numSelected={selected.length} onSelectAllClick={handleSelectAllClick} rowCount={windowsselected.length} />
              <TableBody>
                {(rowsPerPage > 0 ? windowsselected.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : windowsselected).map((row, index) => {
                  isItemSelected = isSelected(row);
                  isReItemSelected = isReselected(row);

                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover className='rowhover' onClick={(event) => handleClick(event, row)} role='checkbox' aria-checked={isItemSelected} tabIndex={-1} key={row.window_id}>
                      {row.covering_selected === true ? (
                        <>
                          {row.covering_status === 'not_applied' ? (
                            <>
                              <TableCell align='left' className='table-content-apply-coveringo sticky-columnws-1'>
                                <Box
                                  sx={{
                                    width: '100% !important',
                                    color: '#008593',
                                    cursor: 'pointer',
                                    display: 'flex',
                                  }}
                                  className='ellipsis2'
                                >
                                  <ThemeProvider theme={innerTheme}>
                                    <BpCheckbox
                                      sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                          zoom: 0.8,
                                          paddingLeft: '0px',
                                          paddingRight: '0px',
                                          paddingTop: '0px',
                                        },
                                      }}
                                      checked={!isItemSelected}
                                      onClick={(e) => reApply(e, row)}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                    />
                                  </ThemeProvider>

                                  <span
                                    style={{
                                      paddingRight: `${windowsselected.indexOf(row) + 1 > 9 ? '20px' : '30px'}`,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      paddingLeft: '15px',
                                      color: 'black',
                                    }}
                                  >
                                    {windowsselected.indexOf(row) + 1}
                                  </span>
                                  <span>
                                    {' '}
                                    <span className='AddingsalesOrderWindowName'>
                                      <LongText content={row.window_name} />
                                    </span>
                                  </span>
                                </Box>
                              </TableCell>

                              <TableCell align='left' className='table-content' sx={{ color: 'red' }}>
                                ─ ─
                              </TableCell>
                              <TableCell align='left' className='table-content ellipsis2' sx={{ color: 'red' }}>
                                {fracty(row.window_width)}" x {fracty(row.window_height)}"{' '}
                                <span className={row.measurement_type.replace(/ /g, '')}>
                                  {row.measurement_type === 'Quick'
                                    ? '(QK)'
                                    : row.measurement_type === 'Shutters'
                                    ? '(SH)'
                                    : row.measurement_type === 'Blinds'
                                    ? '(BL)'
                                    : `(${row.measurement_type
                                        .split(' ')
                                        .map((word) => word[0])
                                        .join('')})`}
                                </span>
                              </TableCell>
                              <TableCell align='left' className='table-content-second' sx={{ color: 'red' }}>
                                ─ ─
                              </TableCell>
                            </>
                          ) : (
                            <>
                              <TableCell align='left' className='table-content sticky-columnws-1'>
                                <Box
                                  sx={{
                                    width: '100% !important',
                                    color: '#008593',
                                    cursor: 'pointer',
                                    display: 'flex',
                                  }}
                                  className='ellipsis2'
                                >
                                  <ThemeProvider theme={innerTheme}>
                                    <BpCheckbox
                                      sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                          zoom: 0.8,
                                          paddingLeft: '0px',
                                          paddingTop: '0px',
                                        },
                                      }}
                                      checked={!isItemSelected}
                                      onClick={(e) => reApply(e, row)}
                                      inputProps={{
                                        'aria-labelledby': labelId,
                                      }}
                                    />
                                  </ThemeProvider>

                                  <span
                                    style={{
                                      paddingRight: `${windowsselected.indexOf(row) + 1 > 9 ? '20px' : '30px'}`,
                                      display: 'flex',
                                      paddingLeft: '6px',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      color: 'black',
                                    }}
                                  >
                                    {windowsselected.indexOf(row) + 1}
                                  </span>
                                  <span>
                                    <span className='AddingsalesOrderWindowName'>
                                      <LongText content={row.window_name} />
                                    </span>
                                  </span>
                                </Box>
                              </TableCell>
                              <TableCell align='left' className='table-content' sx={AllReadySelectedWindows}>
                                ${row.suggested_price}
                              </TableCell>
                              <TableCell align='left' className='table-content ellipsis2' sx={AllReadySelectedWindows}>
                                {fracty(row.window_width)}" x {fracty(row.window_height)}"{' '}
                                <span className={row.measurement_type.replace(/ /g, '')}>
                                  {row.measurement_type === 'Quick'
                                    ? '(' + 'QK' + ')'
                                    : row.measurement_type === 'Shutters'
                                    ? '(' + 'SH' + ')'
                                    : row.measurement_type === 'Blinds'
                                    ? '(' + 'BL' + ')'
                                    : '(' +
                                      row.measurement_type
                                        .split(' ')
                                        .map((word) => word[0])
                                        .join('') +
                                      ')'}
                                </span>
                              </TableCell>
                              <TableCell align='left' className='table-content-second2' sx={AllReadySelectedWindows}>
                                {row.material_code.toString().replace(/,/g, ', ')}, {row.build_type}, {row.existing_covering}, {row.mount_surface}, {row.ladder}
                              </TableCell>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <TableCell className='table-content-apply-coveringo sticky-columnws-1' align='left'>
                            <Box
                              sx={{
                                width: '100% !important',
                                color: '#008593',
                                cursor: 'pointer',
                                display: 'flex',
                              }}
                              className='ellipsis2'
                            >
                              <Checkbox
                                sx={{
                                  zoom: 0.8,
                                  paddingLeft: '0px',
                                  paddingRight: '15px',
                                  paddingTop: '0px',
                                }}
                                color='primary'
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                              <span
                                style={{
                                  paddingRight: `${windowsselected.indexOf(row) + 1 > 9 ? '20px' : '30px'}`,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: 'black',
                                }}
                              >
                                {windowsselected.indexOf(row) + 1}
                              </span>

                              <span className='AddingsalesOrderWindowName'>
                                <LongText content={row.window_name} />
                              </span>
                            </Box>
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            ─ ─
                          </TableCell>
                          <TableCell className='table-content ellipsis2' align='left'>
                            {fracty(row.window_width)}" x {fracty(row.window_height)}"{' '}
                            <span className={row.measurement_type.replace(/ /g, '')}>
                              {row.measurement_type === 'Quick'
                                ? '(' + 'QK' + ')'
                                : row.measurement_type === 'Shutters'
                                ? '(' + 'SH' + ')'
                                : row.measurement_type === 'Blinds'
                                ? '(' + 'BL' + ')'
                                : '(' +
                                  row.measurement_type
                                    .split(' ')
                                    .map((word) => word[0])
                                    .join('') +
                                  ')'}
                            </span>
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            ─ ─
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{
              mx: 0,
              px: 0,
              textAlign: 'center',
            }}
            rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]}
            component='div'
            count={windowsselected.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPageprp) => {
              setPage(newPageprp);
            }}
            onRowsPerPageChange={({ target }) => {
              setRowsPerPage(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>

        <Box sx={{ width: '100%' }}>
          {!checkedrowdata.length > 0 ? (
            <span className='  proposal_select_window_continue_paddting_bottom button_top_space'>
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                <img alt='CONTINUED' src={ContinueIcon} className='primary_icons_dimension_disable' />

                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                  CONTINUE
                </Typography>
              </Box>
            </span>
          ) : (
            <span className='button_span proposal_select_window_continue_paddting_bottom button_top_space' onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
              <Box onClick={continueToProposalApplyCoveringFun} className='globalIconMainBox globalMarginLeftAddWindow'>
                {isHovered ? <img alt='CONTINUEH' className='primary_icons_dimension_1' src={ContinueHovIcon} /> : <img alt='CONTINUE' className='primary_icons_dimension_1' src={ContinueIcon} />}
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                  CONTINUE
                </Typography>
              </Box>
            </span>
          )}
          <hr />

          <span className='gray_button_span button_top_space' style={{ paddingBottom: '30px', paddingLeft: '22px' }}>
            <Box onClick={() => navigate(-1)} className='globalIconMainBox'>
              <img alt='CANCEL' src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered2 ? 'hovered' : ''}`} />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover2} onMouseLeave={handleHover2}>
                CANCEL
              </Typography>
            </Box>
          </span>
        </Box>
      </ThemeProvider>
    </div>
  );
}
