import { Box, Typography } from '@mui/material';

/*
 * alert: {
 *  key: unique key,
 *  text: string,
 *  active: boolean
 * }
 */

export default function InformationAlerts({ startIcon, sx, alerts = [] }) {
  return (
    <Box
      sx={{
        marginTop: '20px',
        display: 'flex',
        flexFlow: 'row no-wrap',
        marginLeft: '1.75em',
        alignItems: 'center',
        textAlign: 'left',
        ...(sx || {}),
      }}
    >
      {startIcon ? <img src={startIcon} className='icon-size-updated' /> : null}
      <Box>
        {alerts
          .filter((alert) => alert.active)
          .map((message) => (
            <Typography key={message.key}>{message.text}</Typography>
          ))}
      </Box>
    </Box>
  );
}
