import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Moment from 'react-moment';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import calenderIcon from '../../assets/icons/primary/calendar.svg';
import searchIcon from '../../assets/icons/primary/search.svg';
import searchHovIcon from '../../assets/icons/primary/WithHovIcon/search-hov.svg';
import calenderHovIcon from '../../assets/icons/primary/WithHovIcon/calender-hov.svg';
import CONFIG from '../../config/config.js';
import { AlertDispatchContext } from '../Shared/Alerts';
import ConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import CancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';

const { REACT_APP_CLIENT_ID, REACT_APP_SCOPE } = process.env;

const CustomerSearch = () => {
  const navigate = useNavigate();
  const dispatchAlert = useContext(AlertDispatchContext);
  const [searchParams, _] = useSearchParams();
  const [cookies, setCookie] = useCookies(['token']);

  const REDIRECT_URI = `${window.location.origin}/tbm/ps/dashboard`;

  const getOAuth = async (code) => {
    const response = await fetch(`https://api.na1.echosign.com/oauth/v2/token`, {
      method: 'POST',
      body: new URLSearchParams({
        code,
        client_id: REACT_APP_CLIENT_ID,
        client_secret: 'NjGRjOntTjHTXzdpfbOlKhHxDJdZZ_hR',
        redirect_uri: REDIRECT_URI,
        grant_type: 'authorization_code',
      }),
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      return console.error(data);
    }
    saveRefreshToken(data);
  };

  const saveRefreshToken = async ({ access_token, refresh_token, expires_in, token_type, api_access_point, web_access_point }) => {
    sessionStorage.setItem('adobe_refresh_token', refresh_token);
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${CONFIG.API_URL}/pm/setOAuth`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        access_token,
        refresh_token,
        expires_in,
        token_type,
        api_access_point,
        web_access_point,
      }),
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      console.error(data);
      return;
    }

    const { session, token: authToken, user } = data;

    sessionStorage.clear();
    sessionStorage.setItem('token', authToken);
    sessionStorage.setItem('user_id', user.id);
    sessionStorage.setItem('user', `${user.first_name} ${user.last_name}`);
    sessionStorage.setItem('userEmail', user.email);

    const expires = new Date(session.cookie.expires);
    setCookie('token', authToken, { path: '/', expires });

    navigate('/tbm/ps/dashboard');
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => setIsImageHovered(!isImageHovered);

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => setIsImageHovered2(!isImageHovered2);

  //  hover states

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const handleHoverFun = () => setIsHovered(true);
  const handleMouseLeaveFun = () => setIsHovered(false);
  const handleHoverFun2 = () => setIsHovered2(true);
  const handleMouseLeaveFun2 = () => setIsHovered2(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!sessionStorage.getItem('hasAdobeToken')) {
      dispatchAlert({
        action: 'OPEN_ALERT',
        payload: {
          id: 'link-adobe-account',
          autoHideDuration: null,
          message: 'You do not have an Adobe Sign account linked to your profile. Please link your account to continue.',
          severity: 'info',
          actions: [
            {
              label: 'Link Account',
              type: 'primary',
              startIcon: <img src={ConinueIcon} className='primary_icons_dimension' />,
              onClick: () => {
                window.location.href = `https://theblindman.na1.echosign.com/public/oauth/v2?redirect_uri=${REDIRECT_URI}&response_type=code&client_id=${REACT_APP_CLIENT_ID}&scope=${REACT_APP_SCOPE}`;
              },
            },
            {
              label: 'Cancel',
              type: 'secondary',
              startIcon: <img src={CancelIcon} className='drawer_secondary_icons_dimension' />,
              onClick: () =>
                dispatchAlert({
                  action: 'CLEAR_ALERT',
                  payload: { id: 'link-adobe-account' },
                }),
            },
          ],
        },
      });
    }
  }, []);
  
  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      getOAuth(code);
    }
  }, [searchParams]);

  return (
    <div>
      <Box className='Customer_serach_date_formats'>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ lineHeight: '26px' }} className='dayFormat'>
            <Moment format='dddd' />
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Moment format='MMMM D, YYYY' />
          </Box>
        </Box>
      </Box>

      <br />
      <br />

      <div className='CustomerSearch_Appointment_MainDiv'>
        <Button
          id='Customer'
          sx={{
            boxShadow: '0px 5px 4px 0px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
          }}
          className='searchCustomer button_bottom_space'
          variant='contained'
          onClick={() => navigate('/tbm/ps/searchCustomer/Customer')}
        >
          <Box onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun} className='globalIconMainBox globalTextDecoration'>
            {isHovered ? <img className='primary_icons_dimension_1' src={searchHovIcon} alt='searchHovIcon' /> : <img className='primary_icons_dimension_1' src={searchIcon} alt='searchIcon' />}

            <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} className='globalText'>
              SEARCH / ADD CUSTOMER
            </Typography>
          </Box>
        </Button>

        <Button
          sx={{
            boxShadow: '0px 5px 4px 0px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
          }}
          className='searchCustomer'
          variant='contained'
          onClick={() => navigate('/tbm/ps/selecteddateappointments')}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '55px',
            }}
            className='globalIconMainBox globalTextDecoration'
            onMouseEnter={handleHoverFun2}
            onMouseLeave={handleMouseLeaveFun2}
          >
            {isHovered2 ? <img className='primary_icons_dimension_1' src={calenderHovIcon} alt='calenderHovIcon' /> : <img className='primary_icons_dimension_1' alt='calenderIcon' src={calenderIcon} />}
            <Typography onMouseEnter={handleHover2} onMouseLeave={handleHover2} className='globalText'>
              MY APPOINTMENTS
            </Typography>
          </Box>
        </Button>
        {/* <Button
          sx={{
            boxShadow: '0px 5px 4px 0px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
            marginTop: '20px',
            padding: '0px',
          }}
          className='searchCustomer'
          variant='contained'
          onClick={() => navigate('/tbm/ps/salesordermasterrecord')}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '29px',
            }}
            className='globalIconMainBox globalTextDecoration'
            onMouseEnter={handleHoverFun4}
            onMouseLeave={handleMouseLeaveFun4}
          >
            <Box
              className={`primary_icons_dimension_db_tools ${isImageHovered4 ? 'hovered' : ''}`}
              sx={{
                backgroundColor: '#00B6BE',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isHovered4 ? <img className='primary_icons_dimension_1' src={SOMasterRecordHovIcon} alt='SOMasterRecordHovIcon' /> : <img className='primary_icons_dimension_1' src={SOMasterRecordIcon} alt='SOMasterRecordIcon' />}
            </Box>
            <Typography onMouseEnter={handleHover4} onMouseLeave={handleHover4} className='Sales_order_master_record_typography ' sx={{ display: 'flex', flexDirection: 'column' }}>
              <span>Sales Order Master</span>
              <span>Records</span>
            </Typography>
          </Box>
        </Button> */}
      </div>
    </div>
  );
};

export default CustomerSearch;
