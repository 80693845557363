import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import headerBack from '../../../assets/icons/primary/header-return.svg';
import CssBaseline from '@mui/material/CssBaseline';
import HeaderLogoIcon from '../../../assets/icons/secondary/hdft-TBM-logo.svg';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import 'react-toastify/dist/ReactToastify.css';
import CONFIG from '../../../config/config.js';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DrawerCancelIcon from '../../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../../assets/icons/drawer-alert/drw-alert.svg';

import { Paper, Box, FormControlLabel, Checkbox, IconButton, Typography, Divider, Button, TextField, Autocomplete } from '@mui/material';

const vertical = 'top';
const horizontal = 'center';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function DelimitedColors() {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [listColor, setListColor] = useState([]);
  const [successText, setSuccessText] = useState('');
  const token = sessionStorage.getItem('token');

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  useEffect(() => {
    getVerified();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(CONFIG.API_URL + '/color/GetMaterialSku', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          token: token,
        },
      });

      if (!response.ok) {
        throw new Error(`Fetch error: ${response.status}`);
      }

      const data = await response.json();

      return data.allsku;
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData().then((res) => {
      setList(res);
    });
  }, []);

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccess(false);
  };

  const fetchColor = async () => {
    try {
      const response = await fetch(CONFIG.API_URL + '/color/GetMaterialColors', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          token: token,
        },
      });

      if (!response.ok) {
        throw new Error(`Fetch error: ${response.status}`);
      }

      const data = await response.json();

      return data.materialcolors;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchColor().then((res) => setListColor(res));
  }, []);

  const [searchstring, setSearchstring] = useState('');

  const [searchColor, setsearchColor] = useState('');
  const [skuType, setSkuType] = useState('M', 'L', 'X', 'P', 'S'); // Initialize with a default SKU type (M, L, X, P, S)
  const [tableName, setTableName] = useState(); // Default table name for the default SKU type
  const [success, setSuccess] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState('');

  const handleInput = (e) => {
    setSelectedMaterial(e.target.value);
    // Split the string by hyphen and get the second part
    const parts = e.target.value.split('-');
    const stringAfterHyphen = parts[1];

    // setInput(e.target.value.toLowerCase());
    setSearchstring(stringAfterHyphen);
    // setFilterValue(true);
  };

  const handleInputColor = (e) => {
    setsearchColor(e.target.value.toLowerCase());
  };

  const filteredList = list.filter((element) => {
    if (searchstring === '') {
      return element;
    } else {
      return element.sku.includes(searchstring);
    }
  });
  useEffect(() => {}, [filteredList]);

  const filteredColorList = listColor.filter((element) => {
    if (searchColor === '') {
      return true;
    } else {
      return element.material_color_code.toLowerCase().includes(searchColor);
    }
  });

  const [selectedItems, setSelectedItems] = useState([]);
  const handleCheckboxChange = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const [GetColorCode, setGetColorCode] = useState([]);

  const [isCopied, setIsCopied] = useState(false);
  const [isSQLCopied, setIsSQLCopied] = useState(false);

  const selectedItemsString = selectedItems.map((item) => item.material_color_code).join(',');

  const handleCopyToClipboard = () => {
    const textToCopy = selectedItems.map((item) => item.material_color_code).join(',');

    if (navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy).then(() => {
        setIsCopied(true);
        notify();
        setTimeout(() => setIsCopied(false), 2000); // Reset the copied state after 2 seconds
      });
    } else {
      // Fallback for unsupported browsers
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setIsCopied(true);
      notify();
      setTimeout(() => setIsCopied(false), 3000); // Reset the copied state after 4 seconds
    }
  };

  const copyToClipboard = () => {
    const sqlToCopy = sqlQuery;

    if (navigator.clipboard) {
      navigator.clipboard.writeText(sqlToCopy).then(() => {
        setIsSQLCopied(true);
        notify();
        setTimeout(() => setIsSQLCopied(false), 2000); // Reset the copied state after 2 seconds
      });
    } else {
      // Fallback for unsupported browsers
      const textArea = document.createElement('textarea');
      textArea.value = sqlToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      setIsCopied(true);
      notify();
      setTimeout(() => setIsCopied(false), 3000); // Reset the copied state after 4 seconds
    }
  };

  const notify = () => {
    // toast.success("Copied");
    setSuccessText('Copied !');
    setSuccess(true);
  };

  const [sqlQuery, setSqlQuery] = useState('');
  // Update the tableName based on skuType using a useEffect
  useEffect(() => {
    // Define a mapping of SKU type to table name
    const skuTypeToTable = {
      M: 'product_material',
      L: 'product_lift_system',
      X: 'product_accessory',
      P: 'product_option',
      S: 'product_service',
    };

    // Set the table name based on skuType if it exists in the mapping
    if (skuType in skuTypeToTable) {
      setTableName(skuTypeToTable[skuType]);
    } else {
      // Handle the case when skuType is not found in the mapping
      setTableName(''); // You can set a default or handle this differently
    }
  }, [skuType]);

  const handleGenerateSqlQuery = () => {
    // Now you can use tableName to generate your SQL query
    const columnName = 'tbm_color_code'; // Change to your actual column name
    const newValue = `${selectedItems.map((item) => `${item.material_color_code}`).join(',')}`;

    const skuIds = filteredList.map((item) => `'${item.sku}'`).join(',');

    const sqlQuery = `UPDATE ${tableName} SET ${columnName} = '${newValue}' WHERE sku = ${skuIds};`;
    setSqlQuery(sqlQuery);

    // Now, you can use the query as needed or display it to the user
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  return (
    <div>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={success} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setSuccess(false)}>
          <Alert className='Success mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel'>
                <img onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
              </div>
              <div className='alertMain'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='DrawerAlertIcon' />

                <div align='center' style={{ paddingTop: 10 }}>
                  {successText}
                </div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>

      <Box>
        <CssBaseline />
        <AppBar position='fixed' className='jsonconverter_header'>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100vw',
              }}
            >
              <Typography sx={{ paddingLeft: '10px' }}>
                <img onClick={() => navigate('/tbm/ps/dashboard')} src={HeaderLogoIcon} alt='Logo' className='logout_icon_dimension drawer_cancel_cursor' />
              </Typography>
              <Typography sx={{ paddingTop: '8px', fontSize: '17px' }}>TBM-DBTools For Colors</Typography>
              <Typography
                className='return_to_dashboard'
                onClick={() => {
                  navigate(-1);
                }}
                sx={{
                  cursor: 'pointer',
                }}
                variant='h6'
                noWrap
                component='div'
              >
                <Typography sx={{ paddingLeft: '10px' }}>
                  <img onClick={() => navigate(-1)} src={headerBack} alt='Logo' className='dbtools_backbuttton ' />
                </Typography>
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
        <DrawerHeader />
      </Box>
      <div className='main_container'>
        <Box className='db_colors_Header'>
          <Box className='db_colors_Header left_alingment_db_color_boxes'>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ marginRight: '12px' }}>
                <Typography sx={{ marginTop: '20px' }}>Search Material</Typography>{' '}
              </Box>
              <Autocomplete
                disablePortal
                id='combo-box-demo'
                options={list}
                getOptionLabel={(item) => `${item.sku_name}-${item.sku}`}
                filterOptions={(options, state) => {
                  const inputValue = state.inputValue.toLowerCase();
                  return options.filter((option) => option.sku.toLowerCase().includes(inputValue) || option.sku_name.toLowerCase().includes(inputValue));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Search Material'
                    onSelect={handleInput}
                    sx={{
                      width: 300,
                      margin: '10px auto',
                    }}
                  />
                )}
              />
              {searchstring === '' ? (
                ''
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '20px',
                  }}
                >
                  {filteredList.map((item) => (
                    <Paper key={(item.sku, item.sku_name)}>
                      <Typography>
                        <strong>SKU:</strong> {item.sku} - <strong>Material Name:</strong> {item.sku_name}
                      </Typography>
                    </Paper>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box className='db_search_colors_main'>
          <Box
            style={{
              border: '1px solid black',
              padding: '10px',
              height: '385px',
              width: '460px',
            }}
          >
            <Typography sx={{ textAlign: 'center' }}>Select Colors</Typography>
            <hr />
            <Autocomplete
              disablePortal
              id='combo-box-demo'
              options={listColor.map((item) => item.material_color_code)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Search Color'
                  onSelect={handleInputColor}
                  sx={{
                    width: 350,
                    margin: '10px auto',
                  }}
                />
              )}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '75%',
              }}
              p={2}
            >
              <Typography variant='h6'>Filtered Items</Typography>
              <Divider />
              <Stack
                sx={{
                  overflow: 'auto',
                  maxHeight: 200,
                  width: '335px',
                }}
              >
                {filteredColorList.map((item) => (
                  <Paper
                    key={item.id}
                    sx={{
                      textAlign: 'left',
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox checked={selectedItems.includes(item)} onChange={() => handleCheckboxChange(item)} />}
                      label={
                        <Typography>
                          <strong></strong> {item.material_color_code} - <strong>{item.color_code} </strong>({item.color_name})
                        </Typography>
                      }
                    />
                  </Paper>
                ))}
              </Stack>
            </Box>
          </Box>
          <Box className='selected_Colors' p={2}>
            <Typography sx={{ textAlign: 'center' }}>Selected Colors</Typography>
            <Divider />
            <Box
              sx={{
                overflow: 'auto',
                maxHeight: 200,
              }}
            >
              {selectedItems.map((item) => {
                return <Typography sx={{ marginTop: '5px' }}>{item.material_color_code}</Typography>;
              })}
            </Box>
          </Box>
        </Box>
        <Box className='db_search_colors_main'>
          <Box
            sx={{
              border: '1px solid black',
              width: '716px',
              height: '70px',
              marginTop: '30px',
              display: 'flex', // Make the inner content a flex container
              alignItems: 'center', // Align the content vertically
            }}
          >
            <Box
              sx={{
                flexGrow: 1, // Grow to fill available space
                marginLeft: '20px',
                marginTop: '10px',
                textAlign: 'start',
                whiteSpace: 'wrap',
                wordWrap: 'break-word',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {selectedItemsString}
            </Box>
          </Box>
        </Box>
        <Box className='left_alingment_db_color_boxes copy_generate_sql_buttons'>
          {selectedItems.length !== 0 ? (
            <Box className=''>
              <Button variant='contained' onClick={handleCopyToClipboard} sx={{ marginTop: 2, width: 'auto ' }}>
                {isCopied ? 'Copied!' : 'Copy to Clipboard'}
              </Button>
            </Box>
          ) : (
            <Box className=''>
              <Button variant='contained' disabled sx={{ marginTop: 2, width: 'auto ' }}>
                {isCopied ? 'Copied!' : 'Copy to Clipboard'}
              </Button>
            </Box>
          )}
          {searchstring && selectedMaterial ? (
            <Box className='Generate_SQL_Query'>
              <div>
                <Button variant='contained' onClick={handleGenerateSqlQuery} sx={{ marginTop: 2, width: 'auto' }}>
                  Generate SQL Query
                </Button>
              </div>
            </Box>
          ) : (
            <Box className='Generate_SQL_Query'>
              <div>
                <Button variant='contained' disabled sx={{ marginTop: 2, width: 'auto' }}>
                  Generate SQL Query
                </Button>
              </div>
            </Box>
          )}
        </Box>

        <Box className='generated_sql_querry left_alingment_db_color_boxes'>
          <strong>Generated SQL Query:</strong>
        </Box>
        <Box className='db_search_colors_main'>
          <Box
            sx={{
              border: '1px solid black',
              width: '716px',
              height: '70px',
              marginTop: '10px',
              display: 'flex', // Make the inner content a flex container
              alignItems: 'center', // Align the content vertically
            }}
          >
            <Box
              sx={{
                flexGrow: 1, // Grow to fill available space
                marginLeft: '20px',
                marginTop: '10px',
                textAlign: 'start',
                whiteSpace: 'wrap',
                wordWrap: 'break-word',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {sqlQuery}
            </Box>
            <IconButton
              onClick={copyToClipboard}
              sx={{
                marginRight: '20px', // Add space to separate the icon from text
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Box>
        </Box>
        {sqlQuery.length !== 0 ? (
          <Box className='generated_sql_querry copy_generate_sql_buttons'>
            <Button variant='contained' onClick={copyToClipboard} sx={{ marginTop: 2, width: 'auto ' }}>
              {isSQLCopied ? 'Copied!' : 'Copy to Clipboard'}
            </Button>
          </Box>
        ) : (
          <Box className='generated_sql_querry copy_generate_sql_buttons'>
            <Button variant='contained' disabled sx={{ marginTop: 2, width: 'auto ' }}>
              {isSQLCopied ? 'Copied!' : 'Copy to Clipboard'}
            </Button>
          </Box>
        )}
      </div>
    </div>
  );
}
