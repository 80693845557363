/** @format */

import React, { forwardRef, useContext, useState, useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TableCell from '@mui/material/TableCell';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import CONFIG from '../../config/config.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DeleteWindowIcon from '../../assets/icons/primary/window-delete.svg';
import DeleteWindowHovIcon from '../../assets/icons/primary/WithHovIcon/window-delete-hov.svg';
import ReorderWindowIcon from '../../assets/icons/primary/window-move.svg';
import ReorderWindowHovIcon from '../../assets/icons/primary/WithHovIcon/move-hov.svg';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import 'moment-timezone';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import EditWindowSetName from './EditWindowSetName';
import { SnackbarContext } from '../../App';
import fracty from 'fracty';
import AddNoteDrawer from '../Customer/AddNoteDrawer';
import NotesDrawer from '../Proposals/NotesDrawer';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import NoteMiscIcon from '../../assets/icons/misc/misc-note.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';
import DrawerConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import { TablePagination } from '@mui/material';

const TurquoiseTooltipforNotes = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#faf3dd',
    color: '#000000',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 300,
    maxHeight: 300,
    textAlign: 'left',
    align: 'left',
    border: '1px solid #000000',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#f2f3f2',
      border: '1px solid #000000',
    },
    top: '100%', // Position the arrow at the bottom of the tooltip box
    right: '100', // Position the arrow at the right side of the tooltip box
  },
}));

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  paper: {
    width: '90%',
    background: 'rgba(69, 157, 166, 70%)  !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

const theme1 = createTheme({
  palette: {
    border: {
      main: '1px solid #000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#FAC41E',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "green", // date picker textfield style
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#008593 !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'white', // disable date
            color: '#E9E9E9',
          },
        },
        dayWithMargin: {
          backgroundColor: 'white', // not selected date
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "blue" //calender icon & month change arrow icon style
        },
      },
    },
  },
});

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const EditWindowSet = (props) => {
  const contextvalue = useContext(SnackbarContext);

  const outerTheme = createTheme({
    palette: {
      primary: {
        main: '#333333',
      },
    },
  });

  const classes = useStyles();

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);
  const navigate = useNavigate();
  const params = useParams();

  const location = useLocation();

  let locationState = [];

  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    locationState = location.state;
  }

  let coveringdetails = locationState.coveringdetails;

  const [WindowSetData, setWindowSetData] = useState([]);

  const [WindowSetName, setWindowSetName] = useState('');

  const [windowsetdata, setWindowsetdata] = useState([]);

  const [customerID, setCustomerID] = useState(0);
  const [addressID, setAddressID] = useState(0);

  const [senddatatonewWindow, setSendDatatoNewWindow] = useState('');
  const [open, setOpen] = useState(false);
  const [deleteWS, setDeleteWS] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [ConfirmationMsg, setConfirmationMsg] = useState(false);
  const [show, setShow] = useState(false);
  const vertical = 'top';
  const horizontal = 'center';
  const [checkedrowdata, setCheckedRowData] = useState([]);
  const [showSA, setShowSA] = useState(true);
  const [isWSDrawerOpen, setIsWSDrawerOpen] = useState(false);
  const [isAddNoteDrawerOpen, setIsAddNoteDrawerOpen] = useState(false);
  const [alertmessage, setAlertMessage] = useState('');
  const [classtype, setClassType] = useState('');
  const [addWindowSuccess, setAddWindowSuccess] = useState(false);
  const [appliedclass, setAppliedClass] = useState('table-contentwsidwithcheckbox_2 sticky-columnws-1');
  const [indexCheckboxClass, setIndexcheckboxclass] = useState('table-headerwsidwithcheckbox sticky-columnws-1');
  const [proposalCount, setProposalCount] = useState();
  const [windowCount, setWindowCount] = useState();
  const [windowSetSuccessMsg, setWindowSetSuccessMsg] = useState();
  const [defaultOptionValues, setdefaultOptionValues] = useState();
  const [defaultValuesStatus, setdefaultValuesStatus] = useState(false);

  // Error Handling
  const [WindowSetApiErr, setWindowSetApiErr] = useState(false);
  const [WindowSetApiErrMsg, setWindowSetApiErrMsg] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };
  const handleHoverFun2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeaveFun2 = () => {
    setIsHovered2(false);
  };

  useEffect(() => {
    {
      window.scrollTo(0, 0);
    }

    if (locationState.addition_sku_data !== undefined) {
      setExistingSku(locationState.addition_sku_data.ExistingSku);
    }
    if (locationState.addition_sku_data !== undefined) {
      setBuildSku(locationState.addition_sku_data.BuildSku);
    }
    if (locationState.addition_sku_data !== undefined) {
      setMountSku(locationState.addition_sku_data.MountSku);
    }
    if (locationState.addition_sku_data !== undefined) {
      setLadderSku(locationState.addition_sku_data.LadderSku);
    }
    if (locationState.addition_sku_data !== undefined) {
      setWindowDepthSku(locationState.addition_sku_data.WindowDepthSku);
    }
    if (locationState.addition_sku_data !== undefined) {
      setHeadrailSku(locationState.addition_sku_data.HeadrailSku);
    }
    if (locationState.addition_sku_data !== undefined) {
      setObstructionsSku(locationState.addition_sku_data.ObstructionsSku);
    }
    getData();
  }, []);

  const getData = async () => {
    await getVerified();
    await ChangeValue();
    await getWindowSet();
    await getNote();
  };

  const ChangeValue = () => {
    setTimeout(function () {
      contextvalue.setOpensnackbar(false);
    }, 5000);
  };
  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const deleteWindowSet = async () => {
    await fetch(`${CONFIG.API_URL}/wm/WindowSet`, {
      method: 'DELETE',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        window_set_id: windowSetID,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setWindowSetSuccessMsg(data.message);
          // WindowSet deleted Successfully!
          if (data.message === 'WindowSet deleted Successfully!') {
            setDeleteWS(true);
            setTimeout(() => {
              navigate(`/tbm/ps/customerdetails`, {
                state: {
                  Custdata: { custid: customerID, Addressid: addressID },
                  WindowSetSuccessMsg: data.message,
                },
              });
            }, 3000);
          }
        }
      });
  };

  let isItemSelected;
  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='table-headerwsname sticky-columnws-2'>
            <span>#</span>
            <span style={{ marginLeft: '30px' }}>Window Name</span>
          </TableCell>
          <TableCell className='table-headersw3'>Size</TableCell>
          <TableCell className='table-headersw3'>Bld</TableCell>
          <TableCell className='table-headersw3'>Surface</TableCell>
          <TableCell className='table-headersw3'>Ladder</TableCell>
          <TableCell className='table-headersw3 ellipsis2'>Ex. Cover</TableCell>
          <TableCell className='table-header4'>Note</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  useEffect(() => {
    if (locationState && locationState.selectedwindows && locationState.selectedwindows.length > 0) {
      setAppliedClass('table-contentwsidwithcheckbox_2 sticky-columnws-1');
      setIndexcheckboxclass('table-headerwsidwithcheckbox sticky-columnws-1');
      locationState.selectedwindows.forEach((element) => {
        setSelected(locationState.selectedwindows);
        setCheckedRowData(locationState.selectedwindows);

        const selectedIndex = locationState.totalwindows.indexOf(element);

        let newSelected = [];

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, element);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
      });
    } else {
      setAppliedClass('table-contentwsid2 sticky-columnws-1');
      setIndexcheckboxclass('table-headerwsid sticky-columnws-1');
    }
  }, [customerID, WindowSetName]);

  const WindowSetNameUpdateDrawer = (name, drawerstatus, message, status) => {
    setIsWSDrawerOpen(drawerstatus);
    setAlertMessage(message);
    if (message === 'Window set name is updated successfully!') {
      setWindowSetName(name);
      setOpen(true);
      setClassType('Success2 mainAlert');
    } else if (message === 'Window set name is already exists.') {
      setOpen(true);
      setClassType('Error mainAlert');
    } else {
      setOpen(false);
      setClassType('');
    }
  };

  const DeleteWindowAlertClose = () => {
    setConfirmationMsg(false);
  };

  const AddNoteDrawerFun = (drawerstatus) => {
    setIsAddNoteDrawerOpen(drawerstatus);
  };

  const [windowSetID, setWindowSetID] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState('');
  const [cityName, setCityName] = useState('');

  //Get WindowSet data through WindowSet API
  const getWindowSet = async () => {
    await fetch(`${CONFIG.API_URL}/wm/window-set/${params.id}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setProposalCount(data.window_set.proposal_count);
          setWindowCount(data.window_set.window_count);

          // Redirect to Login page if token is not valid
          if (data.error.message === 'Token is not valid') {
            navigate(`/login`, {
              state: { error: 'Unauthorized Please Login' },
            });
          }

          // Pass the data if token is valid
          else {
            setWindowSetData(data.window);
            setWindowsetdata(data.window_set);

            setWindowSetName(data.window_set.window_set_name);
            setCustomerID(data.window_set.customer_id);
            setAddressID(data.window_set.address_id);

            setSendDatatoNewWindow(windowsetdata);
            setWindowSetID(data.window_set.window_set_id);
            setLastName(data.window_set.last_name);
            setCityName(data.window_set.city);
            setFirstName(data.window_set.first_name);
            if (data.window_set.set_default_value === true) {
              setdefaultValuesStatus(true);
              setdefaultOptionValues(data.default_options);
            } else {
              setdefaultValuesStatus(false);
              setdefaultOptionValues();
            }
          }
        } else {
          setWindowSetApiErrMsg(data.error_message);
          setWindowSetApiErr(true);
        }
      });
  };

  const rows = WindowSetData;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
  };

  const handleSelectAllClick = (event) => {
    if (event === true) {
      const newSelecteds = rows.map((n) => n);

      setSelected(newSelecteds);
      setCheckedRowData(newSelecteds);
      newSelecteds.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = true;
        }
      });
      return;
    } else {
      const newSelecteds = rows.map((n) => n);

      newSelecteds.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = false;
        }
      });
      setCheckedRowData([]);
      setSelected([]);
    }
  };

  const handleClick = (event, row) => {
    if (event.target.checked === true) {
      setShowSA(true);
      const selectedIndex = selected.indexOf(row);

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      newSelected.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = true;
        }
      });

      for (var i = 0; i < newSelected.length; i++) {
        if (WindowSetData[i].selected === newSelected[i].selected) {
        }
      }

      if (WindowSetData.length === newSelected.length) {
        setShowSA(false);
      }
      setCheckedRowData(newSelected);
      setSelected(newSelected);
    } else {
      const selectedIndex = selected.indexOf(row);
      const selectedActualIndex = WindowSetData.indexOf(row);

      WindowSetData[selectedActualIndex].selected = false;

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      if (WindowSetData.length > newSelected.length) {
        setShowSA(true);
      }

      setCheckedRowData(newSelected);
      setSelected(newSelected);
    }
  };

  const isSelected = (row) => selected.indexOf(row) !== -1;

  const DialogueClose = () => {
    setDeleteWS(false);
  };

  const navigateCD = () => {
    navigate(`/tbm/ps/customerdetails`, {
      state: {
        Custdata: { custid: customerID, Addressid: addressID },
      },
    });
  };

  const windowSetCreatedFun = () => {
    if (contextvalue.opensnackbar === true) {
      setAddWindowSuccess(true);
      setSuccessOpen(true);
    }
  };

  const [ExistingSku, setExistingSku] = useState();
  const [BuildSku, setBuildSku] = useState();
  const [MountSku, setMountSku] = useState();
  const [LadderSku, setLadderSku] = useState();
  const [WindowDepthSku, setWindowDepthSku] = useState('S500470');
  const [HeadrailSku, setHeadrailSku] = useState('S500190');
  const [ObstructionsSku, setObstructionsSku] = useState('S500540');

  const additional_sku_data = {
    ExistingSku: ExistingSku,
    BuildSku: BuildSku,
    MountSku: MountSku,
    LadderSku: LadderSku,
    WindowDepthSku: WindowDepthSku,
    HeadrailSku: HeadrailSku,
    ObstructionsSku: ObstructionsSku,
  };

  const [EditNotes, setEditNote] = useState('');
  const [notes, setNotes] = useState(false);

  const [notesData, setNotesData] = useState('');
  const [noteTypeId, setNoteTypeId] = useState('');

  const [notesContent, setnotesContent] = useState([]);
  const [noteId, setNoteId] = useState([]);
  const [notesContentForWindow, setNotesContentForWindow] = useState([]);
  const [eyeButtonMsg, setEyeButtonMsg] = useState(false);

  const [notesDataDescription, setNotesDataDescription] = useState([]);

  const NotesDrawerData = (notesDrawerStatus, notesMsgAlert, notesMsg, eyeButtonMsg, referenceId, noteId, noteContent) => {
    setNotes(notesDrawerStatus);
    setEyeButtonMsg(eyeButtonMsg);

    if (notesMsg === 'Note added successfully!' || notesMsg === 'Note updated successfully!') {
      getNote();
    }
    setNoteId(noteId);
    setNotesContentForWindow(noteContent);
    setEyeButtonMsg(eyeButtonMsg);
  };

  const getNote = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    await fetch(`${CONFIG.API_URL}/cm/Notes/${windowSetID}`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setnotesContent(data.Notes);
        }
      });
  };

  const NotesCustomerDetails = {
    firstName: firstName,
    lastname: lastName,
    customerId: customerID,
    proposalId: '',
    windowSetId: windowSetID,
    WindowSetName: WindowSetName,
  };

  const [valueValue, setValueValue] = useState();
  const EditNotesCustomerDetails = {
    firstName: firstName,
    lastname: lastName,
    customerId: customerID,
    proposalId: '',
    windowSetId: '',
    WindowSetName: WindowSetName,
    notesContent: notesData,
    noteId: noteId,
    noteTypeId: noteTypeId,
    eyeButtonMsg: eyeButtonMsg,
    notesDataDescription: notesDataDescription,
    valueValue: valueValue,
  };

  const LongText = ({ content }) => {
    const [limit, setLimit] = useState(CONFIG.GlobalTruncateCharacterLimit);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    if (content === undefined || (windowsetdata.last_name === undefined && windowsetdata.city === undefined)) {
      // Data is undefined or both last_name and city are undefined, return empty
      return null;
    } else {
      let result = '';

      if (windowsetdata.last_name !== undefined) {
        result += windowsetdata.last_name;
      }

      if (windowsetdata.city !== undefined) {
        if (result.length > 0) {
          result += ' | ';
        }
        result += windowsetdata.city;
      }

      if (windowWidth >= 280 && windowWidth <= 767 && content.length > 15) {
        const truncatedContent = content.substring(0, 15) + '...';
        return <span>{truncatedContent}</span>;
      }

      if (windowWidth >= 768) {
        if (content.length <= limit) {
          // There is nothing more to show
          return <span>{content}</span>;
        }

        let truncatedContent = content.substring(0, limit);

        // Find the last space within the truncated content
        const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

        // Adjust the truncated content if a space was found
        if (lastSpaceIndex !== -1) {
          truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
        }

        // Add the ellipsis to the truncated content
        const toShow = truncatedContent + '...';

        return <span>{toShow}</span>;
      }

      if (content.length <= limit) {
        // There is nothing more to show
        return <span>{content}</span>;
      }

      let truncatedContent = content.substring(0, limit);

      // Find the last space within the truncated content
      const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

      // Adjust the truncated content if a space was found
      if (lastSpaceIndex !== -1) {
        truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
      }

      // Add the ellipsis to the truncated content
      const toShow = truncatedContent + '...';

      return <span>{toShow}</span>;
    }
  };

  const navigateReorderPage = () => {
    navigate(`/tbm/ps/reorderwindowset/${params.id}`, {
      state: {
        coveringdetails: coveringdetails,
        customerdata: windowsetdata,
        totalwindows: WindowSetData,
      },
    });
  };

  const navigateDeleteWindow = () => {
    navigate(`/tbm/ps/deletewindow/${params.id}`, {
      state: {
        coveringdetails: coveringdetails,
        customerdata: windowsetdata,
        totalwindows: WindowSetData,
      },
    });
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  const [isImageHovered3, setIsImageHovered3] = useState(false);
  const handleHover3 = () => {
    setIsImageHovered3(!isImageHovered3);
  };

  const [editingNote, setEditingNote] = useState(false);
  const [noteDrawerDetails, setNoteDrawerDetails] = useState({});
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);

  const closeNotesDrawer = (alert) => {
    setNotesDrawerOpen(false);
    setEditingNote(false);
    setNoteDrawerDetails({});

    if (alert.message === 'Note added successfully!' || alert.message === 'Note updated successfully!') {
      // TODO: fetch notes again
    }
  };

  const openNotesDrawer = (note) => {
    setEditingNote(!!note);
    setNoteDrawerDetails({
      firstName,
      lastName,
      referenceId: windowSetID,
      noteId: note ? note.id : null,
    });
    setNotesDrawerOpen(true);
  };

  return (
    <div className='padding_bottom_class'>
      <ThemeProvider theme={theme1}>
        <Drawer
          classes={{ paper: classes.paper }}
          PaperProps={{
            sx: { width: '90%' },
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          anchor='right'
          open={notesDrawerOpen}
          onClose={() => setNotesDrawerOpen(false)}
          className='drawer_Header_page'
        >
          <NotesDrawer closeDrawer={closeNotesDrawer} editing={editingNote} type='windowSet' details={noteDrawerDetails} />
        </Drawer>
      </ThemeProvider>
      <ThemeProvider theme={outerTheme}>
        <div align='center'>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={WindowSetApiErr} anchorOrigin={{ vertical, horizontal }} onClose={() => setWindowSetApiErr(false)}>
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setWindowSetApiErr(false);
                    }}
                    alt='CANCELICONWSE'
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img src={RedExclamationIcon} alt='CONFIRMICONWSE' className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{WindowSetApiErrMsg}</div>
                </div>
              </Alert>
            </Snackbar>

            <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setOpen(false)}>
              {classtype === 'Success2 mainAlert' ? (
                <Alert className={classtype} variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img alt='CANCELICONUWSN' onClick={handleSuccessClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img alt='CONFIRMICONUWSN' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />
                    <div align='left' className='alertLeftPadding alertSpacing alertSpacing1'>
                      {alertmessage}
                    </div>
                  </div>
                </Alert>
              ) : (
                <Alert className={classtype} variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img alt='CANCELICONREDUWSN' onClick={handleSuccessClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img alt='CONFIRMICONREDUWSN' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                    <div align='left' className='alertLeftPadding alertSpacing alertSpacing1'>
                      {alertmessage}
                    </div>
                  </div>
                </Alert>
              )}
            </Snackbar>
          </Stack>

          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={successOpen} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setSuccessOpen(false)}>
              {classtype === 'Success2 mainAlert' ? (
                ''
              ) : (
                <Alert className='Success2 mainAlert' variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img alt='CANCELICONWSC' onClick={handleClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img alt='CONFIRMICONWSC' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />
                    <div align='left' className='alertLeftPadding alertSpacing alertSpacing1'>
                      Window Set created successfully!
                    </div>
                  </div>
                </Alert>
              )}
            </Snackbar>
          </Stack>

          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={ConfirmationMsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000}>
              {classtype === 'Success mainAlert' ? (
                ''
              ) : (
                <Alert className='Success mainAlert' variant='filled' icon={false}>
                  <div className='alertCancel' align='right'>
                    <img alt='CANCELICONWSD' onClick={DeleteWindowAlertClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>

                  <div className='alertMain'>
                    <div align='center' style={{ paddingTop: 30 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'Center',
                        }}
                      >
                        <Box className='alertCantinueButton'>
                          <img alt='CONFIRMICONWSD' src={DrawerAlertIcon} className='primary_icons_dimension' />
                        </Box>
                      </Box>

                      <div style={{ paddingTop: 10 }}>Delete Window Set</div>
                    </div>

                    <div
                      align='left'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px',
                      }}
                    >
                      <Box
                        onClick={() => {
                          setConfirmationMsg(false);
                          deleteWindowSet();
                        }}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'Center',
                          cursor: 'pointer',
                          marginBottom: '10px',
                          marginLeft: '6px',
                        }}
                      >
                        <Box
                          onClick={() => {
                            setConfirmationMsg(false);
                            deleteWindowSet();
                          }}
                          className='alertCantinueButton'
                        >
                          <img alt='CONTINUE' src={DrawerConinueIcon} className='primary_icons_dimension' />
                        </Box>
                        <Typography
                          className='globalTextForDrawer'
                          onClick={() => {
                            setConfirmationMsg(false);
                            deleteWindowSet();
                          }}
                          sx={{ marginLeft: '12px' }}
                        >
                          CONTINUE
                        </Typography>
                      </Box>

                      <Box
                        onClick={DeleteWindowAlertClose}
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'Center',
                          cursor: 'pointer',
                        }}
                      >
                        <Box className='globalIconMainBox'>
                          <Box className='iconSecondaryButtonGlobalForDrawer'>
                            <img alt='CANCEL1' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                          </Box>
                          <Typography className='globalTextForDrawer'>CANCEL</Typography>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </Alert>
              )}
            </Snackbar>
          </Stack>

          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={deleteWS} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setDeleteWS(false)}>
              {classtype === 'Success2 mainAlert' ? (
                ''
              ) : (
                <Alert className='Success2 mainAlert' variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img alt='CANCELICONDA' onClick={handleClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img alt='CONFIRMICONDA' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />

                    <div align='center' className='alertLeftPadding alertSpacing alertSpacing1'>
                      {windowSetSuccessMsg}
                    </div>
                  </div>
                </Alert>
              )}
            </Snackbar>
          </Stack>

          <Drawer
            PaperProps={{
              sx: { width: '90%' },
            }}
            BackdropProps={{
              classes: {
                root: classes.backDrop,
              },
            }}
            classes={{ paper: classes.paper }}
            anchor='right'
            open={isAddNoteDrawerOpen}
            onClose={() => setIsAddNoteDrawerOpen(true)}
            sx={{ zIndex: '100000' }}
          >
            <AddNoteDrawer func={AddNoteDrawerFun} WindowSetName={WindowSetName} />
          </Drawer>

          <div className='heading-box-1'>
            <h2 className='clickable_header all_pages_h2_header ' onClick={() => setIsWSDrawerOpen(true)}>
              <LongText content={WindowSetName} />
            </h2>

            <h3
              className='clickable_header_1 all_pages_h2_header center button_top_space common_line_height_css'
              style={{
                textTransform: 'capitalize',
                textAlign: 'center !important',
              }}
              onClick={() => {
                navigate(`/tbm/ps/customerdetails`, {
                  state: {
                    Custdata: { custid: customerID, Addressid: addressID },
                  },
                });
              }}
            >
              <LongText content={`${windowsetdata.last_name} | ${windowsetdata.city}`} />
            </h3>

            <Box sx={{ position: 'relative', float: 'right', top: '-55px' }}>
              <img
                alt='NOTESICON'
                src={NoteMiscIcon}
                className='misc_icons_dimensions'
                onClick={() => {
                  setEditNote('');
                  setNotes(true);
                }}
              />
            </Box>
          </div>
        </div>
        {notesContent.map((note, index) => (
          <Box key={note.id} sx={{ display: 'flex' }}>
            {note.visible ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : null}{' '}
            <Box>
              <Typography className='' onClick={() => openNotesDrawer(note)}>
                {' '}
                <span
                  style={{
                    color: '#008593',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }}
                >
                  Note #{index + 1} :
                </span>{' '}
                {note.content}
              </Typography>
            </Box>
          </Box>
        ))}
        <Dialog open={show} onClose={DialogueClose}>
          <DialogTitle id='alert-dialog-title'>
            <div align='center' style={{ paddingLeft: '1%', paddingRight: '1%' }}>
              <span style={{ color: 'red' }}> {'Are you sure you want to delete '} </span>
              <br />
              {windowsetdata.window_set_name} {'?'}
            </div>
          </DialogTitle>

          <DialogActions>
            <div style={{ display: 'flex', width: '100%' }}>
              <Button
                className='small_buttons'
                onClick={() => {
                  navigateCD();
                }}
              >
                Yes
              </Button>{' '}
            </div>
            <Button className='small_buttons' onClick={DialogueClose}>
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Drawer
          PaperProps={{
            sx: { width: '90%' },
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          classes={{ paper: classes.paper }}
          anchor='right'
          open={isWSDrawerOpen}
          onClose={() => setIsWSDrawerOpen(true)}
          sx={{ zIndex: '100000' }}
        >
          <EditWindowSetName windowdetails={windowsetdata} func={WindowSetNameUpdateDrawer} />
        </Drawer>

        <hr className='button_bottom_space button_top_space' />
        <Box className='table-box-1'>
          <TableContainer sx={{ borderBottom: '1px solid #D6D6D6' }} className='table_padding_bottom'>
            <Table className='windowset-table-dimension' aria-labelledby='tableTitle' size='small'>
              <EnhancedTableHead numSelected={selected.length} onSelectAllClick={handleSelectAllClick} rowCount={rows.length} />
              <TableBody>
                {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((window) => {
                  isItemSelected = isSelected(window);
                  return (
                    <TableRow hover className='rowhover' onClick={(event) => handleClick(event, window)} role='checkbox' aria-checked={isItemSelected} tabIndex={-1} key={window.window_id}>
                      <TableCell className={appliedclass} align='left'>
                        {' '}
                        <span className='ellipsis2'>
                          <span
                            style={{
                              paddingRight: `${rows.indexOf(window) + 1 > 9 ? '20px' : '28px'}`,
                            }}
                          >
                            {rows.indexOf(window) + 1}
                          </span>
                          <Link
                            className='link ellipsis2'
                            to='/tbm/ps/window'
                            state={{
                              data: windowsetdata,
                              row_id: window.window_id,
                              customer_id: windowsetdata.customer_id,
                              additional_sku_data: additional_sku_data,
                              windowSetID: windowSetID,
                            }}
                          >
                            <LongText content={window.window_name} />
                          </Link>
                        </span>
                      </TableCell>
                      <TableCell className='table-content ellipsis2' align='left'>
                        {fracty(window.window_width)}" x {fracty(window.window_height)}"{' '}
                        <span className={window.measurement_type.replace(/ /g, '')}>
                          {window.measurement_type === 'Quick'
                            ? '(' + 'QK' + ')'
                            : window.measurement_type === 'Shutters'
                            ? '(' + 'SH' + ')'
                            : window.measurement_type === 'Blinds'
                            ? '(' + 'BL' + ')'
                            : '(' +
                              window.measurement_type
                                .split(' ')
                                .map((word) => word[0])
                                .join('') +
                              ')'}
                        </span>
                      </TableCell>
                      <TableCell className='table-content' align='left'>
                        {window.build_type}
                      </TableCell>
                      <TableCell className='table-content' align='left'>
                        {window.mount_surface}
                      </TableCell>
                      <TableCell className='table-content' align='left'>
                        {!isNaN(window.ladder) ? window.ladder + "'" : window.ladder}
                      </TableCell>
                      <TableCell className='table-content' align='left'>
                        {window.existing_covering}
                      </TableCell>
                      <TableCell className='table-content' align='left'>
                        {window.notes.length > 0 && (
                          <TurquoiseTooltipforNotes
                            title={
                              <>
                                <Typography>
                                  {window.notes.map((note, index) => (
                                    <div key={note.id}>
                                      {index > 0 && <br />}
                                      <b>Note:</b> {note.content}
                                    </div>
                                  ))}
                                </Typography>
                              </>
                            }
                            placement='bottom-end'
                            TransitionComponent={Zoom}
                            arrow
                          >
                            <Link
                              className='link'
                              to='/tbm/ps/window'
                              state={{
                                data: windowsetdata,
                                row_id: window.window_id,
                                customer_id: windowsetdata.customer_id,
                                additional_sku_data: additional_sku_data,
                              }}
                            >
                              {window.notes_count === '0' ? <></> : <span style={{ paddingLeft: '5px' }}>{window.notes_count}</span>}
                            </Link>
                          </TurquoiseTooltipforNotes>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{
              mx: 0,
              px: 0,
              textAlign: 'center',
            }}
            rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]}
            component='div'
            count={WindowSetData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPageprp) => {
              setPage(newPageprp);
            }}
            onRowsPerPageChange={({ target }) => {
              setRowsPerPage(parseInt(target.value, 10));
              setPage(0);
            }}
          />
        </Box>

        {WindowSetData.length ? (
          <Box display='flex' alignItems='center' justifyContent='center' className='add_Window_Button_margin_Bottom'>
            <span
              className='button_span button_top_space'
              onClick={() => {
                navigateDeleteWindow();
              }}
              onMouseEnter={handleHoverFun}
              onMouseLeave={handleMouseLeaveFun}
            >
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                {isHovered ? <img alt='REMOVEWINDOWSH' className='primary_icons_dimension_1' src={DeleteWindowHovIcon} /> : <img alt='REMOVEWINDOWS' className='primary_icons_dimension_1' src={DeleteWindowIcon} />}
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                  REMOVE WINDOWS
                </Typography>
              </Box>
            </span>
          </Box>
        ) : (
          <Box display='flex' alignItems='center' justifyContent='center'>
            <span className='button_span button_top_space button_bottom_space'>
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                <img alt='REMOVEWINDOWSD' src={DeleteWindowIcon} className='primary_icons_dimension_disable' />

                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                  REMOVE WINDOWS
                </Typography>
              </Box>
            </span>
          </Box>
        )}

        {WindowSetData.length ? (
          <Box display='flex' alignItems='center' justifyContent='center' className='add_Window_Button_margin_Bottom'>
            <span className='button_span button_top_space button_bottom_space' onClick={navigateReorderPage} onMouseEnter={handleHoverFun2} onMouseLeave={handleMouseLeaveFun2}>
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                {isHovered2 ? <img alt='REORDERWINDOWSH' className='primary_icons_dimension_1' src={ReorderWindowHovIcon} /> : <img alt='REORDERWINDOWS' className='primary_icons_dimension_1' src={ReorderWindowIcon} />}

                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover2} onMouseLeave={handleHover2}>
                  REORDER WINDOWS
                </Typography>
              </Box>
            </span>
          </Box>
        ) : (
          <Box display='flex' alignItems='center' justifyContent='center'>
            <span className='button_span  button_bottom_space'>
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                <img alt='REORDERWINDOWSD' src={ReorderWindowIcon} className='primary_icons_dimension_disable' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                  REORDER WINDOWS
                </Typography>
              </Box>
            </span>
          </Box>
        )}
        <hr />

        <Box sx={{ marginLeft: '15px' }}>
          <Box display='flex' alignItems='center' justifyContent='center' sx={{ marginLeft: '0px' }} className='add_Window_Button_margin_Bottom'>
            <span className='gray_button_span button_top_space' style={{ paddingBottom: '30px' }}>
              <Box
                onClick={() => {
                  navigate(`/tbm/ps/windowset/${params.id}`, {
                    state: {
                      coveringdetails: coveringdetails,
                      customerdata: windowsetdata,
                      totalwindows: WindowSetData,
                    },
                  });
                }}
                className='globalIconMainBox'
              >
                <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered3 ? 'hovered' : ''}`} alt='cancel' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover3} onMouseLeave={handleHover3}>
                  CANCEL
                </Typography>
              </Box>
            </span>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
};
