import React from "react";
import { Typography, Checkbox } from "@mui/material";
import { Box } from "@mui/system";
import fracty from "fracty";

function convertKeyToLabel(value) {
  const words = value.split(/(?=[A-Z])/);
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const WindowDetails = ({ window, checked, isDisabled, onChange }) => (
  <Box className="sales_order_product_details">
    <Typography className="convert_sales_order_window_list">
      Measurement Type :{" "}
      <span className="font_weight_bold">{window.measurement_type}</span>
    </Typography>
    <Typography className="convert_sales_order_window_list">
      Width X Height :{" "}
      <span className="font_weight_bold">
        {fracty(window.sales_order_window_width)}" X{" "}
        {fracty(window.sales_order_window_height)}"{" "}
      </span>
    </Typography>
    <Typography className="convert_sales_order_window_list">
      Existing Coverings :{" "}
      <span className="font_weight_bold">{window.existing_covering}</span>
    </Typography>
    <Typography className="convert_sales_order_window_list">
      Build Type : <span className="font_weight_bold">{window.build_type}</span>
    </Typography>
    <Typography className="convert_sales_order_window_list">
      Mount Surface :{" "}
      <span className="font_weight_bold">{window.mount_surface}</span>
    </Typography>
    <Typography className="convert_sales_order_window_list">
      Ladder : <span className="font_weight_bold">{window.ladder}</span>
    </Typography>
    <Typography className="convert_sales_order_window_list">
      Covering :{" "}
      <span className="font_weight_bold">
        {window.material_code.replace(/,/g, ", ")}
      </span>
    </Typography>

    {Object.keys(window.measurement_type_specific_factor).map((key, index) =>
      key !== "AddNote" && window.measurement_type_specific_factor[key] !== "" ? (
        <Typography key={index} className="convert_sales_order_window_list">
          {convertKeyToLabel(key)} :{" "}
          <span className="font_weight_bold">
            {window.measurement_type_specific_factor[key]}
          </span>
        </Typography>
      ) : null)
    }

    {window.sales_order_specific_factor ? (Object.keys(window.sales_order_specific_factor).map((key, index) => (
      <Typography key={index} className="convert_sales_order_window_list">
        {convertKeyToLabel(key)} :{" "}
        <span className="font_weight_bold">
          {window.sales_order_specific_factor[key].name}
        </span>
      </Typography>
    ))): null}

    <Box sx={{ display: "flex", textAlign: "start" }}>
      <Checkbox
        disabled={isDisabled}
        checked={checked}
        onChange={onChange}
        sx={{ width: "5%" }}
      />
      <Typography
        className={isDisabled ? "globalTextDisabled" : null}
        sx={{
          marginLeft: "15px",
          marginTop: "10px",
          color: "#008593",
        }}
      >
        CONFIRM FOR SALES ORDER
      </Typography>
    </Box>
  </Box>
);

export default WindowDetails;
