/** @format */

import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import WindowProvider from './components/Shared/WindowProvider';
import ProductProvider from './components/Shared/ProductProvider';
import { WindowSet } from './components/WindowsManagement/WindowSet';
import CreateProposal from './components/Proposals/CreateProposal';
import { EditWindowSet } from './components/WindowsManagement/EditWindowSet';
import { ReOrderWindowSet } from './components/WindowsManagement/ReOrderWindowSet';
import { LoginPage } from './components/Login';
import Settings from './components/Settings';
import AddWindow from './components/WindowsManagement/AddWindow';
import CreateNewWindowSet from './components/WindowsManagement/CreateNewWindowSet';
import Window from './components/WindowsManagement/Window';
import CustomerInfo from './components/Customer/CustomerInfo';
import ProposalApplyCovering from './components/Proposals/ProposalApplyCovering';
import ProposalSelectWindow from './components/Proposals/ProposalSelectWindow';
import ProposalDetails from './components/Proposals/ProposalDetails';
import DuplicateProposalDetails from './components/Proposals/DuplicateProposalDetails';
import AddWindowSet from './components/WindowsManagement/AddWindowSet';
import EditProposalDetails from './components/Proposals/EditProposalDetails';
import DeleteProposalDetails from './components/Proposals/DeleteProposalDetails';
import BatchEditProposalDetails from './components/Proposals/BatchEditProposalDetails';
import BatchEditWindowCovering from './components/Proposals/BatchEditWindowCovering';
import EditWindowProposal from './components/Proposals/EditWindowProposal';
import ReOrderProposalDetails from './components/Proposals/ReOrderProposalDetails';
import { EditingProposalWindowSetToAddWindows } from './components/Proposals/EditingProposalWindowSetToAddWindows';
import EditingProposalSelectWindowToAddWindows from './components/Proposals/EditingProposalSelectWindowToAddWindows';
import SkuLookUp from './components/Proposals/SkuLookUp';
import PricingLookup from './components/Proposals/PricingLookup';
import ViewProposalPriceBasis from './components/Proposals/ViewProposalPricingBasis';
import LandingPage from './components/Customer/LandingPage';
import { NewCustomer } from './components/Customer/NewCustomer';
import Layout from './components/Shared/Layout';
import SystemInformation from './components/SystemInformation';
import CTWPage from './components/ConvertToWorkOrder/index';
import WCPPage from './components/ProposalForCustomer/index';
import CTSOPage from './components/ConvertToSalesOrder/ConvertToSalesOrderPdf/index';
//---------------------------Customer-search-----------------------------------------

import CustomerSearchExisting from './components/Customer/ExistingCustomer';
import Customer_Search from './components/Customer/CustomerSearch';
import Search_Customer_By_Details from './components/Customer/SearchCustomerByDetails';
import Search_result from './components/Customer/SearchResult';
import Create_new_customer from './components/Customer/CreateNewCustomer';
import Appoinments from './components/Appointments/Appoinments';
import ViewAllApointments from './components/Appointments/ViewAllApointments';
import EditCustomerDetails from './components/Customer/EditCustomerAddress';
import AddNewAddress from './components/Customer/AddNewAddress';
import AddNewEmail from './components/Customer/AddNewEmail';
import EditCustomerEmail from './components/Customer/EditCustomerEmail';
import EditCustomerPhoneNumber from './components/Customer/EditCustomerPhoneNumber';
import AddNewPhoneNumber from './components/Customer/AddNewPhoneNumber';
import EditCustomerFirstLastName from './components/Customer/EditCustomerFirstLastName';
import EditCustomerPhoneEmail from './components/Customer/EditCustomerPhoneEmail';
import SalesOrder from './components/ConvertToSalesOrder/SalesOrder';
import FinalizeSalesOrder from './components/ConvertToSalesOrder/FinalizeSalesOrder';
import Customer_Address from './components/Customer/CustomerAddress';
import SalesOrderAddingWindows from './components/ConvertToSalesOrder/SalesOrderAddingWindows';
import SalesOrderAddingandConfirmwindows from './components/ConvertToSalesOrder/SalesOrderAddingandConfirmwindows';
import EditWindowDetailsInSalesOrder from './components/ConvertToSalesOrder/EditWindowDetailsInSalesOrder';
import SalesOrderConfirm from './components/ConvertToSalesOrder/SalesOrderConfirm';
import PreviewProposal from './components/Proposals/PreviewProposal';
import JsonImporter from './components/dbtools/JsonImporter/JsonImporter';
import JsonConverter from './components/dbtools/JsonConverter/JsonConverter';
import DbtoolsList from './components/dbtools/DbtoolsList';
import DeleteWindow from './components/WindowsManagement/DeleteWindow';
import DelimitedColors from './components/dbtools/DelimitedColors/DelimitedColors';
import PageNotFound from './components/PageNotFound';
import SalesOrderMasterRecord from '../src/components/ConvertToSalesOrder/SalesOrderMasterRecord';
import SalesOrderDetails from '../src/components/ConvertToSalesOrder/SalesOrderDetails';
import WorkOrderProvider from './components/Shared/WorkOrderProvider';

export const SnackbarContext = createContext();

export default function App() {
  const theme = createTheme({
    shadows: 'none',
    typography: {
      fontFamily: `"Roboto"`,
      fontWeightLight: 200,
      fontWeightRegular: 300,
      fontWeightMedium: 400,
    },
  });

  const [opensnackbar, setOpensnackbar] = useState(false);
  const [successwindows, setSuccesswindows] = useState(false);
  const [failurewindows, setFailurewindows] = useState(false);
  const [hostname, setHostname] = useState(window.location.hostname);

  useEffect(() => {
    setHostname(window.location.hostname);
  }, []);

  const hideButtons = hostname === 'stg.theblindman.com';

  return (
    <ThemeProvider theme={theme}>
      <SnackbarContext.Provider
        value={{
          opensnackbar,
          setOpensnackbar,
          successwindows,
          setSuccesswindows,
          failurewindows,
          setFailurewindows,
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path='/' exact element={<Navigate replace to='/login' />} />
            <Route path='/login' element={<LoginPage />} />
            {!hideButtons ? (
              <>
                <Route path='/dbtools/jsonimporter' element={<JsonImporter />} />
                <Route path='/dbtools/jsonconverter' element={<JsonConverter />} />
                <Route path='/dbtools/delimitedcolors' element={<DelimitedColors />} />
              </>
            ) : null}

            <Route path='/dbtools/skulookup' element={<SkuLookUp />} />
            <Route path='/dbtools/pricinglookup' element={<PricingLookup />} />
            <Route path='/dbtools/ViewProposalPriceBasis' element={<ViewProposalPriceBasis />} />
            <Route path='/' element={<Layout />}>
              <Route path='*' element={<PageNotFound />} />
              <Route path='/pagenotfound' element={<PageNotFound />} />
              <Route path='/tbm/ps/window' element={<Window />} />
              {/* ----------------------------------Customer------------------------------------------*/}
              <Route path='/tbm/ps/dashboard' element={<Customer_Search />} />
              <Route path='/tbm/ps/searchCustomer/Customer' element={<Search_Customer_By_Details />} />
              <Route path='/tbm/ps/Salesordermasterrecord' element={<SalesOrderMasterRecord />} />
              <Route
                path='/tbm/ps/salesorderdetails'
                element={
                  <WorkOrderProvider>
                    <SalesOrderDetails />
                  </WorkOrderProvider>
                }
              />
              <Route path='/tbm/ps/dbtools' element={<DbtoolsList />} />
              <Route path='/tbm/ps/searchCustomer/searchresult' element={<Search_result />} />
              <Route path='/tbm/ps/createnewcustomer' element={<Create_new_customer />} />
              <Route path='/tbm/ps/existingcustomer' element={<CustomerSearchExisting />} />
              <Route path='/tbm/ps/customerdetails' element={<CustomerInfo />} />
              <Route path='/tbm/ps/editcustomeraddress' element={<EditCustomerDetails />} />
              <Route path='/tbm/ps/AddNewAddress' element={<AddNewAddress />} />
              <Route path='/tbm/ps/AddNewEmail' element={<AddNewEmail />} />
              <Route path='/tbm/ps/CustomerAddress' element={<Customer_Address />} />
              <Route path='/tbm/ps/editcustomeremail' element={<EditCustomerEmail />} />
              <Route path='/tbm/ps/editcustomerphoneemail' element={<EditCustomerPhoneEmail />} />
              <Route path='/tbm/ps/editcustomerphonenumber' element={<EditCustomerPhoneNumber />} />
              <Route path='/tbm/ps/addnewphonenumber' element={<AddNewPhoneNumber />} />
              <Route path='/tbm/ps/editcustomerfirstlastname' element={<EditCustomerFirstLastName />} />

              <Route path='/tbm/ps/windowset/:id' element={<WindowSet />} />

              <Route path='/tbm/ps/createproposal' element={<CreateProposal />} />
              <Route path='/tbm/ps/editwindowset/:id' element={<EditWindowSet />} />
              <Route path='/tbm/ps/reorderwindowset/:id' element={<ReOrderWindowSet />} />
              <Route path='/tbm/ps/deletewindow/:id' element={<DeleteWindow />} />
              <Route path='/tbm/ps/addwindow' element={<AddWindow />} />
              <Route path='/tbm/ps/addwindowset' element={<AddWindowSet />} />
              <Route path='/tbm/ps/createnewwindowset' element={<CreateNewWindowSet />} />

              <Route path='/tbm/ps/proposalselectwindow/:id' element={<ProposalSelectWindow />} />
              <Route
                path='/tbm/ps/proposalapplycovering/:id'
                element={
                  <WindowProvider>
                    <ProductProvider>
                      <ProposalApplyCovering />
                    </ProductProvider>
                  </WindowProvider>
                }
              />
              <Route path='/tbm/ps/proposal/:id/details' element={<ProposalDetails />} />

              <Route path='/tbm/ps/duplicateproposaldetails/:id' element={<DuplicateProposalDetails />} />
              <Route path='/tbm/ps/editmode/:id' element={<EditProposalDetails />} />
              <Route path='/tbm/ps/editingwsproposal/:id' element={<EditingProposalWindowSetToAddWindows />} />
              <Route path='/tbm/ps/editingswproposal/:id' element={<EditingProposalSelectWindowToAddWindows />} />
              <Route path='/tbm/ps/deletemode/:id' element={<DeleteProposalDetails />} />
              <Route path='/tbm/ps/proposal-batchedit/:id' element={<BatchEditProposalDetails />} />
              <Route
                path='/tbm/ps/batcheditingwindowcovering'
                element={
                  <WindowProvider>
                    <ProductProvider>
                      <BatchEditWindowCovering />
                    </ProductProvider>
                  </WindowProvider>
                }
              />
              <Route path='/tbm/ps/reordermode/:id' element={<ReOrderProposalDetails />} />
              <Route
                path='/tbm/ps/editingwindowproposal'
                element={
                  <WindowProvider>
                    <ProductProvider>
                      <EditWindowProposal />
                    </ProductProvider>
                  </WindowProvider>
                }
              />
              <Route path='/tbm/ps/newcustomer' element={<NewCustomer />} />
              <Route path='/tbm/ps/settings' element={<Settings />} />
              <Route path='/tbm/ps/home' element={<LandingPage />} />

              <Route path='/tbm/ps/systeminformation' element={<SystemInformation />} />
              <Route path='/tbm/ps/newcustomer' element={<NewCustomer />} />
              <Route path='/tbm/ps/selecteddateappointments' element={<Appoinments />} />
              <Route path='/tbm/ps/viewallappointments' element={<ViewAllApointments />} />
              <Route path='/tbm/ps/salesorder' element={<SalesOrder />} />
              <Route path='/tbm/ps/salesorderaddingwindows' element={<SalesOrderAddingWindows />} />
              <Route path='/tbm/ps/salesorderaddingandconfirmwindows' element={<SalesOrderAddingandConfirmwindows />} />
              <Route path='/tbm/ps/editwindowdetailsinsalesorder' element={<EditWindowDetailsInSalesOrder />} />

              <Route path='/tbm/ps/finalizesalesorder' element={<FinalizeSalesOrder />} />
              <Route path='/tbm/ps/salesorderconfirm' element={<SalesOrderConfirm />} />

              <Route path='/tbm/ps/proposal_id/:id' element={<CTWPage />} />
              <Route path='/tbm/ps/generate-salesorder-pdf' element={<CTSOPage />} />
              <Route path='/tbm/ps/previewproposal/:id' element={<PreviewProposal />} />
            </Route>
            <Route path='/tbm/customerproposal/:id' element={<WCPPage />} />

            <Route path='/sales-order-for-payment-&-signoff/:id' element={<WCPPage />} />
            <Route path='/tbm/converttosalesorder/:id' element={<CTSOPage />} />
          </Routes>
        </BrowserRouter>
      </SnackbarContext.Provider>
    </ThemeProvider>
  );
}
