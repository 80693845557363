import React, { useState } from "react";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { Box, Typography } from "@mui/material";
import WindowSetCheckIcon from "../../assets/icons/primary/windowset-complete.svg";
import WindowCheckIcon from "../../assets/icons/primary/window-confirm.svg";
import Grid from "@mui/material/Grid";

export default function SalesOrderStepper(props) {
  //  hover states

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  const handleHoverFun2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeaveFun2 = () => {
    setIsHovered2(false);
  };

  return (
    <div
      style={{
        marginLeft: "15px",
      }}
    >
      <Grid container spacing={2}>
        <Stack className="CoveringStepperStack">
          <Box className="stepperMainBox">
            {props.stepperData === "ConvertingSalesOrder" ? (
              <Box>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  onMouseEnter={handleHoverFun}
                  onMouseLeave={handleMouseLeaveFun}
                >
                  <Box>
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                    >
                      <Typography className="CoveringstepperMainTextSize">
                        STEP 1 :
                      </Typography>

                      <img
                        className="primary_icons_dimension_1 stepperImageMargins"
                        src={WindowSetCheckIcon}
                        alt="WindowSetCheckIcon"
                      />
                    </Stack>
                  </Box>
                  <Box
                    sx={{ borderLeft: "1px solid #D6D6D6", marginLeft: "10px" }}
                    className=""
                  >
                    <Typography className=" CoveringstepperSubTextStyle">
                      Select Line-items to <br /> Finalize for Sales Order
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            ) : (
              <Box>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Box>
                    <Stack
                      className="stepperDisable"
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                    >
                      <Typography className="CoveringstepperMainTextSize">
                        STEP 1 :
                      </Typography>
                      <img
                        src={WindowSetCheckIcon}
                        className="primary_icons_dimension_1 stepperImageMargins"
                        alt="WindowSetCheckIcon"
                      />
                    </Stack>
                  </Box>
                  <Box
                    sx={{ borderLeft: "1px solid #D6D6D6", marginLeft: "10px" }}
                    className="stepperDisable"
                  >
                    <Typography className=" CoveringstepperSubTextStyle">
                      Select Line-items to <br /> Finalize for Sales Order
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            )}
          </Box>

          {/* 2 */}
          <Box className="stepperMainBox">
            {props.stepperData === "FinalizeSalesOrder" ? (
              <Box>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  onMouseEnter={handleHoverFun2}
                  onMouseLeave={handleMouseLeaveFun2}
                >
                  <Box>
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                    >
                      <Typography className="CoveringstepperMainTextSize">
                        STEP 2 :
                      </Typography>

                      <img
                        className="primary_icons_dimension_1 stepperImageMargins"
                        src={WindowCheckIcon}
                        alt="WindowCheckIcon"
                      />
                    </Stack>
                  </Box>
                  <Box
                    sx={{ borderLeft: "1px solid #D6D6D6", marginLeft: "10px" }}
                    className=""
                  >
                    <Typography className=" CoveringstepperSubTextStyle">
                      Add Final Details and <br /> Confirm for Sales Order
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            ) : (
              <Box>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <Box>
                    <Stack
                      className="stepperDisable"
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem />}
                    >
                      <Typography className="CoveringstepperMainTextSize">
                        STEP 2 :
                      </Typography>
                      <img
                        src={WindowCheckIcon}
                        className="primary_icons_dimension_1 stepperImageMargins"
                        alt="WindowCheckIcon"
                      />
                    </Stack>
                  </Box>
                  <Box
                    sx={{ borderLeft: "1px solid #D6D6D6", marginLeft: "10px" }}
                    className="stepperDisable"
                  >
                    <Typography className=" CoveringstepperSubTextStyle">
                      Add Final Details and <br /> Confirm for Sales Order
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            )}
          </Box>
        </Stack>
      </Grid>
    </div>
  );
}
