/** @format */

import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation, Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material';
import CONFIG from '../../config/config.js';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import Radio from '@mui/material/Radio';
import { useSnackbar } from 'notistack';
import Fab from '@mui/material/Fab';
import { UserContext } from '../Shared/Layout';
import ShowProposalName from './ShowProposalName';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SnackbarContext } from '../../App';
import FormControlLabel from '@mui/material/FormControlLabel';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import { makeStyles } from '@mui/styles';
import Save from '../../img/save.png';
import ClearIcon from '../../img/clear.png';
import CancelIcon from '../../img/cancel.png';
import SaveIcon from '../../img/SaveButton.png';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerSaveIcon from '../../assets/icons/drawer-alert/drw-save.svg';
import { CoveringDrawer2 } from './CoveringDrawer2';
import DrawerCloseIcon from '../../assets/icons/drawer-alert/close.svg';

import fracty from 'fracty';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});
let filteredCoveringsDetails = {};

const useStyles = makeStyles({
  paper: {
    width: '90%',
    backdropFilter: 'blur(10px) !important',
    background: 'rgba(69, 157, 166, 0.73)  !important',
  },
});

export const CoveringDrawer = (props) => {
  const [isDrawerClose, setIsDrawerClose] = useState(false);

  let additional_sku = [];
  const classes = useStyles();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [opacity, setOpacity] = useState(props.data.opacity);
  const [fabric, setFabric] = useState(props.data.fabric);
  const [lift, setLift] = useState(props.data.lift);
  const [direction, setDirection] = useState(props.data.direction);
  const [headrail, setHeadrail] = useState('');
  const [windowdepth, setWindowDepth] = useState('');
  const [features, setFeatures] = useState('');
  const [track, setTrack] = useState('');
  const [design, setDesigner] = useState('0');
  const [install, setInstall] = useState('Yes');
  const [option, setOption] = useState([...props.data.option]);
  const [open, setOpen] = React.useState(false);
  const [chooseFilters, setChooseFilters] = useState([]);
  const [chooseProduct, setChooseProduct] = useState([]);
  const [chooseStyle, setChooseStyle] = useState([]);
  const [mt, setMT] = useState();
  const [wcc, setWCC] = useState(props.data.wcc);
  const [brand, setBrand] = useState(props.data.brand);
  const [label, setLabel] = useState(props.data.label);
  const [type, setType] = useState(props.data.type);
  const [product, setProduct] = useState(props.data.product);
  const [savedwcc, setsavedWCC] = useState(props.data.wcc);
  const [savedbrand, setsavedBrand] = useState(props.data.brand);
  const [savedlabel, setsavedLabel] = useState(props.data.label);
  const [savedtype, setsavedType] = useState(props.data.type);
  const [savedproduct, setsavedProduct] = useState(props.data.product);
  const [savedopacity, setsavedOpacity] = useState(props.data.opacity);
  const [savedfabric, setsavedFabric] = useState(props.data.fabric);
  const [savedlift, setsavedLift] = useState(props.data.lift);
  const [saveddirection, setsavedDirection] = useState(props.data.direction);
  const [savedoption, setsavedOption] = useState(props.data.option);
  const [showThirdSection, setshowThirdSection] = useState(false);
  const [brands, setBrands] = useState([]);
  const [brandLabel, setBrandLabel] = useState([]);
  const [brandLabelProduct, setbrandLabelProduct] = useState([]);
  const [brandLabelProductType, setbrandLabelProductType] = useState([]);
  const [checkedItems, setCheckedItems] = useState(props.data.checkedItems);
  const [brandLabelProductTypeOpacity, setbrandLabelProductTypeOpacity] = useState([]);
  const [brandLabelProductTypeOpacityFabric, setbrandLabelProductTypeOpacityFabric] = useState([]);
  const [brandLabelProductTypeOpacityFabricLift, setbrandLabelProductTypeOpacityFabricLift] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirection, setbrandLabelProductTypeOpacityFabricLiftDirection] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrail, setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrail] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValance, setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValance] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeatures, setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeatures] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeaturesTrack, setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeaturesTrack] = useState([]);
  const [sucessmsg, setSuccessMsg] = useState(false);
  const [errormsg, setErrorMsg] = useState(false);

  const [showInTable, setShowInTable] = useState(props.data.showInTable);
  const [incomingshowInTable, setInShowInTable] = useState(props.data.showInTable);
  const [showTable, setShowTable] = useState(false);
  const [tablemessage, setTableMessage] = useState('');
  const [selectedSKU, setSelectedSKU] = React.useState('');
  const [classtype, setClassType] = useState('');
  const [proposalName, setProposalName] = useState();
  const [brandnames, setBrandNames] = useState([]);
  const [labelnames, setLabelNames] = useState([]);
  const [categorynames, setCategoryNames] = useState([]);
  const [AllreadyCoveringAppliedWindows, setAllreadyCoveringAppliedWindows] = useState([]);
  const [CoveringnotAppliedtoWindows, setCoveringnotAppliedtoWindows] = useState([]);

  const [OptionName, setOptionName] = useState([]);
  const [LiftSystem, setLiftSystem] = useState([]);
  const [LiftSytemDetails, setLiftSytemDetails] = useState([]);
  const [SelectedLiftSytemDetails, setSelectedLiftSytemDetails] = useState([]);
  const [SelectedLiftSytemSKU, setSelectedLiftSytemSKU] = useState(props.data.SelectedLiftSytemSKU);
  const [SelectedOptionSKU, setSelectedOptionSKU] = useState(props.data.SelectedOptionSKU);
  const [SelectedOptionDetails, setSelectedOptionDetails] = useState([]);
  const [SelectedFabricSKU, setSelectedFabricSKU] = useState(props.data.SelectedFabricSKU);
  const [IncomingSelectedFabricSKU, setIncomingSelectedFabricSKU] = useState();
  const [alertmessage, setAlertMessage] = useState('');
  const [SKUCombinations, setSKUCombinations] = useState([...props.data.SKUCombinations]);
  const [ProductDescription, setProductDescriptions] = useState([...props.data.ProductDescription]);
  const [savedSKUCombinations, setsavedSKUCombinations] = useState(props.data.SKUCombinations);
  const [savedProductDescription, setsavedProductDescriptions] = useState(props.data.ProductDescription);
  const [showmismatchalert, setShowMismatchAlert] = useState();

  const [obstructions, setObstructions] = useState('');
  const [multiple, setMultiple] = useState('');
  const [rolldirection, setRollDirection] = useState('');
  const [leftshade_size, setLeftshade_size] = useState('');
  const [rightshade_size, setRightshade_size] = useState('');
  const [centershade_size, setCentershade_size] = useState('');
  const [AddNote, setAddNote] = useState('');
  const [AddNoteforBuildOut, setAddNoteforBuildOut] = useState('');
  const [AddNoteforFillerStrip, setAddNoteforFillerStrip] = useState('');
  const [AddNoteforSplitTilt, setAddNoteforSplitTilt] = useState('');
  const [AddNoteforSplitRail, setAddNoteforSplitRail] = useState('');
  const [Panel, setPanel] = useState('');
  const [Windowsill, setWindowsill] = useState('');
  const [DeadMold, setDeadMold] = useState('');
  const [Buildout, setBuildout] = useState('');
  const [FillerStrip, setFillerStrip] = useState('');
  const [SplitTilt, setSplitTilt] = useState('');
  const [SplitRail, setSplitRail] = useState('');
  const [TPost, setTPost] = useState('');
  const [PanelOpen, setPanelOpen] = useState(false);
  const [WindowsillOpen, setWindowsillOpen] = useState(false);
  const [DeadMoldOpen, setDeadMoldOpen] = useState(false);
  const [BuildoutOpen, setBuildoutOpen] = useState(false);
  const [FillerStripOpen, setFillerStripOpen] = useState(false);
  const [SplitTiltOpen, setSplitTiltOpen] = useState(false);
  const [SplitRailOpen, setSplitRailOpen] = useState(false);
  const [TPostOpen, setTPostOpen] = useState(false);
  const [ValanceOpen, setValanceOpen] = useState(false);
  const [HeadrailOpen, setHeadrailOpen] = useState(false);
  const [CutoutOpen, setCutoutOpen] = useState(false);
  const [MultipleOpen, setMultipleOpen] = useState(false);
  const [RolldirectionOpen, setRolldirectionOpen] = useState(false);
  const [wccOpen, setWccOpen] = useState(false);
  const [brandOpen, setBrandOpen] = useState(false);
  const [labelOpen, setLabelOpen] = useState(false);
  const [productOpen, setProductOpen] = useState(false);
  const [typeOpen, setTypeOpen] = useState(false);
  const [opacityOpen, setOpacityOpen] = useState(false);
  const [fabricOpen, setFabricOpen] = useState(false);
  const [liftOpen, setLiftOpen] = useState(false);
  const [directionOpen, setDirectionOpen] = useState(false);
  const [optionOpen, setOptionOpen] = useState(false);
  const [sendmsg, setSendmsg] = useState();
  const [selectedOptionJSON, setSelectedOptionJSON] = useState({ ...props.data.selectedOptionJSON });
  const [showProductDescription, setShowProductDescription] = useState(false);
  const [showSKUCombination, setShowSKUCombination] = useState(false);
  const [OptionalFinalizationFields, setOptionalFinalizationFields] = useState(props.data.OptionalFinalizationFields);
  const [confirmSalesOrderStatus, setConfirmSalesOrderStatus] = useState(false);
  const [markasconfirmedSalesOrderStatus, setMarkAsConfirmSalesOrderStatus] = useState(false);
  const [windowData, setWindowData] = useState(props.data.windowData[0]);
  const [clearfilters, setClearfilters] = useState(false);
  const [mountsurface, setMountsurface] = useState(props.data.mountsurface);
  const [ladder, setLadder] = useState(props.data.ladder);
  const [existingCovering, setExistingCovering] = useState(props.data.existingCovering);
  const [buildType, setBuildType] = useState(props.data.buildType);
  const [outgoingwindow_width, setOutgoingSizeW] = useState(props.data.outgoingwindow_width);
  const [outgoingwindow_height, setOutgoingSizeH] = useState(props.data.outgoingwindow_height);
  const [materialColor, setMaterialColor] = useState(props.data.materialColor);
  const [valanceColor, setValanceColor] = useState(props.data.ValanceColor);
  const [hingeColor, setHingeColor] = useState(props.data.HingeColor);
  const [headrailColor, setHeadrailColor] = useState(props.data.HeadrailColor);
  const [cordColor, setCordColor] = useState(props.data.CordColor);
  const [pClip, setPClip] = useState(props.data.PClip);
  const [paintColor, setPaintColor] = useState(props.data.paintColor);
  const [tiltConfiguration, setTiltConfiguration] = useState(props.data.tiltConfiguration);
  const [cordLocation, setCordLocation] = useState(props.data.cordLocation);
  const [cordType, setCordType] = useState(props.data.cordType);
  const [panelConfiguration, setPanelConfiguration] = useState(props.data.panelConfiguration);
  const [measurementType, setMeasurementType] = useState(props.data.measurementType);

  const [size, setSize] = useState(props.data.size);
  const [saveStatus, setSaveStatus] = useState(false);
  const [psf, setPSF] = useState(props.data.psf);
  const [proposalAdditionalSKU, setProposalAdditionalSKU] = useState(props.data.proposalAdditionalSKU);

  const [requestFrom, setRequestFrom] = useState(props.data.requestFrom);
  const [othermeasurement, setOtherMeasurement] = useState('');
  const [description, setDescription] = useState('');
  const [SectionOneTopShow, setSectionOneTopShow] = useState('');
  const [SectionTwoBottomShow, setSectionTwoBottomShow] = useState('');

  const [HeightForVerticalConfiguration, setHeightForVerticalConfiguration] = useState(props.data.HeightForVerticalConfiguration);
  const [SectionOneTopHeigth, setSectionOneTopHeigth] = useState('');
  const [SectionTwoBottomWidth, setSectionTwoBottomWidth] = useState('');
  const [VerticalConfiguration, setVerticalConfiguration] = useState('1 Section');
  const [SelectedWindow, setSelectedWindow] = useState(props.data.SelectedWindow);
  const [quickapply, setQuickApply] = useState(props.data.quickapply);
  const [AllOptionSelectedStatus, setAllOptionSelectedStatus] = useState(false);
  const [AllOptionNPSSelectedStatus, setAllOptionNPSSelectedStatus] = useState(false);
  const [ReqOptionDetails, setReqOptionDetails] = useState([]);
  const [NotReqOptionDetails, setNotReqOptionDetails] = useState([]);
  const [reqselectedOptionJSON, setReqSelectedOptionJSON] = useState({
    ...props.data.selectedOptionJSON,
  });
  const [notreqselectedOptionJSON, setNotReqSelectedOptionJSON] = useState({
    ...props.data.selectedOptionJSON,
  });
  var fsku = '';
  var lsku = '';
  var osku = '';
  const vertical = 'top';
  const horizontal = 'center';
  const capitalizeFirst = (str) => {
    str = str.toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  let windowsetnull = [];
  let windowsendforapplycovering = [];
  let coveringdetails = '';
  let windowname = [];
  let AppliedCoveringsWindows = [];
  let appliedcoveringswindowname = [];
  const coveringdetail = useContext(UserContext);

  if (location.state.coveringdetails === undefined) {
    coveringdetails = coveringdetail;
  } else {
    coveringdetails = location.state.coveringdetails;
  }
  let proposaldetails = {
    customerdata: location.state.customerdata,
    proposal_id: location.state.proposalID,
    proposal_name: location.state.proposalName,
  };
  useEffect(() => {
    let proposal_specific_factor = props.data.psf;

    switch (props.data.measurementType) {
      case 'Blinds':
        setWindowDepth(proposal_specific_factor.WindowDepth);
        setAddNote(proposal_specific_factor.AddNote);
        setHeadrail(proposal_specific_factor.HeadrailType);
        setLeftshade_size(proposal_specific_factor.LeftShade);
        setRightshade_size(proposal_specific_factor.RightShade);
        setCentershade_size(proposal_specific_factor.CenterShade);
        setObstructions(proposal_specific_factor.Obstructions);
        setOtherMeasurement(proposal_specific_factor.othermeasurement);
        setDescription(proposal_specific_factor.description);
        break;
      case 'Soft Shades':
        setHeadrail(proposal_specific_factor.HeadrailType);
        setLeftshade_size(proposal_specific_factor.LeftShade);
        setRightshade_size(proposal_specific_factor.RightShade);
        setCentershade_size(proposal_specific_factor.CenterShade);
        setWindowDepth(proposal_specific_factor.WindowDepth);
        setObstructions(proposal_specific_factor.Obstructions);
        setOtherMeasurement(proposal_specific_factor.othermeasurement);
        setDescription(proposal_specific_factor.description);
        break;
      case 'Roller Shades':
        setMultiple(proposal_specific_factor.Multiple);
        setRollDirection(proposal_specific_factor.Rolldirection);
        setWindowDepth(proposal_specific_factor.WindowDepth);
        setObstructions(proposal_specific_factor.Obstructions);
        setOtherMeasurement(proposal_specific_factor.othermeasurement);
        setDescription(proposal_specific_factor.description);
        break;
      case 'Shutters':
        setPanel(proposal_specific_factor.Panel);
        setWindowsill(proposal_specific_factor.Windowsill);
        setDeadMold(proposal_specific_factor.DeadMold);
        setBuildout(proposal_specific_factor.Buildout);
        setFillerStrip(proposal_specific_factor.FillerStrip);
        setSplitTilt(proposal_specific_factor.SplitTilt);
        setSplitRail(proposal_specific_factor.SplitRail);
        setAddNoteforBuildOut(proposal_specific_factor.AddNoteforBuildOut);
        setAddNoteforFillerStrip(proposal_specific_factor.AddNoteforFillerStrip);
        setAddNoteforSplitRail(proposal_specific_factor.AddNoteforSplitRail);
        setAddNoteforSplitTilt(proposal_specific_factor.AddNoteforSplitTilt);
        setTPost(proposal_specific_factor.TPost);
        setWindowDepth(proposal_specific_factor.WindowDepth);
        setObstructions(proposal_specific_factor.Obstructions);
        setOtherMeasurement(proposal_specific_factor.othermeasurement);
        setDescription(proposal_specific_factor.description);
        break;
    }
  }, [props.data.psf]);

  const appliedCoveringStatus = () => {
    let coveringsappliedwindows = location.state.totalselectedwindows;

    function allAreTrue(arr) {
      return arr.every((element) => element.covering_selected === true);
    }
  };
  useEffect(() => {
    getVerified();
    let arr_measurement_type = [];
    if (selectedwindowsIDs.length > 0) {
      selectedwindowsIDs.forEach((element) => {
        if (element.measurement_type === 'Quick') {
        } else {
        }
        arr_measurement_type.push(element.measurement_type);
      });

      arr_measurement_type.filter(unique);
      const allEqual = (arr) => arr.every((val) => val === arr[0]);
      let checkmeasurementtype;
      if (arr_measurement_type.includes('Quick')) {
        if (!arr_measurement_type.includes('Soft Shades') && !arr_measurement_type.includes('Roller Shades') && !arr_measurement_type.includes('Blinds') && !arr_measurement_type.includes('Shutters')) {
          checkmeasurementtype = allEqual(arr_measurement_type);
        } else {
          arr_measurement_type = arr_measurement_type.filter(unique);
          var positionofQuick = arr_measurement_type.indexOf('Quick');
          var newarr = arr_measurement_type.splice(positionofQuick, 1);
          checkmeasurementtype = allEqual(arr_measurement_type);
        }
      } else {
        checkmeasurementtype = allEqual(arr_measurement_type);
      }
      if (checkmeasurementtype) {
        setMT(arr_measurement_type[0]);
        setWCC(arr_measurement_type[0]);
        setShowMismatchAlert(false);
      }
    }
    setProposalName(location.state.proposalName);
    filteredResult();
  }, []);

  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  let checkedrowdata = [];
  let selectedwindowsIDs = [];

  // get state from proposalSelectWindow page
  if (location.state.selectedwindows) {
    selectedwindowsIDs = location.state.selectedwindows;
  }

  checkedrowdata = selectedwindowsIDs;

  let totalrowdata = [];
  totalrowdata = location.state.totalselectedwindows;
  let windowsetdata = [];
  windowsetdata = location.state.customerdata;
  let result = [];
  const unique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const changePosition = (arr, from, to) => {
    arr.splice(to, 0, arr.splice(from, 1)[0]);

    return arr;
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const filteredResult = () => {
    if (brand !== 'undefined' && product !== 'undefined' && label !== 'undefined' && type !== 'undefined') {
    }
  };

  useEffect(() => {
    if (coveringdetails !== undefined && coveringdetails.length > 0) {
      setChooseFilters(coveringdetails);
      setChooseProduct(coveringdetails);
      setChooseStyle(coveringdetails);
      const Covering_Category = [];
      const Brand = [];
      const Label = [];
      const Product = [];
      const Type = [];
      const Opacity = [];
      const Fabric = [];
      coveringdetails.forEach((element, i) => {
        Covering_Category.push(element.Covering_Category);
      });
      coveringdetails.forEach((element, i) => {
        Brand.push(element.Brand);
      });
      if (wcc === 'Blinds' || wcc === 'Soft Shades') {
        let Updatedbrand = Brand.filter(unique);
        var positionofQuick = Updatedbrand.indexOf('Blindman');
        var newarr = Updatedbrand.splice(positionofQuick, 1);

        setBrands(Updatedbrand.filter(unique));
      } else {
        setBrands(Brand.filter(unique));
      }

      coveringdetails.forEach((element, i) => {
        if (element.Covering_Category === wcc && element.Brand === brand) {
          Label.push(element.Label);
        }
      });
      coveringdetails.forEach((element, i) => {
        if (element.Covering_Category === wcc && element.Brand === brand && element.Label === label) {
          Product.push(element.Product);
        }
      });
      coveringdetails.forEach((element, i) => {
        if (element.Covering_Category === wcc && element.Brand === brand && element.Label === label && element.Product === product) {
          Type.push(element.Type);
        }
      });
      coveringdetails.forEach((element, i) => {
        if (element.Covering_Category === wcc && element.Brand === brand && element.Label === label && element.Product === product && element.Type === type) {
          Opacity.push(element.opacity);
        }
      });
      coveringdetails.forEach((element, i) => {
        if (element.Covering_Category === wcc && element.Brand === brand && element.Label === label && element.Product === product && element.Type === type && element.opacity === opacity) {
          Fabric.push(element.material);
        }
      });

      setCategoryNames(Covering_Category.filter(unique));
      setBrandNames(Brand.filter(unique));
      setLabelNames(Label.filter(unique));
      setBrandLabel(Label.filter(unique));
      setbrandLabelProduct(Product.filter(unique));
      setbrandLabelProductType(Type.filter(unique));

      setbrandLabelProductTypeOpacity(Opacity.filter(unique));
      setbrandLabelProductTypeOpacityFabric(Fabric.filter(unique));
    }
  }, [wcc]);

  useEffect(() => {
    let CheckReqSelectedOptionJSON = [];
    for (const key in ReqOptionDetails) {
      if (reqselectedOptionJSON.hasOwnProperty(key)) {
        CheckReqSelectedOptionJSON.push(reqselectedOptionJSON[key].pricing_status);
      }
    }

    const countTrue = CheckReqSelectedOptionJSON.reduce((count, currentValue) => {
      if (currentValue === true) {
        return count + 1;
      }
      return count;
    }, 0);

    if (Object.keys(ReqOptionDetails).length > 0 && Object.keys(ReqOptionDetails).length === countTrue) {
      setAllOptionSelectedStatus(true);
    } else if (Object.keys(ReqOptionDetails).length === 0) {
      setAllOptionSelectedStatus(true);
    } else {
      setAllOptionSelectedStatus(false);
    }
  }, [ReqOptionDetails, reqselectedOptionJSON]);

  useEffect(() => {
    let CheckNotReqSelectedOptionJSON = [];
    for (const key in NotReqOptionDetails) {
      if (notreqselectedOptionJSON.hasOwnProperty(key)) {
        CheckNotReqSelectedOptionJSON.push(notreqselectedOptionJSON[key].pricing_status);
      }
    }

    const countTrue = CheckNotReqSelectedOptionJSON.reduce((count, currentValue) => {
      if (currentValue === false) {
        return count + 1;
      }
      return count;
    }, 0);

    if (Object.keys(NotReqOptionDetails).length > 0 && Object.keys(NotReqOptionDetails).length === countTrue) {
      setAllOptionNPSSelectedStatus(true);
    } else if (Object.keys(NotReqOptionDetails).length === 0) {
      setAllOptionNPSSelectedStatus(true);
    } else {
      setAllOptionNPSSelectedStatus(false);
    }
  }, [NotReqOptionDetails, notreqselectedOptionJSON]);

  useEffect(() => {
    props.func(
      isDrawerClose,
      wcc,
      brand,
      label,
      product,
      type,
      opacity,
      fabric,
      lift,
      direction,
      option,
      showTable,
      savedProductDescription,
      ProductDescription,
      SKUCombinations,
      SelectedFabricSKU,
      SelectedLiftSytemSKU,
      SelectedOptionSKU,
      showInTable,
      clearfilters,
      showProductDescription,
      showSKUCombination,
      windowData,
      measurementType,
      outgoingwindow_width,
      outgoingwindow_height,
      existingCovering,
      mountsurface,
      buildType,
      ladder,
      OptionalFinalizationFields,
      selectedOptionJSON,
      checkedItems,
      saveStatus,
      size,
      psf,
      proposalAdditionalSKU,
      AllOptionSelectedStatus,
      AllOptionNPSSelectedStatus,
      quickapply,
      windowdepth,
      othermeasurement,
      obstructions,
      description,
      VerticalConfiguration,
      SectionOneTopShow,
      SectionTwoBottomShow
    );

    if (fabric === '') {
      if (SelectedFabricSKU === undefined) {
        setSKUCombinations([...SKUCombinations.filter((element) => !element.includes('M'))]);
      }
    }

    if (props.data.windowData.length === 1 && props.data.windowData[0].window_id) {
    }
  }, [isDrawerClose, saveStatus]);

  const save = () => {
    if (SelectedFabricSKU === '') {
      setSaveStatus(true);
    } else {
      setsavedWCC(wcc);
      setsavedBrand(brand);
      setsavedLabel(label);
      setsavedProduct(product);
      setsavedType(type);
      setsavedOpacity(opacity);
      setsavedFabric(fabric);
      setsavedLift(lift);
      setsavedDirection(direction);
      setsavedOption(option);
      setsavedProductDescriptions(ProductDescription);
      setsavedSKUCombinations(SKUCombinations);
      close();
    }
  };
  const close = () => setIsDrawerClose(true);

  const fetchdata = (
    drawerstatus,
    wcc,
    brand,
    label,
    product,
    type,
    opacity,
    fabric,
    lift,
    direction,
    option,
    showtable,
    newProductDescription,
    ProductDescription,
    newSKUCombinations,
    newselectedFabricSKU,
    newselectedLiftSytemSKU,
    SelectedOptionSKU,
    newshowInTable,
    clearfilters,
    showPD,
    showSKU,
    windowData,
    measurementType,
    outgoingwindow_width,
    outgoingwindow_height,
    existingCovering,
    mountsurface,
    buildType,
    ladder,
    OptionalFinalizationFields,
    selectedOptionJSON,
    checkedItems,
    saveStatus,
    size,
    psf,
    additional_sku,
    AllOptionSelectedStatus,
    AllOptionNPSSelectedStatus,
    quickapply,
    WindowDepth,
    othermeasurement,
    obstructions,
    description,
    VerticalConfiguration,
    SectionOneTopShow,
    SectionTwoBottomShow
  ) => {
    setMeasurementType(measurementType);
    setSize(fracty(outgoingwindow_width) + ' x ' + fracty(outgoingwindow_height));
    setOutgoingSizeW(outgoingwindow_width);
    setOutgoingSizeH(outgoingwindow_height);
    setExistingCovering(existingCovering);
    setBuildType(buildType);
    setMountsurface(mountsurface);
    setLadder(ladder);
    setWindowDepth(WindowDepth);
    setOtherMeasurement(othermeasurement);
    setObstructions(obstructions);
    setDescription(description);
    if (Array.isArray(newSKUCombinations)) {
      if (newSKUCombinations.length > 0) {
      }
    }

    if (wcc && brand && label && product && type && opacity && fabric) {
      setShowTable(true);
    } else {
      setShowTable(false);
    }

    setSKUCombinations(newSKUCombinations.filter(unique));
    setWCC(wcc);
    setBrand(brand);
    setLabel(label);
    setProduct(product);
    setType(type);
    setOpacity(opacity);
    setFabric(fabric);
    setLift(lift);
    setDirection(direction);
    setCheckedItems(checkedItems);
    setOption(option);

    setProductDescriptions([ProductDescription]);
    setSKUCombinations(newSKUCombinations.filter(unique));
    setSelectedFabricSKU(newselectedFabricSKU);
    setSelectedOptionJSON(selectedOptionJSON);
    setShowInTable(newshowInTable);
    setSelectedLiftSytemSKU(newselectedLiftSytemSKU);
    setSelectedOptionSKU(SelectedOptionSKU);
    setOptionalFinalizationFields(OptionalFinalizationFields);
    setProposalAdditionalSKU(additional_sku);
    if (OptionalFinalizationFields) {
      let count = Object.keys(OptionalFinalizationFields).length;

      if (count > 0) {
        setConfirmSalesOrderStatus(true);
      } else {
        setConfirmSalesOrderStatus(false);
        setMarkAsConfirmSalesOrderStatus(false);
      }
    }
    setShowProductDescription(showPD);
    setShowSKUCombination(showSKU);
  };

  filteredCoveringsDetails = {
    wcc: wcc,
    brand: brand,
    label: label,
    product: product,
    type: type,
    opacity: opacity,
    fabric: fabric,
    lift: lift,
    direction: direction,
    option: option,
    ProductDescription: ProductDescription,
    SKUCombinations: SKUCombinations,
    showInTable: showInTable,
    SelectedFabricSKU: SelectedFabricSKU,
    SelectedOptionSKU: SelectedOptionSKU,
    SelectedLiftSytemSKU: SelectedLiftSytemSKU,
    sendmsg: sendmsg,
    windowData: [windowData],
    OptionalFinalizationFields: OptionalFinalizationFields,
    selectedOptionJSON: selectedOptionJSON,
    checkedItems: checkedItems,
    clearfilters: clearfilters,
    saveStatus: saveStatus,
    existingCovering: existingCovering,
    buildType: buildType,
    measurementType: measurementType,
    mountsurface: mountsurface,
    ladder: ladder,
    outgoingwindow_width: outgoingwindow_width,
    outgoingwindow_height: outgoingwindow_height,
    size: size,
    psf: psf,
    proposalAdditionalSKU: proposalAdditionalSKU,
    requestFrom: requestFrom,
    SelectedWindow: SelectedWindow,
    WindowConfiguration: Panel,
    Windowsill: Windowsill,
    VerticalConfiguration: VerticalConfiguration,
    HeightForVerticalConfiguration: HeightForVerticalConfiguration,
    SectionOneTopHeigth: SectionOneTopHeigth,
    SectionTwoBottomWidth: SectionTwoBottomWidth,
    RSWindowConfiguration: multiple,
  };

  return (
    <>
      <div align='center' style={{ zIndex: 1000000 }}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setOpen(false)}>
            {classtype === 'Success' ? (
              <Alert className={classtype} style={{ zIndex: 1000000 }} variant='filled' icon={false}>
                <div className='alertMsgCancelICon' align='right'>
                  <IconButton color='inherit' onClick={handleSuccessClose}>
                    <CancelRoundedIcon />
                  </IconButton>
                </div>
                <div align='center' style={{ paddingTop: 30 }}>
                  <CheckCircleIcon sx={{ fontSize: 40 }} />

                  <div style={{ paddingTop: 10 }}>{alertmessage}</div>
                </div>
              </Alert>
            ) : (
              <Alert className={classtype} variant='filled' icon={false}>
                <div className='alertMsgCancelICon' align='right'>
                  <IconButton color='inherit' onClick={handleSuccessClose}>
                    <CancelRoundedIcon />
                  </IconButton>
                </div>
                <div align='center' style={{ paddingTop: 30 }}>
                  <ErrorIcon sx={{ fontSize: 40 }} />

                  <div style={{ paddingTop: 10 }}>{alertmessage}</div>
                </div>
              </Alert>
            )}
          </Snackbar>
        </Stack>
      </div>

      <Box p={4} pt={2} textAlign='center' paddingTop={1} className='drawer_Header_page'>
        <div align='right' style={{ marginLeft: '85%' }}>
          <img src={DrawerCloseIcon} onClick={close} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
        </div>

        <ThemeProvider theme={theme}>
          <Box align='center'>
            <CoveringDrawer2 func2={fetchdata} data={filteredCoveringsDetails} />
          </Box>
        </ThemeProvider>

        <Stack direction='column' spacing={2}>
          <span className='drawer_gray_button_span' style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
            <Box className='globalIconMainBox globalDrawerText' onClick={save}>
              <img src={DrawerSaveIcon} className='primary_icons_dimension' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
                SAVE
              </Typography>
            </Box>
          </span>
          <span className='drawer_gray_button_span button_top_space' onClick={close} style={{ paddingBottom: '30px', paddingLeft: '14px' }}>
            <Box className='globalIconMainBox'>
              <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
                CANCEL
              </Typography>
            </Box>
          </span>
        </Stack>
      </Box>
    </>
  );
};
