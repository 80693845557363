/** @format */

import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import searchIcon from '../../assets/icons/primary/search.svg';
import searchHovIcon from '../../assets/icons/primary/WithHovIcon/search-hov.svg';
import userIcon3 from '../../assets/icons/primary/customer-add.svg';
import TableHead from '@mui/material/TableHead';
import Box from '@mui/material/Box';
import CONFIG from '../../config/config.js';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import CustomerAddICon from '../../assets/icons/primary/WithHovIcon/customer-add-hov.svg';
import TablePagination from '@mui/material/TablePagination';

const vertical = 'top';
const horizontal = 'center';

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
const ResultPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let locationState = [];
  let customerDetailsVal = [];

  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    locationState = location.state;
    customerDetailsVal = location.state.data.CustomerList;
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [errorMsg, setErrorMsg] = React.useState(false);
  const [customerDetails, setCustomerDetails] = useState([]);

  const [isImageHovered, setIsImageHovered] = useState(false);

  //  hover states

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const handleHoverFun2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeaveFun2 = () => {
    setIsHovered2(false);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMsg(false);
  };

  const [mssqlDisconnect, setMssqlDisconnect] = useState(false);
  const getError = () => {
    if (locationState.data === false) {
      setErrorMsg(true);
    } else if (locationState.data === 'Mssql is disconnected!') {
      setMssqlDisconnect(true);
    } else {
      setCustomerDetails(customerDetailsVal);
    }
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getError();
  }, []);

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow style={{ margin: '0px' }}>
          <TableCell className='search_table_header6'>First Name</TableCell>
          <TableCell className='search_table_header6'>Last Name</TableCell>
          <TableCell className='search_table_header4'>Address</TableCell>
          <TableCell className='search_table_header6'>City</TableCell>
          <TableCell className='search_table_header4'>Zip Code</TableCell>
          <TableCell className='search_table_header3'>CustomerID</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const LongText = ({ content }) => {
    const [limit, setLimit] = useState(CONFIG.GlobalTruncateCharacterLimit);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    if (content === undefined) {
      // Data is undefined, return empty
      return null;
    }

    // Check if the window width is between 280px and 768px and content length is greater than 14
    if (windowWidth >= 280 && windowWidth <= 767 && content.length > 15) {
      const truncatedContent = content.substring(0, 15) + '...';
      return <span>{truncatedContent}</span>;
    }

    // Check if the window width is above or equal to 768
    if (windowWidth >= 768) {
      if (content.length <= limit) {
        // There is nothing more to show
        return <span>{content}</span>;
      }

      let truncatedContent = content.substring(0, limit);

      // Find the last space within the truncated content
      const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

      // Adjust the truncated content if a space was found
      if (lastSpaceIndex !== -1) {
        truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
      }

      // Add the ellipsis to the truncated content
      const toShow = truncatedContent + '...';

      return <span>{toShow}</span>;
    }

    if (content.length <= limit) {
      // There is nothing more to show
      return <span>{content}</span>;
    }

    let truncatedContent = content.substring(0, limit);

    // Find the last space within the truncated content
    const lastSpaceIndex = truncatedContent.lastIndexOf(' ');

    // Adjust the truncated content if a space was found
    if (lastSpaceIndex !== -1) {
      truncatedContent = truncatedContent.substring(0, lastSpaceIndex);
    }

    // Add the ellipsis to the truncated content
    const toShow = truncatedContent + '...';

    return <span>{toShow}</span>;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const rows = [];
  return (
    <div>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={errorMsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setErrorMsg(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel_2'>
                <img onClick={handleErrorClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='RedDrawerCancelIcon' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' alt='RedExclamationIcon' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Customer Not Found !</div>
              </div>
            </>
          </Alert>
        </Snackbar>
        <Snackbar className='snackbar_bar_postition' open={mssqlDisconnect} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setMssqlDisconnect(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel_2'>
                <img onClick={() => setMssqlDisconnect(false)} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='RedDrawerCancelIcon' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' alt='RedExclamationIcon' />
                <div align='center' className='alertLeftPadding alertSpacing alertSpacing1'>
                  {locationState.data}
                </div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <br />
      <Typography className='search_customer_heading'>Search Results</Typography>
      <br />
      <Box className='search-table-box'>
        <TableContainer
          sx={{
            maxHeight: 600,
          }}
          className='table_padding_bottom'
        >
          <Table className='search-table-dimension' stickyHeader aria-label='sticky table'>
            <EnhancedTableHead />
            <TableBody>
              {(rowsPerPage > 0 ? customerDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : customerDetails).map((row) => {
                return (
                  <TableRow hover className='rowhover' key={row.CustId}>
                    <TableCell className='table-content' align='left'>
                      <span
                        className='search_result_table'
                        onClick={() =>
                          navigate('/tbm/ps/customerdetails', {
                            state: { Custdata: row },
                          })
                        }
                      >
                        <LongText content={row.Husband || row.wife || row.LName} />
                      </span>
                    </TableCell>
                    <TableCell className='table-content' align='left'>
                      <span
                        className='search_result_table'
                        onClick={() =>
                          navigate('/tbm/ps/customerdetails', {
                            state: { Custdata: row },
                          })
                        }
                      >
                        <LongText content={row.LName || row.lname} />
                      </span>
                    </TableCell>
                    <TableCell className='table-content' align='left'>
                      <span
                        className='search_result_table'
                        onClick={() =>
                          navigate('/tbm/ps/customerdetails', {
                            state: { Custdata: row },
                          })
                        }
                      >
                        <LongText content={row.Address1} />
                      </span>
                    </TableCell>
                    <TableCell className='table-content' align='left'>
                      <span
                        className='search_result_table'
                        onClick={() =>
                          navigate('/tbm/ps/customerdetails', {
                            state: { Custdata: row },
                          })
                        }
                      >
                        <LongText content={row.City || row.city} />
                      </span>
                    </TableCell>
                    <TableCell sx={{ minWidth: '100px', maxWidth: '100px' }} className='table-content' align='left'>
                      <span
                        className='search_result_table'
                        onClick={() =>
                          navigate('/tbm/ps/customerdetails', {
                            state: { Custdata: row },
                          })
                        }
                      >
                        {row.zip}
                      </span>
                    </TableCell>
                    <TableCell className='table-content' align='left'>
                      <span
                        className='search_result_table'
                        onClick={() =>
                          navigate('/tbm/ps/customerdetails', {
                            state: { Custdata: row },
                          })
                        }
                      >
                        {row.custid}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className='search_table_dimension_for_pagination'
          rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]}
          component='div'
          count={customerDetails.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <hr className='button_bottom_space' />

      <Box className='padding_bottom_class' sx={{ display: 'flex', flexDirection: 'column' }}>
        <Button sx={{ margin: '0px !important', padding: '0px 8px !important' }} className='search_details_button_search' onClick={() => navigate('/tbm/ps/searchCustomer/Customer')} onMouseEnter={handleHoverFun2} onMouseLeave={handleMouseLeaveFun2}>
          <Box className='globalIconMainBox globalTextDecoration'>
            {isHovered2 ? <img className='primary_icons_dimension_1' src={searchHovIcon} alt='searchHovIcon' /> : <img className='primary_icons_dimension_1' src={searchIcon} alt='searchIcon' />}
            <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} className='globalText'>
              BEGIN A NEW SEARCH
            </Typography>
          </Box>
        </Button>

        <Button id='CreateNewCustomer' className='search_details_button_create button_top_space' sx={{ padding: '0px 8px !important' }} onClick={() => navigate('/tbm/ps/createnewcustomer')} onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
          <Box className='globalIconMainBox globalTextDecoration'>
            {isHovered ? <img className='primary_icons_dimension_1' src={CustomerAddICon} alt='CustomerAddICon' /> : <img className='primary_icons_dimension_1' src={userIcon3} alt='userIcon3' />}
            <Typography id='CreateNewCustomerTG' onMouseEnter={handleHover2} onMouseLeave={handleHover2} className='globalText'>
              {' '}
              CREATE NEW CUSTOMER
            </Typography>
          </Box>
        </Button>
      </Box>
    </div>
  );
};

export default ResultPage;
