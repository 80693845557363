import { Container, Button } from '@mui/material';
import CloseIcon from '../../../assets/icons/drawer-alert/close.svg';

export default function Header({ closeDrawer }) {
  return (
    <Container sx={{ pt: 5 }} align='right'>
      <Button
        onClick={closeDrawer}
        sx={{
          width: 'fit-content',
        }}
      >
        <img onClick={closeDrawer} src={CloseIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='closeDrawer' />
      </Button>
    </Container>
  );
}
