import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  section_col_body: {
    flexDirection: "col",
  },
  section_row: {
    flexDirection: "row",
    paddingTop: 15,
  },
  section_col_key: {
    paddingTop: 10,
    flexDirection: "col",
  },
  section_col_val: {
    paddingTop: 3.5,
    flexDirection: "col",
  },
  headingText: {
    paddingLeft: 5,
    fontFamily: "RobotoLight",
    fontSize: "8px",
    lineHeight: "2.5px",
    color: "#000",
    textAlign: "justify",
  },
  rowText: {
    paddingTop: 5,
    paddingLeft: 40,
    fontFamily: "RobotoLight",
    fontSize: "10px",
    lineHeight: "1.5px",
    color: "#000",
    textAlign: "justify",
  },
  box: {
    flexDirection: "col",
    backgroundColor: "#EDECEB",
    padding: 5,
    marginLeft: 120,
    marginTop: -12,
    width: "100%",
  },
  heading: {
    width: "60%",
    padding: 20,
    fontSize: "22px",
    color: "#007383",
    fontFamily: "RobotoLight",
    lineHeight: 1,
  },
  p: {
    width: "95%",
    marginTop: -5,
    paddingLeft: 20,
    paddingRight: 10,
    fontFamily: "RobotoLight",
    fontSize: "11px",
    lineHeight: "1.8",
    color: "#2D3030",
    textAlign: "justify",
  },
});

const PdfSubHeader = ({ date, name, customer, sales_rep, proposal_no }) => (
  <View style={styles.section_col_body}>
    <View style={styles.section_row}>
      <View style={styles.section_col_key}>
        <Text style={styles.headingText}>Date: </Text>
        <Text style={styles.headingText}>Customer: </Text>
        <Text style={styles.headingText}>Address: </Text>
        {customer.address_second_line ? (
          <Text style={styles.headingText}>Address Line 2: </Text>
        ) : null}
        <Text style={styles.headingText}>City, State, Zip Code: </Text>
        <Text style={styles.headingText}>Phone: </Text>
        <Text style={styles.headingText}>Email: </Text>
        <Text style={styles.headingText}>&nbsp;</Text>
        <Text style={styles.headingText}>Consultant:</Text>
        <Text style={styles.headingText}>Proposal Name:</Text>
      </View>
      <View style={styles.section_col_val}>
        <Text style={styles.rowText}>{date}</Text>
        <Text style={styles.rowText}>{name}</Text>
        <Text style={styles.rowText}>{customer.address_line}</Text>
        {customer.address_second_line ? (
          <Text style={styles.rowText}>{customer.address_second_line}</Text>
        ) : null}
        <Text style={styles.rowText}>
          {customer.city.toUpperCase()}, {customer.state.toUpperCase()},{" "}
          {customer.zip}
        </Text>
        {customer.mobile.map((mobile, i) => (
          <Text key={i} style={styles.rowText}>
            {mobile.Type ? `${mobile.Type}: ${mobile["#"]}` : `${mobile["#"]}`}
          </Text>
        ))}
        {customer.emails.map((email, i) => (
          <Text key={i} style={styles.rowText}>
            {email.Type
              ? `${email.Type}: ${email.email_id}`
              : `${email.email_id}`}
          </Text>
        ))}
        <Text style={styles.rowText}>&nbsp;</Text>
        <Text style={styles.rowText}>{sales_rep}</Text>
        <Text style={styles.rowText}>{proposal_no}</Text>
      </View>
      <View style={styles.box}>
        <Text style={styles.heading}>THE BLINDMAN DIFFERENCE</Text>
        <Text style={styles.p}>
          You can trust The Blindman for innovative, technically
          <br /> advanced, and efficient window covering solutions. From the
          very first blind we built in 1990, our quality craftsmanship and
          unmatched customer service have helped us become Utah’s largest custom
          window covering manufacturer.{" "}
        </Text>
      </View>
    </View>
  </View>
);

export default PdfSubHeader;
