import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableBody, TableRow, TableCell, Tooltip, Zoom } from '@mui/material';
import Moment from 'react-moment';
import { tooltipClasses } from '@mui/material/Tooltip';
import { Star as StarIcon, StarOutline as StarOutlineIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import LongText from '../LongText';

const TurquoiseTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0ca8b0',
    color: '#ffffff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 90,
    textAlign: 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#0ca8b0',
      border: '1px solid #0ca8b0',
    },
  },
}));

const SalesOrderTableBody = ({ state, salesOrders, page, rowsPerPage, navigateToSalesOrder }) => {
  const navigate = useNavigate();
  function iconStyles() {
    return {
      salesrepIcon: {
        fontSize: 'medium !important',
        color: '#808080',
      },
      customerIcon: {
        fontSize: 'medium !important',
        color: '#0CA8B0',
      },
      proposalsentIcon: {
        fontSize: 'medium !important',
        color: '#0CA8B0',
      },
      spaceIcon: {
        fontSize: 'medium !important',
        color: 'white',
      },
    };
  }

  const iconclasses = makeStyles(iconStyles)();

  return (
    <TableBody>
      {(rowsPerPage > 0 ? salesOrders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : salesOrders).map((salesOrder) => (
        <TableRow hover className='rowhover' role='checkbox' tabIndex={-1} key={salesOrder.index}>
          {/* Sales Order Name */}
          <TableCell className='table-content sticky-column' align='left'>
            {salesOrder.sales_order_status === 'active' && salesOrder.invoice !== null ? (
              <div style={{ cursor: 'pointer' }} className='link ellipsis'>
                {' '}
                {salesOrder.adobe_sign_status === 'OUT_FOR_ACCEPTANCE' ? (
                  <TurquoiseTooltip title='Sales Order has sent' placement='left' TransitionComponent={Zoom} arrow>
                    <StarOutlineIcon className={iconclasses.proposalsentIcon} />
                  </TurquoiseTooltip>
                ) : null}
                <span
                  onClick={() =>
                    navigate('/tbm/ps/salesorderdetails', {
                      state: {
                        Custdata: {
                          Addressid: state.Custdata.Addressid,
                          customerId: state.Custdata.custid,
                          Proposal_Id: salesOrder.proposal_id,
                          Sales_Order_Id: salesOrder.sales_order_id,
                          Invoice: salesOrder.invoice,
                          Sales_rep: salesOrder.sales_person,
                        },
                      },
                    })
                  }
                >
                  {salesOrder.adobe_sign_status === 'ACCEPTED' || salesOrder.adobe_sign_status === 'COMPLETED' ? (
                    <TurquoiseTooltip title='All Good! Customer has approved Sales  Order' placement='left' TransitionComponent={Zoom} arrow>
                      <StarIcon className={iconclasses.customerIcon} />
                    </TurquoiseTooltip>
                  ) : null}
                </span>
                &nbsp;{' '}
                <span
                  onClick={() =>
                    navigate('/tbm/ps/salesorderdetails', {
                      state: {
                        Custdata: {
                          Addressid: state.Custdata.Addressid,
                          customerId: state.Custdata.custid,
                          Proposal_Id: salesOrder.proposal_id,
                          Sales_Order_Id: salesOrder.sales_order_id,
                          Invoice: salesOrder.invoice,
                          Sales_rep: salesOrder.sales_person,
                        },
                      },
                    })
                  }
                >
                  <LongText>{salesOrder.sales_order_name}</LongText>
                </span>
                {!salesOrder.sales_order_finalize_loop_status ? <span onClick={() => navigateToSalesOrder(salesOrder.sales_order_id)}>(Incomplete)</span> : null}
              </div>
            ) : salesOrder.sales_order_status === 'active' && salesOrder.invoice === null ? (
              <div style={{ cursor: 'pointer' }} className='link ellipsis'>
                {' '}
                {salesOrder.adobe_sign_status === 'OUT_FOR_ACCEPTANCE' ? (
                  <TurquoiseTooltip title='Sales Order has sent' placement='left' TransitionComponent={Zoom} arrow>
                    <StarOutlineIcon className={iconclasses.proposalsentIcon} />
                  </TurquoiseTooltip>
                ) : null}
                <span onClick={() => navigateToSalesOrder(salesOrder.sales_order_id)}>
                  {salesOrder.adobe_sign_status === 'ACCEPTED' || salesOrder.adobe_sign_status === 'COMPLETED' ? (
                    <TurquoiseTooltip title='All Good! Customer has approved Sales  Order' placement='left' TransitionComponent={Zoom} arrow>
                      <StarIcon className={iconclasses.customerIcon} />
                    </TurquoiseTooltip>
                  ) : null}
                </span>
                &nbsp;{' '}
                <span onClick={() => navigateToSalesOrder(salesOrder.sales_order_id)}>
                  <LongText>{salesOrder.sales_order_name}</LongText>{' '}
                </span>
                {!salesOrder.sales_order_finalize_loop_status ? <span onClick={() => navigateToSalesOrder(salesOrder.sales_order_id)}> (Incomplete) </span> : null}
              </div>
            ) : (
              <div style={{ cursor: 'pointer' }} onClick={() => {}} className='link ellipsis'>
                {' '}
                {salesOrder.adobe_sign_status === 'OUT_FOR_ACCEPTANCE' ? (
                  <TurquoiseTooltip title='Sales Order has sent' placement='left' TransitionComponent={Zoom} arrow>
                    <StarOutlineIcon className={iconclasses.proposalsentIcon} />
                  </TurquoiseTooltip>
                ) : null}
                <span>
                  {salesOrder.adobe_sign_status === 'ACCEPTED' || salesOrder.adobe_sign_status === 'COMPLETED' ? (
                    <TurquoiseTooltip title='All Good! Customer has approved Sales Order' placement='left' TransitionComponent={Zoom} arrow>
                      <StarIcon className={iconclasses.customerIcon} />
                    </TurquoiseTooltip>
                  ) : null}
                </span>
                &nbsp;{' '}
                <span style={{ opacity: '40%' }}>
                  <LongText>{salesOrder.sales_order_name}</LongText>
                </span>{' '}
                {!salesOrder.sales_order_finalize_loop_status ? <span>(Incomplete)</span> : null}
              </div>
            )}
          </TableCell>

          {/* Work Order # */}
          <TableCell className='table-content' align='left'>
            {!salesOrder.invoice ? (
              <>- -</>
            ) : (
              <span
                onClick={() =>
                  navigate('/tbm/ps/salesorderdetails', {
                    state: {
                      Custdata: {
                        Addressid: state.Custdata.Addressid,
                        customerId: state.Custdata.custid,
                        Proposal_Id: salesOrder.proposal_id,
                        Sales_Order_Id: salesOrder.sales_order_id,
                        Invoice: salesOrder.invoice,
                        Sales_rep: salesOrder.sales_person,
                      },
                    },
                  })
                }
                className='link ellipsis'
                style={{ cursor: 'pointer' }}
              >
                {salesOrder.invoice}
              </span>
            )}
          </TableCell>

          {/* Conversion Date */}
          <TableCell className='table-content' align='left'>
            {salesOrder.sales_order_finalize_loop_status ? <Moment format='MM/DD/YY'>{salesOrder.sales_order_entry_date}</Moment> : <>- - </>}
          </TableCell>

          {/* Sales Order Total */}
          <TableCell className='table-content' align='left'>
            {salesOrder.sales_order_finalize_loop_status ? `$${salesOrder.final_total}` : <>- -</>}
          </TableCell>

          {/* Balance */}
          <TableCell className='table-content' align='left'>
            {salesOrder.sales_order_finalize_loop_status ? `$${salesOrder.final_total}` : <>- -</>}
          </TableCell>

          {/* Sales Rep */}
          <TableCell className='table-content' align='left'>
            <LongText>{salesOrder.sales_person}</LongText>
          </TableCell>

          {/* Date Sales Order Sent */}
          <TableCell className='table-content' align='left'>
            {salesOrder.mail_sent ? <Moment format='MM/DD/YY'>{salesOrder.mail_sent}</Moment> : <span>- -</span>}
          </TableCell>

          {/* Date Signed */}
          <TableCell className='table-content' align='left'>
            {salesOrder.sales_order_accept ? <Moment format='MM/DD/YY'>{salesOrder.sales_order_accept}</Moment> : <span>- -</span>}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

export default SalesOrderTableBody;
