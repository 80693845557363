import React, { useState } from 'react';
import { FormControl, Select, MenuItem, InputAdornment } from '@mui/material';

const DropDown = ({ options = [], disabled = false, value = '', onChange = null, startAdornment, label, type = 'service_name', sx }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const close = () => setOpen(false);

  const toggleOpen = () => setOpen(!open);

  const handleChange = ({ target }) => {
    onChange({
      action: `SET_${label.split(' ').join('_').toUpperCase()}`,
      payload: label === 'Measurement Type' ? target.value : options.find(({ sku }) => sku === target.value),
    });
    close();
  };

  return (
    <FormControl sx={{ width: '100%', color: '#000', fontWeight: 'bold' }} onClick={disabled ? toggleOpen : null}>
      <Select
        sx={{
          ...sx,
          backgroundColor: '#EEE',
          margin: '10px 0',
        }}
        MenuProps={{
          style: { zIndex: 10_000_000 },
        }}
        value={value}
        required
        disabled={disabled}
        align='left'
        size='small'
        select
        onClose={close}
        onOpen={handleOpen}
        open={!disabled && open}
        onChange={!disabled ? handleChange : null}
        displayEmpty
        startAdornment={
          <InputAdornment className='cursor' position='start'>
            {label} : {startAdornment}
          </InputAdornment>
        }
      >
        <MenuItem value='Multiple' sx={{ display: 'none' }}>
          Multiple
        </MenuItem>
        <MenuItem value=''>- -</MenuItem>
        {options?.map((option) => (
          <MenuItem key={option.sku || option.short_code} value={option.sku || option.measurement_type}>
            {!type !== 'measurement_type' ? (
              <b>{option[type]}</b>
            ) : (
              <b style={{ color: option.color_code }}>
                {option[type]} ({option.short_code})
              </b>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropDown;
