/** @format */

import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import CONFIG from '../../config/config.js';
import SkuLookupIcon from '../../assets/icons/primary/SKU_Lookup.svg';
import SkuLookupHovIcon from '../../assets/icons/primary/WithHovIcon/skulookup-hov.svg';
import PricingLookup from '../../assets/icons/primary/Pricing_Lookup.svg';
import PricingLookupHovIcon from '../../assets/icons/primary/WithHovIcon/pricelookup-hov.svg';
import JsonConverter from '../../assets/icons/primary/JSON_Converter.svg';
import JsonConverterHovIcon from '../../assets/icons/primary/WithHovIcon/jsonconv-hov.svg';
import DataImporter from '../../assets/icons/primary/Data_Importer.svg';
import DataImporterHovIcon from '../../assets/icons/primary/WithHovIcon/dataimporter-hov.svg';
import RefreshIcon from '../../assets/icons/primary/Refresh.svg';
import RefreshHovIcon from '../../assets/icons/primary/WithHovIcon/refresh-hov.svg';
import PricingBasis from '../../assets/icons/primary/pricing-basis.svg';
import PricingBasisHovIcon from '../../assets/icons/primary/WithHovIcon/viewpropprice-hov.svg';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
const vertical = 'top';
const horizontal = 'center';
const DbtoolsList = () => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const [deleteItem, setDeleteItem] = useState(false);

  //  hover states

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const [isHovered6, setIsHovered6] = useState(false);
  const [isHovered7, setIsHovered7] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  const handleHoverFun2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeaveFun2 = () => {
    setIsHovered2(false);
  };

  const handleHoverFun3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeaveFun3 = () => {
    setIsHovered3(false);
  };

  const handleHoverFun4 = () => {
    setIsHovered4(true);
  };

  const handleMouseLeaveFun4 = () => {
    setIsHovered4(false);
  };

  const handleHoverFun5 = () => {
    setIsHovered5(true);
  };

  const handleMouseLeaveFun5 = () => {
    setIsHovered5(false);
  };

  const handleHoverFun6 = () => {
    setIsHovered6(true);
  };

  const handleMouseLeaveFun6 = () => {
    setIsHovered6(false);
  };
  const handleHoverFun7 = () => {
    setIsHovered7(true);
  };

  const handleMouseLeaveFun7 = () => {
    setIsHovered7(false);
  };

  const handleClose = () => {
    setDeleteItem(false);
  };
  const handleRedisClear = async () => {
    await fetch(`${CONFIG.API_URL}/pm/ClearRedis`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.error === true) {
          toast.error(data.error_message);
          setDeleteItem(false);
        } else if (!data.error) {
          toast.success(data.message);
          setDeleteItem(false);
          sessionStorage.clear();
          sessionStorage.clear();
          setTimeout(() => {
            navigate('/login');
          }, 3000);
        }
      });
  };

  const [hostname, setHostname] = useState('');
  useEffect(() => {
    const currentHostname = window.location.hostname;
    setHostname(currentHostname);
  }, []);

  const shouldHideButton = hostname === 'tbmqa.theblindman.com';

  return (
    <div className='CustomerSearch_Appointment_MainDiv'>
      <Snackbar className='snackbar_bar_postition' open={deleteItem} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
        <Alert className='Success' variant='filled' icon={false} severity='warning'>
          <div className='alertMsgCancelICon' align='right'>
            <img alt='DrawerCancelIcon' onClick={handleClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
          </div>
          <div align='center'>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'Center',
                paddingBottom: '10px',
              }}
            >
              <Box className='alertCantinueButton'>
                <img alt='DrawerAlertIcon' src={DrawerAlertIcon} className='primary_icons_dimension' />
              </Box>
            </Box>
            <div align='center' style={{ paddingLeft: '1%', paddingRight: '1%' }}>
              <span style={{ fontSize: '20px' }}> {'Are you sure want to clear the Redis & Materialized View ? it will Distroy the session !'}</span>
              <div
                align='left'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <Box>
                  <Button type='submit' onClick={handleRedisClear}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'Center',
                        cursor: 'pointer',
                        marginBottom: '10px',
                        marginLeft: '6px',
                      }}
                    >
                      <Box className='alertCantinueButton'>
                        <img alt='DrawerConinueIcon' src={DrawerConinueIcon} className='primary_icons_dimension' />
                      </Box>
                      <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                        CONTINUE
                      </Typography>
                    </Box>
                  </Button>
                </Box>
                <Box className='gray_button_span button_top_space' style={{ paddingBottom: '30px', paddingLeft: '23px' }} onClick={handleClose}>
                  <Box className='globalIconMainBox'>
                    <Box className='iconSecondaryButtonGlobalForDrawer'>
                      <img alt='DrawerCancelIcon' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                    </Box>
                    <Typography className='globalTextForDrawer'>CANCEL</Typography>
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        </Alert>
      </Snackbar>
      <Button
        sx={{
          boxShadow: '0px 5px 4px 0px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
          marginTop: '20px',
        }}
        className='searchCustomer button_bottom_space'
        variant='contained'
        onClick={() => {
          navigate('/dbtools/skulookup');
        }}
      >
        <Box className='globalIconMainBox globalTextDecoration' sx={{ position: 'relative', right: '40px' }} onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
          {isHovered ? <img alt='SkuLookupHovIcon' className='primary_icons_dimension_1' src={SkuLookupHovIcon} /> : <img alt='SkuLookupIcon' className='primary_icons_dimension_1' src={SkuLookupIcon} />}
          <Typography className='globalText'>SKU Lookup</Typography>
        </Box>
      </Button>

      <Button
        sx={{
          boxShadow: '0px 5px 4px 0px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        }}
        className='Appointment searchCustomer'
        variant='contained'
        onClick={() => {
          navigate('/dbtools/pricinglookup');
        }}
      >
        <Box className='globalIconMainBox globalTextDecoration' onMouseEnter={handleHoverFun2} onMouseLeave={handleMouseLeaveFun2}>
          {isHovered2 ? <img alt='PricingLookupHovIcon' className='primary_icons_dimension_1' src={PricingLookupHovIcon} /> : <img alt='PricingLookup' className='primary_icons_dimension_1' src={PricingLookup} />}
          <Typography className='globalText'>Pricing Lookup</Typography>
        </Box>
      </Button>
      <Button
        sx={{
          boxShadow: '0px 5px 4px 0px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
          marginTop: '20px',
        }}
        className='dbtools'
        variant='contained searchCustomer'
        onClick={() => navigate('/dbtools/ViewProposalPriceBasis')}
      >
        <Box className='globalIconMainBox globalTextDecoration' sx={{ position: 'relative', left: '13px' }} onMouseEnter={handleHoverFun3} onMouseLeave={handleMouseLeaveFun3}>
          {isHovered3 ? <img alt='PricingBasisHovIcon' className='primary_icons_dimension_1' src={PricingBasisHovIcon} /> : <img alt='PricingBasis' className='primary_icons_dimension_1' src={PricingBasis} />}

          <Typography
            sx={{
              fontSize: '15px',

              marginLeft: '5px',

              color: '#008593',

              fontFamily: 'RobotoLight !important',
            }}
          >
            View Proposal Pricing<div>Basis</div>
          </Typography>
        </Box>
      </Button>
      {shouldHideButton ? null : (
        <>
          <Button
            sx={{
              boxShadow: '0px 5px 4px 0px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              marginTop: '20px',
            }}
            className='dbtools searchCustomer'
            variant='contained'
            onClick={() => navigate('/dbtools/jsonconverter')}
          >
            <Box className='globalIconMainBox globalTextDecoration' sx={{ position: 'relative', left: '5px' }} onMouseEnter={handleHoverFun4} onMouseLeave={handleMouseLeaveFun4}>
              {isHovered4 ? <img alt='JsonConverterHovIcon' className='primary_icons_dimension_1' src={JsonConverterHovIcon} /> : <img alt='JsonConverter' className='primary_icons_dimension_1' src={JsonConverter} />}
              <Typography className='globalText'>JSON Converter</Typography>
            </Box>
          </Button>

          <Button
            sx={{
              boxShadow: '0px 5px 4px 0px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              marginTop: '20px',
            }}
            className='dbtools'
            variant='contained searchCustomer'
            onClick={() => navigate('/dbtools/jsonimporter')}
          >
            <Box className='globalIconMainBox globalTextDecoration' sx={{ position: 'relative', right: '3px' }} onMouseEnter={handleHoverFun5} onMouseLeave={handleMouseLeaveFun5}>
              {isHovered5 ? <img alt='DataImporterHovIcon' className='primary_icons_dimension_1' src={DataImporterHovIcon} /> : <img alt='DataImporter' className='primary_icons_dimension_1' src={DataImporter} />}
              <Typography className='globalText'>Data Importer</Typography>
            </Box>
          </Button>
          <Button
            sx={{
              boxShadow: '0px 5px 4px 0px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              marginTop: '20px',
            }}
            className='dbtools'
            variant='contained searchCustomer'
            onClick={() => navigate('/dbtools/delimitedcolors')}
          >
            <Box className='globalIconMainBox globalTextDecoration' sx={{ position: 'relative', right: '3px', marginLeft: '24px' }} onMouseEnter={handleHoverFun6} onMouseLeave={handleMouseLeaveFun6}>
              {isHovered6 ? <img alt='DataImporterHovIcon' className='primary_icons_dimension_1' src={DataImporterHovIcon} /> : <img alt='DataImporter' className='primary_icons_dimension_1' src={DataImporter} />}
              <Typography className='globalText'>Delimited Colors</Typography>
            </Box>
          </Button>

          <Button
            sx={{
              boxShadow: '0px 5px 4px 0px rgba(0,0,0,0.2), 0px 2px 4px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
              marginTop: '20px',
            }}
            className='dbtools'
            variant='contained searchCustomer'
            onClick={() => setDeleteItem(true)}
          >
            <Box className='globalIconMainBox globalTextDecoration' sx={{ position: 'relative', left: '13px' }} onMouseEnter={handleHoverFun7} onMouseLeave={handleMouseLeaveFun7}>
              {isHovered7 ? <img alt='RefreshHovIcon' className='primary_icons_dimension_1' src={RefreshHovIcon} /> : <img alt='RefreshIcon' className='primary_icons_dimension_1' src={RefreshIcon} />}

              <Typography
                sx={{
                  fontSize: '15px',
                  marginLeft: '5px',
                  color: '#008593',
                  fontFamily: 'RobotoLight !important',
                }}
              >
                Refresh Materialized <div>View & Clear Redis</div>
              </Typography>
            </Box>
          </Button>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default DbtoolsList;
