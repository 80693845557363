/** @format */

import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import CONFIG from '../../config/config.js';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../../index.css';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import SaveEmailIcon from '../../img/SaveButton.png';
import validator from 'validator';
import DrawerCloseIcon from '../../assets/icons/drawer-alert/close.svg';
import DrawerCheckIcon from '../../assets/icons/drawer-alert/2nd-enter.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerSendIcon from '../../assets/icons/drawer-alert/drw-send.svg';
import DrawerEditIcon from '../../assets/icons/drawer-alert/2drw-edit.svg';
import DrawerAddIcon from '../../assets/icons/drawer-alert/2drw-add.svg';

export default function ShareProposal(props) {
  const navigate = useNavigate();
  let filename;
  let customerEmailID = props.data.Proposaldetails.customerdata.email_id;
  let customerMobileNo = props.data.Proposaldetails.customerdata.mobile;
  let Notes = 'NA';

  const [ShowEmailAddress, setShowEmailAddress] = useState([]);
  const [emailAddress, setEmailAddress] = useState([]);

  const inputNotes = [
    {
      type: 'email-id',
      id: 0,
      value: Notes,
    },
  ];

  const [EmailIds, setEmailIds] = useState();
  const [NotesIds, setNotesIds] = useState(inputNotes);
  const [NewEmail, setNewEmail] = useState();
  const [NewNotes, setNewNotes] = useState('');
  const [ShowEmailInputfield, setShowEmailInputfield] = useState(false);
  const [NotesTextField, setNotesTextField] = useState(false);
  const [NotesTextFieldValue, setNotesTextFieldValue] = useState('');
  const [emailToBeSent, setEmailTobeSend] = useState([]);
  const [IsDeliveryDrawerClose, setIsDeliveryDrawerClose] = useState(true);
  const [proposalStatus, setProposalStatus] = useState('');
  const [isSend, setIsSend] = useState(false);
  const [emailError, setEmailError] = useState(true);
  const [message, setMessage] = useState(props.data.shareablelink);
  const [successMsg, setSuccessMsg] = useState('');
  const [mailStatus, setmailStatus] = useState(false);
  const [successOpen, setSuccessOpen] = React.useState(false);
  const [paymentoption, setPaymentOption] = React.useState('Full');
  const [sendingButtonMsg, setsendingButtonMsg] = useState(false);
  const token = sessionStorage.getItem('token');
  const [selected, setSelected] = React.useState([]);
  const [CheckedArray, setCheckedArray] = useState([]);
  const [AddNewEmailInCustomer, setAddNewEmailInCustomer] = useState('');

  useEffect(() => {
    GetCustomerEmailFun();
  }, []);

  let isItemSelected;
  const isSelected = (row) => selected.indexOf(row) !== -1;

  const GetCustomerEmailFun = async (emailData) => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        customer_id: props.data.Proposaldetails.customerdata.customer_id,
        address_id: props.data.Proposaldetails.customerdata.address_id,
      }),
    };
    await fetch(`${CONFIG.API_URL}/cm/Customer`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          if (data.customer.email.length === 0) {
            setEmailIds([
              {
                type: 'email-id',
                id: 0,
                value: customerEmailID,
              },
            ]);
          } else {
            setEmailAddress(data.customer.email);
            const AddNewField = data.customer.email.map((ele, i, arr) => {
              return {
                ...ele,
                type: 'email-id',
                id: i,
                value: ele.email_id,
              };
            });

            setShowEmailAddress(AddNewField);
            setEmailIds(AddNewField);
            if (emailData) {
              if (AddNewField.length === 1) {
                const lastItem = AddNewField[0];
                setSelected([lastItem]);
                setEmailTobeSend([lastItem]);
              } else {
                const lastItem = AddNewField[AddNewField.length - 1];
                CheckedArray.push(lastItem);

                setSelected(CheckedArray);
                setEmailTobeSend(CheckedArray);
              }
            }
          }
        }
      });
  };

  const notesarray = [];

  const closeDrawer = (e) => {
    setIsDeliveryDrawerClose(false);
  };

  const AdditionalEmails = () => {
    setShowEmailInputfield(true);
  };

  const AdditionalNotes = () => {
    setNotesTextField(true);
  };

  const handleChangeEmail = (e) => {
    e.preventDefault();
    var email = e.target.value;

    if (validator.isEmail(email)) {
      setEmailError(false);
      setNewEmail(e.target.value);
    } else {
      setEmailError(true);
    }
    setAddNewEmailInCustomer(e.target.value);
  };

  const handleChangeNotes = (e) => {
    e.preventDefault();
    setNewNotes(e.target.value);
    setNotesTextFieldValue(e.target.value);
  };

  const AddEmail = () => {
    setShowEmailInputfield(false);
    AddNewEmailInCustomerFun();
    setEmailError(true);
    setNewEmail('');
    setEmailIds((s) => {
      const lastId = s[s.length - 1].id;

      return [
        ...s,
        {
          type: 'email-id',
          id: lastId + 1,
          value: NewEmail,
        },
      ];
    });
  };

  const AddNotes = () => {
    setNotesTextField(false);
    setNewNotes('');
    setNotesIds((s) => {
      const lastId = s[s.length - 1].id;
      return [
        ...s,
        {
          type: 'email-id',
          id: lastId + 1,
          value: NewNotes,
        },
      ];
    });
  };

  const AddNewEmailInCustomerFun = async () => {
    await fetch(CONFIG.API_URL + '/cm/AddCustomerEmail', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        customer_id: props.data.Proposaldetails.customerdata.customer_id,
        description: '',
        email: AddNewEmailInCustomer,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.message === 'Customer Email is added successfully!') {
          GetCustomerEmailFun(true);
        }
      });
  };

  const handleCheckboxChange = (event, emailjson) => {
    const emailarray = [...emailToBeSent];

    if (event.target.checked) {
      emailarray.push(emailjson);
      setSelected(emailarray);
    } else {
      emailarray.splice(emailToBeSent.indexOf(emailjson), 1);
      setSelected(emailarray);
    }
    setEmailTobeSend(emailarray);
  };

  const SendEmail = async () => {
    setEmailTobeSend([]);
    setIsSend(true);

    const emails = selected.map((email) => email.value);

    if (selected.length === 1) {
    } else {
      emailToBeSent.forEach((element) => {
        emails.push(element.value);
      });
    }

    const link = CONFIG.URL + '/tbm/customerproposal/' + props.data.Proposaldetails.proposalID;
    let customerDetails = props.data.Proposaldetails.customerdata;

    filename = 'Proposal for ' + customerDetails.first_name + customerDetails.last_name + '.pdf';

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        link: link,
        emails: emails,
        proposal_id: props.data.Proposaldetails.proposalID,
        notes: NotesTextFieldValue,
      }),
    };

    await fetch(`${CONFIG.API_URL}/pm/ProposalOutForAcceptance`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setsendingButtonMsg(false);
          setmailStatus(true);
          setSuccessMsg(data.message);
          setSuccessOpen(true);
          setMessage('');
          setIsDeliveryDrawerClose(false);
          setProposalStatus('OUT_FOR_ACCEPTANCE');
        }
      });
  };

  const SaveButtonNotes = () => (
    <IconButton>
      <img className='drawer_iconsize2' src={SaveEmailIcon} onClick={AddNotes} alt='save email icon' />
    </IconButton>
  );

  const handleChangePaymentoption = (event) => {
    setPaymentOption(event.target.value);
  };

  props.func(IsDeliveryDrawerClose, isSend, mailStatus, successMsg, successOpen, proposalStatus);

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  return (
    <Box p={2} pt={2} className='sideDrawer'>
      <div align='right' style={{ paddingTop: '10px' }}>
        <img alt='CLOSEDRAWER' src={DrawerCloseIcon} onClick={closeDrawer} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
      </div>

      <Box p={3} textAlign='center' paddingTop={1}>
        <Typography sx={{ color: 'white', display: 'flex', paddingBottom: '18px' }}>SELECT DELIVERY METHOD</Typography>

        <Box className=''>
          {EmailIds !== undefined ? (
            <Box sx={{ flexDirection: 'colomn !important' }} className='price_drawer_box_2'>
              {EmailIds.map((item, i) => {
                isItemSelected = isSelected(item);

                return item.value === 'NA' ? (
                  <Typography sx={{ marginBottom: '6px', fontFamily: 'RobotoLight', fontSize: '18px' }} align='left'>
                    No email on file
                  </Typography>
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: 'white' }}
                        id={i}
                        checked={isItemSelected}
                        onClick={(e) => {
                          handleCheckboxChange(e, item);
                        }}
                      />
                    }
                    label={
                      <span style={{ paddingBottom: '5px' }} id={i}>
                        Email : {item.value}
                      </span>
                    }
                  />
                );
              })}
            </Box>
          ) : null}
        </Box>

        <span onClick={AdditionalEmails} className='drawer_gray_button_span' style={{ paddingBottom: '15px', paddingLeft: '14px' }}>
          {EmailIds !== undefined ? (
            EmailIds[0].value === 'NA' ? (
              <Box className='globalIconMainBox'>
                <img alt='ADDEMAIL' src={DrawerAddIcon} className='drawer_secondary_icons_dimension' />
                <Typography sx={{ fontFamily: 'RobotoLight', textAlign: 'start' }} className='drawerGlobalText'>
                  ADD EMAIL
                </Typography>
              </Box>
            ) : (
              <Box className='globalIconMainBox'>
                <img alt='ADDANOTHEREMAIL' src={DrawerAddIcon} className='drawer_secondary_icons_dimension' />
                <Typography sx={{ fontFamily: 'RobotoLight', textAlign: 'start' }} className='drawerGlobalText'>
                  ADD ANOTHER EMAIL
                </Typography>
              </Box>
            )
          ) : null}
        </span>

        <Box sx={{ marginLeft: '20px' }} className='price_drawer_box_3'>
          {ShowEmailInputfield ? (
            <>
              <TextField
                className='email_short_textfield formcontrol'
                label={emailError ? <span style={{ color: '#AA0D0D' }}>Please Enter a Valid Email</span> : 'Add Email'}
                variant='filled'
                id={EmailIds.length + 1}
                InputProps={{
                  style: { fontWeight: 'bold' },
                }}
                onChange={handleChangeEmail}
              />
              {emailError ? (
                <img alt='ADDEMAIL' src={DrawerCheckIcon} style={{ marginLeft: '20px' }} className='secondary_icons_dimension_disable' />
              ) : (
                <img alt='ADDMAILD' src={DrawerCheckIcon} onClick={AddEmail} style={{ marginLeft: '20px', cursor: 'pointer' }} className='drawer_secondary_icons_dimension' />
              )}
            </>
          ) : (
            ''
          )}
        </Box>

        {/*
        <Box className="price_drawer_box">
          <Box sx={{ display: "flex" }}>
            {
              customerMobileNo === "NA" ? <Typography sx={{ marginBottom: "6px", fontFamily: "RobotoLight", fontSize: "18px" }}>No phone number on file</Typography> : <FormControlLabel
                control={<Checkbox style={{ color: "white" }} />}
                label={<span>SMS Text # : {customerMobileNo}</span>}
              />
            }
          </Box>
        </Box>

        <span
          className="drawer_gray_button_span"
          style={{ paddingBottom: "20px", paddingLeft: "14px" }}
        >
          <Box className="globalIconMainBox">
            <img
              alt="ADDANOTHERSMSText"
              src={DrawerAddIcon}
              className="drawer_secondary_icons_dimension"
            />
            <Typography
              sx={{ fontFamily: "RobotoLight", textAlign: "start" }}
              className="drawerGlobalText"
            >
              {
                customerMobileNo === "NA" ? <>ADD SMS Text #</> : <>ADD ANOTHER SMS Text #</>
              }

            </Typography>
          </Box>
        </span>
        */}

        <hr />

        <span
          className='drawer_gray_button_span button_top_space'
          onClick={() => {
            navigate(`/tbm/ps/customerdetails`, {
              state: {
                Custdata: {
                  custid: props.data.Proposaldetails.customerdata.customer_id,
                  Addressid: props.data.Proposaldetails.customerdata.address_id,
                },
              },
            });
          }}
          style={{ paddingBottom: '20px', paddingLeft: '14px' }}
        >
          <Box className='globalIconMainBox'>
            <img alt='EDITCUSTOMERINFO' src={DrawerEditIcon} className='drawer_secondary_icons_dimension' />
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
              EDIT CUSTOMER INFO
            </Typography>
          </Box>
        </span>

        <hr />

        <span onClick={AdditionalNotes} className='drawer_gray_button_span button_top_space' style={{ paddingBottom: '20px', paddingLeft: '14px' }}>
          <Box className='globalIconMainBox'>
            <img alt='ADDNOTETOCUSTOMEREMAIL' src={DrawerAddIcon} className='drawer_secondary_icons_dimension' />
            <Typography sx={{ fontFamily: 'RobotoLight', textAlign: 'start' }} className='drawerGlobalText'>
              ADD MESSAGE TO CUSTOMER EMAIL
            </Typography>
          </Box>
        </span>

        <Box sx={{ marginLeft: '20px' }} className='price_drawer_box_1 '>
          {NotesTextField ? (
            <TextField
              className='email_short_textfield formcontrol button_bottom_space'
              variant='filled'
              id={NotesIds.length + 1}
              InputProps={{
                endAdornment: <SaveButtonNotes />,
                style: { fontWeight: 'bold' },
              }}
              onChange={handleChangeNotes}
            />
          ) : null}
        </Box>

        <hr className='' />
        {props.data.route && props.data.route === 'shareproposal' ? null : (
          <>
            <Box sx={{ textAlign: 'start' }}>
              <Typography sx={{ color: 'white', display: 'flex', paddingBottom: '18px' }}>SELECT PAYMENT OPTIONS</Typography>
              <RadioGroup aria-label='payment' name='payment' value={paymentoption} onChange={handleChangePaymentoption}>
                <FormControlLabel value='Full' control={<Radio style={{ color: 'white' }} />} label={<span style={{ paddingBottom: '5px', color: 'white' }}>PAY IN FULL</span>} />
                <FormControlLabel value='Half' control={<Radio style={{ color: 'white' }} />} label={<span style={{ paddingBottom: '5px', color: 'white' }}>50% DEPOSIT</span>} />
              </RadioGroup>
            </Box>
            <hr className='' />
          </>
        )}
      </Box>

      <Stack direction='column' sx={{ paddingLeft: '24px' }} spacing={2}>
        {(emailToBeSent.length > 0 || selected.length > 0) && !sendingButtonMsg ? (
          <span
            className='drawer_gray_button_span '
            onClick={() => {
              setsendingButtonMsg(true);
              SendEmail();
            }}
            style={{ paddingBottom: '0px', paddingLeft: '0px' }}
          >
            <Box className='globalIconMainBox globalDrawerText'>
              <img alt='SEND' src={DrawerSendIcon} className={`primary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} />
              <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
                SEND
              </Typography>
            </Box>
          </span>
        ) : (
          <span className='drawer_gray_button_span' style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
            <Box className='globalIconMainBox '>
              <img alt='SENDD' src={DrawerSendIcon} className='primary_icons_dimension_disable' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalTextDisable'>
                {sendingButtonMsg ? <>SENDING...</> : <>SEND</>}
              </Typography>
            </Box>
          </span>
        )}

        <span className='drawer_gray_button_span button_top_space' onClick={closeDrawer} style={{ paddingBottom: '30px', paddingLeft: '14px' }}>
          <Box className='globalIconMainBox'>
            <img alt='CANCEL' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension' />
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
              CANCEL
            </Typography>
          </Box>
        </span>
      </Stack>
    </Box>
  );
}
