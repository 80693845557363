/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
/** @format */

import React, { forwardRef, useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import WindowIcon from '../../assets/icons/primary/window-edit.svg';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material';
import CONFIG from '../../config/config.js';
import Paper from '@mui/material/Paper';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import { UserContext } from '../Shared/Layout';
import MuiAlert from '@mui/material/Alert';
import { SnackbarContext } from '../../App';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CoveringDrawer } from './CoveringsDrawer';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { CoveringDrawer2 } from './CoveringDrawer2';
import ApplyCoveringIcon from '../../assets/icons/primary/window.svg';
import ApplyCoveringHovIcon from '../../assets/icons/primary/WithHovIcon/window-hov.svg';
import fracty from 'fracty';
import NotesDrawer from './NotesDrawer';
import ProposalIcon from '../../assets/icons/primary/proposal.svg';
import Checkbox from '@mui/material/Checkbox';
import FilterIcon from '../../assets/icons/secondary/2nd-filter.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerContinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import EditProposalWindowName from './EditProposalWindowName';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';
import NoteMiscIcon from '../../assets/icons/misc/misc-note.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import NotesSecondaryIcon from '../../assets/icons/secondary/2nd-note.svg';
import LongText from '../LongText';
import { WindowContext, WindowDispatchContext } from '../Shared/WindowProvider';
import WindowDetails from '../Shared/WindowDetails';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});

const theme1 = createTheme({
  palette: {
    border: {
      main: '1px solid #000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#FAC41E',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "green", // date picker textfield style
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#008593 !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'white', // disable date
            color: '#E9E9E9',
          },
        },
        dayWithMargin: {
          backgroundColor: 'white', // not selected date
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "blue" //calender icon & month change arrow icon style
        },
      },
    },
  },
});

const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#333333',
    },
  },
});

const useStyles = makeStyles({
  paper: {
    width: '90%',
    background: 'rgba(69, 157, 166, 70%) !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  paddingRight: theme.spacing(0.5),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

export default function ProposalWindowCovering() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const contextvalue = useContext(SnackbarContext);
  const { windowDetails } = useContext(WindowContext);
  const windowDispatch = useContext(WindowDispatchContext);

  if (!location.state) {
    navigate('pagenotfound');
  }

  const customerdetail = location.state.data;
  const customerID = location.state.data.customer_id;
  const addressID = location.state.data.address_id;
  const WindowId = location.state.row_id;

  const [windowName, setWindowName] = useState(location.state.window_name);
  const [windowData, setWindowData] = useState([]);
  const [successmessage, setSuccessMessage] = useState('');
  const [openupdatewindowname, setUpdateWindownameOpen] = useState(false);
  const [outgoingwindow_width, setOutgoingSizeW] = useState('');
  const [outgoingwindow_height, setOutgoingSizeH] = useState('');
  const [mountSurface, setMountsurface] = useState('');
  const [ladder, setLadder] = useState('');
  const [value, setValue] = useState([]);
  const [selectedOptionJSON, setSelectedOptionJSON] = useState({});
  const [confirmSalesOrderStatus, setConfirmSalesOrderStatus] = useState(false);
  const [confirmable, setConfirmable] = useState(false);
  const [keywordsbyFilter, setKeywordsbyFilter] = useState([]);
  const [confirmed, setConfirmed] = useState(false);
  const [SelectedWindow, setSelectedWindow] = useState([]);
  const [clearfilters, setClearFilters] = useState(false);
  const [otherMeasurement, setotherMeasurement] = useState('');
  const [showWindowDepth, setShowWindowDepth] = useState('');
  const [showObstructions, setshowObstructions] = useState('');
  const [showDescription, setshowdescription] = useState('');
  const [showVerticalConfig, setshowVerticalConfig] = useState('');
  const [SectionOneTopShow, setSectionOneTopShow] = useState('');
  const [SectionTwoBottomShow, setSectionTwoBottomShow] = useState('');
  const [proposalDetailsId, setProposalDetailsId] = useState('');

  const [brand, setBrand] = useState('');
  const [label, setLabel] = useState('');
  const [type, setType] = useState('');
  const [product, setProduct] = useState('');
  const [opacity, setOpacity] = useState('');
  const [fabric, setFabric] = useState('');
  const [lift, setLift] = useState('');
  const [direction, setDirection] = useState('');
  const [headrail, setHeadrail] = useState('');
  const [windowdepth, setWindowDepth] = useState('');
  const [features, setFeatures] = useState('');
  const [track, setTrack] = useState('');
  const [design, setDesigner] = useState('0');
  const install = 'Yes';
  const [option, setOption] = useState([]);
  const [open, setOpen] = useState(false);
  const [chooseFilters, setChooseFilters] = useState([]);
  const [wcc, setWCC] = useState('');
  const [brands, setBrands] = useState([]);
  const [brandLabel, setBrandLabel] = useState([]);
  const [sucessmsg, setSuccessMsg] = useState(false);
  const [errormsg, setErrorMsg] = useState(false);
  const [showInTable, setShowInTable] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedSKU, setSelectedSKU] = useState('');
  const [classtype, setClassType] = useState('');
  const [proposalName, setProposalName] = useState('');
  const [ProposalLineItemName, setProposalLineItemName] = useState('');
  const [AllreadyCoveringAppliedWindows, setAllreadyCoveringAppliedWindows] = useState([]);
  const [CoveringnotAppliedtoWindows, setCoveringnotAppliedtoWindows] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [nameDrawerOpen, setNameDrawerOpen] = useState(false);
  const [sendmsg, setSendmsg] = useState();
  const [showProductDescription, setShowProductDescription] = useState(false);
  const [showSKUCombination, setShowSKUCombination] = useState(false);
  const [outOption, setOutOption] = useState('');
  const [sku, setSku] = useState('');
  const [coveringStatus, setCoveringStatus] = useState('');
  const [coveringType, setCoveringType] = useState('');
  const [coveringSelected, setCoveringSelected] = useState('');
  const [materialDescription, setMaterialDescription] = useState('');
  const [coveringCategory, setCoveringCategory] = useState('');
  const [size, setSize] = useState();
  const [ReqOptionDetails, setReqOptionDetails] = useState([]);
  const [NotReqOptionDetails, setNotReqOptionDetails] = useState([]);
  const [existingCovering, setExistingCovering] = useState('');
  const [measurementType, setMeasurementType] = useState('');
  const [buildType, setBuildType] = useState('');
  const [OptionName, setOptionName] = useState([]);
  const [LiftSystem, setLiftSystem] = useState([]);
  const [LiftSytemDetails, setLiftSytemDetails] = useState([]);
  const [SelectedLiftSytemDetails, setSelectedLiftSytemDetails] = useState([]);
  const [selectedLiftSytemSku, setSelectedLiftSytemSKU] = useState();
  const [SelectedOptionDetails, setSelectedOptionDetails] = useState([]);
  const [SelectedOptionSKU, setSelectedOptionSKU] = useState();
  const [selectedFabricSku, setSelectedFabricSKU] = useState();
  const [SelectedFabricSKUName, setSelectedFabricSKUName] = useState();
  const [SKUCombinations, setSKUCombinations] = useState([]);
  const [productDescription, setProductDescriptions] = useState([]);
  const [SKUDetails, setSKUDetails] = useState([]);
  const coveringdetail = useContext(UserContext);
  const [disablebutton, setDisableButton] = useState(false);
  const [minqty, setMinqty] = useState(1);
  const [Panel, setPanel] = useState('');
  const [VerticalConfiguration, setVerticalConfiguration] = useState('');
  const [Windowsill, setWindowsill] = useState('');
  const [HeightForVerticalConfiguration, setHeightForVerticalConfiguration] = useState('');
  const [totalWidth, setTotalWidth] = useState('');
  const [SectionOneTopHeigth, setSectionOneTopHeigth] = useState('');
  const [SectionTwoBottomWidth, setSectionTwoBottomWidth] = useState('');
  const [DeadMold, setDeadMold] = useState('');
  const [Buildout, setBuildout] = useState('');
  const [FillerStrip, setFillerStrip] = useState('');
  const [SplitTilt, setSplitTilt] = useState('');
  const [SplitRail, setSplitRail] = useState('');
  const [TPost, setTPost] = useState('');
  const [obstructions, setObstructions] = useState('');
  const [multiple, setMultiple] = useState('');
  const [rolldirection, setRollDirection] = useState('');
  const [AddNote, setAddNote] = useState('');
  const [AddNoteforBuildOut, setAddNoteforBuildOut] = useState('');
  const [AddNoteforFillerStrip, setAddNoteforFillerStrip] = useState('');
  const [AddNoteforSplitTilt, setAddNoteforSplitTilt] = useState('');
  const [AddNoteforSplitRail, setAddNoteforSplitRail] = useState('');
  const [quickapply, setQuickApply] = useState(false);
  const [liftOpen, setLiftOpen] = useState(false);

  const [brandLabelProduct, setbrandLabelProduct] = useState([]);
  const [brandLabelProductType, setbrandLabelProductType] = useState([]);
  const [brandLabelProductTypeOpacity, setbrandLabelProductTypeOpacity] = useState([]);
  const [brandLabelProductTypeOpacityFabric, setbrandLabelProductTypeOpacityFabric] = useState([]);
  const [brandLabelProductTypeOpacityFabricLift, setBrandLabelProductTypeOpacityFabricLift] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirection, setbrandLabelProductTypeOpacityFabricLiftDirection] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrail, setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrail] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValance, setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValance] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeatures, setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeatures] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeaturesTrack, setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeaturesTrack] = useState([]);
  const [categorynames, setCategoryNames] = useState([]);
  const [livaluechangeflag, setLIValueChanged] = useState(false);

  const [optionalFinalizationFields, setOptionalFinalizationFields] = useState({});
  const [checkedItems, setCheckedItems] = useState();
  const [CoveringDetails, setCoveringDetails] = useState([]);
  const [CoveringSearchResults, setCoveringSearchResults] = useState([]);

  const [labelnames, setLabelNames] = useState([]);
  const [searchArr, setSearchArr] = useState([]);

  const [keywordsbySearch, setKeywordsbySearch] = useState([]);

  const [isEnabled, setIsEnabled] = useState(true);

  const [saveStatus, setSaveStatus] = useState(false);
  const [psf, setPSF] = useState('');
  const [updatedMeasurementTypeSpecificFactor, setUpdatedMeasurementTypeSpecificFactor] = useState('');
  const [proposalAdditionalSKU, setProposalAdditionalSKU] = useState([]);
  const [defaultProposalAdditionalSKU, setdefaultProposalAdditionalSKU] = useState([]);
  const [othermeasurement, setOtherMeasurement] = useState('');
  const [description, setDescription] = useState('');
  const [allOptionSelectedStatus, setAllOptionSelectedStatus] = useState(false);
  const [allOptionNPSSelectedStatus, setAllOptionNPSSelectedStatus] = useState(false);

  // Error Handling
  const [ProposalByProposalIdErrorAlert, setProposalByProposalIdErrorAlert] = useState(false);
  const [ProposalByProposalIdErrorMsg, setProposalByProposalIdErrorMsg] = useState('');

  //  hover states
  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    init();
  }, []);

  useEffect(() => {
    getProposalDetailsNotes();
  }, [proposalDetailsId]);

  const init = async () => {
    await getVerified();
    await getProposalByProposalId();
    await getWindowDetails();
    await getWindowCoveringProposalDetails();
    await getWindowNotes();
    await getProposalDetailsNotes();
  };

  useEffect(() => {
    proposalAdditionalSKU.forEach((item) => {
      if (item.type === 'Vertical Configuration') {
        setVerticalConfiguration(item.sku_name);
        const { SectionOneTop, SectionTwoBottom } = JSON.parse(item.additional_detail);
        setSectionOneTopShow(SectionOneTop ? SectionOneTop : '');
        setSectionTwoBottomShow(SectionTwoBottom ? SectionTwoBottom : '');
      }
    });
  }, [proposalAdditionalSKU]);
  const fetchdata = (
    drawerstatus,
    wcc,
    brand,
    label,
    product,
    type,
    opacity,
    fabric,
    lift,
    direction,
    option,
    showtable,
    newProductDescription,
    ProductDescription,
    newSKUCombinations,
    newselectedFabricSKU,
    newselectedLiftSytemSKU,
    SelectedOptionSKU,
    newshowInTable,
    clearfilters,
    showPD,
    showSKU,
    windowData,
    measurementType,
    outgoingwindow_width,
    outgoingwindow_height,
    existingCovering,
    mountsurface,
    buildType,
    ladder,
    OptionalFinalizationFields,
    selectedOptionJSON,
    checkedItems,
    saveStatus,
    size,
    psf,
    proposalAdditionalSKU,
    AllOptionSelectedStatus,
    AllOptionNPSSelectedStatus,
    quickapply,
    windowdepth,
    othermeasurement,
    obstructions,
    description,
    VerticalConfiguration,
    SectionOneTopShow,
    SectionTwoBottomShow,
    headrail,
    section1,
    section2,
    section3,
    section4,
    section5,
    section6
  ) => {
    if (clearfilters) {
      reset();
    }
    if (quickapply) {
      setQuickApply(false);
    }
    setShowWindowDepth(windowdepth);
    setotherMeasurement(othermeasurement);
    setshowObstructions(obstructions);
    setshowdescription(description);
    setshowVerticalConfig(VerticalConfiguration);
    setHeadrail(headrail);
    setSelectedOptionJSON({ ...selectedOptionJSON });
    setSaveStatus(saveStatus);
    setProposalAdditionalSKU(!proposalAdditionalSKU ? proposalAdditionalSKU : defaultProposalAdditionalSKU);

    setAllOptionSelectedStatus(AllOptionSelectedStatus);
    setAllOptionNPSSelectedStatus(AllOptionNPSSelectedStatus);

    if (wcc && brand && label && product && type && opacity && fabric) {
      setShowTable(true);
    }
    setWCC(wcc);
    setBrand(brand);
    setLabel(label);
    setProduct(product);
    setType(type);
    setOpacity(opacity);
    setFabric(fabric);
    setLift(lift);
    setDirection(direction);
    setCheckedItems(checkedItems);
    setOption([...option]);
    setClearFilters(false);
    setProductDescriptions(ProductDescription);
    setSKUCombinations(newSKUCombinations.filter(unique));
    setSelectedFabricSKU(newselectedFabricSKU);
    setShowInTable(newshowInTable);
    setSelectedLiftSytemSKU(newselectedLiftSytemSKU);
    setSelectedOptionSKU(SelectedOptionSKU);
    setOptionalFinalizationFields(OptionalFinalizationFields || {});
    setExistingCovering(existingCovering);
    if (measurementType !== 'Roller Shades' && buildType === 'Fabric') {
      setBuildType('');
    } else {
      setBuildType(buildType);
    }

    setMountsurface(mountsurface);
    setLadder(ladder);
    setMeasurementType(measurementType);

    setSize(fracty(outgoingwindow_width) + ' x ' + fracty(outgoingwindow_height));
    setOutgoingSizeW(outgoingwindow_width);
    setOutgoingSizeH(outgoingwindow_height);
    setUpdatedMeasurementTypeSpecificFactor(psf);
    setShowProductDescription(showPD);
    setShowSKUCombination(showSKU);
    setIsDrawerOpen(!drawerstatus);
  };

  useEffect(() => {
    // Is this causing a infinite loop?
    setSaveStatus(saveStatus);
  }, [saveStatus]);

  useEffect(() => {
    // Is this causing a infinite loop?
    setPSF(psf);
  }, [psf]);

  useEffect(() => {
    function containsEmptyValues(arr) {
      return arr.length ? arr.some((value) => value === ' ' || value === '') : true;
    }
    setConfirmSalesOrderStatus(() => !containsEmptyValues(Object.values(optionalFinalizationFields)));

    // setMarkAsConfirmSalesOrderStatus(false);
  }, [optionalFinalizationFields]);

  useEffect(() => {
    setConfirmable(confirmSalesOrderStatus && allOptionNPSSelectedStatus);
  }, [confirmSalesOrderStatus, allOptionNPSSelectedStatus]);

  const filteredCoveringsDetails = {
    wcc: wcc,
    brand: brand,
    label: label,
    product: product,
    type: type,
    opacity: opacity,
    fabric: fabric,
    lift: lift,
    direction: direction,
    option: option,
    ProductDescription: productDescription,
    SKUCombinations: SKUCombinations,
    showInTable: showInTable,
    SelectedFabricSKU: selectedFabricSku,
    SelectedOptionSKU: SelectedOptionSKU,
    SelectedLiftSytemSKU: selectedLiftSytemSku,
    sendmsg: sendmsg,
    windowData: [windowData],
    OptionalFinalizationFields: optionalFinalizationFields || {},
    selectedOptionJSON: selectedOptionJSON,
    proposalSpecificFactor: SelectedWindow[0]?.proposal_specific_factor,
    checkedItems: checkedItems,
    clearfilters: clearfilters,
    existingCovering: existingCovering,
    buildType: buildType,
    measurementType: measurementType,
    mountsurface: mountSurface,
    ladder: ladder,
    outgoingwindow_width: outgoingwindow_width,
    outgoingwindow_height: outgoingwindow_height,
    size: size,
    psf: psf,
    proposalAdditionalSKU: proposalAdditionalSKU,
    SelectedWindow: SelectedWindow,
    WindowConfiguration: Panel,
    Windowsill: Windowsill,
    VerticalConfiguration: VerticalConfiguration,
    HeightForVerticalConfiguration: HeightForVerticalConfiguration,
    SectionOneTopHeigth: SectionOneTopHeigth,
    SectionTwoBottomWidth: SectionTwoBottomWidth,
    RSWindowConfiguration: multiple,
    totalWidth: totalWidth,
    PDnew: [],
    quickapply: quickapply,
    windowdepth: windowdepth,
    obstructions: obstructions,
  };

  const vertical = 'top';
  const horizontal = 'center';

  let windowsetnull = [];
  let coveringdetails = location.state.coveringdetails ? location.state.coveringdetails : coveringdetail;
  let windowname = [];
  let appliedcoveringswindowname = [];

  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const applyCoveringOptions = async () => {
    const proposal_additional_sku = generateProposalAdditionalSkus(windowDetails);

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: location.state.proposalID,
        window_set_id: location.state.data.window_set_id,
        windows: SelectedWindow,
        proposal_window_width: windowDetails.width,
        proposal_window_height: windowDetails.height,
        measurement_type: windowDetails.measurementType.service_name,
        build_type: windowDetails.buildType.service_name,
        existing_covering: windowDetails.existingCoverings.service_name,
        mount_surface: windowDetails.mountSurface.service_name,
        ladder: windowDetails.ladder.service_name,
        sku: SKUCombinations,
        measurement_type: windowDetails.measurementType,
        description: productDescription,
        direction: direction,
        option: option,
        installation: install,
        windowCoveringCategory: wcc,
        confirm_to_sales_order: confirmed,
        OptionalFinalizationFields: optionalFinalizationFields,
        measurement_type_specific_factor: {},
        proposal_additional_sku,
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/pm/ApplyCoverings`, requestOptions);
    const data = await response.json();

    if (!data.error) {
      let windowsselectedforcovering = [];
      let coverings_applied_windows = [];

      data.proposalDetails.forEach((element) => {
        if (element.window_selected === true) {
          windowsselectedforcovering.push(element);
        }
        if (element.covering_selected === true) {
          coverings_applied_windows.push(element);
        }
        if (element.covering_selected === true && element.covering_status === 'applied') {
          appliedcoveringswindowname.push(element.window_name);

          setAllreadyCoveringAppliedWindows(appliedcoveringswindowname.toString());

          setSuccessMsg(true);
        } else if (element.covering_selected === true && element.covering_status === 'not_applied') {
          windowname.push(element.window_name);
          setCoveringnotAppliedtoWindows(windowname.toString());
          setErrorMsg(true);
        }
      });

      if (windowsselectedforcovering.length === coverings_applied_windows.length) {
        contextvalue.setSuccesswindows(true);
        contextvalue.setFailurewindows(true);
        navigate(`/tbm/ps/proposal/${location.state.proposalID}/details`, {
          state: {
            selectedwindows: selectedwindowsIDs,
            customerdata: windowsetdata,
            totalselectedwindowsforcoverings: data.covering_proposal_details,
            proposalID: location.state.proposalID,
            proposalName: proposalName,
            totalwindows: location.state.totalwindows,
            windowsselectednotnull: windowsetnull,
            jsonData: jsonData,
            coveringdetails: coveringdetails,
            successwindownames: appliedcoveringswindowname,
            failurewindownames: windowname,
            alerts: data.proposalCoverings,
          },
        });
      }
    }
  };

  function generateProposalAdditionalSkus(details = {}) {
    const proposalAdditionalSkus = [
      {
        label: '',
        type: 'Window Depth',
        sku_name: details.windowDepth.service_name,
        additional_charge_sku: details.windowDepth.sku,
        additional_detail: details.windowDepthMeasurement,
      },
      {
        label: '',
        type: 'Window Configuration',
        sku_name: details.windowConfiguration.service_name,
        additional_charge_sku: details.windowConfiguration.sku,
        additional_detail: JSON.stringify(details.sections),
      },
      {
        label: '',
        type: 'Existing Coverings',
        sku_name: details.existingCoverings.service_name,
        additional_charge_sku: details.existingCoverings.sku,
        additional_detail: null,
      },
      {
        label: '',
        type: 'Build Type',
        sku_name: details.buildType.service_name,
        additional_charge_sku: details.buildType.sku,
        additional_detail: null,
      },
      {
        label: '',
        type: 'Mount Surface',
        sku_name: details.mountSurface.service_name,
        additional_charge_sku: details.mountSurface.sku,
        additional_detail: null,
      },
      {
        label: '',
        type: 'Obstructions',
        sku_name: details.obstructions.service_name,
        additional_charge_sku: details.obstructions.sku,
        additional_detail: JSON.stringify(details.obstructionsDetails?.split(';')),
      },
      {
        label: '',
        type: 'Ladder',
        sku_name: details.ladder.service_name,
        additional_charge_sku: details.ladder.sku,
        additional_detail: null,
      },
    ];

    if (details.measurementType === 'Shutters') {
      proposalAdditionalSkus.push(
        {
          label: '',
          type: 'Vertical Configuration',
          sku_name: details.verticalConfigurations.service_name,
          additional_charge_sku: details.verticalConfigurations.sku,
          additional_detail: JSON.stringify(details.verticalSections),
        },
        {
          label: '',
          type: 'Window Sill',
          sku_name: details.windowSill.service_name,
          additional_charge_sku: details.windowSill.sku,
          additional_detail: null,
        }
      );
    }

    return proposalAdditionalSkus;
  }

  let checkedrowdata = [];
  let selectedwindowsIDs = [];

  // get state from proposalSelectWindow page
  selectedwindowsIDs = location.state.selectedwindows;
  checkedrowdata = selectedwindowsIDs;

  let totalrowdata = [];
  totalrowdata = location.state.totalselectedwindows;
  let windowsetdata = [];
  windowsetdata = location.state.data;

  const unique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const reset = () => {
    setClearFilters(true);
    setValue([]);
    setSearchArr([]);
    setCoveringSearchResults([]);
    setWCC('');
    setDisableButton(true);
    setBrand('');
    setProduct('');
    setLabel('');
    setType('');
    setOpacity('');
    setFabric('');
    setLift('');
    setDirection('');
    setOption([]);
    setFeatures('');
    setTrack('');
    setDesigner(0);
    setSelectedSKU('');
    setProductDescriptions([]);
    setSKUCombinations([]);
    setSelectedFabricSKU('');
    setSelectedLiftSytemSKU('');
    setSelectedOptionSKU([]);
    setCheckedItems();
    setSelectedOptionJSON({});
    setReqOptionDetails([]);
    setNotReqOptionDetails([]);
    setOptionalFinalizationFields({});
    setConfirmed(false);
    setBrandLabel([]);
    setbrandLabelProduct([]);
    setbrandLabelProductType([]);
    setbrandLabelProductTypeOpacity([]);
    setbrandLabelProductTypeOpacityFabric([]);
    setBrandLabelProductTypeOpacityFabricLift([]);
    setbrandLabelProductTypeOpacityFabricLiftDirection([]);
    setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrail([]);
    setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValance([]);
    setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeatures([]);
    setBrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeaturesTrack([]);
    setShowTable(false);
    setShowInTable([]);
    setSKUCombinations([]);
    setProductDescriptions([]);
    setClearFilters(false);
    setConfirmSalesOrderStatus(false);
    setAllOptionNPSSelectedStatus(false);
    setConfirmable(false);
  };

  const cancel = () => {
    setSendmsg(true);
    setTimeout(() => {
      setSendmsg(false);
      navigate(-1);
    }, 500);
  };

  const handleClose = () => {
    setSaveStatus(false);
  };

  const handleContinue = async () => {
    setSaveStatus(false);
    setIsDrawerOpen(false);
    await getWindowCoveringProposalDetails();
  };

  let searchedArr = [];
  let checkSubset = (parentArray, subsetArray) => {
    return subsetArray.every((el) => {
      return parentArray.includes(el);
    });
  };

  useEffect(() => {
    if (coveringdetail !== undefined) {
      setCoveringDetails(coveringdetail);
    }

    setCoveringCategory(wcc);
  }, [brand, wcc]);

  useEffect(() => {
    if (coveringdetail) {
      setCoveringDetails(coveringdetail);
    }
  }, [coveringdetail]);

  useEffect(() => {
    if (value.length > 0) {
      setKeywordsbySearch(value);
    }
    if (value.length === 0) {
      setWCC('');
      setBrand('');
      setLabel('');
      setProduct('');
      setType('');
      setOpacity('');
      setFabric('');
      setShowSKUCombination(false);
      setProductDescriptions([]);
      setShowProductDescription(false);
      setIsEnabled(true);
      setKeywordsbySearch([]);
    }
  }, [value]);

  useEffect(() => {
    var filteredKeywords = [];
    if (keywordsbyFilter.length > 0 && keywordsbySearch.length > 0) {
      let filteredVal = keywordsbyFilter.filter((word) => keywordsbySearch.includes(word));
      if (filteredVal.length > 0) {
        setKeywordsbyFilter([]);
        setKeywordsbySearch([]);
        filteredKeywords = filteredVal;
      } else {
        filteredKeywords = keywordsbySearch;
      }
    } else if (keywordsbyFilter.length === 0 && keywordsbySearch.length > 0) {
      filteredKeywords = keywordsbySearch;
    } else if (keywordsbyFilter.length > 0 && keywordsbySearch.length === 0) {
      filteredKeywords = keywordsbyFilter;
    } else {
    }
    if (filteredKeywords.length > 0) {
      handleSearch(filteredKeywords);
    }
  }, [keywordsbySearch, keywordsbyFilter]);

  const handleSearch = (searchedItems) => {
    if (searchedItems.length === 0) {
      setProductDescriptions([]);
      setSKUCombinations([]);
    }

    setSearchArr(searchedItems);
    setCoveringDetails(coveringdetail);
    searchedArr = coveringdetail;

    let materialfinalarr = [];
    let labelsforspecificliftSystem = [];
    let materialarr = [];
    let listsystemarr = [];
    let liftsystems = [];
    let directions = [];
    let optionsarr = [];
    searchedArr.forEach((element, index) => {
      if (element.sku_type === 'M') {
        materialarr.push(element);
      } else if (element.sku_type === 'L') {
        listsystemarr.push(element);
        liftsystems.push(element.value_1);
        directions.push(element.value_2);
      } else if (element.sku_type === 'P') {
        optionsarr.push(element);
      }
      let status = checkSubset(searchedArr[index].keywords, searchedItems);

      if (status === true && searchedArr[index].sku_type === 'M') {
        materialfinalarr.push(searchedArr[index]);
      }
    });

    materialfinalarr = materialfinalarr.filter(unique);
    setCoveringSearchResults(materialfinalarr);
    setShowInTable(materialfinalarr);

    let mskus = [];
    if (materialfinalarr.length === 1) {
      setProductDescriptions([materialfinalarr[0].description]);
      setSKUCombinations([materialfinalarr[0].sku]);
      mskus = [materialfinalarr[0].sku];

      if (materialfinalarr.length === 1 && lift && !direction) {
        let liftSystemPD = [liftOpen];
        setProductDescriptions([materialfinalarr[0].description, ...liftSystemPD]);
        setSKUCombinations([materialfinalarr[0].sku]);
        setShowProductDescription(true);
      }
      if (materialfinalarr.length === 1 && lift && direction) {
        let liftSystemPD = [lift, direction];
        setProductDescriptions([materialfinalarr[0].description, ...liftSystemPD]);
        setSKUCombinations([materialfinalarr[0].sku, selectedLiftSytemSku]);
        setShowProductDescription(true);
      }
      if (materialfinalarr.length === 1 && option.length > 0) {
        let optionPD = [...option];
        setProductDescriptions([materialfinalarr[0].description, ...optionPD]);
        setSKUCombinations([materialfinalarr[0].sku, ...SelectedOptionSKU]);
        setShowProductDescription(true);
      }
      if (materialfinalarr.length === 1 && lift && direction && option.length > 0) {
        let liftSystemPD = [lift, direction];
        let optionPD = [...option];
        setProductDescriptions([materialfinalarr[0].description, ...liftSystemPD, ...optionPD]);
        setSKUCombinations([materialfinalarr[0].sku, selectedLiftSytemSku, ...SelectedOptionSKU]);
        setShowProductDescription(true);
      }
    }
    liftsystems = liftsystems.filter(unique);
    setLiftSystem(liftsystems);
    directions = directions.filter(unique);
    setbrandLabelProductTypeOpacityFabricLiftDirection(directions);
    if (materialfinalarr.length === 1) {
      let arrsku = [];
      setShowSKUCombination(true);

      setSelectedFabricSKU(materialfinalarr[0].sku);
      setShowProductDescription(true);

      if (materialfinalarr[0].sku) {
        setIsEnabled(false);
      } else {
        setIsEnabled(true);
      }
    }
  };

  let FilteredCovering_Category = [];
  let FilteredBrand = [];
  let FilteredLabel = [];
  let FilteredProduct = [];
  let FilteredType = [];
  let FilteredOpacity = [];
  let FilteredFabric = [];

  useEffect(() => {
    let keywordsArr = [];
    if (CoveringDetails !== undefined && CoveringDetails.length > 0) {
      CoveringDetails.forEach((element) => {
        if (element.keywords) {
          keywordsArr.push(...element.keywords);
        }
      });
      if (CoveringSearchResults.length > 0) {
        CoveringSearchResults.forEach((element, i) => {
          FilteredCovering_Category.push(element.covering_category);
          FilteredBrand.push(element.brand);
          FilteredLabel.push(element.label);
          FilteredProduct.push(element.product);
          FilteredType.push(element.type);
          FilteredOpacity.push(element.opacity);
          FilteredFabric.push(element.material);
        });
        FilteredCovering_Category = FilteredCovering_Category.filter(unique);
        FilteredBrand = FilteredBrand.filter(unique);
        FilteredProduct = FilteredProduct.filter(unique);
        FilteredLabel = FilteredLabel.filter(unique);
        FilteredType = FilteredType.filter(unique);
        FilteredOpacity = FilteredOpacity.filter(unique);
        FilteredFabric = FilteredFabric.filter(unique);

        if (FilteredCovering_Category.length === 1) {
          setWCC(FilteredCovering_Category[0]);
          setCategoryNames([FilteredCovering_Category[0]]);
        }

        if (FilteredBrand.length === 1) {
          setBrand(FilteredBrand[0]);
          setBrands([FilteredBrand[0]]);
        }
        if (FilteredBrand.length > 1) {
          setBrand('');
          setBrands(FilteredBrand);
        }

        if (FilteredCovering_Category.length > 0) {
          setCategoryNames(FilteredCovering_Category);
        }

        if (FilteredProduct.length === 1) {
          setProduct(FilteredProduct[0]);
          setbrandLabelProduct([FilteredProduct[0]]);
        }
        if (FilteredProduct.length > 1) {
          setProduct('');
          setbrandLabelProduct(FilteredProduct);
        }
        if (FilteredLabel.length === 1) {
          setLabel(FilteredLabel[0]);
          setBrandLabel([FilteredLabel[0]]);
        }
        if (FilteredLabel.length > 1) {
          setLabel('');
          setBrandLabel(FilteredLabel);
        }
        if (FilteredType.length === 1) {
          setType(FilteredType[0]);
          setbrandLabelProductType([FilteredType[0]]);
        }
        if (FilteredType.length > 1) {
          setType('');
          setbrandLabelProductType(FilteredType);
        }
        if (FilteredOpacity.length === 1) {
          setOpacity(FilteredOpacity[0]);
          setbrandLabelProductTypeOpacity([FilteredOpacity[0]]);
        }
        if (FilteredOpacity.length > 1) {
          setOpacity('');
          setbrandLabelProductTypeOpacity(FilteredOpacity);
        }
        if (FilteredFabric.length === 1) {
          setFabric(FilteredFabric[0]);
          setbrandLabelProductTypeOpacityFabric(FilteredFabric);
        }
        if (FilteredFabric.length > 1) {
          setFabric('');
          setSelectedFabricSKU('');
          setShowSKUCombination(false);
          setShowProductDescription(false);
          setbrandLabelProductTypeOpacityFabric(FilteredFabric);
        }
      }
    }
    if (CoveringSearchResults.length === 0) {
      if (CoveringDetails !== undefined && CoveringDetails.length > 0) {
        setChooseFilters(CoveringDetails);

        const covering_category = [];
        const Brand = [];
        const Label = [];
        const Product = [];
        const Type = [];
        const Opacity = [];
        const Fabric = [];
        const LiftSystems = [];
        const Directions = [];
        const LiftSystemDetails = [];
        const OptionsAvailable = [];
        const OptionsAvailableDetails = [];
        CoveringDetails.forEach((element, i) => {
          covering_category.push(element.covering_category);
        });
        CoveringDetails.forEach((element, i) => {
          Brand.push(element.brand);
        });
        CoveringDetails.forEach((element, i) => {
          Label.push(element.label);
        });
        CoveringDetails.forEach((element, i) => {
          Product.push(element.product);
        });
        CoveringDetails.forEach((element, i) => {
          if (element.type !== null) {
            Type.push(element.type);
          }
        });
        CoveringDetails.forEach((element, i) => {
          if (element.sku_type === 'M') {
            Opacity.push(element.opacity);
          }
        });
        CoveringDetails.forEach((element, i) => {
          if (element.sku_type === 'M') {
            Fabric.push(element.fabric);
          }
        });
        CoveringDetails.forEach((element, i) => {
          if (element.sku_type === 'L') {
            if (lift) {
              if (element.value_1 === lift) {
                Directions.push(element.value_2);
              }
            } else {
              Directions.push(element.value_2);
            }
            if (direction) {
              if (element.value_2 === direction) {
                LiftSystems.push(element.value_1);
              }
            } else {
              LiftSystems.push(element.value_1);
            }
            LiftSystemDetails.push(element);
          }
        });
        CoveringDetails.forEach((element, i) => {
          if (element.sku_type === 'P') {
            OptionsAvailable.push(element.value_1);
            OptionsAvailableDetails.push(element);
          }
        });

        setCategoryNames(covering_category.filter(unique));
        setLabelNames(Label.filter(unique));
        setBrandLabel(Label.filter(unique));
        setBrands(Brand.filter(unique));

        setbrandLabelProduct(Product.filter(unique));
        setbrandLabelProductType(Type.filter(unique));

        setLiftSystem(LiftSystems.filter(unique));
        setLiftSytemDetails(LiftSystemDetails.filter(unique));
        setSelectedLiftSytemDetails(LiftSystemDetails.filter(unique));
        setbrandLabelProductTypeOpacityFabricLiftDirection(Directions.filter(unique));
        setbrandLabelProductTypeOpacity(Opacity.filter(unique));
        setbrandLabelProductTypeOpacityFabric(Fabric.filter(unique));
        setOptionName(OptionsAvailable.filter(unique));
        setSelectedOptionDetails(OptionsAvailableDetails.filter(unique));
        if (brand && product && label && type) {
        }
      }
    }
  }, [CoveringSearchResults, CoveringDetails]);

  const checkLift = (newval) => {
    if (newval !== lift) {
      setLIValueChanged(true);
    } else {
      setLIValueChanged(false);
    }
  };

  // store selected window data and selected (brand,label,product,type) data in json format start
  let jsonData = { brand, label, product, type };

  const [notes, setNotes] = useState(false);
  const [notesContent, setnotesContent] = useState([]);
  const [notesSuccessMsg, setNotesSuccessMsg] = useState(false);
  const [notesAlertMsg, setNotesAlertMsg] = useState('');

  const getProposalByProposalId = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };
    // Created Proposal will be added to the database through CreateProposal API
    const response = await fetch(`${CONFIG.API_URL}/pm/ProposalByProposalId/${location.state.proposalID}`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      setProposalByProposalIdErrorMsg(data.error_message);
      setProposalByProposalIdErrorAlert(true);
      return;
    }
    setProposalName(data.proposal.proposal_name);
    windowsetnull = data.proposal_details;
  };

  // Get window details
  const getWindowDetails = async () => {
    const token = sessionStorage.getItem('token');
    const options = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', token: token },
    };
    const response = await fetch(
      `${CONFIG.API_URL}/pm/window-covering-proposal-details/window?${new URLSearchParams({
        proposal_id: location.state.proposalID,
        windows: [location.state.row_id],
      })}`,
      options
    );
    const { error, details, notes } = await response.json();
    if (!response.ok || !details.length || error) {
      return console.error(response.status, 'Error fetching window details');
    }

    const { proposal_measurement_type, proposal_width, proposal_height, proposal_additional_sku, proposal_measurement_type_specific_factor } = details[0];

    const existingCoveringSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Existing Covering')[0];
    const ladderSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Ladder')[0];
    const mountSurfaceSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Mount Surface')[0];
    const windowConfigurationSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Window Configuration')[0];
    const windowDepthSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Window Depth')[0];
    const obstructionsSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Obstructions')[0];
    const buildTypeSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Build Type')[0];
    const verticalConfigurationSku = proposal_additional_sku.filter((skuDetails) => skuDetails.type === 'Vertical Configuration')[0];

    windowDispatch({
      action: 'SET_ALL',
      payload: {
        ids: [location.state.row_id],
        measurementType: proposal_measurement_type,
        width: proposal_width,
        height: proposal_height,
        size: `${fracty(proposal_width)}" X ${fracty(proposal_height)}"`,
        windowDepth: {
          sku: windowDepthSku?.additional_charge_sku,
          service_name: windowDepthSku?.sku_name,
        },
        windowDepthMeasurement: parseFloat(windowDepthSku?.additional_detail),
        windowConfiguration: {
          sku: windowConfigurationSku?.additional_charge_sku,
          service_name: windowConfigurationSku?.sku_name,
        },
        sections: windowConfigurationSku ? JSON.parse(windowConfigurationSku.additional_detail) : null,
        existingCoverings: {
          sku: existingCoveringSku.additional_charge_sku,
          service_name: existingCoveringSku.sku_name,
        },
        ladder: {
          sku: ladderSku.additional_charge_sku,
          service_name: ladderSku.sku_name,
        },
        mountSurface: {
          sku: mountSurfaceSku.additional_charge_sku,
          service_name: mountSurfaceSku.sku_name,
        },
        obstructions: {
          sku: obstructionsSku?.additional_charge_sku,
          service_name: obstructionsSku?.sku_name,
        },
        obstructionsDetails: obstructionsSku?.additional_detail ? JSON.parse(obstructionsSku?.additional_detail).join(';') : null,
        buildType: {
          sku: buildTypeSku?.additional_charge_sku,
          service_name: buildTypeSku?.sku_name,
        },
        windowSill: proposal_measurement_type_specific_factor.Windowsill
          ? {
              sku: proposal_measurement_type_specific_factor.Windowsill === 'No' ? 0 : 1,
              service_name: proposal_measurement_type_specific_factor.Windowsill,
            }
          : { sku: '', service_name: '' },
        verticalConfigurations: {
          sku: verticalConfigurationSku?.additional_charge_sku,
          service_name: verticalConfigurationSku?.sku_name,
        },
        verticalSections: verticalConfigurationSku ? JSON.parse(verticalConfigurationSku.additional_detail) : null,
      },
    });
  };

  const getWindowCoveringProposalDetails = async () => {
    const token = sessionStorage.getItem('token');
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', token: token },
    };
    const response = await fetch(
      `${CONFIG.API_URL}/pm/WindowCoveringProposalDetails?${new URLSearchParams({
        proposal_id: location.state.proposalID,
        window_ids: [location.state.row_id],
      })}`,
      options
    );
    const { error, ...data } = await response.json();
    if (!response.ok || error) {
      return;
    }

    setSelectedWindow(data.details);
    setConfirmed(data.details[0].confirm_to_sales_order);
    setProposalAdditionalSKU(data.details[0].proposal_additional_sku);
    setdefaultProposalAdditionalSKU(data.details[0].proposal_additional_sku);
    setHeightForVerticalConfiguration(data.details[0].proposal_window_height);
    setTotalWidth(data.details[0].proposal_window_width);
    setSectionOneTopHeigth(data.details[0].proposal_measurement_type_specific_factor.SectionOneTop);
    setSectionTwoBottomWidth(data.details[0].proposal_measurement_type_specific_factor.SectionTwoBottom);

    setShowWindowDepth(data.details[0].proposal_measurement_type_specific_factor.WindowDepth);
    setshowObstructions(data.details[0].proposal_measurement_type_specific_factor.Obstructions);

    setShowSKUCombination(true);

    setProposalDetailsId(data.details[0].proposal_detail_id);
    setProposalLineItemName(data.details[0].window_name);
    setnotesContent(data.proposal_detail_notes);
    setWindowData(data.details[0]);

    setCoveringStatus(data.details[0].covering_status);
    setCoveringType(data.details[0].covering_type);
    setCoveringSelected(data.details[0].covering_selected);
    setProductDescriptions(data.details[0].material_code);
    setSKUCombinations(data.details[0].sku);
    setCoveringCategory(data.details[0].covering_category);

    setMeasurementType(data.details[0].proposal_measurement_type);
    setSelectedLiftSytemSKU(data.details[0].lift_system_sku);
    const { sku_details, proposal_specific_factor } = data.details[0];

    const Material_SKU_details = sku_details.filter((sku) => sku && sku.sku_type === 'M')[0];
    if (Material_SKU_details) {
      setSelectedFabricSKU(Material_SKU_details.sku);
      setWCC(Material_SKU_details.covering_category);
      setBrand(Material_SKU_details.brand);
      setProduct(Material_SKU_details.product);
      setLabel(Material_SKU_details.label);
      setType(Material_SKU_details.type);
      setOpacity(Material_SKU_details.opacity);
      setFabric(Material_SKU_details.fabric);

      setSelectedFabricSKU(Material_SKU_details.sku);
      setSelectedFabricSKUName(Material_SKU_details.sku_name);
    }

    const LiftSystem_SKU_details = sku_details.filter((sku) => sku && sku.sku_type === 'L')[0];
    if (LiftSystem_SKU_details) {
      setSelectedLiftSytemSKU(LiftSystem_SKU_details.sku);
      setLift(LiftSystem_SKU_details.value_1);
      setDirection(LiftSystem_SKU_details.value_2);
    }

    const Option_SKU_details = sku_details.filter((sku) => sku && sku.sku_type === 'P');

    setSelectedOptionJSON({
      ...Option_SKU_details.reduce((acc, { value_2, sku_name, sku, pricing_status }) => {
        acc[value_2] = { key: value_2, name: sku_name, sku, pricing_status };
        return acc;
      }, {}),
      ...proposal_specific_factor,
    });
    setOptionalFinalizationFields({
      ...Option_SKU_details.reduce((acc, { value_2, sku_name, sku, pricing_status }) => {
        acc[value_2] = { key: value_2, name: sku_name, sku, pricing_status };
        return acc;
      }, {}),
      ...proposal_specific_factor,
    });
    setOption(Option_SKU_details.map((option) => option.sku_name));
    setOutOption(Option_SKU_details.map((option) => option.sku_name));

    if (data.details[0].lift_system_name) {
      setLift(data.details[0].lift_system_name);
      checkLift(data.details[0].lift_system_name);

      const filteredDirection = SelectedLiftSytemDetails.filter((element, i) => {
        if (element.sku_name === data.details[0].lift_system_name) {
          return element.direction;
        }
      });
      setbrandLabelProductTypeOpacityFabricLiftDirection(filteredDirection);
    }
  };

  useEffect(() => {
    let proposal_specific_factor = {};
    switch (measurementType) {
      case 'Blinds':
        proposal_specific_factor = {
          WindowDepth: windowdepth,
          WindowConfiguration: headrail,
          Obstructions: obstructions,
          Description: description,
          Othermeasurement: othermeasurement,
        };
        break;
      case 'Quick':
        proposal_specific_factor = {
          WindowDepth: windowdepth,
          Obstructions: obstructions,
          Othermeasurement: othermeasurement,
          Description: description,
        };
        break;
      case 'Soft Shades':
        proposal_specific_factor = {
          WindowConfiguration: headrail,
          WindowDepth: windowdepth,
          Obstructions: obstructions,
          Description: description,
          Othermeasurement: othermeasurement,
        };
        break;
      case 'Roller Shades':
        proposal_specific_factor = {
          WindowConfiguration: headrail,
          WindowDepth: windowdepth,
          Obstructions: obstructions,
          Description: description,
          Othermeasurement: othermeasurement,
        };
        break;
      case 'Shutters':
        if (SplitTilt === 'No') {
          setAddNoteforSplitTilt('');
        }
        if (SplitRail === 'No') {
          setAddNoteforSplitRail('');
        }
        proposal_specific_factor = {
          WindowConfiguration: headrail,
          VerticalConfiguration: VerticalConfiguration,
          Windowsill: Windowsill,
          DeadMold: DeadMold,
          Buildout: Buildout,
          FillerStrip: FillerStrip,
          SplitTilt: SplitTilt,
          SplitRail: SplitRail,
          AddNoteforBuildOut: AddNoteforBuildOut,
          AddNoteforFillerStrip: AddNoteforFillerStrip,
          AddNoteforSplitTilt: AddNoteforSplitTilt,
          AddNoteforSplitRail: AddNoteforSplitRail,
          TPost: TPost,
          WindowDepth: windowdepth,
          Obstructions: obstructions,
          Description: description,
          Othermeasurement: othermeasurement,
        };
        break;
      default:
        proposal_specific_factor = {};
        break;
    }

    setPSF(proposal_specific_factor);
  }, [measurementType, windowdepth, headrail, obstructions, Panel, VerticalConfiguration, Windowsill, DeadMold, Buildout, FillerStrip, SplitTilt, SplitRail, AddNoteforBuildOut, AddNoteforFillerStrip, AddNoteforSplitTilt, AddNoteforSplitRail, TPost, othermeasurement, description]);

  const closeDrawer = (name, drawerstatus, message) => {
    setNameDrawerOpen(drawerstatus);

    if (message === 'Window is updated successfully!') {
      setUpdateWindownameOpen(true);
      setClassType('Success2 mainAlert');
      setSuccessMessage('Window name is updated successfully!');
      setWindowName(name);
    } else if (message === 'Window name already exists.') {
      setUpdateWindownameOpen(true);
      setClassType('Error mainAlert');
      setSuccessMessage('Window name already exists.');
    } else {
      setUpdateWindownameOpen(false);
      setClassType('');
    }
  };

  const [isFilterBtnHovered, setIsFilterBtnHovered] = useState(false);
  const hoverClearFilterBtn = () => setIsFilterBtnHovered(!isFilterBtnHovered);

  const [isCancelBtnHovered, setIsCancelBtnHovered] = useState(false);
  const hoverCancelBtn = () => setIsCancelBtnHovered(!isCancelBtnHovered);

  const appliedWindows = location.state.totalWindows.filter((window) => window.covering_status === 'applied');

  // const handleCoveringinProposal = (coveringData) => {
  //   setQuickApply(true);
  //   reset();

  //   const materials = coveringData.sku_details.filter((sku) => sku.sku_type === 'M');
  //   const liftDirections = coveringData.sku_details.filter((sku) => sku.sku_type === 'L');
  //   const options = coveringData.sku_details.filter((sku) => sku.sku_type === 'P');

  //   if (materials.length) {
  //     const material = materials[0];
  //     setWCC(material?.covering_category);
  //     setBrand(material?.brand);
  //     setLabel(material?.label);
  //     setProduct(material?.product);
  //     setType(material?.type);
  //     setFabric(material?.fabric);
  //     setOpacity(material?.opacity);
  //   }

  //   if (liftDirections.length) {
  //     const liftDirection = liftDirections[0];
  //     setSelectedLiftSytemSKU(liftDirection?.sku);
  //     setLift(liftDirection?.value_1);
  //     setDirection(liftDirection?.value_2);
  //   }

  //   if (options.length) {
  //     const optionDetails = {};
  //     const optionNamesArr = [];

  //     options.forEach((element) => {
  //       optionDetails[element.value_2] = {
  //         key: element.type,
  //         name: element.sku_name,
  //         sku: element.sku,
  //         pricing_status: element.pricing_status,
  //       };
  //       optionNamesArr.push(element.sku_name);
  //     });

  //     const optionDetailsArr = [optionDetails];

  //     setSelectedOptionJSON(...optionDetailsArr);
  //     setOption(optionNamesArr);
  //     setCheckedItems(optionNamesArr);
  //   }

  //   setShowSKUCombination(true);
  // };

  const commonConditions =
    size &&
    existingCovering &&
    buildType &&
    mountSurface &&
    ladder &&
    (showWindowDepth === 'Standard' || (showWindowDepth === 'Other' && otherMeasurement)) &&
    (showObstructions === 'None' || (showObstructions === 'Yes' && showDescription)) &&
    selectedFabricSku &&
    selectedLiftSytemSku &&
    allOptionSelectedStatus;

  const isShutter = measurementType === 'Shutters';

  const verticalConfigConditions = showVerticalConfig === '1 Section' || (showVerticalConfig === '2 Sections' && SectionOneTopShow && SectionTwoBottomShow);

  const isDisabled = !((!isShutter && commonConditions) || (isShutter && verticalConfigConditions && commonConditions));

  const [editingNote, setEditingNote] = useState(false);
  const [noteDrawerDetails, setNoteDrawerDetails] = useState({});
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);

  const [proposalNotes, setProposalNotes] = useState([]);
  const [proposalDetailsNotes, setProposalDetailsNotes] = useState([]);

  const closeNotesDrawer = async (alert) => {
    setNotesDrawerOpen(false);
    setEditingNote(false);
    setNoteDrawerDetails({});

    if (alert.success === 'Note added successfully!' || alert.success === 'Note updated successfully!') {
      await getWindowNotes();
      await getProposalDetailsNotes();
    }
  };

  const openNotesDrawer = (note) => {
    setEditingNote(!!note);
    setNoteDrawerDetails({
      name: windowName,
      referenceId: WindowId,
      noteId: note ? note.id : null,
    });
    setNotesDrawerOpen(true);
  };

  const openProposalDetailsNotesDrawer = async (note) => {
    setEditingNote(!!note);
    setNoteDrawerDetails({
      name: `Line Item for ${windowName}`,
      referenceId: proposalDetailsId,
      noteId: note ? note.id : null,
    });
    setNotesDrawerOpen(true);
  };

  const getWindowNotes = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/Notes/${WindowId}`, requestOptions);
    const data = await response.json();

    if (!data.error) {
      setProposalNotes(data.Notes);
    }
  };

  const getProposalDetailsNotes = async () => {
    if (!proposalDetailsId) {
      return;
    }

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/Notes/${proposalDetailsId}`, requestOptions);
    const data = await response.json();

    if (!data.error) {
      setProposalDetailsNotes(data.Notes);
    }
  };

  return (
    <ThemeProvider theme={outerTheme}>
      <Box sx={{ flexGrow: 1, marginBottom: '30px' }} p={0}>
        <div align='center'>
          <Stack spacing={2}>
            <Stack spacing={2} sx={{ paddingLeft: '20%' }}>
              <Snackbar className='snackbar_bar_postition' open={ProposalByProposalIdErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setProposalByProposalIdErrorAlert(false)}>
                <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img
                      onClick={() => {
                        setProposalByProposalIdErrorAlert(false);
                      }}
                      src={RedDrawerCancelIcon}
                      alt='CANCELICONPBPA'
                      className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                    />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img src={RedExclamationIcon} alt='CONFIRMICONPBPA' className='primary_icons_dimension alertImageMargin' />
                    <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{ProposalByProposalIdErrorMsg}</div>
                  </div>
                </Alert>
              </Snackbar>

              <Snackbar className='snackbar_bar_postition' open={openupdatewindowname} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setUpdateWindownameOpen(false)}>
                {classtype === 'Success2 mainAlert' ? (
                  <Alert className={classtype} variant='filled' icon={false}>
                    <>
                      <div className='alertCancel_2'>
                        <img
                          alt='CANCELICONUWNA'
                          onClick={() => {
                            setUpdateWindownameOpen(false);
                          }}
                          src={DrawerCancelIcon}
                          className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                        />
                      </div>
                      <div className='alertMain_2_4 confirmIconStyle1'>
                        <img alt='CONFIRMICONUWNA' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />
                        <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{successmessage}</div>
                      </div>
                    </>
                  </Alert>
                ) : (
                  <Alert className={classtype} variant='filled' icon={false}>
                    <>
                      <div className='alertCancel_2'>
                        <img
                          alt='CANCELICONREDUWNA'
                          onClick={() => {
                            setUpdateWindownameOpen(false);
                          }}
                          src={RedDrawerCancelIcon}
                          className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                        />
                      </div>
                      <div className='alertMain_2_4 confirmIconStyle1'>
                        <img alt='CONFIRMICOREDNUWNA' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                        <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{successmessage}</div>
                      </div>
                    </>
                  </Alert>
                )}
              </Snackbar>
            </Stack>

            <Snackbar className='snackbar_bar_postition' open={notesSuccessMsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setNotesSuccessMsg(false)}>
              <Alert className='Success2 mainAlert' variant='filled' icon={false}>
                <>
                  <div className='alertCancel_2'>
                    <img alt='CANCELICONNOTES' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img alt='CONFIRMICONNOTES' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />

                    <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{notesAlertMsg}</div>
                  </div>
                </>
              </Alert>
            </Snackbar>
          </Stack>
          <Snackbar className='snackbar_bar_postition' open={saveStatus} anchorOrigin={{ vertical, horizontal }} onClose={() => setSaveStatus(false)} sx={{ zIndex: '100000000' }}>
            <Alert className='Success mainAlert' variant='filled' icon={false} sx={{ zIndex: '100000000' }}>
              <div className='alertCancel' align='right'>
                <img alt='CANCELICON' onClick={handleClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>

              <div className='alertMainNew'>
                <div align='left' style={{ paddingTop: 37 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'Center',
                    }}
                  >
                    <Box className='alertCantinueButton'>
                      <img alt='CONFIRMICON' src={DrawerAlertIcon} className='primary_icons_dimension' />
                    </Box>
                  </Box>

                  <div
                    style={{
                      paddingTop: 20,
                      paddingLeft: '26px',
                      fontSize: '20px',
                      fontFamily: 'RobotoRegular',
                    }}
                  >
                    Any required fields left empty will revert to the previous selection. Okay to continue?
                  </div>
                </div>

                <div
                  align='left'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px 10px 0px 24px',
                    justifyContent: 'center',
                    alignItems: 'start',
                    lineHeight: '24px',
                  }}
                >
                  <Box
                    onClick={() => {
                      handleContinue();
                    }}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'Center',
                      cursor: 'pointer',
                      marginBottom: '20px',
                    }}
                  >
                    <Box onClick={() => {}} className='alertCantinueButton'>
                      <img alt='CONTINUE' src={DrawerContinueIcon} className='primary_icons_dimension' />
                    </Box>
                    <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                      CONTINUE
                    </Typography>
                  </Box>

                  <Box
                    onClick={handleClose}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'Center',
                      cursor: 'pointer',
                      paddingBottom: '16px',
                      paddingLeft: '15px',
                    }}
                  >
                    <Box className='globalIconMainBox'>
                      <Box className='iconSecondaryButtonGlobalForDrawer'>
                        <img alt='CANCEL' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                      </Box>
                      <Typography className='globalTextForDrawer'>CANCEL</Typography>
                    </Box>
                  </Box>
                </div>
              </div>
            </Alert>
          </Snackbar>
          <div className='heading-box'>
            <h2 className='clickable_header' onClick={() => setNameDrawerOpen(true)}>
              <LongText>{windowName}</LongText>
            </h2>
            <h3
              className='clickable_header all_pages_h2_header'
              style={{ textTransform: 'capitalize' }}
              onClick={() => {
                navigate(`/tbm/ps/customerdetails`, {
                  state: {
                    Custdata: { custid: customerID, Addressid: addressID },
                  },
                });
              }}
            >
              <LongText>{`${customerdetail.last_name} | ${customerdetail.city}`}</LongText>
            </h3>
          </div>
          <img
            alt='NOTES'
            style={{
              width: '20px',
              color: '#008593',
              cursor: 'pointer',
              position: 'relative',
              float: 'right',
              top: '-55px',
            }}
            src={NoteMiscIcon}
            className='misc_icons_dimensions'
            onClick={() => openNotesDrawer()}
          />

          {proposalNotes.map((note, index) => (
            <Box key={note.id} sx={{ display: 'flex' }}>
              {note.visible ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : null}{' '}
              <Box>
                <Typography className='' onClick={() => openNotesDrawer(note)}>
                  {' '}
                  <span
                    style={{
                      color: '#008593',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                    }}
                  >
                    Note #{index + 1} :
                  </span>{' '}
                  {note.content}
                </Typography>
              </Box>
            </Box>
          ))}

          <Box className='button_top_space removeProposalWindowPromptMainBox_1 stepper_prompt_margin_bottom'>
            <Stack direction='row'>
              <Box>
                <Stack direction='row'>
                  <img alt='Window Icon' className='primary_icons_dimension_1 stepperImageMargins' src={WindowIcon} />
                </Stack>
              </Box>
              <Box sx={{ borderLeft: '1px solid #D6D6D6' }} className='removeProposalWindowPromptMainBox2'>
                <Typography className='stepperMainTextSize stepperSubTextStyle'>Edit Window Covering</Typography>
              </Box>
            </Stack>
          </Box>
        </div>

        <Grid container sx={{ backgroundColor: 'white' }} columnGap={2} rowSpacing={2} p={0} direction='row'>
          <Grid
            item
            xs={0}
            sm={5}
            md={4}
            lg={3}
            sx={{
              height: '100vh',
              overflowX: 'scroll',
              padding: '0 10px',
              scrollbarWidth: 'none',
            }}
            display={{ xs: 'none', lg: 'block', md: 'block', sm: 'block' }}
          >
            <CoveringDrawer2 func2={fetchdata} data={filteredCoveringsDetails} />
          </Grid>
          <Grid item xs={12} sm={6.5} md={7.5} lg={8.75}>
            <Drawer
              PaperProps={{
                sx: { width: '90%' },
              }}
              classes={{ paper: classes.paper }}
              BackdropProps={{
                classes: {
                  root: classes.backDrop,
                },
              }}
              anchor='right'
              open={nameDrawerOpen}
              onClose={() => setNameDrawerOpen(true)}
              sx={{ zIndex: '100000' }}
            >
              <EditProposalWindowName token={token} window_name={windowName} customer_id={customerID} address_id={addressID} window_id={WindowId} closeDrawer={closeDrawer} />
            </Drawer>
            <Box component='main' className='apply_covering_box'>
              {/* Covering Drawer */}
              <Drawer classes={{ paper: classes.paper }} anchor='right' open={isDrawerOpen} onClose={() => setIsDrawerOpen(true)} className='drawer_Header_page'>
                <CoveringDrawer key={saveStatus} func={fetchdata} data={filteredCoveringsDetails} />
              </Drawer>
              {/* Notes Drawer */}
              <ThemeProvider theme={theme1}>
                <Drawer
                  classes={{ paper: classes.paper }}
                  PaperProps={{
                    sx: { width: '90%' },
                  }}
                  BackdropProps={{
                    classes: {
                      root: classes.backDrop,
                    },
                  }}
                  anchor='right'
                  open={notesDrawerOpen}
                  onClose={() => setNotesDrawerOpen(false)}
                  className='drawer_Header_page'
                >
                  <NotesDrawer closeDrawer={closeNotesDrawer} editing={editingNote} type='window_line_item' details={noteDrawerDetails} />
                </Drawer>
              </ThemeProvider>
              <Grid
                item
                xs={12}
                sm={0}
                md={0}
                lg={0}
                display={{
                  xs: 'block',
                  lg: 'none',
                  md: 'none',
                  sm: 'none',
                }}
              >
                <Box>
                  <span className='gray_button_span button_top_space'>
                    <Box className='globalIconMainBox' onClick={reset}>
                      <img alt='CLEARFILTERS' className={`secondary_icons_dimension ${isFilterBtnHovered ? 'hovered' : ''}`} src={FilterIcon} />
                      <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={hoverClearFilterBtn} onMouseLeave={hoverClearFilterBtn}>
                        {' '}
                        CLEAR FILTERS
                      </Typography>
                    </Box>
                  </span>
                  <hr className='button_top_space button_bottom_space' />
                  <Box className='container small'>
                    <span className='button_span' onClick={() => setIsDrawerOpen(true)}>
                      <Box className='globalIconMainBox'>
                        <img alt='SELECT/EDITPRODUCTOPTIONS' src={ProposalIcon} className='primary_icons_dimension' />
                        <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                          {' '}
                          SELECT/EDIT PRODUCT OPTIONS
                        </Typography>
                      </Box>
                    </span>
                  </Box>
                </Box>
              </Grid>
              {/* Window Details */}
              <WindowDetails windowDetails={windowDetails} />
              <hr className='button_top_space button_bottom_space' />
              {/* Product details */}
              <Box className='show_Sku_Sub_Box_Proposal_Apply_Covering_Page'>
                <Grid
                  item
                  xs={12}
                  display={{
                    xs: 'block',
                    lg: 'block',
                    md: 'block',
                    sm: 'block',
                  }}
                >
                  <h3 className='proposal_result_header'>Covering System :</h3>

                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1} columns={2}>
                      <Grid item xs={2} sm={1}>
                        <Item>
                          {wcc === '' || wcc === ' ' ? (
                            ''
                          ) : (
                            <>
                              Category :{' '}
                              <Typography variant='h7' className='proposal_result_header'>
                                {wcc}
                              </Typography>
                              <br />
                            </>
                          )}
                          {brand === '' ? (
                            ''
                          ) : (
                            <>
                              Brand :{' '}
                              <Typography variant='h7' className='proposal_result_header'>
                                {brand}
                              </Typography>
                              <br />
                            </>
                          )}
                          {label === '' ? (
                            ''
                          ) : (
                            <>
                              Label :{' '}
                              <Typography variant='h7' className='proposal_result_header'>
                                {label}
                              </Typography>
                              <br />
                            </>
                          )}
                          {product === '' ? (
                            ''
                          ) : (
                            <>
                              Product :{' '}
                              <Typography variant='h7' className='proposal_result_header'>
                                {product}
                              </Typography>
                              <br />
                            </>
                          )}
                          {type === '' ? (
                            ''
                          ) : (
                            <>
                              Type :{' '}
                              <Typography variant='h7' className='proposal_result_header'>
                                {type}
                              </Typography>
                              <br />
                            </>
                          )}
                        </Item>
                      </Grid>
                      <Grid item xs={2} sm={1}>
                        <Item>
                          {fabric === '' ? (
                            ''
                          ) : (
                            <>
                              Material :{' '}
                              <Typography variant='h7' className='proposal_result_header'>
                                {fabric}
                              </Typography>
                              <br />
                            </>
                          )}
                          {opacity === '' ? (
                            ''
                          ) : (
                            <>
                              {wcc === 'Blinds' || wcc === 'Shutters' ? <>Louver Size : </> : <>Opacity : </>}

                              <Typography variant='h7' className='proposal_result_header'>
                                {opacity}
                              </Typography>
                              <br />
                            </>
                          )}
                          {lift === '' ? (
                            ''
                          ) : (
                            <>
                              Lift System :{' '}
                              <Typography variant='h7' className='proposal_result_header'>
                                {lift}
                              </Typography>
                              <br />
                            </>
                          )}
                          {direction === '' ? (
                            ''
                          ) : (
                            <>
                              Direction :{' '}
                              <Typography variant='h7' className='proposal_result_header'>
                                {direction}
                              </Typography>
                              <br />
                            </>
                          )}
                          {Object.keys(selectedOptionJSON).length
                            ? Object.entries(selectedOptionJSON).map(([key, value]) =>
                                value.name !== ' ' ? (
                                  <div>
                                    <span>{key} : </span>
                                    <Typography variant='h7' className='proposal_result_header'>
                                      {value.name}
                                    </Typography>
                                  </div>
                                ) : null
                              )
                            : null}
                        </Item>
                      </Grid>
                    </Grid>
                  </Box>

                  <hr className='button_top_space button_bottom_space' />
                  <div>
                    <h3 className='proposal_result_header'>Coverings in this Proposal :</h3>
                    {Array.from(new Set(appliedWindows.map((coveringdata) => coveringdata.material_code))).map((materialCode, index) => (
                      <div
                        className='table-box'
                        key={index}
                        // onClick={async () => {
                        //   handleCoveringinProposal(appliedWindows.find((coveringdata) => coveringdata.material_code === materialCode));
                        // }}
                      >
                        {materialCode}
                      </div>
                    ))}
                  </div>
                </Grid>
              </Box>
              <hr className='button_top_space button_bottom_space' />
              {/* Detail Notes and Confirm For Sales Order */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '16px',
                }}
              >
                <Button
                  sx={{
                    fontFamily: 'RobotoLight',
                    justifyContent: 'start',
                    cursor: 'pointer',
                    width: 'fit-content',
                  }}
                  startIcon={<img className={`secondary_icons_dimension`} src={NotesSecondaryIcon} />}
                  className='button_span globalText'
                  onClick={() => openProposalDetailsNotesDrawer()}
                >
                  Add a Note to this Window Covering Detail
                </Button>

                {proposalDetailsNotes.map((note, index) => (
                  <Box key={note.id} sx={{ display: 'flex' }}>
                    {note.visible ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : null}{' '}
                    <Box>
                      <Typography className='' onClick={() => openProposalDetailsNotesDrawer(note)}>
                        {' '}
                        <span
                          style={{
                            color: '#008593',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                        >
                          Note #{index + 1}:
                        </span>{' '}
                        {note.content}
                      </Typography>
                    </Box>
                  </Box>
                ))}

                <span className={`button_span ${!confirmable ? 'button_span_notes_disabled' : ''} padding_Continue_Button_Proposal_Apply_Covering_Page${!confirmable ? '_2' : ''}`}>
                  <FormControlLabel
                    className='confirm-checkbox'
                    label={<span style={{ fontSize: '18px', fontFamily: 'RobotoLight', marginLeft: '8px' }}>CONFIRM FOR SALES ORDER</span>}
                    control={<Checkbox disabled={!confirmable} sx={{ padding: '0px !important' }} style={{ transform: 'scale(1.2)', padding: '0px !important' }} checked={confirmed} onChange={confirmable ? (e) => setConfirmed(e.target.checked) : undefined} />}
                  />
                </span>
              </Box>

              <hr className='button_top_space button_bottom_space' />
              {/* Apply and Cancel Buttons */}
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Button
                  className={`${isDisabled ? 'button_span_disabled' : 'button_span'} padding_Continue_Button_Proposal_Apply_Covering_Page_1`}
                  onClick={applyCoveringOptions}
                  onMouseEnter={handleHoverFun}
                  onMouseLeave={handleMouseLeaveFun}
                  sx={{
                    justifyContent: 'left',
                    paddingLeft: 0,
                    width: 'fit-content',
                  }}
                  disabled={isDisabled}
                >
                  <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                    <img alt='Apply Covering' className={isDisabled ? 'primary_icons_dimension_disable' : 'primary_icons_dimension_1'} src={isHovered && isDisabled ? ApplyCoveringIcon : ApplyCoveringHovIcon} />
                    <Typography sx={{ fontFamily: 'RobotoLight' }} className={isDisabled ? 'globalTextDisabled' : 'globalText'}>
                      APPLY OPTIONS
                    </Typography>
                  </Box>
                </Button>

                <Button
                  sx={{
                    width: 'fit-content',
                    justifyContent: 'left',
                    marginLeft: '10px',
                  }}
                  className='gray_button_span'
                >
                  <Box onClick={cancel} className='globalIconMainBox'>
                    <img alt='CANCEL' src={CancelIcon} className={`secondary_icons_dimension ${isCancelBtnHovered ? 'hovered' : ''}`} />
                    <Typography onMouseEnter={hoverCancelBtn} onMouseLeave={hoverCancelBtn} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                      CANCEL
                    </Typography>
                  </Box>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
