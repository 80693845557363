import React from 'react';
import {
  Box,
  Container,
} from '@mui/material';

import { RollerShades, Shutters, Blinds, SoftShades } from './SalesOrderProductTables';

export default function SalesOrderProductGridView({windows, ...props}) {
  const softShades = windows.filter(window => window.covering_type === 'Soft Shades');
  const shutters = windows.filter(window => window.covering_type === 'Shutters');
  const blinds = windows.filter(window => window.covering_type === 'Blinds');
  const rollerShades = windows.filter(window => window.covering_type === 'Roller Shades');
  
  return (
    <Box>
      {rollerShades.length ? <RollerShades windows={rollerShades} {...props} /> : null}
      {shutters.length ? <Shutters windows={shutters} {...props} /> : null}
      {blinds.length ? <Blinds windows={blinds} {...props} /> : null}
      {softShades.length ? <SoftShades windows={softShades} {...props} /> : null}
    </Box>
  )
}