import React, { useEffect, useState } from 'react';
import FileUpload from './FileUpload';
import Header from './Header';
import price_chart from '../JsonConverter/csv_files/price_chart.csv';
import min_max_area from '../JsonConverter/csv_files/min_max_area.csv';
import Price_chat_Width_price from '../JsonConverter/csv_files/Price_chat_Width_price.csv';
import CONFIG from '../../../config/config.js';
import Jsonlint from '../../../img/jsonlint.png';
import Jsonformatter from '../../../img/jsonformatter.jpeg';
import { useNavigate } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { styled, useTheme } from '@mui/material/styles';
import headerBack from '../../../assets/icons/primary/header-return.svg';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Helmet } from 'react-helmet';
import HeaderLogoIcon from '../../../assets/icons/secondary/hdft-TBM-logo.svg';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DrawerCancelIcon from '../../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../../assets/icons/drawer-alert/drw-alert.svg';
import { Stack } from '@mui/material';

const drawerWidth = 350;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const vertical = 'top';
const horizontal = 'center';

const ListItems = [
  {
    fileName: 'Price_chat_Width_price.csv',
    filePath: Price_chat_Width_price,
    type: 'Type-1',
  },
  {
    fileName: 'price_chart.csv',
    filePath: price_chart,
    type: 'Type-2',
  },
  {
    fileName: 'min_max_area.csv',
    filePath: min_max_area,
    type: 'Type-3',
  },
];

const handleDownloadClick = (filepath, fileName) => {
  const link = document.createElement('a');
  link.href = filepath;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function JsonConverter() {
  const [selectedValue, setSelectedValue] = useState('');
  const [refferedOpen, setRefferedOpen] = useState(false);
  const [displayType, setdisplaytype] = useState('');
  const [storedTableName, setstoredTableName] = useState('');
  const [errormsg, setErrorMsg] = React.useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  const handleDownloadFile = () => {
    const selectedFile = ListItems.find((item) => item.filePath === selectedValue);
    if (selectedFile) {
      const link = document.createElement('a');
      link.href = selectedFile.filePath;
      link.setAttribute('download', selectedFile.fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setTimeout(() => {
        setSuccess(true);
        setSelectedValue('');
        setRefferedOpen(false);
        setdisplaytype('');
      }, 200);
    }
  };

  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  useEffect(() => {
    getVerified();
  }, []);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMsg(false);
    setSuccess(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  return (
    <div>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={errormsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setErrorMsg(false)}>
          <Alert className='Error mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel'>
                <img onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
              </div>
              <div className='alertMain'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='DrawerAlertIcon' />

                <div align='center' style={{ paddingTop: 10 }}>
                  Proposal ID is invalid!
                </div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={success} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setSuccess(false)}>
          <Alert className='Success mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel'>
                <img onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
              </div>
              <div className='alertMain'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='DrawerAlertIcon' />

                <div align='center' style={{ paddingTop: 10 }}>
                  File downloaded Successfully !
                </div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <Helmet>
        {' '}
        <link rel='stylesheet' href='https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css' integrity='sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T' crossorigin='anonymous' />{' '}
      </Helmet>

      <div
        dangerouslySetInnerHTML={{
          __html: ` <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
            integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM"
              crossorigin="anonymous"></script>`,
        }}
      ></div>

      <Box
        sx={{
          display: 'flex',
          width: '80%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <CssBaseline />
        <AppBar position='fixed' className='jsonconverter_header' open={open}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100vw',
              }}
            >
              <Typography sx={{ paddingLeft: '10px' }}>
                <img onClick={() => navigate('/tbm/ps/dashboard')} src={HeaderLogoIcon} alt='Logo' className='logout_icon_dimension drawer_cancel_cursor' />
              </Typography>
              <Typography sx={{ paddingTop: '8px', fontSize: '17px' }}>TBM-DB-TOOLS</Typography>
              <Typography
                className='return_to_dashboard'
                onClick={() => {
                  navigate(-1);
                }}
                sx={{
                  cursor: 'pointer',
                }}
                variant='h6'
                noWrap
                component='div'
              >
                <Typography sx={{ paddingLeft: '10px' }}>
                  <img onClick={() => navigate(-1)} src={headerBack} alt='Logo' className='dbtools_backbuttton ' />
                </Typography>
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant='persistent'
          anchor='left'
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold', paddingLeft: '20px' }}>Sample Files</Typography>
          </List>
          <Divider />
          <List>
            {ListItems.map((text) => {
              return (
                <ListItem disablePadding onClick={() => handleDownloadClick(text.filePath, text.fileName)}>
                  <ListItemButton>
                    <ListItemIcon>
                      <SimCardDownloadIcon />
                    </ListItemIcon>
                    <ListItemText primary={text.fileName} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
          <Divider />
          <List>
            {['Json Importer'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <Button
                    onClick={() => {
                      navigate(`/dbtools/jsonimporter`);
                    }}
                    variant='contained'
                  >
                    <ListItemText primary={text} />
                  </Button>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <h4 className='display-4 text-center mb-4'>
            <Header />
          </h4>

          <FormControl fullWidth onClick={() => setRefferedOpen(!refferedOpen)}>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              onChange={handleChange}
              value={selectedValue}
              open={refferedOpen}
              onClose={() => setRefferedOpen(false)}
              onOpen={() => setRefferedOpen(true)}
              startAdornment={<InputAdornment position='start'>{!selectedValue ? <Typography sx={{ color: 'black', fontFamily: 'RobotoLight' }}>Select JSON type :</Typography> : null}</InputAdornment>}
              sx={{ fontWeight: 'bold', cursor: 'pointer' }}
            >
              {ListItems.map((item) => {
                return (
                  <MenuItem sx={{ background: '#f0f1f2' }} value={item.filePath} key={item.fileName}>
                    {item.type}
                  </MenuItem>
                );
              })}
            </Select>
            <Box sx={{ margin: '10px 0px 10px 0px' }}>
              {selectedValue === '/static/media/Price_chat_Width_price.bbf65ddb6fd363bb364d.csv' ? (
                <>
                  <Typography>
                    <span style={{ fontWeight: 'bold', marginLeft: '6px' }}>Selected Type : </span>W / H:P Example :<b>"24"</b>: <span style={{ color: 'blue' }}>103</span> , <b>"30"</b>:<span style={{ color: 'blue' }}>126</span>,<b>"36"</b>:<span style={{ color: 'blue' }}>156</span>, <b>"42"</b>:
                    <span style={{ color: 'blue' }}>191</span>
                  </Typography>

                  <Typography sx={{ marginLeft: '6px' }}>
                    Data store in <b>pricing_lift_system.dollar_retail_surcharge</b> || <b>pricing_accessory.dollar_retail_surcharge</b> || <b>pricing_option.dollar_retail_surcharge</b>) (price based on only width )
                  </Typography>
                </>
              ) : selectedValue === '/static/media/price_chart.286f01d4762c720239be.csv' ? (
                <>
                  <Typography>
                    {' '}
                    <span style={{ fontWeight: 'bold', marginLeft: '6px' }}>Selected Type : </span>
                    W.H : P Example : <b> "24.36"</b>:<span style={{ color: 'blue' }}>114</span>,<b> "24.42"</b>:<span style={{ color: 'blue' }}>121</span>,<b> "24.48"</b>:<span style={{ color: 'blue' }}>127</span>,<b> "24.54"</b>:<span style={{ color: 'blue' }}>130</span>,<b> "24.60"</b>:
                    <span style={{ color: 'blue' }}>134</span>, <b> "24.66"</b>:<span style={{ color: 'blue' }}>141</span>,<b> "24.72"</b>:<span style={{ color: 'blue' }}>183</span>
                  </Typography>

                  <Typography sx={{ marginLeft: '6px' }}>
                    Data store in <b>pricing_lift_system.dollar_retail_surcharge</b> ||
                    <b>pricing_accessory.dollar_retail_surcharge</b> ||
                    <b>pricing_option.dollar_retail_surcharge</b>) (price based on width and Height)
                  </Typography>
                </>
              ) : selectedValue === '/static/media/min_max_area.b47f4a92aee76930d9f3.csv' ? (
                <>
                  <Typography>
                    {' '}
                    <span style={{ fontWeight: 'bold', marginLeft: '6px' }}>Selected Type : </span>
                    <b> "max_height_inches"</b>:<span style={{ color: 'blue' }}>144</span>, <b>"max_width_inches"</b>:<span style={{ color: 'blue' }}>174</span>, <b>"max_area"</b> :<span style={{ color: 'blue' }}>144</span>,<b> "min_width_inches"</b>:<span style={{ color: 'blue' }}>16</span>,
                    <b>"min_height_inches"</b> :<span style={{ color: 'blue' }}>24</span>{' '}
                  </Typography>

                  <Typography sx={{ marginLeft: '6px' }}>
                    Data store in <b>limitation_covering_system.limitation_values</b>
                  </Typography>
                </>
              ) : null}
            </Box>

            <Box sx={{ margin: '10px 0px 10px 0px' }}>
              {selectedValue === '/static/media/Price_chat_Width_price.925df992770430e6a7f5.csv' ? (
                <>
                  <Typography>
                    <span style={{ fontWeight: 'bold', marginLeft: '6px' }}>Selected Type : </span>W / H:P Example :<b>"24"</b>: <span style={{ color: 'blue' }}>103</span> , <b>"30"</b>:<span style={{ color: 'blue' }}>126</span>,<b>"36"</b>:<span style={{ color: 'blue' }}>156</span>, <b>"42"</b>:
                    <span style={{ color: 'blue' }}>191</span>
                  </Typography>
                  <Typography sx={{ marginLeft: '6px' }}>
                    Data store in <b>pricing_lift_system.dollar_retail_surcharge</b> || <b>pricing_accessory.dollar_retail_surcharge</b> || <b>pricing_option.dollar_retail_surcharge</b>) (price based on only width )
                  </Typography>
                </>
              ) : selectedValue === '/static/media/price_chart.dd2119efa808f96b887d.csv' ? (
                <>
                  <Typography>
                    {' '}
                    <span style={{ fontWeight: 'bold', marginLeft: '6px' }}>Selected Type : </span>
                    W.H : P Example : <b> "24.36"</b>:<span style={{ color: 'blue' }}>114</span>,<b> "24.42"</b>:<span style={{ color: 'blue' }}>121</span>,<b> "24.48"</b>:<span style={{ color: 'blue' }}>127</span>,<b> "24.54"</b>:<span style={{ color: 'blue' }}>130</span>,<b> "24.60"</b>:
                    <span style={{ color: 'blue' }}>134</span>, <b> "24.66"</b>:<span style={{ color: 'blue' }}>141</span>,<b> "24.72"</b>:<span style={{ color: 'blue' }}>183</span>
                  </Typography>
                  <Typography sx={{ marginLeft: '6px' }}>
                    Data store in <b>pricing_lift_system.dollar_retail_surcharge</b> ||
                    <b>pricing_accessory.dollar_retail_surcharge</b> ||
                    <b>pricing_option.dollar_retail_surcharge</b>) (price based on width and Height)
                  </Typography>
                </>
              ) : null}
            </Box>

            {storedTableName ? (
              <Typography
                sx={{
                  paddingLeft: '5px',
                  fontSize: '15px',
                  paddingBottom: '10px',
                }}
              >
                <span style={{ fontWeight: 'bold' }}></span>
                {storedTableName}
              </Typography>
            ) : null}

            {selectedValue ? (
              <button className='btn btn-success download_button' onClick={handleDownloadFile}>
                Download Sample File
              </button>
            ) : null}
            <br />
          </FormControl>

          <FileUpload />

          <hr />
          <br />
          <Typography sx={{ display: 'flex' }}>
            JSON VALIDATOR's :{' '}
            <Box sx={{ padding: '0px 10px 0px 10px' }}>
              <a href='https://jsonlint.com/' target='_blank'>
                <img src={Jsonlint} alt='jsonlint' height='40px' />
              </a>{' '}
            </Box>
            <Box>
              <a href='https://jsonformatter.org/' target='_blank'>
                <img src={Jsonformatter} alt='jsonformatter' height='40px' />
              </a>
            </Box>
          </Typography>
          <br />
          <hr />
          <Box sx={{ marginTop: '20px' }}>
            <Typography sx={{ fontWeight: 'bold' }}>
              NOTE : &nbsp;
              <Typography sx={{ paddingBottom: '10px' }}>
                ● JSON Type 1 <b>&#123;W/H:P&#125;</b> Example : &#123; <b>"24"</b>:<span style={{ color: 'blue' }}>103</span>,<b>"30"</b>:<span style={{ color: 'blue' }}>126</span>, <b>"36"</b>:<span style={{ color: 'blue' }}>156</span>156, <b>"42"</b>:<span style={{ color: 'blue' }}>191</span>{' '}
                &#125;
              </Typography>
              <Typography sx={{ paddingBottom: '10px' }}>
                ● JSON Type 2 <b>&#123;W.H : P&#125;</b> Example : &#123; <b>"24.36"</b>:<span style={{ color: 'blue' }}>114</span>,<b>"24.42"</b>:<span style={{ color: 'blue' }}>121</span>, <b>"24.48"</b>:<span style={{ color: 'blue' }}>127</span>, <b>"24.54"</b>:
                <span style={{ color: 'blue' }}>130</span>, <b>"24.60"</b>:<span style={{ color: 'blue' }}>134</span>, <b>"24.66"</b>:<span style={{ color: 'blue' }}>141</span>,<b>"24.72"</b>:<span style={{ color: 'blue' }}>183</span> &#125;
              </Typography>
              <Typography sx={{ paddingBottom: '10px' }}>
                ● JSON Type 3 Example &#123; <b>"max_height_inches"</b>:<span style={{ color: 'blue' }}>144</span>,<b>"max_width_inches"</b>:<span style={{ color: 'blue' }}>174</span>, <b>"max_area"</b>:<span style={{ color: 'blue' }}>144</span>, <b>"min_width_inches"</b>:
                <span style={{ color: 'blue' }}>16</span>,<b>"min_height_inches"</b>:<span style={{ color: 'blue' }}>24</span> &#125;
              </Typography>
            </Typography>
          </Box>
        </Main>
      </Box>
    </div>
  );
}
