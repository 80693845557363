import { createContext, useReducer, useState, useEffect } from 'react';
import CONFIG from '../../config/config.js';
import { useLocation } from 'react-router-dom';

export const WorkOrderContext = createContext(null);
export const WorkOrderDispatchContext = createContext(null);

export function WorkOrderProvider({ children }) {
  const location = useLocation();
  const [state, dispatch] = useReducer(workOrderReducer, initialWorkOrderState);

  const getSaleOrderWindows = async () => {
    const token = sessionStorage.getItem('token');
    const response = await fetch(`${CONFIG.API_URL}/so/salesorderdetails/${location.state.Custdata.Sales_Order_Id}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      return console.error(`Unable to fetch sales order details: ${data.error}`);
    }
    dispatch({
      action: 'SET_SALE_ORDER_DETAILS',
      payload: {
        salesOrder: data.SalesOrder,
        details: data.SalesOrderDetails,
      },
    });
  };

  const getWorkOrderDetails = async () => {
    const token = sessionStorage.getItem('token');
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/WorkOrderDetails/${location.state.Custdata.Invoice}`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      return console.error(data.error);
    }
    dispatch({
      action: 'SET_WORK_ORDER_DETAILS',
      payload: {
        softShades: data.ordersB_data.filter((order) => order.CoveringType === 'Soft Shades'),
        rollerShades: data.ordersB_data.filter((order) => order.CoveringType === 'Roller Shades'),
        blinds: data.ordersB_data.filter((order) => order.CoveringType === 'Blinds'),
        shutters: data.ordersS_data
          .filter((order) => order.CoveringType === 'Shutters')
          .map((order) => ({
            ...order,
            ...data.shutterStatus_data[0],
          })),
        additionalItems: [...data.ordersS_data.filter((order) => !order.CoveringType), ...data.ordersB_data.filter((order) => !order.CoveringType)],
        installation: data.install_data,
        invoiceData: data.invoice_data,
      },
    });
  };

  useEffect(() => {
    getSaleOrderWindows();
    getWorkOrderDetails();
  }, []);

  return <WorkOrderContext.Provider value={{ workOrderDetails: state }}>{children}</WorkOrderContext.Provider>;
}

export default WorkOrderProvider;

const workOrderReducer = (state = initialWorkOrderState, { action, payload }) => {
  switch (action) {
    case 'SET_SALE_ORDER_DETAILS':
      return {
        ...state,
        ...payload,
      };
    case 'SET_WORK_ORDER_DETAILS':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

const initialWorkOrderState = {
  salesOrder: {},
  details: [],
  softShades: [],
  rollerShades: [],
  blinds: [],
  shutters: [],
  additionalItems: [],
  installation: [],
  invoiceData: [],
};
