import React from "react";

const Header = () => {
  return (
    <header>
      <h1>CSV to JSON Converter</h1>
   
    </header>
  );
};

export default Header;
