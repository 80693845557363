import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
} from "@mui/material";


import SalesOrderWindows from './SalesOrderWindows';
import ProductGridViewIcon from "../../assets/icons/secondary/productGridView.svg";
import SalesOrderProductGridView from './SalesOrderProductGridView';


export default function SalesOrderTable({ salesOrderWindows }) {
  
  const [openGridView, setOpenGridView] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box className="proposal_table_main_box button_top_space">
      <Box className="proposal_table_sub_box">

        <SalesOrderWindows windows={salesOrderWindows} />

        <Button
          onClick={() => setOpenGridView(!openGridView)}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            width: "fit-content",
            contentAlign: "center",
            justifyContent: "left",
            paddingLeft: "1.5em",
          }}
        >
          <img
            alt="Product Grid View"
            src={ProductGridViewIcon}
            className={`secondary_icons_dimension ${
              isHovered ? "hovered" : null
            }`}
          />
          <Typography
            sx={{ fontFamily: "RobotoLight" }}
            className={`globalText ${isHovered ? "globalTextDecoration" : ""}`}
          >
            {" "}
            Product Grid View
          </Typography>
        </Button>
      
        {openGridView ? <SalesOrderProductGridView windows={salesOrderWindows} /> : null}
      </Box>
    </Box>
  );
}
