import React from "react";
import { Box, Grid, Divider } from "@mui/material";
import Label from "./Label";

const ProductDescription = ({ description, customStyles }) => {

  const material = description.find((object) => object.sku_type === "M");
  const liftSystem = description.find((object) => object.sku_type === "L");
  const products = description.filter((object) => object.sku_type === "P");
  

  return (
    <Box display="flex" justifyContent="right">
      <Grid container direction="row" spacing={0} style={customStyles.row_section}>
        {/* Material */}
        <Grid item sm={6}>
          <Label label="Category" customStyles={customStyles}>{material.covering_category}</Label>
          <Label label="Brand" customStyles={customStyles}>{material.brand}</Label>
          <Label label="Label" customStyles={customStyles}>{material.label}</Label>
          <Label label="Product" customStyles={customStyles}>{material.product}</Label>
          <Label label="Type" customStyles={customStyles}>{material.type}</Label>
        </Grid>

        <Divider style={customStyles.headerverticallinefortable} orientation="vertical" flexItem />

        {/* Lift & Product */}
        <Grid item sm={6}>
          <Label label="Material" customStyles={customStyles}>{material.fabric}</Label>
          <Label label={["Chart A", "Chart B"].includes(material.fabric) ? "Louver Size" : "Opacity" } customStyles={customStyles}>{material.opacity}</Label>
          <Label label="Lift System" customStyles={customStyles}>{liftSystem.sku_name}</Label>
          <Label label="Direction" customStyles={customStyles}>{liftSystem.type}</Label>

          {products.map((product) => (
            <Label label={product.value_2} customStyles={customStyles}>{product.value_1}</Label>
          ))}

        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductDescription;