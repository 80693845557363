import React from "react";
import font from "../../../font/Roboto-Light.woff";
import { Text, Font, View, StyleSheet } from "@react-pdf/renderer";
Font.register({
  family: "RobotoLight",
  src: font,
});
// Create styles
const styles = StyleSheet.create({
  section_row_body: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 15,
  },
  firstBlock: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "10px",
  },
  fontFamily: {
    paddingTop: 0,
    fontFamily: "RobotoLight",
    fontWeight: "400",
  },
  headingTextSign: {
    fontSize: 18,
    color: "#3998A2",
    paddingTop: "1px",
    fontFamily: "RobotoLight",
    fontWeight: "400",
  },
  description: {
    color: "#2C2E35",
    width: "100%",
    fontSize: "11px",
    paddingTop: 5,
    lineHeight: 1.1,
  },
  section_space: {
    lineHeight: "1.5px",
    paddingTop: 5,
  },
  signature: {
    paddingLeft: "20%",
    paddingRight: "20%",
  },
  signaturebox: {
    color: "gray",
    border: "2px solid #2C2E35",
    paddingTop: 25,
    width: 350,
    height: 60,
    fontSize: "12px",
    textAlign: "center",
    backgroundColor: "#FAC41E",
    fontFamily: "RobotoLight",
    fontWeight: "400",
  },
  headingText: {
    fontSize: 18,
    color: "#333333",
    paddingTop: "1px",
    fontFamily: "RobotoLight",
    fontWeight: "400",
  },

  paraheading: {
    fontSize: 13,
    color: "#2C2E35",
    paddingTop: 8,
    fontFamily: "RobotoLight",
  },
  horizontalborder: {
    width: "100%",
    height: "2px",
    flexDirection: "row",
    backgroundColor: "#7E7E7E",
  },
});

// Create Document Component
export default function CustomerAuthorization(props) {
  return (
    <View>
      <View style={styles.section_row_body}>
        <View style={styles.firstBlock}>
          <Text style={styles.headingText}>CUSTOMER AUTHORIZATION</Text>
        </View>
        <View>
          <Text style={styles.paraheading}>PURCHASE AGREEMENT</Text>
        </View>
        <View style={styles.description}>
          <Text style={styles.fontFamily}>
            By sigining the customer the customer authorization,I authorize The
            Blindman Inc.(TBM) to procees with the manufacture of this
            non-cancellable,custome order. I further acknowledge if I elect to
            finance my purchase of custom windows coverings from TBM, or through
            one of its authorized affiliates,TBM honors my right to cancel this
            contract up until midnight on the third business day following the
            signing of this contract. I understand that my order will be buily
            to the exact specifications written on the opposite side of this
            page (with the exception of shutters that may be altered at a
            re-measure).All products will be manufactured and installed to TB
            company standards.No additional products or services are guaranteed
            unless written and aggreed upon on this original work order.
          </Text>
        </View>
        <View style={styles.description}>
          <Text style={styles.fontFamily}>
            All balances not paid at installation are subject to 1 1/2% monthly
            interest charge plus all collection fees deemed necessary to collect
            this account.I hereby grant a purchase money security interest in
            the afore mentioned propoerty and agree that until I have paid the
            unpaid balance, in full, that said property shall remain as personal
            property of The Blindman Inc. not-withstanding any installtion,
            connection or attachments to really.{" "}
          </Text>
          <View style={styles.section_space}>&nbsp;</View>
          <Text style={styles.boldheading}>
            Purchase Agreement, Written Warranty and Product Safety Notice are
            detailed in this document.No other contracts or warranties are given
            or implied.
          </Text>
        </View>
      </View>
      <View style={styles.horizontalborder}>&nbsp;</View>
    </View>
  );
}
