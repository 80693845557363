/** @format */

import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import CONFIG from '../../config/config.js';

export default function LandingPage() {
  const navigate = useNavigate();

  const user = sessionStorage.getItem('user');

  useEffect(() => {
    {
      window.scrollTo(0, 0);
    }
    getVerified();
  }, []);
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  return (
    <div align='center'>
      <h3>WELCOME</h3>
      <h2>
        <b>{user}</b>
      </h2>
      <h3>
        <Moment format='MM/DD/YY, h:mm a' />
      </h3>
      <hr />
      <Box className='landing_page_main_box'>
        <Box>
          <Button className='landing_page_sub_box' variant='contained' onClick={() => navigate('/tbm/ps/newcustomer')}>
            New Customer User Path
          </Button>
        </Box>
        <Box>
          <Button className='landing_page_sub_box' variant='contained' onClick={() => navigate('/tbm/ps/existingcustomer')}>
            Existing Customer User Path
          </Button>
        </Box>
        <Box>
          <Button className='landing_page_sub_box' variant='contained' onClick={() => navigate('/dbtools/skulookup')}>
            SKU Lookup
          </Button>
        </Box>
        <Box>
          <Button className='landing_page_sub_box' variant='contained' onClick={() => navigate('/dbtools/pricinglookup')}>
            Pricing Lookup
          </Button>
        </Box>
        <Box>
          <Button className='landing_page_sub_box' variant='contained' onClick={() => navigate('/tbm/ps/systeminformation')}>
            System Information
          </Button>
        </Box>
        <Box>
          <Button className='landing_page_sub_box' variant='contained' onClick={() => navigate('/tbm/ps/dashboard')}>
            Search Customer Details
          </Button>
        </Box>
      </Box>
    </div>
  );
}
