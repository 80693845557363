import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CONFIG from '../../../config/config.js';
import { PDFDownloadLink, PDFViewer, StyleSheet } from '@react-pdf/renderer';
import Pdf from './Pdf';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';

import { createTheme } from '@mui/material';
import { ConsoleView } from 'react-device-detect';

let filename;

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});

const styles = StyleSheet.create({
  viewer: {
    width: '90vw',
    height: window.innerHeight,
  },
});

const CTSOPage = () => {
  const downloadStyle = {
    textDecoration: 'none',
    fontfamily: 'Roboto-Light.woff',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2,
    paddingRight: 2,
    marginTop: '4%',
    marginBottom: '2%',
    fontSize: '100%',
    backgroundColor: '#008593',
    borderRadius: '25px',
    '&:hover': {
      backgroundColor: '#008593',
      textDecoration: 'none',
    },
  };

  const token = sessionStorage.getItem('token');
  const location = useLocation();

  const { path, customer, notes } = location.state;
  const { email_id: emailId } = customer;

  const [file, setFile] = useState('');
  const [content, setContent] = useState([]);
  const [isEmailValid, setIsEmailValid] = useState('primary');
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const uploadDocument = async (filecontent) => {
    let customer = location.state.customer;
    filename = `Sales Order for ${customer.first_name} ${customer.last_name}.pdf`;
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        customer_id: customer.customer_id,
        file: {
          filename: filename,
          path: filecontent,
        },
      }),
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/UploadPDF`, requestOptions);
    const data = await response.json();
  };

  const uploadFile = (file) => {
    const blobToBase64 = (blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };

    if (file) {
      setFile(file);
      blobToBase64(file).then((res) => setContent(res));
    }
  };

  const validateEmail = (email) => {
    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{1,5}/g;
    const result = pattern.test(email);

    if (result) {
      setIsEmailValid('primary');
    } else {
      setIsEmailValid('error');
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (content.length > 0) {
      uploadDocument(content);
    }
  }, [content]);

  useEffect(() => {
    if (emailId) {
      validateEmail(emailId);
    }

    if (emailId && file && isEmailValid === 'primary') {
      uploadFile(file);
    }
  }, [emailId, file]);

  const isMobile = width <= 768;

  return isMobile ? (
    <div style={{ textAlign: 'center' }}>
      <span> Download Sales Order by clicking the below button</span>
      <br />
      <PDFDownloadLink style={{ textDecoration: 'none' }} document={<Pdf data={location.state} customer={customer} notes={notes} />} fileName={filename}>
        {({ blob, url, loading, error }) =>
          !loading ? (
            <Button sx={downloadStyle} variant='contained' size='large' startIcon={<DownloadIcon />}>
              {' '}
              Download Sales Order
            </Button>
          ) : null
        }
      </PDFDownloadLink>
    </div>
  ) : (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <PDFViewer style={{ width: styles.viewer.width, height: styles.viewer.height }}>
        <Pdf path={path} state={location.state} customer={customer} notes={notes} />
      </PDFViewer>
    </Box>
  );
};

export default CTSOPage;
