import React from "react";
import { Font, StyleSheet, View, Text } from "@react-pdf/renderer";

import light from "../../../font/Roboto-Light.woff";
import regular from "../../../font/Roboto-Regular.woff";

Font.register({
  family: "RobotoLight",
  src: light,
});

Font.register({
  family: "Roboto",
  src: regular,
});

const styles = StyleSheet.create({
  section_row: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    // paddingBottom: 10,
  },
  section_customer: {
    paddingTop: 15,
    paddingLeft: 10,
    paddingBottom: 15,
    flexDirection: "col",
  },
  headingText: {
    paddingLeft: 5,
    fontFamily: "RobotoLight",
    fontSize: "8px",
    lineHeight: "1.5px",
    color: "#000",
    textAlign: "justify",
  },
  headingTextCopy: {
    paddingLeft: 5,
    fontFamily: "RobotoLight",
    fontSize: "8px",
    lineHeight: "1.5px",
    color: "#000",
    textAlign: "justify",
    textTransform: "capitalize",
  },
  rowText: {
    fontWeight: "bold",
    paddingTop: 5,
    paddingLeft: 40,
    fontFamily: "Roboto",
    fontSize: "10px",
    lineHeight: "1.5px",
    color: "#000",
    textAlign: "justify",
  },
  rowTextCopy: {
    paddingTop: 5,
    paddingLeft: 40,
    fontFamily: "Roboto",
    fontSize: "10px",
    lineHeight: "1.5px",
    color: "#000",
    textAlign: "justify",
    textTransform: "capitalize",
  },
  box: {
    flexDirection: "col",
    backgroundColor: "#EDECEB",
    padding: 10,
    marginLeft: 120,
    paddingBottom: 15,
    height: "100%",
    width: "100%",
  },
  heading: {
    width: "60%",
    paddingLeft: 20,
    fontSize: "22px",
    color: "#007383",
    fontFamily: "Roboto",
    lineHeight: 1,
  },
  p: {
    width: "95%",
    marginTop: 10,
    paddingLeft: 20,
    paddingRight: 10,
    fontFamily: "RobotoLight",
    fontSize: "11px",
    lineHeight: "1.5px",
    color: "#2D3030",
    textAlign: "justify",
  },
});

const CSTSOSubHeader = ({ customerDetails, salesRep, orderNumber, date }) => (
  <View style={styles.section_row}>
    <View style={styles.section_customer}>
      <Text style={styles.headingText}>
        Date : <Text style={styles.rowText}>{date}</Text>
      </Text>
      <Text style={styles.headingText}>
        Customer :{" "}
        <Text style={styles.rowText}>
          {customerDetails.first_name === "NA"
            ? `${customerDetails.last_name}`
            : `${customerDetails.first_name} ${
                customerDetails.wife !== "NA"
                  ? `& ${customerDetails.wife}`
                  : null
              } ${customerDetails.last_name}`}
        </Text>
      </Text>
      <Text style={styles.headingTextCopy}>
        Address :{" "}
        <Text style={styles.rowText}>{customerDetails.address_line}</Text>
      </Text>
      {customerDetails.address_second_line ? (
        <Text style={styles.headingTextCopy}>
          Address Line 2 :{" "}
          <Text style={styles.rowText}>
            {customerDetails.address_second_line}
          </Text>
        </Text>
      ) : null}
      <Text style={styles.headingTextCopy}>
        City, State, Zip :{" "}
        <Text
          style={styles.rowText}
        >{`${customerDetails.city}, ${customerDetails.state} ${customerDetails.zip}`}</Text>
      </Text>
      <Text style={styles.headingText}>
        Phone : <Text style={styles.rowText}>{customerDetails.mobile}</Text>
      </Text>
      <Text style={styles.headingText}>
        Email : <Text style={styles.rowText}>{customerDetails.email_id}</Text>
      </Text>
      <Text style={styles.headingText}>&nbsp;</Text>
      <Text style={styles.headingText}>
        Consultant : <Text style={styles.rowText}>{salesRep}</Text>
      </Text>
      <Text style={styles.headingText}>
        Sales Order Name : <Text style={styles.rowText}>{orderNumber}</Text>
      </Text>
    </View>

    <View style={styles.box}>
      <Text style={styles.heading}>THE BLINDMAN DIFFERENCE</Text>
      <Text style={styles.p}>
        You can trust The Blindman for innovative, technically
        <br /> advanced, and efficient window covering solutions. From the
        very first blind we built in 1990, our quality craftsmanship and
        unmatched customer service have helped us become Utah’s largest custom
        window covering manufacturer.
      </Text>
    </View>
  </View>
);

export default CSTSOSubHeader;
