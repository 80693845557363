import { TableHead, TableRow, TableCell } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export function formatLabel(key) {
  if (key === 'coveringType') {
    return (
      <>
        <span style={{ paddingRight: '30px' }}>#</span>
        <span>Covering Type</span>
      </>
    );
  }
  if (key === 'name') {
    return (
      <>
        <span style={{ paddingRight: '30px' }}>#</span>
        <span>Name</span>
      </>
    );
  }
  if (key === 'applied') {
    return <CheckCircleIcon />;
  }
  const words = key.replace(/([a-z])([A-Z])/g, '$1 $2').split(/(?=[A-Z])/);
  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function Header({ columns }) {
  return (
    <TableHead>
      <TableRow style={{ margin: 0, fontWeight: 'bold' }}>
        {columns.map((column) =>
          column.dataKey !== 'id' ? (
            <TableCell
              key={column.dataKey}
              variant='head'
              style={{ width: column.width }}
              sx={{
                padding: 0,
                paddingRight: 1,
                whiteSpace: 'nowrap',
                font: 'bold 1rem RobotoLight',
                justifyContent: 'left',
                position: column.dataKey === 'name' ? 'sticky' : 'inherit',
                left: column.dataKey === 'name' ? 0 : 'auto',
                backgroundColor: 'white',
              }}
            >
              {column.label}
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
}

export function Row({ columns, row }) {
  console.log(row);
  return (
    <TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
      {columns.map((column) =>
        column.dataKey !== 'id' ? (
          <TableCell
            key={column.dataKey}
            sx={{
              backgroundColor: 'white',
              borderBottom: 0,
              color: '#000',
              font: '1rem RobotoLight',
              left: column.dataKey === 'name' ? 0 : 'auto',
              padding: '2px 30px 2px 0px',
              position: column.dataKey === 'name' ? 'sticky' : 'inherit',
              whiteSpace: 'nowrap',
              width: '7%',
            }}
            align={'left'}
          >
            {row[column.dataKey] || column.dataKey === 'applied' ? row[column.dataKey] : 'N/A'}
          </TableCell>
        ) : null
      )}
    </TableRow>
  );
}

const Table = {
  Header,
  Row,
};

export default Table;
