/** @format */

import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import { TextField } from '@mui/material';
import CONFIG from '../../config/config.js';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerSaveIcon from '../../assets/icons/drawer-alert/drw-save.svg';
import DrawerCloseIcon from '../../assets/icons/drawer-alert/close.svg';

const EditWindowSetName = (props) => {
  const navigate = useNavigate();

  const [WindowSetName, setWindowSetName] = useState(props.windowdetails.window_set_name);
  const [isDrawerClose, setIsDrawerClose] = useState(true);
  const [alertmesssage, setAlertMessage] = useState('');
  const [duplicateWSN, setDuplicateWSN] = useState(false);

  useEffect(() => {
    getVerified();
  }, []);
  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const EditFun = (e) => {
    setWindowSetName(e.target.value);
  };

  const closeDrawer = () => {
    setIsDrawerClose(false);
  };
  props.func(WindowSetName, isDrawerClose, alertmesssage, duplicateWSN);
  const saveWindowSetName = () => {
    if (props.windowdetails.path && props.windowdetails.path === 'NewWindowSet') {
      CheckDuplicacy();
    } else {
      editWindowSetFun();
    }
  };

  const CheckDuplicacy = async () => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        window_set_name: WindowSetName,
        customer_id: props.windowdetails.customer_id,
        address_id: props.windowdetails.address_id,
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/CheckCustomerWindowSetName`, options);
    const data = await response.json();
    if (!response.ok || data.error) {
      setAlertMessage('Window set name is already exists.');
      setDuplicateWSN(true);
      setIsDrawerClose(false);
      return;
    }
    setIsDrawerClose(false);
    setDuplicateWSN(false);
    setAlertMessage('Window set name is updated successfully!');
  };

  const editWindowSetFun = async () => {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        window_set_id: props.windowdetails.window_set_id,
        window_set_name: WindowSetName,
        customer_id: props.windowdetails.customer_id,
        address_id: props.windowdetails.address_id,
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/pm/UpdateWindowSetName`, options);
    const data = await response.json();
    if (!response.ok || data.error) {
      setAlertMessage(data.error_message);
      setDuplicateWSN(true);
      setIsDrawerClose(false);
      return;
    }
    setIsDrawerClose(false);
    setDuplicateWSN(false);
    setAlertMessage(data.message);
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  return (
    <Box p={4} pt={2} className='sideDrawer'>
      <div align='right' className='drawer_backbutton'>
        <img alt='CANCELDRAWER' src={DrawerCloseIcon} onClick={closeDrawer} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
      </div>
      <Stack direction='column' spacing={0}>
        <TextField value={WindowSetName} onChange={EditFun} className='notes_textfield' />

        {WindowSetName ? (
          <span className='drawer_gray_button_span button_top_space' onClick={saveWindowSetName} style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
            <Box className='globalIconMainBox globalDrawerText'>
              <img alt='SAVE' src={DrawerSaveIcon} className={`primary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                SAVE
              </Typography>
            </Box>
          </span>
        ) : (
          <span className='drawer_gray_button_span button_top_space' style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
            <Box className='globalIconMainBox '>
              <img alt='SAVED' src={DrawerSaveIcon} className='primary_icons_dimension_disable' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
                SAVE
              </Typography>
            </Box>
          </span>
        )}

        <span className='drawer_gray_button_span button_top_space' onClick={closeDrawer} style={{ paddingBottom: '30px', paddingLeft: '14px' }}>
          <Box className='globalIconMainBox'>
            <img alt='CANCEL' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension' />
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
              CANCEL
            </Typography>
          </Box>
        </span>
      </Stack>
    </Box>
  );
};

export default EditWindowSetName;
