import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import '../../index.css';
import React, { useState, useEffect } from 'react';
import Header from './Header';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import CONFIG from '../../config/config.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { SnackbarProvider } from 'notistack';
import FooterCustomerIcon from '../../assets/icons/secondary/hdft-customers.svg';
import FooterAppointmentIcon from '../../assets/icons/secondary/hdft-appointments.svg';
import AlertProvider from './Alerts';
const theme = createTheme({
  typography: {
    fontFamily: 'RobotoLight',
  },
  box: {
    fontFamily: 'RobotoLight',
  },
});

export const UserContext = React.createContext(null);
export const Layout = (props) => {
  const [value, setValue] = React.useState(0);
  const [coveringdetails, setCoveringDetails] = React.useState();
  const [checkLoginStatus, setCheckLoginStatus] = useState(false);
  const token = sessionStorage.getItem('token');

  const navigate = useNavigate();
  useEffect(() => {
    if (token === null) {
      navigate(`/login`, {
        state: { error: 'UnAuthorised Access, Please Login' },
      });
    } else {
      getVerified();
    }

    getCoveringCriteria();
  }, []);

  //Get token

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (data.success !== true && data.data.message !== 'Token is Valid!') {
      setCheckLoginStatus(false);
      navigate(`/login`, {
        state: { error: 'Session Timeout! Please Relogin to Continue' },
      });
    } else {
      setCheckLoginStatus(true);
    }
  };

  const getCoveringCriteria = async () => {
    const response = await fetch(`${CONFIG.API_URL}/pm/PopulateCSPList`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      token: token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    setCoveringDetails(data.MaterialList);
  };

  return checkLoginStatus ? (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header
        position='fixed'
        style={{
          zIndex: 100_000,
        }}
        data={props}
      />
      <AlertProvider>
        <UserContext.Provider value={coveringdetails}>
          <main className='main'>
            <SnackbarProvider preventDuplicate maxSnack={20}>
              <Outlet />
            </SnackbarProvider>
          </main>
        </UserContext.Provider>
        <Box sx={{ marginTop: '64px' }}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            className='bottom_navigation_all'
            sx={{ height: '64px !important', alignItems: 'center !important' }}
          >
            <BottomNavigationAction
              className='footer_button_dimension'
              style={{
                color: 'white',
              }}
              label='CUSTOMER'
              icon={<img src={FooterCustomerIcon} height='26px' alt='GroupUserIcon' />}
              onClick={(e) => {
                navigate('/tbm/ps/searchCustomer/Customer');
              }}
            />
            <BottomNavigationAction
              className='footer_button_dimension'
              style={{
                color: 'white',
              }}
              label='APPOINTMENTS'
              onClick={(e) => {
                navigate('/tbm/ps/selecteddateappointments');
              }}
              icon={<img src={FooterAppointmentIcon} height='26px' alt='GroupUserIcon' />}
            />
          </BottomNavigation>
        </Box>
      </AlertProvider>
      {/* End footer */}
    </ThemeProvider>
  ) : null;
};

export default Layout;
