import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Worker } from '@react-pdf-viewer/core';
import Modal from '@mui/material/Modal';
import CONFIG from '../../config/config.js';
import { Viewer } from '@react-pdf-viewer/core';

const SalesOrderMasterRecord = () => {
  const [open, setOpen] = React.useState(false);
  const [Pdf, setpdf] = useState();

  const handleOpen = (pdf_url) => {
    setOpen(true);
    setpdf(pdf_url);
  };
  const handleClose = () => setOpen(false);
  const [documents, setDocuments] = useState([]);
  const [errorMsg, setErrMsg] = useState('');
  const [loading, setLoading] = useState(' ');

  const handleSearchChange = async (e) => {
    setLoading('Loading...');
    const token = sessionStorage.getItem('token');
    await fetch(CONFIG.API_URL + '/pm/SearchDocument/' + `${e.target.value}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.error === true) {
          setDocuments([]);

          setLoading('');
        } else if (!data.error) {
          setDocuments(data.Documents);
          if (data.Documents.length === 0) {
            setErrMsg('Records Not Found !');
          }
          setLoading('');
        }
      });
    if (e.target.value === '') {
      setDocuments([]);
      setErrMsg('');
      setLoading('');
    }
  };

  return (
    <div style={{ paddingTop: '20px' }}>
      <Paper
        component='form'
        sx={{
          p: '1px 3px',
          display: 'flex',
          alignItems: 'center',
          width: 'auto',
          border: '1px solid black',
          borderRadius: '10px',
        }}
        onSubmit={(e) => {
          e.preventDefault(); // Prevents the default form submission behavior
        }}
      >
        <InputBase sx={{ ml: 1, flex: 1 }} placeholder='Search/Find  Documents' inputProps={{ 'aria-label': 'Search Documents' }} onChange={handleSearchChange} />
        <IconButton type='button' sx={{ p: '10px' }} aria-label='search'>
          <SearchIcon />
        </IconButton>
      </Paper>
      <br />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>S.No</TableCell>
              <TableCell align='center'>Proposal ID</TableCell>
              <TableCell align='center'>Sales Order ID</TableCell>
              <TableCell align='center'>Customer ID</TableCell>
              <TableCell align='center'>Customer Name</TableCell>
              <TableCell align='center'>View PDF</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((row, index) => {
              return (
                <TableRow key='' sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align='left' component='th' scope='row'>
                    {index + 1}
                  </TableCell>
                  <TableCell align='center'>{row.metadata.proposal_id}</TableCell>
                  <TableCell align='center'>{row.metadata.sales_order_id}</TableCell>
                  <TableCell align='center'>{row.metadata.customer_id}</TableCell>
                  <TableCell align='center'>{row.metadata.customer_name}</TableCell>
                  <TableCell
                    onClick={() => {
                      handleOpen(row.metadata.pdf_url);
                    }}
                    align='center'
                  >
                    <RemoveRedEyeIcon />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {errorMsg !== '' ? (
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '20px',
          }}
        >
          {errorMsg}
        </span>
      ) : null}
      {loading ? (
        <span
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '20px',
          }}
        >
          {loading}
        </span>
      ) : null}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            outline: 0,
            height: '80%',
            width: '80%',
            overflow: 'scroll ',
            backgroundColor: 'white',
            borderRadius: '10px',
            paddingLeft: '110px',
          }}
        >
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
            <Viewer className='' fileUrl={Pdf} />
          </Worker>
        </div>
      </Modal>
    </div>
  );
};

export default SalesOrderMasterRecord;
