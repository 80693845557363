/** @format */

import React, { forwardRef, useContext, useState } from 'react';
import { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import { SnackbarContext } from '../../App';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const ProposalAlertMessage = (alertdetails) => {
  const contextvalue = useContext(SnackbarContext);

  const vertical = 'top';
  const horizontal = 'center';
  const [successmsg, setSuccessMsg] = useState(false);
  const [errormsg, setErrorMsg] = useState(false);
  const [alertShown, setAlertShown] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(alertdetails.data.alerts);
  let props = alertdetails.data;

  useEffect(() => {
    if (!alertShown) {
      if (contextvalue.successwindows === true) {
        if (props.successwindownames.length > 0) {
          setSuccessMsg(true);
          setAlertShown(true);
        }
      }

      if (contextvalue.failurewindows === true) {
        if (props.failurewindownames.length > 0) {
          setErrorMsg(true);
          setAlertShown(true);
        }
      }
    }
    ChangeValue();
  }, [props, alertShown]);

  const ChangeValue = () => {
    setTimeout(function () {
      contextvalue.setSuccesswindows(false);
      contextvalue.setFailurewindows(false);
    }, 5000);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessMsg(false);
  };
  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMsg(false);
  };

  return (
    <div align='center'>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={successmsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={props.successwindownames.length < 5 ? 3000 : props.successwindownames.length * 1000} onClose={() => setSuccessMsg(false)}>
          <Alert className='Success2 mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel_2'>
                <img onClick={handleSuccessClose} alt='CANCELICON' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={DrawerConfirmIcon} alt='CONFIRMICON' className='primary_icons_dimension alertImageMargin' />

                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Covering applied to {props.successwindownames.toString()}</div>
              </div>
            </>
          </Alert>
        </Snackbar>

        <Snackbar className='snackbar_bar_postition' open={errormsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setErrorMsg(false)}>
          <Alert sx={{ marginTop: '120px !important' }} className='Error1 mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel_2'>
                <img alt='CANCELICONRED' onClick={handleErrorClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img alt='CONFIRMICONRED' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>
                  {showErrorMsg?.map((errorMsg) => {
                    return (
                      <div align='left' className='alertLeftPadding alertSpacing alertSpacing1'>
                        {errorMsg.success === false ? errorMsg.message : ''}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
    </div>
  );
};

export default ProposalAlertMessage;
