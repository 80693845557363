import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, TextField, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import CONFIG from '../../config/config.js';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import SaveCustomerAdd from '../../assets/icons/primary/customer-complete.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import CustomerHovIcon from '../../assets/icons/primary/WithHovIcon/customer-comp-hov.svg';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});

const vertical = 'top';
const horizontal = 'center';

const EditCustomerPhoneNumber = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let locationState = [];

  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    locationState = location.state;
  }

  const [errormsg, setErrorMsg] = React.useState(false);
  const [Description, setDescription] = useState(locationState.Description);
  const [PhoneNumber, setPhoneNumber] = useState(formatUsPhone(locationState.phoneno));
  const [phoneError, setPhoneError] = useState();
  const [showerror, setShowError] = useState(false);

  // error handling states

  const [AddUpdateCustomerPhoneErrorMsg, setAddUpdateCustomerPhoneErrorMsg] = useState(false);
  const [AddUpdateCustomerPhoneApiErrorMsg, setAddUpdateCustomerPhoneApiErrorMsg] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  //------------------------------------------------------------React-Hooks--------------------------------------------------------------
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //---------------------------------------------------------------HandleEvents & Functions----------------------------------------------
  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMsg(false);
  };
  function formatUsPhone(phone) {
    var phoneTest = new RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);
    if (phone === undefined) {
      navigate('/pagenotfound');
    } else {
      phone = phone.trim();
    }

    var results = phoneTest.exec(phone);
    if (results !== null && results.length > 8) {
      return '(' + results[3] + ') ' + results[4] + '-' + results[5] + (typeof results[8] !== 'undefined' ? ' x' + results[8] : '');
    } else {
      return phone;
    }
  }
  const handlePhone = (e) => {
    const Regphone1 = /^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/;
    if (e.target.value === '') {
      setTimeout(() => {
        setPhoneError(null);
        setShowError(false);
      }, 2000);
      setPhoneError('Phone number cannot be empty');
      setShowError(false);
    } else if (!Regphone1.test(e.target.value)) {
      setPhoneError('Enter valid phone number !');
      setShowError(false);
    } else {
      setPhoneError('Valid Phone Number !');
      setShowError(true);
    }
    setPhoneNumber(formatUsPhone(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    UpdatePhoneNumber();
  };

  const UpdatePhoneNumber = async () => {
    const token = sessionStorage.getItem('token');
    await fetch(CONFIG.API_URL + '/cm/AddUpdateCustomerPhone', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        CustId: locationState.CustId,
        phone: PhoneNumber,
        description: Description,
        PhoneID: locationState.PhoneID,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setErrorMsg(true);
          setTimeout(() => {
            navigate('/tbm/ps/customerdetails', {
              state: {
                Custdata: {
                  custid: locationState.CustId,
                  Addressid: locationState.addressid,
                },
              },
            });
          }, 3000);
        } else {
          setAddUpdateCustomerPhoneApiErrorMsg(data.error_message);
          setAddUpdateCustomerPhoneErrorMsg(true);
        }
      });
  };

  const handleDescription = (e) => {
    if (phoneError === 'Enter valid phone number !') {
      setShowError(false);
    } else {
      setShowError(true);
    }
    setDescription(e.target.value);
  };

  const handleCancel = (e) => {
    navigate('/tbm/ps/customerdetails', {
      state: {
        Custdata: {
          custid: locationState.CustId,
          Addressid: locationState.addressid,
        },
      },
    });
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  //----------------------------------------------------------------------------------------------------------------------------------------------
  return (
    <div align='left'>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={AddUpdateCustomerPhoneErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setAddUpdateCustomerPhoneErrorMsg(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img
                onClick={() => {
                  setAddUpdateCustomerPhoneErrorMsg(false);
                }}
                src={RedDrawerCancelIcon}
                alt='RedDrawerCancelIcon'
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{AddUpdateCustomerPhoneApiErrorMsg}</div>
            </div>
          </Alert>
        </Snackbar>

        <Snackbar className='snackbar_bar_postition' open={errormsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setErrorMsg(false)}>
          <Alert className='Success2 mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel_2'>
                <img onClick={handleErrorClose} src={DrawerCancelIcon} alt='DrawerCancelIcon' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={DrawerAlertIcon} style={{ margin: '0px auto' }} className='primary_icons_dimension' alt='DrawerAlertIcon' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Customer Phone Updated Successfully..!</div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>

      <br />

      <Typography sx={{ fontWeight: 'bold', marginBottom: '20px' }}>Edit Customer Phone Number</Typography>

      <ThemeProvider theme={theme}>
        <form style={{ display: 'flex', flexDirection: 'column' }} noValidate autoComplete='off'>
          <TextField
            sx={{
              input: {
                color: 'black',
                fontWeight: 'bold',
                textTransform: 'capitalize',
              },
            }}
            className='drawer_textfieldcolor_1 formcontrol text_selectfield_upper'
            label='Description'
            onChange={handleDescription}
            value={Description}
            autoComplete='off'
          />
          <TextField sx={{ input: { color: 'black', fontWeight: 'bold' } }} className='drawer_textfieldcolor_2 formcontrol text_selectfield_upper' label='Phone Number' onChange={handlePhone} value={PhoneNumber} autoComplete='off' required />
          {phoneError ? (
            <>
              <Box sx={{ marginTop: '20px' }} align='left'>
                {phoneError !== 'Valid Phone Number !' ? (
                  <Grid
                    style={{
                      display: 'flex',
                      color: '#B42A2A',
                      position: 'relative',
                    }}
                  >
                    <ErrorRoundedIcon fontSize='medium' />
                    <Typography style={{ textAlign: 'left' }}>
                      &nbsp;
                      <span style={{ fontSize: '13px' }}>{phoneError}</span>
                    </Typography>
                  </Grid>
                ) : (
                  <Grid
                    style={{
                      display: 'flex',
                      color: 'Green',
                      position: 'relative',
                    }}
                  >
                    <Typography style={{ textAlign: 'left' }}>
                      &nbsp;
                      <span style={{ fontSize: '13px' }}>Valid Phone Number</span>
                    </Typography>
                  </Grid>
                )}
              </Box>
            </>
          ) : null}
          <hr className='button_top_space button_bottom_space' />
        </form>
      </ThemeProvider>
      <Box>
        {showerror && phoneError !== null ? (
          <Box className='submitBox'>
            <Button sx={{ padding: '0px 8px !important' }} onClick={handleSubmit} onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun} className='search_details_button submit_button_customername button_bottom_space'>
              {' '}
              <Box className='globalIconMainBox globalMarginLeftAddWindow_1 globalDrawerText'>
                {isHovered ? <img className='primary_icons_dimension_1' src={CustomerHovIcon} alt='CustomerHovIcon' /> : <img className='primary_icons_dimension_1' alt='SaveCustomerAdd' src={SaveCustomerAdd} />}
                <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  {' '}
                  SAVE CUSTOMER PHONE
                </Typography>
              </Box>
            </Button>
          </Box>
        ) : (
          <Box className='submitBox'>
            <Button sx={{ padding: '0px 8px !important' }} className='disable_submit_box_button_1 button_bottom_space' type='submit' disabled>
              {' '}
              <Box className='globalIconMainBox globalMarginLeftAddWindow_1'>
                <img className='primary_icons_dimension_disable' src={SaveCustomerAdd} alt='SaveCustomerAdd' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                  SAVE CUSTOMER PHONE
                </Typography>
              </Box>
            </Button>
          </Box>
        )}
      </Box>

      <span className='gray_button_span' onClick={handleCancel} style={{ paddingBottom: '30px', paddingLeft: '23px' }}>
        <Box onClick={() => handleCancel()} className='globalIconMainBox '>
          <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered2 ? 'hovered' : ''}`} alt='CancelIcon' />
          <Typography onMouseEnter={handleHover2} onMouseLeave={handleHover2} onClick={() => handleCancel()} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
            CANCEL
          </Typography>
        </Box>
      </span>

      <br />
    </div>
  );
};

export default EditCustomerPhoneNumber;
