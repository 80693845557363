/** @format */
import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import CONFIG from '../../config/config.js';
import Stack from '@mui/material/Stack';
import BackspaceOutlinedIcon from '../../img/backspace2.png';
import { useNavigate } from 'react-router-dom';
import Delete from '../../img/delete-key-symbol2.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerMeasurementIcon from '../../assets/icons/drawer-alert/drw-measurement.svg';
import DrawerCloseIcon from '../../assets/icons/drawer-alert/close.svg';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

export default function DrawerInput(props) {
  const [isDrawerClose, setIsDrawerClose] = useState(true);
  const [result, setResult] = useState('');
  const [result_1, setResult_1] = useState('');

  const [storeValue, setstoreValue] = useState('');
  const [StoreHeightWidth, setStoreHeightWidth] = useState();
  const [onClickCancel, setOnclickCancel] = useState(false);
  const [print, setPrint] = useState('');
  const [print_2, setPrint_2] = useState('');
  const [disable, setdisabled] = useState(false);
  const [width, setWidth] = useState('');
  const [width_2, setWidth_2] = useState('');
  const [height, setHeight] = useState('');
  const [height_2, setHeight_2] = useState('');
  const [decimalwidth, setDecimalWidth] = useState('');
  const [decimalwidth_2, setDecimalWidth_2] = useState('');
  const [decimalheight, setDecimalHeight] = useState('');
  const [decimalheight_2, setDecimalHeight_2] = useState('');
  const navigate = useNavigate();
  const [viewbuttons, setViewbuttons] = useState(false);
  const [buttons, setButtons] = useState(false);
  const [match, setMatch] = useState(true);
  const [CanclePassData, setCancelPassData] = useState(false);
  const [CancelGetDimesion, setCancelGetDimension] = useState('');
  const [cancelWidth, setcancelWidth] = useState('');
  const [cancelHeight, setcancelHeight] = useState('');
  const [VerifyStatus, setVerifyStatus] = useState('false');
  const [BlankData, setBlankData] = useState('');
  const [CloseResult, setCloseResult] = useState();
  const [limitDigit, setLimitDigit] = useState(false);
  const [limitFractionDigit, setLimitFractionDigit] = useState(false);
  const [defaultSize, setdefaultSize] = useState('');
  const [ForCheckingValueVC, setForCheckingValueVC] = useState(false);
  const [updatedWidth, setUpdatedWidth] = useState('');

  props.func(print, isDrawerClose, width, height, decimalwidth, decimalheight, result, cancelWidth, cancelHeight, CanclePassData, CancelGetDimesion, VerifyStatus, BlankData, CloseResult, storeValue, onClickCancel, StoreHeightWidth, ForCheckingValueVC, updatedWidth);

  useEffect(() => {
    if (result_1.toString().includes('x')) {
      function trimDigits(str) {
        return str.replace(/\s+/g, '');
      }
      const trimmedStr = trimDigits(result_1.trim().toString());

      let size = trimmedStr.split('x');
      let winwidth = size[0];
      let winlength = size[1];

      if (winlength.length >= 4) {
        setLimitDigit(true);
      } else {
        setLimitDigit(false);
      }

      // For Fraction

      if (winlength.length >= 5) {
        setLimitFractionDigit(true);
      } else {
        setLimitFractionDigit(false);
      }

      if (winlength.length === 0) {
        setLimitFractionDigit(true);
      }
    } else {
      if (result_1.toString().length >= 3) {
        setLimitDigit(true);
      } else {
        setLimitDigit(false);
      }

      // For Fraction
      if (result_1.toString().length >= 4) {
        setLimitFractionDigit(true);
      } else {
        setLimitFractionDigit(false);
      }

      if (result_1.toString().length === 0) {
        setLimitFractionDigit(true);
      }
    }
  }, [result, result_1]);

  useEffect(() => {
    if (result_1.toString().includes('x')) {
      function trimDigits(str) {
        return str.replace(/\s+/g, '');
      }
      const trimmedStr = trimDigits(result_1.trim().toString());
      let size = trimmedStr.split('x');
      let winwidth = size[0];
      let winlength = size[1];

      if (winlength.length > 0) {
        setMatch(true);
      } else {
        setMatch(false);
      }
    } else {
      setMatch(false);
    }
  }, [result_1]);

  const replaceLastQuote = (str, replacement) => {
    const lastQuoteIndex = str.lastIndexOf('"');

    if (lastQuoteIndex >= 0) {
      return str.substring(0, lastQuoteIndex) + replacement + str.substring(lastQuoteIndex + 1);
    }

    return str;
  };

  const handleClick2 = (e) => {
    const pattern = /(\d+(\s\d+\/\d+)?)\s*x\s*(\d+)/g;
    if (
      e.target.name === '1/4' ||
      e.target.name === '1/2' ||
      e.target.name === '3/4' ||
      e.target.name === '1/8' ||
      e.target.name === '3/8' ||
      e.target.name === '5/8' ||
      e.target.name === '7/8' ||
      e.target.name === '1/16' ||
      e.target.name === '3/16' ||
      e.target.name === '5/16' ||
      e.target.name === '7/16' ||
      e.target.name === '9/16' ||
      e.target.name === '11/16' ||
      e.target.name === '13/16' ||
      e.target.name === '15/16'
    ) {
      let newval = ' ' + e.target.name;

      if (result_1.includes('x')) {
        const replacedString = replaceLastQuote(result_1, '');
        newval = replacedString.concat(newval);
        setResult_1(newval + '"');
      } else {
        setResult_1(result_1.concat(newval));
      }
    } else {
      if (e.target.name === 'x') {
        setdisabled(true);
        let newval = '" ' + e.target.name + ' ';
        setResult_1(result_1.concat(newval));
      } else {
        let newval = e.target.name;
        let checkValue;
        setResult_1(result_1.concat(newval));
        const ResultStore = result_1.concat(newval).replace(/"/g, '');

        const finalResult = ResultStore.replace(pattern, (match, num1, num2, afterXData) => {
          checkValue = num1;

          if (num2 === undefined) {
            return num1 + '"' + ' x ' + afterXData + '"';
          } else {
            return num1 + '"' + ' x ' + afterXData + '"';
          }
        });

        setResult_1(finalResult);
      }

      if (e.target.name === 'x') {
        setdisabled(true);

        if (result_1.includes('/')) {
          var newStr = result_1.split(' ');

          var fractionalnos = newStr[1].split('/');

          var fractiontodecimal = parseInt(newStr[0]) + parseFloat(fractionalnos[0] / fractionalnos[1]);

          setWidth_2(result_1);
          setWidth(result_1);
          setDecimalWidth_2(fractiontodecimal);
          setDecimalWidth(fractiontodecimal);
        } else {
          setWidth_2(result_1);
          setWidth(result_1);
          setDecimalWidth_2('');
          setDecimalWidth('');
        }
      }
    }
  };

  const [conditionExecuted, setConditionExecuted] = useState(false);

  if (!conditionExecuted) {
    const newValue333 = result.replace(/"/g, '');

    const checkForFraction = (pattern) => {
      const fractionPattern = /\d+\s+\d+\/\d+/;

      return fractionPattern.test(newValue333);
    };

    const hasFraction = checkForFraction(newValue333);

    if (hasFraction) {
      const checkFractionPosition = (str) => {
        const parts = str.split(' ');
        for (let i = 0; i < parts.length; i++) {
          if (parts[i].includes('/')) {
            return i;
          }
        }
        return -1;
      };

      const findSecondFraction = (str, firstFractionPosition) => {
        const parts = str.split(' ');
        let secondFractionPosition = -1;

        for (let i = firstFractionPosition + 1; i < parts.length; i++) {
          if (parts[i].includes('/')) {
            secondFractionPosition = i;
            break;
          }
        }

        return secondFractionPosition;
      };

      const firstFractionPosition = checkFractionPosition(result);
      const secondFractionPosition = findSecondFraction(result, firstFractionPosition);

      // 1,5
      if (secondFractionPosition === 5) {
        const addInchesMarkAfterThirdPosition = (str) => {
          const parts = str.split(' ');
          parts[5] = `${parts[5]}"`;
          parts[4] = parts[4].replace('"', '');
          return parts.join(' ');
        };
        const valueValue = addInchesMarkAfterThirdPosition(result);

        setResult(valueValue);
      }
      // 1,6
      if (secondFractionPosition === 6) {
        const addInchesMarkAfterThirdPosition = (str) => {
          const parts = str.split(' ');
          parts[6] = `${parts[6]}"`;
          parts[5] = parts[5].replace('"', '');
          return parts.join(' ');
        };
        const valueValue = addInchesMarkAfterThirdPosition(result);

        setResult(valueValue);
      }
      // 3,-1
      if (secondFractionPosition === -1 && firstFractionPosition === 3) {
        const addInchesMarkAfterThirdPosition = (str) => {
          const parts = str.split(' ');
          parts[3] = `${parts[3]}"`;
          parts[2] = parts[2].replace('"', '');

          return parts.join(' ');
        };

        const valueValue = addInchesMarkAfterThirdPosition(result);

        setResult(valueValue);
      }
    }

    setConditionExecuted(true);
  }

  const backspace1 = () => {
    // remove height
    if (result_1.includes('x')) {
      let val = result_1.slice(0, -4);
      function trimDigits(str) {
        return str.replace(/\s+/g, '');
      }

      const trimmedStr = trimDigits(result_1.trim().toString());

      let size = trimmedStr.split('x');
      let winwidth = size[0];
      let winlength = size[1];

      if (winlength.length > 0) {
        val = result_1.slice(0, -2);
        if (winlength.length === 2) {
          setResult_1(val);
        } else {
          const lastSpaceIndex = result_1.lastIndexOf(' ');
          let lengthOfSubstring;
          if (lastSpaceIndex !== -1) {
            const substringAfterLastSpace = result_1.substring(lastSpaceIndex + 1);
            lengthOfSubstring = substringAfterLastSpace.length;
          }

          if (winlength.includes('/')) {
            if (lengthOfSubstring === 4) {
              val = result_1.slice(0, -5);
              setResult_1(val + '"');
            } else if (lengthOfSubstring === 5) {
              val = result_1.slice(0, -6);
              setResult_1(val + '"');
            } else if (lengthOfSubstring === 6) {
              val = result_1.slice(0, -7);
              setResult_1(val + '"');
            }
          } else {
            setResult_1(val + '"');
          }
        }
      } else {
        val = result_1.slice(0, -4);
        setResult_1(val);
      }

      if (val.includes('x')) {
        setdisabled(true);
      } else {
        setdisabled(false);
      }
    }
    // remove width
    else {
      setdisabled(false);
      const lastSpaceIndex = result_1.lastIndexOf(' ');
      let lengthOfSubstring;
      if (lastSpaceIndex !== -1) {
        const substringAfterLastSpace = result_1.substring(lastSpaceIndex + 1);
        lengthOfSubstring = substringAfterLastSpace.length;
      }

      let val = result_1.slice(0, -1);
      if (result_1.includes('/')) {
        if (lengthOfSubstring === 3) {
          val = result_1.slice(0, -4);

          setResult_1(val);
        } else if (lengthOfSubstring === 4) {
          val = result_1.slice(0, -5);

          setResult_1(val);
        } else if (lengthOfSubstring === 5) {
          val = result_1.slice(0, -6);

          setResult_1(val);
        }
      } else {
        setResult_1(val);
      }

      if (val.includes('x')) {
        setdisabled(true);
      } else {
        setdisabled(false);
      }
    }
  };

  const printClick2 = (e) => {
    var string = result_1;

    var sliceend = width_2.length + 3;
    var newStr = string.split('');
    newStr.splice(0, sliceend);
    newStr = newStr.join('');

    if (newStr.includes('x')) {
      let removeX = newStr.replace('x', '');
      newStr = removeX.replace(/\s/, '');
    }

    const checkPatternExist = (text) => {
      const pattern = /"/; // This is the regular expression for a double quote (")
      return pattern.test(text);
    };

    const checkDoubleQuoteExist = checkPatternExist(newStr);

    if (checkDoubleQuoteExist === true) {
      newStr = newStr.replace(/"/g, '');
    }

    if (newStr.includes('/')) {
      const trimmedText = newStr.trim();
      var newheight = trimmedText.split(' ');
      var fractionalnos = newheight[1].split('/');
      var fractiontodecimal = parseInt(newheight[0]) + parseFloat(fractionalnos[0] / fractionalnos[1]);

      setHeight_2(newStr);
      setHeight(newStr);
      setDecimalHeight_2(fractiontodecimal);
      setDecimalHeight(fractiontodecimal);
    } else {
      let new_string = newStr.replace('x', '');

      setHeight_2(new_string);
      setHeight(new_string);
      setDecimalHeight_2('');
      setDecimalHeight('');
    }

    var heightCheck = result.concat(e.target.name);
    let index = heightCheck.split('x');
    let checkvalue = index[0];

    setPrint_2(result_1);
    setPrint(result_1);
    setIsDrawerClose(false);

    if (props.measurement_type === 'Quick') {
      setVerifyStatus('false');
    } else {
      setVerifyStatus('true');
    }
    setForCheckingValueVC(true);
  };

  const closeDrawer = (e) => {
    setOnclickCancel(true);

    setIsDrawerClose(false);

    const pattern3 = /\d+\s*\d+\/\d+/;
    const hasFraction = pattern3.test(CancelGetDimesion);

    if (props.AddWindow === 'AddWindow') {
      setPrint();
    }
    setCloseResult(result);
    setCancelPassData(true);
    setBlankData('blank');
  };

  useEffect(() => {
    if (props.size) {
      setstoreValue(props.size);
      setStoreHeightWidth(props.StoreHeightWidth);

      setdefaultSize(props.size);
      setCancelGetDimension(props.size);
    }

    const pattern = /^(\d{1,3})\s+(\d\/\d{1}\s+)?x\s+"?(\d{1,3})$/;

    if (pattern.test(props.size)) {
      setMatch(true);
    }
  }, [result, match, CancelGetDimesion]);

  useEffect(() => {
    if (props.SectionOneTopHeigth) {
      setcancelHeight(props.SectionOneTopHeigth);
    }
    if (props.SectionTwoBottomWidth) {
      setcancelWidth(props.SectionTwoBottomWidth);
    }
    if (props.Width) {
      setUpdatedWidth(props.Width);
    }
  }, []);

  const [isConditionMet, setisConditionMet] = useState();

  useEffect(() => {
    conditionFun();
  }, []);

  const conditionFun = () => {
    if (props.measurement_type === 'Quick') {
      setisConditionMet(false);
    } else {
      setisConditionMet(true);
    }
  };

  const valueValidation = (e) => {
    const pattern = /^(\d{1,3})\s+(\d\/\d{1}\s+)?x\s+"?(\d{1,3})$/;

    const resultNumeric = e.target.value.replace(/^([^0-9])+([^x])+([^0-9])/g, '');
    const newValue = resultNumeric.replace(/x/g, ' $& ');
    const newValueValue = newValue.replace(/\s+/g, ' ').trim();
    if (pattern.test(newValueValue) || pattern.test(result)) {
      setMatch(true);
    } else {
      setMatch(false);
    }

    setResult(newValueValue);
  };

  const showbuttons = () => {
    setViewbuttons(true);
    setButtons(true);
  };

  const hidebuttons = () => {
    setViewbuttons(false);
    setButtons(false);
  };

  return (
    <Box>
      <Box sx={{ flexGrow: 1, paddingTop: '10px' }} align='right'>
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>

          <Grid item xs={10} md={10} sm={10}>
            <img onClick={closeDrawer} src={DrawerCloseIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='closeDrawer' />
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Box>
      <Box p={3} textAlign='center' paddingTop={0}>
        {props.measurement_type === 'Quick' || props.measurement_type === '' ? <h2 className='add_specific_fontcolor button_bottom_space'>Quick Measurement</h2> : <h2 className='add_specific_fontcolor button_bottom_space'>Final Measurement</h2>}
        {/*------------------------ Input field---------------------------- */}
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}></Grid>
            <Grid item xs={12} md={6} sm={6}>
              <Stack className='drawer_input_width_height_text'>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>WIDTH</span>
                  <span>HEIGHT</span>
                </Box>
                <TextField
                  id='filled-basic'
                  placeholder='Width x Height'
                  inputProps={{
                    className: 'width_and_height_header',
                  }}
                  value={result_1}
                  onChange={valueValidation}
                  variant='outlined'
                />
              </Stack>
              <Grid item xs={3}></Grid>
            </Grid>
          </Grid>
        </Box>
        {/* ---------------------ShowPreviousMeasurement-------------------------- */}

        {defaultSize !== '' ? (
          <Typography className='add_specific_fontcolor' sx={{ marginTop: '10px' }}>
            Previous Measurement: {defaultSize}
          </Typography>
        ) : (
          ''
        )}

        {/* ----------------------------------------------------------------------*/}
        <hr className='button_top_space button_bottom_space' />

        {/* ------------------------Calulator_Buttons---------------------------- */}
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}></Grid>

            <Grid item xs={12} md={6} sm={6}>
              <Box sx={{ marginBottom: '20px !important' }} className='calculator_drawer_digit_box_2'>
                <Box className={isConditionMet ? 'calculator_drawer_digit_box_1' : 'calculator_drawer_digit_box'}>
                  <Stack direction='row' spacing={4} className={isConditionMet ? 'button_margin_top_calculator' : 'button_margin_top_calculator_1'}>
                    <Button disabled={limitDigit} onClick={handleClick2} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='7'>
                      7
                    </Button>

                    <Button onClick={handleClick2} disabled={limitDigit} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style margin_left_button'} name='8'>
                      8
                    </Button>
                    <Button onClick={handleClick2} disabled={limitDigit} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style margin_left_button'} name='9'>
                      9
                    </Button>
                  </Stack>

                  {/* Buttons arranged in stack 4-6 */}
                  <Stack direction='row' spacing={4} disabled={limitDigit} className={isConditionMet ? 'button_margin_top_calculator' : 'button_margin_top_calculator_1'}>
                    <Button onClick={handleClick2} disabled={limitDigit} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='4'>
                      4
                    </Button>
                    <Button onClick={handleClick2} disabled={limitDigit} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style margin_left_button'} name='5'>
                      5
                    </Button>
                    <Button onClick={handleClick2} disabled={limitDigit} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style margin_left_button'} name='6'>
                      6
                    </Button>
                  </Stack>

                  {/* Buttons arranged in stack 1-3 */}
                  <Stack direction='row' spacing={4} disabled={limitDigit} className={isConditionMet ? 'button_margin_top_calculator' : 'button_margin_top_calculator_1'}>
                    <Button onClick={handleClick2} disabled={limitDigit} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='1'>
                      1
                    </Button>
                    <Button onClick={handleClick2} disabled={limitDigit} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style margin_left_button'} name='2'>
                      2
                    </Button>
                    <Button onClick={handleClick2} disabled={limitDigit} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style margin_left_button'} name='3'>
                      3
                    </Button>
                  </Stack>

                  {/* Buttons arranged in stack 0 - x */}
                  <Stack direction='row' spacing={4} disabled={limitDigit} className={isConditionMet ? 'button_margin_top_calculator' : 'button_margin_top_calculator_1'}>
                    <Button disabled={limitDigit} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='0' onClick={handleClick2}>
                      0
                    </Button>

                    <Button disabled={disable} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style margin_left_button'} name='x' onClick={handleClick2}>
                      <img
                        src={Delete}
                        name='x'
                        className='buttonIcon'
                        style={{
                          width: '40px',
                          height: '40px',
                          position: 'absolute',
                        }}
                        alt='delete'
                      />
                    </Button>

                    <Button className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator ' : 'drawer_input_buttons_style margin_left_button'} onClick={backspace1} id='backspace'>
                      <img
                        src={BackspaceOutlinedIcon}
                        className='buttonIcon'
                        style={{
                          width: '45px',
                          height: '40px',
                          position: 'absolute',
                        }}
                        alt='backspace'
                      />
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <hr className='button_top_space' />

          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              {props.measurement_type === 'Quick' || props.measurement_type === '' ? (
                ''
              ) : (
                <div>
                  {/* Buttons arranged in stack 3/4 - 1/4 */}
                  <div style={{ paddingTop: '20px', paddingBottom: '20px' }} className='measurement_fraction_main'>
                    <span>
                      <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_input_buttons_style2 ' sx={{ margin: '10px' }} name='1/4'>
                        1/4
                      </Button>
                      <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_input_buttons_style2 drawer_input_fraction_buttons' name='1/2' sx={{ margin: '10px' }}>
                        1/2
                      </Button>

                      <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_input_buttons_style2 drawer_input_fraction_buttons' name='3/4' sx={{ margin: '10px' }}>
                        3/4
                      </Button>
                    </span>
                    <span style={{ marginLeft: '60px' }}></span>

                    {/* Buttons arranged in stack 1/8 - 7/8 */}
                    <span className=''>
                      <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_input_buttons_style2 ' sx={{ margin: '10px' }} name='1/8'>
                        1/8
                      </Button>
                      <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_input_buttons_style2 drawer_input_fraction_buttons' name='3/8' sx={{ margin: '10px' }}>
                        3/8
                      </Button>
                      <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_input_buttons_style2 drawer_input_fraction_buttons' name='5/8' sx={{ margin: '10px' }}>
                        5/8
                      </Button>

                      <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_input_buttons_style2 drawer_input_fraction_buttons' name='7/8' sx={{ margin: '10px' }}>
                        7/8
                      </Button>
                    </span>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Box>

        <hr className='' />
        {/* ---------------------------Fraction_buttons------------------------ */}
        {props.measurement_type === 'Quick' || props.measurement_type === '' ? (
          ''
        ) : (
          <>
            <Box sx={{ padding: '20px 8px 20px 8px !important' }} className='drawer_iconbutton' aria-label='edit'>
              1/16ths
              {viewbuttons ? <ArrowDropUpIcon onClick={hidebuttons} fontSize='medium' sx={{ position: 'relative', top: '5px' }} /> : <ArrowDropDownIcon onClick={showbuttons} fontSize='medium' sx={{ position: 'relative', top: '5px' }} />}
            </Box>
            {buttons ? (
              <>
                <Box sx={{ flexGrow: 1 }} className=''>
                  <span>
                    <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_iconbutton' name='1/16'>
                      1/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_iconbutton' name='3/16'>
                      3/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_iconbutton' name='5/16'>
                      5/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_iconbutton' name='7/16'>
                      7/16
                    </Button>
                  </span>

                  <span className=''>
                    <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_iconbutton' name='9/16'>
                      9/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_iconbutton' name='11/16'>
                      11/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_iconbutton' name='13/16'>
                      13/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick2} className='drawer_iconbutton' name='15/16'>
                      15/16
                    </Button>
                  </span>
                </Box>
              </>
            ) : (
              ' '
            )}
            <hr className='' />
          </>
        )}
        {/* ----------------------------  Apply_final_measure------------------ */}

        {match ? (
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={3}></Grid>

              <Grid item xs={12} md={6} sm={6}>
                <span onClick={printClick2} className='drawer_gray_button_span button_top_space' style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
                  <Box className='globalIconMainBox globalDrawerText'>
                    <img src={DrawerMeasurementIcon} className='primary_icons_dimension' alt='APPLYQUICKMEASURE' />
                    <Typography
                      sx={{
                        fontFamily: 'RobotoLight',
                        textAlign: 'start',
                        width: '290px',
                      }}
                      className='drawerGlobalText'
                    >
                      {props.measurement_type === 'Quick' || props.measurement_type === '' ? 'APPLY QUICK MEASURE' : 'APPLY FINAL MEASURE'}
                    </Typography>
                  </Box>
                </span>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </Box>
        ) : (
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={3}></Grid>

              <Grid item xs={12} md={6} sm={6}>
                <span className='drawer_gray_button_span button_top_space' style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
                  <Box className='globalIconMainBox'>
                    <img src={DrawerMeasurementIcon} className='primary_icons_dimension_disable' alt='disablePrimaryIcon' />
                    <Typography
                      sx={{
                        fontFamily: 'RobotoLight',
                        textAlign: 'start',
                        width: '290px',
                      }}
                      className='drawerGlobalTextDisable'
                    >
                      {props.measurement_type === 'Quick' || props.measurement_type === '' ? 'APPLY QUICK MEASURE' : 'APPLY FINAL MEASURE'}
                    </Typography>
                  </Box>
                </span>
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </Box>
        )}

        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={3}></Grid>

            <Grid item xs={12} md={6} sm={6}>
              <span className='drawer_gray_button_span button_top_space' onClick={closeDrawer} style={{ paddingBottom: '30px', paddingLeft: '14px' }}>
                <Box className='globalIconMainBox'>
                  <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension' alt='drawerCancelIcon' />
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
                    CANCEL
                  </Typography>
                </Box>
              </span>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
