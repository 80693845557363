/** @format */

import 'moment-timezone';
import React, { forwardRef, useContext, useState, useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { Box, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TablePagination, Zoom, Checkbox, Button, Stack, Snackbar, Alert as MuiAlert, Dialog, DialogActions, DialogTitle, Drawer, Tooltip } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import fracty from 'fracty';

import VisibilityIcon from '@mui/icons-material/Visibility';

import AddWindow from './AddWindow';
import EditWindowSetName from './EditWindowSetName';

import LongText from '../LongText';
import ContinueIcon from '../../assets/icons/primary/continue.svg';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import CONFIG from '../../config/config.js';
import DeleteIcon from '../../assets/icons/secondary/2nd-delete.svg';
import ProposalAddIcon from '../../assets/icons/primary/salesorder-add.svg';
import ProposalHovIcon from '../../assets/icons/primary/WithHovIcon/salesorder-add-hov.svg';
import WindowAddIcon from '../../assets/icons/primary/window-add.svg';
import WindowHovIcon from '../../assets/icons/primary/WithHovIcon/window-add-hov.svg';
import { SnackbarContext } from '../../App';
import EditWindowListIcon from '../../assets/icons/secondary/2nd-edit.svg';
import SaveIcon from '../../assets/icons/secondary/2nd-save.svg';
import AddNoteDrawer from '../Customer/AddNoteDrawer';
import NotesDrawer from '../Proposals/NotesDrawer';
import NoteMiscIcon from '../../assets/icons/misc/misc-note.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';
import DrawerConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';

const TurquoiseTooltipforNotes = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#faf3dd',
    color: '#000000',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 300,
    maxHeight: 300,
    textAlign: 'left',
    align: 'left',
    border: '1px solid #000000',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#f2f3f2',
      border: '1px solid #000000',
    },
    top: '100%', // Position the arrow at the bottom of the tooltip box
    right: '100', // Position the arrow at the right side of the tooltip box
  },
}));

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  paper: {
    width: '90%',
    background: 'rgba(69, 157, 166, 70%)  !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

const theme1 = createTheme({
  palette: {
    border: {
      main: '1px solid #000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#FAC41E',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "green", // date picker textfield style
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#008593 !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'white', // disable date
            color: '#E9E9E9',
          },
        },
        dayWithMargin: {
          backgroundColor: 'white', // not selected date
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "blue" //calender icon & month change arrow icon style
        },
      },
    },
  },
});

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const WindowSet = () => {
  const contextvalue = useContext(SnackbarContext);

  const outerTheme = createTheme({
    palette: {
      primary: {
        main: '#333333',
      },
    },
  });

  const classes = useStyles();

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  useEffect(() => {}, []);

  const [selected, setSelected] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);

  let coveringdetails;

  if (location.state === null) {
    navigate(`/pagenotfound`);
  } else {
    coveringdetails = location.state.coveringdetails;
  }

  const [WindowSetData, setWindowSetData] = useState([]);
  const [WindowSetName, setWindowSetName] = useState('');

  const [windowsetdata, setWindowsetdata] = useState([]);
  const [customerID, setCustomerID] = useState(0);
  const [addressID, setAddressID] = useState(0);
  const [showCreateWindowComponent, setShowCreateWindowComponent] = useState(false);
  const [senddatatonewWindow, setSendDatatoNewWindow] = useState('');

  const [checkboxflag, setCheckboxFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteWS, setDeleteWS] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [ConfirmationMsg, setConfirmationMsg] = useState(false);
  const [show, setShow] = useState(false);
  const vertical = 'top';
  const horizontal = 'center';
  const [checkedrowdata, setCheckedRowData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [showSA, setShowSA] = useState(true);
  const [isWSDrawerOpen, setIsWSDrawerOpen] = useState(false);
  const [isAddNoteDrawerOpen, setIsAddNoteDrawerOpen] = useState(false);
  const [alertmessage, setAlertMessage] = useState('');
  const [classtype, setClassType] = useState('');
  const [addWindowSuccess, setAddWindowSuccess] = useState(false);
  const [appliedclass, setAppliedClass] = useState('table-contentwsidwithcheckbox_2 sticky-columnws-1');
  const [indexCheckboxClass, setIndexcheckboxclass] = useState('table-headerwsidwithcheckbox sticky-columnws-1');
  const [proposalCount, setProposalCount] = useState(0);
  const [windowCount, setWindowCount] = useState();
  const [windowSetSuccessMsg, setWindowSetSuccessMsg] = useState();
  const [defaultOptionValues, setdefaultOptionValues] = useState();
  const [defaultValuesStatus, setdefaultValuesStatus] = useState(false);

  // error handling states

  const [GetLookupTableDataErrorMsg, setGetLookupTableDataErrorMsg] = useState(false);
  const [GetLookupTableDataErrorMsgTxt, setGetLookupTableDataErrorMsgTxt] = useState('');

  const [DeleteWindowSetErrorMsg, setDeleteWindowSetErrorMsg] = useState(false);
  const [DeleteWindowSetErrorMsgTxt, setDeleteWindowSetErrorMsgTxt] = useState('');

  const [CreateProposalErrorMsg, setCreateProposalErrorMsg] = useState(false);
  const [CreateProposalErrorMsgTxt, setCreateProposalErrorMsgTxt] = useState('');

  const [NotesErrorMsg, setNotesErrorMsg] = useState(false);
  const [NotesErrorMsgTxt, setNotesErrorMsgTxt] = useState('');

  const [WindowSetErrorMsg, setWindowSetErrorMsg] = useState(false);
  const [WindowSetApiErrMsg, setWindowSetApiErrMsg] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  const handleHoverFun2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeaveFun2 = () => {
    setIsHovered2(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    getVerified();
    ChangeValue();
  }, []);

  const getSaleAppDropDowns = async () => {
    const response = await fetch(`${CONFIG.API_URL}/wm/GetLookupTableData`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      token: token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
        } else {
          setGetLookupTableDataErrorMsgTxt(data.error_message);
          setGetLookupTableDataErrorMsg(true);
        }
      });
  };

  const ChangeValue = () => {
    setTimeout(function () {
      contextvalue.setOpensnackbar(false);
    }, 5000);
  };
  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const deleteWindowSet = async () => {
    const options = {
      method: 'DELETE',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        window_set_id: windowSetID,
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/wm/window-set`, options);
    const data = await response.json();
    if (!response.ok || data.error) {
      setDeleteWindowSetErrorMsgTxt(data.error_message);
      setDeleteWindowSetErrorMsg(true);
      return;
    }
    if (data.message === 'WindowSet deleted Successfully!') {
      setWindowSetSuccessMsg(data.message);
      setDeleteWS(true);
      setTimeout(() => {
        navigate(`/tbm/ps/customerdetails`, {
          state: {
            Custdata: { custid: customerID, Addressid: addressID },
            WindowSetSuccessMsg: data.message,
          },
        });
      }, 3000);
    }
  };
  let isItemSelected;
  function WindowsTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='table-headerwsname sticky-columnws-2'>
            <span>#</span>
            <span style={{ marginLeft: '30px' }}>Window Name</span>
          </TableCell>
          <TableCell className='table-headersw3'>Size</TableCell>
          <TableCell className='table-headersw3'>Bld</TableCell>
          <TableCell className='table-headersw3'>Surface</TableCell>
          <TableCell className='table-headersw3'>Ladder</TableCell>
          <TableCell className='table-headersw3 ellipsis2'>Ex. Cover</TableCell>
          <TableCell className='table-header4'>Note</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  useEffect(() => {
    if (location.state && location.state.selectedwindows && location.state.selectedwindows.length > 0) {
      setCheckboxFlag(false);
      setAppliedClass('table-contentwsidwithcheckbox_2 sticky-columnws-1');
      setIndexcheckboxclass('table-headerwsidwithcheckbox sticky-columnws-1');
      location.state.selectedwindows.forEach((element) => {
        setSelected(location.state.selectedwindows);
        setCheckedRowData(location.state.selectedwindows);

        const selectedIndex = location.state.totalwindows.indexOf(element);

        let newSelected = [];

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, element);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
      });
    } else {
      setCheckboxFlag(true);
      setAppliedClass('table-contentwsid2 sticky-columnws-1');
      setIndexcheckboxclass('table-headerwsid sticky-columnws-1');
    }
    getSaleAppDropDowns();
    getWindowSet();
    windowSetCreatedFun();
    getNotes();
  }, [customerID, WindowSetName]);

  const WindowSetNameUpdateDrawer = (name, drawerstatus, message, status) => {
    setIsWSDrawerOpen(drawerstatus);
    setAlertMessage(message);
    if (message === 'Window set name is updated successfully!') {
      setWindowSetName(name);
      setOpen(true);
      setClassType('Success2 mainAlert');
    } else if (message === 'Window set name already exists.') {
      setOpen(true);
      setClassType('Error mainAlert');
    } else {
      setOpen(false);
      setClassType('');
    }
  };

  const DeleteWindowConfirmationFun = () => {
    setConfirmationMsg(true);
  };

  const DeleteWindowAlertClose = () => {
    setConfirmationMsg(false);
  };

  const AddNoteDrawerFun = (drawerstatus) => {
    setIsAddNoteDrawerOpen(drawerstatus);
  };

  const [windowSetID, setWindowSetID] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  const getWindowSet = async () => {
    const options = {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/wm/window-set/${params.id}`, options);
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Unauthorized Please Login' },
        });
      }

      setWindowSetApiErrMsg(data.error_message);
      setWindowSetErrorMsg(true);
      return;
    }

    setProposalCount(+data.window_set.proposal_count);
    setWindowCount(data.window_set.window_count);

    setWindowSetData(data.window);
    setWindowsetdata(data.window_set);
    setWindowSetName(data.window_set.window_set_name);
    setCustomerID(data.window_set.customer_id);
    setAddressID(data.window_set.address_id);
    setSendDatatoNewWindow(windowsetdata);
    setWindowSetID(data.window_set.window_set_id);
    setLastName(data.window_set.last_name);
    setFirstName(data.window_set.first_name);
    setdefaultValuesStatus(data.window_set.has_default_values);
    setdefaultOptionValues(data.window_set.has_default_values ? data.default_options : null);
  };

  const rows = WindowSetData;

  function handleChangeState(newValue, val, quantity) {
    setShowCreateWindowComponent(newValue);
    if (val) {
      setAlertMessage('');
      handleNotificationClick(quantity);
      getWindowSet();
    }
  }

  const SaveProposal = () => {
    navigate(`/tbm/ps/customerdetails`, {
      state: { Custdata: { custid: customerID, Addressid: addressID } },
    });
  };

  const createWindow = () => {
    setShowCreateWindowComponent(true);
    setIsHovered(false);
  };

  const selectForProposal = () => {
    navigate(`/tbm/ps/CreateProposal`, {
      state: {
        creatingProposal: location.state,
        windowsetdata: WindowSetData,
        windowSetID: windowSetID,
      },
    });
  };
  // New Proposal will be created
  const createProposal = async () => {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        window_set_id: params.id,
        windows: WindowSetData,
      }),
    };
    // Created Proposal will be added to the database through CreateProposal API
    await fetch(`${CONFIG.API_URL}/pm/CreateProposal`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setLoading(true);
          // Screen will redirect to ProposalSelectWindow page
          setTimeout(() => {
            navigate(`/tbm/ps/proposalselectwindow/${data.proposal_id}`, {
              state: {
                coveringdetails: coveringdetails,
                selectedwindows: checkedrowdata,
                customerdata: windowsetdata,
                totalwindows: WindowSetData,
                proposalID: data.proposal_id,
                proposalName: data.proposal_name,
              },
            });
          }, 1000);
        } else {
          setCreateProposalErrorMsgTxt(data.error_message);
          setCreateProposalErrorMsg(true);
        }
      });
  };

  const cancelProposalSelection = () => {
    checkedrowdata.forEach((item) => {
      const idx = WindowSetData.findIndex((e) => e === item);
      if (idx >= 0) {
        WindowSetData[idx].selected = false;
      }
    });

    setCheckboxFlag(true);
    setAppliedClass('table-contentwsid2 sticky-columnws-1');
    setIndexcheckboxclass('table-headerwsid sticky-columnws-1');
    setCheckedRowData([]);
    setSelected([]);
    setShowSA(true);
  };

  const handleNotificationClick = (n) => {
    setOpen(true);
    setClassType('Success2 mainAlert');

    if (n === 1) {
      setAlertMessage('New Window Created Successfully');
    } else {
      setAlertMessage('New Windows Created Successfully');
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
  };

  const handleSelectAllClick = (event) => {
    if (event === true) {
      const newSelecteds = rows.map((n) => n);

      setSelected(newSelecteds);
      setCheckedRowData(newSelecteds);
      newSelecteds.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = true;
        }
      });
      return;
    } else {
      const newSelecteds = rows.map((n) => n);

      newSelecteds.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = false;
        }
      });
      setCheckedRowData([]);
      setSelected([]);
    }
  };

  const handleClick = (event, row) => {
    if (event.target.checked === true) {
      setShowSA(true);
      const selectedIndex = selected.indexOf(row);

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      newSelected.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = true;
        }
      });

      for (var i = 0; i < newSelected.length; i++) {
        if (WindowSetData[i].selected === newSelected[i].selected) {
        }
      }

      if (WindowSetData.length === newSelected.length) {
        setShowSA(false);
      }

      setCheckedRowData(newSelected);
      setSelected(newSelected);
    } else {
      const selectedIndex = selected.indexOf(row);
      const selectedActualIndex = WindowSetData.indexOf(row);

      WindowSetData[selectedActualIndex].selected = false;

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      if (WindowSetData.length > newSelected.length) {
        setShowSA(true);
      }

      setCheckedRowData(newSelected);
      setSelected(newSelected);
    }
  };

  const isSelected = (row) => selected.indexOf(row) !== -1;

  const DialogueClose = () => setDeleteWS(false);

  const navigateCD = () => {
    navigate(`/tbm/ps/customerdetails`, {
      state: {
        Custdata: { custid: customerID, Addressid: addressID },
      },
    });
  };

  const onSelectAllClick = () => {
    setShowSA(false);
    handleSelectAllClick(true);
  };
  const onDeSelectAllClick = () => {
    setShowSA(true);
    handleSelectAllClick(false);
  };

  const windowSetCreatedFun = () => {
    if (contextvalue.opensnackbar === true) {
      setAddWindowSuccess(true);
      setSuccessOpen(true);
    }
  };
  // Windowset created successfully!

  const [ExistingSku, setExistingSku] = useState();
  const [BuildSku, setBuildSku] = useState();
  const [MountSku, setMountSku] = useState();
  const [LadderSku, setLadderSku] = useState();
  const [WindowDepthSku, setWindowDepthSku] = useState();
  const [HeadrailSku, setHeadrailSku] = useState();
  const [ObstructionsSku, setObstructionsSku] = useState();
  const [MultipleSku, setMultipleSku] = useState();
  const [RollDiectionSku, setRollDiectionSku] = useState();

  const [EditNotes, setEditNote] = useState('');
  const [notes, setNotes] = useState(false);
  const [notesData, setNotesData] = useState('');
  const [noteTypeId, setNoteTypeId] = useState('');
  const [notesContent, setnotesContent] = useState([]);
  const [noteId, setNoteId] = useState([]);
  const [eyeButtonMsg, setEyeButtonMsg] = useState(false);
  const [notesDataDescription, setNotesDataDescription] = useState([]);

  const fetchdata = (ExistingSku, BuildSku, MountSku, LadderSku, WindowDepthSku, HeadrailSku, ObstructionsSku) => {
    setExistingSku(ExistingSku);
    setBuildSku(BuildSku);
    setMountSku(MountSku);
    setLadderSku(LadderSku);
    setWindowDepthSku(WindowDepthSku);
    setHeadrailSku(HeadrailSku);
    setObstructionsSku(ObstructionsSku);
  };

  const additional_sku_data = {
    ExistingSku: ExistingSku,
    BuildSku: BuildSku,
    MountSku: MountSku,
    LadderSku: LadderSku,
    WindowDepthSku: WindowDepthSku,
    HeadrailSku: HeadrailSku,
    ObstructionsSku: ObstructionsSku,
    MultipleSku: MultipleSku,
    RollDiectionSku: RollDiectionSku,
  };

  const getNotes = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/Notes/${windowSetID}`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      return console.error('Error', data.error_message);
    }
    setnotesContent(data.Notes);
  };

  const NotesCustomerDetails = {
    firstName: firstName,
    lastname: lastName,
    customerId: customerID,
    proposalId: '',
    windowSetId: windowSetID,
    WindowSetName: WindowSetName,
  };

  const [valueValue, setValueValue] = useState();
  const EditNotesCustomerDetails = {
    firstName: firstName,
    lastname: lastName,
    customerId: customerID,
    proposalId: '',
    windowSetId: '',
    WindowSetName: WindowSetName,
    notesContent: notesData,
    noteId: noteId,
    noteTypeId: noteTypeId,
    eyeButtonMsg: eyeButtonMsg,
    notesDataDescription: notesDataDescription,
    valueValue: valueValue,
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const handleTextClick = () => {
    setIsImageHovered(false);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  const handleTextClick2 = () => {
    setIsImageHovered2(false);
  };

  const [isImageHovered3, setIsImageHovered3] = useState(false);
  const handleHover3 = () => {
    setIsImageHovered3(!isImageHovered3);
  };

  const handleTextClick3 = () => {
    setIsImageHovered3(false);
  };

  const [isImageHovered4, setIsImageHovered4] = useState(false);
  const handleHover4 = () => {
    setIsImageHovered4(!isImageHovered4);
  };

  const [isImageHovered5, setIsImageHovered5] = useState(false);
  const handleHover5 = () => {
    setIsImageHovered5(!isImageHovered5);
  };

  const handleTextClick5 = () => {
    setIsImageHovered5(false);
  };

  const [isImageHovered6, setIsImageHovered6] = useState(false);
  const handleHover6 = () => {
    setIsImageHovered6(!isImageHovered6);
  };

  const handleTextClick6 = () => {
    setIsImageHovered6(false);
  };

  const [isImageHovered7, setIsImageHovered7] = useState(false);
  const handleHover7 = () => {
    setIsImageHovered7(!isImageHovered7);
  };

  const handleTextClick7 = () => {
    setIsImageHovered7(false);
  };

  const [isImageHovered8, setIsImageHovered8] = useState(false);
  const handleHover8 = () => {
    setIsImageHovered8(true);
  };
  const handleHover8_1 = () => {
    setIsImageHovered8(false);
  };

  const handleTextClick8 = () => {
    setIsImageHovered8(false);
  };

  const navigateNextPage = () => {
    navigate(`/tbm/ps/editwindowset/${params.id}`, {
      state: {
        coveringdetails: coveringdetails,
        customerdata: windowsetdata,
        totalwindows: WindowSetData,
      },
    });
    handleTextClick3();
  };

  const [editingNote, setEditingNote] = useState(false);
  const [noteDrawerDetails, setNoteDrawerDetails] = useState({});
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);

  const closeNotesDrawer = ({ error, success }) => {
    setNotesDrawerOpen(false);
    setEditingNote(false);
    setNoteDrawerDetails({});

    if (success === 'Note added successfully!' || success === 'Note updated successfully!') {
      getNotes();
    }
  };

  const openNotesDrawer = (note) => {
    setEditingNote(!!note);
    setNoteDrawerDetails({
      name: WindowSetName,
      referenceId: windowSetID,
      noteId: note ? note.id : null,
    });
    setNotesDrawerOpen(true);
  };

  return (
    <div className='padding_bottom_class'>
      <ThemeProvider theme={theme1}>
        <Drawer
          classes={{ paper: classes.paper }}
          PaperProps={{
            sx: { width: '90%' },
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          anchor='right'
          open={notesDrawerOpen}
          onClose={() => setNotesDrawerOpen(false)}
          className='drawer_Header_page'
        >
          <NotesDrawer closeDrawer={closeNotesDrawer} editing={editingNote} type='window_set' details={noteDrawerDetails} />
        </Drawer>
      </ThemeProvider>
      <ThemeProvider theme={outerTheme}>
        {/* <CreateNewWinddowSet /> */}

        <div align='center'>
          {/* Error handling Alerts start */}

          <Stack spacing={2} sx={{ width: '100%' }}>
            {/* GetLookupTableDataErrorMsg */}
            <Snackbar className='snackbar_bar_postition' open={GetLookupTableDataErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setGetLookupTableDataErrorMsg(false)}>
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setGetLookupTableDataErrorMsg(false);
                    }}
                    src={RedDrawerCancelIcon}
                    alt='CANCELICONGLT'
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img src={RedExclamationIcon} alt='CONFIRMICONGLT' className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{GetLookupTableDataErrorMsgTxt}</div>
                </div>
              </Alert>
            </Snackbar>

            {/* DeleteWindowSetErrorMsg */}
            <Snackbar className='snackbar_bar_postition' open={DeleteWindowSetErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setDeleteWindowSetErrorMsg(false)}>
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setDeleteWindowSetErrorMsg(false);
                    }}
                    src={RedDrawerCancelIcon}
                    alt='CANCELICONREDDWS'
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img src={RedExclamationIcon} alt='CONFIRMICONREDDWS' className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{DeleteWindowSetErrorMsgTxt}</div>
                </div>
              </Alert>
            </Snackbar>

            {/* WindowSetErrorMsg */}
            <Snackbar className='snackbar_bar_postition' open={WindowSetErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setWindowSetErrorMsg(false)}>
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setWindowSetErrorMsg(false);
                    }}
                    src={RedDrawerCancelIcon}
                    alt='CANCELICONREDWS'
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img src={RedExclamationIcon} alt='CONFIRMICONREDWS' className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{WindowSetApiErrMsg}</div>
                </div>
              </Alert>
            </Snackbar>

            {/* CreateProposalErrorMsg */}
            <Snackbar className='snackbar_bar_postition' open={CreateProposalErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setCreateProposalErrorMsg(false)}>
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setCreateProposalErrorMsg(false);
                    }}
                    src={RedDrawerCancelIcon}
                    alt='CANCELICONCP'
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img src={RedExclamationIcon} alt='CONFIRMICONCP' className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{CreateProposalErrorMsgTxt}</div>
                </div>
              </Alert>
            </Snackbar>

            {/* NotesErrorMsg */}
            <Snackbar className='snackbar_bar_postition' open={NotesErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setNotesErrorMsg(false)}>
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setNotesErrorMsg(false);
                    }}
                    src={RedDrawerCancelIcon}
                    alt='CANCELICONNOTES'
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img src={RedExclamationIcon} alt='CONFIRMICONNOTES' className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{NotesErrorMsgTxt}</div>
                </div>
              </Alert>
            </Snackbar>
          </Stack>

          {/* Error handling Alerts end */}

          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setOpen(false)}>
              {classtype === 'Success2 mainAlert' ? (
                <Alert className={classtype} variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img onClick={handleSuccessClose} src={DrawerCancelIcon} alt='CANCELICONSCS' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img src={DrawerConfirmIcon} alt='CONFIRMICONSCS' className='primary_icons_dimension alertImageMargin' />
                    <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{alertmessage}</div>
                  </div>
                </Alert>
              ) : (
                <Alert className={classtype} variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img onClick={handleSuccessClose} src={RedDrawerCancelIcon} alt='CANCELICONRED' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img src={RedExclamationIcon} alt='CONFRIMICONRED' className='primary_icons_dimension alertImageMargin' />
                    <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{alertmessage}</div>
                  </div>
                </Alert>
              )}
            </Snackbar>
          </Stack>

          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={successOpen} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setSuccessOpen(false)}>
              {classtype === 'Success2 mainAlert' ? (
                ''
              ) : (
                <Alert className='Success2 mainAlert' variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img onClick={handleClose} src={DrawerCancelIcon} alt='CANCELICONWSC' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img src={DrawerConfirmIcon} alt='CONFIRMICONWSC' className='primary_icons_dimension alertImageMargin' />
                    <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Window Set created successfully!</div>
                  </div>
                </Alert>
              )}
            </Snackbar>
          </Stack>

          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={ConfirmationMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setConfirmationMsg(false)}>
              <Alert className='Success mainAlert' variant='filled' icon={false}>
                <div className='alertCancel' align='right'>
                  <img onClick={DeleteWindowAlertClose} src={DrawerCancelIcon} alt='CANCELICONDW' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                </div>

                <div className='alertMainNew'>
                  <div align='left' style={{ paddingTop: 37 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'Center',
                      }}
                    >
                      <Box className='alertCantinueButton'>
                        <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='ALERTICOND' />
                      </Box>
                    </Box>

                    <div
                      className='alertTextAlign'
                      style={{
                        paddingTop: 20,
                        fontSize: '20px',
                        fontFamily: 'RobotoRegular',
                      }}
                    >
                      Delete Window Set
                    </div>
                  </div>

                  <div
                    align='left'
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '20px 10px 0px 24px',
                      justifyContent: 'center',
                      alignItems: 'start',
                      lineHeight: '24px',
                    }}
                  >
                    <Box
                      onClick={() => {
                        setConfirmationMsg(false);
                        deleteWindowSet();
                      }}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'Center',
                        cursor: 'pointer',
                        marginBottom: '20px',
                      }}
                    >
                      <Box onClick={() => {}} className='alertCantinueButton'>
                        <img src={DrawerConinueIcon} className='primary_icons_dimension' alt='CONTINUEICOND' />
                      </Box>
                      <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                        CONTINUE
                      </Typography>
                    </Box>

                    <Box
                      onClick={DeleteWindowAlertClose}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'Center',
                        cursor: 'pointer',
                        paddingBottom: '16px',
                        paddingLeft: '15px',
                      }}
                    >
                      <Box className='globalIconMainBox'>
                        <Box className='iconSecondaryButtonGlobalForDrawer'>
                          <img src={DrawerCancelIcon} alt='CANCELICOND' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                        </Box>
                        <Typography className='globalTextForDrawer'>CANCEL</Typography>
                      </Box>
                    </Box>
                  </div>
                </div>
              </Alert>
            </Snackbar>
          </Stack>

          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={deleteWS} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setDeleteWS(false)}>
              {classtype === 'Success' ? (
                ''
              ) : (
                <Alert className='Success2 mainAlert' variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img onClick={handleClose} src={DrawerCancelIcon} alt='CANCELICONWS' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img src={DrawerConfirmIcon} alt='CONFIRMICONWS' className='primary_icons_dimension alertImageMargin' />

                    <div align='center' className='alertLeftPadding alertSpacing alertSpacing1'>
                      {windowSetSuccessMsg}
                    </div>
                  </div>
                </Alert>
              )}
            </Snackbar>
          </Stack>

          <Drawer
            PaperProps={{
              sx: { width: '90%' },
            }}
            BackdropProps={{
              classes: {
                root: classes.backDrop,
              },
            }}
            classes={{ paper: classes.paper }}
            anchor='right'
            open={isAddNoteDrawerOpen}
            onClose={() => setIsAddNoteDrawerOpen(true)}
            sx={{ zIndex: '100000' }}
          >
            <AddNoteDrawer func={AddNoteDrawerFun} WindowSetName={WindowSetName} />
          </Drawer>

          <div className='heading-box-1'>
            <h2 className='clickable_header all_pages_h2_header ' onClick={() => setIsWSDrawerOpen(true)}>
              <LongText>{WindowSetName}</LongText>
            </h2>

            <h3
              className='clickable_header_1 all_pages_h2_header center button_top_space common_line_height_css'
              style={{
                textTransform: 'capitalize',
                textAlign: 'center !important',
              }}
              onClick={() => {
                navigate(`/tbm/ps/customerdetails`, {
                  state: {
                    Custdata: { custid: customerID, Addressid: addressID },
                  },
                });
              }}
            >
              <LongText>{`${windowsetdata.last_name} | ${windowsetdata.city}`}</LongText>
            </h3>

            <Box sx={{ position: 'relative', float: 'right', top: '-55px' }}>
              <img src={NoteMiscIcon} className='misc_icons_dimensions' onClick={() => openNotesDrawer()} alt='NOTES' />
            </Box>
          </div>
        </div>

        <Box sx={{ marginBottom: '20px', marginTop: '20px' }}>
          {notesContent.map((note, index) => (
            <Box key={note.id} sx={{ display: 'flex' }}>
              {note.visible ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : null}{' '}
              <Box>
                <Typography className='' onClick={() => openNotesDrawer(note)}>
                  {' '}
                  <span
                    style={{
                      color: '#008593',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                    }}
                  >
                    Note #{index + 1} :
                  </span>{' '}
                  {note.content}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Dialog open={show} onClose={DialogueClose}>
          <DialogTitle id='alert-dialog-title'>
            <div align='center' style={{ paddingLeft: '1%', paddingRight: '1%' }}>
              <span style={{ color: 'red' }}> {'Are you sure you want to delete '} </span>
              <br />
              {windowsetdata.window_set_name} {'?'}
            </div>
          </DialogTitle>

          <DialogActions>
            <div style={{ display: 'flex', width: '100%' }}>
              <Button
                className='small_buttons'
                onClick={() => {
                  navigateCD();
                }}
              >
                Yes
              </Button>{' '}
            </div>
            <Button className='small_buttons' onClick={DialogueClose}>
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Drawer
          PaperProps={{
            sx: { width: '90%' },
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          classes={{ paper: classes.paper }}
          anchor='right'
          open={isWSDrawerOpen}
          onClose={() => setIsWSDrawerOpen(true)}
          sx={{ zIndex: '100000' }}
        >
          <EditWindowSetName windowdetails={windowsetdata} func={WindowSetNameUpdateDrawer} />
        </Drawer>
        {!checkboxflag ? (
          showSA ? (
            <span style={{ marginLeft: '16px' }} className='gray_button_span button_top_space'>
              <Box onClick={onSelectAllClick} className='globalIconMainBox'>
                <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered8 ? 'hovered' : ''}`} alt='SELECTALL' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover8} onMouseLeave={handleHover8_1} onClick={handleTextClick8}>
                  SELECT ALL
                </Typography>
              </Box>
            </span>
          ) : (
            <span style={{ marginLeft: '16px' }} className='gray_button_span button_top_space'>
              <Box onClick={onDeSelectAllClick} className='globalIconMainBox'>
                <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered8 ? 'hovered' : ''}`} alt='DESELECTALL' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover8} onMouseLeave={handleHover8_1} onClick={handleTextClick8}>
                  DESELECT ALL
                </Typography>
              </Box>
            </span>
          )
        ) : null}
        <hr className='button_bottom_space button_top_space' />
        <Box className='table-box-1'>
          <TableContainer sx={{ borderBottom: '1px solid #D6D6D6' }} className='table_padding_bottom'>
            <Table className='windowset-table-dimension' aria-labelledby='tableTitle' size='small'>
              <WindowsTableHead numSelected={selected.length} onSelectAllClick={handleSelectAllClick} rowCount={rows.length} />
              <TableBody>
                {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((window, index) => {
                  isItemSelected = isSelected(window);

                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover className='rowhover' onClick={(event) => handleClick(event, window)} role='checkbox' aria-checked={isItemSelected} tabIndex={-1} key={window.window_id}>
                      <TableCell className={appliedclass} align='left'>
                        {' '}
                        <span className='ellipsis2'>
                          {!checkboxflag ? (
                            <Checkbox
                              sx={{
                                zoom: 0.8,
                                paddingTop: '0px',
                                marginLeft: '-5px',
                              }}
                              color='primary'
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          ) : (
                            ''
                          )}

                          <span
                            style={{
                              paddingRight: `${rows.indexOf(window) + 1 > 9 ? '20px' : '28px'}`,
                            }}
                          >
                            {rows.indexOf(window) + 1}
                          </span>
                          <Link
                            className='link ellipsis2'
                            to='/tbm/ps/window'
                            state={{
                              data: windowsetdata,
                              row_id: window.window_id,
                              customer_id: windowsetdata.customer_id,
                              additional_sku_data: additional_sku_data,
                              windowSetID: windowSetID,
                            }}
                          >
                            <LongText>{window.window_name}</LongText>
                          </Link>
                        </span>
                      </TableCell>

                      <TableCell className='table-content ellipsis2' align='left'>
                        {fracty(window.window_width)}" x {fracty(window.window_height)}"{' '}
                        <span className={window.measurement_type.replace(/ /g, '')}>
                          {window.measurement_type === 'Quick'
                            ? '(' + 'QK' + ')'
                            : window.measurement_type === 'Shutters'
                            ? '(' + 'SH' + ')'
                            : window.measurement_type === 'Blinds'
                            ? '(' + 'BL' + ')'
                            : '(' +
                              window.measurement_type
                                .split(' ')
                                .map((word) => word[0])
                                .join('') +
                              ')'}
                        </span>
                      </TableCell>
                      <TableCell className='table-content' align='left'>
                        {window.build_type}
                      </TableCell>
                      <TableCell className='table-content' align='left'>
                        {window.mount_surface}
                      </TableCell>
                      <TableCell className='table-content' align='left'>
                        {!isNaN(window.ladder) ? window.ladder + "'" : window.ladder}
                      </TableCell>
                      <TableCell className='table-content' align='left'>
                        {window.existing_covering}
                      </TableCell>
                      <TableCell className='table-content' align='left'>
                        {window.notes.length ? (
                          <TurquoiseTooltipforNotes
                            title={
                              <>
                                <Typography>
                                  {window.notes.map((note, index) => (
                                    <div key={note.id}>
                                      {index > 0 && <br />}
                                      <b>Note:</b> {note.content}
                                    </div>
                                  ))}
                                </Typography>
                              </>
                            }
                            placement='bottom-end'
                            TransitionComponent={Zoom}
                            arrow
                          >
                            <Link
                              className='link'
                              to='/tbm/ps/window'
                              state={{
                                data: windowsetdata,
                                row_id: window.window_id,
                                customer_id: windowsetdata.customer_id,
                                additional_sku_data: additional_sku_data,
                              }}
                            >
                              {window.notes.length}
                            </Link>
                          </TurquoiseTooltipforNotes>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{
              mx: 0,
              px: 0,
              textAlign: 'center',
            }}
            rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPageprp) => {
              setPage(newPageprp);
            }}
            onRowsPerPageChange={(eventprp) => {
              setRowsPerPage(eventprp.target.value, 10);
              setPage(0);
            }}
          />
        </Box>
        {checkboxflag ? (
          !showCreateWindowComponent ? (
            <>
              <Box className='button-box-1'>
                <span className='button_span' onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
                  <Box onClick={createWindow} className='globalIconMainBox globalMarginLeftAddWindow'>
                    {isHovered ? <img alt='ADDNEWWINDOWH' className='primary_icons_dimension_1' src={WindowHovIcon} /> : <img alt='ADDNEWWINDOW' className='primary_icons_dimension_1' src={WindowAddIcon} />}
                    <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover} onMouseLeave={handleHover} onClick={handleTextClick}>
                      ADD NEW WINDOW
                    </Typography>
                  </Box>
                </span>

                <span className='button_span button_top_space button_bottom_space' onMouseEnter={handleHoverFun2} onMouseLeave={handleMouseLeaveFun2}>
                  <Box onClick={selectForProposal} className='globalIconMainBox globalMarginLeftAddWindow'>
                    <img alt='Create Proposal' className='primary_icons_dimension_1' src={isHovered2 ? ProposalHovIcon : ProposalAddIcon} />
                    <Typography onMouseEnter={handleHover2} onMouseLeave={handleHover2} onClick={handleTextClick2} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                      CREATE PROPOSAL
                    </Typography>
                  </Box>
                </span>
              </Box>

              <span style={{ paddingLeft: '22px' }} className='gray_button_span button_top_space' onClick={navigateNextPage}>
                <Box className='globalIconMainBox'>
                  <img src={EditWindowListIcon} className={`secondary_icons_dimension ${isImageHovered3 ? 'hovered' : ''}`} alt='EDITWINDOWLIST' />
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover3} onMouseLeave={handleHover3}>
                    {' '}
                    EDIT WINDOW LIST
                  </Typography>
                </Box>
              </span>

              {proposalCount === 0 ? (
                <span style={{ paddingLeft: '22px' }} className='gray_button_span button_top_space' onClick={DeleteWindowConfirmationFun}>
                  <Box onClick={DeleteWindowConfirmationFun} className='globalIconMainBox'>
                    <img src={DeleteIcon} className={`secondary_icons_dimension ${isImageHovered4 ? 'hovered' : ''}`} alt='DELETEWINDOWSET' />
                    <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover4} onMouseLeave={handleHover4}>
                      {' '}
                      DELETE WINDOW SET
                    </Typography>
                  </Box>
                </span>
              ) : (
                <span style={{ paddingLeft: '22px', cursor: 'not-allowed' }} className='gray_button_span_delete button_top_space'>
                  <Box className='globalIconMainBox'>
                    <img src={DeleteIcon} alt='DELETEWINDOWSET' className='secondary_icons_dimension_disable' />
                    <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                      {' '}
                      DELETE WINDOW SET
                    </Typography>
                  </Box>
                </span>
              )}

              <span style={{ paddingLeft: '22px' }} className='gray_button_span button_top_space'>
                <Box onClick={SaveProposal} className='globalIconMainBox'>
                  <img src={SaveIcon} className={`secondary_icons_dimension ${isImageHovered5 ? 'hovered' : ''}`} alt='SAVEWINDOWSET' />
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover5} onMouseLeave={handleHover5} onClick={handleTextClick5}>
                    {' '}
                    SAVE WINDOW SET
                  </Typography>
                </Box>
              </span>

              <br />
            </>
          ) : (
            <AddWindow location={senddatatonewWindow} value={showCreateWindowComponent} checkbuttonOption={handleChangeState} func={fetchdata} defaultValuesStatus={defaultValuesStatus} defaultOptionValues={defaultOptionValues} />
          )
        ) : checkedrowdata.length > 0 ? (
          <Box sx={{ width: '100%' }}>
            <span className='button_span button_top_space button_bottom_space'>
              <Box onClick={createProposal} className='globalIconMainBox globalMarginLeftAddWindow'>
                <img src={ContinueIcon} className={`primary_icons_dimension ${isImageHovered6 ? 'hovered' : ''}`} alt='CONTINUE' />
                <Typography onMouseEnter={handleHover6} onMouseLeave={handleHover6} onClick={handleTextClick6} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  CONTINUE
                </Typography>
              </Box>
            </span>

            <hr />

            <span className='gray_button_span button_top_space' style={{ paddingBottom: '30px', paddingLeft: '22px' }}>
              <Box onClick={cancelProposalSelection} className='globalIconMainBox'>
                <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered7 ? 'hovered' : ''}`} alt='CANCEL' />
                <Typography onMouseEnter={handleHover7} onMouseLeave={handleHover7} onClick={handleTextClick7} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  CANCEL
                </Typography>
              </Box>
            </span>
          </Box>
        ) : (
          <Box>
            <span className='button_span_disabled button_top_space button_bottom_space'>
              <Box onClick={createProposal} className='globalIconMainBox globalMarginLeftAddWindow'>
                <img src={ContinueIcon} className='primary_icons_dimension_disable' alt='CONTINUE' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                  CONTINUE
                </Typography>
              </Box>
            </span>

            <hr />

            <span className='gray_button_span button_top_space' style={{ paddingBottom: '30px', paddingLeft: '22px' }}>
              <Box onClick={cancelProposalSelection} className='globalIconMainBox'>
                <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered7 ? 'hovered' : ''}`} alt='CANCEL' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover7} onMouseLeave={handleHover7}>
                  CANCEL
                </Typography>
              </Box>
            </span>
          </Box>
        )}
      </ThemeProvider>
    </div>
  );
};
