import React from "react";
import logo from "../../img/1.png";
import window from "../../img/Window.png";
import font from "../../font/Roboto-Light.woff";

import { Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";

Font.register({
  family: "RobotoLight",
  src: font,
});

const styles = StyleSheet.create({
  section_row: {
    flexDirection: "row",
    paddingTop: 5,
    paddingLeft: 10,
    backgroundColor: "#00B6BC",
    width: "100%",
    height: "10%",
  },
  section_col: {
    flexDirection: "col",
  },
  image: {
    width: 75,
    height: 70,
  },
  windowimage: {
    textAlign: "right",
    paddingTop: 10,
    marginLeft: 40,
    width: 120,
    height: 60,
  },
  headerverticalline: {
    borderLeft: "1.1px solid #9AE2E3",
    height: "60px",
    marginTop: 8,
    marginLeft: 10,
    marginRight: 10,
  },
  text: {
    paddingTop: 10,
    paddingLeft: 5,
    fontFamily: "RobotoLight",
    fontWeight: "400",
    fontSize: "22px",
    color: "#FFFFFF",
    textAlign: "justify",
  },
  subtext: {
    paddingLeft: 5,
    fontFamily: "RobotoLight",
    fontSize: "14px",
    color: "#FFFFFF",
    textAlign: "justify",
    textTransform: "uppercase",
  },
  headingText: {
    fontSize: 8,
    padding: 2,
  },
});

const PdfHeader = ({ name }) => {
  return (
    <View style={styles.section_row}>
      <Image src={logo} style={styles.image}></Image>
      <Text style={styles.headerverticalline}></Text>
      <View style={styles.section_col}>
        <Text style={styles.text}>WINDOW COVERING PROPOSAL</Text>
        <Text style={styles.subtext}>
          FOR&nbsp;
          {name}
        </Text>
      </View>
      <View style={styles.section_col}>
        {" "}
        <Image src={window} style={styles.windowimage}></Image>
      </View>
    </View>
  );
}
export default PdfHeader;
