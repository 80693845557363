/** @format */

import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import React, { forwardRef, useContext, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import ContinueIcon from '../../assets/icons/primary/continue.svg';
import ContinueHovIcon from '../../assets/icons/primary/WithHovIcon/continue-hov.svg';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import CONFIG from '../../config/config.js';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import 'moment-timezone';
import Box from '@mui/material/Box';
import ProposalsStepper from './ProposalsStepper';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import EditWindowSetName from '../../components/WindowsManagement/EditWindowSetName';
import { SnackbarContext } from '../../App';
import SelectIcon from '../../assets/icons/secondary/2nd-confirm.svg';
import fracty from 'fracty';
import { Grid, TablePagination } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';
import DrawerConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import LongText from '../LongText';

const TurquoiseTooltipforNotes = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#faf3dd',
    color: '#000000',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 300,
    maxHeight: 300,
    textAlign: 'left',
    align: 'left',
    border: '1px solid #000000',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#f2f3f2',
      border: '1px solid #000000',
    },
    top: '100%', // Position the arrow at the bottom of the tooltip box
    right: '100', // Position the arrow at the right side of the tooltip box
  },
}));

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  paper: {
    width: '90%',
    background: 'rgba(69, 157, 166, 70%)  !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

const theme1 = createTheme({
  palette: {
    border: {
      main: '1px solid #000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#FAC41E',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "green", // date picker textfield style
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#008593 !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'white', // disable date
            color: '#E9E9E9',
          },
        },
        dayWithMargin: {
          backgroundColor: 'white', // not selected date
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {},
      },
    },
  },
});

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const CreateProposal = () => {
  const token = sessionStorage.getItem('token');
  const contextvalue = useContext(SnackbarContext);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const outerTheme = createTheme({
    palette: {
      primary: {
        main: '#333333',
      },
    },
  });

  const classes = useStyles();

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };
  const [rowsPerPage, setRowsPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);
  const handleRowsPerPageChange = ({ target }) => {
    const { value } = target;
    setRowsPerPage(value === -1 ? WindowSetData.length : +value);
    setPage(0);
  };

  if (location.state === null) {
    navigate('/pagenotfound');
  }
  const coveringdetails = location.state.coveringdetails;

  const [WindowSetData, setWindowSetData] = useState([]);

  const [WindowSetName, setWindowSetName] = useState('');

  const [windowsetdata, setWindowsetdata] = useState([]);
  const [customerID, setCustomerID] = useState(0);
  const [addressID, setAddressID] = useState(0);
  const [senddatatonewWindow, setSendDatatoNewWindow] = useState('');

  const [checkboxflag, setCheckboxFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteWS, setDeleteWS] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [ConfirmationMsg, setConfirmationMsg] = useState(false);
  const [show, setShow] = useState(false);
  const vertical = 'top';
  const horizontal = 'center';
  const [checkedrowdata, setCheckedRowData] = useState([]);

  const [loading, setLoading] = useState(false);
  const [showSA, setShowSA] = useState(false);
  const [isWSDrawerOpen, setIsWSDrawerOpen] = useState(false);
  const [isAddNoteDrawerOpen, setIsAddNoteDrawerOpen] = useState(false);
  const [alertmessage, setAlertMessage] = useState('');
  const [classtype, setClassType] = useState('');
  const [addWindowSuccess, setAddWindowSuccess] = useState(false);
  const [appliedclass, setAppliedClass] = useState('table-contentwsidwithcheckbox_2 sticky-columnws-1');
  const [indexCheckboxClass, setIndexcheckboxclass] = useState('table-headerwsidwithcheckbox sticky-columnws-1');
  const [proposalCount, setProposalCount] = useState();
  const [windowCount, setWindowCount] = useState();
  const [windowSetSuccessMsg, setWindowSetSuccessMsg] = useState();
  const [defaultOptionValues, setdefaultOptionValues] = useState();
  const [defaultValuesStatus, setdefaultValuesStatus] = useState(false);

  // error handling states

  const [GetLookupTableDataErrorAlert, setGetLookupTableDataErrorAlert] = useState(false);
  const [GetLookupTableDataErrorAlertTxt, setGetLookupTableDataErrorAlertTxt] = useState('');

  const [DeleteWindowSetErrorAlert, setDeleteWindowSetErrorAlert] = useState(false);
  const [DeleteWindowSetErrorAlertTxt, setDeleteWindowSetErrorAlertTxt] = useState('');

  const [CreateProposalErrorAlert, setCreateProposalErrorAlert] = useState(false);
  const [CreateProposalErrorAlertTxt, setCreateProposalErrorAlertTxt] = useState('');

  const [WindowSetErrorAlert, setWindowSetErrorAlert] = useState(false);
  const [CreateProposalErrMsg, setCreateProposalErrMsg] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    getVerified();
    ChangeValue();
  }, []);

  const [BuildTypeData, setBuildTypeData] = useState([]);
  const [ExistingCoveringsData, setExistingCoveringsData] = useState([]);
  const [LadderData, setLadderData] = useState([]);

  const getSaleAppDropDowns = async () => {
    const response = await fetch(`${CONFIG.API_URL}/wm/GetLookupTableData`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      token: token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      setGetLookupTableDataErrorAlertTxt(data.error_message);
      setGetLookupTableDataErrorAlert(true);
    }
    setExistingCoveringsData(data.TypeExistingCovering);
    setBuildTypeData(data.TypeBuild);
    setLadderData(data.TypeLadder);
  };

  const ChangeValue = () => {
    setTimeout(function () {
      contextvalue.setOpensnackbar(false);
    }, 5000);
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // Get Token
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const deleteWindowSet = async () => {
    await fetch(`${CONFIG.API_URL}/wm/WindowSet`, {
      method: 'DELETE',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        window_set_id: windowSetID,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setWindowSetSuccessMsg(data.message);
          // WindowSet deleted Successfully!
          if (data.message === 'WindowSet deleted Successfully!') {
            setDeleteWS(true);
            setTimeout(() => {
              navigate(`/tbm/ps/customerdetails`, {
                state: {
                  Custdata: { custid: customerID, Addressid: addressID },
                  WindowSetSuccessMsg: data.message,
                },
              });
            }, 3000);
          }
        } else {
          setDeleteWindowSetErrorAlertTxt(data.error_message);
          setDeleteWindowSetErrorAlert(true);
        }
      });
  };

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='table-headerwsname sticky-columnws-2'>
            <span style={{ marginLeft: '30px' }}>#</span>
            <span style={{ marginLeft: '26px' }}>Window Name</span>
          </TableCell>
          <TableCell className='table-headersw3'>Size</TableCell>
          <TableCell className='table-headersw3'>Bld</TableCell>
          <TableCell className='table-headersw3'>Surface</TableCell>
          <TableCell className='table-headersw3'>Ladder</TableCell>
          <TableCell className='table-headersw3 ellipsis2'>Ex. Cover</TableCell>
          <TableCell className='table-header4'>Note</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  useEffect(() => {
    handleSelectAllClick(true);
  }, [WindowSetData]);

  useEffect(() => {
    if (location.state && location.state.selectedwindows && location.state.selectedwindows.length > 0) {
      setCheckboxFlag(false);
      setAppliedClass('table-contentwsidwithcheckbox_2 sticky-columnws-1');
      setIndexcheckboxclass('table-headerwsidwithcheckbox sticky-columnws-1');
      location.state.selectedwindows.forEach((element) => {
        const newSelecteds = location.state.selectedwindows.map((n) => n);
        setSelected(location.state.selectedwindows);

        setCheckedRowData(location.state.selectedwindows);

        const selectedIndex = location.state.totalwindows.indexOf(element);

        let newSelected = [];

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, element);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
      });
    } else {
      setCheckboxFlag(true);
      setAppliedClass('table-contentwsid2 sticky-columnws-1');
      setIndexcheckboxclass('table-headerwsid sticky-columnws-1');
    }
    getSaleAppDropDowns();
    getWindowSet();
    windowSetCreatedFun();
    getNote();
  }, [customerID, WindowSetName]);

  const WindowSetNameUpdateDrawer = (name, drawerstatus, message, status) => {
    setIsWSDrawerOpen(drawerstatus);
    setAlertMessage(message);
    if (message === 'Window set name is updated successfully!') {
      setWindowSetName(name);
      setOpen(true);
      setClassType('Success2 mainAlert');
    } else if (message === 'Window set name already exists.') {
      setOpen(true);
      setClassType('Error mainAlert');
    } else {
      setOpen(false);
      setClassType('');
    }
  };

  const DeleteWindowAlertClose = () => {
    setConfirmationMsg(false);
  };

  const AddNoteDrawerFun = (drawerstatus) => {
    setIsAddNoteDrawerOpen(drawerstatus);
  };

  useEffect(() => {
    getWindowSet();
  }, []);

  const [windowSetID, setWindowSetID] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();

  const windowSetId = location.state.windowSetID;

  const getWindowSet = async () => {
    await fetch(`${CONFIG.API_URL}/wm/window-set/${windowSetId}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setProposalCount(data.window_set.proposal_count);
          setWindowCount(data.window_set.window_count);

          if (data.error.message === 'Token is not valid') {
            navigate(`/login`, {
              state: { error: 'Unauthorized Please Login' },
            });
          } else {
            setWindowSetData(data.window);
            setWindowsetdata(data.window_set);

            setWindowSetName(data.window_set.window_set_name);
            setCustomerID(data.window_set.customer_id);
            setAddressID(data.window_set.address_id);

            setSendDatatoNewWindow(windowsetdata);
            setWindowSetID(data.window_set.window_set_id);
            setLastName(data.window_set.last_name);
            setFirstName(data.window_set.first_name);
            if (data.window_set.set_default_value === true) {
              setdefaultValuesStatus(true);
              setdefaultOptionValues(data.default_options);
            } else {
              setdefaultValuesStatus(false);
              setdefaultOptionValues();
            }
          }
        } else {
          setCreateProposalErrMsg(data.error_message);
          setWindowSetErrorAlert(true);
        }
      });
  };

  const createProposal = async () => {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        window_set_id: windowSetId,
        windows: WindowSetData,
      }),
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/CreateProposal`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      setCreateProposalErrorAlertTxt(data.error_message);
      setCreateProposalErrorAlert(true);
      return;
    }
    setLoading(true);
    setTimeout(() => {
      navigate(`/tbm/ps/proposalselectwindow/${data.proposal_id}`, {
        state: {
          coveringdetails: coveringdetails,
          selectedwindows: checkedrowdata,
          customerdata: windowsetdata,
          totalwindows: WindowSetData,
          proposalID: data.proposal_id,
          proposalName: data.proposal_name,
        },
      });
    }, 1000);
  };
  const cancelProposalSelection = () => {
    navigate(-1);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
  };

  const handleSelectAllClick = (event) => {
    if (event === true) {
      const newSelecteds = WindowSetData.map((n) => n);

      setSelected(newSelecteds);
      setCheckedRowData(newSelecteds);
      newSelecteds.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = true;
        }
      });
      return;
    } else {
      const newSelecteds = WindowSetData.map((n) => n);

      newSelecteds.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = false;
        }
      });
      setCheckedRowData([]);
      setSelected([]);
    }
  };

  const handleClick = (event, row) => {
    if (event.target.checked === true) {
      setShowSA(true);
      const selectedIndex = selected.indexOf(row);

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      newSelected.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = true;
        }
      });

      for (var i = 0; i < newSelected.length; i++) {
        if (WindowSetData[i].selected === newSelected[i].selected) {
        }
      }

      if (WindowSetData.length === newSelected.length) {
        setShowSA(false);
      }
      setCheckedRowData(newSelected);
      setSelected(newSelected);
    } else {
      const selectedIndex = selected.indexOf(row);
      const selectedActualIndex = WindowSetData.indexOf(row);

      WindowSetData[selectedActualIndex].selected = false;

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      if (WindowSetData.length > newSelected.length) {
        setShowSA(true);
      }

      setCheckedRowData(newSelected);
      setSelected(newSelected);
    }
  };

  const isSelected = (row) => selected.indexOf(row) !== -1;

  const DialogueClose = () => {
    setDeleteWS(false);
  };

  const navigateCD = () => {
    navigate(`/tbm/ps/customerdetails`, {
      state: {
        Custdata: { custid: customerID, Addressid: addressID },
      },
    });
  };

  const onSelectAllClick = () => {
    setShowSA(false);
    handleSelectAllClick(true);
  };
  const onDeSelectAllClick = () => {
    setShowSA(true);
    handleSelectAllClick(false);
  };

  const windowSetCreatedFun = () => {
    if (contextvalue.opensnackbar === true) {
      setAddWindowSuccess(true);
      setSuccessOpen(true);
    }
  };
  // Windowset created successfully!

  const [ExistingSku, setExistingSku] = useState();
  const [BuildSku, setBuildSku] = useState();
  const [MountSku, setMountSku] = useState();
  const [LadderSku, setLadderSku] = useState();
  const [ValanceSku, setValanceSku] = useState();
  const [HeadrailSku, setHeadrailSku] = useState();
  const [CutoutsSku, setCutoutsSku] = useState();
  const [MultipleSku, setMultipleSku] = useState();
  const [RollDiectionSku, setRollDiectionSku] = useState();

  const [EditNotes, setEditNote] = useState('');

  const [notes, setNotes] = useState(false);

  const [notesData, setNotesData] = useState('');

  const [noteTypeId, setNoteTypeId] = useState('');

  const [notesContent, setnotesContent] = useState([]);

  const [referenceId, setReferenceId] = useState('');

  const [noteId, setNoteId] = useState([]);
  const [notesContentForWindow, setNotesContentForWindow] = useState([]);

  const [eyeButtonMsg, setEyeButtonMsg] = useState(false);

  const [notesDataDescription, setNotesDataDescription] = useState([]);

  const additional_sku_data = {
    ExistingSku: ExistingSku,
    BuildSku: BuildSku,
    MountSku: MountSku,
    LadderSku: LadderSku,
    ValanceSku: ValanceSku,
    HeadrailSku: HeadrailSku,
    CutoutsSku: CutoutsSku,
    MultipleSku: MultipleSku,
    RollDiectionSku: RollDiectionSku,
  };

  const NotesDrawerData = (notesDrawerStatus, notesMsgAlert, notesMsg, eyeButtonMsg, referenceId, noteId, noteContent) => {
    setNotes(notesDrawerStatus);
    setEyeButtonMsg(eyeButtonMsg);

    if (notesMsg === 'Note added successfully!' || notesMsg === 'Note updated successfully!') {
      getNote();
    }
    setNoteId(noteId);
    setNotesContentForWindow(noteContent);
    setEyeButtonMsg(eyeButtonMsg);
  };

  const getNote = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    await fetch(`${CONFIG.API_URL}/cm/Notes/${windowSetId}`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setnotesContent(data.Notes);
          data.Notes.map((ele) => {});
        }
      });
  };

  const NotesCustomerDetails = {
    firstName: firstName,
    lastname: lastName,
    customerId: customerID,
    proposalId: '',
    windowSetId: windowSetID,
    WindowSetName: WindowSetName,
  };

  const [valueValue, setValueValue] = useState();
  const EditNotesCustomerDetails = {
    firstName: firstName,
    lastname: lastName,
    customerId: customerID,
    proposalId: '',
    windowSetId: '',
    WindowSetName: WindowSetName,
    notesContent: notesData,
    noteId: noteId,
    noteTypeId: noteTypeId,
    eyeButtonMsg: eyeButtonMsg,
    notesDataDescription: notesDataDescription,
    valueValue: valueValue,
  };

  const [isImageHovered6, setIsImageHovered6] = useState(false);
  const handleHover6 = () => {
    setIsImageHovered6(!isImageHovered6);
  };

  const handleTextClick6 = () => {
    setIsImageHovered6(false);
  };

  const [isImageHovered7, setIsImageHovered7] = useState(false);
  const handleHover7 = () => {
    setIsImageHovered7(!isImageHovered7);
  };

  const handleTextClick7 = () => {
    setIsImageHovered7(false);
  };

  const [isImageHovered8, setIsImageHovered8] = useState(false);
  const handleHover8 = () => {
    setIsImageHovered8(true);
  };
  const handleHover8_1 = () => {
    setIsImageHovered8(false);
  };

  const handleTextClick8 = () => {
    setIsImageHovered8(false);
  };

  const windowDetails = {
    totalselectedwindows: '',
    selectedwindows: '',
    totalwindows: '',
  };

  return (
    <div className='padding_bottom_class'>
      {/* <ThemeProvider theme={theme1}>
      <Drawer
        classes={{ paper: classes.paper }}
        PaperProps={{
          sx: { width: "90%" },
        }}
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
        anchor="right"
        open={notesDrawerOpen}
        onClose={() => setNotesDrawerOpen(false)}
        className="drawer_Header_page"
      >
        <NotesDrawer
          closeDrawer={closeNotesDrawer}
          editing={editingNote}
          type="customer"
          details={noteDrawerDetails} 
        />
      </Drawer>
      </ThemeProvider> */}

      <ThemeProvider theme={outerTheme}>
        <div align='center'>
          <Stack spacing={2} sx={{ width: '100%' }}>
            {/* GetLookupTableDataErrorAlert */}
            <Snackbar className='snackbar_bar_postition' open={GetLookupTableDataErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setGetLookupTableDataErrorAlert(false)}>
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setGetLookupTableDataErrorAlert(false);
                    }}
                    src={RedDrawerCancelIcon}
                    alt='CANCELICONGLT'
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img src={RedExclamationIcon} alt='CONFIRMICONGLT' className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{GetLookupTableDataErrorAlertTxt}</div>
                </div>
              </Alert>
            </Snackbar>

            {/* DeleteWindowSetErrorAlert */}
            <Snackbar className='snackbar_bar_postition' open={DeleteWindowSetErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setDeleteWindowSetErrorAlert(false)}>
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setDeleteWindowSetErrorAlert(false);
                    }}
                    src={RedDrawerCancelIcon}
                    alt='CANCELICONREDDWSA'
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img src={RedExclamationIcon} alt='CONFIRMICONREDDWSA' className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{DeleteWindowSetErrorAlertTxt}</div>
                </div>
              </Alert>
            </Snackbar>

            {/* WindowSetErrorAlert */}
            <Snackbar
              className='snackbar_bar_postition'
              open={WindowSetErrorAlert}
              anchorOrigin={{ vertical, horizontal }}
              // autoHideDuration={3000}
              onClose={() => setWindowSetErrorAlert(false)}
            >
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setWindowSetErrorAlert(false);
                    }}
                    src={RedDrawerCancelIcon}
                    alt='CANCELICONCPA'
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img src={RedExclamationIcon} alt='CONFIRMICONCPA' className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{CreateProposalErrMsg}</div>
                </div>
              </Alert>
            </Snackbar>

            {/* CreateProposalErrorAlert */}
            <Snackbar
              className='snackbar_bar_postition'
              open={CreateProposalErrorAlert}
              anchorOrigin={{ vertical, horizontal }}
              // autoHideDuration={3000}
              onClose={() => setCreateProposalErrorAlert(false)}
            >
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setCreateProposalErrorAlert(false);
                    }}
                    src={RedDrawerCancelIcon}
                    alt='CANCELICONREDCPA'
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img src={RedExclamationIcon} alt='CONFIRMICONREDCPA' className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{CreateProposalErrorAlertTxt}</div>
                </div>
              </Alert>
            </Snackbar>

            <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setOpen(false)}>
              {classtype === 'Success2 mainAlert' ? (
                <Alert className={classtype} variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img onClick={handleSuccessClose} src={DrawerCancelIcon} alt='CANCELICON' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img src={DrawerConfirmIcon} alt='CONFIRMICON' className='primary_icons_dimension alertImageMargin' />
                    <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{alertmessage}</div>
                  </div>
                </Alert>
              ) : (
                <Alert className={classtype} variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img onClick={handleSuccessClose} src={RedDrawerCancelIcon} alt='CANCELICONRED' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img src={RedExclamationIcon} alt='CONFIRMICONRED' className='primary_icons_dimension alertImageMargin' />
                    <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{alertmessage}</div>
                  </div>
                </Alert>
              )}
            </Snackbar>
          </Stack>

          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={successOpen} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setSuccessOpen(false)}>
              {classtype === 'Success2 mainAlert' ? (
                ''
              ) : (
                <Alert className='Success2 mainAlert' variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img onClick={handleClose} src={DrawerCancelIcon} alt='CANCELICONWSC' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img src={DrawerConfirmIcon} alt='CONFIRMICONWSC' className='primary_icons_dimension alertImageMargin' />
                    <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Window Set created successfully!</div>
                  </div>
                </Alert>
              )}
            </Snackbar>
          </Stack>

          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={deleteWS} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setDeleteWS(false)}>
              {classtype === 'Success' ? (
                ''
              ) : (
                <Alert className='Success2 mainAlert' variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img onClick={handleClose} src={DrawerCancelIcon} alt='CANCEICONWSA' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img src={DrawerConfirmIcon} alt='CONFIRMICONWSA' className='primary_icons_dimension alertImageMargin' />

                    <div align='center' className='alertLeftPadding alertSpacing alertSpacing1'>
                      {windowSetSuccessMsg}
                    </div>
                  </div>
                </Alert>
              )}
            </Snackbar>
          </Stack>

          <div className='heading-box-1'>
            <h2 className='clickable_header all_pages_h2_header ' onClick={() => setIsWSDrawerOpen(true)}>
              <LongText>{WindowSetName}</LongText>
            </h2>

            <h3
              className='clickable_header_1 all_pages_h2_header center button_top_space common_line_height_css'
              style={{
                textTransform: 'capitalize',
                textAlign: 'center !important',
              }}
              onClick={() => {
                navigate(`/tbm/ps/customerdetails`, {
                  state: {
                    Custdata: { custid: customerID, Addressid: addressID },
                  },
                });
              }}
            >
              <LongText>{`${windowsetdata.last_name} | ${windowsetdata.city}`}</LongText>
            </h3>

            {/* <Box sx={{ position: "relative", float: "right", top: "-55px" }}>
              <img
                src={NoteMiscIcon}
                className="misc_icons_dimensions"
                onClick={() => openNotesDrawer()}
                alt="NOTES"
              />
            </Box> */}
          </div>
        </div>

        <Dialog open={show} onClose={DialogueClose}>
          <DialogTitle id='alert-dialog-title'>
            <div align='center' style={{ paddingLeft: '1%', paddingRight: '1%' }}>
              <span style={{ color: 'red' }}> {'Are you sure you want to delete '} </span>
              <br />
              {windowsetdata.window_set_name} {'?'}
            </div>
          </DialogTitle>

          <DialogActions>
            <div style={{ display: 'flex', width: '100%' }}>
              <Button
                className='small_buttons'
                onClick={() => {
                  navigateCD();
                }}
              >
                Yes
              </Button>{' '}
            </div>
            <Button className='small_buttons' onClick={DialogueClose}>
              No
            </Button>
          </DialogActions>
        </Dialog>

        <Drawer
          PaperProps={{
            sx: { width: '90%' },
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          classes={{ paper: classes.paper }}
          anchor='right'
          open={isWSDrawerOpen}
          onClose={() => setIsWSDrawerOpen(true)}
          sx={{ zIndex: '100000' }}
        >
          <EditWindowSetName windowdetails={windowsetdata} func={WindowSetNameUpdateDrawer} />
        </Drawer>

        {!checkboxflag ? (
          <Grid container spacing={0}>
            <Grid xs></Grid>
            <Grid lg={4} xl={3} md={4} xs={10} sm={8}>
              <Box align='center' className='prompt-box_2'>
                <Typography
                  sx={{
                    fontSize: '110%',
                    px: '1%',
                    color: 'white',
                  }}
                  variant='contained'
                >
                  Select windows for proposal:
                </Typography>
              </Box>
            </Grid>
            <Grid xs></Grid>
          </Grid>
        ) : (
          ''
        )}

        <div align='center' style={{ marginBottom: '30px' }} className='button_top_space'>
          <ProposalsStepper windowDetails={windowDetails} stepperData='CreateProposal' />
        </div>

        {showSA ? (
          <>
            <span style={{ marginLeft: '16px' }} className='gray_button_span button_top_space'>
              <Box onClick={onSelectAllClick} className='globalIconMainBox'>
                <img src={SelectIcon} className={`secondary_icons_dimension ${isImageHovered8 ? 'hovered' : ''}`} alt='SELECTALL' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover8} onMouseLeave={handleHover8_1} onClick={handleTextClick8}>
                  SELECT ALL
                </Typography>
              </Box>
            </span>
          </>
        ) : (
          <span style={{ marginLeft: '16px' }} className='gray_button_span button_top_space'>
            <Box onClick={onDeSelectAllClick} className='globalIconMainBox'>
              <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered8 ? 'hovered' : ''}`} alt='DESELECTALL' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover8} onMouseLeave={handleHover8_1} onClick={handleTextClick8}>
                DESELECT ALL
              </Typography>
            </Box>
          </span>
        )}

        <hr className='button_bottom_space button_top_space' />

        {/* <Box className='table-box-1'> */}
        <TableContainer sx={{ borderBottom: '1px solid #D6D6D6' }} className='table_padding_bottom'>
          <Table className='windowset-table-dimension' aria-labelledby='tableTitle' size='small'>
            <EnhancedTableHead />
            <TableBody>
              {(rowsPerPage > 0 ? WindowSetData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : WindowSetData).map((window, index) => {
                return (
                  <TableRow hover className='rowhover' onClick={(event) => handleClick(event, window)} role='checkbox' aria-checked={isSelected(window)} tabIndex={-1} key={window.window_id}>
                    <TableCell className={appliedclass} align='left'>
                      {' '}
                      <span className='ellipsis2'>
                        <Checkbox
                          sx={{
                            zoom: 0.8,
                            paddingTop: '0px',
                            marginLeft: '-5px',
                          }}
                          color='primary'
                          checked={isSelected(window)}
                          inputProps={{
                            'aria-labelledby': `enhanced-table-checkbox-${index}`,
                          }}
                        />

                        <span
                          style={{
                            paddingRight: `${WindowSetData.indexOf(window) + 1 > 9 ? '20px' : '28px'}`,
                          }}
                        >
                          {WindowSetData.indexOf(window) + 1}
                        </span>

                        <LongText>{window.window_name}</LongText>
                      </span>
                    </TableCell>

                    <TableCell className='table-content ellipsis2' align='left'>
                      {fracty(window.window_width)}" x {fracty(window.window_height)}"{' '}
                      <span className={window.measurement_type.replace(/ /g, '')}>
                        {window.measurement_type === 'Quick'
                          ? '(QK)'
                          : window.measurement_type === 'Shutters'
                          ? '(SH)'
                          : window.measurement_type === 'Blinds'
                          ? '(BL)'
                          : `(${window.measurement_type
                              .split(' ')
                              .map((word) => word[0])
                              .join('')})`}
                      </span>
                    </TableCell>
                    <TableCell className='table-content' align='left'>
                      {window.build_type}
                    </TableCell>
                    <TableCell className='table-content' align='left'>
                      {window.mount_surface}
                    </TableCell>
                    <TableCell className='table-content' align='left'>
                      {!isNaN(window.ladder) ? window.ladder + "'" : window.ladder}
                    </TableCell>
                    <TableCell className='table-content' align='left'>
                      {window.existing_covering}
                    </TableCell>
                    <TableCell className='table-content' align='left'>
                      {window.WindowConfiguration}
                    </TableCell>
                    <TableCell className='table-content' align='left'>
                      {window.notes.length && (
                        <TurquoiseTooltipforNotes
                          title={
                            <>
                              <Typography>
                                {window.notes.map((note, index) => (
                                  <div key={note.id}>
                                    {index > 0 && <br />}
                                    <b>Note:</b> {note.content}
                                  </div>
                                ))}
                              </Typography>
                            </>
                          }
                          placement='bottom-end'
                          TransitionComponent={Zoom}
                          arrow
                        >
                          <Link
                            className='link'
                            to='/tbm/ps/window'
                            state={{
                              data: windowsetdata,
                              row_id: window.window_id,
                              customer_id: windowsetdata.customer_id,
                              additional_sku_data: additional_sku_data,
                            }}
                          >
                            {window.notes_count === '0' ? <></> : <span style={{ paddingLeft: '5px' }}>{window.notes_count}</span>}
                          </Link>
                        </TurquoiseTooltipforNotes>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            sx={{
              width: '100%',
              minWidth: 5,
              mx: 0,
              px: 0,
              textAlign: 'center',
            }}
            rowsPerPageOptions={[5, 10, 20, 25, { value: -1, label: 'All' }]}
            component='div'
            count={WindowSetData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </TableContainer>
        {/* </Box> */}

        <Box sx={{ width: '100%' }}>
          <span className='button_span button_top_space button_bottom_space' onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
            {selected.length > 0 ? (
              <Box onClick={createProposal} className='globalIconMainBox globalMarginLeftAddWindow'>
                {isHovered ? <img alt='CONTINUEH' className='primary_icons_dimension_1' src={ContinueHovIcon} /> : <img alt='CONTINUE' className='primary_icons_dimension_1' src={ContinueIcon} />}
                <Typography onMouseEnter={handleHover6} onMouseLeave={handleHover6} onClick={handleTextClick6} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  CONTINUE
                </Typography>
              </Box>
            ) : (
              <Box onClick={createProposal} className='globalIconMainBox globalMarginLeftAddWindow'>
                <img src={ContinueIcon} className='primary_icons_dimension_disable' alt='CONTINUED' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                  CONTINUE
                </Typography>
              </Box>
            )}
          </span>
          <hr />

          <span className='gray_button_span button_top_space' style={{ paddingBottom: '30px', paddingLeft: '22px' }}>
            <Box onClick={cancelProposalSelection} className='globalIconMainBox'>
              <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered7 ? 'hovered' : ''}`} alt='CANCEL' />
              <Typography onMouseEnter={handleHover7} onMouseLeave={handleHover7} onClick={handleTextClick7} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                CANCEL
              </Typography>
            </Box>
          </span>
        </Box>
      </ThemeProvider>
    </div>
  );
};

export default CreateProposal;
