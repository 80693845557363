import React from "react";
import { Box } from "@mui/material";

import DetailsHeader from "./DetailsHeader";
import WindowDetails from "./DetailsTableRow";

const DetailsTable = ({ windows, customStyles }) => (
  <Box style={customStyles.proposal_section}>
    {/* <DetailsHeader customStyles={customStyles}/> */}
    {/* <hr className="greyout_1" /> */}
    {windows.map((window, index) => (
      <>
        <WindowDetails key={index} row={index + 1} window={window} customStyles={customStyles}/>
        <hr key={index} className="greyout_1" />
      </>
    ))}
  </Box>
);

export default DetailsTable;
