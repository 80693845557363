import { Container, Drawer as MuiDrawer } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

export default function Drawer({ children, ...props }) {
  const classes = useStyles();
  return (
    <MuiDrawer
      PaperProps={{
        sx: {
          backdropFilter: 'blur(10px)',
          background: 'rgba(0, 133, 147, 0.73)',
          width: { md: '50vw', xs: '90vw' },
        },
      }}
      slotProps={{
        backdrop: {
          classes: {
            root: classes.backDrop,
          },
        },
      }}
      anchor='right'
      sx={{ zIndex: '1000000' }}
      {...props}
    >
      <Container sx={{ sm: false, lg: false, padding: '0 90px' }}>{children}</Container>
    </MuiDrawer>
  );
}
