/** @format */

import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import Stack from '@mui/material/Stack';
import { TextField } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import CONFIG from '../../config/config.js';
import Paper from '@mui/material/Paper';
import Skeleton from 'react-loading-skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment/moment';
import CalanderIcon from '../../assets/icons/primary/calendar.svg';
import CalanderHovIcon from '../../assets/icons/primary/WithHovIcon/calender-hov.svg';
import SearchIcon from '../../assets/icons/primary/search.svg';
import SearchHovIcon from '../../assets/icons/primary/WithHovIcon/search-hov.svg';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { esES } from '@mui/material/locale';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

const useStyles = makeStyles({
  futureDay: {
    color: 'blue',
  },
  StaticDatePicker: {
    height: '340px',
  },
  dateTimePaperPropsStyles: {
    display: 'flex',
    position: 'absolute',
    paddingLeft: '80px',
  },
});

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {},
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          top: '105px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          marginTop: '0px',
          labelContainer: {
            overFlow: 'auto',
            position: 'absolute',
            marginTop: '6px',
            textTransform: 'uppercase',
            fontFamily: 'RobotoLight',
            fontSize: '18px',
          },
        },
        labelContainer: {
          overFlow: 'auto',
          position: 'absolute',
          marginTop: '6px',
          textTransform: 'uppercase',
          fontFamily: 'RobotoLight',
          fontSize: '18px',
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
          width: '300px',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '2rem',
          fontFamily: 'RobotoLight',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#008593 !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'white', // disable date
            color: '#E9E9E9',
          },
          '&.css-nk89i7-MuiPickersCalendarHeader-root': {
            display: 'block !important',
            paddingLeft: '80px !important',
          },
        },
        dayWithMargin: {
          backgroundColor: 'white', // not selected date
        },
        '&.css-nk89i7-MuiPickersCalendarHeader-root': {
          display: 'block !important',
          paddingLeft: '80px !important',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {},
      },
    },
  },
});

const formatPhoneNumber = ({ pnumber }) => {
  if (pnumber === null) {
    return undefined;
  }
  const pattern = /^(\d{3})(\d{3})(\d{4})$/;
  const cleaned = pnumber.replace(/\D/g, '');
  return cleaned.replace(pattern, '($1) $2-$3');
};

function formatName({ Husband, Wife, LName }) {
  return `${Husband ? Husband : ''}${Husband && Wife ? ' & ' : ''}${Wife ? Wife : ''}${LName ? ` ${LName}` : ''}`.trim();
}

export default function Appoinments() {
  const token = sessionStorage.getItem('token');
  const user_id = sessionStorage.getItem('user_id');

  const classes = useStyles();
  const navigate = useNavigate();
  const dynamicDateTime1 = new Date();
  const usTimeZone1 = 'America/Denver';
  const usDateTime1 = moment(dynamicDateTime1).tz(usTimeZone1);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [date, setDate] = useState(new Date());
  const [searchArea, setSearchArea] = useState(today);
  const [value, setValue] = useState(today);
  const [appointments, setAppointments] = useState([]);
  // const [data, setData] = useState([]);
  const [filtertData, setFilterData] = useState([]);
  const [withoutFilter, setWithoutFilter] = useState(true);
  const [withFilterTable, setWithFilterTable] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);
  const [fullyear, setFullYear] = useState([]);
  const [skeltonValue, setSkeltonValue] = useState(false);

  //  hover states

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const handleHoverFun = () => setIsHovered(true);
  const handleMouseLeaveFun = () => setIsHovered(false);
  const handleHoverFun2 = () => setIsHovered2(true);
  const handleMouseLeaveFun2 = () => setIsHovered2(false);

  useEffect(() => {
    getAllAppointments();
  }, []);

  const getAllAppointments = async () => {
    const options = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        personID: user_id,
        done: 'notdone',
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/GetSalesRepAppointments`, options);
    const data = await response.json();
    if (!response.ok || data.error) {
      return console.error(data.error_message);
    }
    setAppointments(data.SalesRepAppointments.recordset);
    // setData(data.SalesRepAppointments.recordset);
    setTimeout(() => {
      setSkeltonValue(true);
    }, 6_000);
  };

  const currentYear = today.getFullYear();

  const dates = [];
  var count = 1;
  for (let month = 0; month < 1; month++) {
    const numDaysInMonth = new Date(currentYear, month + 1, 0).getDate();
    for (let day = 1; day <= numDaysInMonth; day++) {
      dates.push(new Date(currentYear, month, day));
      fullyear.push(new Date(currentYear, month, day));
    }
    fullyear.map((ele) => {
      const disableAppointments = appointments.filter((item) => moment(item.StartTime).local().format('MM/DD/YYYY') === moment(ele).local().format('MM/DD/YYYY'));
      if (fullyear.length === count) {
        disableAppointments.map((_) => {});
      }
      count++;
    });
  }

  const tomorrowAppointments = appointments.filter((item) => moment(item.StartTime).local().format('MM/DD/YYYY') === moment(today).local().format('MM/DD/YYYY'));
  const notSchduleApointmentsDate = appointments.filter((item) => moment(item.StartTime).local().format('MM/DD/YYYY') != moment(tomorrow).local().format('MM/DD/YYYY'));
  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow style={{ margin: '0px' }}>
          <TableCell className='font_weight_bold table-header-cd-proposal1'>Date</TableCell>
          <TableCell className='table-headersw3 font_weight_bold'>Name</TableCell>
          <TableCell className='search_table_header2'>City</TableCell>
          <TableCell className='search_table_header2'>Phone</TableCell>
          <TableCell className='table-header_material font_weight_bold'>Notes</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const handleSearchChange = (newValue) => {
    setValue(moment(newValue.$d).local().format('MM/DD/YYYY'));

    if (newValue === '') {
      getAllAppointments();
    } else {
      const result = appointments.filter((item) => moment(item.StartTime).local().format('MM/DD/YYYY') === moment(newValue.$d).local().format('MM/DD/YYYY'));

      setFilterData(result);
      setWithoutFilter(false);
      setWithFilterTable(true);
    }
    setSearchArea(newValue);
  };

  return (
    <div>
      <Box>
        <Box className='show_appointment_type appointments_left_margin'>
          <Stack component='form' noValidate spacing={3}>
            <LocalizationProvider locale={esES} dateAdapter={AdapterDayjs}>
              <Stack spacing={3} sx={{ width: '315px !important' }} className='Calender_stack'>
                <ThemeProvider theme={theme}>
                  <StaticDatePicker
                    sx={{ margin: '0px !important', width: '315px !important' }}
                    className={classes.StaticDatePicker}
                    views={['day']}
                    label='Select Date'
                    inputFormat='MM/DD/YYYY'
                    value={searchArea}
                    format='DD-MM-YYYY'
                    onChange={handleSearchChange}
                    renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, readOnly: true }} />}
                    toolbarFormat='ddd DD MMMM'
                    showToolbar={false}
                    componentsProps={{
                      actionBar: {
                        actions: [''],
                      },
                    }}
                    renderDay={(day, _value, DayComponentProps) => {
                      let selected = false;
                      const Calenderdate = moment(DayComponentProps.key).local().format('MM/DD/YYYY');
                      appointments.forEach((dates) => {
                        if (Calenderdate === moment(dates.StartTime).local().format('MM/DD/YYYY')) {
                          selected = true;
                        }
                      });

                      const yesterday = moment().subtract(1, 'day').toDate();

                      const isPastDate = day < yesterday; // Check if the date is in the past

                      const selected2 = isPastDate || appointments.some((dates) => moment(dates.StartTime).local().isSame(date, 'day'));

                      const className = selected2 ? (isPastDate ? 'past-date selected-date' : 'selected-date') : '';
                      return (
                        <div align='left'>
                          {selected ? (
                            <PickersDay
                              sx={{
                                backgroundColor: '#D2D2D2 !important',
                                margin: '2px',
                                borderRadius: '50px',
                              }}
                              {...DayComponentProps}
                            />
                          ) : isPastDate ? (
                            <PickersDay className='previous_date_disable' {...DayComponentProps} disabled={isPastDate} />
                          ) : (
                            <PickersDay {...DayComponentProps} />
                          )}
                        </div>
                      );
                    }}
                  />
                </ThemeProvider>
              </Stack>
            </LocalizationProvider>
          </Stack>

          <div className='show_appointment_type_div'>
            <Box className='show_appointment_type_box'>
              <div className='show_appointment_type_div_1'>
                <span
                  style={{
                    width: '20px',
                    height: '20px',
                    border: '1px solid #333333',
                    borderRadius: '50%',
                    marginLeft: '2px',
                    marginRight: '2px',
                  }}
                ></span>
                <span className='show_appointment_type_font_size'> Today's Date</span>{' '}
              </div>

              <div className='show_appointment_type_div_1'>
                {' '}
                <CircleIcon sx={{ color: '#008593' }} /> <span className='show_appointment_type_font_size'> Selected Date</span>{' '}
              </div>
            </Box>

            <Box className='show_appointment_type_box'>
              <div className='show_appointment_type_div_1'>
                {' '}
                <CircleIcon sx={{ color: '#D1D1D1' }} /> <span className='show_appointment_type_font_size'> Date With Appointments</span>{' '}
              </div>

              <div style={{ marginRight: '3px' }} className='show_appointment_type_div_1'>
                <ClearIcon sx={{ color: '#D1D1D1' }} /> <span className='show_appointment_type_font_size'> Date Blocked</span>
              </div>
            </Box>
          </div>
        </Box>
      </Box>
      <hr />

      <Box sx={{ padding: '20px 0px 20px 0px' }}>
        <Typography sx={{ fontSize: '22px !important' }} className='common_line_height_css'>
          {moment(value).local().format('MMMM DD, YYYY')}
        </Typography>
      </Box>

      <hr className='button_bottom_space' />
      <Box className='table-box-1'>
        {withFilterTable ? (
          filtertData.length ? (
            <TableContainer sx={{ maxHeight: 600, borderBottom: '1px solid #E0E0E0' }} className='table_padding_bottom'>
              <Table className='proposal-select-window-table' stickyHeader aria-label='sticky table'>
                <EnhancedTableHead />
                <TableBody>
                  {filtertData
                    .sort((a, b) => new Date(a.Start1Time).getTime() - new Date(b.StartTime).getTime())
                    .map((row) => {
                      return (
                        <TableRow hover className='rowhover' role='checkbox' tabIndex={-1} key={row.CustId}>
                          <TableCell className='table-content' align='left'>
                            {moment(row.StartTime).local().format('MM/DD/YYYY')} - {moment(row.StartTime).utc().format('hh:mm a')}
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            {formatName(row) === '' ? (
                              <>- -</>
                            ) : (
                              <span
                                onClick={() =>
                                  navigate('/tbm/ps/customerdetails', {
                                    state: {
                                      Custdata: {
                                        custid: row.custid,
                                        Addressid: row.AddressID[0],
                                        Husband: row.Husband,
                                        LName: row.LName,
                                        Email: row.Email,
                                        state: row.P_State,
                                        City: row.City,
                                        zip: row.Zip,
                                        Address1: row.Address1,
                                      },
                                    },
                                  })
                                }
                                style={{
                                  color: '#0ca8b0',
                                  cursor: 'pointer',
                                }}
                              >
                                {formatName(row)}
                              </span>
                            )}
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            {row.City}
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            {formatPhoneNumber(row)}
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            {row.Notes === '' ? <>- -</> : <>{row.Notes}</>}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>There are no appointments scheduled for this date.</Typography>
          )
        ) : null}

        {withoutFilter ? (
          tomorrowAppointments.length ? (
            <TableContainer sx={{ maxHeight: 600, borderBottom: '1px solid #D6D6D6' }} className='table_padding_bottom'>
              <Table className='proposal-select-window-table' stickyHeader aria-label='sticky table'>
                <EnhancedTableHead />
                <TableBody>
                  {tomorrowAppointments
                    .sort((a, b) => new Date(a.StartTime).getTime() - new Date(b.StartTime).getTime())
                    .map((row, index) => {
                      const phoneNumber = formatPhoneNumber(row);

                      return (
                        <TableRow hover className='rowhover' key={row.CustId}>
                          <TableCell className='table-content' align='left'>
                            {moment(row.StartTime).local().format('MM/DD/YYYY')} - {moment(row.StartTime).utc().format('hh:mm a')}
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            {formatName(row) === '' ? (
                              <>- -</>
                            ) : (
                              <span
                                onClick={() =>
                                  navigate('/tbm/ps/customerdetails', {
                                    state: {
                                      Custdata: {
                                        custid: row.custid,
                                        Addressid: row.AddressID[0],
                                        Husband: row.Husband,
                                        LName: row.LName,
                                        Email: row.Email,
                                        state: row.P_State,
                                        City: row.City,
                                        zip: row.Zip,
                                        Address1: row.Address1,
                                      },
                                    },
                                  })
                                }
                                style={{
                                  color: '#0ca8b0',
                                  cursor: 'pointer',
                                }}
                              >
                                {formatName(row)}
                              </span>
                            )}
                          </TableCell>
                          <TableCell sx={{ textTransform: 'capitalize' }} className='table-content' align='left'>
                            {row.City === '' ? <>- -</> : <>{row.City}</>}
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            {phoneNumber === undefined ? <>- -</> : <>{phoneNumber}</>}
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            {row.Notes === '' ? <>- -</> : <>{row.Notes}</>}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : !skeltonValue ? (
            <TableContainer component={Paper}>
              <Table aria-label='simple table'>
                <EnhancedTableHead rowCount={appointments.length} />
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component='th' scope='row'>
                    <Skeleton />
                  </TableCell>
                  <TableCell align='left'>
                    <Skeleton />
                  </TableCell>
                  <TableCell align='left'>
                    <Skeleton />
                  </TableCell>
                  <TableCell align='left'>
                    <Skeleton />
                  </TableCell>
                  <TableCell align='left'>
                    <Skeleton />
                  </TableCell>
                  <TableCell align='left'>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          ) : (
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>There are no appointments scheduled for this date.</Typography>
          )
        ) : null}
      </Box>

      <span
        onClick={(e) => {
          navigate('/tbm/ps/viewallappointments');
        }}
        className='button_span button_top_space'
        onMouseEnter={handleHoverFun}
        onMouseLeave={handleMouseLeaveFun}
      >
        <Box className='globalIconMainBox globalMarginLeftAddWindow'>
          {isHovered ? <img className='primary_icons_dimension_1' src={CalanderHovIcon} alt='CalanderHovIcon' /> : <img className='primary_icons_dimension_1' src={CalanderIcon} alt='CalanderIcon' />}
          <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
            VIEW ALL APPOINTMENTS
          </Typography>
        </Box>
      </span>

      <span
        onMouseEnter={handleHoverFun2}
        onMouseLeave={handleMouseLeaveFun2}
        style={{
          marginBottom: '30px !important',
          paddingBottom: '30px !important',
        }}
        onClick={(e) => {
          navigate('/tbm/ps/searchCustomer/Customer');
        }}
        className='button_span button_top_space margin_bottom_class'
      >
        <Box sx={{ paddingBottom: '50px' }} className='globalIconMainBox globalMarginLeftAddWindow'>
          {isHovered2 ? <img className='primary_icons_dimension_1' src={SearchHovIcon} alt='SearchHovIcon' /> : <img className='primary_icons_dimension_1' src={SearchIcon} alt='SearchIcon' />}
          <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
            SEARCH / ADD CUSTOMER
          </Typography>
        </Box>
      </span>
    </div>
  );
}
