/** @format */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CONFIG from '../../config/config.js';
import { Stack, Chip, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import headerBack from '../../assets/icons/primary/header-return.svg';
import HeaderLogoIcon from '../../assets/icons/secondary/hdft-TBM-logo.svg';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  width: 300,
  color: theme.palette.text.secondary,
}));

const vertical = 'top';
const horizontal = 'center';

export default function SkuLookUp() {
  const [skuData, setSkuData] = useState([]);
  const [errormsg, setErrorMsg] = React.useState(false);
  const [enterSkuData, setEnterSkuData] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [getBrand, setGetBrand] = useState('');
  const [getProduct, setGetProduct] = useState('');
  const [getSku, setGetSku] = useState('');
  const [search, setSearch] = useState(true);
  const [getType, setGetType] = useState('');
  const [getLabel, setGetLabel] = useState('');
  const [getCategory, setGetCategory] = useState('');
  const [Active, setActive] = useState('');
  const [success, setSuccess] = useState(false);
  const [blankSpace, setBlankSpace] = useState(true);

  const navigate = useNavigate();
  useEffect(() => {
    getVerified();
  }, []);

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMsg(false);
    setSuccess(false);
  };

  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };
  // Get token from local
  const localtoken = sessionStorage.getItem('Localtoken');

  // Get sku discription
  const getData = async () => {
    const response = await fetch(`${CONFIG.API_URL}/pm/GetTBMSKUDetails/${enterSkuData}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: localtoken,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setSuccess(true);
          switch (data.SKUDetails[0].sku_type) {
            case 'S':
              setGetType('Service');
              break;
            case 'M':
              setGetType('Material');
              break;
            case 'X':
              setGetType('Accessories');
              break;
            case 'A':
              setGetType('Attribute');
              break;
            case 'B':
              setGetType('BPL');
              break;
            case 'L':
              setGetType('Lift System');
              break;
            case 'P':
              setGetType('Option');
              break;

            default:
              setGetType();
              break;
          }

          setGetBrand(data.SKUDetails[0].brand);
          setGetProduct(data.SKUDetails[0].product);
          setGetLabel(data.SKUDetails[0].label);
          setGetSku(data.SKUDetails[0].sku);
          setSkuData(data.SKUDetails);
          setActive(data.SKUDetails[0].active);
          setGetCategory(data.SKUDetails[0].sku_name);
          setShowTable(true);
        } else {
          setShowTable(false);
          setErrorMsg(true);
        }
      });
  };
  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  const searchPosition = () => {
    setSearch(false);
  };
  const getInput = (e) => {
    setEnterSkuData(e.target.value.toUpperCase());
  };

  const onclickOnSearch = () => {
    getData();
    searchPosition();
  };

  const fillBlankSpace = () => {
    setBlankSpace(false);
  };

  return (
    <>
      <div align='center'>
        <Stack spacing={14} sx={{ width: '100%' }}>
          <Snackbar className='snackbar_bar_postition' open={errormsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setErrorMsg(false)}>
            <Alert className='Error mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel'>
                  <img alt='CANCELICONRED' onClick={handleErrorClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                </div>
                <div className='alertMain'>
                  <img alt='CONFIRMICONRED' src={RedExclamationIcon} className='primary_icons_dimension' />

                  <div align='center' style={{ paddingTop: 10 }}>
                    SKU not found !
                  </div>
                </div>
              </>
            </Alert>
          </Snackbar>
        </Stack>
        <Stack spacing={14} sx={{ width: '100%' }}>
          <Snackbar className='snackbar_bar_postition' open={success} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setSuccess(false)}>
            <Alert className='Success mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel'>
                  <img alt='CANCELICON' onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                </div>
                <div className='alertMain'>
                  <img alt='CONFIRMICON' src={DrawerAlertIcon} className='primary_icons_dimension' />

                  <div align='center' style={{ paddingTop: 10 }}>
                    SKU Found !
                  </div>
                </div>
              </>
            </Alert>
          </Snackbar>
        </Stack>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar className='jsonconverter_header'>
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ paddingLeft: '10px' }}>
                <img onClick={() => navigate('/tbm/ps/dashboard')} src={HeaderLogoIcon} alt='Logo' className='logout_icon_dimension drawer_cancel_cursor' />
              </Typography>
              <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
                TBM-DB-TOOLS
              </Typography>

              <Typography sx={{ paddingLeft: '10px' }}>
                <img onClick={() => navigate(-1)} src={headerBack} alt='Logo' className='dbtools_backbuttton ' />
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
        <br />
        <br />
        <br />
        <br />

        <h2 style={{ color: 'black', fontWeight: 'bold' }}>SKU LOOKUP</h2>

        <Box className='sku_Input_Main_Box_width'>
          {search ? (
            <Box className='sku_Input_Sub_Box_width_Before'>
              <TextField className='input_field' sx={{ input: { textAlign: 'center' } }} label='Enter SKU' id='outlined-size-small' size='small' onChange={getInput} onInput={toInputUppercase} onKeyPress={(e) => e.key === 'Enter' && onclickOnSearch()} />

              <br />
              {enterSkuData ? (
                <Button
                  onClick={() => {
                    onclickOnSearch();
                    fillBlankSpace();
                  }}
                  className='search_Button'
                  variant='contained'
                >
                  Search
                </Button>
              ) : (
                <Button className='search_Button' variant='contained' disabled>
                  Search
                </Button>
              )}
            </Box>
          ) : (
            <Box className='sku_Input_Sub_Box_width_After'>
              <TextField className='input_field' sx={{ input: { textAlign: 'center' } }} label='Enter SKU' id='outlined-size-small' size='small' onChange={getInput} onInput={toInputUppercase} onKeyPress={(e) => e.key === 'Enter' && onclickOnSearch()} /> <br />
              {enterSkuData ? (
                <Button
                  onClick={() => {
                    onclickOnSearch();
                    fillBlankSpace();
                  }}
                  className='search_Button'
                  variant='contained'
                >
                  Search
                </Button>
              ) : (
                <Button className='search_Button' variant='contained' disabled>
                  Search
                </Button>
              )}
            </Box>
          )}
        </Box>

        {showTable ? (
          <>
            <Box align='start' className='sku_Details_Main_Box_Sku_Page'>
              {getSku ? (
                <Box className='sku_Type_Status_width'>
                  <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={1}>
                    <Item className='sku_type_status_brand_product_label'>
                      SKU:{<br />} <span className='sku_page_Font_weight'>{getSku}</span>
                    </Item>
                    <Item className='sku_type_status_brand_product_label'>
                      Type:{<br />} <span className='sku_page_Font_weight'>{getType}</span>
                    </Item>
                    <Item className='sku_type_status_brand_product_label'>
                      {Active === true ? (
                        <>
                          Status:
                          <span>
                            <Chip size='small' label='Active' color='success' />
                          </span>
                        </>
                      ) : (
                        <>
                          Status:
                          <span>
                            <Chip size='small' label='Inactive' color='error' />
                          </span>
                        </>
                      )}
                    </Item>
                  </Stack>
                </Box>
              ) : (
                ''
              )}
              <Box className='brand_Product_Label_Margin'>
                {getBrand ? (
                  <>
                    <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={1}>
                      {getBrand ? (
                        <Item className='sku_type_status_brand_product_label'>
                          Brand: <span className='sku_page_Font_weight'>{getBrand}</span>
                        </Item>
                      ) : (
                        ''
                      )}

                      {getProduct ? (
                        <Item className='sku_type_status_brand_product_label'>
                          Product: <span className='sku_page_Font_weight'>{getProduct}</span>
                        </Item>
                      ) : (
                        ''
                      )}
                      {getLabel ? (
                        <Item className='sku_type_status_brand_product_label'>
                          Label: <span className='sku_page_Font_weight'>{getLabel}</span>
                        </Item>
                      ) : (
                        ''
                      )}
                    </Stack>
                  </>
                ) : (
                  <>
                    <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={1} mt={0}>
                      {getLabel ? (
                        <Item className='category_width_Sku_Page'>
                          Label: <span className='sku_page_Font_weight'>{getLabel}</span>
                        </Item>
                      ) : (
                        ''
                      )}
                    </Stack>
                  </>
                )}

                <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={1} mt={2}>
                  {getCategory ? (
                    <Item className='category_width_Sku_Page'>
                      Description: <span className='sku_page_Font_weight'>{getCategory}</span>
                    </Item>
                  ) : (
                    ''
                  )}
                </Stack>
              </Box>
            </Box>
          </>
        ) : (
          <Box className='blank_Space_Box_Width_Height'></Box>
        )}
      </div>
    </>
  );
}
