import React from "react";
import font from "../../../font/Roboto-Light.woff";
import fontr from "../../../font/Roboto-Regular.woff";
import fontb from "../../../font/Roboto-Bold.woff";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: "RobotoLight",
  src: font,
});
Font.register({
  family: "Roboto-Regular.woff",
  src: fontr,
});
Font.register({
  family: "Roboto-Bold.woff",
  src: fontb,
});
// Create styles
const styles = StyleSheet.create({
  section_row_body: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 15,
  },
  section_row_bullets: {
    flexDirection: "row",
    paddingTop: 4,
    paddingRight: 5,
  },

  headingText: {
    fontSize: 18,
    color: "#333333",
    paddingTop: "1px",
    fontFamily: "RobotoLight",
    fontWeight: "400",
  },

  paraheading: {
    fontSize: 13,
    color: "#2C2E35",
    paddingTop: 8,
    fontFamily: "RobotoLight",
  },
  headingButton: {
    color: "gray",
    border: "1.5px solid #85837D",
    paddingTop: "4.5px",
    width: 65,
    height: 25,
    fontSize: "12px",
    textAlign: "center",
    backgroundColor: "#FAC41E",
    fontFamily: "RobotoLight",
    fontWeight: "400",
  },
  firstBlock: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "10px",
  },
  secondBlock: {
    border: "2px solid #2D3030",
    marginTop: "15px",
  },
  firstSection: {
    backgroundColor: "#2D3030",
    color: "white",
    fontSize: 22,
    paddingTop: 3,
    paddinBottom: 4,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
  },
  secondSection: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "15px",
  },
  secondBlockImage: {
    width: "20%",
  },
  description: {
    color: "#2C2E35",
    width: "100%",
    fontSize: "11px",
    paddingTop: 5,
    lineHeight: 1.1,
  },
  secondBlockDescription: {
    width: "80%",
    fontSize: "10px",
    paddingTop: "15px",
  },
  image: {
    width: 140,
    height: 120,
    paddingLeft: "10px",
    paddingRight: "25px",
    paddingTop: "15px",
  },
  image2: {
    width: 55,
    height: 35,
    paddingLeft: 15,
  },
  padding: {
    paddingTop: 5,
    fontFamily: "RobotoLight",
  },
  boldheadingwithpadding: {
    paddingTop: 5,
    fontFamily: "Roboto-Bold.woff",
    color: "#000",
  },
  break: {
    backgroundColor: "black",
    height: 5,
    marginTop: 5,
  },
  breakCopy: {
    backgroundColor: "black",
    height: 1,
    marginTop: "4px",
  },
  fontFamily: {
    paddingTop: 0,
    fontFamily: "RobotoLight",
    fontWeight: "400",
  },
  bullets: {
    paddingLeft: 15,
    color: "#2C2E35",
  },
  bulletspoint: {
    paddingLeft: 0,
    color: "#2C2E35",
  },
  bulletspointcontent: {
    paddingLeft: 0,
    color: "#2C2E35",
    fontFamily: "RobotoLight",
  },
  bulletscontent: {
    fontFamily: "RobotoLight",
    paddingLeft: 15,
    width: "98%",
  },
  paddingstyle: {
    fontFamily: "Roboto-Bold.woff",
  },
  boldheading: {
    fontFamily: "Roboto-Bold.woff",
    color: "#000",
  },
  horizontalborder: {
    width: "100%",
    height: "2px",
    flexDirection: "row",
    backgroundColor: "#7E7E7E",
  },
  section_space: {
    lineHeight: "1.5px",
    paddingTop: 5,
  },
});

export default function WarrantyInformation() {
  return (
    <>
      <View style={styles.section_row_body}>
        <View style={styles.firstBlock}>
          <Text style={styles.headingText}>WARRANTY INFORMATION</Text>
        </View>
        <View>
          <Text style={styles.paraheading}>
            THE BLINDMAN PRODUCTS STANDARD WARRANTY
          </Text>
        </View>
        <View style={styles.description}>
          <Text style={styles.fontFamily}>
            A limited lifetime warranty is extended to the original purchaser
            for the life of the TBM product against defects in materials and
            workmanship. The industry standard for the lifetime of window
            coverings is 7 years. Non TBM brand products are subject to their
            manufacturers warranty terms. Any resale or transfer of ownership of
            the product voids the warranty. Repair or remake are the only
            remedies for warranty claims. No other warranty is implied or given
            by TBM. Slight warping and discoloration on all window covering
            products are considered normal and are not covered under the
            warranty. Acts of God and normal fading and exposure to water, wind
            or extreme heat are considered abuse and are not covered under the
            warranty. Strings, fabrics and other parts that wear out over time
            are not defects, they are normal “wear and tear”.{" "}
          </Text>
          <View style={styles.section_row_bullets}>
            <Text style={styles.bulletspointcontent}>
              <Text style={styles.boldheading}>Fabric shades </Text>Fabrics are
              warrantied for between 3-7 years depending on the manufacturer of
              the fabric. Moisture, wind and sun damage are not covered under
              this warranty. Roller shades must be operated with care. Tracking
              on a roller shade can be thrown off by any type of miss-handling.
            </Text>
          </View>
          <View style={styles.section_row_bullets}>
            <Text style={styles.bulletspointcontent}>
              <Text style={styles.boldheading}>Service Repairs </Text>performed
              or attempted by unauthorized service agents will void the
              warranty. We retain the right to make a factory inspection to
              determine the cause of the problem. Inspection must reveal a
              manufacturer defect to be a covered warran-ty repair. All repairs
              not covered under our warranty must be paid in full at time of
              service.
            </Text>
          </View>
          <View style={styles.section_row_bullets}>
            <Text style={styles.bulletspointcontent}>
              <Text style={styles.boldheading}>
                TBM warranty includes in-home services for 1 year from the date
                of the original installation. After the first year, warranty
                services are offered at your location, a trip charge will be
                assessed for this service.
              </Text>{" "}
              Products needing service may be brought or sent to one of our
              service locations. All shipping is the responsibility of the
              customer.
            </Text>
          </View>
        </View>
        <View>
          <Text style={styles.paraheading}>
            THE BLINDMAN MOTORIZATION WARRANTY
          </Text>
        </View>
        <View style={styles.description}>
          <Text style={styles.fontFamily}>
            All motorization, both battery and hard-wire are not included in TBM
            limited lifetime warranty. Motors, remotes and other hard-ware are
            warrantied for 5 years. With a 1 year in-home warranty covering
            manufacturer defects. Abuse or misuse of any kind is not warrantied.
            Re-programing and other in-home services are subject to the current
            Blindman trip charge. Batteries are not warrantied. Blinds needing
            service may be brought or sent into one of our service locations.
            All shipping is the responsibility of the customer.
          </Text>
          <View style={styles.section_space}>&nbsp;</View>
          <Text style={styles.boldheading}>
            See our website for additional warranty and maintenance information:
            www.theblindman.com
          </Text>
        </View>
        <View style={styles.section_space}>&nbsp;</View>
        <View>
          <Text style={styles.paraheading}>
            NON-BLINDMAN BRAND PRODUCTS WARRANTY
          </Text>
        </View>
        <View style={styles.description}>
          <Text style={styles.fontFamily}>
            TBM is a licensed distributer for many other window covering
            products. Products that are not manufactured by TBM are subject to
            the warranty given by the manufacturer of that product.
          </Text>
        </View>
      </View>
      <View style={styles.horizontalborder}>&nbsp;</View>
    </>
  );
}
