/** @format */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CONFIG from '../../config/config.js';
import { Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';

export const NewCustomer = (props) => {
  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();
  const [fullname, setFullname] = useState();

  useEffect(() => {
    {
      window.scrollTo(0, 0);
    }
    getVerified();

    getCustomerList();
  }, []);

  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };
  // Fetching data through Customers API
  const getCustomerList = async () => {
    await fetch(CONFIG.API_URL + '/cm/Customers', {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        // Redirect to Login page if token is not valid
        if (data.error.message === 'Token is not valid') {
          navigate(`/login`, { state: { error: 'Unauthorized Please Login' } });
        }

        // Pass the data if token is valid
        else {
          setSearchData(data.customers);
          setFullname(data.customers.first_name + ' ' + data.customers.last_name);
        }
      });
  };

  return (
    <div align='center' className='padding_bottom'>
      {searchData.slice(5, 8).map((customer) => {
        return (
          <Box className='centered_box'>
            <h2>
              <List className='list'>
                <ListItem className='margin_padding_0'>
                  <ListItemText className='App clickable_header' onClick={(e) => {}}>
                    {' '}
                    <Link className='link2' to={`/tbm/ps/customerdetails/${customer.customer_id}`}>
                      {customer.first_name} {customer.last_name} <br />({customer.address_line}, {customer.city})
                    </Link>
                  </ListItemText>
                </ListItem>
              </List>
            </h2>
          </Box>
        );
      })}
    </div>
  );
};
