/** @format */

import React, { useState, useEffect, useContext, createContext } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Grid, Typography, MenuItem, FormControl, Select, InputAdornment, Stack, Snackbar, createTheme, ThemeProvider, Button, Alert as MuiAlert, IconButton, Drawer, Autocomplete, Chip } from '@mui/material';
import { Box } from '@mui/system';
import { makeStyles } from '@mui/styles';
import { Error as ErrorIcon, CancelRounded as CancelRoundedIcon, ErrorRounded as ErrorRoundedIcon, CheckCircle as CheckCircleIcon, Search as SearchIcon } from '@mui/icons-material';
import fracty from 'fracty';

import DrawerInputWidth from '../WindowsManagement/DrawerInputWidth';
import CONFIG from '../../config/config.js';
import { UserContext } from '../Shared/Layout';
import { SnackbarContext } from '../../App';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import FilterIcon from '../../assets/icons/secondary/2nd-filter.svg';
import DrawerInput from '../WindowsManagement/DrawerInput';
import CommonDrawerInputWidth from '../WindowsManagement/CommonDrawerInputWidth';
import WindowOptions from '../Shared/WindowOptions';
import ProductOptions from '../Shared/ProductOptions';
import InformationAlerts from '../Shared/InfoAlerts.jsx';
import WarningIcon from '../../assets/icons/misc/alert.svg';
import { WindowContext } from '../Shared/WindowProvider.jsx';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#333333',
    },
  },
});

const useStyles = makeStyles({
  paper: {
    width: '90%',
    backdropFilter: 'blur(10px) !important',
    background: 'rgba(69, 157, 166, 0.73)  !important',
  },
});

export const CoveringDrawer2 = ({ data, func2 }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const coveringdetail = useContext(UserContext);
  const { windowDetails } = useContext(WindowContext);

  if (location.state === null) {
    navigate('/pagenotfound');
  }

  let additional_sku = [];
  let coveringdetails = [];

  const [windowData, setWindowData] = useState(data.windowData);
  const [opacity, setOpacity] = useState(data.opacity);
  const [fabric, setFabric] = useState(data.fabric);
  const [lift, setLift] = useState(data.lift);
  const [direction, setDirection] = useState(data.direction);
  const [headrail, setHeadrail] = useState('');
  const [design, setDesigner] = useState('0');
  const [option, setOption] = useState([...data.option]);
  const [open, setOpen] = React.useState(false);
  const [chooseFilters, setChooseFilters] = useState([]);
  const [multiplemt, setmultipleMT] = useState([]);
  const [coveringcategory, setWCC] = useState(data.wcc);
  const [keywordsbyFilter, setKeywordsbyFilter] = useState([]);
  const [keywordsbySearch, setKeywordsbySearch] = useState([]);
  const [brand, setBrand] = useState(data.brand);
  const [label, setLabel] = useState(data.label);
  const [type, setType] = useState(data.type);
  const [product, setProduct] = useState(data.product);
  const [savedlift, setsavedLift] = useState(data.lift);
  const [saveddirection, setsavedDirection] = useState(data.direction);
  const [showThirdSection, setshowThirdSection] = useState(false);
  const [brands, setBrands] = useState([]);
  const [brandLabel, setBrandLabel] = useState([]);
  const [brandLabelProduct, setbrandLabelProduct] = useState([]);
  const [brandLabelProductType, setbrandLabelProductType] = useState([]);
  const [brandLabelProductTypeOpacity, setbrandLabelProductTypeOpacity] = useState([]);
  const [brandLabelProductTypeOpacityFabric, setbrandLabelProductTypeOpacityFabric] = useState([]);
  const [brandLabelProductTypeOpacityFabricLift, setbrandLabelProductTypeOpacityFabricLift] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirection, setbrandLabelProductTypeOpacityFabricLiftDirection] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrail, setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrail] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValance, setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValance] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeatures, setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeatures] = useState([]);
  const [brandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeaturesTrack, setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeaturesTrack] = useState([]);
  const [valanceColor, setValanceColor] = useState('');
  const [hingeColor, setHingeColor] = useState('');
  const [headrailColor, setHeadrailColor] = useState('');
  const [cordColor, setCordColor] = useState('');
  const [pClip, setPClip] = useState('');
  const [paintColor, setPaintColor] = useState('');
  const [tiltConfiguration, setTiltConfiguration] = useState('');
  const [cordLocation, setCordLocation] = useState('');
  const [panelConfiguration, setPanelConfiguration] = useState('');
  const [CoveringCategory, setCoveringCategory] = useState();
  const [showInTable, setShowInTable] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedSKU, setSelectedSKU] = React.useState('');
  const [classtype, setClassType] = useState('');
  const [proposalName, setProposalName] = useState();
  const [labelnames, setLabelNames] = useState([]);
  const [categorynames, setCategoryNames] = useState([]);
  const [optionSKUs, setOptionSkus] = useState([]);
  const [OptionLabel, setOptionLabel] = useState([]);
  const [OptionName, setOptionName] = useState([]);
  const [SelectedOptions, setSelectedOptions] = useState([]);
  const [ReqOptionDetails, setReqOptionDetails] = useState([]);
  const [NotReqOptionDetails, setNotReqOptionDetails] = useState({});
  const [LiftSystem, setLiftSystem] = useState([]);
  const [LiftSytemDetails, setLiftSytemDetails] = useState([]);
  const [SelectedLiftSytemDetails, setSelectedLiftSytemDetails] = useState([]);
  const [SelectedLiftSytemSKU, setSelectedLiftSytemSKU] = useState(data.SelectedLiftSytemSKU);
  const [SelectedOptionDetails, setSelectedOptionDetails] = useState([]);
  const [SelectedOptionSKU, setSelectedOptionSKU] = useState(data.SelectedOptionSKU);
  const [SelectedFabricSKU, setSelectedFabricSKU] = useState(data.SelectedFabricSKU);
  const [alertmessage, setAlertMessage] = useState('');
  const [SKUCombinations, setSKUCombinations] = useState([...data.SKUCombinations]);
  const [ProductDescription, setProductDescriptions] = useState([]);
  const [savedSKUCombinations, setsavedSKUCombinations] = useState(data.SKUCombinations);
  const [savedProductDescription, setsavedProductDescriptions] = useState(data.ProductDescription);
  const [SelectedOption, setSelectedOption] = useState([]);
  const [allReqOptionskey, setAllReqOptionsKey] = useState({});
  const [allNotReqOptionskey, setAllNotReqOptionsKey] = useState({});
  const [SelectedOptionskey, setSelectedOptionsKey] = useState([]);
  const [selectedOptionJSON, setSelectedOptionJSON] = useState({
    ...data.selectedOptionJSON,
    ...data.OptionalFinalizationFields,
  });
  const [drawerstatus, setDrawerStatus] = useState(true);
  const [clearfilters, setClearFilters] = useState(data.clearfilters);
  const [showProductDescription, setShowProductDescription] = useState(false);
  const [showSKUCombination, setShowSKUCombination] = useState(false);
  const [searchArr, setSearchArr] = useState([]);
  const [AllKeywords, setAllKeywords] = useState([]);
  const [CoveringSearchResults, setCoveringSearchResults] = useState([]);
  const [CoveringDetails, setCoveringDetails] = useState([]);
  const [windowCoveringCategoryOpen, setWindowCoveringCategoryOpenOpen] = useState(false);
  const [brandOpen, setBrandOpen] = useState(false);
  const [labelOpen, setLabelOpen] = useState(false);
  const [productOpen, setProductOpen] = useState(false);
  const [typeOpen, setTypeOpen] = useState(false);
  const [opacityOpen, setOpacityOpen] = useState(false);
  const [fabricOpen, setFabricOpen] = useState(false);
  const [liftOpen, setLiftOpen] = useState(false);
  const [directionOpen, setDirectionOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState(data.checkedItems);
  const [measurementType, setMeasurementType] = useState(data.measurementType);
  const [windowdepth, setWindowDepth] = useState('');
  const [size, setSize] = useState(data.size);
  const [isNoDrawerOpen, setIsNoDrawerOpen] = useState(false);
  const [mountsurface, setMountsurface] = useState(data.mountsurface);
  const [ladder, setLadder] = useState(data.ladder);
  const [WindowConfiguration, setWindowConfiguration] = useState('');
  const [existingCovering, setExistingCovering] = useState(data.existingCovering);
  const [buildType, setBuildType] = useState(data.buildType);
  const [outgoingwindow_width, setOutgoingSizeW] = useState(data.outgoingwindow_width);
  const [outgoingwindow_height, setOutgoingSizeH] = useState(data.outgoingwindow_height);
  const [isEnabled, setIsEnabled] = useState(true);
  const [value, setValue] = useState([]);
  const [optionalFinalizationFields, setOptionalFinalizationFields] = useState(data.OptionalFinalizationFields);
  const [cordColorOption, setcordColorOption] = useState([]);
  const [valanceColorOption, setValanceColorOption] = useState([]);
  const [headrailColorOption, setHeadrailColorOption] = useState([]);
  const [cordLocationOption, setCordLocationOption] = useState([]);
  const [panelConfigurationOption, setPanelConfigurationOption] = useState([]);
  const [hingeColorOption, setHingeColorOption] = useState([]);
  const [pClipOption, setPClipOption] = useState([]);
  const [tiltConfigurationOption, setTiltConfigurationOption] = useState([]);
  const [saveStatus, setSaveStatus] = useState(data.saveStatus);
  const [window_width, setSizeW] = useState();
  const [window_height, setSizeH] = useState();
  const [window_widthindecimal, setSizeWindecimal] = useState();
  const [window_heightindecimal, setSizeHindecimal] = useState();
  const [VerifyStatus, setVerifyStatus] = useState('false');
  const [psf, setPSF] = useState(data.psf);
  const [isCommonDrawerOpen, setIsCommonDrawerOpen] = useState(false);
  const [SelectedWindow, setSelectedWindow] = useState(data.SelectedWindow);
  const [SelectedWindowMeasurementType, setSelectedWindowMeasurementType] = useState();
  const [ProposalID, setProposalID] = useState();
  const [measurementTypeArray, setMeasurementTypeArray] = useState([]);
  const [AllOptionSelectedStatus, setAllOptionSelectedStatus] = useState(false);
  const [AllOptionNPSSelectedStatus, setAllOptionNPSSelectedStatus] = useState(false);
  const [obstructions, setObstructions] = useState('');
  const [multiple, setMultiple] = useState('');
  const [AddNote, setAddNote] = useState('');
  const [AddNoteforBuildOut, setAddNoteforBuildOut] = useState('');
  const [AddNoteforFillerStrip, setAddNoteforFillerStrip] = useState('');
  const [AddNoteforSplitTilt, setAddNoteforSplitTilt] = useState('');
  const [AddNoteforSplitRail, setAddNoteforSplitRail] = useState('');
  const [Panel, setPanel] = useState('');
  const [Windowsill, setWindowsill] = useState('Yes');
  const [DeadMold, setDeadMold] = useState('');
  const [Buildout, setBuildout] = useState('');
  const [FillerStrip, setFillerStrip] = useState('');
  const [SplitTilt, setSplitTilt] = useState('');
  const [SplitRail, setSplitRail] = useState('');
  const [VerticalConfiguration, setVerticalConfiguration] = useState('1 Section');
  const [SectionOneTopShow, setSectionOneTopShow] = useState('');
  const [SectionTwoBottomShow, setSectionTwoBottomShow] = useState('');
  const [SectionOneTopHeigth, setSectionOneTopHeigth] = useState('');
  const [SectionTwoBottomWidth, setSectionTwoBottomWidth] = useState('');
  const [HeigthType, setHeigthType] = useState('');
  const [SectionTopDrawerOpen, setSectionTopDrawerOpen] = useState(false);
  const [VerticalConfigurationAlert, setVerticalConfigurationAlert] = useState(false);
  const [HeightForVerticalConfiguration, setHeightForVerticalConfiguration] = useState('');
  const [othermeasurement, setOtherMeasurement] = useState('');
  const [description, setDescription] = useState('');
  const [quickapply, setQuickApply] = useState();
  const [forMultipleCheckMT, setForMultipleCheckMT] = useState();
  const [forMultipleCheckSW, setForMultipleCheckSW] = useState();
  const [forMultipleCheckSH, setForMultipleCheckSH] = useState();
  const [forMultipleCheckEC, setForMultipleCheckEC] = useState();
  const [forMultipleCheckBT, setForMultipleCheckBT] = useState();
  const [forMultipleCheckMS, setForMultipleCheckMS] = useState();
  const [forMultipleCheckL, setForMultipleCheckL] = useState();
  const [forMultipleCheckWD, setForMultipleCheckWD] = useState();
  const [forMultipleCheckWC, setForMultipleCheckWC] = useState();
  const [forMultipleCheckOM, setForMultipleCheckOM] = useState();
  const [forMultipleCheckO, setForMultipleCheckO] = useState();
  const [forMultipleCheckVC, setforMultipleCheckVC] = useState();
  const [forMultipleCheckDesc, setForMultipleCheckDesc] = useState();

  const vertical = 'top';
  const horizontal = 'center';

  const [section1, setSection1] = useState('');
  const [section2, setSection2] = useState('');
  const [section3, setSection3] = useState('');
  const [section4, setSection4] = useState('');
  const [section5, setSection5] = useState('');
  const [section6, setSection6] = useState('');
  const isNumeric = (value) => !isNaN(value) && isFinite(value);

  const handleSectionValue = (value) => {
    return isNumeric(value) && value >= 0 ? fracty(value) + '"' : '';
  };

  // Process each section value
  const sec2_1 = handleSectionValue(section1);
  const sec2_2 = handleSectionValue(section2);
  const sec3_1 = handleSectionValue(section1);
  const sec3_2 = handleSectionValue(section2);
  const sec3_3 = handleSectionValue(section3);
  const sec4_1 = handleSectionValue(section1);
  const sec4_2 = handleSectionValue(section2);
  const sec4_3 = handleSectionValue(section3);
  const sec4_4 = handleSectionValue(section4);
  const sec5_1 = handleSectionValue(section1);
  const sec5_2 = handleSectionValue(section2);
  const sec5_3 = handleSectionValue(section3);
  const sec5_4 = handleSectionValue(section4);
  const sec5_5 = handleSectionValue(section5);
  const sec6_1 = handleSectionValue(section1);
  const sec6_2 = handleSectionValue(section2);
  const sec6_3 = handleSectionValue(section3);
  const sec6_4 = handleSectionValue(section4);
  const sec6_5 = handleSectionValue(section5);
  const sec6_6 = handleSectionValue(section6);

  const [ChangedSection1, setChangedSection1] = useState(false);
  const [ChangedSection2, setChangedSection2] = useState(false);
  const [ChangedSection3, setChangedSection3] = useState(false);
  const [ChangedSection4, setChangedSection4] = useState(false);
  const [ChangedSection5, setChangedSection5] = useState(false);
  const [ChangedSection6, setChangedSection6] = useState(false);

  let sectionValues = {
    section1: { value: section1, changed: ChangedSection1 },
    section2: { value: section2, changed: ChangedSection2 },
    section3: { value: section3, changed: ChangedSection3 },
    section4: { value: section4, changed: ChangedSection4 },
    section5: { value: section5, changed: ChangedSection5 },
    section6: { value: section6, changed: ChangedSection6 },
  };

  const trueSections = {}; // Initialize an empty JSON object
  const [totalWidth, setTotalWidth] = useState('');
  const [WidthType, setWidthType] = useState('');
  const [msg, setMsg] = useState('');
  const [warnQty, setwarnQty] = useState(false);
  const [leftShadeisDrawerOpen, setLeftShadeisDrawerOpen] = useState(false);

  useEffect(() => {
    if (coveringcategory !== '') {
      if (measurementTypeArray.length > 0) {
        function removeValueFromArray(arr, value) {
          return arr.filter((item) => item !== value);
        }
        function findCommonValues(arr) {
          const seenValues = new Set();
          const commonValues = [];

          for (let i = 0; i < arr.length; i++) {
            const currentValue = arr[i];
            if (seenValues.has(currentValue)) {
              if (!commonValues.includes(currentValue)) {
                commonValues.push(currentValue);
              }
            } else {
              seenValues.add(currentValue);
            }
          }

          return commonValues;
        }
      }
    }
  }, [coveringcategory, measurementTypeArray]);

  useEffect(() => {
    setWindowConfiguration(data.WindowConfiguraion);
    setWindowsill(data.Windowsill);
    setHeightForVerticalConfiguration(data.HeightForVerticalConfiguration);
    setSectionOneTopShow(fracty(data.SectionOneTopHeigth) + '"');
    setSectionTwoBottomShow(fracty(data.SectionTwoBottomWidth) + '"');
    setSectionOneTopHeigth(data.SectionOneTopHeigth);
    setSectionTwoBottomWidth(data.SectionTwoBottomWidth);
    setTotalWidth(data.totalWidth);
  }, [data.WindowConfiguraion, data.HeightForVerticalConfiguration, data.SectionOneTopHeigth, data.SectionTwoBottomWidth, data.totalWidth]);

  useEffect(() => {
    if (data.clearfilters === true) {
      reset();
      setClearFilters(false);
    }
  }, [data.clearfilters]);

  useEffect(() => {
    let proposal_specific_factor = {};
    switch (measurementType) {
      case 'Blinds':
        proposal_specific_factor = {
          WindowDepth: windowdepth,
          WindowConfiguration: headrail,
          Obstructions: obstructions,
          Description: description,
          Othermeasurement: othermeasurement.replace(/"/g, ''),
        };
        break;
      case 'Quick':
        proposal_specific_factor = {
          WindowDepth: windowdepth,
          Obstructions: obstructions,
          Othermeasurement: othermeasurement.replace(/"/g, ''),
          Description: description,
        };
        break;
      case 'Soft Shades':
        proposal_specific_factor = {
          WindowConfiguration: headrail,
          WindowDepth: windowdepth,
          Obstructions: obstructions,
          Description: description,
          Othermeasurement: othermeasurement.replace(/"/g, ''),
        };
        break;
      case 'Roller Shades':
        proposal_specific_factor = {
          WindowConfiguration: headrail,
          Obstructions: obstructions,
          WindowDepth: windowdepth,
          Description: description,
          Othermeasurement: othermeasurement.replace(/"/g, ''),
        };
        break;
      case 'Shutters':
        if (SplitTilt === 'No') {
          setAddNoteforSplitTilt('');
        }
        if (SplitRail === 'No') {
          setAddNoteforSplitRail('');
        }
        proposal_specific_factor = {
          VerticalConfiguration: VerticalConfiguration,
          Windowsill: Windowsill,
          SectionOneTop: SectionOneTopHeigth,
          SectionTwoBottom: SectionTwoBottomWidth,
          WindowConfiguration: WindowConfiguration,
          Panel: Panel,
          WindowDepth: windowdepth,
          Obstructions: obstructions,
          Description: description,
          Othermeasurement: othermeasurement.replace(/"/g, ''),
        };
        break;
      default:
        proposal_specific_factor = {};
        break;
    }

    setPSF(proposal_specific_factor);
  }, [
    measurementType,
    windowdepth,
    headrail,
    obstructions,
    Panel,
    Windowsill,
    DeadMold,
    Buildout,
    FillerStrip,
    SplitTilt,
    SplitRail,
    AddNoteforBuildOut,
    AddNoteforFillerStrip,
    AddNoteforSplitTilt,
    AddNoteforSplitRail,
    othermeasurement,
    description,
    existingCovering,
    buildType,
    mountsurface,
    ladder,
    VerticalConfiguration,
    SectionOneTopHeigth,
    SectionTwoBottomWidth,
    multiple,
  ]);

  const fetchdatafromCommonDrawer = (commondrawerstatus, result) => {
    setIsCommonDrawerOpen(commondrawerstatus);

    if (result === '') {
      setOtherMeasurement('');
    } else {
      setOtherMeasurement(result + '"');
    }
  };

  useEffect(() => {
    setMultiple(data.RSWindowConfiguration);
  }, [data.RSWindowConfiguration]);

  useEffect(() => {
    if (coveringdetail !== undefined) {
      if (data.brand && coveringcategory) {
        coveringdetail.forEach((element, i) => {
          if (element.brand === data.brand && element.covering_category === coveringcategory) {
            coveringdetails.push(element);
          }
        });

        setCoveringDetails([...coveringdetails]);
      }
    }

    setCoveringCategory(coveringcategory);
  }, [brand, coveringcategory]);

  useEffect(() => {
    if (coveringdetail !== undefined) {
      if (data.brand) {
        coveringdetail.forEach((element, i) => {
          if (element.brand === data.brand) {
            coveringdetails.push(element);
          }
        });

        setCoveringDetails([...coveringdetails]);
      } else {
        coveringdetails = coveringdetail;
        setCoveringDetails([...coveringdetails]);
      }
    }
  }, [coveringdetail]);

  useEffect(() => {
    if (coveringdetail !== undefined) {
      if (data.brand) {
        if (coveringcategory) {
          setCoveringCategory(coveringcategory);
          coveringdetail.forEach((element, i) => {
            if (element.brand === data.brand && element.covering_category === coveringcategory) {
              coveringdetails.push(element);
            }
          });

          setCoveringDetails([...data.brand]);
        } else {
          coveringdetail.forEach((element, i) => {
            if (element.brand === data.brand) {
              coveringdetails.push(element);
            }
          });
        }
      } else {
        coveringdetails = coveringdetail;
        setCoveringDetails([...coveringdetails]);
      }
    }
  }, [data.brand]);

  useEffect(() => {
    let brandSelected = [];
    if (coveringdetail !== undefined && coveringcategory && label) {
      coveringdetail.forEach((element, i) => {
        if (element.covering_category === coveringcategory && element.label === label) {
          brandSelected.push(element.brand);
        }
      });

      brandSelected = brandSelected.filter(unique);
      setCoveringCategory(coveringcategory);
    }
  }, [coveringcategory, label, coveringdetail]);

  let proposaldetails = {
    customerdata: location.state.customerdata,
    proposal_id: location.state.proposalID,
    proposal_name: location.state.proposalName,
  };

  const getOptionalFinalizationDropDowns = async () => {
    const response = await fetch(`${CONFIG.API_URL}/pm/OptionalFinalizationDropDownList`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      token: token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      return;
    }
    setValanceColorOption(data.OptionalFinalizationDropDownList.ValanceColor);
    setcordColorOption(data.OptionalFinalizationDropDownList.CordColor);
    setCordLocationOption(data.OptionalFinalizationDropDownList.CordLocation);
    setHingeColorOption(data.OptionalFinalizationDropDownList.HingeColor);
    setPClipOption(data.OptionalFinalizationDropDownList.PClip);
    setPanelConfigurationOption(data.OptionalFinalizationDropDownList.PanelConfiguration);
    setTiltConfigurationOption(data.OptionalFinalizationDropDownList.TiltConfiguration);
    setHeadrailColorOption(data.OptionalFinalizationDropDownList.HeadrailColor);
  };

  useEffect(() => {
    getVerified();
    getOptionalFinalizationDropDowns();
    setProposalName(location.state.proposalName);
    filteredResult();

    let ActualSelectedWindows = [];
    if (location.state.selectedwindows) {
      ActualSelectedWindows = location.state.selectedwindows;
      if (ActualSelectedWindows.length > 0) {
        let arr_measurement_type = [];
        ActualSelectedWindows.forEach((element) => {
          arr_measurement_type.push(element.measurement_type);
        });
        arr_measurement_type = arr_measurement_type.filter(unique);

        setMeasurementTypeArray(arr_measurement_type);
      }
    }
  }, []);

  const token = sessionStorage.getItem('token');

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  useEffect(() => {
    if (location.state.path && location.state.path === 'Batch-Edit') {
      setProposalID(location.state.proposaldetails.proposal_id);
      ProposalByProposal();
    } else {
      setProposalID(location.state.proposalID);
    }
  }, [ProposalID]);

  const ProposalByProposal = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };
    // Created Proposal will be added to the database through CreateProposal API
    const response = await fetch(`${CONFIG.API_URL}/pm/ProposalByProposalId/${ProposalID}`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      return;
    }
    let ActualSelectedWindows = location.state.selectedwindows;

    function findCommonObjects(arrays) {
      if (arrays.length === 0) {
        return [];
      }

      // Start with the first array as the reference
      const referenceArray = arrays[0];

      // Use the rest of the arrays for comparison
      const commonObjects = referenceArray.filter((item1) => arrays.every((array) => array.some((item2) => item1.window_id === item2.window_id)));

      return commonObjects;
    }

    if (ActualSelectedWindows !== undefined) {
      setSelectedWindow(findCommonObjects([data.proposal_details, ActualSelectedWindows]));
    }
  };

  useEffect(() => {
    if (SelectedWindow && SelectedWindow.length > 0) {
      let arr_measurement_type = [];
      let sizes_W = [];
      let sizes_H = [];
      let ECs = [];
      let BTs = [];
      let MSs = [];
      let Ladders = [];
      let WDs = [];
      let Os = [];
      let OMs = [];
      let WCs = [];
      let Descs = [];
      let proposal_additional_skus = SelectedWindow.map(({ proposal_additional_sku, additional_sku }) => proposal_additional_sku || additional_sku);

      SelectedWindow.forEach((windowDetails) => {
        arr_measurement_type.push(windowDetails.proposal_measurement_type);
        sizes_W.push(windowDetails.proposal_window_width);
        sizes_H.push(windowDetails.proposal_window_height);

        WCs.push(windowDetails.proposal_measurement_type_specific_factor.WindowConfiguration);

        // shutters
        setWindowConfiguration(windowDetails.proposal_measurement_type_specific_factor.WindowConfiguration);
        setSelectedWindowMeasurementType(arr_measurement_type);
        if (arr_measurement_type.includes('Shutters')) {
          setWindowsill(windowDetails.proposal_measurement_type_specific_factor.Windowsill);
        }
        setHeightForVerticalConfiguration(windowDetails.proposal_window_height);

        // blinds & soft shades
        setHeadrail(windowDetails.proposal_measurement_type_specific_factor.WindowConfiguration);

        // roller shades
        setMultiple(windowDetails.proposal_measurement_type_specific_factor.WindowConfiguration);
      });

      // Initialize arrays to store common and uncommon data
      let commonADData = [];
      function findCommonData(arrays) {
        return arrays.reduce((common, currentArray) => {
          return common.filter((item) => currentArray.some((currentItem) => currentItem.additional_charge_sku === item.additional_charge_sku));
        }, arrays[0]);
      }

      commonADData = findCommonData(proposal_additional_skus);
      let all_arr_measurement_type = arr_measurement_type.filter(unique);
      const allEqual = (arr) => arr.every((val) => val === arr[0]);

      let checkmeasurementtype;
      let ForMultipleCheckMT = allEqual(arr_measurement_type);
      let ForMultipleCheckSW = allEqual(sizes_W);
      let ForMultipleCheckSH = allEqual(sizes_H);
      let ForMultipleCheckEC = false;
      let ForMultipleCheckBT = false;
      let ForMultipleCheckMS = false;
      let ForMultipleCheckL = false;
      let ForMultipleCheckWD = false;
      let ForMultipleCheckWC = false;
      let ForMultipleCheckO = false;
      let ForMultipleCheckOM = false;
      let ForMultipleCheckDesc = false;
      let ForMultipleCheckVC = false;
      let ForMultipleCheckWS = false;

      commonADData.forEach((ele) => {
        if (ele.type === 'Existing Covering') {
          setExistingCovering(ele.sku_name);
          ForMultipleCheckEC = true;
        }
        if (ele.type === 'Build Type') {
          setBuildType(ele.sku_name);
          ForMultipleCheckBT = true;
        }
        if (ele.type === 'Mount Surface') {
          setMountsurface(ele.sku_name);
          ForMultipleCheckMS = true;
        }
        if (ele.type === 'Ladder') {
          setLadder(ele.sku_name);
          ForMultipleCheckL = true;
        }

        if (ele.type === 'Window Configuration') {
          setHeadrail(ele.sku_name);
          ForMultipleCheckWC = true;
          if (ele.sku_name !== '1 Section') {
            let SectionsValue = JSON.parse(ele.additional_detail);

            setSection1(SectionsValue.section1);
            setSection2(SectionsValue.section2);
            setSection3(SectionsValue.section3);
            setSection4(SectionsValue.section4);
            setSection5(SectionsValue.section5);
            setSection6(SectionsValue.section6);
          }
        }

        if (ele.type === 'Vertical Configuration') {
          setVerticalConfiguration(ele.sku_name);
          ForMultipleCheckVC = true;
          if (ele.sku_name === '2 Sections') {
            let SectionsValue = JSON.parse(ele.additional_detail);

            // top height
            setSectionOneTopHeigth(SectionsValue.SectionOneTop);
            setSectionOneTopShow(fracty(SectionsValue.SectionOneTop) + '"');
            // bottom height
            setSectionTwoBottomWidth(SectionsValue.SectionTwoBottom);
            setSectionTwoBottomShow(fracty(SectionsValue.SectionTwoBottom) + '"');
          }
        }

        if (ele.type === 'Window Depth') {
          setWindowDepth(ele.sku_name);
          ForMultipleCheckWD = true;
          if (ele.sku_name === 'Other') {
            proposal_additional_skus.forEach((ad) => {
              for (const item of ad) {
                if (item.sku_name === 'Other' && item.additional_detail) {
                  OMs.push(item.additional_detail);
                }
              }
            });

            ForMultipleCheckOM = allEqual(OMs);
          }

          if (ele.sku_name === 'Other') {
            if (ForMultipleCheckOM) {
              setOtherMeasurement(OMs[0]);
              setForMultipleCheckOM(false);
            } else {
              setForMultipleCheckOM(true);
            }
          }
        }

        if (ele.type === 'Obstructions') {
          setObstructions(ele.sku_name);

          ForMultipleCheckO = true;
          if (ele.sku_name === 'Yes') {
            proposal_additional_skus.forEach((ad) => {
              for (const item of ad) {
                if (item.sku_name === 'Yes' && item.additional_detail) {
                  Descs.push(item.additional_detail);
                }
              }
            });

            ForMultipleCheckDesc = allEqual(Descs);
          }

          if (ele.sku_name === 'Yes') {
            if (ForMultipleCheckDesc) {
              setDescription(Descs[0]);
              setForMultipleCheckDesc(false);
            } else {
              setForMultipleCheckDesc(true);
            }
          }
        }
      });

      if (ForMultipleCheckMT) {
        setMeasurementType(arr_measurement_type[0]);
        setForMultipleCheckMT(false);
      } else {
        setForMultipleCheckMT(true);

        setmultipleMT(arr_measurement_type);
      }
      if (ForMultipleCheckSW && ForMultipleCheckSH) {
        setForMultipleCheckSH(false);
        setForMultipleCheckSW(false);
      } else {
        setForMultipleCheckSH(true);
        setForMultipleCheckSW(true);
      }
      if (ForMultipleCheckEC) {
        setForMultipleCheckEC(false);
      } else {
        setForMultipleCheckEC(true);
      }
      if (ForMultipleCheckBT) {
        setForMultipleCheckBT(false);
      } else {
        setForMultipleCheckBT(true);
      }
      if (ForMultipleCheckMS) {
        setForMultipleCheckMS(false);
      } else {
        setForMultipleCheckMS(true);
      }
      if (ForMultipleCheckL) {
        setForMultipleCheckL(false);
      } else {
        setForMultipleCheckL(true);
      }
      if (ForMultipleCheckWD) {
        setForMultipleCheckWD(false);
      } else {
        setForMultipleCheckWD(true);
      }

      if (ForMultipleCheckO) {
        setForMultipleCheckO(false);
      } else {
        setForMultipleCheckO(true);
      }

      if (ForMultipleCheckVC) {
        setforMultipleCheckVC(false);
      } else {
        setforMultipleCheckVC(true);
      }
      if (ForMultipleCheckWC) {
        setForMultipleCheckWC(false);
      } else {
        setForMultipleCheckWC(true);
      }

      if (ForMultipleCheckWC) {
        setForMultipleCheckWC(false);
      } else {
        setForMultipleCheckWC(true);
      }

      if (arr_measurement_type.includes('Quick')) {
        if (!arr_measurement_type.includes('Soft Shades') && !arr_measurement_type.includes('Roller Shades') && !arr_measurement_type.includes('Blinds') && !arr_measurement_type.includes('Shutters')) {
          checkmeasurementtype = allEqual(arr_measurement_type);
        } else {
          var positionofQuick = arr_measurement_type.indexOf('Quick');

          var newarr = arr_measurement_type.splice(positionofQuick, 1);

          checkmeasurementtype = allEqual(arr_measurement_type);
        }
      } else {
        checkmeasurementtype = allEqual(arr_measurement_type);
      }

      if (checkmeasurementtype) {
        setMeasurementType(arr_measurement_type[0]);
      }
    }
  }, [SelectedWindow]);

  const unique = (value, index, self) => self.indexOf(value) === index;

  const [disableButton, setDisableButton] = useState(false);

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  // To get window details
  const getLiftSystemDirectionList = async (MaterialSKU) => {
    const response = await fetch(`${CONFIG.API_URL}/pm/AvailableLiftSystemAndDirectionByMaterial`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',

      body: JSON.stringify({
        sku: MaterialSKU,
      }),
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      return;
    }
    let LiftSystemList = [];
    let DirectionList = [];
    let LiftSystemDetails = [];

    setLiftSytemDetails(data.LiftSystemAndDirection);
    data.LiftSystemAndDirection.forEach((element) => {
      if (element.sku_type === 'L') {
        LiftSystemList.push(element.value_1);

        LiftSystemDetails.push(element);
      }
      setSelectedLiftSytemDetails(LiftSystemDetails.filter(unique));

      if (lift === '' && direction === '' && element.display_sort_order === 0) {
        setLift(element.value_1);
        setDirection(element.value_2);
      }
    });

    let newLiftSystemList = LiftSystemList.filter(unique);

    setLiftSystem(LiftSystemList.filter(unique));
    if (newLiftSystemList.length === 1) {
      setLift(newLiftSystemList[0]);
      handleLiftSelection(newLiftSystemList[0]);
    }

    if (lift) {
      const DirectionList = data.LiftSystemAndDirection.filter((element) => element.sku_type === 'L' && element.value_1 === lift).map((element) => element.value_2);

      setbrandLabelProductTypeOpacityFabricLiftDirection(DirectionList.filter(unique));
      if (newLiftSystemList.length > 0) {
        let checkLiftAvailability = newLiftSystemList.includes(lift);
        if (!checkLiftAvailability) {
          setLift('');
          setDirection('');
        }
      }
    }

    if (direction) {
      if (DirectionList.length > 0) {
        let checkDirectionAvailability = DirectionList.includes(direction);
        if (!checkDirectionAvailability) {
          setDirection('');
        }
      }
    }
  };

  useEffect(() => {
    if (SelectedFabricSKU !== undefined && SelectedLiftSytemSKU !== undefined) {
      getOptionsbyLiftSystem();
    }
  }, [SelectedFabricSKU, SelectedLiftSytemSKU]);

  let reqoptionsjson = {};
  let reqoptionsjsonnames = {};
  let reqoptionsjsons = {};
  let notreqoptionsjson = {};
  let notreqoptionsjsonnames = {};
  let notreqoptionsjsons = {};

  const getOptionsbyLiftSystem = async () => {
    const response = await fetch(`${CONFIG.API_URL}/pm/AvailableOptionAndTypeByLiftSystem`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
      body: JSON.stringify({
        sku: [SelectedFabricSKU, SelectedLiftSytemSKU],
      }),
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      return;
    }
    let reqoptionLabel = [];
    let reqoptionList = [];
    let reqoptionListDetails = [];
    let notreqoptionLabel = [];
    let notreqoptionList = [];
    let notreqoptionListDetails = [];
    data.AvailableOptionAndType.forEach((element) => {
      if (element.sku_type === 'P' && element.pricing_status === true) {
        reqoptionLabel.push(element.type);
        reqoptionList.push(element.value_1);
        reqoptionListDetails.push(element);
      }
      if (element.sku_type === 'P' && element.pricing_status === false) {
        notreqoptionLabel.push(element.type);
        notreqoptionList.push(element.value_1);
        notreqoptionListDetails.push(element);
      }
    });

    let reqoptionLabellist = reqoptionLabel.filter(unique);
    let notreqoptionLabellist = notreqoptionLabel.filter(unique);
    // Function to check if the array contains  0
    function findIndexOfZero(jsonArray) {
      for (let i = 0; i < jsonArray.length; i++) {
        if (jsonArray[i].sortorder === 0) {
          return i;
        }
      }
      return -1;
    }
    if (reqoptionLabellist.length > 0) {
      reqoptionLabellist.forEach((ol) => {
        let reqoptionvalues = [];
        data.AvailableOptionAndType.forEach((element) => {
          if (element.sku_type === 'P' && element.pricing_status === true) {
            if (element.type === ol) {
              let optionskudetails = {
                key: element.type,
                name: element.sku_name,
                sku: element.sku,
                pricing_status: element.pricing_status,
                display_sort_order: element.display_sort_order,
              };

              reqoptionvalues.push(optionskudetails);
            }
            let reqoptionskujson = {
              key: '',
              name: ' ',
              sku: '',
              pricing_status: '',
            };

            reqoptionsjson[ol] = reqoptionvalues;
            reqoptionsjsonnames[ol] = reqoptionskujson;
          }
        });
      });

      Object.keys(reqoptionsjsonnames).forEach((opt) => {
        let displaySortOrder = [];
        Object.values(reqoptionsjson).forEach((ele) => {
          ele.forEach((element) => {
            let optstatus = false;

            if (element.key === opt) {
              displaySortOrder.push({
                sortorder: element.display_sort_order,
                key: element.key,
                name: element.name,
                sku: element.sku,
                pricing_status: element.pricing_status,
              });
            }
          });
        });

        const hasZeroValueIndex = findIndexOfZero(displaySortOrder);

        if (hasZeroValueIndex !== -1) {
        }
      });
      setAllReqOptionsKey({ ...reqoptionsjsonnames });
      setReqOptionDetails(reqoptionsjson);
    }
    if (notreqoptionLabellist.length > 0) {
      if (notreqoptionLabellist.length > 0) {
        notreqoptionLabellist.forEach((ol) => {
          let notreqoptionvalues = [];
          data.AvailableOptionAndType.forEach((element) => {
            if (element.sku_type === 'P' && element.pricing_status === false) {
              if (element.type === ol) {
                let optionskudetails = {
                  key: element.type,
                  name: element.sku_name,
                  sku: element.sku,
                  pricing_status: element.pricing_status,
                  display_sort_order: element.display_sort_order,
                };

                notreqoptionvalues.push(optionskudetails);
              }
              let notreqoptionskujson = {
                key: '',
                name: ' ',
                sku: '',
                pricing_status: '',
              };

              notreqoptionsjson[ol] = notreqoptionvalues;
              notreqoptionsjsonnames[ol] = notreqoptionskujson;
            }
          });
        });
        Object.keys(notreqoptionsjsonnames).forEach((opt) => {
          let displaySortOrder = [];
          Object.values(notreqoptionsjson).forEach((ele) => {
            ele.forEach((element) => {
              let optstatus = false;
              if (element.key === opt) {
                displaySortOrder.push({
                  sortorder: element.display_sort_order,
                  key: element.key,
                  name: element.name,
                  sku: element.sku,
                  pricing_status: element.pricing_status,
                });
              }
            });
          });
          const hasZeroValueIndex = findIndexOfZero(displaySortOrder);
          if (hasZeroValueIndex !== -1) {
            setSelectedOptionJSON((prevObject) => ({
              ...prevObject,
              [displaySortOrder[hasZeroValueIndex].key]: {
                key: displaySortOrder[hasZeroValueIndex].key,
                name: displaySortOrder[hasZeroValueIndex].name,
                sku: displaySortOrder[hasZeroValueIndex].sku,
                pricing_status: displaySortOrder[hasZeroValueIndex].pricing_status,
              },
            }));
          }
        });
        setAllNotReqOptionsKey({ ...notreqoptionsjsonnames });
      }
    }
    if (data.selectedOptionJSON && Object.keys(data.selectedOptionJSON).length === 0) {
    } else {
      for (const targetkey in reqoptionsjsonnames) {
        if (reqoptionsjsonnames.hasOwnProperty(targetkey)) {
          for (const sourcekey in selectedOptionJSON) {
            if (selectedOptionJSON.hasOwnProperty(sourcekey)) {
              if (targetkey === sourcekey) {
                reqoptionsjsonnames[targetkey] = selectedOptionJSON[sourcekey];
              }
            }
          }
        }
      }

      for (const targetkey in notreqoptionsjsonnames) {
        if (notreqoptionsjsonnames.hasOwnProperty(targetkey)) {
          for (const sourcekey in selectedOptionJSON) {
            if (selectedOptionJSON.hasOwnProperty(sourcekey)) {
              if (targetkey === sourcekey) {
                notreqoptionsjsonnames[targetkey] = selectedOptionJSON[sourcekey];
              }
            }
          }
        }
      }
    }

    setNotReqOptionDetails(notreqoptionsjson);
    setOptionLabel(reqoptionLabel.filter(unique));
    setOptionName(reqoptionList.filter(unique));

    setSelectedOptionDetails(reqoptionListDetails.filter(unique));
  };

  useEffect(() => {
    let CheckReqSelectedOptionJSON = [];
    for (const key in ReqOptionDetails) {
      if (selectedOptionJSON.hasOwnProperty(key)) {
        CheckReqSelectedOptionJSON.push(selectedOptionJSON[key].sku);
      }
    }

    const countTrue = CheckReqSelectedOptionJSON.reduce((count, currentValue) => (currentValue ? count + 1 : count), 0);

    setAllOptionSelectedStatus((Object.keys(ReqOptionDetails).length && Object.keys(ReqOptionDetails).length === countTrue) || Object.keys(ReqOptionDetails).length === 0);
  }, [ReqOptionDetails, selectedOptionJSON]);

  useEffect(() => {
    const CheckNotReqSelectedOptionJSON = [];

    for (const key in NotReqOptionDetails) {
      if (optionalFinalizationFields.hasOwnProperty(key)) {
        CheckNotReqSelectedOptionJSON.push(optionalFinalizationFields[key].sku);
      }
    }

    const countTrue = CheckNotReqSelectedOptionJSON.reduce((count, currentValue) => (currentValue ? count + 1 : count), 0);
    setAllOptionNPSSelectedStatus(Object.keys(NotReqOptionDetails).length > 0 && Object.keys(NotReqOptionDetails).length === countTrue);
  }, [NotReqOptionDetails, optionalFinalizationFields]);

  useEffect(() => {
    if (option.length > 0) {
      Object.entries(data.selectedOptionJSON).forEach(([key, value]) => {
        Object.keys(allReqOptionskey).forEach((opt) => {
          reqoptionsjsons[opt] = {
            key: '',
            name: ' ',
            sku: '',
            pricing_status: '',
          };
        });
        Object.keys(allNotReqOptionskey).forEach((opt) => {
          notreqoptionsjsons[opt] = {
            key: '',
            name: ' ',
            sku: '',
            pricing_status: '',
          };
        });
      });
    }
  }, [option, allReqOptionskey, allNotReqOptionskey]);

  const filteredResult = () => {
    if (brand !== 'undefined' && product !== 'undefined' && label !== 'undefined' && type !== 'undefined') {
    }
  };

  let FilteredCovering_Category = [];
  let FilteredBrand = [];
  let FilteredLabel = [];
  let FilteredProduct = [];
  let FilteredType = [];
  let FilteredOpacity = [];
  let FilteredFabric = [];

  useEffect(() => {
    let keywordsArr = [];
    if (CoveringDetails !== undefined && CoveringDetails.length > 0) {
      CoveringDetails.forEach((element) => {
        if (element.keywords) {
          keywordsArr.push(...element.keywords);
        }
      });

      setAllKeywords(keywordsArr.filter(unique));
      if (CoveringSearchResults.length > 0) {
        CoveringSearchResults.forEach((element, i) => {
          FilteredCovering_Category.push(element.covering_category);
          FilteredBrand.push(element.brand);
          FilteredLabel.push(element.label);
          FilteredProduct.push(element.product);
          FilteredType.push(element.type);
          FilteredOpacity.push(element.opacity);
          FilteredFabric.push(element.material);
        });
        FilteredCovering_Category = FilteredCovering_Category.filter(unique);
        FilteredBrand = FilteredBrand.filter(unique);
        FilteredProduct = FilteredProduct.filter(unique);
        FilteredLabel = FilteredLabel.filter(unique);
        FilteredType = FilteredType.filter(unique);
        FilteredOpacity = FilteredOpacity.filter(unique);
        FilteredFabric = FilteredFabric.filter(unique);

        if (FilteredCovering_Category.length === 1) {
          if (measurementType === 'Quick') {
          } else {
            if (measurementType !== '' && measurementType === FilteredCovering_Category[0]) {
            } else if (measurementType !== '' && measurementType !== FilteredCovering_Category[0]) {
            }
          }

          setWCC(FilteredCovering_Category[0]);
          setCategoryNames([FilteredCovering_Category[0]]);
        }

        if (FilteredBrand.length === 1) {
          setBrand(FilteredBrand[0]);
          setBrands([FilteredBrand[0]]);
        }
        if (FilteredBrand.length > 1) {
          setBrands(FilteredBrand);
        }

        if (FilteredCovering_Category.length > 0) {
          setCategoryNames(FilteredCovering_Category);
        }

        if (FilteredProduct.length === 1) {
          setProduct(FilteredProduct[0]);
          setbrandLabelProduct([FilteredProduct[0]]);
        }
        if (FilteredProduct.length > 1) {
          setbrandLabelProduct(FilteredProduct);
        }
        if (FilteredLabel.length === 1) {
          setLabel(FilteredLabel[0]);
          setBrandLabel([FilteredLabel[0]]);
        }
        if (FilteredLabel.length > 1) {
          setBrandLabel(FilteredLabel);
        }
        if (FilteredType.length === 1) {
          setType(FilteredType[0]);
          setbrandLabelProductType([FilteredType[0]]);
        }
        if (FilteredType.length > 1) {
          setbrandLabelProductType(FilteredType);
        }
        if (FilteredOpacity.length === 1) {
          setOpacity(FilteredOpacity[0]);
          setbrandLabelProductTypeOpacity([FilteredOpacity[0]]);
        }
        if (FilteredOpacity.length > 1) {
          setbrandLabelProductTypeOpacity(FilteredOpacity);
        }
        if (FilteredFabric.length === 1) {
          setFabric(FilteredFabric[0]);
          setbrandLabelProductTypeOpacityFabric(FilteredFabric);
        }
        if (FilteredFabric.length > 1) {
          setSelectedFabricSKU('');
          setShowSKUCombination(false);
          setShowProductDescription(false);
          setbrandLabelProductTypeOpacityFabric(FilteredFabric);
        }
      }
    }
    if (CoveringSearchResults.length === 0) {
      if (CoveringDetails !== undefined && CoveringDetails.length > 0) {
        setChooseFilters(CoveringDetails);

        const covering_category = [];
        const Brand = [];
        const Label = [];
        const Product = [];
        const Type = [];
        const Opacity = [];
        const Fabric = [];
        const LiftSystems = [];
        const Directions = [];
        const LiftSystemDetails = [];
        const OptionsAvailable = [];
        const OptionsAvailableDetails = [];
        CoveringDetails.forEach((element, i) => {
          covering_category.push(element.covering_category);
        });
        CoveringDetails.forEach((element, i) => {
          Brand.push(element.brand);
        });
        CoveringDetails.forEach((element, i) => {
          Label.push(element.label);
        });
        CoveringDetails.forEach((element, i) => {
          Product.push(element.product);
        });
        CoveringDetails.forEach((element, i) => {
          if (element.sku_type === 'M') {
            Type.push(element.type);
          }
        });
        CoveringDetails.forEach((element, i) => {
          if (element.sku_type === 'M') {
            Opacity.push(element.opacity);
          }
        });
        CoveringDetails.forEach((element, i) => {
          if (element.sku_type === 'M') {
            Fabric.push(element.material);
          }
        });

        setCategoryNames(covering_category.filter(unique));
        setLabelNames(Label.filter(unique));
        setBrandLabel(Label.filter(unique));
        setBrands(Brand.filter(unique));

        setbrandLabelProduct(Product.filter(unique));
        setbrandLabelProductType(Type.filter(unique));

        setbrandLabelProductTypeOpacityFabric(Fabric.filter(unique));
        setOptionName(OptionsAvailable.filter(unique));
        setSelectedOptionDetails(OptionsAvailableDetails.filter(unique));
        if (brand && product && label && type) {
        }
      }
    }
  }, [CoveringSearchResults, CoveringDetails]);

  useEffect(() => {
    setshowThirdSection(brand && product && label && type);
  }, [brand, product, label, type]);

  useEffect(() => {
    if (brand && product && label && type && opacity && fabric && lift) {
      filterLift(lift);
    }
  }, [lift]);

  useEffect(() => {
    if (brand && product && label && type && opacity && fabric && lift && direction) {
      filterLift(lift);
    }
  }, [direction]);

  const getMaterialColorbySKU = async () => {
    const response = await fetch(`${CONFIG.API_URL}/pm/MaterialColorsBySKUs`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',

      body: JSON.stringify({
        sku: SKUCombinations.toString(),
      }),
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();

    if (!response.ok || data.error) {
      return;
    }

    const materialColors = data.MaterialColors.map((color, index) => ({
      sortorder: index,
      key: 'Material Color',
      name: color.color_name,
      sku: color.material_sku,
      pricing_status: false,
    }));

    setNotReqOptionDetails((previousObject) => ({
      'Material Color': materialColors,
      ...previousObject,
    }));
  };

  let arr = [];
  let searchkeywords = [];

  useEffect(() => {
    arr = [coveringcategory, brand, label, product, type, opacity, fabric];

    arr.forEach((element) => {
      if (element !== '') {
        searchkeywords.push(element);
      }
    });
    if (searchkeywords.length === 0) {
      setDisableButton(true);

      setsavedProductDescriptions('');
      setsavedSKUCombinations('');
    } else {
      setKeywordsbyFilter(searchkeywords);
    }
    if (checkedItems !== undefined && checkedItems.length > 0) {
      let newoptions = checkedItems.filter(unique);
      setOption([...newoptions]);
    }
  }, [coveringcategory, brand, label, product, type, opacity, fabric, checkedItems]);

  const handleWindowCoveringCategorySelection = (value) => {
    let checkstatus;
    if (measurementTypeArray.length > 0) {
      setWCC(value);
      function removeValueFromArray(arr, value) {
        return arr.filter((item) => item !== value);
      }
      function findCommonValues(arr) {
        const seenValues = new Set();
        const commonValues = [];

        for (let i = 0; i < arr.length; i++) {
          const currentValue = arr[i];
          if (seenValues.has(currentValue)) {
            if (!commonValues.includes(currentValue)) {
              commonValues.push(currentValue);
            }
          } else {
            seenValues.add(currentValue);
          }
        }

        return commonValues;
      }
      if (measurementTypeArray.includes('Quick')) {
        const valueToRemove = 'Quick';
        const newArray = removeValueFromArray(measurementTypeArray, valueToRemove);
      } else {
      }
    } else {
      if (brand) {
        if (value === ' ') {
          checkstatus = true;
        } else {
          checkstatus = false;
        }
        if (!checkstatus) {
          setWCC(value);
        } else {
          setWCC(' ');
          setBrand('');
          setLabel('');
          setProduct('');
          setType('');
          setFabric('');
          setOpacity('');
          setLift('');
          setDirection('');
          setOption([]);
          setSelectedOptionJSON({});
          setSelectedSKU('');
          setProductDescriptions([]);
          setSKUCombinations([]);
          setOptionalFinalizationFields({});
          setSelectedFabricSKU('');
          setSelectedLiftSytemSKU('');
          setOptionSkus([]);
          setCheckedItems();

          const index = searchArr.indexOf(coveringcategory);
          const newsearchArr = searchArr.splice(index, 1);
          setSearchArr([...searchArr]);
        }
      } else {
        if (value !== ' ') {
          setWCC(value);

          const index = searchArr.indexOf(coveringcategory);
          const newsearchArr = searchArr.splice(index, 1);
          setSearchArr([...searchArr]);
        } else {
          setWCC(' ');
          setBrand('');
          setLabel('');
          setProduct('');
          setType('');
          setFabric('');
          setOpacity('');
          setLift('');
          setDirection('');
          setOption([]);
          setSelectedOptionJSON({});
          setProductDescriptions([]);
          setSKUCombinations([]);
          setOptionalFinalizationFields({});
          setSelectedFabricSKU('');
          setSelectedLiftSytemSKU('');
          setOptionSkus([]);
          setCheckedItems();
        }
      }
    }
  };

  // rectify unique brand
  const handleBrandSelection = (selectedbrand, e) => {
    if (selectedbrand !== ' ') {
      setBrand(selectedbrand);
    } else {
      const index = searchArr.indexOf(selectedbrand);
      const newsearchArr = searchArr.splice(index, 1);
      setSearchArr([...searchArr]);

      setBrand('');
      setWCC('');
      setLabel('');
      setProduct('');
      setType('');
      setOpacity('');
      setFabric('');
      setOption([]);
      setSelectedFabricSKU('');
      setsavedSKUCombinations('');
      setShowProductDescription(false);
      setShowSKUCombination(false);

      let filteredLabel = [];
      chooseFilters.forEach((element, i) => {
        if (element.covering_category === coveringcategory && element.brand === e) {
          filteredLabel.push(element.label);
        }
      });
      filteredLabel = filteredLabel.filter(unique);

      setBrandLabel(filteredLabel);
    }
  };

  // rectify unique label
  const handleLabelSelection = (e) => {
    if (e.target.value !== label && e.target.value !== ' ') {
      setLabel(e.target.value);
    } else {
      setLabel('');

      const index = searchArr.indexOf(label);
      const newsearchArr = searchArr.splice(index, 1);
      setSearchArr([...newsearchArr]);
    }
  };

  // rectify unique product
  const handleProductSelection = (e) => {
    if (e.target.value !== product && e.target.value !== ' ') {
      setProduct(e.target.value);
    } else {
      setProduct('');

      const index = searchArr.indexOf(product);
      const newsearchArr = searchArr.splice(index, 1);
      setSearchArr([...searchArr]);
    }
  };

  // rectify unique type
  const handleTypeSelection = (e) => {
    setshowThirdSection(true);
    if (e.target.value !== type && e.target.value !== ' ') {
      setType(e.target.value);
    } else {
      setType('');

      const index = searchArr.indexOf(type);
      const newsearchArr = searchArr.splice(index, 1);
      setSearchArr([...searchArr]);
    }
  };

  // rectify unique style
  const handleOpacitySelection = (e) => {
    if (e.target.value !== opacity && e.target.value !== ' ') {
      setOpacity(e.target.value);
    } else {
      setOpacity('');

      const index = searchArr.indexOf(opacity);
      const newsearchArr = searchArr.splice(index, 1);
      setSearchArr([...searchArr]);
    }
  };

  // rectify unique type
  const handleFabricSelection = (e) => {
    if (e.target.value !== fabric && e.target.value !== ' ') {
      setFabric(e.target.value);
    } else if (e.target.value !== fabric && e.target.value === ' ') {
      setFabric('');

      const index = searchArr.indexOf(fabric);
      const newsearchArr = searchArr.splice(index, 1);
      setSearchArr([...newsearchArr]);
    }

    if (fabric) {
      if (e.target.value !== fabric && e.target.value !== ' ') {
        setFabric(e.target.value);
        setWCC('');
        setBrand('');
        setProduct('');
        setLabel('');
        setType('');
        setOpacity('');

        if (lift) {
          if (brandLabelProductTypeOpacityFabricLift.length > 0) {
            let checkLiftAvailability = brandLabelProductTypeOpacityFabricLift.includes(lift);
            if (!checkLiftAvailability) {
              setLift('');
              setDirection('');
            }
          }
        }
        if (direction) {
          if (brandLabelProductTypeOpacityFabricLiftDirection.length > 0) {
            let checkDirectionAvailability = brandLabelProductTypeOpacityFabricLiftDirection.includes(direction);

            if (!checkDirectionAvailability) {
              setDirection('');
            }
          }
        }
      } else if (e.target.value !== fabric && e.target.value === ' ') {
        setFabric('');

        const index = searchArr.indexOf(fabric);
        const newsearchArr = searchArr.splice(index, 1);
        setSearchArr([...newsearchArr]);

        setSelectedFabricSKU('');
        setSKUCombinations([]);
        setProductDescriptions([]);
      }
    } else {
    }
  };

  useEffect(() => {
    setHeightForVerticalConfiguration(data.HeightForVerticalConfiguration);
  }, [data.HeightForVerticalConfiguration]);

  useEffect(() => {
    if (lift) {
      filterLift(lift);
    }
    if (direction) {
      filterDirection(direction);
    }
    SelectedLiftSytemDetails.forEach((ele) => {
      if (lift === ele.value_1 && direction === ele.value_2 && ele.display_sort_order === 0) {
        handleDirectionSelection(ele.value_1, ele.value_2);
      }
    });

    let LiftSystems = [];
    let LiftSystemDetails = [];
    let Directions = [];

    if (coveringdetails !== undefined && coveringdetails.length > 0) {
      coveringdetails.forEach((element, i) => {
        if (element.sku_type === 'L') {
          LiftSystemDetails.push(element);
          LiftSystems.push(element.value_1);
          Directions.push(element.value_2);
        }
      });

      if (lift === '' && direction === '' && SelectedFabricSKU === '') {
        LiftSystems = LiftSystems.filter(unique);
        Directions = Directions.filter(unique);
      }
      if (lift === '' && direction === '' && SelectedFabricSKU !== '') {
        LiftSystems = LiftSystems.filter(unique);
        Directions = Directions.filter(unique);
      }
      if (lift !== '' && direction === '' && SelectedFabricSKU !== '') {
        Directions = Directions.filter(unique);
        setSelectedLiftSytemSKU('');
      }
    }
  }, [lift, direction, SelectedFabricSKU]);

  const filterLift = (lift) => {
    const filteredDirection = SelectedLiftSytemDetails.filter((element) => element.sku_type === 'L' && element.value_1 === lift)
      .filter(unique)
      .map((element) => element.value_2);

    setbrandLabelProductTypeOpacityFabricLiftDirection(filteredDirection);
    if (filteredDirection.length === 1) {
      handleDirectionSelection(lift, filteredDirection[0]);
    }
  };

  // rectify unique type
  const handleLiftSelection = (e) => {
    if (e !== '') {
      if (e === ' ') {
        setDirection('');
        setLift('');
        setSelectedLiftSytemSKU('');
        if (showInTable.length > 0) {
          let newDescription = showInTable[0].description;
          setProductDescriptions([newDescription]);
          setSKUCombinations([showInTable[0].sku]);
        }
      } else {
        setLift(e);
        if (showInTable.length > 0) {
          let newDescription = [showInTable[0].description];
          setsavedProductDescriptions(newDescription);
        }
      }

      setsavedLift(lift);
    } else {
      if (showInTable.length > 0) {
        let newDescription = showInTable[0].description;
        setProductDescriptions([newDescription]);
      }
    }
  };

  const filterDirection = (direction) => {
    let filteredLift = [];

    SelectedLiftSytemDetails.forEach((element, i) => {
      if (element.sku_type === 'L' && element.value_2 === direction) {
        filteredLift.push(element.value_1);
      }
    });
    filteredLift = filteredLift.filter(unique);
  };

  // rectify unique type
  const handleDirectionSelection = (lift, e) => {
    if (e !== '') {
      if (e === ' ') {
        setDirection('');
        setLift(lift);

        setSelectedLiftSytemSKU('');
        if (showInTable.length === 0) {
        } else {
          let newDescription = [showInTable[0].description, lift];
          setProductDescriptions([newDescription]);
          setsavedProductDescriptions(newDescription);
          setSKUCombinations([showInTable[0].sku]);
        }
      } else {
        setLift(lift);
        setDirection(e);
        if (showInTable.length === 0) {
        } else {
          let newDescription = [showInTable[0].description, lift, e];
          setProductDescriptions(newDescription);
          setSKUCombinations([showInTable[0].sku]);
          setsavedProductDescriptions(newDescription);

          let SelectedLiftSystem = '';
          let materialSKU = '';
          let appendedsku = [];
          SelectedLiftSytemDetails.forEach((element) => {
            if (element.value_2 === e && element.value_1 === lift) {
              setSelectedLiftSytemSKU(element.sku);
              SelectedLiftSystem = element;

              if (SelectedFabricSKU === '') {
                newDescription = element.description;
                setsavedSKUCombinations(appendedsku);
                setProductDescriptions(newDescription);
                setsavedProductDescriptions(newDescription);
                if (SelectedLiftSystem) {
                  setWCC(SelectedLiftSystem.covering_category);
                  setBrand(SelectedLiftSystem.brand);
                  setProduct(SelectedLiftSystem.product);
                  setLabel(SelectedLiftSystem.label);
                  appendedsku = [element.sku];
                }
              } else {
                materialSKU = SelectedFabricSKU;
                setsavedSKUCombinations(appendedsku);
                setProductDescriptions(newDescription);
                setsavedProductDescriptions(newDescription);

                appendedsku = [materialSKU, element.sku];
              }
              setSKUCombinations(appendedsku);
            }
          });
        }
      }
      let newDescription = '';

      setsavedDirection(direction);
      setShowProductDescription(true);
      setShowSKUCombination(true);
    } else {
      let appendedsku = [];
      let materialSKU = '';

      if (showInTable.length === 1) {
        let newDescription = [showInTable[0].description, lift];
        setProductDescriptions(newDescription);

        SelectedLiftSytemDetails.forEach((element) => {
          if (e.target.value === ' ' && element.value_1 === lift) {
            if (SelectedFabricSKU === undefined) {
              setSelectedFabricSKU(showInTable[0].sku);
              materialSKU = showInTable[0].sku;
            } else {
              materialSKU = SelectedFabricSKU;
            }
            appendedsku = [materialSKU, e.target.value];
          }
        });
        setSKUCombinations(appendedsku);
        setsavedSKUCombinations(appendedsku);
      }
      setsavedSKUCombinations('');
      setProductDescriptions([]);
      setsavedProductDescriptions('');
    }
  };

  useEffect(() => {
    func2(
      drawerstatus,
      coveringcategory,
      brand,
      label,
      product,
      type,
      opacity,
      fabric,
      lift,
      direction,
      option,
      showTable,
      savedProductDescription,
      ProductDescription,
      SKUCombinations,
      SelectedFabricSKU,
      SelectedLiftSytemSKU,
      SelectedOptionSKU,
      showInTable,
      clearfilters,
      showProductDescription,
      showSKUCombination,
      windowData,
      measurementType,
      outgoingwindow_width,
      outgoingwindow_height,
      existingCovering,
      mountsurface,
      buildType,
      ladder,
      optionalFinalizationFields,
      selectedOptionJSON,
      checkedItems,
      saveStatus,
      size,
      psf,
      additional_sku,
      AllOptionSelectedStatus,
      AllOptionNPSSelectedStatus,
      quickapply,
      windowdepth,
      othermeasurement,
      obstructions,
      description,
      VerticalConfiguration,
      SectionOneTopShow,
      SectionTwoBottomShow,
      headrail,
      section1,
      section2,
      section3,
      section4,
      section5,
      section6
    );
    getMaterialColorbySKU();
    clearfilters && setClearFilters(false);
  }, [
    coveringcategory,
    brand,
    label,
    product,
    type,
    opacity,
    fabric,
    lift,
    direction,
    option,
    showTable,
    savedProductDescription,
    ProductDescription,
    SKUCombinations,
    SelectedFabricSKU,
    SelectedLiftSytemSKU,
    SelectedOptionSKU,
    showInTable,
    clearfilters,
    showProductDescription,
    showSKUCombination,
    windowData,
    optionalFinalizationFields,
    selectedOptionJSON,
    checkedItems,
    outgoingwindow_width,
    outgoingwindow_height,
    psf,
    AllOptionSelectedStatus,
    AllOptionNPSSelectedStatus,
    quickapply,
    section1,
    section2,
    section3,
    section4,
    section5,
    section6,
  ]);

  useEffect(() => {
    if (data.quickapply) {
      setQuickApply(false);
      setWCC(data.coveringcategory);
      setBrand(data.brand);
      setLabel(data.label);
      setProduct(data.product);
      setType(data.type);
      setOpacity(data.opacity);
      setFabric(data.fabric);
      setLift(data.lift);
      setSelectedFabricSKU(data.SelectedFabricSKU);
      setDirection(data.direction);
      setSelectedLiftSytemSKU(data.SelectedLiftSytemSKU);
      setSelectedOptionJSON({
        ...data.selectedOptionJSON,
        ...data.OptionalFinalizationFields,
      });
      setOptionalFinalizationFields(data.OptionalFinalizationFields);
      setCheckedItems(data.checkedItems);
    }
  }, [data.quickapply]);

  useEffect(() => {
    if (data.windowData.length === 1 && data.windowData[0].window_id) {
      setMeasurementType(data.windowData[0].proposal_measurement_type);
      setWCC(data.wcc);
      setBrand(data.brand);
      setLabel(data.label);
      setProduct(data.product);
      setType(data.type);
      setOpacity(data.opacity);
      setFabric(data.fabric);
      setSelectedFabricSKU(data.SelectedFabricSKU);
      setLift(data.lift);
      setDirection(data.direction);
      setSelectedLiftSytemSKU(data.SelectedLiftSytemSKU);
      setSelectedOptionJSON({
        ...data.selectedOptionJSON,
        ...data.OptionalFinalizationFields,
      });
      setSelectedOptionSKU([...data.SelectedOptionSKU]);
      setOptionalFinalizationFields(data.OptionalFinalizationFields);
      setSKUCombinations([...data.SKUCombinations]);
      setProductDescriptions([...data.ProductDescription]);
      setSizeWindecimal(data.windowData[0].proposal_window_width);

      data.windowData[0].proposal_additional_sku.forEach((ele) => {
        if (ele.type === 'Existing Covering') {
          setExistingCovering(ele.sku_name);
        }
        if (ele.type === 'Build Type') {
          setBuildType(ele.sku_name);
        }
        if (ele.type === 'Mount Surface') {
          setMountsurface(ele.sku_name);
        }
        if (ele.type === 'Ladder') {
          setLadder(ele.sku_name);
        }
        if (ele.type === 'Window Depth') {
          setWindowDepth(ele.sku_name);
          if (ele.sku_name === 'Other') {
            setOtherMeasurement(ele.additional_detail + '"');
          } else {
            setOtherMeasurement('');
          }
        }
        if (ele.type === 'Obstructions') {
          setObstructions(ele.sku_name);

          if (ele.sku_name === 'Yes') {
            setDescription(ele.additional_detail);
          } else {
            setDescription('');
          }
        }
      });
    }
  }, [data.windowData[0]]);

  useEffect(() => {
    if (data.windowData[0].proposal_window_width !== undefined && data.windowData[0].proposal_window_height !== undefined) {
      setSize(fracty(data.windowData[0].proposal_window_width) + '"' + ' x ' + fracty(data.windowData[0].proposal_window_height) + '"');
    } else if (data.outgoingwindow_width === '' && data.outgoingwindow_height !== '') {
      setSize(fracty(data.windowData[0].proposal_window_width) + '"' + ' x ' + fracty(data.outgoingwindow_height) + '"');
    } else if (data.outgoingwindow_height !== '' && data.outgoingwindow_height === '') {
      setSize(fracty(data.outgoingwindow_width) + '"' + ' x ' + fracty(data.windowData[0].proposal_window_height) + '"');
    } else {
      setSize(fracty(data.outgoingwindow_width) + '"' + ' x ' + fracty(data.outgoingwindow_height) + '"');
    }
  }, [data.windowData[0].proposal_window_width, data.windowData[0].proposal_window_height]);

  useEffect(() => {
    let selectedoptionSKUs = Object.values(selectedOptionJSON);
    setSelectedOptionsKey(Object.keys(selectedOptionJSON));
    setSelectedOptions(selectedoptionSKUs);
    if (selectedoptionSKUs.length > 0) {
      let SKUs = [];
      let SKUName = [];
      selectedoptionSKUs.forEach((sku) => {
        if (sku.sku !== '') {
          SKUs.push(sku.sku);
          SKUName.push(sku.name);
        }
      });
      setSelectedOptionSKU([...SKUs]);
      setCheckedItems(SKUName);
    } else {
      setSelectedOptionSKU([]);
      setCheckedItems();
    }
  }, [selectedOptionJSON]);

  const [openMenu, setOpenMenu] = useState(null);

  const handleFormControlClick = (opt) => {
    if (openMenu === opt) {
      setOpenMenu(null); // Close the menu if it's already open
    } else {
      setOpenMenu(opt); // Open the menu for the clicked FormControl
    }
  };

  const handleOption = ({ target }, option, val) => {
    const targetValue = target.value;
    const newJsonObject = {};

    if (targetValue && targetValue.trim()) {
      for (const key in ReqOptionDetails) {
        if (ReqOptionDetails.hasOwnProperty(key)) {
          const value = ReqOptionDetails[key];
          if (key === option) {
            value.forEach((jsonval) => {
              if (jsonval.name === targetValue) {
                setSelectedOptionJSON((prevObject) => ({
                  ...prevObject,
                  [jsonval.key]: {
                    key: jsonval.key,
                    name: jsonval.name,
                    sku: jsonval.sku,
                    pricing_status: jsonval.pricing_status,
                  },
                }));
              }
            });
          }
        }
      }
      for (const key in NotReqOptionDetails) {
        if (NotReqOptionDetails.hasOwnProperty(key)) {
          const value = NotReqOptionDetails[key];
          if (key === option) {
            value.forEach((jsonval) => {
              if (jsonval.name === targetValue) {
                setSelectedOptionJSON((prevObject) => ({
                  ...prevObject,
                  [jsonval.key]: {
                    key: jsonval.key,
                    name: jsonval.name,
                    sku: jsonval.sku,
                    pricing_status: jsonval.pricing_status,
                  },
                }));
                setOptionalFinalizationFields((prevObject) => ({
                  ...prevObject,
                  [jsonval.key]: {
                    key: jsonval.key,
                    name: jsonval.name,
                    sku: jsonval.sku,
                    pricing_status: jsonval.pricing_status,
                  },
                }));
              }
            });
          }
        }
      }

      setSelectedOption([...SelectedOption, newJsonObject]);
    } else if (targetValue === ' ') {
      setSelectedOptionSKU([]);
      setOption([]);
      setCheckedItems();
      for (const key in ReqOptionDetails) {
        if (ReqOptionDetails.hasOwnProperty(key)) {
          const value = ReqOptionDetails[key];

          if (key === option) {
            value.forEach((jsonval) => {
              if (jsonval.name !== targetValue) {
                setSelectedOptionJSON((prevObject) => ({
                  ...prevObject,
                  [jsonval.key]: {
                    key: '',
                    name: ' ',
                    sku: '',
                    pricing_status: '',
                  },
                }));
              }
            });
          }
        } else {
          setSelectedOption([]);
        }
      }
      for (const key in NotReqOptionDetails) {
        if (NotReqOptionDetails.hasOwnProperty(key)) {
          const value = NotReqOptionDetails[key];
          if (key === option) {
            value.forEach((jsonval) => {
              if (jsonval.name !== targetValue) {
                setSelectedOptionJSON((prevObject) => ({
                  ...prevObject,
                  [jsonval.key]: {
                    key: '',
                    name: ' ',
                    sku: '',
                    pricing_status: '',
                  },
                }));
                setOptionalFinalizationFields((prevObject) => ({
                  ...prevObject,
                  [jsonval.key]: {
                    key: '',
                    name: ' ',
                    sku: '',
                    pricing_status: '',
                  },
                }));
              }
            });
          }
        } else {
          setSelectedOption([]);
        }
      }
    }
  };

  let searchedArr = [];
  let checkSubset = (parentArray, subsetArray) => {
    return subsetArray.every((el) => {
      return parentArray.includes(el);
    });
  };

  const handleSearch = (searchedItems) => {
    setSearchArr(searchedItems);
    setCoveringDetails([...CoveringDetails, ...coveringdetails]);
    searchedArr = coveringdetail;

    let materialfinalarr = [];
    let materialarr = [];
    if (searchedArr !== undefined) {
      searchedArr.forEach((element, index) => {
        if (element.sku_type === 'M') {
          materialarr.push(element);
        }

        let status = checkSubset(searchedArr[index].keywords, searchedItems);

        if (status === true && searchedArr[index].sku_type === 'M') {
          materialfinalarr.push(searchedArr[index]);
        }
      });
    }

    materialfinalarr = materialfinalarr.filter(unique);
    setCoveringSearchResults(materialfinalarr);

    setShowInTable(materialfinalarr);

    let mskus = [];
    if (materialfinalarr.length === 1) {
      setProductDescriptions([materialfinalarr[0].description]);
      setSKUCombinations([materialfinalarr[0].sku]);
      mskus = [materialfinalarr[0].sku];

      if (materialfinalarr.length === 1 && lift && !direction) {
        let liftSystemPD = [lift];
        setProductDescriptions([materialfinalarr[0].description, ...liftSystemPD]);
        setSKUCombinations([materialfinalarr[0].sku]);
        mskus = [materialfinalarr[0].sku];
        setShowProductDescription(true);
      }
      if (materialfinalarr.length === 1 && lift && direction) {
        let liftSystemPD = [lift, direction];

        setProductDescriptions([materialfinalarr[0].description, ...liftSystemPD]);
        setSKUCombinations([materialfinalarr[0].sku, SelectedLiftSytemSKU]);
        setShowProductDescription(true);
      }
      if (materialfinalarr.length === 1 && lift && direction && option.length === 0) {
        let liftSystemPD = [lift, direction];

        setProductDescriptions([materialfinalarr[0].description, ...liftSystemPD]);
        setSKUCombinations([materialfinalarr[0].sku, SelectedLiftSytemSKU]);
        setShowProductDescription(true);
      }
      if (materialfinalarr.length === 1 && option.length > 0) {
        let optionPD = [...option];
        setProductDescriptions([materialfinalarr[0].description, ...optionPD]);
        setSKUCombinations([materialfinalarr[0].sku, ...SelectedOptionSKU]);
        setShowProductDescription(true);
      }
      if (materialfinalarr.length === 1 && lift && direction && option.length > 0) {
        let liftSystemPD = [lift, direction];
        let optionPD = [...option];

        setProductDescriptions([materialfinalarr[0].description, ...liftSystemPD, ...optionPD]);
        setSKUCombinations([materialfinalarr[0].sku, SelectedLiftSytemSKU, ...SelectedOptionSKU]);
        setShowProductDescription(true);
      }
    }

    if (materialfinalarr.length === 1) {
      let arrsku = [];
      setShowSKUCombination(true);

      setSelectedFabricSKU(materialfinalarr[0].sku);
      setShowProductDescription(true);

      getLiftSystemDirectionList(materialfinalarr[0].sku);
      if (materialfinalarr[0].sku) {
        setIsEnabled(false);
      } else {
        setIsEnabled(true);
      }
    }
  };

  const reset = () => {
    setClearFilters(true);
    setSearchArr([]);
    setCoveringSearchResults([]);
    setValue([]);
    setKeywordsbyFilter([]);
    setKeywordsbySearch([]);
    setWCC('');
    setDisableButton(true);
    setBrand('');
    setProduct('');
    setLabel('');
    setType('');
    setOpacity('');
    setFabric('');
    setLift('');
    setDirection('');
    setOption([]);
    setDesigner(0);
    setSelectedSKU('');
    setProductDescriptions([]);
    setSKUCombinations([]);
    setsavedProductDescriptions('');
    setOptionalFinalizationFields({});
    setsavedSKUCombinations('');
    setSelectedFabricSKU('');
    setSelectedLiftSytemSKU('');
    setSelectedOptionSKU([]);
    setOptionSkus([]);
    setCheckedItems();
    setSelectedOptionJSON({});
    setReqOptionDetails([]);
    setNotReqOptionDetails([]);
    // setMaterialColor("");
    setHeadrailColor('');
    setCordLocation('');
    setValanceColor('');
    setHingeColor('');
    setHeadrailColor('');
    setCordColor('');
    setPClip('');
    setPaintColor('');
    setTiltConfiguration('');
    setPanelConfiguration('');
    setCordLocation('');
    setIsEnabled(true);
    setLiftSystem([]);
    setBrands([]);
    setBrandLabel([]);
    setbrandLabelProduct([]);
    setbrandLabelProductType([]);
    setbrandLabelProductTypeOpacity([]);
    setbrandLabelProductTypeOpacityFabric([]);
    setbrandLabelProductTypeOpacityFabricLift([]);
    setbrandLabelProductTypeOpacityFabricLiftDirection([]);
    setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrail([]);
    setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValance([]);
    setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeatures([]);
    setbrandLabelProductTypeOpacityFabricLiftDirectionHeadrailValanceFeaturesTrack([]);
    setShowTable(false);
    setShowInTable([]);
  };

  useEffect(() => {
    if (value.length > 0) {
      setKeywordsbySearch(value);
    }
    if (value.length === 0) {
      setWCC('');
      setBrand('');
      setLabel('');
      setProduct('');
      setType('');
      setOpacity('');
      setFabric('');
      setShowSKUCombination(false);
      setSKUCombinations([]);
      setSelectedFabricSKU('');
      setProductDescriptions([]);
      setShowProductDescription(false);
      setIsEnabled(true);
      setOptionSkus([]);
      setCheckedItems();
      setKeywordsbySearch([]);
    }
  }, [value]);
  useEffect(() => {
    var filteredKeywords = [];
    if (keywordsbyFilter.length > 0 && keywordsbySearch.length > 0) {
      let filteredVal = keywordsbyFilter.filter((word) => keywordsbySearch.includes(word));
      if (filteredVal.length > 0) {
        setKeywordsbyFilter([]);
        setKeywordsbySearch([]);
        filteredKeywords = filteredVal;
      } else {
        filteredKeywords = keywordsbySearch;
      }
    } else if (keywordsbyFilter.length === 0 && keywordsbySearch.length > 0) {
      filteredKeywords = keywordsbySearch;
    } else if (keywordsbyFilter.length > 0 && keywordsbySearch.length === 0) {
      filteredKeywords = keywordsbyFilter;
    } else {
    }

    if (filteredKeywords.length > 0) {
      handleSearch(filteredKeywords);
    }
  }, [keywordsbySearch, keywordsbyFilter]);

  const [isImageHovered, setIsImageHovered] = useState(false);
  const hoverReset = () => {
    setIsImageHovered(!isImageHovered);
  };

  let StoreHeightWidth = {
    height: data.windowData[0].proposal_window_height,
    width: data.windowData[0].proposal_window_width,
  };

  const calfetchdata = (data, drawerstatus, width, height, decimalwidth, decimalheight, result, cancelWidth, cancelHeight, CanclePassData, CancelGetDimesion, verifyStatus, BlankData, CloseResult, storeValue, onClickCancel, StoreHeightWidth, ForCheckingValueVC, updatedWidth) => {
    if (BlankData !== 'blank') {
      const newValue222 = data.replace(/"/g, '');

      const newValue3333 = width.replace(/"/g, '');
      const newValue4444 = height.replace(/"/g, '');

      if (decimalwidth) {
        setTotalWidth(decimalwidth);
        setSizeWindecimal(decimalwidth);
        setOutgoingSizeW(decimalwidth);
      } else {
        setSizeWindecimal(newValue3333);
        setOutgoingSizeW(newValue3333);
        setTotalWidth(newValue3333);
      }
      if (decimalheight) {
        setHeightForVerticalConfiguration(decimalheight);
        setSizeHindecimal(decimalheight);
        setOutgoingSizeH(decimalheight);
      } else {
        setSizeHindecimal(newValue4444);
        setHeightForVerticalConfiguration(newValue4444);
        setOutgoingSizeH(newValue4444);
      }
      setSizeH(height);

      setSize(data);
      setSizeW(newValue3333);

      if (ForCheckingValueVC === true) {
        if (VerticalConfiguration === '2 Sections') {
          const distributedValue = parseFloat(window_heightindecimal) / 2;

          setSectionOneTopShow(fracty(distributedValue) + '"');
          setSectionTwoBottomShow(fracty(distributedValue) + '"');
          setSectionOneTopHeigth(distributedValue);
          setSectionTwoBottomWidth(distributedValue);
        }
      }
    } else {
      if (onClickCancel === true) {
        setSize(storeValue);
        setSizeWindecimal(StoreHeightWidth.width);
        setSizeHindecimal(StoreHeightWidth.height);
        setOutgoingSizeW(StoreHeightWidth.width);
        setOutgoingSizeH(StoreHeightWidth.height);
        setHeightForVerticalConfiguration(StoreHeightWidth.height);
        setTotalWidth(parseFloat(StoreHeightWidth.width));
        setSectionOneTopShow(fracty(cancelHeight) + '"');
        setSectionTwoBottomShow(fracty(cancelWidth) + '"');
        setSectionOneTopHeigth(cancelHeight);
        setSectionTwoBottomWidth(cancelWidth);
        setChangedSection1(false);
        setChangedSection2(false);
        setChangedSection3(false);
        setChangedSection4(false);
        setChangedSection5(false);
        setChangedSection6(false);
        setSizeWindecimal(updatedWidth);
        setTotalWidth(updatedWidth);
      } else {
        setSize(CloseResult);
      }
    }
    setIsNoDrawerOpen(drawerstatus);
    if (verifyStatus === 'true') {
      setVerifyStatus('true');
    }

    if (headrail === '1 Section') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');
    }

    if (headrail === '2 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');
      // For 2 Sections, divide the TotalWidth by 2
      let TotalWidth = totalWidth;
      const dividedValue = TotalWidth / 2;
      setSection1(dividedValue);
      setSection2(dividedValue);
    }

    if (headrail === '3 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      if (TotalWidth) {
        const dividedValue = TotalWidth / 3;
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
      } else {
      }
    }

    if (headrail === '4 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 4;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }

    if (headrail === '5 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 5;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
        setSection5(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }

    if (headrail === '6 Sections') {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      let TotalWidth = totalWidth;
      // Check if TotalWidth is a valid number
      if (!isNaN(TotalWidth)) {
        // Calculate the divided value
        const dividedValue = TotalWidth / 6;

        // Set the divided value for each section
        setSection1(dividedValue);
        setSection2(dividedValue);
        setSection3(dividedValue);
        setSection4(dividedValue);
        setSection4(dividedValue);
        setSection5(dividedValue);
        setSection6(dividedValue);
      } else {
        // Handle the case where TotalWidth is not a valid number
        console.error('TotalWidth is not a valid number.');
      }
    }

    if (VerticalConfiguration === '2 Sections') {
      const distributedValue = parseFloat(window_heightindecimal) / 2;

      setSectionOneTopShow(fracty(distributedValue));
      setSectionTwoBottomShow(fracty(distributedValue));
      setSectionOneTopHeigth(distributedValue);
      setSectionTwoBottomWidth(distributedValue);
    }
  };

  const fetchdata3 = (HeigthPrint, drawerstatus, SectionTopHeigth, SectionTopHeigthWithDecimal, VerticalConfigurationSectionTopValueStatus, VCValue, result, ForCancelSectionTop1) => {
    if (VCValue === true) {
      setSectionOneTopShow(fracty(ForCancelSectionTop1) + '"');
      setSectionOneTopHeigth(ForCancelSectionTop1);
      const SectionBottomHeigth = Number(HeightForVerticalConfiguration) - Number(ForCancelSectionTop1);
      setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
      setSectionTwoBottomWidth(SectionBottomHeigth);
    } else {
      if (parseFloat(HeightForVerticalConfiguration) === parseFloat(SectionTopHeigth) || parseFloat(HeightForVerticalConfiguration) === parseFloat(SectionTopHeigthWithDecimal) || parseFloat(HeightForVerticalConfiguration) < parseFloat(SectionTopHeigth)) {
        setVerticalConfigurationAlert(true);
        setSectionTwoBottomShow('');
        setSectionOneTopShow('');
        setSectionOneTopHeigth('');
        setSectionTwoBottomWidth('');
      } else {
        setSectionOneTopShow(HeigthPrint);
        if (VerticalConfigurationSectionTopValueStatus === true) {
          if (HeigthPrint.includes('/')) {
            setSectionOneTopHeigth(SectionTopHeigthWithDecimal);
            const SectionBottomHeigth = Number(HeightForVerticalConfiguration) - Number(SectionTopHeigthWithDecimal);
            setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
            setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
            setSectionTwoBottomWidth(SectionBottomHeigth);
          } else {
            setSectionOneTopHeigth(SectionTopHeigth);
            const SectionBottomHeigth = Number(HeightForVerticalConfiguration) - Number(SectionTopHeigth);
            if (SectionBottomHeigth.toString().includes('.')) {
              setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
            } else {
              setSectionTwoBottomShow(fracty(SectionBottomHeigth) + '"');
            }
            setSectionTwoBottomWidth(SectionBottomHeigth);
          }
        }
      }
    }

    setSectionTopDrawerOpen(drawerstatus);
  };

  const fetchdata2 = (data, drawerstatus, width, decimalwidth, StoreHeightWidth, decimalheight, result, cancelWidth, cancelHeight, CanclePassData, storedSection, onClickSectionCancel, sectionPosition) => {
    if (WidthType === 'LeftWidth') {
    } else if (WidthType === 'Section 1') {
      setSection1(decimalwidth);
      if (!sectionValues.section1.changed) {
        setSection1(decimalwidth);
        sectionValues.section1.value = decimalwidth;

        setChangedSection1(true);
      } else {
      }

      // setLeftShadeisDrawerOpen(drawerstatus);
    } else if (WidthType === 'Section 2') {
      setSection2(decimalwidth);
      if (!sectionValues.section2.changed) {
        setSection2(decimalwidth);
        sectionValues.section2.value = decimalwidth;
        setChangedSection2(true);
      } else {
      }
    } else if (WidthType === 'Section 3') {
      setSection3(decimalwidth);
      if (!sectionValues.section3.changed) {
        setSection3(decimalwidth);
        sectionValues.section3.value = decimalwidth;
        setChangedSection3(true);
      } else {
      }
    } else if (WidthType === 'Section 4') {
      setSection4(decimalwidth);
      if (!sectionValues.section4.changed) {
        setSection4(decimalwidth);
        sectionValues.section4.value = decimalwidth;
        setChangedSection4(true);
      } else {
      }
    } else if (WidthType === 'Section 5') {
      setSection5(decimalwidth);
      if (!sectionValues.section5.changed) {
        setSection5(decimalwidth);
        sectionValues.section5.value = decimalwidth;
        setChangedSection5(true);
      } else {
      }
    } else {
    }

    setSectionTopDrawerOpen(drawerstatus);

    const sections = [section1, section2, section3, section4, section5, section6];

    let totalOfTrueSections = 0; //Sum of the true sections

    // JSON to store the values of sections that are marked as 'true'

    // Loop through sectionValues and determine true sections
    for (let i = 1; i <= 6; i++) {
      const sectionKey = `section${i}`;
      if (sectionValues[sectionKey].changed) {
        totalOfTrueSections += parseFloat(sections[i - 1]);
        trueSections[`section${i}`] = sectionValues[sectionKey].value;
      }
    }

    // Calculate the total value that needs to be distributed among other sections
    const totalToDistribute = totalWidth - totalOfTrueSections;

    let numberOfSections;

    if (headrail === '6 Sections') {
      numberOfSections = 6;
    } else if (headrail === '5 Sections') {
      numberOfSections = 5;
    } else if (headrail === '4 Sections') {
      numberOfSections = 4;
    } else if (headrail === '3 Sections') {
      numberOfSections = 3;
    } else if (headrail === '2 Sections') {
      numberOfSections = 2;
    } else {
    }

    // Calculate the value to distribute to each section
    const remainingSections = numberOfSections - Object.keys(trueSections).length;
    const distributedValue = totalToDistribute / remainingSections;

    for (let i = 1; i <= 6; i++) {
      const sectionKey = `section${i}`;
      if (!sectionValues[sectionKey].changed) {
        if (i > numberOfSections) {
          sectionValues[sectionKey].value = '';
        } else {
          sectionValues[sectionKey].value = distributedValue;
        }
      }
    }

    setLeftShadeisDrawerOpen(drawerstatus);

    if (onClickSectionCancel) {
      if (sectionPosition === 'Section 1') {
        setSection1(storedSection);
      } else if (sectionPosition === 'Section 2') {
        setSection2(storedSection);
      } else if (sectionPosition === 'Section 3') {
        setSection3(storedSection);
      } else if (sectionPosition === 'Section 4') {
        setSection4(storedSection);
      } else if (sectionPosition === 'Section 5') {
        setSection5(storedSection);
      }
    }

    if (headrail === '2 Sections') {
      if (section1 > totalWidth) {
        setwarnQty(true);
        setMsg('Section 1 is greater than ' + fracty(totalWidth));
      } else if (totalWidth === '') {
      } else if (section1 === totalWidth) {
        setwarnQty(true);
        setMsg('Section 1 is equal to ' + fracty(totalWidth));
      } else {
      }
    } else if (headrail === '3 Sections') {
      // Check if section1 and section2 are not blank and are valid numbers
      if (!isNaN(section1) && !isNaN(section2)) {
        const section1Value = parseFloat(section1);
        const section2Value = parseFloat(section2);

        // Check if totalWidth is a valid number
        if (!isNaN(totalWidth)) {
          const sum = section1Value + section2Value;

          if (section1Value === 0 || isNaN(section1Value)) {
          } else if (totalWidth === '') {
          } else if (section1Value >= totalWidth) {
            setSection2('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 1 is greater than or equal to ' + fracty(totalWidth));
          } else if (section2Value >= totalWidth) {
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (sum >= totalWidth) {
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1 and 2 is greater than or equal to ' + fracty(totalWidth));
          } else {
            // Calculate section3 outside of the "else if" block
            const section3Value = totalWidth - sum;
            if (section3Value > 0) {
              setSection3(section3Value);
              // Clear any previous alert message
              setwarnQty(false);
              setMsg('');
            } else {
              setSection3('');
            }
          }
        } else {
          console.error('totalWidth is not a valid number.');
          setSection3('');
        }
      } else {
        // Handle the case where section1 or section2 is not a valid number
        console.error('Sections 1 and 2 must be valid numbers.');
      }

      // Clear section4, section5, and section6
      setSection4('');
      setSection5('');
      setSection6('');
    } else if (headrail === '4 Sections') {
      // Check if section1, section2, and section3 are not blank and are valid numbers
      if (!isNaN(section1) && !isNaN(section2) && !isNaN(section3)) {
        const section1Value = parseFloat(section1);
        const section2Value = parseFloat(section2);
        const section3Value = parseFloat(section3);

        // Check if totalWidth is a valid number
        if (!isNaN(totalWidth)) {
          const sum = section1Value + section2Value + section3Value;

          if (section1Value === 0 || isNaN(section1Value)) {
          } else if (totalWidth === '') {
          } else if (section1Value >= totalWidth) {
            setSection2('');
            setSection3('');
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 1 is greater than or equal to ' + fracty(totalWidth));
          } else if (section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section3Value >= totalWidth) {
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (sum >= totalWidth) {
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2 and 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1 and 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value >= totalWidth) {
            setSection4('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2 and 3 is greater than or equal to ' + fracty(totalWidth));
          } else {
            // Calculate section4 outside of the "else if" block
            const section4Value = totalWidth - sum;
            if (section4Value > 0) {
              setSection4(section4Value);
              // Clear any previous alert message
              setwarnQty(false);
              setMsg('');
            } else {
              setSection4('');
            }
          }
        } else {
          console.error('totalWidth is not a valid number.');
          setSection4('');
        }
      } else {
        // Handle the case where any of section1, section2, or section3 is not a valid number
        console.error('Sections 1, 2 and 3 must be valid numbers.');
      }

      // Clear section5 and section6
      setSection5('');
      setSection6('');
    }
    // Case for 5 Sections
    else if (headrail === '5 Sections') {
      if (!isNaN(section1) && !isNaN(section2) && !isNaN(section3) && !isNaN(section4)) {
        const section1Value = parseFloat(section1);
        const section2Value = parseFloat(section2);
        const section3Value = parseFloat(section3);
        const section4Value = parseFloat(section4);
        // Check if totalWidth is a valid number
        if (!isNaN(totalWidth)) {
          const sum = section1Value + section2Value + section3Value + section4Value; // Include section4Value in the sum

          if (section1Value === 0 || isNaN(section1Value)) {
          } else if (totalWidth === '') {
          } else if (section1Value >= totalWidth) {
            setSection2('');
            setSection3('');
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 1 is greater than or equal to ' + fracty(totalWidth));
          } else if (section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section3Value >= totalWidth) {
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section4Value >= totalWidth) {
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 4 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1 and 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value >= totalWidth) {
            setSection4('');
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2 and 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value + section4Value >= totalWidth) {
            setSection5('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2, 3, 4 and 5 is greater than or equal to ' + fracty(totalWidth));
          } else if (sum >= totalWidth) {
            //   "Sum of section1, 2, 3, 4 and 5 is greater than or equal to totalWidth."
            // );
            setSection5('');
          } else {
            // Calculate section5 outside of the "else if" block
            const section5Value = totalWidth - sum;
            if (section5Value >= 0) {
              setSection5(section5Value);
            } else {
              //   "Sum of section1, 2, 3 and 4 is greater than or equal to totalWidth."
              // );
              setSection5('');
            }
          }
        } else {
          console.error('totalWidth is not a valid number.');
          setSection5('');
        }
      } else {
        // Handle the case where any of section is blank
        console.error('Sections 1, 2, 3 and 4 must not be blank.');
      }
      setSection6('');
    }
    // Case for 6 Sections
    else if (headrail === '6 Sections') {
      if (!isNaN(section1) && !isNaN(section2) && !isNaN(section3) && !isNaN(section4) && !isNaN(section5)) {
        const section1Value = parseFloat(section1);
        const section2Value = parseFloat(section2);
        const section3Value = parseFloat(section3);
        const section4Value = parseFloat(section4);
        const section5Value = parseFloat(section5);
        const totalWidthValue = parseFloat(totalWidth);

        if (!isNaN(totalWidthValue)) {
          const sum = section1Value + section2Value + section3Value + section4Value + section5Value;

          if (section1Value === 0 || isNaN(section1Value)) {
          } else if (totalWidth === '') {
          } else if (section1Value >= totalWidthValue) {
            setSection2('');
            setSection3('');
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 1 is greater than or equal to ' + fracty(totalWidth));
          } else if (section2Value >= totalWidthValue) {
            setSection3('');
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section3Value >= totalWidthValue) {
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section4Value >= totalWidthValue) {
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 4 is greater than or equal to ' + fracty(totalWidth));
          } else if (section5Value >= totalWidthValue) {
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Section 5 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value >= totalWidth) {
            setSection3('');
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1 and 2 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value >= totalWidth) {
            setSection4('');
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2 and 3 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value + section4Value >= totalWidth) {
            setSection5('');
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2, 3, 4 and 5 is greater than or equal to ' + fracty(totalWidth));
          } else if (section1Value + section2Value + section3Value + section4Value + section5Value >= totalWidth) {
            setSection6('');
            // Display an alert message here
            setwarnQty(true);
            setMsg('Sum of Section 1, 2, 3, 4, 5 and 6 is greater than or equal to ' + fracty(totalWidth));
          } else if (sum >= totalWidthValue) {
            //   "Sum of section1, 2, 3, 4 and 5 is greater than or equal to totalWidth."
            // );
            setSection6('');
          } else {
            const section6Value = totalWidthValue - sum;
            if (section6Value >= 0) {
              setSection6(section6Value);
            } else {
              //   "Sum of section1, 2, 3, 4 and 5 is greater than or equal to totalWidth."
              // );
              setSection6('');
            }
          }
        } else {
          console.error('totalWidth is not a valid number.');
          setSection6('');
        }
      } else {
        console.error('Sections 1, 2, 3, 4 and 5 must not be blank, and they must be valid numbers.');
        setSection6('');
      }
    } else {
    }
  };

  useEffect(() => {
    const hasChangedSection = Object.values(sectionValues).some((item) => item.changed);
    if (hasChangedSection) {
      for (let [key, value] of Object.entries(sectionValues)) {
        if (key === 'section1') {
          setSection1(value.value);
        }
        if (key === 'section2') {
          setSection2(value.value);
        }
        if (key === 'section3') {
          setSection3(value.value);
        }
        if (key === 'section4') {
          setSection4(value.value);
        }
        if (key === 'section5') {
          setSection5(value.value);
        }
        if (key === 'section6') {
          setSection6(value.value);
        }
      }
    }
  }, [sectionValues, totalWidth, section1, section2, section3, section4, section5, section6]);

  useEffect(() => {
    // Check and update individually for each section
    if (section1 <= 0 || isNaN(section1)) {
      setSection1('');
    }
    if (section2 <= 0 || isNaN(section2)) {
      setSection2('');
    }
    if (section3 <= 0 || isNaN(section3)) {
      setSection3('');
    }
    if (section4 <= 0 || isNaN(section4)) {
      setSection4('');
    }
    if (section5 <= 0 || isNaN(section5)) {
      setSection5('');
    }
    if (section6 <= 0 || isNaN(section6)) {
      setSection6('');
    }
  }, [section1, section2, section3, section4, section5, section6]);

  useEffect(() => {
    if (warnQty === true) {
      setSection1('');
      setSection2('');
      setSection3('');
      setSection4('');
      setSection5('');
      setSection6('');

      setChangedSection1(false);
      setChangedSection2(false);
      setChangedSection3(false);
      setChangedSection4(false);
      setChangedSection5(false);
      setChangedSection6(false);
    }
  }, [warnQty, sectionValues]);

  return (
    <div style={{ marginBottom: '30px !important' }}>
      <div align='center' style={{ zIndex: 1000000 }}>
        <Typography color='text.secondary'>
          <Drawer
            PaperProps={{
              sx: { width: '90%' },
            }}
            classes={{ paper: classes.paper }}
            BackdropProps={{
              classes: {
                root: classes.backDrop,
              },
            }}
            anchor='right'
            open={isNoDrawerOpen}
            onClose={() => setIsNoDrawerOpen(true)}
            sx={{ zIndex: '100000' }}
          >
            <DrawerInput size={size} Width={window_widthindecimal} StoreHeightWidth={StoreHeightWidth} SectionOneTopHeigth={SectionOneTopHeigth} SectionTwoBottomWidth={SectionTwoBottomWidth} measurement_type={measurementType} func={calfetchdata} AddWindow='AddWindow' />
          </Drawer>

          <Drawer
            classes={{ paper: classes.paper }}
            PaperProps={{
              sx: { width: '90%' },
            }}
            BackdropProps={{
              classes: {
                root: classes.backDrop,
              },
            }}
            anchor='right'
            open={isCommonDrawerOpen}
            onClose={() => setIsCommonDrawerOpen(true)}
            sx={{ zIndex: '1000000' }}
          >
            <CommonDrawerInputWidth othermeasurement={othermeasurement} func={fetchdatafromCommonDrawer} />
          </Drawer>

          <Drawer
            classes={{ paper: classes.paper }}
            PaperProps={{
              sx: { width: '90%' },
            }}
            BackdropProps={{
              classes: {
                root: classes.backDrop,
              },
            }}
            anchor='right'
            open={SectionTopDrawerOpen}
            onClose={() => setSectionTopDrawerOpen(true)}
            sx={{ zIndex: '1000000' }}
          >
            {WidthType === 'LeftWidth' ? (
              <>
                <DrawerInputWidth measurement_type={measurementType} WidthType='LeftWidth' func={fetchdata2} AddWindow='AddWindow' />
              </>
            ) : WidthType === 'Section 1' ? (
              <>
                <DrawerInputWidth measurement_type={measurementType} section={section1} WidthType='Section 1' func={fetchdata2} AddWindow='AddWindow' />
              </>
            ) : WidthType === 'Section 2' ? (
              <>
                <DrawerInputWidth measurement_type={measurementType} section={section2} WidthType='Section 2' func={fetchdata2} AddWindow='AddWindow' />
              </>
            ) : WidthType === 'Section 3' ? (
              <>
                <DrawerInputWidth section={section3} measurement_type={measurementType} WidthType='Section 3' func={fetchdata2} AddWindow='AddWindow' />
              </>
            ) : WidthType === 'Section 4' ? (
              <>
                <DrawerInputWidth section={section4} measurement_type={measurementType} WidthType='Section 4' func={fetchdata2} AddWindow='AddWindow' />
              </>
            ) : WidthType === 'Section 5' ? (
              <>
                <DrawerInputWidth section={section5} measurement_type={measurementType} WidthType='Section 5' func={fetchdata2} AddWindow='AddWindow' />
              </>
            ) : HeigthType === 'SectionTopHeigth' || WidthType === 'SectionTopHeigth' ? (
              <>
                <DrawerInputWidth measurement_type={measurementType} SectionOneTopShow={SectionOneTopHeigth} HeigthType='SectionTopHeigth' func2={fetchdata3} AddWindow='AddWindow' />
              </>
            ) : (
              <>
                <DrawerInputWidth measurement_type={measurementType} func={fetchdata2} AddWindow='AddWindow' />
              </>
            )}
          </Drawer>
        </Typography>

        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setOpen(false)}>
            {classtype === 'Success' ? (
              <Alert className={classtype} style={{ zIndex: 1000000 }} variant='filled' icon={false}>
                <div className='alertMsgCancelICon' align='right'>
                  <IconButton color='inherit' onClick={handleSuccessClose}>
                    <CancelRoundedIcon />
                  </IconButton>
                </div>
                <div align='center' style={{ paddingTop: 30 }}>
                  <CheckCircleIcon sx={{ fontSize: 40 }} />

                  <div style={{ paddingTop: 10 }}>{alertmessage}</div>
                </div>
              </Alert>
            ) : (
              <Alert className={classtype} variant='filled' icon={false}>
                <div className='alertMsgCancelICon' align='right'>
                  <IconButton color='inherit' onClick={handleSuccessClose}>
                    <CancelRoundedIcon />
                  </IconButton>
                </div>
                <div align='center' style={{ paddingTop: 30 }}>
                  <ErrorIcon sx={{ fontSize: 40 }} />

                  <div style={{ paddingTop: 10 }}>{alertmessage}</div>
                </div>
              </Alert>
            )}
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={VerticalConfigurationAlert} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setVerticalConfigurationAlert(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setVerticalConfigurationAlert(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Section 1 - Top H is greater than {fracty(HeightForVerticalConfiguration)}</div>
                </div>
              </>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={warnQty} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setwarnQty(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setwarnQty(false);
                    }}
                    src={RedDrawerCancelIcon}
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle'>
                  <img src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{msg}</div>
                </div>
              </>
            </Alert>
          </Snackbar>
        </Stack>
      </div>

      <Box p={0} paddingLeft={0} paddingTop={0} textAlign='center'>
        <ThemeProvider theme={theme}>
          {data.requestFrom !== 'ProposalApplyCovering' ? <WindowOptions /> : null}
          {/* <ProductOptions /> */}
          <Box sx={{ marginBottom: '25px' }} align='left' className='filter-box-ipad'>
            <Grid
              item
              xs={0}
              sm={12}
              md={12}
              lg={12}
              sx={{
                position: 'sticky',
              }}
              display={{
                xs: 'none',
                lg: 'block',

                md: 'block',
                sm: 'block',
              }}
            >
              <Button
                className='gray_button_span button_top_space'
                onMouseEnter={hoverReset}
                onMouseLeave={hoverReset}
                onClick={reset}
                sx={{
                  justifyContent: 'left',
                }}
              >
                <img className={`secondary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} src={FilterIcon} />

                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  {' '}
                  CLEAR FILTERS
                </Typography>
              </Button>
            </Grid>

            <Box
              sx={{
                height: 'auto',
                overflowY: 'hidden',
                overflowX: 'hidden',
              }}
            >
              <Box align='center' className='filter-box-ipad'>
                <FormControl className='formcontrol filtertext_selectfield' onClick={() => setWindowCoveringCategoryOpenOpen(!windowCoveringCategoryOpen)}>
                  <Select
                    MenuProps={{
                      style: { zIndex: 100000 },
                    }}
                    size='small'
                    select
                    align='left'
                    value={coveringcategory}
                    onClose={() => setWindowCoveringCategoryOpenOpen(false)}
                    onOpen={() => setWindowCoveringCategoryOpenOpen(true)}
                    open={windowCoveringCategoryOpen}
                    onChange={({ target }) => handleWindowCoveringCategorySelection(target.value)}
                    displayEmpty
                    startAdornment={
                      <InputAdornment position='start'>
                        Category :&nbsp;
                        {coveringcategory === '' ? <span>─ ─</span> : ''}
                      </InputAdornment>
                    }
                  >
                    <MenuItem value='Multiple' sx={{ display: 'none' }}>
                      Multiple
                    </MenuItem>
                    <MenuItem value={' '}>
                      <b>─ ─</b>
                    </MenuItem>
                    {categorynames.sort().map((i) => {
                      return (
                        <MenuItem key={i} value={i}>
                          <b>{i}</b>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {windowDetails.measurementType !== coveringcategory ? (
                  <InformationAlerts
                    startIcon={WarningIcon}
                    sx={{ color: { xs: 'white', sm: 'black' } }}
                    alerts={[
                      {
                        alert: 'measurement-category-mismatch',
                        text: 'Category selection does not match Measurement Type',
                        active: windowDetails.measurementType !== coveringcategory,
                      },
                    ]}
                  />
                ) : null}
                <hr className='top_bottom_space' />
                <FormControl className='formcontrol filtertext_selectfield' onClick={() => setBrandOpen(!brandOpen)}>
                  <Select
                    MenuProps={{
                      style: { zIndex: 100000 },
                    }}
                    size='small'
                    select
                    align='left'
                    value={brand}
                    onClose={() => setBrandOpen(false)}
                    onOpen={() => setBrandOpen(true)}
                    open={brandOpen}
                    onChange={(e) => {
                      handleBrandSelection(e.target.value, e);
                    }}
                    displayEmpty
                    startAdornment={
                      <InputAdornment position='start'>
                        Brand :&nbsp;&nbsp;
                        {brand === '' ? <span>─ ─</span> : ''}
                      </InputAdornment>
                    }
                  >
                    <MenuItem value='Multiple' sx={{ display: 'none' }}>
                      Multiple
                    </MenuItem>
                    <MenuItem value={' '}>
                      <b>─ ─</b>
                    </MenuItem>
                    {brands.sort().map((i) => {
                      return (
                        <MenuItem key={i} value={i}>
                          <b>{i}</b>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl className='formcontrol filtertext_selectfield' onClick={() => setLabelOpen(!labelOpen)}>
                  <Select
                    MenuProps={{
                      style: { zIndex: 100000 },
                    }}
                    size='small'
                    select
                    align='left'
                    value={label}
                    onClose={() => setLabelOpen(false)}
                    onOpen={() => setLabelOpen(true)}
                    open={labelOpen}
                    onChange={handleLabelSelection}
                    displayEmpty
                    startAdornment={
                      <InputAdornment position='start'>
                        Label :&nbsp;&nbsp;
                        {label === '' ? <span>─ ─</span> : ''}
                      </InputAdornment>
                    }
                  >
                    <MenuItem value='Multiple' sx={{ display: 'none' }}>
                      Multiple
                    </MenuItem>
                    <MenuItem value={' '}>
                      <b>─ ─</b>
                    </MenuItem>
                    {brandLabel.sort().map((i) => (
                      <MenuItem key={i} value={i}>
                        <b>{i}</b>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl className='formcontrol filtertext_selectfield' onClick={() => setProductOpen(!productOpen)}>
                  <Select
                    MenuProps={{
                      style: { zIndex: 100000 },
                    }}
                    size='small'
                    select
                    align='left'
                    value={product}
                    onClose={() => setProductOpen(false)}
                    onOpen={() => setProductOpen(true)}
                    open={productOpen}
                    onChange={handleProductSelection}
                    displayEmpty
                    startAdornment={
                      <InputAdornment position='start'>
                        Product : &nbsp;&nbsp;
                        {product === '' ? <span>─ ─</span> : ''}
                      </InputAdornment>
                    }
                  >
                    <MenuItem value='Multiple' sx={{ display: 'none' }}>
                      Multiple
                    </MenuItem>
                    <MenuItem value={' '}>
                      <b>─ ─</b>
                    </MenuItem>
                    {brandLabelProduct.sort().map((i) => {
                      return (
                        <MenuItem key={i} value={i}>
                          <b>{i}</b>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl onClick={() => setTypeOpen(!typeOpen)} className='formcontrol filtertext_selectfield'>
                  <Select
                    MenuProps={{
                      style: { zIndex: 100000 },
                    }}
                    size='small'
                    select
                    onClose={() => setTypeOpen(false)}
                    onOpen={() => setTypeOpen(true)}
                    open={typeOpen}
                    align='left'
                    value={type}
                    onChange={handleTypeSelection}
                    displayEmpty
                    startAdornment={
                      <InputAdornment position='start'>
                        Type :&nbsp;&nbsp;
                        {type === '' ? <span>─ ─</span> : ''}
                      </InputAdornment>
                    }
                  >
                    <MenuItem value='Multiple' sx={{ display: 'none' }}>
                      Multiple
                    </MenuItem>
                    <MenuItem value={' '}>
                      <b>─ ─</b>
                    </MenuItem>
                    {brandLabelProductType.sort().map((i) => {
                      return (
                        <MenuItem key={i} value={i}>
                          <b>{i}</b>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <hr className='top_bottom_space' />

                <FormControl onClick={() => setFabricOpen(!fabricOpen)} className='formcontrol filtertext_selectfield'>
                  <Select
                    MenuProps={{
                      style: { zIndex: 100000 },
                    }}
                    size='small'
                    select
                    onClose={() => setFabricOpen(false)}
                    onOpen={() => setFabricOpen(true)}
                    open={fabricOpen}
                    align='left'
                    value={fabric}
                    onChange={handleFabricSelection}
                    displayEmpty
                    startAdornment={
                      <InputAdornment position='start'>
                        Material :&nbsp;&nbsp;
                        {fabric === '' ? <span>─ ─</span> : ''}
                      </InputAdornment>
                    }
                  >
                    <MenuItem value='Multiple' sx={{ display: 'none' }}>
                      Multiple
                    </MenuItem>
                    <MenuItem value={' '}>
                      <b>─ ─</b>
                    </MenuItem>
                    {brandLabelProductTypeOpacityFabric.map((i) => {
                      return (
                        <MenuItem key={i} value={i}>
                          <b>{i}</b>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl onClick={() => setOpacityOpen(!opacityOpen)} className='formcontrol filtertext_selectfield'>
                  <Select
                    MenuProps={{
                      style: { zIndex: 100000 },
                    }}
                    size='small'
                    select
                    onClose={() => setOpacityOpen(false)}
                    onOpen={() => setOpacityOpen(true)}
                    open={opacityOpen}
                    align='left'
                    value={opacity}
                    onChange={handleOpacitySelection}
                    displayEmpty
                    startAdornment={
                      <InputAdornment position='start'>
                        {coveringcategory === 'Blinds' || coveringcategory === 'Shutters' ? <>Louver Size</> : <>Opacity</>}
                        :&nbsp;&nbsp;
                        {opacity === '' ? <span>─ ─</span> : ''}
                      </InputAdornment>
                    }
                  >
                    <MenuItem value='Multiple' sx={{ display: 'none' }}>
                      Multiple
                    </MenuItem>
                    <MenuItem value={' '}>
                      <b>─ ─</b>
                    </MenuItem>
                    {brandLabelProductTypeOpacity.sort().map((i) => (
                      <MenuItem key={i} value={i}>
                        <b>{i}</b>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl onClick={() => setLiftOpen(!liftOpen)} className='formcontrol filtertext_selectfield'>
                  <Select
                    MenuProps={{
                      style: { zIndex: 100000 },
                    }}
                    size='small'
                    select
                    onClose={() => setLiftOpen(false)}
                    onOpen={() => setLiftOpen(true)}
                    open={liftOpen}
                    align='left'
                    value={lift}
                    onChange={(e) => {
                      handleLiftSelection(e.target.value);
                    }}
                    displayEmpty
                    startAdornment={
                      <InputAdornment position='start'>
                        Lift System :&nbsp;&nbsp;
                        {lift === '' ? <span>─ ─</span> : ''}
                      </InputAdornment>
                    }
                  >
                    <MenuItem value='Multiple' sx={{ display: 'none' }}>
                      Multiple
                    </MenuItem>
                    <MenuItem value={' '}>
                      <b>─ ─</b>
                    </MenuItem>
                    {LiftSystem.map((i) => {
                      return (
                        <MenuItem key={i} value={i}>
                          <b>{i}</b>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl onClick={() => setDirectionOpen(!directionOpen)} className='formcontrol filtertext_selectfield'>
                  <Select
                    MenuProps={{
                      style: { zIndex: 100000 },
                    }}
                    size='small'
                    select
                    onClose={() => setDirectionOpen(false)}
                    onOpen={() => setDirectionOpen(true)}
                    open={directionOpen}
                    align='left'
                    value={direction}
                    onChange={(e) => {
                      handleDirectionSelection(lift, e.target.value);
                    }}
                    displayEmpty
                    startAdornment={
                      <InputAdornment position='start'>
                        {' '}
                        Direction :&nbsp;&nbsp;
                        {direction === '' ? <span>─ ─</span> : ''}
                      </InputAdornment>
                    }
                  >
                    <MenuItem value='Multiple' sx={{ display: 'none' }}>
                      Multiple
                    </MenuItem>
                    <MenuItem value={' '}>
                      <b>─ ─</b>
                    </MenuItem>
                    {brandLabelProductTypeOpacityFabricLiftDirection.map((i) => (
                      <MenuItem key={i} value={i}>
                        <b>{i}</b>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <div>
                  {Object.keys(ReqOptionDetails).length > 0
                    ? Object.keys(ReqOptionDetails).map((opt, index) => (
                        <FormControl key={index} onClick={() => handleFormControlClick(opt)} className='formcontrol filtertext_selectfield'>
                          <Select
                            MenuProps={{
                              style: { zIndex: 100000 },
                            }}
                            onClose={() => setOpenMenu(null)}
                            open={openMenu === opt}
                            size='small'
                            select
                            align='left'
                            value={selectedOptionJSON[opt] ? selectedOptionJSON[opt].name : ' '}
                            onChange={(e) => {
                              handleOption(e, opt);
                            }}
                            displayEmpty
                            startAdornment={
                              <InputAdornment position='start'>
                                {' '}
                                {opt} :&nbsp;&nbsp;
                                {SelectedOptions[opt] === ' ' ? <span>─ ─</span> : ''}
                              </InputAdornment>
                            }
                          >
                            <MenuItem value='Multiple' sx={{ display: 'none' }}>
                              Multiple
                            </MenuItem>
                            <MenuItem value={' '}>
                              <b>─ ─</b>
                            </MenuItem>
                            {ReqOptionDetails[opt].map((i) => {
                              return (
                                <MenuItem key={i.sku} value={i.name}>
                                  <b>{i.name}</b>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      ))
                    : null}
                </div>
                <div align='left'>
                  <hr className='button_top_space button_bottom_space' />
                  {opacity && opacity !== ' ' ? (
                    <>
                      <InformationAlerts
                        startIcon={WarningIcon}
                        sx={{ color: { xs: 'white', sm: 'black' } }}
                        alerts={[
                          {
                            alert: 'optional-pricing-items',
                            text: 'The following fields are not required for pricing but are required for conversion to sales order',
                            active: true,
                          },
                        ]}
                      />
                      {/* Optional Options */}
                      {Object.keys(NotReqOptionDetails).length
                        ? Object.keys(NotReqOptionDetails).map((option, index) => (
                            <FormControl key={index} onClick={() => handleFormControlClick(option)} className='formcontrol filtertext_selectfield'>
                              <Select
                                MenuProps={{
                                  style: { zIndex: 100_000 },
                                }}
                                size='small'
                                select
                                onClose={() => setOpenMenu(null)}
                                open={openMenu === option}
                                align='left'
                                value={selectedOptionJSON[option] ? selectedOptionJSON[option].name : ' '}
                                onChange={(e) => {
                                  handleOption(e, option);
                                }}
                                displayEmpty
                                startAdornment={
                                  <InputAdornment position='start'>
                                    {' '}
                                    {option} :&nbsp;&nbsp;
                                    {SelectedOption[option] === '' ? <span>─ ─</span> : null}
                                  </InputAdornment>
                                }
                              >
                                <MenuItem value='Multiple' sx={{ display: 'none' }}>
                                  Multiple
                                </MenuItem>
                                <MenuItem value={' '}>
                                  <b>─ ─</b>
                                </MenuItem>
                                {NotReqOptionDetails[option].map((option) => (
                                  <MenuItem key={option.sku} value={option.name}>
                                    <b>{option.name}</b>
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ))
                        : null}
                    </>
                  ) : null}
                </div>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </Box>
    </div>
  );
};
