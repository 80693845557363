/** @format */

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import { TextField, Typography } from '@mui/material';
import CONFIG from '../../config/config.js';
import DrawerCloseIcon from '../../assets/icons/drawer-alert/close.svg';

import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerSaveIcon from '../../assets/icons/drawer-alert/drw-save.svg';

const EditProposalName = (props) => {
  const [proposalName, setProposalName] = React.useState(props.proposaldetails.proposal_name);
  const [isDrawerClose, setIsDrawerClose] = React.useState(true);
  const [alertmesssage, setAlertMessage] = React.useState('');
  const navigate = useNavigate();
  useEffect(() => {
    {
      window.scrollTo(0, 0);
    }
    getVerified();
  }, []);
  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };
  const EditFun = (e) => {
    setProposalName(e.target.value);
  };
  const closeDrawer = () => {
    setIsDrawerClose(false);
  };
  props.func(proposalName, isDrawerClose, alertmesssage);
  const editProposalNameFun = async () => {
    await fetch(`${CONFIG.API_URL}/pm/UpdateProposalName`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: props.proposaldetails.proposal_id,
        proposal_name: proposalName,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setIsDrawerClose(false);
          setAlertMessage(data.message);
        } else if (data.error === true) {
          setAlertMessage(data.error_message);
          setIsDrawerClose(false);
        } else {
          setAlertMessage('');
          setIsDrawerClose(false);
        }
      });
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  return (
    <Box p={4} pt={2} className='sideDrawer'>
      <div align='right' className='drawer_backbutton'>
        <img alt='CLOSEDRAWER' src={DrawerCloseIcon} onClick={closeDrawer} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
      </div>
      <Stack direction='column' spacing={0}>
        <TextField value={proposalName} onChange={EditFun} className='notes_textfield' />

        {proposalName ? (
          <span className='drawer_gray_button_span ' onClick={editProposalNameFun} style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
            <Box className='globalIconMainBox globalDrawerText'>
              <img alt='SAVE' src={DrawerSaveIcon} className={`primary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
                SAVE
              </Typography>
            </Box>
          </span>
        ) : (
          <span className='drawer_gray_button_span' style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
            <Box className='globalIconMainBox '>
              <img alt='SAVED' src={DrawerSaveIcon} className='primary_icons_dimension_disable' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
                SAVE
              </Typography>
            </Box>
          </span>
        )}

        <span className='drawer_gray_button_span button_top_space' onClick={closeDrawer} style={{ paddingBottom: '30px', paddingLeft: '14px' }}>
          <Box className='globalIconMainBox'>
            <img alt='CANCEL' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension' />
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
              CANCEL
            </Typography>
          </Box>
        </span>
      </Stack>
    </Box>
  );
};

export default EditProposalName;
