/** @format */

import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import proposalAddIcon2 from '../../assets/icons/primary/proposal-add.svg';
import proposalAddHovIcon from '../../assets/icons/primary/WithHovIcon/proposal-add-hov.svg';
import { Stack, Typography } from '@mui/material';
import ClearIcon from '../../img/clear.png';
import ContinueIcon from '../../assets/icons/secondary/2nd-continue.svg';
import { Box } from '@mui/system';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Checkbox from '@mui/material/Checkbox';
import MiscCheckIcon from '../../assets/icons/misc/misc-confirm.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CONFIG from '../../config/config.js';
import CancelIcon from '../../img/cancel.png';
import CancelIcon1 from '../../assets/icons/secondary/2nd-cancel.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';
import DrawerConinueIcon from '../../assets/icons/drawer-alert/drw-continue.svg';
import SalesOrderStepper from './SalesOrderStepper';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import WindowDetails from './WindowDetails';
import { makeStyles } from '@mui/styles';
import NotesDrawer from '../Proposals/NotesDrawer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoteMiscIcon from '../../assets/icons/misc/misc-note.svg';
import LongText from '../LongText';

const vertical = 'top';
const horizontal = 'center';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const useStyles = makeStyles({
  paper: {
    width: '90%',
    background: 'rgba(69, 157, 166, 0.70)  !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

let selectedwindowArray = [];

export default function SalesOrder() {
  const classes = useStyles();
  const outerTheme = createTheme({
    palette: {
      primary: {
        main: '#333333',
      },
    },
  });

  const location = useLocation();
  const navigate = useNavigate();

  let locationState = [];
  let emailid;
  let lastName;
  let city;

  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    locationState = location.state;
    emailid = locationState.customerDetails.customerdata.email_id;
    lastName = locationState.customerDetails.customerdata.last_name;
    city = locationState.customerDetails.customerdata.city;
  }

  const [materialColorOpen, setMaterialColorOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const [materialColor, setMaterialColor] = useState('');
  const [materialColorCode, setMaterialColorCode] = useState('');
  const [materialColorOptions, setMaterialColorOptions] = useState([]);
  const [selectedWindowsforSalesOrderCheck, setselectedWindowsforSalesOrderCheck] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const [checkedState, setCheckedState] = useState(new Array(location.state.SalesOrderDetails.length).fill(false));
  const [accordianopen, setAccordianOpen] = useState(new Array(location.state.SalesOrderDetails.length).fill(false));
  const [checkedStateConfirmation, setCheckedStateConfirmation] = useState(new Array(location.state.SalesOrderDetails.length).fill(false));
  const [windowItem, setWindowItems] = useState(new Array(location.state.SalesOrderDetails.length).fill(false));

  const handleHoverFun = () => setIsHovered(true);

  const handleMouseLeaveFun = () => setIsHovered(false);

  const [valConfirmation, setValConfirmation] = useState(new Array(location.state.SalesOrderDetails.length).fill(false));

  const appliedcoveringswindows = locationState.checkedrowdata;
  const [deleteItem, setDeleteItem] = useState(false);
  const [editItem, setEditItem] = useState(false);
  const [SalesOrderName, setSalesOrderName] = useState(locationState.salesordername);
  const [selectedWindowsforAdditionalItems, setSelectedWindowsforAdditionalItems] = useState();
  const [confirmedWindows, setConfirmedWindows] = useState([]);
  const [SalesOrderSpecificFactorDetails, setSalesOrderSpecificFactorDetails] = useState({});
  const salesOrderId = locationState.salesOrderId;

  const [selected, setSelected] = React.useState([]);
  const [selectedforconfirm, setSelectedforConfirm] = React.useState([]);
  const [CoveringCategory, setCoveringCategory] = useState();
  const token = sessionStorage.getItem('token');
  const [salesOrderWindows, setSalesOrderWindows] = useState([]);

  // error handling states
  const [OptionalFinalizationDropDownListErrorAlert, setOptionalFinalizationDropDownListErrorAlert] = useState(false);
  const [OptionalFinalizationDropDownListErrorMsg, setOptionalFinalizationDropDownListErrorMsg] = useState('');
  const [SalesOrderSpecificFactorDetailsErrorAlert, setSalesOrderSpecificFactorDetailsErrorAlert] = useState(false);
  const [SalesOrderSpecificFactorDetailsErrorMsg, setSalesOrderSpecificFactorDetailsErrorMsg] = useState('');

  const [UpdateSalesOrderToConfirmErrorAlert, setUpdateSalesOrderToConfirmErrorAlert] = useState(false);
  const [UpdateSalesOrderToConfirmErrorMsg, setUpdateSalesOrderToConfirmErrorMsg] = useState('');
  const [MaterialColorsBySKUsErrorAlert, setMaterialColorsBySKUsErrorAlert] = useState(false);
  const [MaterialColorsBySKUsErrorMsg, setMaterialColorsBySKUsErrorMsg] = useState('');

  const [SalesOrderDetailsErrorAlert, setSalesOrderDetailsErrorAlert] = useState(false);
  const [SalesOrderDetailsErrorMsg, setSalesOrderDetailsErrorMsg] = useState('');

  const [NotReqOptionDetails, setNotReqOptionDetails] = useState([]);
  const [notreqselectedOptionJSON, setNotReqSelectedOptionJSON] = useState({});

  const [openMenu, setOpenMenu] = useState(null);
  const [AllOptionNPSSelectedStatus, setAllOptionNPSSelectedStatus] = useState(false);
  const [AllFFSelectedStatus, setAllFFSelectedStatus] = useState(false);

  const unique = (value, index, self) => self.indexOf(value) === index;

  useEffect(() => {
    const updated = salesOrderWindows.map((window) => {
      if (Object.keys(NotReqOptionDetails).length > 0 && !window.sales_order_specific_factor) {
        window.sales_order_specific_factor = {
          'Material Color': {
            key: 'Material Color',
            name: ' ',
            colorCode: '',
            pricingStatus: false,
            sku: '',
          },
        };
        Object.keys(NotReqOptionDetails).forEach((key) => {
          window.sales_order_specific_factor[key] = {
            key,
            name: ' ',
            colorCode: '',
            pricingStatus: false,
            sku: '',
          };
        });
      }
      return window;
    });
    setSalesOrderWindows(updated);
  }, [NotReqOptionDetails]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getNotesByReferenceId();
    GetSaleOrderWindows(locationState.salesOrderId);
  }, []);

  useEffect(() => {
    let updatedCheckBox = [];

    selectedWindowsforSalesOrderCheck.map((item) => {
      if (item.sales_order_specific_factor) {
        updatedCheckBox.push(false);
      } else {
        updatedCheckBox.push(true);
      }
      setCheckedState(updatedCheckBox);
    });
  }, [selectedWindowsforSalesOrderCheck]);

  useEffect(() => {
    materialColorOptions.forEach((option) => {
      if (option.color_name === materialColor) {
        setMaterialColorCode(option.material_color_code);
      }
    });
  }, [materialColor, materialColorOptions, materialColorCode]);

  const handleClose = () => setDeleteItem(false);

  const handleCloseEdit = () => setEditItem(false);

  const handleFormControlClick = (opt) => setOpenMenu(openMenu === opt ? null : opt); // Close the menu if it's already open; otherwise, open the menu for the clicked FormControl

  const handleOnChange = (position, e, row) => {
    if (e.target.checked) {
      selectedwindowArray.push(row);
    } else {
      // Checkbox is unchecked, so remove the row from the array
      const selectedIndex = selectedwindowArray.indexOf(row);
      if (selectedIndex !== -1) {
        selectedwindowArray.splice(selectedIndex, 1);
      }
    }

    let checkstatus = e.target.checked;
    let allWindowStatus;
    const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item));

    setCheckedState(updatedCheckedState);
    let newSelected = [];
    if (checkstatus) {
      const selectedIndex = selected.indexOf(row);

      newSelected.push(row);
      newSelected.forEach((item) => {
        const idx = salesOrderWindows.findIndex((e) => e === item);
        if (idx >= 0) {
          salesOrderWindows[idx].selected = true;
        }
      });

      setSelected(newSelected);
      setSelectedWindowsforAdditionalItems(selectedwindowArray);
    } else {
      const selectedIndex = selected.indexOf(row);
      const selectedActualIndex = salesOrderWindows.indexOf(row);
      salesOrderWindows[selectedActualIndex].selected = false;

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      setSelected(newSelected);
      setSelectedWindowsforAdditionalItems(newSelected);
    }

    const checker = (arr1) => arr1.every((v) => v === false);

    allWindowStatus = checker(updatedCheckedState);

    let similarArrIndex = [];
    let dissimilarArrIndex = [];

    appliedcoveringswindows.forEach((windows, index) => {
      if (windows.covering_type === CoveringCategory) {
        similarArrIndex.push(index);
      } else {
        dissimilarArrIndex.push(index);
      }
    });

    if (checkstatus) {
      dissimilarArrIndex.forEach((selectedposition) => {
        for (var i = 0; i < windowItem.length; i++) {
          if (i === selectedposition) {
            windowItem[i] = true;
          }
        }

        setWindowItems(windowItem);
      });
    }

    if (!checkstatus && allWindowStatus) {
      dissimilarArrIndex.forEach((selectedposition) => {
        for (let i = 0; i < windowItem.length; i++) {
          if (i === selectedposition) windowItem[i] = false;
        }
        setWindowItems(windowItem);
      });
    }
  };

  const handleOnChangeConfirmation = async (position, e, row) => {
    let checkstatus = e.target.checked;
    const updatedCheckedState = checkedStateConfirmation.map((item, index) => (index === position ? !item : item));
    setValConfirmation(updatedCheckedState);
    if (checkstatus) {
      const selectedIndex = selectedforconfirm.indexOf(row);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedforconfirm, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedforconfirm.slice(1));
      } else if (selectedIndex === selectedforconfirm.length - 1) {
        newSelected = newSelected.concat(selectedforconfirm.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selectedforconfirm.slice(0, selectedIndex), selectedforconfirm.slice(selectedIndex + 1));
      }

      newSelected.forEach((item) => {
        const idx = salesOrderWindows.findIndex((e) => e === item);
        if (idx >= 0) {
          salesOrderWindows[idx].selected = true;
        }
      });

      setSelectedforConfirm(newSelected);
      setConfirmedWindows(newSelected);
    } else {
      const selectedIndex = selectedforconfirm.indexOf(row);
      const selectedActualIndex = salesOrderWindows.indexOf(row);
      salesOrderWindows[selectedActualIndex].selected = false;

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedforconfirm, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedforconfirm.slice(1));
      } else if (selectedIndex === selectedforconfirm.length - 1) {
        newSelected = newSelected.concat(selectedforconfirm.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selectedforconfirm.slice(0, selectedIndex), selectedforconfirm.slice(selectedIndex + 1));
      }

      setSelectedforConfirm(newSelected);
      setConfirmedWindows(newSelected);
    }

    setCheckedStateConfirmation(updatedCheckedState);
  };

  const accordianOpenCloseFun = (index, window) => {
    const currentState = accordianopen[index];
    setAccordianOpen((previous) => {
      const updated = [...previous];
      updated.splice(index, 1, !currentState);
      return updated;
    });
  };

  const GetSaleOrderWindows = async (salesOrderId) => {
    await fetch(`${CONFIG.API_URL}/so/salesorderdetails/${salesOrderId}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          const selectedWindowsforSalesOrder = data.SalesOrderDetails.filter((SalesOrderWindow) => SalesOrderWindow.window_selected && SalesOrderWindow.covering_type === locationState.CoveringCategory);

          const selectedwindows = selectedWindowsforSalesOrder.filter((window) => appliedcoveringswindows.some((element) => window.window_id === element.window_id));

          const pushArray = selectedwindows.filter((window) => window.window_selected && !window.sales_order_status);
          // pushArray.forEach(window =>)

          setSalesOrderWindows(pushArray);
          setSelectedWindowsforAdditionalItems(selectedWindowsforSalesOrder);
          const allEqual = (arr) => arr.every((val) => val.covering_type === arr[0].covering_type);
          const checkCoveringType = allEqual(selectedWindowsforSalesOrder);

          if (checkCoveringType) {
            setCoveringCategory(selectedWindowsforSalesOrder[0].covering_type);
          }
          setselectedWindowsforSalesOrderCheck(selectedWindowsforSalesOrder);

          setSelected(selectedWindowsforSalesOrder);

          const SKUs = selectedwindows.map((element) => element.sku);

          getMaterialColorbySKU(SKUs);
          getNotReqOptionsBySKUs(SKUs);
        } else {
          setSalesOrderDetailsErrorMsg(data.error_message);
          setSalesOrderDetailsErrorAlert(true);
        }
      });
  };

  const countEmptyValues = (jsonObject) => {
    let count = 0;

    for (const key in jsonObject) {
      if (jsonObject[key] === ' ' || (typeof jsonObject[key] === 'string' && !jsonObject[key]) || (Array.isArray(jsonObject[key]) && !jsonObject[key].length)) {
        count++;
      }
    }

    return count;
  };

  useEffect(() => {
    let sales_order_specific_factor = {
      'Material Color': {
        key: 'Material Color',
        name: materialColor,
        colorCode: materialColorCode,
        pricingStatus: false,
        sku: '',
      },
      ...notreqselectedOptionJSON,
    };

    if (countEmptyValues(sales_order_specific_factor) === 0 && Object.keys(sales_order_specific_factor).length !== 0) {
      setAllFFSelectedStatus(true);
    } else {
      setAllFFSelectedStatus(false);
    }

    setSalesOrderSpecificFactorDetails({
      ...notreqselectedOptionJSON,
      ...sales_order_specific_factor,
    });
  }, [materialColor, notreqselectedOptionJSON]);

  const UpdateSalesOrderStatus = async () => {
    const response = await fetch(`${CONFIG.API_URL}/so/SalesOrderSpecificFactorDetails`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        sales_order_id: salesOrderId,
        sales_order_specific_factor: SalesOrderSpecificFactorDetails,
        windows: selectedWindowsforAdditionalItems,
      }),
    });
    const data = await response.json();

    if (!data.error) {
      setOpen(true);
      GetSaleOrderWindows(salesOrderId);
      setMaterialColor('');
      selectedwindowArray = [];
    } else {
      setSalesOrderSpecificFactorDetailsErrorMsg(data.error_message);
      setSalesOrderSpecificFactorDetailsErrorAlert(true);
    }
  };

  const handleConfirmSalesOrder = async () => {
    const response = await fetch(`${CONFIG.API_URL}/so/UpdateSalesOrderToConfirm`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        sales_order_id: salesOrderId,
        windows: selectedforconfirm,
      }),
    });
    const data = await response.json();
    if (!data.error) {
      GetSaleOrderWindows(salesOrderId);
      let SalesOrderWindows = data.SalesOrderDetails;
      let confirmall = data.SalesOrderDetails.every(({ sales_order_status }) => sales_order_status === true);
      if (confirmall) {
        navigate('/tbm/ps/finalizesalesorder', {
          state: {
            SalesOrder: locationState.SalesOrder,
            SalesOrderDetails: SalesOrderWindows,
            ProposalDetails: locationState.ProposalDetails,
            remeasure: locationState.remeasure,
            customerId: locationState.customerId,
            addressId: locationState.addressId,
            customerDetails: locationState.customerDetails,
            data: locationState.data,
            salesOrderId: locationState.salesOrderId,
          },
        });
      } else {
        navigate(`/tbm/ps/salesorderaddingwindows`, {
          state: {
            SalesOrder: locationState.SalesOrder,
            SalesOrderDetails: locationState.SalesOrderDetails,
            ProposalDetails: locationState.ProposalDetails,
            remeasure: locationState.remeasure,
            customerId: locationState.customerId,
            addressId: locationState.addressId,
            customerDetails: locationState.customerDetails,
            salesOrderId: locationState.salesOrderId,
            data: locationState.data,
          },
        });
      }
    } else {
      setUpdateSalesOrderToConfirmErrorMsg(data.error_message);
      setUpdateSalesOrderToConfirmErrorAlert(true);
    }
  };

  const getMaterialColorbySKU = async (SKUs) => {
    const response = await fetch(`${CONFIG.API_URL}/pm/MaterialColorsBySKUs`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',

      body: JSON.stringify({
        sku: SKUs.toString(),
      }),
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!data.error) {
      setMaterialColorOptions(data.MaterialColors);
    } else {
      setMaterialColorsBySKUsErrorMsg(data.error_message);
      setMaterialColorsBySKUsErrorAlert(true);
    }
  };

  const getNotReqOptionsBySKUs = async (SKUs) => {
    const response = await fetch(`${CONFIG.API_URL}/pm/AvailableOptionAndTypeByLiftSystem`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',

      body: JSON.stringify({
        sku: SKUs,
      }),
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();

    const options = {};
    const selected = {};

    if (!data.error) {
      data.AvailableOptionAndType.forEach((element) => {
        if (element.sku_type === 'P' && !element.pricing_status) {
          if (!options[element.type]) {
            const emptyOption = {
              key: element.type,
              name: ' ',
              sku: '',
              pricing_status: '',
              display_sort_order: 0,
            };
            options[element.type] = [emptyOption];
            selected[element.type] = emptyOption;
          }
          options[element.type].push({
            key: element.type,
            name: element.sku_name,
            sku: element.sku,
            pricing_status: element.pricing_status,
            display_sort_order: element.display_sort_order + 1,
          });
        }
      });

      Object.keys(options).forEach((key) => {
        options[key].sort((a, b) => a.display_sort_order - b.display_sort_order);
      });

      setNotReqOptionDetails(options);
      setNotReqSelectedOptionJSON(selected);
    }
  };

  useEffect(() => {
    const allAreSelected = Object.keys(NotReqOptionDetails).length === 0 || Object.keys(notreqselectedOptionJSON).every((key) => notreqselectedOptionJSON[key].name != ' ');
    setAllOptionNPSSelectedStatus(allAreSelected);
  }, [NotReqOptionDetails, notreqselectedOptionJSON]);

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => setIsImageHovered(!isImageHovered);

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => setIsImageHovered2(!isImageHovered2);

  const [isImageHovered3, setIsImageHovered3] = useState(false);
  const handleHover3 = () => setIsImageHovered3(!isImageHovered3);

  const [CancelAlert, setCancelAlert] = useState(false);

  const ConfirmAlertFun = () => {
    setCancelAlert(false);
    navigate(`/tbm/ps/customerdetails`, {
      state: {
        Custdata: {
          custid: locationState.customerId,
          Addressid: locationState.addressId,
        },
      },
    });
  };

  const handleOption = ({ target }, option) => {
    const options = NotReqOptionDetails[option];
    const selected = options.find((option) => option.name === target.value);
    setNotReqSelectedOptionJSON((prevObject) => ({
      ...prevObject,
      [option]: selected,
    }));
  };

  const isConfirmButtonDisabled = ({ sales_order_specific_factor }) => {
    if (!sales_order_specific_factor) return false;
    return Object.keys(sales_order_specific_factor).length !== Object.keys(notreqselectedOptionJSON).length + 1;
  };

  const [notesContent, setNotesContent] = useState([]);
  const [editingNote, setEditingNote] = useState(false);
  const [noteDrawerDetails, setNoteDrawerDetails] = useState({});
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);

  const closeNotesDrawer = async (alert) => {
    setNotesDrawerOpen(false);
    setEditingNote(false);
    setNoteDrawerDetails({});
    if (alert.error) {
    }
    if (alert.success === 'Note added successfully!' || alert.success === 'Note updated successfully!') {
      getNotesByReferenceId();
    }
  };

  const getNotesByReferenceId = async () => {
    const options = {
      method: 'GET',
      accept: 'application/json',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/Notes/${salesOrderId}`, options);
    const data = await response.json();
    if (!response.ok || data.error) {
    }
    setNotesContent(data.Notes);
  };

  const openNotesDrawer = (note) => {
    setEditingNote(!!note);
    setNoteDrawerDetails({
      name: SalesOrderName,
      referenceId: salesOrderId,
      noteId: note ? note.id : null,
    });
    setNotesDrawerOpen(true);
  };

  return (
    <div align='center'>
      <ThemeProvider theme={outerTheme}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar className='snackbar_bar_postition' open={OptionalFinalizationDropDownListErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setOptionalFinalizationDropDownListErrorAlert(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img src={RedDrawerCancelIcon} alt='RedDrawerCancelIcon' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{OptionalFinalizationDropDownListErrorMsg}</div>
              </div>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={SalesOrderDetailsErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setSalesOrderDetailsErrorAlert(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img src={RedDrawerCancelIcon} alt='RedDrawerCancelIcon' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{SalesOrderDetailsErrorMsg}</div>
              </div>
            </Alert>
          </Snackbar>

          {/* SalesOrderSpecificFactorDetailsErrorAlert */}
          <Snackbar className='snackbar_bar_postition' open={SalesOrderSpecificFactorDetailsErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setSalesOrderSpecificFactorDetailsErrorAlert(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img src={RedDrawerCancelIcon} alt='' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{SalesOrderSpecificFactorDetailsErrorMsg}</div>
              </div>
            </Alert>
          </Snackbar>

          {/* UpdateSalesOrderToConfirmErrorAlert */}
          <Snackbar className='snackbar_bar_postition' open={UpdateSalesOrderToConfirmErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setUpdateSalesOrderToConfirmErrorAlert(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img src={RedDrawerCancelIcon} alt='' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{UpdateSalesOrderToConfirmErrorMsg}</div>
              </div>
            </Alert>
          </Snackbar>

          {/* MaterialColorsBySKUsErrorAlert */}
          <Snackbar className='snackbar_bar_postition' open={MaterialColorsBySKUsErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setMaterialColorsBySKUsErrorAlert(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img src={RedDrawerCancelIcon} alt='' className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{MaterialColorsBySKUsErrorMsg}</div>
              </div>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={CancelAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setCancelAlert(false)}>
            <Alert className='Success mainAlert' variant='filled' icon={false}>
              <div className='alertCancel' align='right'>
                <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
              </div>

              <div className='alertMainNew'>
                <div align='left' style={{ paddingTop: 37 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'Center',
                    }}
                  >
                    <Box className='alertCantinueButton'>
                      <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='DrawerAlertIcon' />
                    </Box>
                  </Box>

                  <div
                    className='alertTextAlign'
                    style={{
                      paddingTop: 20,
                      fontSize: '20px',
                      fontFamily: 'RobotoRegular',
                    }}
                  >
                    This Sales Order has not been completed. Leaving will save it as a work in progress in the Customer Details section
                  </div>
                </div>

                <div
                  align='left'
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '20px 10px 0px 24px',
                    justifyContent: 'center',
                    alignItems: 'start',
                    lineHeight: '24px',
                  }}
                >
                  <Box
                    onClick={() => {
                      ConfirmAlertFun();
                    }}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'Center',
                      cursor: 'pointer',
                      marginBottom: '20px',
                    }}
                  >
                    <Box onClick={() => {}} className='alertCantinueButton'>
                      <img src={DrawerConinueIcon} className='primary_icons_dimension' alt='DrawerConinueIcon' />
                    </Box>
                    <Typography className='globalTextForDrawer' sx={{ marginLeft: '12px' }}>
                      CONTINUE
                    </Typography>
                  </Box>

                  <Box
                    onClick={() => {
                      setCancelAlert(false);
                    }}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'Center',
                      cursor: 'pointer',
                      paddingBottom: '16px',
                      paddingLeft: '15px',
                    }}
                  >
                    <Box className='globalIconMainBox'>
                      <Box className='iconSecondaryButtonGlobalForDrawer'>
                        <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
                      </Box>
                      <Typography className='globalTextForDrawer'>CANCEL</Typography>
                    </Box>
                  </Box>
                </div>
              </div>
            </Alert>
          </Snackbar>
        </Stack>

        <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setOpen(false)}>
          <Alert className='Success2 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img alt='DrawerCancelIcon' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' alt='DrawerConfirmIcon' />

              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Selections Applied Successfully</div>
            </div>
          </Alert>
        </Snackbar>

        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar className='snackbar_bar_postition' open={deleteItem} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
            <Alert className='Success ' variant='filled' icon={false} severity='warning'>
              <div className='alertMsgCancelICon' align='right'>
                <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
              </div>

              <div align='center' style={{ paddingTop: 20 }}>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='DrawerAlertIcon' />

                <div className='alertTextAlign' style={{ paddingLeft: '1%', paddingRight: '1%' }}>
                  <span style={{ fontSize: '20px' }}>This will override existing details on selected windows.</span>
                  <br />
                  <br />
                </div>
              </div>

              <div align='start'>
                <Button onClick={() => handleClose()} className='edit_drawer_button'>
                  <ArrowForwardIosIcon className='drawer_cleariconsize' sx={{ color: 'gray' }} />
                  Continue
                </Button>
                <br />
                <Button onClick={() => handleClose()} sx={{ marginTop: '0px !important' }} variant='contained' className='edit_drawer_button'>
                  <img src={CancelIcon} className='cancel_icon' alt='Clear' />
                  CANCEL
                </Button>
              </div>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={editItem} anchorOrigin={{ vertical, horizontal }} onClose={handleCloseEdit}>
            <Alert className='Success' variant='filled' icon={false} severity='warning'>
              <div className='alertMsgCancelICon' align='right'>
                <IconButton color='inherit' onClick={handleCloseEdit}>
                  <CancelRoundedIcon />
                </IconButton>
              </div>

              <div align='center' style={{ paddingTop: 20 }}>
                <ErrorIcon sx={{ fontSize: 40 }} />
                <div align='center' style={{ paddingLeft: '1%', paddingRight: '1%' }}>
                  <span style={{ fontSize: '20px' }}> {'Any changes made may affect the proposal price and possibly commissions'} </span>
                  <br />

                  <br />
                </div>
              </div>
              <div align='start'>
                <Button className='edit_drawer_button'>
                  <ArrowForwardIosIcon className='drawer_cleariconsize' sx={{ color: 'gray' }} />
                  Continue
                </Button>
                <br />
                <Button
                  onClick={() => {
                    handleCloseEdit();
                  }}
                  sx={{ marginTop: '0px !important' }}
                  variant='contained'
                  className='edit_drawer_button'
                >
                  <img className='drawer_cleariconsize' src={ClearIcon} alt='Clear' />
                  &nbsp;&nbsp;&nbsp;CANCEL
                </Button>
              </div>
            </Alert>
          </Snackbar>
        </Stack>

        <Drawer
          classes={{ paper: classes.paper }}
          PaperProps={{
            sx: { width: '90%' },
          }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          anchor='right'
          open={notesDrawerOpen}
          onClose={() => setNotesDrawerOpen(false)}
          className='drawer_Header_page'
        >
          <NotesDrawer closeDrawer={closeNotesDrawer} editing={editingNote} type='customer' details={noteDrawerDetails} />
        </Drawer>

        <div className='heading-box'>
          <h2 className='clickable_header all_pages_h2_header common_line_height_css'>
            <LongText>{SalesOrderName}</LongText>
          </h2>

          <h3 className='clickable_header_1 all_pages_h2_header button_top_space common_line_height_css' style={{ textTransform: 'capitalize' }} onClick={() => setCancelAlert(true)}>
            <LongText>{`${lastName} | ${city}`}</LongText>
          </h3>
          <img
            alt='NOTES'
            style={{
              width: '20px',
              color: '#008593',
              cursor: 'pointer',
              position: 'relative',
              float: 'right',
              top: '-55px',
            }}
            src={NoteMiscIcon}
            className='misc_icons_dimensions'
            onClick={() => openNotesDrawer()}
          />
        </div>

        <Box sx={{ marginBottom: '20px' }}>
          {notesContent.map((note, index) => (
            <Box key={note.id} sx={{ display: 'flex' }}>
              {note.visible ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : null}{' '}
              <Box>
                <Typography onClick={() => openNotesDrawer(note)}>
                  {' '}
                  <span
                    style={{
                      color: '#008593',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                    }}
                  >
                    Note #{index + 1} :
                  </span>{' '}
                  {note.content}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <div className='button_top_space' align='center' style={{ marginBottom: '10px' }}>
          <SalesOrderStepper stepperData='FinalizeSalesOrder' />
        </div>

        <hr className='button_bottom_space' />

        <div align='left'>
          <div>
            <FormControl onClick={() => setMaterialColorOpen(!materialColorOpen)} className='formcontrol text_selectfield_copy_upper margin_bottom'>
              <Select
                value={materialColor}
                required
                align='left'
                size='small'
                onClose={() => setMaterialColorOpen(false)}
                onOpen={() => setMaterialColorOpen(true)}
                open={materialColorOpen}
                onChange={(e) => setMaterialColor(e.target.value)}
                displayEmpty
                startAdornment={
                  <InputAdornment position='start'>
                    Material Color
                    <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                    &nbsp;:&nbsp;&nbsp;
                    {materialColor === '' ? <span>─ ─</span> : null}
                  </InputAdornment>
                }
              >
                <MenuItem value={' '}>
                  <b>─ ─</b>
                </MenuItem>
                {materialColorOptions.map(({ color_code, color_name }) => (
                  <MenuItem
                    onClick={() => {
                      setMaterialColorCode(color_code);
                    }}
                    key={color_name}
                    value={color_name}
                  >
                    <b>{color_name}</b>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <br />

            {Object.keys(NotReqOptionDetails).map((option, index) => (
              <>
                <FormControl key={index} onClick={() => handleFormControlClick(option)} className='formcontrol text_selectfield_copy_upper margin_bottom'>
                  <Select
                    size='small'
                    select
                    onClose={() => setOpenMenu(null)}
                    open={openMenu === option}
                    align='left'
                    value={notreqselectedOptionJSON[option] ? notreqselectedOptionJSON[option].name : ' '}
                    onChange={(e) => {
                      handleOption(e, option);
                    }}
                    displayEmpty
                    startAdornment={
                      <InputAdornment position='start'>
                        {' '}
                        {option}
                        <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                        &nbsp;:&nbsp;&nbsp;
                        {notreqselectedOptionJSON[option].name === ' ' ? <span>─ ─</span> : null}
                      </InputAdornment>
                    }
                  >
                    <MenuItem value={' '}>
                      <b>─ ─</b>
                    </MenuItem>
                    {NotReqOptionDetails[option].map(({ sku, name }) => (
                      <MenuItem key={sku} value={name}>
                        <b>{name}</b>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br key={'index'} />
              </>
            ))}
          </div>
        </div>

        {AllFFSelectedStatus && AllOptionNPSSelectedStatus ? (
          <span style={{ marginLeft: '6px' }} className='gray_button_span button_top_space' onClick={UpdateSalesOrderStatus}>
            <Box className='globalIconMainBox'>
              <img src={ContinueIcon} alt='' className={`secondary_icons_dimension ${isImageHovered ? 'hovered' : null}`} />
              <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                {' '}
                APPLY TO SELECTED COVERINGS
              </Typography>
            </Box>
          </span>
        ) : (
          <span style={{ marginLeft: '6px' }} className='gray_button_span_disabled button_top_space'>
            <Box className='globalIconMainBox'>
              <img src={ContinueIcon} className='secondary_icons_dimension_disable' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                {' '}
                APPLY TO SELECTED COVERINGS
              </Typography>
            </Box>
          </span>
        )}
        <hr className='button_top_space' />

        <Stack
          direction='column'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div align='left'>
            {salesOrderWindows.map((window, index) =>
              window.covering_status ? (
                <div key={index}>
                  {window.covering_status === 'applied' ? (
                    <>
                      <Box id={index} className='sales_order_main_div'>
                        <Checkbox color='primary' checked={checkedState[index]} onChange={(e) => handleOnChange(index, e, window)} sx={{ width: '5%', color: 'black' }} />
                        <Box sx={{ width: '100%', display: 'flex' }} onClick={() => accordianOpenCloseFun(index, window)}>
                          <Typography className='sales_order_window_name'>
                            Window Name : <span style={{ fontWeight: 'bold' }}> {window.window_name}</span>
                          </Typography>

                          <ArrowDropUpIcon
                            sx={{
                              marginTop: '10px',
                              transform: accordianopen[index] ? 'none' : 'rotate(180deg)', // Adjusted syntax
                            }}
                            className='sales_order_window_name_dropdown'
                          />
                        </Box>
                      </Box>

                      {!accordianopen[index] ? (
                        <Typography
                          sx={{
                            paddingTop: '-20px',
                            textAlign: 'start',
                            paddingLeft: '30px',
                          }}
                        >
                          {valConfirmation[index] ? (
                            <span>
                              <img src={MiscCheckIcon} className='misc_icons_dimensions' alt='MiscCheckIcon' />
                              Confirmed
                            </span>
                          ) : (
                            <span>- - Not Confirmed</span>
                          )}
                        </Typography>
                      ) : (
                        <WindowDetails isDisabled={isConfirmButtonDisabled(window)} window={window} checked={checkedStateConfirmation[index]} onChange={(e) => handleOnChangeConfirmation(index, e, window)} />
                      )}
                    </>
                  ) : null}
                </div>
              ) : null
            )}
          </div>
        </Stack>

        <hr className='button_top_space button_bottom_space' />

        {salesOrderWindows.length === confirmedWindows.length ? (
          <span style={{ marginLeft: '1px' }} className='button_span button_bottom_space' onClick={handleConfirmSalesOrder} onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
            <Box className='globalIconMainBox globalMarginLeftAddWindow'>
              {isHovered ? <img className='primary_icons_dimension_1' src={proposalAddHovIcon} alt='proposalAddHovIcon' /> : <img className='primary_icons_dimension_1' src={proposalAddIcon2} alt='proposalAddIcon2' />}
              <Typography sx={{ fontFamily: 'RobotoLight' }} onMouseEnter={handleHover2} className='globalText' onMouseLeave={handleHover2}>
                CONFIRM & ADD TO S.O.
              </Typography>
            </Box>
          </span>
        ) : (
          <span style={{ marginLeft: '1px' }} className='button_span_disabled button_top_space button_bottom_space'>
            <Box className='globalIconMainBox globalMarginLeftAddWindow'>
              <img src={proposalAddIcon2} alt='proposalAddIcon2' className='primary_icons_dimension_disable' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                CONFIRM & ADD TO S.O.
              </Typography>
            </Box>
          </span>
        )}

        <span className='gray_button_span button_top_space' onClick={() => navigate(-1)} style={{ paddingBottom: '30px', paddingLeft: '24px' }}>
          <Box className='globalIconMainBox'>
            <img src={CancelIcon1} className={`secondary_icons_dimension ${isImageHovered3 ? 'hovered' : ''}`} alt='CancelIcon1' />
            <Typography onMouseEnter={handleHover3} onMouseLeave={handleHover3} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
              CANCEL
            </Typography>
          </Box>
        </span>
      </ThemeProvider>
    </div>
  );
}
