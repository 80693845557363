import { Switch as MuiSwitch } from '@mui/material';
import { styled } from '@mui/system';

const Switch = styled(MuiSwitch)(({ theme }) => ({
  width: 70,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(4px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(32px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#00b6be',
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#d1d3d4',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#d1d3d4',
    border: '2px solid #fff',
    boxShadow: 'none',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#e6e7e8',
    borderRadius: 20 / 2,
    border: '2px solid #fff',
    width: '10rem',
  },
}));

export default Switch;
