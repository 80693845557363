import React from 'react';
import { Grid, Box } from '@mui/material';

const CustomerDetails = ({ date, customer, proposal: { sales_rep, proposal_name, proposal_display_id }, customStyles }) => {
  const formatPhoneNumber = (phoneNumber) => {
    const phone = phoneNumber.replace(/[^\d]/g, '');

    return phone.length === 10 ? `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}` : null;
  };
  const customerName = customer.first_name === 'NA' ? customer.last_name : `${customer.first_name} ${customer.wife === 'NA' ? '' : `& ${customer.wife}`} ${customer.last_name}`;
  // const customerAddress = customer.address_line !== "NA"? `${customer.address_line} ${customer.city !== "NA" ? customer.city : null}, ${customer.state !== "NA" ? customer.state.toUpperCase() : null} ${customer.zip !== "NA" ? customer.zip : null}` : null;

  return Object.keys(customer).length ? (
    <Grid item md={5}>
      <Box sx={{ padding: '20px 25px' }}>
        <Grid container direction='row' spacing={0} style={customStyles.sectionRow}>
          <Grid item xs={4} style={customStyles.headingText}>
            Date :{' '}
          </Grid>
          <Grid item xs={8} style={customStyles.rowTextCopy}>
            {date}
          </Grid>
        </Grid>
        <Grid container direction='row' spacing={0} style={customStyles.sectionRow}>
          <Grid item xs={4} style={customStyles.headingText}>
            Customer :{' '}
          </Grid>
          <Grid item xs={8} style={customStyles.rowTextCopy}>
            {customerName}
          </Grid>
        </Grid>
        <Grid container direction='row' spacing={0} style={customStyles.sectionRow}>
          <Grid item xs={4} style={customStyles.headingText}>
            Address :
          </Grid>
          <Grid item xs={8} style={customStyles.rowTextCopy}>
            {customer.address_line}
          </Grid>
        </Grid>
        {customer.address_second_line ? (
          <Grid container direction='row' spacing={0} style={customStyles.sectionRow}>
            <Grid item xs={4} style={customStyles.headingText}>
              Address Line 2 :
            </Grid>
            <Grid item xs={8} style={customStyles.rowTextCopy}>
              {customer.address_second_line}
            </Grid>
          </Grid>
        ) : null}
        <Grid container direction='row' spacing={0} style={customStyles.sectionRow}>
          <Grid item xs={4} style={customStyles.headingText}>
            City, State, Zip :{' '}
          </Grid>
          <Grid item xs={8} style={customStyles.rowTextCopy}>{`${customer.city !== 'NA' ? customer.city : null}, ${customer.state !== 'NA' ? customer.state.toUpperCase() : null} ${customer.zip !== 'NA' ? customer.zip : null}`}</Grid>
        </Grid>
        <Grid container direction='row' spacing={0} style={customStyles.sectionRow}>
          <Grid item xs={4} style={customStyles.headingText}>
            Phone :{' '}
          </Grid>
          <Grid item xs={8}>
            {customer.mobile.map((phone, index) => (
              <Grid key={index} container direction='row' spacing={0}>
                {phone.type ? (
                  <Grid item xs={3}>
                    <div style={customStyles.headingText}>{phone.type} : </div>
                  </Grid>
                ) : null}
                <span>
                  <Grid key={index} item xs={12} style={customStyles.rowTextCopy}>
                    {formatPhoneNumber(phone['#'])}
                  </Grid>
                </span>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container direction='row' spacing={0} style={customStyles.sectionRow}>
          <Grid item xs={4} style={customStyles.headingText}>
            Email :
          </Grid>
          <Grid item xs={8}>
            {customer.emails.map((email, index) => (
              <Grid key={index} container direction='row' spacing={0}>
                {email.email_type ? (
                  <Grid style={customStyles.headingText} item xs={3} md={3}>
                    {email.email_type}
                  </Grid>
                ) : null}
                <Grid item style={customStyles.rowTextCopy} xs={12}>
                  {email.email_id}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <br />
        <Grid container direction='row' spacing={0} style={customStyles.sectionRow}>
          <Grid item xs={4} style={customStyles.headingText}>
            Consultant :
          </Grid>
          <Grid item xs={8} style={customStyles.rowTextCopy}>
            {sales_rep}
          </Grid>
        </Grid>
        <Grid container direction='row' spacing={0} style={customStyles.sectionRow}>
          <Grid item xs={4} style={customStyles.headingText}>
            Proposal Name :
          </Grid>
          <Grid item xs={8} style={customStyles.rowTextCopy}>
            {proposal_name}
          </Grid>
        </Grid>
        <Grid container direction='row' spacing={0} style={customStyles.section_row_Display_Id}>
          <Grid item xs={4} style={customStyles.headingText}>
            Proposal ID :
          </Grid>
          <Grid item xs={6} style={customStyles.rowTextCopy}>
            {proposal_display_id}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  ) : null;
};
export default CustomerDetails;
