import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HeaderLogoIcon from '../../assets/icons/secondary/hdft-TBM-logo.svg';
import headerBack from '../../assets/icons/primary/header-return.svg';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CONFIG from '../../config/config.js';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import moment from 'moment/moment';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import fracty from 'fracty';
import Grid from '@mui/material/Grid';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  width: 300,
  color: theme.palette.text.secondary,
}));

const vertical = 'top';
const horizontal = 'center';

const ViewProposalPricingBasis = () => {
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');
  const [proposalID, setProposalId] = useState('');
  const [ProposalData, setProposalData] = useState([]);
  const [proposalDetails, setproposalDetails] = useState([]);
  const [ShowData, setShowData] = useState(false);
  const [errormsg, setErrorMsg] = React.useState(false);
  const [success, setSuccess] = useState(false);
  const [WindowDepth, setWindowDepth] = useState([]);
  const [ProposalRetailPrice, setProposalRetailPrice] = useState('');
  const [Accessoriescharge, setAccessoriesCharge] = useState([]);
  const [InstallationCost, setInstallationCost] = useState('');
  const [Servicescharge, setServicesCharge] = useState([]);
  const [Discount, setDiscount] = useState('');
  const [DiscountDollar, setDiscountDollar] = useState('');
  const [addtionalCharge, setAddtionalCharge] = useState([]);
  const [Tax, setTax] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [TotalCost, setTotalCost] = useState('');
  const [uniqueProposalData, setuniqueProposalData] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const getData = async () => {
    await fetch(`${CONFIG.API_URL}/pm/ProposalPricingBasisData/${proposalID}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        const filteredAddData = [];
        let serviceDetails = [];
        let accessoriesDetails = [];

        if (!data.error) {
          setShowData(true);
          setSuccess(true);

          setCustomerName(data.Customer[0].first_name + ' ' + data.Customer[0].last_name);
          setCustomerAddress((data.Customer[0].address_line || '') + '' + (data.Customer[0].address_second_line || ''));
          setproposalDetails(data.Proposal);
          setProposalData(data.ProposalPricingBasisByProposalID[0]);
          setWindowDepth(data.ProposalDetails);
          setProposalRetailPrice(data.Proposal.proposal_retail_price);
          setTotalCost(data.Proposal.sub_total);
          setTax(data.Proposal.sales_tax);
          setDiscountDollar(data.Proposal.discount_dollar);
          setInstallationCost(data.Proposal.install_total);
          setDiscount(data.Proposal.discount);

          let showAddtionalPricedata = data.proposal_level_accessory_service_charges;
          showAddtionalPricedata
            .filter((item) => item.charge_type === 'AD')
            .forEach((item) => {
              filteredAddData.push(item);
            });

          setAddtionalCharge(filteredAddData);

          showAddtionalPricedata.forEach((charge) => {
            if (charge.charge_type === 'X') {
              accessoriesDetails.push(charge);
            }
            if (charge.charge_type === 'S') {
              serviceDetails.push(charge);
            }
          });
          if (data.ProposalDetails) {
            const uniqueArray = [];
            const uniqueNames = new Set();
            const originalArray = data.ProposalDetails;
            originalArray.forEach((item) => {
              if (!uniqueNames.has(item.label)) {
                uniqueNames.add(item.label);
                uniqueArray.push(item);
              }
              setuniqueProposalData(uniqueArray);
            });
          }

          setServicesCharge(serviceDetails);
          setAccessoriesCharge(accessoriesDetails);
        }
        if (data.error === true) {
          setShowData(false);
          setErrorMsg(true);
          setErrorMessage(data.error_message);
        }
      });
  };

  const GetProposalID = (e) => {
    setProposalId(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    getData();
  };

  const handleErrorClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMsg(false);
    setSuccess(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });
  let upchargeValue;
  let discountValue;

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell
            className='table-headerwsname sticky-columnws-2'
            style={{
              borderRight: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
              borderLeft: '1px solid #ccc',
            }}
          >
            <span>#</span>

            <span style={{ marginLeft: '30px' }}>Window Name</span>
          </TableCell>
          <TableCell
            className='wide-cell'
            style={{
              borderRight: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
            }}
            align='center'
          >
            Size
          </TableCell>
          <TableCell
            className='wide-cell'
            style={{
              borderRight: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
            }}
            align='center'
          >
            CIN
          </TableCell>
          <TableCell
            className='wide-cell ellipsis2'
            style={{
              borderRight: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
            }}
            align='center'
          >
            Catalog Price
            <Tooltip placement='top' title='Price of SKU as per the window size from the catalog'>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell
            className='wide-cell ellipsis2'
            style={{
              borderRight: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
            }}
            align='center'
          >
            COGS
            <Tooltip placement='top' title='Catalog Price * Label cost factor'>
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell
            className='wide-cell ellipsis2'
            style={{
              borderRight: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
            }}
            align='center'
          >
            Retail
            <span>
              <Tooltip placement='top' title='[(Catalog_price x label_cost_factor) x label_margin] x Global_margin'>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </span>
          </TableCell>
          <TableCell
            className='wide-cell ellipsis2'
            style={{
              borderRight: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
            }}
            align='center'
          >
            Suggested Price
            <span>
              <Tooltip placement='top' title='Retail_price + (Retail_price * upcharge_percentage/100)'>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </span>
          </TableCell>

          <TableCell
            className='wide-cell ellipsis2'
            style={{
              borderRight: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
            }}
            align='center'
          >
            Discount
            <span>
              <Tooltip placement='top' title='Suggested_Price * discount_percentage/100'>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </span>
          </TableCell>
          <TableCell
            className='wide-cell ellipsis2'
            style={{
              borderRight: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
            }}
            align='center'
          >
            Suggested Price After Discount
            <span>
              <Tooltip placement='top' title='Suggested_Price - Discount'>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </span>
          </TableCell>
          <TableCell
            className='wide-cell ellipsis2'
            style={{
              borderRight: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
            }}
            align='center'
          >
            Install
            <span>
              <Tooltip placement='top' title='Suggested Price After Discount * label_install_cost/100'>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </span>
          </TableCell>
          <TableCell
            className='wide-cell ellipsis2'
            style={{
              borderRight: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
            }}
            align='center'
          >
            Additional SKUs Charge
            <span>
              <Tooltip placement='top' title='Sum of Additional SKUs Price'>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </span>
          </TableCell>
          <TableCell
            className='wide-cell ellipsis2'
            style={{
              borderRight: '1px solid #ccc',
              borderBottom: '1px solid #ccc',
              borderTop: '1px solid #ccc',
            }}
            align='center'
          >
            Total
            <span>
              <Tooltip placement='top' title='Suggested Price After Discount + Install + Additional Charge'>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </span>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
  const useStyles = makeStyles(() => ({
    coloredTooltip: {
      backgroundColor: 'blue', // Change this to the desired color
      color: 'white', // Change this to the desired text color
    },
  }));

  const classes = useStyles();

  return (
    <div align='center'>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={errormsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setErrorMsg(false)}>
          <Alert className='Error mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel'>
                <img onClick={handleErrorClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='CANCELICONRED' />
              </div>
              <div className='alertMain'>
                <img src={RedExclamationIcon} className='primary_icons_dimension' alt='CONFIRMICONRED' />
                <div align='center' style={{ paddingTop: 10 }}>
                  {errorMessage}
                </div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={success} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setSuccess(false)}>
          <Alert className='Success mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel'>
                <img onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='CANCELICON' />
              </div>
              <div className='alertMain'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='CONFIRMICON' />

                <div align='center' style={{ paddingTop: 10 }}>
                  Success !
                </div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar className='jsonconverter_header'>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ paddingLeft: '10px' }}>
              <img onClick={() => navigate('/tbm/ps/dashboard')} src={HeaderLogoIcon} alt='Logo' className='logout_icon_dimension drawer_cancel_cursor' />
            </Typography>
            <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
              TBM-DB-TOOLS
            </Typography>

            <Typography sx={{ paddingLeft: '10px' }}>
              <img onClick={() => navigate(-1)} src={headerBack} alt='Logo' className='dbtools_backbuttton ' />
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <br />
      <br />
      <br />
      <br />
      <h2 style={{ color: 'black', fontWeight: 'bold' }}>View Proposal Price Basis</h2>

      <Box className='sku_Input_Sub_Box_width_Before'>
        <form onSubmit={handleSubmit}>
          <TextField className='input_field' sx={{ input: { textAlign: 'center', width: '230px' } }} label='Enter Proposal Id' id='outlined-size-small' size='small' value={proposalID} onChange={GetProposalID} />
          <br />
          {proposalID ? (
            <Button className='search_Button' variant='contained' type='submit'>
              Search
            </Button>
          ) : (
            <Button disabled className='search_Button' variant='contained' type='submit'>
              Search
            </Button>
          )}
        </form>
      </Box>
      <br />

      {ShowData ? (
        <h3
          style={{
            textAlign: 'left',
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontWeight: 'bold',
          }}
        >
          Proposal Header Details :
        </h3>
      ) : null}
      <br />
      {ShowData ? (
        <>
          {proposalDetails.map((element) => {
            upchargeValue = element.upcharge;
            discountValue = element.discount;

            const dateObject = moment(element.entry_date);
            const formattedMonth = dateObject.format('MMMM');
            const formattedDate = dateObject.format('DD');
            const formattedYear = dateObject.format('YYYY');
            return (
              <Box align='start' className='proposal_price_basis_Main_Box'>
                <Box className='proposal_price_Type_Status_width'>
                  <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={1}>
                    <Item className='sku_type_status_brand_product_label'>
                      Proposal ID {<br />}
                      <span className='sku_page_Font_weight'>{element.proposal_id}</span>
                    </Item>
                    <Item className='sku_type_status_brand_product_label'>
                      Display ID {<br />}
                      <span className='sku_page_Font_weight'>{element.proposal_display_id}</span>
                    </Item>
                    <Item className='sku_type_status_brand_product_label'>
                      Created By {<br />}
                      <span className='sku_page_Font_weight'>{element.sales_rep}</span>
                    </Item>
                    <Item className='sku_type_status_brand_product_label'>
                      Created Date {<br />}
                      <span className='sku_page_Font_weight'>
                        {formattedDate} {formattedMonth} {formattedYear}
                      </span>
                    </Item>
                    <Item className='sku_type_status_brand_product_label'>
                      Customer Name {<br />}
                      <span className='sku_page_Font_weight'>{customerName}</span>
                      {<br />}
                      Customer Address {<br />}
                      <span className='sku_page_Font_weight'>{customerAddress}</span>
                    </Item>
                    <Item className='sku_type_status_brand_product_label'>
                      Proposal Total {<br />}
                      <span className='sku_page_Font_weight'>${element.sub_total}</span>
                    </Item>
                  </Stack>
                  <br />
                  <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={1}>
                    <Item className='sku_type_status_brand_product_label'>
                      Global Margin {<br />}
                      <span className='sku_page_Font_weight'>{ProposalData.pricing_basis.globel_margin}</span>
                    </Item>
                    <Item className='sku_type_status_brand_product_label'>
                      Discount {<br />}
                      <span className='sku_page_Font_weight'>{element.discount}%</span>
                    </Item>
                    <Item className='sku_type_status_brand_product_label'>
                      Commission {<br />}
                      <span className='sku_page_Font_weight'>{element.commission}%</span>
                    </Item>
                    <Item className='sku_type_status_brand_product_label'>
                      Upcharge / Designer Charge {<br />}
                      <span className='sku_page_Font_weight'>{element.upcharge}%</span>
                    </Item>
                    <Item className='sku_type_status_brand_product_label'>
                      Install Requested {<br />}
                      <span className='sku_page_Font_weight'>{element.install_requested.toString()}</span>
                    </Item>
                    <Item className='sku_type_status_brand_product_label'>
                      Fixed Price Calculation {<br />}
                      <span className='sku_page_Font_weight'>{element.target_price_option}</span>
                    </Item>
                  </Stack>
                </Box>
              </Box>
            );
          })}
        </>
      ) : null}
      <br />

      <br />
      {ShowData ? <hr style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }} /> : null}
      <br />
      {ShowData ? (
        <>
          <h3
            style={{
              textAlign: 'left',
              width: '80%',
              marginLeft: 'auto',
              marginRight: 'auto',
              fontWeight: 'bold',
            }}
          >
            Window Details :
          </h3>
          <br />
        </>
      ) : null}

      {ShowData ? (
        <Box className='proposal_table_main_box button_top_space' sx={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
          <Box className='proposal_table_sub_box'>
            <Box className='table-box-1'>
              <TableContainer className='table_padding_bottom' sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Table className='proposal_table' aria-labelledby='tableTitle' size='small'>
                  <EnhancedTableHead />
                  <TableBody>
                    {WindowDepth.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            className='sticky-columnws-2'
                            align='left'
                            style={{
                              borderRight: '1px solid #ccc',
                              borderBottom: '1px solid #ccc ',
                              borderLeft: '1px solid #ccc',
                            }}
                          >
                            <span
                              style={{
                                paddingRight: `${index + 1 > 9 ? '20px' : '30px'}`,
                              }}
                            >
                              {index + 1}
                            </span>
                            <span>{item.window_name}</span>
                          </TableCell>

                          <TableCell
                            className='wide-cell  ellipsis2'
                            align='left'
                            style={{
                              borderRight: '1px solid #ccc',
                              borderBottom: '1px solid #ccc',
                            }}
                          >
                            {fracty(item.window_width)}" x {fracty(item.window_height)}"{' '}
                            <span className={item.measurement_type.replace(/ /g, '')}>
                              {item.measurement_type === 'Quick'
                                ? '(' + 'QK' + ')'
                                : item.measurement_type === 'Shutters'
                                ? '(' + 'SH' + ')'
                                : item.measurement_type === 'Blinds'
                                ? '(' + 'BL' + ')'
                                : '(' +
                                  item.measurement_type
                                    .split(' ')
                                    .map((word) => word[0])
                                    .join('') +
                                  ')'}
                            </span>
                          </TableCell>
                          <TableCell
                            className='wide-cell ellipsis2 '
                            align='left'
                            style={{
                              borderRight: '1px solid #ccc',
                              borderBottom: '1px solid #ccc',
                            }}
                          >
                            <Tooltip classes={{ tooltip: classes.coloredTooltip }} title={item.cin ? item.cin : 'cin not available for this Proposal id'} placement='top'>
                              <ArrowDropDownIcon />
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className='wide-cell  ellipsis2 alertTextAlign1'
                            align='left'
                            style={{
                              borderRight: '1px solid #ccc',
                              borderBottom: '1px solid #ccc',
                            }}
                          >
                            ${parseFloat(item.covering_price).toFixed(2)}
                          </TableCell>
                          <TableCell
                            className='wide-cell  ellipsis2 '
                            align='left'
                            style={{
                              borderRight: '1px solid #ccc',
                              borderBottom: '1px solid #ccc',
                            }}
                          >
                            <Tooltip
                              placement='top'
                              title={
                                <span>
                                  {item.covering_price} * {item.label_cost_factor}
                                </span>
                              }
                            >
                              <Button>${parseFloat(item.label_cost_factor * item.covering_price).toFixed(2)}</Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className='wide-cell ellipsis2'
                            align='left'
                            style={{
                              borderRight: '1px solid #ccc',
                              borderBottom: '1px solid #ccc',
                            }}
                          >
                            <Tooltip
                              placement='top'
                              title={
                                <span>
                                  [({item.covering_price} x {item.label_cost_factor}) x {item.label_margin}] x 1
                                </span>
                              }
                            >
                              <Button>${item.retail_price}</Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className='wide-cell  ellipsis2 alertTextAlign1'
                            align='left'
                            style={{
                              borderRight: '1px solid #ccc',
                              borderBottom: '1px solid #ccc',
                            }}
                          >
                            <Tooltip
                              placement='top'
                              title={
                                <span>
                                  {item.retail_price} + ({item.retail_price} x {upchargeValue}/100)
                                </span>
                              }
                            >
                              <Button>${parseFloat(item.suggested_price).toFixed(2)}</Button>
                            </Tooltip>
                          </TableCell>

                          <TableCell
                            className='wide-cell  ellipsis2 '
                            align='left'
                            style={{
                              borderRight: '1px solid #ccc',
                              borderBottom: '1px solid #ccc',
                            }}
                          >
                            <Tooltip
                              placement='top'
                              title={
                                <span>
                                  {item.suggested_price} x {discountValue}/100
                                </span>
                              }
                            >
                              <Button>-${parseFloat(item.discount_dollar).toFixed(2)}</Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className=' ellipsis2 alertTextAlign1'
                            align='left'
                            style={{
                              borderRight: '1px solid #ccc',
                              borderBottom: '1px solid #ccc',
                            }}
                          >
                            <Tooltip
                              placement='top'
                              title={
                                <span>
                                  {item.suggested_price} - {parseFloat(item.discount_dollar).toFixed(2)}
                                </span>
                              }
                            >
                              <Button>${item.suggested_price - parseFloat(item.discount_dollar).toFixed(2)}</Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className='wide-cell ellipsis2'
                            align='left'
                            style={{
                              borderRight: '1px solid #ccc',
                              borderBottom: '1px solid #ccc',
                            }}
                          >
                            <Tooltip
                              placement='top'
                              title={
                                <span>
                                  ( {item.suggested_price} - {parseFloat(item.discount_dollar).toFixed(2)}) x {item.label_install_percentage / 100}
                                </span>
                              }
                            >
                              <Button>${item.covering_install_price}</Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell
                            className='ellipsis2 alertTextAlign1'
                            align='left'
                            style={{
                              borderRight: '1px solid #ccc',
                              borderBottom: '1px solid #ccc',
                            }}
                          >
                            ${parseFloat(item.proposal_additional_sku_price).toFixed(2)}
                          </TableCell>
                          <TableCell
                            className=' ellipsis2'
                            align='left'
                            style={{
                              borderRight: '1px solid #ccc',
                              borderBottom: '1px solid #ccc',
                            }}
                          >
                            <Tooltip
                              placement='top'
                              title={
                                <span>
                                  ({item.suggested_price} - {parseFloat(item.discount_dollar).toFixed(2)}) + {parseFloat(item.covering_install_price).toFixed(2)} + {parseFloat(item.proposal_additional_sku_price).toFixed(2)}
                                </span>
                              }
                            >
                              <Button> ${parseFloat(item.proposal_total_with_install).toFixed(2)}</Button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      ) : null}
      {ShowData ? (
        <Box sx={{ width: '80%', border: '1px solid #ccc' }}>
          <Box sx={{ padding: '10px' }}>
            <Grid container direction='row' spacing={0} className='section_row_1st' sx={{ paddingLeft: '5px' }}>
              <Grid xs={9} md={9}>
                <div className='boldheadingText Coverings_Subtotal_Proposal_Details_Page button_top_space common_line_height_css textAlignProposalPricingBasis'>COVERINGS SUBTOTAL : </div>
              </Grid>
              <Grid xs={3} md={3}>
                <span className='boldheadingText Coverings_Subtotal_Proposal_Details_Page price button_top_space common_line_height_css'>{ProposalRetailPrice ? '$' + parseFloat(ProposalRetailPrice).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
              </Grid>
            </Grid>
            {Accessoriescharge.length > 0 ? (
              <Grid container direction='row' spacing={0} className='section_row_1st'>
                <Grid xs={9} md={9}>
                  <div className='rowTotalforproposal common_text_height_css common_text_height_css textAlignProposalPricingBasis'>Accessories : </div>
                  {Accessoriescharge.map((accessory, index) => {
                    return <div className='subAccessoriesAndServicesProposalPricingBasis common_line_height_css common_text_height_css'>{accessory.charge_description}</div>;
                  })}
                </Grid>

                <Grid xs={3} md={3}>
                  <div className='rowTotalforproposal'>&nbsp;</div>
                  {Accessoriescharge.map((accessory, index) => {
                    return <div className='rowTotalforproposalforAccandserv common_line_height_css common_text_height_css'>${parseFloat(accessory.charge).toFixed(2)}</div>;
                  })}
                </Grid>
              </Grid>
            ) : (
              ''
            )}

            <Grid container direction='row' spacing={0} className='section_row_1st'>
              <Grid xs={9} md={9}>
                <div className='textAlignProposalPricingBasis rowTotalforproposal common_line_height_css common_text_height_css'>Installation : </div>
              </Grid>
              <Grid xs={3} md={3}>
                <span className='rowTotalforproposal price common_line_height_css common_text_height_css'> {InstallationCost ? '$' + parseFloat(InstallationCost).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
              </Grid>
            </Grid>
            <Grid container direction='row' spacing={0} className='section_row_1st'>
              <Grid xs={9} md={9}>
                <div className='textAlignProposalPricingBasis rowTotalforproposal common_line_height_css common_text_height_css'>Discount ({Discount}%) : </div>
              </Grid>
              <Grid xs={3} md={3}>
                <span className='rowTotalforproposal price common_line_height_css common_text_height_css'>{DiscountDollar ? '- $' + parseFloat(DiscountDollar).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
              </Grid>
            </Grid>
            {Servicescharge.length > 0 ? (
              <Grid container direction='row' spacing={0} className='section_row_1st'>
                <Grid xs={9} md={9}>
                  <div className='textAlignProposalPricingBasis rowTotalforproposal common_line_height_css common_text_height_css'>Services : </div>
                  {Servicescharge.map((service, index) => {
                    return <div className='subAccessoriesAndServicesProposalPricingBasis common_line_height_css common_text_height_css'>{service.charge_description}</div>;
                  })}
                </Grid>
                <Grid xs={3} md={3}>
                  <div className='rowTotalforproposal'>&nbsp;</div>
                  {Servicescharge.map((service, index) => {
                    return <div className='rowTotalforproposalforAccandserv common_line_height_css common_text_height_css'>${parseFloat(service.charge).toFixed(2)}</div>;
                  })}
                </Grid>
              </Grid>
            ) : (
              ''
            )}

            {addtionalCharge.length > 0 ? (
              <Grid container direction='row' spacing={0} className='section_row_1st'>
                <Grid xs={9} md={9}>
                  <div className='textAlignProposalPricingBasis rowTotalforproposal common_line_height_css common_text_height_css'>Addtional Charge : </div>
                  {addtionalCharge.map((item) => {
                    return (
                      <div key={item.proposal_id} className='subAccessoriesAndServicesProposalPricingBasis common_line_height_css common_text_height_css'>
                        {item.charge_description}
                      </div>
                    );
                  })}
                </Grid>
                <Grid
                  xs={3}
                  md={3}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <div className='rowTotalforproposal'>&nbsp;</div>
                  {addtionalCharge.map((AD_charge) => {
                    return (
                      <div key={AD_charge.proposal_id} className='subAccessoriesAndServicesProposalPricingBasis common_line_height_css common_text_height_css' style={{ textAlign: 'right' }}>
                        ${parseFloat(AD_charge.charge).toFixed(2)}
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
            ) : (
              ''
            )}
            <Grid container direction='row' spacing={0} className='section_row_1st'>
              <Grid xs={9} md={9}>
                <div className='textAlignProposalPricingBasis rowTotalforproposal common_line_height_css common_text_height_css'>Tax : </div>
              </Grid>
              <Grid xs={3} md={3}>
                <span className='rowTotalforproposal price common_line_height_css common_text_height_css'>{Tax ? '$' + parseFloat(Tax).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
              </Grid>
            </Grid>

            <Grid container direction='row' spacing={0} className='section_row_1st'>
              <Grid xs={9} md={9}>
                <div className='textAlignProposalPricingBasis boldheadingText Coverings_Subtotal_Proposal_Details_Page button_bottom_space common_line_height_css common_text_height_css'>PROPOSAL TOTAL :</div>
              </Grid>
              <Grid xs={3} md={3}>
                <span className='boldheadingText Coverings_Subtotal_Proposal_Details_Page price common_line_height_css common_text_height_css'> {TotalCost ? '$' + parseFloat(TotalCost).toFixed(2) : <Skeleton style={{ width: 50 }} />}</span>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : null}
      <br />
      {ShowData ? <hr style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }} /> : null}

      <br />
      {ShowData ? (
        <h3
          style={{
            textAlign: 'left',
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontWeight: 'bold',
          }}
        >
          Pricing Basis Details :
        </h3>
      ) : null}
      {ShowData ? (
        <Box align='start' className='proposal_price_basis_Main_Box' sx={{ marginTop: '20px', marginBottom: '50px' }}>
          {uniqueProposalData.map((item, index) => {
            return (
              <>
                <Box className='proposal_price_Type_Status_width'>
                  <Stack direction='row' divider={<Divider orientation='vertical' flexItem />} spacing={2}>
                    <Item className='proposal_pricing_basis_item'>
                      Label {<br />}
                      <span className='sku_page_Font_weight'>{item.label}</span>
                    </Item>
                    <Item className='proposal_pricing_basis_item'>
                      Label Cost Factor {<br />}
                      <span className='sku_page_Font_weight'>{item.label_cost_factor}</span>
                    </Item>
                    <Item className='proposal_pricing_basis_item'>
                      Label Install Percentage {<br />}
                      <span className='sku_page_Font_weight'>{item.label_install_percentage}</span>
                    </Item>
                    <Item className='proposal_pricing_basis_item'>
                      Label Margin {<br />}
                      <span className='sku_page_Font_weight'>{item.label_margin}</span>
                    </Item>
                  </Stack>
                </Box>
              </>
            );
          })}
        </Box>
      ) : null}

      {ShowData ? <hr style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }} /> : null}
      <br />
      {ShowData ? (
        <h3
          style={{
            textAlign: 'left',
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontWeight: 'bold',
          }}
        >
          Proposal Line Items Formulas
        </h3>
      ) : null}

      {ShowData ? (
        <Box align='start' className='proposal_price_basis_Main_Box' sx={{ marginTop: '20px', marginBottom: '50px' }}>
          <div style={{ padding: '20px 0px 30px 20px' }}>
            <Box sx={{ fontFamily: 'RobotoLight !important', fontSize: '18px' }}>
              <h5> Catalog_price = Price of SKU as per the window size from the catalog</h5>
              <h5> Retail_price = [(Catalog_price x label_cost_factor) x label_margin] x Global_margin</h5>
              <h5> Suggested_Price = Retail_price + Retail_price * upcharge_percentage/100</h5>
              <h5> Discount = Suggested_Price * discount_percentage/100</h5>
              <h5> Suggested Price After Discount = Suggested_Price - Discount</h5>
              <h5> Install = Suggested Price After Discount * label_install_cost/100</h5>
              <h5> Additional Charge = Sum of Additional SKUs Price</h5>
              <h5> Total = Suggested Price After Discount + Install + Additional Charge</h5>
            </Box>
          </div>
        </Box>
      ) : null}
      {ShowData ? <hr style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }} /> : null}
      <br />

      {ShowData ? (
        <h3
          style={{
            textAlign: 'left',
            width: '80%',
            marginLeft: 'auto',
            marginRight: 'auto',
            fontWeight: 'bold',
          }}
        >
          Proposal Total Formulas
        </h3>
      ) : null}

      {ShowData ? (
        <Box align='start' className='proposal_price_basis_Main_Box' sx={{ marginTop: '20px', marginBottom: '50px' }}>
          <div style={{ padding: '20px 0px 30px 20px' }}>
            <Box sx={{ fontFamily: 'RobotoLight !important', fontSize: '18px' }}>
              <h5> Coverings Subtotal = Sum of Suggested Price of all Line Items</h5>
              <h5> Accessories Charge = Sum of all accessories charge</h5>
              <h5> Installation = Sum of Install of all Line Items (If Installation is YES) else Installation = 0</h5>
              <h5> Discount = Sum of Discount of all Line Items</h5>
              <h5> Services Charge = Sum of all services charge</h5>
              <h5> Additional SKUs Charge = Sum of Additional SKU Charge of all Line Items</h5>
              <h5> Additional Charge = Sum of all Additional charge</h5>
              <h5> Sales Tax = [Coverings Subtotal + Additional SKUs Charge + Accessories Charge + Installation - Discount + Services Charge + Additional Charge] * sales_tax_percentage / 100 (If Installation is NO) else Sales Tax = 0</h5>
              <h5> Proposal Total = Coverings Subtotal + Additional SKUs Charge + Accessories Charge + Installation - Discount + Services Charge + Additional Charge + Sales Tax</h5>
            </Box>
          </div>
        </Box>
      ) : null}
    </div>
  );
};

export default ViewProposalPricingBasis;
