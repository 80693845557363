/** @format */

import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import CONFIG from '../../config/config.js';
import Stack from '@mui/material/Stack';
import BackspaceOutlinedIcon from '../../img/backspace2.png';
import { useNavigate } from 'react-router-dom';
import Delete from '../../img/delete-key-symbol2.png';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerMeasurementIcon from '../../assets/icons/drawer-alert/drw-measurement.svg';
import DrawerCloseIcon from '../../assets/icons/drawer-alert/close.svg';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

export default function CommonDrawerInputWidth(props) {
  const [isCommonDrawerClose, setIsCommonDrawerClose] = useState(true);
  const [result, setResult] = useState('');
  const [print, setPrint] = useState('');
  const [disable, setdisabled] = useState(true);
  const [width, setWidth] = useState('');
  const [decimalwidth, setDecimalWidth] = useState('');
  const navigate = useNavigate();
  const [viewbuttons, setViewbuttons] = useState(false);
  const [buttons, setButtons] = useState(false);
  const [match, setMatch] = useState(true);
  const [CancelGetDimesion, setCancelGetDimension] = useState('');

  const [limitDigit, setLimitDigit] = useState(false);
  const [limitFractionDigit, setLimitFractionDigit] = useState(false);

  props.func(isCommonDrawerClose, result.replace(/"/g, ''));

  useEffect(() => {
    if (result.toString().length >= 4) {
      setLimitDigit(true);
    } else {
      setLimitDigit(false);
    }

    // For Fraction
    if (result.toString().length >= 6) {
      setLimitFractionDigit(true);
    } else {
      setLimitFractionDigit(false);
    }

    if (result.toString().length === 0) {
      setLimitFractionDigit(true);
    }
  }, [result]);

  const handleClick = (e) => {
    // On clicking x button once, it'll be disabled
    if (
      e.target.name === '1/4' ||
      e.target.name === '1/2' ||
      e.target.name === '3/4' ||
      e.target.name === '1/8' ||
      e.target.name === '3/8' ||
      e.target.name === '5/8' ||
      e.target.name === '7/8' ||
      e.target.name === '1/16' ||
      e.target.name === '3/16' ||
      e.target.name === '5/16' ||
      e.target.name === '7/16' ||
      e.target.name === '9/16' ||
      e.target.name === '11/16' ||
      e.target.name === '13/16' ||
      e.target.name === '15/16'
    ) {
      let newval = ' ' + e.target.name;

      if (result.concat(newval).includes('"')) {
        const replaceResult = result.replace(/"/g, '');

        newval = replaceResult.concat(newval);
        setResult(newval + '"');
      } else {
        newval = e.target.name;
        setResult(result.concat(e.target.name) + '"');
      }
    } else {
      let newval = e.target.name;

      if (result.concat(e.target.name).includes('"')) {
        const replaceResult = result.replace(/"/g, '');
        newval = replaceResult.concat(newval);
        setResult(newval + '"');
      } else {
        newval = e.target.name;
        setResult(result.concat(e.target.name) + '"');
      }
    }
  };

  const backspace = () => {
    const lastSpaceIndex = result.lastIndexOf(' ');
    let lengthOfSubstring;
    if (lastSpaceIndex !== -1) {
      const substringAfterLastSpace = result.substring(lastSpaceIndex + 1);
      lengthOfSubstring = substringAfterLastSpace.length;
    }
    let val = result.slice(0, -2);

    if (result.includes('/')) {
      if (lengthOfSubstring === 3) {
        val = result.slice(0, -4);
        setResult(val + '"');
      } else if (lengthOfSubstring === 4) {
        val = result.slice(0, -5);
        setResult(val + '"');
      } else if (lengthOfSubstring === 5) {
        val = result.slice(0, -6);
        setResult(val + '"');
      } else if (lengthOfSubstring === 6) {
        val = result.slice(0, -7);
        setResult(val + '"');
      }
    } else {
      if (val.includes('"')) {
        const replaceResult = val.replace(/"/g, '');
        setResult(val + '"');
      } else if (val === '') {
        setResult(val);
      } else {
        setResult(val + '"');
      }
    }
  };

  // Pass data from slider to Window - Width x Height, Input field
  const printClick = (e) => {
    var string = result;
    if (string.includes('/')) {
      var newStr = string.split(' ');
      var fractionalnos = newStr[1].split('/');
      var fractiontodecimal = parseInt(newStr[0]) + parseFloat(fractionalnos[0] / fractionalnos[1]);

      setWidth(string);
      setDecimalWidth(fractiontodecimal);
    } else {
      setWidth(result);
      setDecimalWidth('');
    }
    setWidth(string);
    setPrint(result);
    setIsCommonDrawerClose(false);
  };

  const closeDrawer = (e) => {
    setIsCommonDrawerClose(false);
    setDecimalWidth('');
    setWidth('');
    if (props.AddWindow === 'AddWindow') {
      setPrint();
    } else {
      setPrint();
    }
  };

  useEffect(() => {
    if (props.othermeasurement) {
      setResult(props.othermeasurement);
    }

    getVerified();
  }, [match, CancelGetDimesion]);

  const [isConditionMet, setisConditionMet] = useState();

  useEffect(() => {
    conditionFun();
  }, []);

  const conditionFun = () => {
    if (props.measurement_type === 'Quick') {
      setisConditionMet(false);
    } else {
      setisConditionMet(true);
    }
  };

  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  const valueValidation = (e) => {
    const pattern = /^(\d{1,3})\s+(\d\/\d{1}\s+)?/;

    const resultNumeric = e.target.value.replace(/^([^0-9])/g, '');
    const newValue = resultNumeric.replace(/x/g, ' $& ');
    const newValueValue = newValue.replace(/\s+/g).trim();

    setMatch(pattern.test(newValueValue) || pattern.test(result));
    setResult(newValueValue);
  };

  const showbuttons = () => {
    setViewbuttons(true);
    setButtons(true);
  };

  const hidebuttons = () => {
    setViewbuttons(false);
    setButtons(false);
  };

  return (
    <Box>
      <Box p={3} textAlign='center' paddingTop={0}>
        <h2 className='add_specific_fontcolor button_bottom_space'>Other Measurement</h2>

        {/*------------------------ Input field---------------------------- */}
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}></Grid>
            <Grid item xs={12} md={6} sm={6}>
              <Stack className='drawer_input_width_height_text'>
                <TextField
                  id='filled-basic'
                  inputProps={{
                    className: 'width_and_height_header',
                  }}
                  value={result}
                  onChange={valueValidation}
                  variant='outlined'
                />
              </Stack>
              <Grid item xs={3}></Grid>
            </Grid>
          </Grid>
        </Box>

        <hr className='button_top_space button_bottom_space' />

        {/* Buttons arranged in stack
         * 7, 8, 9
         * 4, 5, 6
         * 1, 2, 3
         */}

        <Box sx={{ flexFlow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}></Grid>

            <Grid item xs={12} md={6} sm={6}>
              <Box sx={{ marginBottom: '20px !important' }} className='calculator_drawer_digit_box_2'>
                <Box className={isConditionMet ? 'calculator_drawer_digit_box_1' : 'calculator_drawer_digit_box'}>
                  <Stack direction='row' spacing={4} className={isConditionMet ? 'button_margin_top_calculator' : 'button_margin_top_calculator_1'}>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='7'>
                      7
                    </Button>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='8'>
                      8
                    </Button>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='9'>
                      9
                    </Button>
                  </Stack>

                  {/* Buttons arranged in stack 4-6 */}
                  <Stack direction='row' spacing={4} className={isConditionMet ? 'button_margin_top_calculator' : 'button_margin_top_calculator_1'}>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='4'>
                      4
                    </Button>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='5'>
                      5
                    </Button>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='6'>
                      6
                    </Button>
                  </Stack>

                  {/* Buttons arranged in stack 1-3 */}
                  <Stack direction='row' spacing={4} className={isConditionMet ? 'button_margin_top_calculator' : 'button_margin_top_calculator_1'}>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='1'>
                      1
                    </Button>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='2'>
                      2
                    </Button>
                    <Button disabled={limitDigit} onClick={handleClick} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='3'>
                      3
                    </Button>
                  </Stack>

                  {/* Buttons arranged in stack 0 - x */}
                  <Stack direction='row' spacing={4} className={isConditionMet ? 'button_margin_top_calculator' : 'button_margin_top_calculator_1'}>
                    <Button disabled={limitDigit} className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator' : 'drawer_input_buttons_style'} name='0' onClick={handleClick}>
                      0
                    </Button>

                    <Button
                      disabled={disable}
                      className='drawer_input_buttons_style_1 button_margin_calculator'
                      name='x'
                      style={{ opacity: '40%' }} // Set opacity to 40%
                    >
                      <img
                        src={Delete}
                        name='x'
                        className='buttonIcon'
                        style={{
                          width: '40px',
                          height: '40px',
                          position: 'absolute',
                          opacity: '40%', // Set opacity to 40%
                        }}
                        alt='delete'
                      />
                    </Button>

                    <Button className={isConditionMet ? 'drawer_input_buttons_style_1 button_margin_calculator ' : 'drawer_input_buttons_style'} onClick={backspace} id='backspace'>
                      <img
                        src={BackspaceOutlinedIcon}
                        className='buttonIcon'
                        style={{
                          width: '45px',
                          height: '40px',
                          position: 'absolute',
                        }}
                        alt='backspace'
                      />
                    </Button>
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <hr className='button_top_space' />
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} sm={12}>
              {props.measurement_type === 'Quick' || props.measurement_type === '' ? (
                ''
              ) : (
                <div>
                  {/* Buttons arranged in stack 3/4 - 1/4 */}
                  <div style={{ marginTop: '20px', paddingBottom: '20px' }} className='measurement_fraction_main'>
                    <span>
                      <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_input_buttons_style2' name='1/4' sx={{ margin: '10px' }}>
                        1/4
                      </Button>
                      <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_input_buttons_style2' name='1/2' sx={{ margin: '10px' }}>
                        1/2
                      </Button>

                      <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_input_buttons_style2' name='3/4' sx={{ margin: '10px' }}>
                        3/4
                      </Button>
                    </span>
                    <span style={{ marginLeft: '60px' }}></span>

                    {/* Buttons arranged in stack 1/8 - 7/8 */}
                    <span>
                      <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_input_buttons_style2' name='1/8' sx={{ margin: '10px' }}>
                        1/8
                      </Button>
                      <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_input_buttons_style2' name='3/8' sx={{ margin: '10px' }}>
                        3/8
                      </Button>
                      <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_input_buttons_style2' name='5/8' sx={{ margin: '10px' }}>
                        5/8
                      </Button>
                      <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_input_buttons_style2' name='7/8' sx={{ margin: '10px' }}>
                        7/8
                      </Button>
                    </span>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </Box>

        <hr className='' />
        {/* ---------------------------Fraction_buttons------------------------ */}

        {!(props.measurement_type === 'Quick' && props.measurement_type === '') ? (
          <>
            <Box sx={{ padding: '20px 8px 20px 8px !important' }} className='drawer_iconbutton' aria-label='edit'>
              {' '}
              1/16ths
              {viewbuttons ? <ArrowDropUpIcon onClick={hidebuttons} fontSize='medium' sx={{ position: 'relative', top: '5px' }} /> : <ArrowDropDownIcon onClick={showbuttons} fontSize='medium' sx={{ position: 'relative', top: '5px' }} />}
            </Box>
            {buttons ? (
              <>
                <Box sx={{ flexGrow: 1 }} className=''>
                  <span>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='1/16'>
                      1/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='3/16'>
                      3/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='5/16'>
                      5/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='7/16'>
                      7/16
                    </Button>
                  </span>

                  <span>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='9/16'>
                      9/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='11/16'>
                      11/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='13/16'>
                      13/16
                    </Button>
                    <Button disabled={limitFractionDigit} onClick={handleClick} className='drawer_iconbutton' name='15/16'>
                      15/16
                    </Button>
                  </span>
                </Box>
              </>
            ) : (
              ' '
            )}
            <hr className='' />
          </>
        ) : null}

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={3}></Grid>

            <Grid item xs={12} md={6} sm={6}>
              <span onClick={printClick} className='drawer_gray_button_span button_top_space' style={{ paddingBottom: '0px', paddingLeft: '0px' }}>
                <Box className='globalIconMainBox'>
                  <img src={DrawerMeasurementIcon} className='primary_icons_dimension' />
                  <Typography
                    sx={{
                      fontFamily: 'RobotoLight',
                      textAlign: 'start',
                      width: '290px',
                    }}
                    className='drawerGlobalText'
                  >
                    APPLY MEASUREMENT
                  </Typography>
                </Box>
              </span>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={3}></Grid>

            <Grid item xs={12} md={6} sm={6}>
              <span className='drawer_gray_button_span button_top_space' onClick={closeDrawer} style={{ paddingBottom: '30px', paddingLeft: '14px' }}>
                <Box className='globalIconMainBox'>
                  <img src={DrawerCancelIcon} className='drawer_secondary_icons_dimension' alt='drawerCancelIcon' />
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
                    CANCEL
                  </Typography>
                </Box>
              </span>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
