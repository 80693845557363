import React from "react";
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import Save from "../../img/save.png";


export default function AddNoteDrawer(props) {
  const [isDrawerClose, setIsDrawerClose] = React.useState(true);

  const closeDrawer = () => {
    setIsDrawerClose(false);
  };
  props.func(isDrawerClose);

  return (
    <Box p={4} pt={0}>
      <div align="right" className="drawer_backbutton">
        <IconButton>
          <ArrowCircleRightIcon
            sx={{ color: "white" }}
            fontSize="large"
            onClick={closeDrawer}
          />
        </IconButton>
      </div>
      <Stack direction="column" spacing={2}>
        <Box>
          <Typography sx={{ fontSize: "30px" }}>Notes</Typography>
          <Typography>{props.WindowSetName}</Typography>
          <Box></Box>
        </Box>

        <Button
          variant="contained"
          className="edit_drawer_button button_top_space"
        >
          <img src={Save} className="drawer_iconsize" alt="Save" />
          note
        </Button>
      </Stack>
    </Box>
  );
}
