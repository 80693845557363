/** @format */

import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import CONFIG from '../../config/config.js';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import EditMiscIcon from '../../assets/icons/misc/misc-edit.svg';
import EditCancelIcon from '../../assets/icons/misc/2drw-cancel.svg';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import AddIcon from '../../assets/icons/secondary/2nd-add.svg';
import ReturnIcon from '../../assets/icons/primary/return.svg';
import ReturnHovIcon from '../../assets/icons/primary/WithHovIcon/return-hov.svg';

const Customer_Address = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let locationState = [];
  let Address_Id;
  let CustomerVal;
  let LnameVal;
  let FnameVal;
  let EmailVal;
  let StateNameVal;
  let cityVal;
  let ZipVal;
  let AddressLineVal;
  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    locationState = location.state;
    CustomerVal = location.state.Custdata.custid;
    Address_Id = location.state.Custdata.addressid;
    LnameVal = location.state.Custdata.l_name;
    FnameVal = location.state.Custdata.f_name;
    EmailVal = location.state.Custdata.email_id;
    StateNameVal = location.state.Custdata.state;
    cityVal = location.state.Custdata.city;
    ZipVal = location.state.Custdata.zip;
    AddressLineVal = location.state.Custdata.address_line;
  }

  const [showButton, setshowButton] = useState(false);
  const [customerAddress, setCustomerAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (location.state !== null) {
      getCustomerDetails();
    } else {
      navigate('/pagenotfound');
    }
  }, []);

  //--------------------------------------------------handling_Events & Functions-------------------------------------------
  const handleCancel = (e) => {
    navigate('/tbm/ps/customerdetails', {
      state: {
        Custdata: {
          custid: locationState.Custdata.custid,
          Addressid: locationState.Custdata.addressid,
        },
      },
    });
  };

  const SelectAddress = async () => {
    const token = sessionStorage.getItem('token');
    await fetch(CONFIG.API_URL + '/cm/SetCurrentAddress', {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        customer_id: locationState.Custdata.custid,
        address_id: selectedAddress,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        navigate('/tbm/ps/customerdetails', {
          state: {
            Custdata: {
              custid: locationState.Custdata.custid,
              Addressid: selectedAddress,
            },
          },
        });
      });
  };

  const getCustomerDetails = async () => {
    const token = sessionStorage.getItem('token');
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        customer_id: CustomerVal,
        address_id: Address_Id,
        f_name: FnameVal,
        l_name: LnameVal,
        email_id: EmailVal,
        state_name: StateNameVal,
        city: cityVal,
        zip: ZipVal,
        address_line: AddressLineVal,
      }),
    };
    await fetch(`${CONFIG.API_URL}/cm/Customer`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        const billingaddress = data.customer;
        billingaddress.map((item) => {
          if (item.is_billing === true) {
          } else {
          }
        });
        setCustomerAddress(data.customer);
      });
  };

  const handleChange = (e) => {
    setshowButton(true);

    setSelectedAddress(e.target.value);
  };

  const handlebillingchange = (e, address_id) => {
    const token = sessionStorage.getItem('token');

    if (e.target.checked) {
      fetch(CONFIG.API_URL + '/cm/SetBillingAddress', {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          token: token,
        },
        body: JSON.stringify({
          customer_id: locationState.Custdata.custid,
          address_id: address_id,
          status: true,
        }),
      })
        .then((data) => data.json())
        .then((data) => {});
    } else {
      fetch(CONFIG.API_URL + '/cm/SetBillingAddress', {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          token: token,
        },
        body: JSON.stringify({
          customer_id: locationState.Custdata.custid,
          address_id: address_id,
          status: false,
        }),
      })
        .then((data) => data.json())
        .then((data) => {});
    }
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  const [isImageHovered3, setIsImageHovered3] = useState(false);
  const handleHover3 = () => {
    setIsImageHovered3(!isImageHovered3);
  };

  //-------------------------------------------------------------------------------------------------------------------------
  return (
    <div>
      <br />
      <Typography className='button_bottom_space' sx={{ fontWeight: 'bold' }}>
        Customer Address
      </Typography>
      {customerAddress.length === 0 ? (
        <Paper className='customer_details_paper' sx={{ marginBottom: '20px !important' }}>
          <Stack direction='row' alignItems='center' gap={1}>
            <Typography className='customer_address' sx={{ marginLeft: '20px' }}>
              <Skeleton width={200} height={30} />
            </Typography>
            <img src={EditMiscIcon} className='misc_icons_dimensions' alt='EditMiscIcon' />
            <img src={EditCancelIcon} className='misc_icons_dimensions' alt='EditCancelIcon' />
          </Stack>
        </Paper>
      ) : null}
      <form>
        <>
          {customerAddress.map((item, index) => {
            return (
              <>
                <Paper className='customer_details_paper' sx={{ marginBottom: '20px !important' }} key={index}>
                  <Stack direction='row' alignItems='center' gap={1}>
                    <Box className='customer_address' sx={{ marginLeft: '20px' }}>
                      {' '}
                      <span style={{ textTransform: 'capitalize' }}>
                        {' '}
                        {`#${index + 1}`} - {item.address_description || 'Address'}:{' '}
                      </span>
                      <br />
                      <span>{item.address_line}</span>
                      <br />
                      <span>{item.address_second_line}</span> {item.city === '' ? '' : <span style={{ textTransform: 'capitalize' }}>{item.city}, </span>}
                      <span style={{ textTransform: 'uppercase' }}>{item.state} </span>
                      {item.zip}{' '}
                    </Box>
                    <Box className='edit_close_box'>
                      <img
                        onClick={() => {
                          navigate('/tbm/ps/editcustomeraddress', {
                            state: {
                              Address1: item.address_line,
                              city: item.city,
                              state: item.state,
                              zip: item.zip,
                              country: item.Country,
                              custid: item.customer_id,
                              addressid: item.address_id,
                              Address2: item.address_second_line,
                              email: item.email_id,
                              discription: item.address_description,
                              is_billing: item.is_billing,
                            },
                          });
                        }}
                        src={EditMiscIcon}
                        className='misc_icons_dimensions'
                        alt='EditMiscIcon'
                      />

                      <img src={EditCancelIcon} onClick={handleCancel} className='misc_icons_dimensions' alt='cancelIcon' />
                    </Box>
                  </Stack>
                  <Box className='View_this_address'>
                    <Checkbox
                      className='billing_address_input'
                      sx={{
                        [`&, &.${checkboxClasses.checked}`]: {
                          color: '#232323d1',
                        },
                      }}
                      value={item.address_id}
                      onChange={(e) => handlebillingchange(e, item.address_id)}
                      type='checkbox'
                      name='Checkbillingaddress'
                      defaultChecked={item.is_billing === true ? 'checked' : ''}
                    />{' '}
                    <Typography className='billing_address'>Billing Address</Typography>
                  </Box>
                  <Box className='View_this_address'>
                    <input className='view_this_address_input' type='radio' color='default' value={item.address_id} name='ViewThisAddress' onChange={handleChange} defaultChecked={item.is_current === true ? 'checked' : ''} />{' '}
                    <Typography sx={{ paddingLeft: '5px' }} className='view_this_address'>
                      Use this Address
                    </Typography>
                  </Box>
                </Paper>
              </>
            );
          })}
          <Box
            onClick={() =>
              navigate('/tbm/ps/AddNewAddress', {
                state: {
                  Custdata: {
                    custId: locationState.Custdata.custid,
                    addressid: locationState.Custdata.addressid,
                  },
                },
              })
            }
            display='flex'
            alignItems='center'
            justifyContent='center'
            className='all_Button_Style_Proposal_Details_Page '
          >
            <span style={{ paddingLeft: '22px' }} className='gray_button_span'>
              <Box
                onClick={() =>
                  navigate('/tbm/ps/AddNewAddress', {
                    state: {
                      Custdata: {
                        custId: locationState.Custdata.custid,
                        addressid: locationState.Custdata.addressid,
                      },
                    },
                  })
                }
                className='box_outside_icons'
              >
                <img src={AddIcon} alt='secondaryIcon' className={`secondary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} />
              </Box>
              <span onMouseEnter={handleHover} onMouseLeave={handleHover} className='gray_button_text_1'>
                ADD NEW ADDRESS
              </span>
            </span>
          </Box>

          <hr className='button_top_space button_bottom_space' />
          <Box className='submitBox'>
            {showButton ? (
              <Button
                sx={{ padding: '0px 14px !important' }}
                onClick={() => {
                  SelectAddress();
                }}
                onMouseEnter={handleHoverFun}
                onMouseLeave={handleMouseLeaveFun}
                className='search_details_button submit_button_customername button_bottom_space'
              >
                {' '}
                <Box className='globalIconMainBox globalDrawerText'>
                  {isHovered ? <img className='primary_icons_dimension_1' src={ReturnHovIcon} alt='ReturnHovIcon' /> : <img className='primary_icons_dimension_1' src={ReturnIcon} alt='ReturnIcon' />}
                  <Typography sx={{ textAlign: 'start' }} onMouseEnter={handleHover2} onMouseLeave={handleHover2} className='globalText'>
                    RETURN TO CUSTOMER DETAILS
                  </Typography>
                </Box>
              </Button>
            ) : (
              <Button sx={{ padding: '0px 14px !important' }} disabled className='disable_submit_box_button button_bottom_space'>
                {' '}
                <Box className='globalIconMainBox'>
                  <img className='primary_icons_dimension_disable' src={ReturnIcon} alt='PrimaryIcon' />
                  <Typography sx={{ textAlign: 'start' }} className='globalTextDisabled'>
                    RETURN TO CUSTOMER DETAILS
                  </Typography>
                </Box>
              </Button>
            )}

            <span className='gray_button_span button_top_space' style={{ paddingBottom: '30px', paddingLeft: '20px' }}>
              <Box onClick={handleCancel} className='globalIconMainBox'>
                <img src={CancelIcon} alt='cancel' className={`secondary_icons_dimension ${isImageHovered3 ? 'hovered' : ''}`} />
                <Typography onMouseEnter={handleHover3} onMouseLeave={handleHover3} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  CANCEL
                </Typography>
              </Box>
            </span>
          </Box>
        </>
      </form>
    </div>
  );
};
export default Customer_Address;
