/** @format */

import React, { useEffect, useState } from 'react';
import { Button, Box, TextField, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import CircleIcon from '@mui/icons-material/Circle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CONFIG from '../../config/config.js';
import NoteIcon from '../../assets/icons/drawer-alert/dwr-note.svg';
import DeleteIcon from '../../assets/icons/drawer-alert/2drw-delete.svg';
import PhotoIcon from '../../assets/icons/drawer-alert/2drw-photo.svg';
import CancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import CloseIcon from '../../assets/icons/drawer-alert/close.svg';

export default function NotesDrawer({ details, type, editing, closeDrawer }) {
  const user = sessionStorage.getItem('user');
  const token = sessionStorage.getItem('token');

  const [isCustomerVisible, setIsCustomerVisible] = useState(false);
  const [isImageHovered, setIsImageHovered] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [noteContent, setNoteContent] = useState('');
  const [referenceId, setReferenceId] = useState('');

  useEffect(() => {
    getDepartments();
  }, []);

  const getDepartments = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/NoteTypes`, requestOptions);
    const data = await response.json();
    if (!data.error) {
      setDepartments(data.NoteType);
    }
  };

  useEffect(() => {
    getCurrentNote();
    setReferenceId(details.referenceId);
  }, [departments]);

  const getCurrentNote = async () => {
    if (!details.noteId) {
      return;
    }
    const options = {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/Note/edit/${details.noteId}`, options);
    const data = await response.json();
    if (!response.ok || data.error) {
      return;
    }

    setNoteContent(data.note.content);
    setIsCustomerVisible(data.note.visible);
    setSelectedDepartments(departments.filter((department) => data.note.departments.includes(department.id)));
  };

  const handleChangeSelectedDepartment = (department) => {
    if (selectedDepartments.map((department) => department.id).includes(department.id)) {
      setSelectedDepartments(selectedDepartments.filter((desc) => desc !== department));
    } else {
      setSelectedDepartments([...selectedDepartments, department]);
    }
  };

  const saveNote = async () => {
    const options = {
      method: editing ? 'PUT' : 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        id: editing ? details.noteId : null,
        referenceId,
        type,
        departments: selectedDepartments,
        content: noteContent,
        image_locaiton: null,
        visible: isCustomerVisible,
        created_by: user,
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/Note`, options);
    const { error, message } = await response.json();
    if (!response.ok || error) {
      return closeDrawer({ error: error.message, success: false });
    }
    closeDrawer({ error, success: message });
  };

  const deleteNote = async () => {
    const options = {
      method: 'DELETE',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/cm/note/${details.noteId}`, options);
    const { error, message } = await response.json();
    if (!response.ok || error) {
      return closeDrawer({ error: error.message, success: false });
    }
    closeDrawer({ error, success: message });
  };

  const handleHover = () => setIsImageHovered(!isImageHovered);

  return (
    <Box
      p={2}
      pt={2}
      sx={{
        color: 'white',
      }}
      className='sideDrawer'
    >
      <div align='right'>
        <img alt='Close Drawer' src={CloseIcon} onClick={closeDrawer} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
      </div>

      <Box
        p={1}
        textAlign='left'
        paddingTop={1}
        className='drawer_Header_page'
        sx={{
          fontFamily: 'RobotoLight !important',
        }}
      >
        <Typography sx={{ fontFamily: 'RobotoLight' }}>
          NOTE FOR : <span className='notes_text_font'>{details.name}</span>
        </Typography>

        <TextField
          value={noteContent}
          onChange={(e) => {
            setNoteContent(e.target.value);
          }}
          className='notes_textfield'
          label='Note :'
        />
        {/* <Box className='attach_photo_box_1'>
          <img alt='ATTACH PHOTO' src={PhotoIcon} className='secondary_icons_dimension_disable' />
          <Typography className='drawerGlobalTextDisable' sx={{ marginLeft: '12px' }}>
            ATTACH PHOTO
          </Typography>
        </Box> */}

        <hr className='button_bottom_space ' />

        <Box>
          <Typography className='notes_text_font' sx={{ textTransform: 'uppercase', marginBottom: '10px' }}>
            Who Needs To See This?
            <span style={{ color: 'red' }}>*</span>
          </Typography>

          <Box>
            {departments.map((department) => (
              <div key={department.id} className='note_content_div notes_text_font'>
                <Checkbox className='notes_checkbox' color='primary' checked={selectedDepartments.map((department) => department.id).includes(department.id)} onChange={() => handleChangeSelectedDepartment(department)} />
                <Typography className='notes_text_font'>{department.description}</Typography>
              </div>
            ))}
          </Box>
        </Box>

        {/* TODO: JD 05/01/2024 - Implement Tagging functionality; Commented out as per BW
        <hr className="button_top_space" />

        <TextField className="notes_textfield" disabled readonly placeholder="Tag Person : Functionality Not Yet Available" /> */}

        <hr className='button_top_space' />

        <Box onClick={() => setIsCustomerVisible(!isCustomerVisible)} className='common_display_flex_css button_top_space button_bottom_space'>
          <Box>
            {!isCustomerVisible ? (
              <Box className='switch_icon_before'>
                <CircleIcon className='cursor notes_color' />
                <VisibilityOffIcon sx={{ cursor: 'pointer' }} className='notes_color' />
              </Box>
            ) : (
              <Box className='switch_icon_after'>
                <VisibilityIcon sx={{ cursor: 'pointer' }} className='notes_color' />
                <CircleIcon className='cursor notes_color' />
              </Box>
            )}
          </Box>
          <Box sx={{ marginLeft: '10px' }}>
            <Typography>CUSTOMER VISIBLE</Typography>
          </Box>
        </Box>

        <hr className='button_top_space' />

        <Box className='notes_text_font' sx={{ marginLeft: '0px', marginTop: '20px' }}>
          <Button
            sx={{
              paddingLeft: '0px',
              justifyContent: 'flex-start',
            }}
            className='drawer_gray_button_span'
            disabled={!(selectedDepartments.length && noteContent)}
            onClick={saveNote}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
          >
            <Box className='globalIconMainBox globalDrawerText'>
              <img alt={editing ? 'Edit Note' : 'Save Note'} src={NoteIcon} className={selectedDepartments.length && noteContent ? `primary_icons_dimension ${isImageHovered ? 'hovered' : null}` : 'primary_icons_dimension_disable'} />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className={selectedDepartments.length && noteContent ? 'drawerGlobalText' : 'drawerGlobalTextDisable'}>
                {editing ? 'UPDATE NOTE' : 'SAVE NOTE'}
              </Typography>
            </Box>
          </Button>
          <Button
            className='drawer_gray_button_span button_top_space'
            onClick={deleteNote}
            disabled={!editing}
            sx={{
              paddingLeft: '14px',
              justifyContent: 'flex-start',
            }}
          >
            <Box className='globalIconMainBox globalDrawerText'>
              <img alt='Delete Note' src={DeleteIcon} className='drawer_secondary_icons_dimension' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className={editing ? 'drawerGlobalText' : 'drawerGlobalTextDisable'}>
                DELETE NOTE
              </Typography>
            </Box>
          </Button>
          <Button
            className='drawer_gray_button_span button_top_space'
            onClick={closeDrawer}
            sx={{
              paddingLeft: '14px',
              justifyContent: 'flex-start',
            }}
          >
            <Box className='globalIconMainBox globalDrawerText'>
              <img alt='CANCEL' src={CancelIcon} className='drawer_secondary_icons_dimension' />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
                CANCEL
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
