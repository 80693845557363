/** @format */

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import CONFIG from '../../config/config.js';
import Paper from '@mui/material/Paper';
import moment from 'moment/moment';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../assets/icons/primary/search.svg';
import SearchHovIcon from '../../assets/icons/primary/WithHovIcon/search-hov.svg';

const formatPhoneNumber = ({ pnumber }) => {
  if (pnumber === null) {
    return undefined;
  }
  const pattern = /^(\d{3})(\d{3})(\d{4})$/;
  const cleaned = pnumber.replace(/\D/g, '');
  return cleaned.replace(pattern, '($1) $2-$3');
};

function formatName({ Husband, Wife, LName }) {
  if (Husband && Wife && LName) {
    return `${Husband} & ${Wife} ${LName}`;
  } else if (Husband && !Wife && LName) {
    return `${Husband} ${LName}`;
  } else if (!Husband && Wife && LName) {
    return `${Wife} ${LName}`;
  } else if (!Husband && !Wife && LName) {
    return `${LName}`;
  } else {
    return '';
  }
}

export default function ViewAllApointments() {
  const navigate = useNavigate();
  const token = sessionStorage.getItem('token');
  const user_id = sessionStorage.getItem('user_id');
  const user = sessionStorage.getItem('user');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);
  const [Custdata, setCustdata] = useState([]);
  const [appointments, setAppointments] = useState([]);

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  const getAllAppointment = async () => {
    const response = await fetch(`${CONFIG.API_URL}/cm/GetSalesRepAppointments`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        personID: user_id,
        done: 'notdone',
      }),
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      return;
    }
    setAppointments(data.SalesRepAppointments.recordset);
    data.SalesRepAppointments.recordset.map((ele) => {
      setCustdata(ele);
    });
  };

  useEffect(() => {
    getAllAppointment();
  }, []);

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow style={{ margin: '0px' }}>
          <TableCell className='table-header-cd-proposal1 font_weight_bold'>Date</TableCell>
          <TableCell className='table-headersw3 font_weight_bold'>Name</TableCell>
          <TableCell className='search_table_header2'>City</TableCell>
          <TableCell className='search_table_header2'>Phone</TableCell>
          <TableCell className='table-header_material font_weight_bold'>Notes</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <div>
      <Typography sx={{ fontWeight: 'bold', paddingBottom: '20px', paddingTop: '20px' }}>
        {appointments.length ? (
          <Typography>
            Last {appointments.length} Appointments for {user}
          </Typography>
        ) : (
          <Skeleton />
        )}
      </Typography>

      <Box className='table-box-1'>
        {appointments.length ? (
          <TableContainer sx={{ maxHeight: 600, borderBottom: '1px solid #D6D6D6' }} className='table_padding_bottom'>
            <Table className='proposal-select-window-table' stickyHeader aria-label='sticky table'>
              <EnhancedTableHead />
              <TableBody>
                {appointments.map((row) => {
                  const phoneNumber = formatPhoneNumber(row);
                  const name = formatName(row);

                  return (
                    <TableRow hover className='rowhover' key={row.CustId}>
                      <TableCell className='table-content' align='left'>
                        {moment(row.StartTime).local().format('MM/DD/YYYY')} - {moment(row.StartTime).local().format('hh:mm a')}
                      </TableCell>
                      <TableCell className='table-content' align='left'>
                        {name ? (
                          <span
                            onClick={() =>
                              navigate('/tbm/ps/customerdetails', {
                                state: {
                                  Custdata: {
                                    custid: row.custid,
                                    Addressid: row.AddressID[0],
                                    Husband: row.Husband,
                                    LName: row.LName,
                                    Email: row.Email,
                                    state: row.P_State,
                                    City: row.City,
                                    zip: row.Zip,
                                    Address1: row.Address1,
                                  },
                                },
                              })
                            }
                            style={{
                              color: '#0ca8b0',
                              cursor: 'pointer',
                            }}
                          >
                            {name}
                          </span>
                        ) : (
                          '- -'
                        )}
                      </TableCell>
                      <TableCell sx={{ textTransform: 'capitalize' }} className='table-content' align='left'>
                        {row.City === '' ? '- -' : row.City}
                      </TableCell>
                      <TableCell className='table-content' align='left'>
                        {phoneNumber === undefined ? '- -' : phoneNumber}
                      </TableCell>
                      <TableCell className='table-content' align='left'>
                        {row.Notes === '' ? '- -' : row.Notes}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer component={Paper}>
            <Table aria-label='simple table'>
              <EnhancedTableHead rowCount={appointments.length} />
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component='th' scope='row'>
                  <Skeleton />
                </TableCell>
                <TableCell align='left'>
                  <Skeleton />
                </TableCell>
                <TableCell align='left'>
                  <Skeleton />
                </TableCell>
                <TableCell align='left'>
                  <Skeleton />
                </TableCell>
                <TableCell align='left'>
                  <Skeleton />
                </TableCell>
                <TableCell align='left'>
                  <Skeleton />
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        )}
      </Box>

      <Button
        className='button_top_space proposal_select_window_paddting_bottom'
        sx={{
          paddingBottom: '30px !importatnt',
        }}
        onClick={() => {
          navigate('/tbm/ps/searchCustomer/Customer');
        }}
        onMouseEnter={handleHoverFun}
        onMouseLeave={handleMouseLeaveFun}
      >
        <Box className='globalIconMainBox globalMarginLeftAddWindow globalTextDecoration'>
          {isHovered ? <img className='primary_icons_dimension_1' src={SearchHovIcon} alt='SearchHovIcon' /> : <img className='primary_icons_dimension_1' src={SearchIcon} alt='SearchIcon' />}
          <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
            SEARCH / ADD CUSTOMER
          </Typography>
        </Box>
      </Button>
    </div>
  );
}
