import React from "react";
import font from "../../../font/Roboto-Light.woff";
import fontr from "../../../font/Roboto-Regular.woff";
import fontb from "../../../font/Roboto-Bold.woff";
import alert from "../../../img/alert.png";
import blinds from "../../../img/blinds.png";
import { Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";

Font.register({
  family: "RobotoLight",
  src: font,
});
Font.register({
  family: "Roboto-Regular.woff",
  src: fontr,
});
Font.register({
  family: "Roboto-Bold.woff",
  src: fontb,
});
// Create styles
const styles = StyleSheet.create({
  section_row_body: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 15,
  },
  section_row_bullets: {
    flexDirection: "row",
    paddingTop: 4,
    paddingRight: 5,
  },

  headingText: {
    fontSize: 18,
    color: "#333333",
    paddingTop: "1px",
    fontFamily: "RobotoLight",
    fontWeight: "400",
  },

  paraheading: {
    fontSize: 13,
    color: "#2C2E35",
    paddingTop: 8,
    fontFamily: "RobotoLight",
  },
  headingButton: {
    color: "gray",
    border: "1.5px solid #85837D",
    paddingTop: "4.5px",
    width: 65,
    height: 25,
    fontSize: "12px",
    textAlign: "center",
    backgroundColor: "#FAC41E",
    fontFamily: "RobotoLight",
    fontWeight: "400",
  },
  firstBlock: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "10px",
  },
  secondBlock: {
    border: "2px solid #2D3030",
    marginTop: "15px",
  },
  firstSection: {
    backgroundColor: "#2D3030",
    color: "white",
    fontSize: 22,
    paddingTop: 3,
    paddinBottom: 4,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
  },
  secondSection: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "15px",
  },
  secondBlockImage: {
    width: "20%",
  },
  description: {
    color: "#2C2E35",
    width: "100%",
    fontSize: "11px",
    paddingTop: 5,
    lineHeight: 1.1,
  },
  secondBlockDescription: {
    width: "80%",
    fontSize: "10px",
    paddingTop: "15px",
  },
  image: {
    width: 140,
    height: 120,
    paddingLeft: "10px",
    paddingRight: "25px",
    paddingTop: "15px",
  },
  image2: {
    width: 55,
    height: 35,
    paddingLeft: 15,
  },
  padding: {
    paddingTop: 5,
    fontFamily: "RobotoLight",
  },
  boldheadingwithpadding: {
    paddingTop: 5,
    fontFamily: "Roboto-Bold.woff",
    color: "#000",
  },
  break: {
    backgroundColor: "black",
    height: 5,
    marginTop: 5,
  },
  breakCopy: {
    backgroundColor: "black",
    height: 1,
    marginTop: "4px",
  },
  fontFamily: {
    paddingTop: 0,
    fontFamily: "RobotoLight",
    fontWeight: "400",
  },
  bullets: {
    paddingLeft: 15,
    color: "#2C2E35",
  },
  bulletspoint: {
    paddingLeft: 0,
    color: "#2C2E35",
  },
  bulletspointcontent: {
    paddingLeft: 0,
    color: "#2C2E35",
    fontFamily: "RobotoLight",
  },
  bulletscontent: {
    fontFamily: "RobotoLight",
    paddingLeft: 15,
    width: "98%",
  },
  paddingstyle: {
    fontFamily: "Roboto-Bold.woff",
  },
  boldheading: {
    fontFamily: "Roboto-Bold.woff",
    color: "#000",
  },
  horizontalborder: {
    width: "100%",
    height: "2px",
    flexDirection: "row",
    backgroundColor: "#7E7E7E",
  },
  section_space: {
    lineHeight: "1.5px",
    paddingTop: 5,
  },
});

export default function ProductSafety() {
  return (
    <>
      <View style={styles.section_row_body}>
        <View style={styles.firstBlock}>
          <Text style={styles.headingText}>PRODUCT SAFETY</Text>
        </View>
        <View style={styles.secondBlock}>
          <View style={styles.firstSection}>
            <View>
              <Image src={alert} style={styles.image2}></Image>
            </View>
            <Text style={styles.paddingstyle}> WARNING / ADVERTENCIA</Text>
          </View>
          <View style={styles.secondSection}>
            <View style={styles.secondBlockImage}>
              <Image src={blinds} style={styles.image}></Image>
            </View>

            <View style={styles.secondBlockDescription}>
              <Text style={styles.fontFamily}>
                Window Blind cords can STRANGLE your child. To prevent
                strangulation, purchase cordless products or products with
                inaccessible cords. The Blindman always recommends the purchase
                of CORDLESS blinds and shades in homes with small children. We
                also recommend when cords are necessary or present in a home
                that the home owner take steps to ensure the safety of their
                children.
              </Text>
              <Text style={styles.padding}>
                Young children can be strangled by loops in pull cords, chains,
                tapes, and inner cords that operate the product. To avoid
                strangulation and entanglement, keep cords out of the reach of
                young children. Cords may become wrapped around a child's neck.
                Move beds, cots, and furniture away from window covering cords.
                Do not tie cords together. Make sure that cords do not twist or
                create a loop.
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.horizontalborder}>&nbsp;</View>
    </>
  );
}
