/** @format */

import { useParams, useNavigate, useLocation } from 'react-router-dom';
import React, { forwardRef, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import CONFIG from '../../config/config.js';
import EditLinesAddWindowStepper from '../Proposals/EditLinesAddWindowStepper';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import 'moment-timezone';
import Box from '@mui/material/Box';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import SelectIcon from '../../assets/icons/secondary/2nd-confirm.svg';
import AddWindow from '../WindowsManagement/AddWindow';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Drawer from '@mui/material/Drawer';
import EditWindowSetName from '../WindowsManagement/EditWindowSetName';
import { makeStyles } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import fracty from 'fracty';
import Grid from '@mui/material/Grid';
import AddWindowIcon2 from '../../assets/icons/primary/window-add.svg';
import AddWindowHovIcon from '../../assets/icons/primary/WithHovIcon/window-add-hov.svg';
import ContinueIcon from '../../assets/icons/primary/continue.svg';
import ContinueHovIcon from '../../assets/icons/primary/WithHovIcon/continue-hov.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import LongText from '../LongText';
import { TablePagination } from '@mui/material';

const TurquoiseTooltipforNotes = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#faf3dd',
    color: '#000000',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 300,
    maxHeight: 300,
    textAlign: 'left',
    align: 'left',
    border: '1px solid #000000',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#f2f3f2',
      border: '1px solid #000000',
    },
    top: '100%', // Position the arrow at the bottom of the tooltip box
    right: '100', // Position the arrow at the right side of the tooltip box
  },
}));

const outerTheme = createTheme({
  palette: {
    primary: {
      main: '#333333',
    },
  },
});

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  paper: {
    width: '90%',
    background: 'rgba(69, 157, 166, 70%)  !important',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
});

const token = sessionStorage.getItem('token');

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export const EditingProposalWindowSetToAddWindows = () => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const [WindowSetData, setWindowSetData] = useState([]);
  const [windows, setWindows] = useState([]);
  const [windowsetdata, setWindowsetdata] = useState([]);
  const [windowsallreadySelected, setWindowsallreadySelected] = useState([]);
  const [windowsremaining, setWindowsremaining] = useState([]);
  const [WindowSetName, setWindowSetName] = useState();
  const [customerID, setCustomerID] = useState(0);
  const [checked, setChecked] = useState();
  const [showCreateWindowComponent, setShowCreateWindowComponent] = useState(false);
  const [sendDataToNewWindow, setSendDatatoNewWindow] = useState('');
  const [checkboxflag, setCheckboxFlag] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const vertical = 'top';
  const horizontal = 'center';
  const [checkedrowdata, setCheckedRowData] = useState([]);
  const [showSelectAll, setShowSelectAll] = useState(true);
  const [isWSDrawerOpen, setIsWSDrawerOpen] = useState(false);
  const [alertmessage, setAlertMessage] = useState('');
  const [classtype, setClassType] = useState('');
  const [defaultOptionValues, setdefaultOptionValues] = useState();

  // Error Handling
  const [ProposalDetailsErrorMsg, setProposalDetailsErrorMsg] = useState('');
  const [ProposalDetailsErrorAlert, setProposalDetailsErrorAlert] = useState(false);

  //  hover states

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const handleHoverFun = () => setIsHovered(true);

  const handleMouseLeaveFun = () => setIsHovered(false);

  const handleHoverFun2 = () => setIsHovered2(true);

  const handleMouseLeaveFun2 = () => setIsHovered2(false);

  if (location.state === null) {
    navigate('/pagenotfound');
  }

  const coveringdetails = location.state.coveringdetails;

  useEffect(() => {
    window.scrollTo(0, 0);

    getVerified();
  }, []);
  // Get Token
  const token = sessionStorage.getItem('token');
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };
  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='table-headerewsd sticky-column'>
            {!checkboxflag ? (
              <>
                <Box sx={{ display: 'flex' }}>
                  <span style={{ paddingLeft: '32px' }}>#</span> <span style={{ paddingLeft: '15px' }}>Window </span>
                  <span style={{ paddingLeft: '5px', paddingRight: '30px' }}>Name</span>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ display: 'flex' }}>
                  <span>#</span> <span style={{ paddingLeft: '10px' }}>Window</span> <span style={{ paddingLeft: '10px' }}>Name</span>
                </Box>
              </>
            )}
          </TableCell>
          <TableCell className='table-headersw3'>Size</TableCell>
          <TableCell className='table-headersw3'>Bld</TableCell>
          <TableCell className='table-headersw3'>Surface</TableCell>
          <TableCell className='table-headersw3'>Ladder</TableCell>
          <TableCell className='table-headersw3 ellipsis2'>Ex. Cover</TableCell>
          <TableCell className='table-header4'>Note</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  useEffect(() => {
    if (location.state.length === 0) {
      navigate('/pagenotfound');
    }
    setCheckboxFlag(location.state && location.state.selectedwindows.length > 0);

    getWindowSet();
    addMoreWindow();
  }, [customerID, WindowSetName]);

  const WindowSetNameUpdateDrawer = (name, drawerstatus, message, status) => {
    setIsWSDrawerOpen(drawerstatus);
    setAlertMessage(message);

    if (message === 'Window set name is updated successfully!') {
      setWindowSetName(name);
      setOpen(true);

      setClassType('Success2 mainAlert');
    } else if (message === 'Window set name already exists.') {
      setOpen(true);
      setClassType('Error mainAlert');
    } else {
      setOpen(false);
      setClassType('');
    }
  };

  const getWindowSet = async () => {
    const response = await fetch(CONFIG.API_URL + `/wm/window-set/${location.state.customerdata.window_set_id}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();

    if (data.error.message === 'Token is not valid') {
      navigate(`/login`, { state: { error: 'Unauthorized Please Login' } });
    }
    setWindows(data.window);
    setWindowsetdata(data.window_set);
    setWindowSetName(data.window_set.window_set_name);
    setCustomerID(data.window_set.customer_id);

    setSendDatatoNewWindow(data.window_set);

    if (data.default_options) {
      setdefaultOptionValues(data.default_options);
    } else {
      setdefaultOptionValues('');
    }
  };
  const AddWindowforProposalSelection = async () => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: location.state.proposalID,
        windows: checkedrowdata,
      }),
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/UpdateProposal`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      return;
    }
    // Screen will redirect to ProposalSelectWindow page
    navigate(`/tbm/ps/editingswproposal/${params.id}`, {
      state: {
        selectedwindows: checkedrowdata,
        customerdata: windowsetdata,
        totalwindows: WindowSetData,
        proposal_id: location.state.proposalID,
        proposal_name: location.state.proposalName,
        coveringdetails: coveringdetails,
      },
    });
  };

  const addMoreWindow = async () => {
    const options = {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(`${CONFIG.API_URL}/pm/ProposalDetails/${location.state.proposalID}`, options);
    const data = await response.json();
    if (!response.ok || data.error) {
      setProposalDetailsErrorMsg(data.error_message);
      setProposalDetailsErrorAlert(true);
    }
    const allreadyselectedwindows = data.proposal_details.filter(({ window_selected }) => window_selected);
    const remainingwindows = data.proposal_details.filter(({ window_selected }) => !window_selected);
    setWindowsallreadySelected(allreadyselectedwindows);
    setWindowsremaining(remainingwindows);
    setCheckedRowData([]);
    setWindowSetData(data.proposal_details);
  };
  const rows = windows.map((window) => {
    window.selected = !!WindowSetData.find(({ window_id }) => window_id === window.window_id);
    window.covering_status = WindowSetData.find(({ window_id }) => window_id === window.window_id)?.covering_status || 'not_consider';

    return window;
  });

  function handleChangeState(newValue, val) {
    setShowCreateWindowComponent(newValue);

    if (val) {
      handleNotificationClick();
      addMoreWindow();
      getWindowSet();
    }
  }

  const createWindow = () => setShowCreateWindowComponent(true);

  const cancelProposalSelection = () => {
    setCheckedRowData([]);
    navigate(`/tbm/ps/proposal/${params.id}/details`, {
      state: {
        data: location.state.data,
        windowsselectednotnull: location.state.data.AppliedCoveringsWindows,
        selectedwindows: checkedrowdata,
        customerdata: windowsetdata,
        totalwindows: WindowSetData,
        proposalID: location.state.proposalID,
        proposalName: location.state.proposalName,
        coveringdetails: coveringdetails,
      },
    });
  };

  const handleNotificationClick = () => {
    setOpenSuccess(true);
    setClassType('Success2 mainAlert');
  };

  const handleSuccessClose = (event, reason) => (reason === 'clickaway' ? null : setOpenSuccess(false));

  const handleSelectAllClick = (event) => {
    if (event === true) {
      const newSelecteds = rows.map((n) => n);
      const newSelectedWindows = newSelecteds.filter((window) => window.covering_status === 'not_consider');

      setSelected(newSelecteds);
      setCheckedRowData(newSelectedWindows);
      return;
    } else {
      setCheckedRowData([]);
      setSelected([]);
    }
  };
  const handleClick = (event, row) => {
    const selectedIndex = selected.indexOf(row);

    const newSelected = selectedIndex === -1 ? [...selected, row] : selected.filter((_, index) => index !== selectedIndex);
    const newSelectedWindow = newSelected.filter((window) => window.covering_status === 'not_consider');

    if (newSelectedWindow.length < WindowSetData.length) {
      setShowSelectAll(false);
    }
    if (newSelected.length === 0) {
      setShowSelectAll(true);
    }
    setCheckedRowData(newSelectedWindow);
    setSelected(newSelected);
  };

  const isSelected = (row) => selected.indexOf(row) !== -1;

  const onSelectAllClick = () => {
    setShowSelectAll(false);
    handleSelectAllClick(true);
  };
  const onDeSelectAllClick = () => {
    setShowSelectAll(true);
    handleSelectAllClick(false);
  };

  const fetchdata = () => {};

  return (
    <ThemeProvider theme={outerTheme}>
      <div>
        <div align='center'>
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar className='snackbar_bar_postition' open={ProposalDetailsErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setProposalDetailsErrorAlert(false)}>
              <Alert className='Error1 mainAlert' variant='filled' icon={false}>
                <div className='alertCancel_2'>
                  <img
                    onClick={() => {
                      setProposalDetailsErrorAlert(false);
                    }}
                    src={RedDrawerCancelIcon}
                    alt='CANCELICONPDA'
                    className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                  />
                </div>
                <div className='alertMain_2_4 confirmIconStyle1'>
                  <img src={RedExclamationIcon} alt='CONFIRMICONPDA' className='primary_icons_dimension alertImageMargin' />
                  <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{ProposalDetailsErrorMsg}</div>
                </div>
              </Alert>
            </Snackbar>

            <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setOpen(false)}>
              {classtype === 'Success2 mainAlert' ? (
                <Alert className={classtype} variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img alt='CANCELICONWSNA' onClick={() => setOpen(false)} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img alt='CONFIRMICONWSNA' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />
                    <div align='left' className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>
                      Window set name updated successfully!
                    </div>
                  </div>
                </Alert>
              ) : (
                <Alert className={classtype} variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img alt='CANCELICONREDWSNA' onClick={() => setOpen(false)} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img alt='CONFIRMICONREDWSNA' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                    <div align='left' className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>
                      Window set name is already exists.
                    </div>
                  </div>
                </Alert>
              )}
            </Snackbar>

            <Snackbar className='snackbar_bar_postition' open={openSuccess} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setOpenSuccess(false)}>
              {classtype === 'Success2 mainAlert' ? (
                <Alert className={classtype} variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img alt='CANCELICONWNA' onClick={handleSuccessClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img alt='CONFIRMICONWNA' src={DrawerConfirmIcon} className='primary_icons_dimension alertImageMargin' />
                    <div align='center' className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>
                      Window created successfully!
                    </div>
                  </div>
                </Alert>
              ) : (
                <Alert className={classtype} variant='filled' icon={false}>
                  <div className='alertCancel_2'>
                    <img alt='CANCELICONREDWNA' onClick={handleSuccessClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
                  </div>
                  <div className='alertMain_2_4 confirmIconStyle1'>
                    <img alt='CONFIRMICONREDWNA' src={RedExclamationIcon} className='primary_icons_dimension alertImageMargin' />
                    <div align='left' className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>
                      Window name already exists.
                    </div>
                  </div>
                </Alert>
              )}
            </Snackbar>
          </Stack>
        </div>
        {/* add window success massage */}

        <div className='heading-box' align='center'>
          <h2 className='clickable_header all_pages_h2_header' onClick={() => setIsWSDrawerOpen(true)}>
            <LongText children={WindowSetName} />
          </h2>
          <h3
            className='clickable_header_1 all_pages_h2_header button_top_space common_line_height_css'
            style={{ textTransform: 'capitalize' }}
            onClick={() => {
              navigate('/tbm/ps/customerdetails', {
                state: {
                  Custdata: {
                    custid: location.state.customerdata.customer_id,
                    Addressid: location.state.customerdata.address_id,
                  },
                },
              });
            }}
          >
            <LongText children={`${windowsetdata.last_name} | ${windowsetdata.city}`} />
          </h3>
        </div>
        <Drawer
          PaperProps={{
            sx: { width: '90%' },
          }}
          classes={{ paper: classes.paper }}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          anchor='right'
          open={isWSDrawerOpen}
          onClose={() => setIsWSDrawerOpen(true)}
          sx={{ zIndex: '100000' }}
        >
          <EditWindowSetName windowdetails={windowsetdata} func={WindowSetNameUpdateDrawer} />
        </Drawer>

        {!checkboxflag ? (
          <Grid container spacing={0}>
            <Grid item xs={1} />

            <Grid item lg={4} xl={3} md={5} xs={10} sm={8}>
              <Box align='center' className='prompt-box_2 button_bottom_space'>
                <Typography
                  sx={{
                    fontSize: '110%',
                    px: '1%',
                    color: 'white',
                  }}
                  variant='contained'
                >
                  Select windows to add to proposal
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        ) : null}
        <div className='button_top_space' align='center' style={{ marginBottom: '10px' }}>
          <EditLinesAddWindowStepper data={'EditLinesAddWindowStepper'} />
        </div>

        {!checkboxflag ? (
          showSelectAll ? (
            <span className='gray_button_span' onClick={onSelectAllClick} style={{ paddingBottom: '20px', paddingLeft: '22px' }}>
              <Box className='globalIconMainBox'>
                <img alt='SELECTALL' src={SelectIcon} className='secondary_icons_dimension' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  SELECT ALL
                </Typography>
              </Box>
            </span>
          ) : (
            <span className='gray_button_span' onClick={onDeSelectAllClick} style={{ paddingBottom: '20px', paddingLeft: '22px' }}>
              <Box className='globalIconMainBox'>
                <img alt='DESELECTALL' src={CancelIcon} className='secondary_icons_dimension' />
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  DESELECT ALL
                </Typography>
              </Box>
            </span>
          )
        ) : null}
        <hr />
        <Box className='table-box'>
          <TableContainer className='table_padding_bottom' sx={{ borderBottom: '1px solid #D6D6D6' }}>
            <Table className='windowset-table-dimension' aria-labelledby='tableTitle' size='small'>
              <EnhancedTableHead />
              <TableBody>
                {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row, index) => (
                  <TableRow hover role='checkbox' aria-checked={isSelected(row)} tabIndex={-1} key={row.window_id}>
                    {row.selected === true && row.covering_status === 'applied' ? (
                      <>
                        <TableCell
                          className='table-content sticky-column'
                          align='left'
                          sx={{
                            display: 'flex',
                            width: '100%',
                          }}
                        >
                          <Box sx={{ display: 'flex' }}>
                            <Checkbox
                              sx={{
                                [`&, &.${checkboxClasses.checked}`]: {
                                  color: '#ADADAD',
                                  zoom: 0.8,
                                  paddingTop: '0px !important',
                                },
                              }}
                              checked={row.selected}
                              inputProps={{
                                'aria-labelledby': `enhanced-table-checkbox-${index}`,
                              }}
                            />
                            <Box sx={{ width: '20px' }}>{rows.indexOf(row) + 1}</Box>
                            &nbsp;
                            <LongText children={row.window_name} />
                          </Box>
                        </TableCell>

                        <TableCell className='table-content ellipsis2' align='left'>
                          {fracty(row.window_width)}" x {fracty(row.window_height)}"{' '}
                          <span className={row.measurement_type.replace(/ /g, '')}>
                            {row.measurement_type === 'Quick'
                              ? '(QK)'
                              : row.measurement_type === 'Shutters'
                              ? '(SH)'
                              : row.measurement_type === 'Blinds'
                              ? '(BL)'
                              : '(' +
                                row.measurement_type
                                  .split(' ')
                                  .map((word) => word[0])
                                  .join('') +
                                ')'}
                          </span>
                        </TableCell>
                        <TableCell align='left' className='table-content'>
                          {row.build_type}
                        </TableCell>
                        <TableCell align='left' className='table-content'>
                          {row.mount_surface}
                        </TableCell>
                        <TableCell align='left' className='table-content'>
                          {row.ladder}
                        </TableCell>
                        <TableCell className='table-content' align='left'>
                          {row.existing_covering}
                        </TableCell>
                        <TableCell className='table-content' align='left'>
                          &nbsp;&nbsp; {row.notes_count < 1 ? '' : row.notes_count}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell
                          className='table-content sticky-column'
                          align='left'
                          sx={{
                            backgroundColor: '#fff',
                            display: 'flex',
                            paddingTop: '0px !important',
                          }}
                        >
                          <Box sx={{ display: 'flex' }}>
                            <Checkbox
                              sx={{
                                zoom: 0.8,
                                paddingTop: '0px !important',
                              }}
                              color='primary'
                              checked={isSelected(row)}
                              inputProps={{
                                'aria-labelledby': `enhanced-table-checkbox-${index}`,
                              }}
                              onClick={(event) => handleClick(event, row)}
                            />
                            <Box sx={{ width: '20px' }}>{rows.indexOf(row) + 1}</Box>
                            &nbsp;
                            <LongText children={row.window_name} />
                          </Box>
                        </TableCell>

                        <TableCell align='left' className='table-content'>
                          {fracty(row.window_width)}" X {fracty(row.window_height)}"{' '}
                          <span className={row.measurement_type.replace(/ /g, '')}>
                            {row.measurement_type === 'Quick'
                              ? '(QK)'
                              : row.measurement_type === 'Shutters'
                              ? '(SH)'
                              : row.measurement_type === 'Blinds'
                              ? '(BL)'
                              : `(${row.measurement_type
                                  .split(' ')
                                  .map((word) => word[0])
                                  .join('')})`}
                          </span>
                        </TableCell>
                        <TableCell align='left' className='table-content'>
                          {row.build_type}
                        </TableCell>

                        <TableCell align='left' className='table-content'>
                          {row.mount_surface}
                        </TableCell>
                        <TableCell align='left' className='table-content'>
                          {row.ladder}
                        </TableCell>
                        <TableCell className='table-content' align='left'>
                          {row.existing_covering}
                        </TableCell>
                        <TableCell className='table-content' align='left'>
                          <TableCell className='table-content' align='left'>
                            &nbsp;&nbsp; {row.notes_count < 1 ? '' : row.notes_count}
                          </TableCell>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPageprp) => {
              setPage(newPageprp);
            }}
            onRowsPerPageChange={({ target }) => {
              setRowsPerPage(target.value);
              setPage(0);
            }}
          />
        </Box>

        <Box>
          {!checkedrowdata.length > 0 ? (
            <span className='button_span_disabled proposal_select_window_continue_paddting_bottom button_top_space'>
              <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                <img alt='ADDSELECTEDWINDOWSD' src={ContinueIcon} className='primary_icons_dimension_disable' />

                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                  ADD SELECTED WINDOWS
                </Typography>
              </Box>
            </span>
          ) : (
            <span onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun} className='button_span proposal_select_window_continue_paddting_bottom button_top_space'>
              <Box onClick={AddWindowforProposalSelection} className='globalIconMainBox globalMarginLeftAddWindow'>
                {isHovered ? <img alt='ADDSELECTEDWINDOWSH' className='primary_icons_dimension_1' src={ContinueHovIcon} /> : <img alt='ADDSELECTEDWINDOWS' className='primary_icons_dimension_1' src={ContinueIcon} />}
                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                  ADD SELECTED WINDOWS
                </Typography>
              </Box>
            </span>
          )}

          {showCreateWindowComponent ? (
            <AddWindow location={sendDataToNewWindow} func={fetchdata} value={showCreateWindowComponent} proposal_id={location.state.proposalID} checkbuttonOption={handleChangeState} defaultOptionValuesProposalLevel={defaultOptionValues} />
          ) : (
            <>
              <span className='button_span proposal_select_window_continue_paddting_bottom' onMouseEnter={handleHoverFun2} onMouseLeave={handleMouseLeaveFun2}>
                <Box onClick={createWindow} className='globalIconMainBox globalMarginLeftAddWindow'>
                  {isHovered2 ? <img alt='CREATEWINDOWH' className='primary_icons_dimension_1' src={AddWindowHovIcon} /> : <img alt='CREATEWINDOW' className='primary_icons_dimension_1' src={AddWindowIcon2} />}
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                    CREATE WINDOW
                  </Typography>
                </Box>
              </span>

              <hr />

              <span className='gray_button_span button_top_space' style={{ paddingBottom: '30px', paddingLeft: '22px' }}>
                <Box onClick={cancelProposalSelection} className='globalIconMainBox'>
                  <img src={CancelIcon} className='secondary_icons_dimension' alt='CANCEL' />
                  <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                    CANCEL
                  </Typography>
                </Box>
              </span>
            </>
          )}
        </Box>
      </div>
    </ThemeProvider>
  );
};
