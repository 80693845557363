import React, { Fragment, useState } from 'react';
import axios from 'axios';
import Progress from './Progress';
import './FileUpload.css';
import loader from '../../../img/loader.gif';
import CONFIG from '../../../config/config.js';
import Loader from '../../../img/loader.gif';
import { ToastContainer, toast } from 'react-toastify';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Stack } from '@mui/material';
import RedDrawerCancelIcon from '../../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../../assets/icons/drawer-alert/red-exclamation.svg';
import DrawerCancelIcon from '../../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../../assets/icons/drawer-alert/drw-alert.svg';

const vertical = 'top';
const horizontal = 'center';
const FileUpload = () => {
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('Choose File');
  const [uploadedFile, setUploadedFile] = useState({});
  const [message, setMessage] = useState('');
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [firstRow, setFirstRow] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [success, setSuccess] = useState(false);
  const [successText, setSuccessText] = useState('');
  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMsg(false);
    setSuccess(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const onChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFile(null);
      setFilename('Choose File');

      setCsvHeaders([]);
    } else {
      setFile(e.target.files[0]);
      setFilename(e.target.files[0].name);
      setCsvHeaders([]);
    }
  };
  const onSubmit = async (e) => {
    const Token = sessionStorage.getItem('token');

    setSuccessMessage('');
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);

    try {
      const res = await axios.post(CONFIG.API_URL + '/csvimp/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          token: Token,
        },
        onUploadProgress: (progressEvent) => {
          setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
        },
      });

      const response = res.data;
      if (response.error === true) {
        setErrorMsg(true);
        setErrorText(response.error_message);
      }
      if (response.error === false) {
        setSuccessText(response.message);
        setSuccess(true);
      }
      const { fileName, filePath } = res.data;

      setUploadedFile({ fileName, filePath });

      setMessage('File Uploaded Successfully !');
      const csvText = res.data.headers.toString();

      const rows = csvText.split('\n');
      setFirstRow(rows[0].split(','));

      const data = rows.slice(1).map((row) => {
        const rowData = row.split(',');
        return firstRow.reduce((obj, header, index) => {
          obj[header] = rowData[index];
          return obj;
        }, {});
      });
    } catch (err) {
      toast.error(err);
    }
  };
  const callServerApi = async () => {
    const Token = sessionStorage.getItem('token');
    setIsLoader(true);
    try {
      const response = await fetch(`${CONFIG.API_URL}/csvimp/${uploadedFile.fileName.slice(0, -4)}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          token: Token,
        },
      });

      const data = await response.json();
      if (data.error === true && data.error_message === '404 URL Not Found!') {
        setErrorText('Invalid Header Type');
        setErrorMsg(true);
      } else {
        setSuccessText(data.message);
        setSuccess(true);
        setIsLoader(false);
        setTimeout(() => {}, 3000);
      }
    } catch (error) {
      setErrorText(error);
      setErrorMsg(true);
    }
  };

  return (
    <Fragment>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={errorMsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setErrorMsg(false)}>
          <Alert className='Error mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel'>
                <img onClick={handleErrorClose} src={RedDrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='RedDrawerCancelIcon' />
              </div>
              <div className='alertMain'>
                <img src={RedExclamationIcon} className='primary_icons_dimension' />

                <div align='center' style={{ paddingTop: 10 }}>
                  {errorText}
                </div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={success} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setSuccess(false)}>
          <Alert className='Success mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel'>
                <img onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
              </div>
              <div className='alertMain'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='DrawerAlertIcon' />

                <div align='center' style={{ paddingTop: 10 }}>
                  {successText}
                </div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>

      <form onSubmit={onSubmit}>
        <div className='custom-file mb-4'>
          <input type='file' className='custom-file-input' id='customFile' onChange={onChange} accept='.csv' />
          <label className='custom-file-label' htmlFor='customFile'>
            {filename}
          </label>
        </div>
        {uploadPercentage ? <Progress percentage={uploadPercentage} /> : null}
        <input type='submit' value='Upload' className='btn btn-primary btn-block mt-4' />
      </form>

      {uploadedFile.fileName ? (
        <div className='row mt-5'>
          <div className='col-md-6 m-auto'>
            <h3 className='text-center'>{uploadedFile.fileName}</h3>
            <img style={{ width: '100%' }} src={uploadedFile.filePath} alt='uploadedFile' />
            {successMessage ? null : (
              <>
                <button className='btn btn-primary btn-block mt-4' onClick={callServerApi}>
                  Insert To DB {uploadedFile.fileName.slice(0, -4)}
                </button>
              </>
            )}
          </div>
        </div>
      ) : null}

      {csvHeaders ? (
        <div className='mt-5'>
          <table className='table'>
            <thead>
              <tr>
                {firstRow.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
          </table>
        </div>
      ) : null}
      {successMessage.error === true ? (
        <div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
          }}
        >
          <img style={{ textAlign: 'center', width: '100px' }} src={loader} alt='loader' />
        </div>
      ) : (
        <div>
          {successMessage && (
            <div className='message success'>
              <span className='icon'>&#10003;</span>
              {successMessage}
            </div>
          )}
        </div>
      )}
      {isLoader ? (
        <div className='d-flex justify-content-center'>
          <img src={Loader} height='100px' alt='Loader' />
        </div>
      ) : null}
      <ToastContainer />
    </Fragment>
  );
};

export default FileUpload;
