import React, { useState } from 'react';
import { TableContainer, Table as MuiTable, TableBody, TablePagination, Tooltip, Typography, Zoom } from '@mui/material';
import fracty from 'fracty';
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import LongText from '../LongText';
import CONFIG from '../../config/config.js';
import MiscCheckIcon from '../../assets/icons/misc/misc-confirm.svg';
import Table, { formatLabel } from '../Shared/Table';

const TurquoiseTooltipforNotes = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#faf3dd',
    color: '#000000',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 300,
    maxHeight: 300,
    textAlign: 'left',
    align: 'left',
    border: '1px solid #000000',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#f2f3f2',
      border: '1px solid #000000',
    },
    top: '100%', // Position the arrow at the bottom of the tooltip box
    right: '100', // Position the arrow at the right side of the tooltip box
  },
}));

export default function SalesOrderWindows({ windows }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);

  const rows = windows.map((window) => {
    const { sales_order_measurement_type_specific_factor, sales_order_measurement_type, sales_order_additional_sku } = window;

    const measurementType =
      sales_order_measurement_type === 'Quick'
        ? '(Q)'
        : sales_order_measurement_type === 'Shutters'
        ? '(SH)'
        : sales_order_measurement_type === 'Blinds'
        ? '(BL)'
        : `(${sales_order_measurement_type
            .split(' ')
            .map((word) => word[0])
            .join('')})`;
    return {
      id: window.window_id,
      name: (
        <>
          <span
            style={{
              paddingRight: +window.item_sequence + 1 > 9 ? '20px' : '30px',
            }}
          >
            {+window.item_sequence}
          </span>
          <LongText>{window.window_name}</LongText>
        </>
      ),
      applied: (
        <img
          src={MiscCheckIcon}
          alt='Confirmed to Sales Order'
          style={{
            width: '1rem',
            cursor: 'pointer',
          }}
        />
      ),
      size: (
        <>
          {`${fracty(window.sales_order_window_width)}" X ${fracty(window.sales_order_window_height)}"`} <span className={sales_order_measurement_type.replace(/ /g, '')}>{measurementType}</span>
        </>
      ),
      category: window.covering_type,
      retail: `$${parseFloat(window.retail_price).toFixed(2)}`,
      install: `$${(parseFloat(window.covering_install_price) + parseFloat(window.sales_order_additional_price)).toFixed(2)}`,
      total: `$${parseFloat(window.total_covering_price_with_install).toFixed(2)}`,
      build: window.build_type,
      depth: sales_order_measurement_type_specific_factor.WindowDepth,
      mount: window.mount_surface,
      obst:
        sales_order_additional_sku?.find((sku) => sku.type === 'Obstructions').sku_name === 'Yes' ? (
          <TurquoiseTooltipforNotes title={<Typography>{JSON.parse(sales_order_additional_sku.find((sku) => sku.type === 'Obstructions').additional_detail).join(';')}</Typography>} placement='bottom-end' TransitionComponent={Zoom} arrow>
            <Typography className={'link ellipsis'}>Y</Typography>
          </TurquoiseTooltipforNotes>
        ) : (
          'N'
        ),
      ladder: window.ladder,
      exstCov: window.existing_covering,
      config: sales_order_measurement_type_specific_factor.WindowConfiguration || `- -`,
      notes: window.window_notes?.length ? (
        <TurquoiseTooltipforNotes
          title={
            <Typography>
              {window.window_notes.map((note, index) => (
                <div key={note.id}>
                  {index > 0 && <br />}
                  <b>Note:</b> {note.content}
                </div>
              ))}
            </Typography>
          }
          placement='bottom-end'
          TransitionComponent={Zoom}
          arrow
        >
          <Typography className={'link ellipsis'}>{window.notes_count}</Typography>
        </TurquoiseTooltipforNotes>
      ) : null,
    };
  });

  const columns = [...new Set(rows.flatMap((obj) => Object.keys(obj)))].map((key) => ({
    label: formatLabel(key),
    align: 'center',
    dataKey: key,
  }));

  return (
    <TableContainer
      sx={{
        width: { xl: 'fit-content' },
      }}
    >
      <MuiTable className='finalize-salesorder-table' aria-labelledby='tableTitle' size='small'>
        <Table.Header columns={columns} />
        <TableBody>
          {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((window) => (
            <Table.Row key={window.id} columns={columns} row={window} />
          ))}
        </TableBody>
      </MuiTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPageprp) => setPage(newPageprp)}
        onRowsPerPageChange={({ target }) => {
          setRowsPerPage(target.value);
          setPage(0);
        }}
      />
    </TableContainer>
  );
}
