import React, { Fragment, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DrawerCancelIcon from '../../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../../assets/icons/drawer-alert/drw-alert.svg';
import { Stack } from '@mui/material';
import axios from 'axios';
import Progress from './Progress';
import CONFIG from '../../../config/config.js';
import Loader from '../../../img/loader.gif';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JSONPretty from 'react-json-pretty';
require('react-json-pretty/themes/monikai.css');
const vertical = 'top';
const horizontal = 'center';

const FileUpload = () => {
  const [file, setFile] = useState('');
  const [filename, setFilename] = useState('Choose File');
  const [loader, setLoader] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [result, setResults] = useState();
  const [showData, setShowData] = useState();
  const [progressBar, setProgressBar] = useState(false);
  const [Result1, setResult1] = useState();
  const [success, setSuccess] = useState(false);
  const [copyAlert, setCopyAlert] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [selectedFile, setSelectedfile] = useState('');
  const [copyToClipBoard, setcopyToClipBoard] = useState('');
  const [uploadedSuccess, setUploadedSuccess] = useState('');
  const [errorAlert, setErrorAlert] = useState(false);

  const onChange = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFile(null);
      setFilename('Choose File');
    } else {
      setFile(e.target.files[0]);
      setFilename(e.target.files[0].name);
      setSuccess(true);
      setSelectedfile(`file selected ${e.target.files[0].name}`);
    }
  };

  const Token = sessionStorage.getItem('token');
  const onSubmit = async (e) => {
    setProgressBar(true);
    setLoader(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    const res = await axios.post(CONFIG.API_URL + '/csvcon/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        token: Token,
      },
      onUploadProgress: (progressEvent) => {
        setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
      },
    });
    const responseData = res.data;

    if (responseData.error === false && responseData.result.length === undefined) {
      setUploadSuccess(true);

      const Length = responseData.result.length;
      setShowData(Length);
      const responseMessage = responseData.message;
      setLoader(false);
      setResults(responseData.result);
      setProgressBar(false);
    } else if (responseData.error === false && responseData.result.length !== undefined) {
      const Length = responseData.result.length;
      setShowData(Length);
      setUploadSuccess(true);
      const stringifyResult = JSON.stringify(responseData.result, null, 2);
      const formattedResult = stringifyResult.slice(1, -1);
      setResult1(formattedResult);
      const responseMessage = responseData.message;
      setUploadedSuccess(responseMessage);
      setProgressBar(false);
      setLoader(false);
    } else {
      setErrorAlert(true);

      setLoader(false);
      setTimeout(() => {
        setProgressBar(false);
      }, 3000);
      setResults();
      setResult1();
    }
  };

  const copyToClipboard = () => {
    const jsonString = JSON.stringify(result);
    const textarea = document.createElement('textarea');
    textarea.value = jsonString;
    textarea.style.position = 'fixed';
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.opacity = 0;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    setcopyToClipBoard('Copied to clipboard!');
    setCopyAlert(true);
  };
  const copyToClipboardOfresult1 = () => {
    const jsonString = Result1;
    const textarea = document.createElement('textarea');
    textarea.value = jsonString;
    textarea.style.position = 'fixed';
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.opacity = 0;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    setcopyToClipBoard('Copied to clipboard!');
    setCopyAlert(true);
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setCopyAlert(false);
    setSuccess(false);
    setUploadSuccess(false);
    setErrorAlert(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  return (
    <Fragment>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={success} anchorOrigin={{ vertical, horizontal }} autoHideDuration={4000} onClose={() => setSuccess(false)}>
          <Alert className='Success mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel'>
                <img onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
              </div>
              <div className='alertMain'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='DrawerAlertIcon' />
                <div align='center' style={{ paddingTop: 10 }}>
                  {selectedFile}
                </div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={uploadSuccess} anchorOrigin={{ vertical, horizontal }} autoHideDuration={4000} onClose={() => setUploadSuccess(false)}>
          <Alert className='Success mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel'>
                <img onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
              </div>
              <div className='alertMain'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='DrawerAlertIcon' />
                <div align='center' style={{ paddingTop: 10 }}>
                  Data converted to JSON successfully!
                </div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={errorAlert} anchorOrigin={{ vertical, horizontal }} autoHideDuration={4000} onClose={() => setErrorAlert(false)}>
          <Alert className='Error mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel'>
                <img onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
              </div>
              <div className='alertMain'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='DrawerAlertIcon' />
                <div align='center' style={{ paddingTop: 10 }}>
                  Invalid headers of the selected file!
                </div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={copyAlert} anchorOrigin={{ vertical, horizontal }} autoHideDuration={4000} onClose={() => setCopyAlert(false)}>
          <Alert className='Success mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel'>
                <img onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
              </div>
              <div className='alertMain'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' alt='DrawerAlertIcon' />
                <div align='center' style={{ paddingTop: 10 }}>
                  {copyToClipBoard}
                </div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <form onSubmit={onSubmit}>
        <div className='custom-file mb-4'>
          <input type='file' className='custom-file-input' id='customFile' onChange={onChange} accept='.csv' />
          <label className='custom-file-label' htmlFor='customFile'>
            {filename}
          </label>
        </div>

        {progressBar ? <Progress percentage={uploadPercentage} /> : null}
        {filename === 'Choose File' ? (
          <input type='submit' value='Upload  Selected File' className='btn btn-secondary btn-block mt-4' style={{ width: '15%', cursor: 'not-allowed' }} disabled />
        ) : (
          <input type='submit' value='Upload  Selected File' className='btn btn-success btn-block mt-4' style={{ width: '15%' }} />
        )}
      </form>
      {loader ? (
        <div className='d-flex justify-content-center'>
          <img src={Loader} height='100px' style={{ paddingTop: '20px' }} alt='Loader' />
        </div>
      ) : null}

      <div
        className='mt-5'
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
          gap: '1rem',
          border: '1px solid black',
          padding: '1rem',
          maxHeight: '300px',
          overflow: 'auto',
        }}
      >
        {showData === undefined ? <JSONPretty data={JSON.stringify(result, null, 2)}></JSONPretty> : <JSONPretty data={Result1}></JSONPretty>}
      </div>
      <br />

      {showData === undefined ? (
        <>
          {result ? (
            <button className='btn btn-success' style={{ marginBottom: '20px' }} onClick={copyToClipboard}>
              Copy to Clipboard
            </button>
          ) : (
            <button className='btn btn-secondary' style={{ marginBottom: '20px' }} disabled>
              Copy to Clipboard
            </button>
          )}
        </>
      ) : (
        <>
          {Result1 ? (
            <button className='btn btn-success' style={{ marginBottom: '20px' }} onClick={copyToClipboardOfresult1}>
              Copy to Clipboard
            </button>
          ) : (
            <button className='btn btn-secondary' style={{ marginBottom: '20px' }} disabled>
              Copy to Clipboard
            </button>
          )}
        </>
      )}

      <ToastContainer position='top-center' />
    </Fragment>
  );
};

export default FileUpload;
