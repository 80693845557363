/** @format */

import React from 'react';
import { useEffect, useState } from 'react';
import CONFIG from '../../config/config.js';
import { FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { Box, Button } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import DrawerCloseIcon from '../../assets/icons/drawer-alert/close.svg';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerSaveIcon from '../../assets/icons/drawer-alert/drw-save.svg';
import CircleIcon from '@mui/icons-material/Circle';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

const upcharges = [
  {
    value: '0',
    label: '0',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '10',
    label: '10',
  },
  {
    value: '15',
    label: '15',
  },
  {
    value: '20',
    label: '20',
  },
];

export default function PriceDrawer({ data, func }) {
  const { proposal } = data;

  useEffect(() => {
    setBaseTotal(+proposal.base_final_total);
  }, [proposal]);

  const [baseTotal, setBaseTotal] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [upcharge, setUpcharge] = useState(proposal.upcharge);
  const [fixedPrice, setFixedPrice] = useState(proposal.is_calculated_fixed_price);
  const [discount, setDiscount] = useState((+proposal.discount).toFixed(2));
  const [discountDollar, setDiscountDollar] = useState(proposal.discount_dollar);
  const [commission, setCommission] = useState(+proposal.commission);
  const [commissiondollar, setCommissionDollar] = useState(+proposal.commissions_dollar);
  const [ProposalTAD, setProposalTAD] = useState(proposal.final_total);
  const [targetPrice, setTargetPrice] = useState(proposal.final_total);
  const [showFixedPriceCalculator, setShowFixedPriceCalculator] = useState(proposal.is_calculated_fixed_price);
  const [showtargetPriceFlag, setShowTargetPriceFlag] = useState(false);
  const [discountCommissionList, setDiscountCommissionList] = useState([]);

  const token = sessionStorage.getItem('token');

  const [PriceBaseOpen, setPriceBaseOpen] = useState(false);
  const [DiscountOpen, setDiscountOpen] = useState(false);
  const [showDiscount, setShowDiscount] = useState(proposal.show_disc);

  func(isDrawerOpen);

  const closeDrawer = () => setIsDrawerOpen(false);

  const HandleDiscount = (discount) => {
    setDiscount(discount);
    discountCommissionList.forEach((commission) => {
      if (commission.price_base_level === upcharge && commission.discount === discount) {
        setCommission(+commission.commission);
        handleTargetPriceChange(upcharge, discount, commission.commission, targetPrice);
      }
    });
  };

  const handleTargetPrice = ({ target }) => {
    const isValidInput = (value) => {
      const regex = /^(?!.*\..*\.)[0-9]*(\.[0-9]{0,2})?$/;
      return regex.test(value);
    };
    const fixedPrice = target.value;

    if (isValidInput(fixedPrice)) {
      setTargetPrice(fixedPrice);
      handleTargetPriceChange(upcharge, discount, commission, fixedPrice);
    }
  };

  const openFixedPriceCalculator = () => {
    setShowFixedPriceCalculator(!showFixedPriceCalculator);
    setDiscount(discount);
    setUpcharge('0');
    setShowDiscount(false);
    setTargetPrice(ProposalTAD);
  };

  useEffect(() => {
    getDiscountCommissionList();
  }, [showFixedPriceCalculator]);

  const getDiscountCommissionList = async () => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        price_base_level: upcharge,
      }),
    };
    const response = await fetch(`${CONFIG.API_URL}/pm/GetDiscountCommissionList`, options);
    const data = await response.json();
    if (!response.ok || data.error) {
      return;
    }
    setDiscountCommissionList(data.commissions);
  };

  const handlePriceBase = (basePrice) => {
    setUpcharge(basePrice);
    handlePriceBaseChange(basePrice);
  };

  const handlePriceBaseChange = async (priceBase) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        price_base_level: priceBase,
      }),
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/GetDiscountCommissionList`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      return;
    }
    setDiscountCommissionList(data.commissions);
    const discountCommision = data.commissions.filter((commission) => {
      if (commission.price_base_level === priceBase.toString() && commission.discount === Math.floor(discount).toString()) {
        setCommission(+commission.commission);
        return true;
      }
      return false;
    });
    handleTargetPriceChange(priceBase, discount, discountCommision[0].commission, targetPrice);
  };

  const handleTargetPriceChange = async (priceBase, discount, commission, targetPrice) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: proposal.proposal_id,
        price_base_level: priceBase,
        discount: discount,
        commission: commission,
        retail_price: baseTotal,
        option: showFixedPriceCalculator,
        target_price: targetPrice,
        retail_total_with_install: proposal.retail_total_with_install,
      }),
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/PricingCalculations`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      setShowTargetPriceFlag(true);
      return;
    }
    if (targetPrice === '') {
      targetPrice = 'No';
    }

    setCommission(+data.pricingData.commission);
    setDiscount(+data.pricingData.discount);
    setCommissionDollar(+data.pricingData.commission_dollar);
    setDiscountDollar(+data.pricingData.discount_dollar);

    setProposalTAD(+data.pricingData.final_customer_cost);
    setShowTargetPriceFlag(false);
  };

  const savePricingCalculations = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        proposal_id: proposal.proposal_id,
        is_calculated_fixed_price: showFixedPriceCalculator,
        upcharge: upcharge,
        commission: commission,
        discount: discount,
        show_discount: showDiscount,
      }),
    };

    const response = await fetch(`${CONFIG.API_URL}/pm/SavePricingCalculations`, requestOptions);
    const data = await response.json();
    if (!response.ok || data.error) {
      return;
    }
    closeDrawer();
  };

  const useStyles = makeStyles((theme) => ({
    textfield_input: {
      color: showtargetPriceFlag ? '#AA0D0D !important' : '#333333 !important',
    },
  }));

  const classes = useStyles();

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => setIsImageHovered(!isImageHovered);

  return (
    <Box pt={5} className='sideDrawer'>
      <div align='right'>
        <img alt='CANCELDRAWER' src={DrawerCloseIcon} onClick={closeDrawer} className='drawer_secondary_icons_dimension drawer_cancel_cursor' />
      </div>
      <Box textAlign='center' paddingTop={2} sx={{ padding: '0px 8px 0px', display: 'flex', flexDirection: 'column' }} className='drawer_Header_page'>
        <FormControl onClick={!showFixedPriceCalculator ? () => setPriceBaseOpen(!PriceBaseOpen) : null} className='formcontrol drawer_textfield'>
          <Select
            MenuProps={{
              style: { zIndex: 10000000 },
            }}
            size='small'
            select
            value=''
            onClose={() => setPriceBaseOpen(false)}
            onOpen={() => setPriceBaseOpen(true)}
            open={PriceBaseOpen}
            onChange={({ target }) => handlePriceBase(target.value)}
            options={upcharges}
            disabled={showFixedPriceCalculator}
            displayEmpty
            startAdornment={
              <InputAdornment className={`color cursor ${showFixedPriceCalculator ? 'disabled' : ''}`} position='start'>
                Price Base : &nbsp;
                <b>{upcharge === '' ? <span>─ ─</span> : upcharge}</b>
              </InputAdornment>
            }
          >
            {upcharges.map((element) => (
              <MenuItem key={element.value} value={element.value}>
                <b>{element.label}</b>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl onClick={!showFixedPriceCalculator ? () => setDiscountOpen(!DiscountOpen) : null} className='formcontrol drawer_textfield'>
          <Select
            size='small'
            MenuProps={{
              style: { zIndex: 10000000 },
            }}
            sx={{ '&.disabled': { color: 'white' } }}
            onClose={() => setDiscountOpen(false)}
            onOpen={() => setDiscountOpen(true)}
            open={DiscountOpen}
            disabled={showFixedPriceCalculator}
            onChange={(e) => HandleDiscount(e.target.value)}
            value=''
            displayEmpty
            startAdornment={
              <InputAdornment className={`color cursor ${showFixedPriceCalculator ? 'disabled' : ''}`} position='start'>
                Discount :&nbsp;<b>{discount > '0.00' ? `${parseFloat(discount).toFixed(2)} %` : '0.00%'} </b>
              </InputAdornment>
            }
          >
            {discountCommissionList.map((option) => (
              <MenuItem key={option.discount} value={option.discount}>
                <b>{option.discount}</b>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {discount > '0.00' && !showFixedPriceCalculator ? (
          <FormControl
            sx={{
              width: '100%',
              color: 'white',
              marginTop: '.75em',
            }}
          >
            <FormLabel
              sx={{
                textAlign: 'left',
                color: 'white',
                '&.Mui-focused': {
                  color: 'white',
                },
              }}
              id='show_discount'
            >
              Show Discount In :
            </FormLabel>
            <RadioGroup aria-labelledby='show_discount' name='show_discount' value={showDiscount} onChange={() => setShowDiscount(!showDiscount)}>
              <FormControlLabel
                value={true}
                sx={{
                  marginTop: '.75em',
                }}
                control={
                  <Radio
                    sx={{
                      color: 'white',
                      marginLeft: '1em',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                    }}
                  />
                }
                label='Summary'
              />
              <FormControlLabel
                value={false}
                control={
                  <Radio
                    sx={{
                      color: 'white',
                      marginLeft: '1em',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                    }}
                  />
                }
                label='Line Items'
              />
            </RadioGroup>
          </FormControl>
        ) : null}

        <hr className='button_top_space' />

        <Box onClick={openFixedPriceCalculator} className='common_display_flex_css button_top_space'>
          <Box>
            {!showFixedPriceCalculator ? (
              <Box className='switch_icon_before'>
                <CircleIcon className='cursor notes_color' />
                <MoneyOffIcon sx={{ cursor: 'pointer' }} className='notes_color' />
              </Box>
            ) : (
              <Box className='switch_icon_after'>
                <MoneyIcon sx={{ cursor: 'pointer' }} className='notes_color' />
                <CircleIcon className='cursor notes_color' />
              </Box>
            )}
          </Box>
          <Box sx={{ color: 'white', marginLeft: '10px' }}>
            <Typography>Fixed Price Calculator</Typography>
          </Box>
        </Box>

        {showFixedPriceCalculator ? (
          <FormControl className='formcontrol drawer_textfield_1'>
            <TextField
              id='filled-basic'
              InputProps={{
                style: { fontWeight: 'bold' },
              }}
              label={showtargetPriceFlag ? <span style={{ color: '#AA0D0D' }}>Please Enter Appropriate Fixed Price</span> : 'Fixed Price'}
              variant='filled'
              value={targetPrice}
              inputProps={{ className: classes.textfield_input }}
              onChange={handleTargetPrice}
              placeholder='Enter Fixed Price'
              options={upcharges}
              displayEmpty
              startAdornment={
                <InputAdornment className='color' position='start'>
                  Fixed Price : $
                </InputAdornment>
              }
            />
          </FormControl>
        ) : null}

        <hr className='button_top_space button_bottom_space' />

        {/* Proposal Totals */}
        <div className='drawer_price_total'>
          <Box className='price_drawer_box'>
            <Box>Proposal Base Total :</Box>
            <Box sx={{ fontWeight: 'bold' }}>${baseTotal.toFixed(2)}</Box>
          </Box>
          <Box className='price_drawer_box'>
            <Box>Discount ({+discount > 0 ? `${parseFloat(discount).toFixed(2)}%` : '0.00%'}) :</Box>
            <Box sx={{ fontWeight: 'bold' }}>${discountDollar > 0 ? parseFloat(discountDollar).toFixed(2) : '0.00'}</Box>
          </Box>
          <Box className='price_drawer_box_1'>
            <Box className='proposal_total_text'>
              <span className='proposal_total_text_2'>Proposal Total After Adjustments :</span>
            </Box>
            <Box sx={{ fontWeight: 'bold' }}>${parseFloat(ProposalTAD).toFixed(2)}</Box>
          </Box>
        </div>

        <hr className='button_top_space button_bottom_space' />

        <div className='drawer_price_total'>
          <Box className='price_drawer_box'>
            <Box sx={{ margin: 0, padding: 0 }}>Commission ({commission.toFixed(2)}%) :</Box>
            <Box>${commissiondollar.toFixed(2)}</Box>
          </Box>
        </div>

        <hr className='button_top_space button_bottom_space' />
      </Box>

      <Stack sx={{ marginLeft: '10px' }} direction='column' spacing={0}>
        <Button className='drawer_gray_button_span' onClick={savePricingCalculations} style={{ paddingBottom: '0px', paddingLeft: '0px', justifyContent: 'left' }}>
          <Box className='globalIconMainBox globalDrawerText'>
            <img alt='SAVED' src={DrawerSaveIcon} className={`primary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} />
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText' onMouseEnter={handleHover} onMouseLeave={handleHover}>
              SAVE
            </Typography>
          </Box>
        </Button>

        <Button className='drawer_gray_button_span button_top_space' onClick={closeDrawer} style={{ paddingBottom: '30px', paddingLeft: '14px', justifyContent: 'left' }}>
          <Box className='globalIconMainBox'>
            <img alt='CANCEL' src={DrawerCancelIcon} className='drawer_secondary_icons_dimension' />
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='drawerGlobalText'>
              CANCEL
            </Typography>
          </Box>
        </Button>
      </Stack>
    </Box>
  );
}
