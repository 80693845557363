/** @format */
import 'react-loading-skeleton/dist/skeleton.css';

import { useNavigate, useLocation, Link } from 'react-router-dom';
import React, { forwardRef, useState, useEffect } from 'react';
import {
  Typography,
  Container,
  Box,
  Drawer,
  Snackbar,
  Alert as MuiAlert,
  Paper,
  IconButton,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Stack,
  TextField,
  Avatar,
  FormControl,
  Select,
  InputAdornment,
  MenuItem,
  Badge,
  createTheme,
  ThemeProvider,
  Zoom,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import Moment from 'react-moment';
import moment from 'moment/moment';
import Skeleton from 'react-loading-skeleton';

import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

import { Visibility as VisibilityIcon, ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon, Add as AddIcon, CheckCircle as CheckCircleIcon, CancelRounded as CancelRoundedIcon, Star as StarIcon, StarOutline as StarOutlineIcon } from '@mui/icons-material';

import EditCustomerName from './EditCustomerName';
import SalesOrderTableBody from './SalesOrderTableBody';
import AddWindowSet from '../WindowsManagement/AddWindowSet';
import NotesDrawer from '../Proposals/NotesDrawer';

import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import WindowsetaddIcon from '../../assets/icons/primary/windowset-add.svg';
import calenderIconAdd from '../../assets/icons/primary/calendar-add.svg';
import calenderIcon from '../../assets/icons/primary/calendar.svg';
import ChangeAddress from '../../assets/icons/secondary/2nd-address.svg';
import ChangeDateIcon from '../../assets/icons/secondary/2nd-calendar.svg';
import NoteMiscIcon from '../../assets/icons/misc/misc-note.svg';
import EditMiscIcon from '../../assets/icons/misc/misc-edit.svg';
import PhoneMiscIcon from '../../assets/icons/misc/misc-phone.svg';
import LocationMiscIcon from '../../assets/icons/misc/misc-address.svg';
import EmailMiscIcon from '../../assets/icons/misc/misc-email.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import WindowSetHovIcon from '../../assets/icons/primary/WithHovIcon/windowset-add-hov.svg';
import CalenderHovIcon from '../../assets/icons/primary/WithHovIcon/calender-add-hov.svg';
import LongText from '../LongText';

import CONFIG from '../../config/config.js';

const TurquoiseTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#0ca8b0',
    color: '#ffffff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 90,
    textAlign: 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#0ca8b0',
      border: '1px solid #0ca8b0',
    },
  },
}));

const TurquoiseTooltipforSalesRep = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#808080',
    color: '#ffffff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 90,
    textAlign: 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    '&::before': {
      backgroundColor: '#808080',
      border: '1px solid #808080',
    },
  },
}));

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const theme = createTheme({
  palette: {
    border: {
      main: '1px solid #000',
    },
    secondary: {
      main: '#FAC41E',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "green", // date picker textfield style
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#008593 !important',
          },
          '&.Mui-disabled': {
            backgroundColor: 'white', // disable date
            color: '#E9E9E9',
          },
        },
        dayWithMargin: {
          backgroundColor: 'white', // not selected date
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          // backgroundColor: "blue" //calender icon & month change arrow icon style
        },
      },
    },
  },
});

const useStyles = makeStyles({
  underline: {
    '&&&:before': {
      borderBottom: 'none',
    },
    '&&:after': {
      borderBottom: 'none',
    },
  },
  paper: {
    background: 'rgba(69, 157, 166, 70%)  !important',
  },
  customBadge: {
    backgroundColor: '#FAC41E',
    border: '1px solid black',
  },
  backDrop: {
    backdropFilter: 'blur(5px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
  StaticDatePicker: {
    height: '360px',
  },
  DatePicker: {
    height: '360px',
  },
});

const CustomerDetailsforOldCustomer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user_id = sessionStorage.getItem('user_id');
  const user = sessionStorage.getItem('user');
  const token = sessionStorage.getItem('token');

  if (location.state === null) {
    navigate('/pagenotfound');
  }

  let Address_Id = location.state.Custdata.Addressid;
  let CustomerVal = location.state.Custdata.custid;
  let LnameVal = location.state.Custdata.LName;
  let FnameVal = location.state.Custdata.Husband;
  let EmailVal = location.state.Custdata.emailaddress;
  let StateNameVal = location.state.Custdata.state;
  let cityVal = location.state.Custdata.City;
  let ZipVal = location.state.Custdata.zip;
  let AddressLineVal = location.state.Custdata.Address1;

  const notesDataDescription = [];

  const today = new Date();
  const tomorrow = new Date(today);

  tomorrow.setDate(tomorrow.getDate() + 1);

  const [value, setValue] = useState(today);
  const [valueincalendar, setValueinCalendar] = useState(today);

  const [editingNote, setEditingNote] = useState(false);
  const [noteDrawerDetails, setNoteDrawerDetails] = useState({});
  const [notesDrawerOpen, setNotesDrawerOpen] = useState(false);

  const [showCreateWindowSetComponent, setShowCreateWindowSetComponent] = useState(false);
  const [WindowSetData, setWindowSetData] = useState([CONFIG.WindowsManagement.WindowSetLimit]);
  const [proposaldata, setProposalData] = useState([]);
  const [windowsetdatalength, setWindowSetDataLength] = useState([]);
  const [customer, setCustomerData] = useState([]);
  const [customerInfo, setCustomerInfo] = useState([]);
  const [PrimaryAddress, setPrimaryAddress] = useState([]);
  const [singleAddress, setSingleAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [emailLength, setemailLength] = useState();
  const [email, setEmail] = useState();
  const [emailAddress, setEmailAddress] = useState();
  const [Address, setAddress] = useState([]);
  const [City, setCustCity] = useState([]);
  const [fullname, setFullName] = useState();
  const [firstName, setFirstName] = useState('');
  const [wifename, setWifeName] = useState('');
  const [lastname, setLastName] = useState('');
  const [phoneno, setPhoneNo] = useState();
  const [AddtionalNumber, setAddtionalNumber] = useState();
  const [AnotherPhoneNumber, setAnotherPhoneNumber] = useState();
  const [PhoneNumberOne, setPhoneNumberOne] = useState();
  const [PhoneNumberTwo, setPhoneNumberTwo] = useState();
  const [viewwindowset, setViewWindowSet] = useState(true);
  const [viewproposal, setViewProposal] = useState(false);
  const [viewappointment, setViewAppointment] = useState(false);
  const [senddatatonewWindow, setSendDatatoNewWindow] = useState('');
  const [alertmessage, setAlertMessage] = useState('');
  const [buttonNameFlag, setButtonNameFlag] = useState(false);
  const [showtabledata, setShowTableData] = useState(false);
  const [coveringdetails, setCoveringDetails] = useState();
  const [viewTableFlag, setViewTableFlag] = useState(false);
  const [viewButtonFlag, setViewButtonFlag] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [successmessage, setSuccessMessage] = useState('');
  const [classtype, setClassType] = useState('');
  const [customerId, setCustomerId] = useState(CustomerVal);
  const [legacyInvoice, setLegecyInvoice] = useState([]);
  const [openDropDown, setOpenDropDown] = useState(true);
  const [dropDown, setDropDown] = useState(true);
  const [showName, setShowName] = useState('');
  const [PhoneNumbers, setPhoneNumbers] = useState();
  const iconclasses = makeStyles(iconStyles)();
  const [SalesOrderData, setSalesOrderData] = useState([]);
  const [openCloseSalesOrder, setOpenCloseSalesOrder] = useState(false);
  const [getSalesRep, setGetSalesRep] = useState();

  const [getAppointmentTime, setGetAppointmentTime] = useState();
  const [getAppointmentLenght, setGetAppointmentLength] = useState();
  const [getAppointmentNotes, setGetAppointmentNotes] = useState();

  const [openScheduleAppiontment, setOpenScheduleAppiontment] = useState(false);

  const [getAppointment, setGetAppointment] = useState([]);

  const [newAppointmentButton, setNewAppointmentButton] = useState(false);

  const [editButtonToOpenCalender, setEditButtonToOpenCalender] = useState(false);
  const [editOpenSelectAppointmentLenght, setEditOpenSelectAppointmentLenght] = useState(false);
  const [closeAppointmentLenght, setCloseAppointmentLenght] = useState(false);
  const [closeListAfterClickEdit, setCloseListAfterClickEdit] = useState(false);
  const [editCalenderOpen, setEditCalenderOpen] = useState(false);
  const [notesContent, setnotesContent] = useState([]);
  const [eyeButtonMsg, setEyeButtonMsg] = useState(false);
  const [EditNotes, setEditNote] = useState('');
  const [notesData, setNotesData] = useState('');
  const [noteTypeId, setNoteTypeId] = useState('');
  const [noteId, setNoteId] = useState();
  const [salesRepOpen, setSalesRepOpen] = useState(false);
  const [salesRepOpen2, setSalesRepOpen2] = useState(false);
  const [lengthOfAppointmentOpen, setLengthOfAppointmentOpen] = useState(false);
  const [lengthOfAppointmentOpen2, setLengthOfAppointmentOpen2] = useState(false);
  const [lengthOfAppointmentOpen3, setLengthOfAppointmentOpen3] = useState(false);
  const [appointmentTimeOpen, setAppointmentTimeOpen] = useState(false);
  const [appointmentTimeOpen2, setAppointmentTimeOpen2] = useState(false);

  const [isHovered, setIsHovered] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);

  const [valueValue, setValueValue] = useState();

  const [EditTime, setSetEditTime] = useState('');

  const [openSelectTimeDuration, setOpenSelectTimeDuration] = useState(false);

  const [getAppointmentID, setGetAppointmentID] = useState();

  const [getSalesRepAppointment, setGetSalesRepAppointment] = useState([]);
  const [appointmentData, setAppointmentData] = useState(getSalesRepAppointment);

  const [cancel, setCancel] = useState(false);

  const [numberOfAppointment, setNumberOfAppointment] = useState();

  const [CustomerApiError, setCustomerApiError] = useState(false);
  const [PopulateCSPListApiError, setPopulateCSPListApiError] = useState(false);
  const [GetLookupTableDataApiError, setGetLookupTableDataApiError] = useState(false);
  const [PopulateCSPListApiErrorMsg, setPopulateCSPListApiErrorMsg] = useState('');
  const [GetLookupTableDataApiErrorMsg, setGetLookupTableDataApiErrorMsg] = useState('');
  const [CustomerApiErrorMsg, setCustomerApiErrorMsg] = useState('');

  // Pagination States
  const [windowSetsPage, setWindowSetsPage] = useState(0);
  const [windowSetsPerPage, setWindowSetsPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);
  const [proposalPage, setProposalPage] = useState(0);
  const [proposalsPerPage, setProposalsPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);
  const [salesOrderPage, setSalesOrderPage] = useState(0);
  const [salesOrdersPerPage, setSalesOrdersPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);
  const [legacyInvoicesPerPage, setLegacyInvoicesPerPage] = useState(CONFIG.WindowsManagement.RowperPageLimit);
  const [legacyInvoicePage, setLegacyInvoicePage] = useState(0);

  const [addressID, setAddressID] = useState();

  const [salesOrderCustomerDetails, setSalesOrderCustomerDetails] = useState([]);

  const [open, setOpen] = useState(false);

  const [customerLastName, setCustomerLastName] = useState(false);
  const [appntTimeOpen, setAppntTimeOpen] = useState(false);

  const currentYear = new Date().getFullYear();
  const fullyear = [];
  const dates = [];
  var count = 1;
  for (let month = 0; month < 1; month++) {
    const numDaysInMonth = new Date(currentYear, month + 1, 0).getDate();
    for (let day = 1; day <= numDaysInMonth; day++) {
      dates.push(new Date(currentYear, month, day));
      fullyear.push(new Date(currentYear, month, day));
    }
    fullyear.map((ele) => {
      const disableAppointments = getAppointment.filter((item) => moment(item.StartTime).local().format('MM/DD/YYYY') === moment(ele).local().format('MM/DD/YYYY'));

      if (fullyear.length === count) {
        disableAppointments.map((val) => {});
      }
      count++;
    });
  }

  const openAppointmentFun = () => {
    setOpenDropDown(false);
  };

  const closeAppointmentFun = () => {
    setOpenDropDown(true);
    setDropDown(cancel);
  };

  const openSalesOrder = () => {
    setOpenCloseSalesOrder(false);
  };

  const closeSalesOrder = () => {
    setOpenCloseSalesOrder(true);
  };

  const callSalesOrderFunction = () => {
    setOpenCloseSalesOrder(!openCloseSalesOrder);
    setViewWindowSet(false);
    setViewProposal(false);
  };

  // appointment module customer info start

  const lengthOfAppointment = [30, 60, 90, 120];

  const timeOfAppointment = [
    '08:00 am',
    '08:30 am',
    '09:00 am',
    '09:30 am',
    '10:00 am',
    '10:30 am',
    '11:00 am',
    '11:30 am',
    '12:00 pm',
    '12:30 pm',
    '01:00 pm',
    '01:30 pm',
    '02:00 pm',
    '02:30 pm',
    '03:00 pm',
    '03:30 pm',
    '04:00 pm',
    '04:30 pm',
    '05:00 pm',
    '05:30 pm',
    '06:00 pm',
    '06:30 pm',
    '07:00 pm',
    '07:30 pm',
    '08:00 pm',
    '08:30 pm',
  ];

  useEffect(() => {
    window.scrollTo(0, 0);

    setViewTableFlag(false);
    getVerified();

    getCustomerDetails();
    getCoveringCriteria();
    getSaleAppDropDowns();
    getAllAppointment();
    getAllSalesRepAppointment();
  }, []);

  //  hover states

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  const handleHoverFun3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeaveFun3 = () => {
    setIsHovered3(false);
  };

  const NotesCustomerDetails = {
    firstName: firstName,
    lastname: lastname,
    customerId: customer.customer_id,
    proposalId: '',
    windowSetId: '',
  };

  const EditNotesCustomerDetails = {
    firstName: firstName,
    lastname: lastname,
    customerId: customer.customer_id,
    proposalId: '',
    windowSetId: '',
    notesContent: notesData,
    noteId: noteId,
    noteTypeId: noteTypeId,
    eyeButtonMsg: eyeButtonMsg,
    notesDataDescription: notesDataDescription,
    valueValue: valueValue,
  };

  function iconStyles() {
    return {
      salesrepIcon: {
        fontSize: 'medium !important',
        color: '#808080',
      },
      customerIcon: {
        fontSize: 'medium !important',
        color: '#0CA8B0',
      },
      proposalsentIcon: {
        fontSize: 'medium !important',
        color: '#0CA8B0',
      },
      spaceIcon: {
        fontSize: 'medium !important',
        color: 'white',
      },
    };
  }

  const handleChangeFun = (newValue) => {
    setValue(newValue.$d);
    setOpenScheduleAppiontment(true);
  };

  const timer = setTimeout(() => {
    setCloseAppointmentLenght(false);
  }, 200);

  const closeScheduleAppiontmentFun = () => {
    setOpenScheduleAppiontment(true);
    // newadd
  };

  const ScheduleAppointment = async () => {
    await fetch(`${CONFIG.API_URL}/cm/ScheduleAppointment`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        RemeasureDate: value,
        ScheduleID: '',
        PersonID: user_id,
        StartTime: getAppointmentTime,
        CustID: customerId,
        AddressID: addressID,
        Duration: getAppointmentLenght,
        Edit: '',
        Notes: getAppointmentNotes,
        SecuserID: '',
        OverRidden: '',
        OverrideReason: '',
        ReferredBy: '',
        ReferredByType: '',
      }),
    })
      .then((data) => data.json())
      .then((data) => {});
    getAllAppointment();
  };

  const sendUpdateButtonTrueFun = (appointment_id) => {
    setGetAppointmentID(appointment_id);
    setEditButtonToOpenCalender(true);
  };

  const sentEditTime = (getEditTime, lenght, notes) => {
    setValueinCalendar(moment(getEditTime).local().format('MMMM DD, YYYY'));
    setValue(getEditTime);

    setGetAppointmentTime(moment(getEditTime).utc().format('hh:mm a'));
    setGetAppointmentLength(lenght);
    setGetAppointmentNotes(notes);
  };

  const editHandleChangeFun = (newValue) => {
    setValue(newValue.$d);
  };

  useEffect(() => {
    setSetEditTime(value);
  }, [value]);

  const EditScheduleAppointment = async () => {
    await fetch(`${CONFIG.API_URL}/cm/ScheduleAppointment`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        RemeasureDate: EditTime,
        ScheduleID: getAppointmentID,
        PersonID: user_id,
        StartTime: getAppointmentTime,
        CustID: customerId,
        AddressID: addressID,
        Duration: getAppointmentLenght,
        Edit: '1',
        Notes: getAppointmentNotes,
        SecuserID: '',
        OverRidden: '',
        OverrideReason: '',
        ReferredBy: '',
        ReferredByType: '',
      }),
    })
      .then((data) => data.json())
      .then((data) => {});
    getAllAppointment();
  };

  const openNewAppointmentSelectDateFun = () => {
    setNewAppointmentButton(true);
    setGetAppointmentTime('');
    setGetAppointmentLength('');
    setGetAppointmentNotes('');
  };

  const getAllAppointment = async () => {
    await fetch(`${CONFIG.API_URL}/cm/GetSalesRepAppointments`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        personID: user_id,
        custID: customerId,
        done: 'notdone',
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setNumberOfAppointment(data.count);
          if (customerId === undefined) {
            setGetAppointment(data.SalesRepAppointments.recordset);
          } else {
            setGetAppointment(data.SalesRepAppointments);
          }
        }
      });
  };

  const getAllSalesRepAppointment = async () => {
    await fetch(`${CONFIG.API_URL}/cm/GetSalesRepAppointments`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        personID: user_id,
        done: 'notdone',
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setGetSalesRepAppointment(data.SalesRepAppointments.recordset);
          setAppointmentData(data.SalesRepAppointments.recordset);
        }
      });
  };

  const tomorrowAppointments = getAppointment.filter((item) => moment(item.StartTime).local().format('MM/DD/YYYY') === moment(today).local().format('MM/DD/YYYY'));

  let phone;

  const vertical = 'top';
  const horizontal = 'center';

  //Get token
  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  let customerDetails = {
    customerdata: salesOrderCustomerDetails,
  };

  const getCustomerDetails = async () => {
    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        customer_id: CustomerVal,
        address_id: Address_Id,
        f_name: FnameVal,
        l_name: LnameVal,
        email_id: EmailVal,
        state_name: StateNameVal,
        city: cityVal,
        zip: ZipVal,
        address_line: AddressLineVal,
      }),
    };

    await fetch(`${CONFIG.API_URL}/cm/Customer`, requestOptions)
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setnotesContent(data.customer_notes);
          setCustomerInfo(data.customer);
          setSingleAddress(data.customer);
          setSelectedAddress(data.customer);

          setemailLength(data.customer.email.length);
          setEmailAddress(data.customer.email);
          setEmail(data.customer.email_id);
          setPrimaryAddress(data.customer.another_address[0]);
          setSalesOrderCustomerDetails(data.customer);
          setPhoneNo(data.customer.mobile);
          setAddtionalNumber(data.customer.another_phone_numbers);

          setPhoneNumberOne(data.customer.another_phone_numbers[0]);
          setAnotherPhoneNumber(data.customer.another_phone_numbers.length);
          setPhoneNumberTwo(data.customer.another_phone_numbers[1]);
          setViewButtonFlag(true);
          setAddress(data.customer.another_address);

          setFirstName(data.customer.first_name);
          setWifeName(data.customer.wife);
          setLastName(data.customer.last_name);

          setAddressID(data.customer.address_id);

          setShowName(data.customer.first_name);
          setPhoneNumbers(data.customer.another_phone_numbers);

          setCustomerId(data.customer.customer_id);
          setCustomerLastName(data.customer.last_name);

          // If token is valid then data will pass
          if (data.customer_windowsets.length > 0) {
            setShowTableData(true);
            setViewWindowSet(false);
            setWindowSetData(data.customer_windowsets);
            setSalesOrderData(data.customer_sales_order_list);
            setLegecyInvoice(data.legacy_invoice);

            setWindowSetDataLength(data.customer_windowsets);
            setCustomerData(data.customer);

            setCustCity(data.customer.city);

            setProposalData(data.customer_proposal_list);
            setFullName(data.customer.first_name + ' ' + data.customer.last_name);
            setFirstName(data.customer.first_name);

            setWifeName(data.customer.wife);

            setLastName(data.customer.last_name);

            if (WindowSetData.length < 5) {
              setAlertMessage(false);
            } else {
              setAlertMessage(true);
            }
            if (WindowSetData.length === 0) {
              setButtonNameFlag(false);
              setViewTableFlag(false);
            } else {
              setButtonNameFlag(true);
              setViewTableFlag(true);
            }
            setSendDatatoNewWindow({
              showprompt: data.customer_windowsets.length,
              windowsetdata: data.customer_windowsets[0],
              rowlength: data.customer_windowsets.length,
            });
          } else {
            setFullName(data.customer.first_name + ' ' + data.customer.last_name);

            setCustomerData(data.customer);
            setWindowSetData(data.customer_windowsets);

            // ticket TB-184
            if (WindowSetData && location.state.ScheduleAppointmentMsg === 'Customer Created & Schedule') {
              setViewWindowSet(false);
              setOpenDropDown(false);

              setOpenSelectTimeDuration(true);
            } else if (WindowSetData) {
              setViewWindowSet(true);
            } else {
              setViewWindowSet(false);
            }

            setSendDatatoNewWindow({
              showprompt: 0,
              windowsetdata: data.customer,
              rowlength: 0,
            });
          }
        } else if (data.error === true) {
          if (data.error.message === 'Token is not valid') {
            navigate(`/login`, {
              state: { error: 'Unauthorized Please Login' },
            });
          }
          setCustomerApiError(true);
          setCustomerApiErrorMsg(data.error_message);
        }
      });
  };

  const getCoveringCriteria = async () => {
    const response = await fetch(`${CONFIG.API_URL}/pm/PopulateCSPList`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      token: token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setCoveringDetails(data.MaterialList);
        } else {
          setPopulateCSPListApiErrorMsg(data.error_message);
        }
      });
  };
  const getSaleAppDropDowns = async () => {
    const response = await fetch(`${CONFIG.API_URL}/wm/GetLookupTableData`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      token: token,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
        } else {
          setGetLookupTableDataApiErrorMsg(data.error_message);
          setGetLookupTableDataApiError(true);
        }
      });
  };

  const callWindowSetCaretFunction = () => {
    setViewWindowSet((prev) => !prev);
    if (viewproposal) setViewProposal(false);
    setOpenCloseSalesOrder(false);
    setOpenCloseSalesOrder(false);
  };

  const callCaretFunction = () => {
    setViewProposal((prev) => !prev);
    if (viewwindowset) setViewWindowSet(false);
    setOpenCloseSalesOrder(false);
    setOpenCloseSalesOrder(false);
  };

  const callCaretFunctionforAppointment = () => {
    setOpenDropDown(!openDropDown);
    setViewAppointment((prev) => !prev);
    if (viewwindowset) setViewWindowSet(true);
    getAllAppointment();
    setOpenCloseSalesOrder(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function handleChange(newValue, val) {
    setShowCreateWindowSetComponent(newValue);

    if (val) {
      getCustomerDetails();
    }
  }

  const createWindowSet = () => {
    if (WindowSetData.length < 5) {
      navigate(`/tbm/ps/createnewwindowset`, {
        state: { data: senddatatonewWindow, address_ID: Address_Id },
      });
      setAlertMessage(false);
    } else {
      setAlertMessage(true);
    }
  };

  function normalize(phone) {
    //normalize string and remove all unnecessary characters
    phone = phone.replace(/[^\d]/g, '');

    //Check if number length equals to 10
    if (phone.length === 10) {
      //Reformat and return phone number
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    return null;
  }

  const classes = useStyles();

  const customerNameUpdateDrawer = (name, drawerstatus, message) => {
    setIsDrawerOpen(drawerstatus);
    setSuccessMessage(message);
    if (message === 'Customer name is updated successfully!') {
      setOpen(true);
      setClassType('Success mainAlert');
      setFullName(name);
    } else {
      setOpen(false);
      setClassType('');
    }
  };

  function ProposalTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='search_table_header2 sticky-column'>Proposal Name </TableCell>
          <TableCell className='table-header-cd-proposal2'># Win.</TableCell>
          <TableCell className='table-header-cd-proposal2'>Total</TableCell>
          <TableCell className='table-header-cd-proposal2'>Disc. </TableCell>
          <TableCell className='table-header-cd-proposal2'>Date</TableCell>
          <TableCell className='table-header-cd-proposal0'>Sales Rep</TableCell>
          <TableCell className='table-header-cd-proposal2'>Base</TableCell>
          <TableCell className='table-header-cd-proposal2'>Sent</TableCell>
          <TableCell className='table-header-cd-proposal0'>Accepted</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function WindowSetTableHead(props) {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='table-header5 sticky-column'>Set Name</TableCell>
          <TableCell sx={{ minWidth: '80px', maxWidth: '80px' }} className='table-headeresw_price_3'>
            # Win.
          </TableCell>
          <TableCell className='table-headeresw_price2'>Date</TableCell>
          <TableCell className='table-header2'>Sales Rep</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function SalesOrderTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='table-header-cd-proposal1 sticky-column'>Sales Order </TableCell>
          <TableCell className='table-header-cd-proposal0'>Work Order#</TableCell>
          <TableCell className='table-header-cd-proposal0'>Conv</TableCell>
          <TableCell className='table-header-cd-proposal0'>Total</TableCell>
          <TableCell className='table-header-cd-proposal0'>Balance</TableCell>
          <TableCell className='table-header-cd-proposal0'>Sales Rep</TableCell>
          <TableCell className='table-header-cd-proposal0'>Sent</TableCell>
          <TableCell className='table-header-cd-proposal0'>Signed</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  function LegacySalesOrderHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className='table-header-cd-proposal1 sticky-column'>Legacy Invoice #</TableCell>
          <TableCell className='table-header-cd-proposal0'>Conv</TableCell>
          <TableCell className='table-header-cd-proposal0'>Total</TableCell>
          <TableCell className='table-header-cd-proposal0'>Balance</TableCell>
          <TableCell className='table-header-cd-proposal0'>Sales Rep</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  const [isImageHovered3, setIsImageHovered3] = useState(false);
  const handleHover3 = () => {
    setIsImageHovered3(!isImageHovered3);
  };

  const [isImageHovered4, setIsImageHovered4] = useState(false);
  const handleHover4 = () => {
    setIsImageHovered4(!isImageHovered4);
  };

  const [isImageHovered5, setIsImageHovered5] = useState(false);
  const handleHover5 = () => {
    setIsImageHovered5(!isImageHovered5);
  };

  const [isImageHovered6, setIsImageHovered6] = useState(false);
  const handleHover6 = () => {
    setIsImageHovered6(!isImageHovered6);
  };

  const [isImageHovered7, setIsImageHovered7] = useState(false);
  const handleHover7 = () => {
    setIsImageHovered7(!isImageHovered7);
  };

  const [isImageHovered8, setIsImageHovered8] = useState(false);
  const handleHover8 = () => {
    setIsImageHovered8(!isImageHovered8);
  };

  const [isImageHovered9, setIsImageHovered9] = useState(false);
  const handleHover9 = () => {
    setIsImageHovered9(!isImageHovered9);
  };
  const [isImageHovered10, setIsImageHovered10] = useState(false);
  const handleHover10 = () => {
    setIsImageHovered10(!isImageHovered10);
  };

  let SalesOrderDetails = [];

  const [SalesOrderDetailsErrorMsg, setSalesOrderDetailsErrorMsg] = useState('');
  const [SalesOrderDetailsErrorAlert, setSalesOrderDetailsErrorAlert] = useState(false);

  const getSalesOrderWindows = async (salesOrderId) => {
    await fetch(`${CONFIG.API_URL}/so/salesorderdetails/${salesOrderId}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          if (data.SalesOrderDetails.every((item) => item.sales_order_status)) {
            navigate('/tbm/ps/finalizesalesorder', {
              state: {
                salesOrderId: salesOrderId,
                customerDetails: customerDetails,
              },
            });
          } else {
            navigate('/tbm/ps/salesorderaddingwindows', {
              state: {
                salesOrderId: salesOrderId,
                customerDetails: customerDetails,
                SalesOrderDetails: SalesOrderDetails,
                salesordername: data.SalesOrder[0].sales_order_name,
                addressId: location.state.Custdata.Addressid,
                customerId: location.state.Custdata.custid,
                SalesOrder: data.SalesOrder,
              },
            });
          }
        } else {
          setSalesOrderDetailsErrorMsg(data.error_message);
          setSalesOrderDetailsErrorAlert(true);
        }
      });
  };

  const closeNotesDrawer = async (alert) => {
    setNotesDrawerOpen(false);
    setEditingNote(false);
    setNoteDrawerDetails({});
    if (alert.error) {
      setAlertMessage(true);
      setSuccessMessage(alert.error);
      setOpen(true);
      setClassType('Error1 mainAlert');
    }
    if (alert.success === 'Note added successfully!' || alert.success === 'Note updated successfully!') {
      await getCustomerDetails();
    }
  };

  const openNotesDrawer = (note) => {
    setEditingNote(!!note);
    setNoteDrawerDetails({
      name: `${firstName} ${lastname}`,
      referenceId: customerId,
      noteId: note ? note.id : null,
    });
    setNotesDrawerOpen(true);
  };

  return (
    <div style={{ paddingBottom: '10px' }}>
      <div align='center'>
        <Stack spacing={2} sx={{ paddingLeft: '20%' }}>
          {/* mssql disconnected error  */}
          <Snackbar className='snackbar_bar_postition' open={CustomerApiError} anchorOrigin={{ vertical, horizontal }} onClose={() => setCustomerApiError(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img
                  onClick={() => {
                    setCustomerApiError(false);
                  }}
                  src={RedDrawerCancelIcon}
                  alt=''
                  className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{CustomerApiErrorMsg}</div>
              </div>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={GetLookupTableDataApiError} anchorOrigin={{ vertical, horizontal }} onClose={() => setGetLookupTableDataApiError(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img
                  onClick={() => {
                    setGetLookupTableDataApiError(false);
                  }}
                  src={RedDrawerCancelIcon}
                  alt='RedDrawerCancelIcon'
                  className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{GetLookupTableDataApiErrorMsg}</div>
              </div>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={PopulateCSPListApiError} anchorOrigin={{ vertical, horizontal }} onClose={() => setPopulateCSPListApiError(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img
                  onClick={() => {
                    setPopulateCSPListApiError(false);
                  }}
                  src={RedDrawerCancelIcon}
                  alt='RedDrawerCancelIcon'
                  className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{PopulateCSPListApiErrorMsg}</div>
              </div>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={SalesOrderDetailsErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setSalesOrderDetailsErrorAlert(false)}>
            <Alert className='Error1 mainAlert' variant='filled' icon={false}>
              <div className='alertCancel_2'>
                <img
                  onClick={() => {
                    setSalesOrderDetailsErrorAlert(false);
                  }}
                  src={RedDrawerCancelIcon}
                  alt=''
                  className='drawer_secondary_icons_dimension drawer_cancel_cursor'
                />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={RedExclamationIcon} alt='RedExclamationIcon' className='primary_icons_dimension alertImageMargin' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{SalesOrderDetailsErrorMsg}</div>
              </div>
            </Alert>
          </Snackbar>

          <Snackbar className='snackbar_bar_postition' open={open} anchorOrigin={{ vertical, horizontal }} autoHideDuration={5000} onClose={() => setOpen(false)}>
            {classtype === 'Success' ? (
              <Alert className={classtype} variant='filled' icon={false}>
                <div className='alertCancel'>
                  <IconButton color='inherit' onClick={handleClose}>
                    <CancelRoundedIcon />
                  </IconButton>
                </div>
                <div className='alertMain'>
                  <CheckCircleIcon sx={{ fontSize: 40 }} />

                  <div align='center' style={{ paddingTop: 10 }}>
                    {successmessage}
                  </div>
                </div>
              </Alert>
            ) : (
              <Alert className={classtype} variant='filled' icon={false}>
                <div className='alertCancel'>
                  <IconButton color='inherit' onClick={handleClose}>
                    <CancelRoundedIcon />
                  </IconButton>
                </div>
                <div className='alertMain'>
                  <CheckCircleIcon sx={{ fontSize: 40 }} />
                  <div align='center' style={{ paddingTop: 10 }}>
                    {successmessage}
                  </div>
                </div>
              </Alert>
            )}
          </Snackbar>
        </Stack>
      </div>

      <ThemeProvider theme={theme}>
        <Container style={{ padding: 0 }} fixed>
          <Box className='customer_details'>
            <Box component='div' className='customer_details_margin'>
              {/* Customer Name */}
              {wifename || (lastname && fullname) ? (
                <Box className='Customer_name_box'>
                  <Box sx={{ marginBottom: '15px !important' }} className='Customer_fullname'>
                    {showName === 'NA' ? (wifename === 'NA' ? lastname : wifename + ' ' + lastname) : fullname}
                  </Box>
                  <Box className='edit_icon_customername'>
                    {showName ? (
                      <>
                        <img src={NoteMiscIcon} onClick={() => openNotesDrawer()} alt='NoteMiscIcon' className='misc_icons_dimensions' />

                        <img
                          sx={{ marginTop: '10px !important' }}
                          onClick={() => {
                            navigate('/tbm/ps/editcustomerfirstlastname', {
                              state: {
                                firstName: firstName,
                                lastname: lastname,
                                wifename: wifename,
                                customer_id: customer.customer_id,
                                address_id: addressID,
                              },
                            });
                          }}
                          src={EditMiscIcon}
                          alt='EditMiscIcon'
                          className='misc_icons_dimensions marginLeftCustomerInfo'
                        />
                      </>
                    ) : (
                      <img src={EditMiscIcon} className='misc_icons_dimensions' alt='EditMiscIcon' />
                    )}
                  </Box>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography sx={{ marginBottom: '15px !important' }} className='clickable_header'>
                    <span>
                      <Skeleton width={160} height={25} />
                    </span>
                  </Typography>
                  <span>
                    <img src={EditMiscIcon} className='misc_icons_dimensions' alt='EditMiscIcon' />
                  </span>
                </Box>
              )}
              <Drawer
                PaperProps={{
                  sx: { width: '90% !important' },
                }}
                classes={{ paper: classes.paper }}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop,
                  },
                }}
                anchor='right'
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(true)}
                sx={{ zIndex: '100000' }}
              >
                <EditCustomerName customerName={fullname} customerId={customerId} token={token} func={customerNameUpdateDrawer} />
              </Drawer>

              {/* notes  */}

              <Drawer
                classes={{ paper: classes.paper }}
                PaperProps={{
                  sx: { width: '90%' },
                }}
                BackdropProps={{
                  classes: {
                    root: classes.backDrop,
                  },
                }}
                anchor='right'
                open={notesDrawerOpen}
                onClose={() => setNotesDrawerOpen(false)}
                className='drawer_Header_page'
              >
                <NotesDrawer closeDrawer={closeNotesDrawer} editing={editingNote} type='customer' details={noteDrawerDetails} />
              </Drawer>

              {/* Customer ID */}
              <Typography className='customer_id_1 button_bottom_space' gutterBottom>
                Customer ID : {customer.customer_id || <Skeleton style={{ width: 100 }} />}
              </Typography>

              <Box sx={{ marginBottom: '20px' }}>
                {notesContent.map((note, index) => (
                  <Box key={note.id} sx={{ display: 'flex' }}>
                    {note.visible ? <VisibilityIcon sx={{ color: '#008593', paddingRight: '10px' }} /> : null}{' '}
                    <Box>
                      <Typography onClick={() => openNotesDrawer(note)}>
                        {' '}
                        <span
                          style={{
                            color: '#008593',
                            cursor: 'pointer',
                            fontWeight: 'bold',
                          }}
                        >
                          Note #{index + 1} :
                        </span>{' '}
                        {note.content}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
          <hr className='button_bottom_space' />
          {selectedAddress ? (
            <Paper className='customer_details_paper'>
              <Stack direction='row' alignItems='center' gap={2} className='Customer_Address_stack'>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <img src={LocationMiscIcon} className='misc_icons_dimensions' alt='LocationMiscIcon' />{' '}
                    {selectedAddress.zip ? (
                      <Box className='customer_address' sx={{ marginLeft: '6px' }}>
                        <Box style={{ textTransform: 'capitalize' }} className='address'>
                          <Box>
                            <span style={{ textTransform: 'Capitalize' }}>{selectedAddress.address_description ? selectedAddress.address_description : 'Address'}</span> {selectedAddress.zip ? ':' : null}
                          </Box>
                          <Box>{selectedAddress.address_line}</Box>
                          <Box> {selectedAddress.address_second_line}</Box>
                        </Box>
                        <Box>
                          <Box style={{ textTransform: 'capitalize' }}>
                            {selectedAddress.city}
                            {selectedAddress.city ? ',' : ''} <span style={{ textTransform: 'Uppercase' }}>{selectedAddress.state}</span>
                            {selectedAddress.state ? ' ' : ' '} {selectedAddress.zip}
                          </Box>
                        </Box>
                      </Box>
                    ) : (
                      <Box sx={{ marginLeft: '6px' }}>
                        <Skeleton width='100px' />
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <Box className='edit_address_icon'>
                      {selectedAddress.city ? (
                        <img
                          src={EditMiscIcon}
                          className='misc_icons_dimensions'
                          alt='EditMiscIcon'
                          onClick={() => {
                            navigate('/tbm/ps/editcustomeraddress', {
                              state: {
                                anotherAddress: customerInfo,
                                Address1: selectedAddress.address_line,
                                city: selectedAddress.city,
                                state: selectedAddress.state,
                                zip: selectedAddress.zip,
                                country: selectedAddress.country,
                                custid: selectedAddress.customer_id,
                                addressid: selectedAddress.address_id,
                                email: selectedAddress.email_id,
                                Discription: selectedAddress.address_description,
                                Address2: selectedAddress.address_second_line,
                                is_billing: selectedAddress.is_billing,
                              },
                            });
                          }}
                        />
                      ) : (
                        <img src={EditMiscIcon} className='misc_icons_dimensions' alt='EditMiscIcon' />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </Paper>
          ) : null}

          {selectedAddress ? (
            <>
              {selectedAddress[0] ? (
                <>
                  <Box display='flex' alignItems='center' justifyContent='center' className='all_Button_Style_Proposal_Details_Page '>
                    <span style={{ paddingLeft: '15px' }} className='gray_button_span button_top_space'>
                      <Box className='globalIconMainBox'>
                        <img src={ChangeAddress} className={`secondary_icons_dimension ${isImageHovered ? 'hovered' : ''}`} alt='ChangeAddress' />
                        <Typography
                          onClick={() => {
                            navigate('/tbm/ps/CustomerAddress', {
                              state: {
                                Custdata: {
                                  anotherAddress: customerInfo,
                                  address_line: customer.address_line,
                                  city: customer.city,
                                  state: customer.state,
                                  zip: customer.zip,
                                  country: customer.country,
                                  custid: customer.customer_id,
                                  addressid: customer.address_id,
                                  email: customer.email,
                                  f_name: customer.first_name,
                                  l_name: customer.last_name,
                                  email_id: customer.email_id,
                                },
                              },
                            });
                          }}
                          sx={{ fontFamily: 'RobotoLight' }}
                          className='globalText'
                          onMouseEnter={handleHover}
                          onMouseLeave={handleHover}
                        >
                          CHANGE ADDRESS
                        </Typography>
                      </Box>
                    </span>
                  </Box>
                </>
              ) : null}
            </>
          ) : null}

          <hr className='button_bottom_space button_top_space' />

          <Box>
            {PhoneNumberOne ? (
              <Paper className='customer_details_paper'>
                <Stack direction='row' alignItems='center' gap={1} sx={{ paddingBottom: '10px' }}>
                  <img src={PhoneMiscIcon} className='misc_icons_dimensions' alt='PhoneMiscIcon' />
                  <Typography sx={{ textTransform: 'capitalize' }} className='customer_Phone'>
                    {' '}
                    {PhoneNumberOne.Type || 'Phone'} :{' '}
                    <a style={{ textDecoration: 'none', color: '#3594AE' }} href={'tel:+1' + PhoneNumberOne['#']} target='_blank'>
                      {PhoneNumberOne ? (
                        <>
                          <>+1 {normalize(PhoneNumberOne['#'])} </>
                        </>
                      ) : (
                        'NA'
                      )}
                    </a>
                  </Typography>
                  <span>
                    <>
                      <img
                        src={EditMiscIcon}
                        className='misc_icons_dimensions'
                        alt='EditMiscIcon'
                        onClick={() => {
                          navigate('/tbm/ps/editcustomerphoneemail', {
                            state: {
                              phoneno: customer.mobile,
                              email_id: customer.email_id,
                              EMAIL: emailAddress,
                              custid: customerId,
                              emailAddress: customer.email,
                              PhoneNo: PhoneNumbers,
                              addressid: addressID,
                            },
                          });
                        }}
                      />
                    </>
                  </span>
                </Stack>
              </Paper>
            ) : (
              <Paper className='customer_details_paper'>
                <Stack direction='row' alignItems='center' gap={1}>
                  <img src={PhoneMiscIcon} className='misc_icons_dimensions' alt='PhoneMiscIcon' />
                  <Typography sx={{ textTransform: 'capitalize' }} className='customer_address'>
                    {AnotherPhoneNumber === 0 ? (
                      <Typography sx={{ textTransform: 'capitalize' }} className='customer_address'>
                        Phone :{' '}
                        <a style={{ textDecoration: 'none', color: '#3594AE' }} href={'tel:+1' + ''} target='_blank'>
                          NA
                        </a>
                      </Typography>
                    ) : (
                      <Skeleton width='100px' />
                    )}
                  </Typography>
                  <span>
                    <>
                      {AnotherPhoneNumber === 0 ? (
                        <>
                          <img
                            src={EditMiscIcon}
                            className='misc_icons_dimensions'
                            alt='EditMiscIcon'
                            onClick={() => {
                              navigate('/tbm/ps/editcustomerphoneemail', {
                                state: {
                                  phoneno: customer.mobile,
                                  emailAddress: customer.email,
                                  email_id: customer.email_id,
                                  custid: customerId,
                                  PhoneNo: PhoneNumbers,
                                  addressid: addressID,
                                },
                              });
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src={EditMiscIcon}
                            className='misc_icons_dimensions'
                            alt='EditMiscIcon'
                            onClick={() => {
                              navigate('/tbm/ps/editcustomerphoneemail', {
                                state: {
                                  phoneno: customer.mobile,
                                  emailAddress: customer.email,
                                  email_id: customer.email_id,
                                  custid: customerId,
                                  PhoneNo: PhoneNumbers,
                                  addressid: addressID,
                                },
                              });
                            }}
                          />
                        </>
                      )}
                    </>
                  </span>
                </Stack>
              </Paper>
            )}
          </Box>

          <Box>
            {PhoneNumberTwo ? (
              <Paper className='customer_details_paper'>
                <Stack direction='row' alignItems='center' gap={1}>
                  <Typography
                    sx={{
                      textTransform: 'capitalize',
                      marginLeft: '32px',
                      position: 'relative',
                      bottom: '10px',
                    }}
                    className='customer_Phone'
                  >
                    {' '}
                    {PhoneNumberTwo.Type || 'Phone'} :{' '}
                    <a style={{ textDecoration: 'none', color: '#3594AE' }} href={'tel:+1' + PhoneNumberTwo['#']} target='_blank'>
                      {PhoneNumberOne ? (
                        <>
                          <>+1 {normalize(PhoneNumberTwo['#'])} </>
                        </>
                      ) : (
                        'NA'
                      )}
                    </a>
                  </Typography>
                </Stack>
              </Paper>
            ) : null}

            <Box sx={{ marginTop: '10px !important' }}>
              {emailAddress === undefined ? (
                <Paper className='customer_details_paper_Email'>
                  <Stack direction='row' alignItems='center' gap={1}>
                    <img src={EmailMiscIcon} className='misc_icons_dimensions' alt='EmailMiscIcon' />

                    <Typography className='customer_address'>
                      <Skeleton width='100px' />
                    </Typography>
                  </Stack>
                </Paper>
              ) : emailLength === 0 ? (
                <Paper className='customer_details_paper_Email'>
                  <Stack direction='row' alignItems='center' gap={1}>
                    <img src={EmailMiscIcon} className='misc_icons_dimensions' alt='EmailMiscIcon' />
                    <Typography className='customer_address'>
                      {' '}
                      Email : <span className='customer_email'>NA</span>
                    </Typography>
                  </Stack>
                </Paper>
              ) : (
                <>
                  <>
                    {emailAddress.slice(0, 2).map((item, index) => {
                      return (
                        <Paper className='customer_details_paper'>
                          <Stack direction='row' alignItems='center' gap={1}>
                            {index / 2 ? (
                              <span style={{ marginLeft: '23px' }}></span>
                            ) : (
                              <>
                                <img src={EmailMiscIcon} className='misc_icons_dimensions' alt='EmailMiscIcon' />
                              </>
                            )}

                            <Typography className='customer_address_email'>
                              {' '}
                              <span style={{ textTransform: 'capitalize' }}>{item.email_type === '' ? 'Email' : item.email_type}</span>:{' '}
                              <span className='customer_email'>
                                {' '}
                                <a
                                  style={{
                                    textDecoration: 'none',
                                    color: '#3594AE',
                                  }}
                                  href={'mailto:' + item.email_id}
                                  target='_blank'
                                >
                                  {item.email_id === '' ? 'NA' : item.email_id}
                                </a>
                              </span>
                            </Typography>
                          </Stack>
                        </Paper>
                      );
                    })}
                  </>
                </>
              )}
            </Box>
          </Box>

          <hr className='button_top_space button_bottom_space' />

          <Typography className='customer_details_segment_size all_pages_h2_header button_bottom_space' onClick={callWindowSetCaretFunction}>
            <Badge badgeContent={windowsetdatalength.length} classes={{ badge: classes.customBadge }} max={999}>
              WINDOW SETS
            </Badge>
            <IconButton onClick={callWindowSetCaretFunction} className='customer_details_iconbutton' aria-label='edit'>
              {viewwindowset ? <ArrowDropUpIcon onClick={callWindowSetCaretFunction} fontSize='large' /> : <ArrowDropDownIcon onClick={callWindowSetCaretFunction} fontSize='large' />}
            </IconButton>
          </Typography>

          {viewwindowset ? (
            <>
              <Box className='table-box-1'>
                {showtabledata ? (
                  <TableContainer sx={{ borderBottom: '1px solid #D6D6D6' }} className='table_padding_bottom'>
                    <Table className='table-dimension-cd-windowsets' aria-labelledby='tableTitle' size='small'>
                      <WindowSetTableHead rowCount={WindowSetData.length} />

                      <TableBody>
                        {(windowSetsPerPage > 0 ? WindowSetData.slice(windowSetsPage * windowSetsPerPage, windowSetsPage * windowSetsPerPage + windowSetsPerPage) : WindowSetData).map((row, index) => (
                          <TableRow hover className='rowhover' role='checkbox' tabIndex={-1} key={row.window_set_id_seq}>
                            <TableCell className='table-content sticky-column' align='left'>
                              <Link
                                className='link ellipsis'
                                to={{
                                  pathname: `/tbm/ps/windowset/${row.window_set_id}`,
                                }}
                                state={{
                                  coveringdetails: coveringdetails,
                                  data: NotesCustomerDetails,
                                  windowSetId: row.window_set_id,
                                  pathname: `/tbm/ps/windowset/${row.window_set_id}`,
                                }}
                              >
                                <LongText>{row.window_set_name}</LongText>
                              </Link>
                            </TableCell>
                            <TableCell className='table-content' align='center'>
                              <>{row.total_number_of_window} </>
                            </TableCell>
                            <TableCell className='table-content' align='left'>
                              <Moment format='MM/DD/YY'>{row.entry_date}</Moment>
                            </TableCell>

                            <TableCell className='table-content' align='left'>
                              <LongText>{row.sales_person_name} </LongText>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null}
              </Box>

              {/* 'Create WindowSet' component will be called on Button click */}
              {alertmessage ? <span style={{ color: 'red' }}>Maximum Window Set Exceeded</span> : ''}

              {!showCreateWindowSetComponent ? (
                <>
                  {viewButtonFlag ? (
                    <>
                      {!buttonNameFlag ? (
                        <>
                          <span className='button_span button_bottom_space' onClick={createWindowSet} onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
                            <Box className='globalIconMainBox'>
                              {isHovered ? <img className='primary_icons_dimension_1' src={WindowSetHovIcon} alt='WindowSetHovIcon' /> : <img className='primary_icons_dimension_1' src={WindowsetaddIcon} alt='WindowsetaddIcon' />}

                              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover2} onMouseLeave={handleHover2}>
                                {' '}
                                CREATE NEW WINDOW SET
                              </Typography>
                            </Box>
                          </span>
                        </>
                      ) : (
                        <>
                          <span className='button_span button_top_space button_bottom_space' onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
                            <Box onClick={createWindowSet} className='globalIconMainBox'>
                              {isHovered ? <img className='primary_icons_dimension_1' src={WindowSetHovIcon} al='WindowSetHovIcon' /> : <img className='primary_icons_dimension_1' src={WindowsetaddIcon} alt='WindowSetHovIcon' />}

                              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover3} onMouseLeave={handleHover3}>
                                {' '}
                                ADD WINDOW SET
                              </Typography>
                            </Box>
                          </span>
                        </>
                      )}
                    </>
                  ) : (
                    <span className='button_span'>
                      <Skeleton width={50} height={50} circle={true} />{' '}
                      <span className='button_text'>
                        <Skeleton width={150} />
                      </span>
                    </span>
                  )}
                </>
              ) : (
                <AddWindowSet data={senddatatonewWindow} value={showCreateWindowSetComponent} sales_rep checkbuttonOption={handleChange} />
              )}
            </>
          ) : null}

          <Typography
            onClick={() => {
              callCaretFunction();
            }}
            className='customer_details_segment_size mt all_pages_h2_header button_bottom_space'
          >
            <Badge badgeContent={proposaldata.length} classes={{ badge: classes.customBadge }} max={999}>
              PROPOSALS
            </Badge>
            <IconButton className='customer_details_iconbutton' aria-label='edit'>
              {viewproposal ? <ArrowDropUpIcon fontSize='large' /> : <ArrowDropDownIcon fontSize='large' />}
            </IconButton>
          </Typography>

          <Typography>
            {viewproposal && proposaldata.length ? (
              <Box className='table-box-1 button_bottom_space'>
                <TableContainer sx={{ borderBottom: '1px solid #D6D6D6' }} className='table_padding_bottom'>
                  <Table className='table-dimension-cd-sales-order' aria-labelledby='tableTitle' size='small'>
                    <ProposalTableHead rowCount={proposaldata.length} />
                    <TableBody>
                      {(proposalsPerPage > 0 ? proposaldata.slice(proposalPage * proposalsPerPage, proposalPage * proposalsPerPage + proposalsPerPage) : proposaldata).map((row) => (
                        <TableRow hover className='rowhover' role='checkbox' tabIndex={-1} key={row.proposal_id_seq}>
                          <TableCell className='table-content sticky-column' align='left' style={{ minWidth: 150 }}>
                            <Link
                              className='link ellipsis'
                              to={{
                                pathname: `/tbm/ps/proposal/${row.proposal_id}/details`,
                              }}
                              state={{
                                proposalID: row.proposal_id,
                                proposalName: row.proposal_name,
                                customerdata: WindowSetData.find(({ window_set_id }) => window_set_id === row.window_set_id),
                                coveringdetails: coveringdetails,
                              }}
                            >
                              {row.adobe_sign_status === 'OUT_FOR_ACCEPTANCE' ? (
                                <TurquoiseTooltip title='Proposal has sent' placement='left' TransitionComponent={Zoom} arrow>
                                  <StarOutlineIcon className={iconclasses.proposalsentIcon} />
                                </TurquoiseTooltip>
                              ) : null}
                              {row.adobe_sign_status === 'ACCEPTED_BY_SALES_REP' ? (
                                <TurquoiseTooltipforSalesRep title='Sales Rep has accepted on behalf of Customer' placement='left' TransitionComponent={Zoom} arrow>
                                  <StarIcon className={iconclasses.salesrepIcon} />
                                </TurquoiseTooltipforSalesRep>
                              ) : null}
                              {row.adobe_sign_status === 'ACCEPTED_BY_CUSTOMER' ? (
                                <TurquoiseTooltip title='All Good! Customer has accepted Proposal' placement='left' TransitionComponent={Zoom} arrow>
                                  <StarIcon className={iconclasses.customerIcon} />
                                </TurquoiseTooltip>
                              ) : null}{' '}
                              {row.adobe_sign_status === 'OUT_FOR_ACCEPTANCE' || row.adobe_sign_status === 'ACCEPTED_BY_SALES_REP' || row.adobe_sign_status === 'ACCEPTED_BY_CUSTOMER' ? '' : <span className={iconclasses.spaceIcon}></span>}
                              &nbsp;
                              <LongText>{row.proposal_name}</LongText>
                            </Link>
                          </TableCell>

                          <TableCell className='table-content' align='left'>
                            &nbsp;&nbsp;<>{row.window_selected_count} </>
                          </TableCell>

                          <TableCell className='table-content' align='left'>
                            {`$${(+row.final_total).toFixed(2)}`}
                          </TableCell>

                          <TableCell className='table-content' align='left'>
                            {row.show_disc ? `${parseFloat(row.discount).toFixed(2)}%` : <>- -</>}
                          </TableCell>

                          <TableCell className='table-content' align='left'>
                            <Moment format='MM/DD/YY'>{row.entry_date}</Moment>
                          </TableCell>

                          <TableCell className='table-content' align='left'>
                            <LongText>{row.sales_rep}</LongText>
                          </TableCell>

                          <TableCell className='table-content' align='left'>
                            {row.upcharge}
                          </TableCell>

                          <TableCell className='table-content' align='left'>
                            {row.mail_sent === null ? <span>- -</span> : <Moment format='MM/DD/YY'>{row.mail_sent}</Moment>}
                          </TableCell>

                          <TableCell className='table-content ' align='left'>
                            {row.proposal_accept === null ? <span>- -</span> : <Moment format='MM/DD/YY'>{row.proposal_accept}</Moment>}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  sx={{
                    mx: 0,
                    px: 0,
                    textAlign: 'center',
                  }}
                  rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]}
                  component='div'
                  count={proposaldata.length}
                  rowsPerPage={proposalsPerPage}
                  page={proposalPage}
                  onPageChange={(eventprp, newPageprp) => {
                    setProposalPage(newPageprp);
                  }}
                  onRowsPerPageChange={(eventprp) => {
                    setProposalsPerPage(eventprp.target.value, 10);
                    setProposalPage(0);
                  }}
                />
              </Box>
            ) : null}
          </Typography>

          <Typography className='customer_details_segment_size mt all_pages_h2_header button_bottom_space' onClick={callSalesOrderFunction}>
            <Badge badgeContent={SalesOrderData.length} classes={{ badge: classes.customBadge }} max={999}>
              SALES ORDER
            </Badge>
            <IconButton onClick={callSalesOrderFunction} className='customer_details_iconbutton' aria-label='edit'>
              {openCloseSalesOrder ? <ArrowDropUpIcon onClick={openSalesOrder} fontSize='large' /> : <ArrowDropDownIcon onClick={closeSalesOrder} fontSize='large' />}
            </IconButton>
          </Typography>
          {/* rowSales */}
          {openCloseSalesOrder ? (
            <Box className='table-box-1'>
              <TableContainer sx={{ paddingBottom: '1rem' }}>
                <Table className='table-dimension-cd-sales-order' aria-labelledby='tableTitle' size='small'>
                  <SalesOrderTableHead />

                  <SalesOrderTableBody state={location.state} salesOrders={SalesOrderData} page={0} rowsPerPage={30} navigateToSalesOrder={getSalesOrderWindows} />
                </Table>
                <TablePagination
                  sx={{
                    // width: "100%",
                    // minWidth: 10,
                    mx: 0,
                    px: 0,
                    textAlign: 'center',
                  }}
                  rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]}
                  component='div'
                  count={proposaldata.length}
                  rowsPerPage={salesOrdersPerPage}
                  page={salesOrderPage}
                  onPageChange={(eventprp, newPageprp) => {
                    setSalesOrderPage(newPageprp);
                  }}
                  onRowsPerPageChange={(eventprp) => {
                    setSalesOrdersPerPage(eventprp.target.value, 10);
                    setSalesOrderPage(0);
                  }}
                />
              </TableContainer>

              <TableContainer sx={{ paddingBottom: '1rem' }}>
                <Table className='table-dimension-cd-sales-order' aria-labelledby='tableTitle' size='small'>
                  <LegacySalesOrderHead />
                  {legacyInvoice.length > 0 ? (
                    <TableBody>
                      {(legacyInvoicesPerPage > 0 ? legacyInvoice.slice(legacyInvoicePage * legacyInvoicesPerPage, legacyInvoicePage * legacyInvoicesPerPage + legacyInvoicesPerPage) : legacyInvoice).map((row) => (
                        <TableRow hover className='rowhover' role='checkbox' key={row.window_set_id_seq}>
                          <TableCell className='table-content' align='left'>
                            {row.Invoice !== null ? row.Invoice : null}
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            <Moment format='MM/DD/YY'>{row.TodaysDate}</Moment>
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            {'$' + parseFloat(row.TotalInvoice).toFixed(2)}
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            {'$' + parseFloat(row.Balance).toFixed(2)}
                          </TableCell>
                          <TableCell className='table-content' align='left'>
                            <LongText>{row.sales_rep}</LongText>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : null}
                </Table>
                <TablePagination
                  sx={{
                    mx: 0,
                    px: 0,
                    textAlign: 'center',
                  }}
                  rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]}
                  component='div'
                  count={legacyInvoice.length}
                  rowsPerPage={legacyInvoicesPerPage}
                  page={legacyInvoicePage}
                  onPageChange={(eventprp, newPageprp) => {
                    setLegacyInvoicePage(newPageprp);
                  }}
                  onRowsPerPageChange={(eventprp) => {
                    setLegacyInvoicesPerPage(eventprp.target.value, 10);
                    setLegacyInvoicePage(0);
                  }}
                />
              </TableContainer>
            </Box>
          ) : null}
          <hr className='button_top_space button_bottom_space' />

          <Typography className='customer_details_segment_size button_bottom_space' onClick={callCaretFunctionforAppointment}>
            <Badge badgeContent={numberOfAppointment} classes={{ badge: classes.customBadge }} max={999}>
              APPOINTMENTS
            </Badge>
            <IconButton className='customer_details_iconbutton' aria-label='edit' onClick={callCaretFunctionforAppointment}>
              {openDropDown ? (
                <ArrowDropDownIcon
                  onClick={() => {
                    openAppointmentFun();
                  }}
                  fontSize='large'
                />
              ) : (
                <ArrowDropUpIcon
                  onClick={() => {
                    closeAppointmentFun();
                  }}
                  fontSize='large'
                />
              )}
            </IconButton>
          </Typography>

          {!openDropDown ? (
            <>
              {!closeListAfterClickEdit ? (
                <>
                  {!openSelectTimeDuration || newAppointmentButton ? (
                    <>
                      <Box className='button_top_space' sx={{ paddingLeft: '0px' }}>
                        {getAppointment.length !== 0 ? (
                          <Box>
                            <Paper className='customer_appointments_list'>
                              <Stack direction='column' alignItems='center' gap={1}>
                                {getAppointment.slice(0, 5).map((row) => {
                                  const a = moment(row.StartTime);
                                  const b = moment(row.EndTime);
                                  const length = b.diff(a, 'minutes');
                                  return (
                                    <>
                                      <Box className='schedule_appointment_width'>
                                        <Box className='all_appointments_box'>
                                          <Box className='new_appointment_edit_appointment' sx={{ width: '100%' }}>
                                            <Typography className=''>
                                              <Box>
                                                <span className='font_weight_bold appointments_timing_color'>{moment(row.StartTime).utc().format('MMMM DD, YYYY')}</span> - {moment(row.StartTime).utc().format('hh:mm a')} -{' '}
                                                <span
                                                  style={{
                                                    fontWeight: '100 !important',
                                                  }}
                                                  className=''
                                                >
                                                  {length} min
                                                </span>
                                              </Box>
                                              <Box>
                                                {row.Address1 === '' && row.Address2 === '' ? (
                                                  ''
                                                ) : (
                                                  <>
                                                    <span className='font_weight_bold'>Address : </span>{' '}
                                                    <span
                                                      style={{
                                                        textTransform: 'capitalize',
                                                      }}
                                                    >
                                                      {row.Address1}
                                                    </span>{' '}
                                                    {row.Address1 === '' ? (
                                                      <span
                                                        style={{
                                                          textTransform: 'capitalize',
                                                        }}
                                                      >
                                                        {row.Address2}
                                                      </span>
                                                    ) : (
                                                      ''
                                                    )}
                                                  </>
                                                )}
                                              </Box>
                                              <Box>
                                                {row.Notes === '' ? (
                                                  ''
                                                ) : (
                                                  <>
                                                    <span className='font_weight_bold'>Notes: </span> <span>{row.Notes}</span>
                                                  </>
                                                )}
                                              </Box>
                                            </Typography>
                                            <Box>
                                              <img
                                                src={EditMiscIcon}
                                                className='misc_icons_dimensions'
                                                alt='EditMiscIcon'
                                                onClick={() => {
                                                  sendUpdateButtonTrueFun(row.ApointmentID);
                                                  sentEditTime(row.StartTime, length, row.Notes);
                                                  setCloseListAfterClickEdit(true);
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </>
                                  );
                                })}
                              </Stack>
                            </Paper>
                          </Box>
                        ) : (
                          <Box sx={{ paddingBottom: '0px !important' }}></Box>
                        )}

                        <span
                          className='button_span padding_bottom_class_1 '
                          onClick={() => {
                            setOpenSelectTimeDuration(true);
                            setNewAppointmentButton(false);
                            setGetAppointmentTime('');
                            setGetAppointmentLength('');
                            setGetAppointmentNotes('');
                            setValue(tomorrow);
                          }}
                        >
                          <Box className='globalIconMainBox' onMouseEnter={handleHoverFun3} onMouseLeave={handleMouseLeaveFun3}>
                            {isHovered3 ? <img className='primary_icons_dimension_1' src={CalenderHovIcon} alt='CalenderHovIcon' /> : <img className='primary_icons_dimension_1' src={calenderIconAdd} alt='calenderIconAdd' />}

                            <Typography onMouseEnter={handleHover4} onMouseLeave={handleHover4} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                              NEW APPOINTMENT
                            </Typography>
                          </Box>
                        </span>
                      </Box>
                    </>
                  ) : (
                    ''
                  )}
                </>
              ) : (
                ''
              )}

              {openSelectTimeDuration && !openScheduleAppiontment ? (
                <>
                  <Box>
                    <Box>
                      <Box></Box>
                      <Box>
                        <Typography className='font_weight_bold button_bottom_space button_top_space'>Schedule Appointment With</Typography>

                        <Box align='left' className=''>
                          <FormControl onClick={() => setSalesRepOpen(!salesRepOpen)} className='formcontrol text_selectfield_upper'>
                            <Select
                              MenuProps={{
                                style: { zIndex: 100000 },
                              }}
                              size='small'
                              align='left'
                              select
                              onClose={() => setSalesRepOpen(false)}
                              onOpen={() => setSalesRepOpen(true)}
                              open={salesRepOpen}
                              value={user}
                              onChange={(e) => {
                                setGetSalesRep(e.target.value);
                              }}
                              displayEmpty
                              startAdornment={<InputAdornment position='start'>Sales Rep: </InputAdornment>}
                            >
                              <MenuItem value={user}>
                                <b>{user}</b>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      <hr className='button_bottom_space button_top_space' />

                      <Box sx={{ marginBottom: '20px' }}>
                        <Typography className='font_weight_bold '>Select a Date for the Appointment:</Typography>

                        <Stack component='form' noValidate spacing={3}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                              <StaticDatePicker
                                className={classes.DatePicker}
                                views={['day']}
                                label='Select Date'
                                inputFormat='MM/DD/YYYY'
                                value={value}
                                disablePast
                                onChange={handleChangeFun}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      readOnly: true,
                                    }}
                                  />
                                )}
                                toolbarFormat='ddd DD MMMM'
                                showToolbar={false}
                                componentsProps={{
                                  actionBar: {
                                    actions: [''],
                                  },
                                }}
                                renderDay={(day, _value, DayComponentProps) => {
                                  let selected = false;
                                  const Calenderdate = moment(DayComponentProps.key).local().format('MM/DD/YYYY');
                                  getSalesRepAppointment.forEach((dates) => {
                                    if (Calenderdate === moment(dates.StartTime).local().format('MM/DD/YYYY')) {
                                      selected = true;
                                    }
                                  });
                                  return (
                                    <>
                                      {selected ? (
                                        <PickersDay
                                          sx={{
                                            backgroundColor: '#D1D1D1 !important',
                                            margin: '2px',
                                            borderRadius: '50px',
                                          }}
                                          {...DayComponentProps}
                                        />
                                      ) : (
                                        <PickersDay {...DayComponentProps} />
                                      )}
                                    </>
                                  );
                                }}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </Stack>
                      </Box>

                      <hr className='button_bottom_space' />

                      <span
                        className='gray_button_span button_top_space'
                        style={{ paddingBottom: '30px' }}
                        onClick={() => {
                          setOpenSelectTimeDuration(false);
                        }}
                      >
                        <Box
                          onClick={() => {
                            setOpenSelectTimeDuration(false);
                          }}
                          className='globalIconMainBox'
                        >
                          <img
                            onClick={() => {
                              setOpenSelectTimeDuration(false);
                            }}
                            src={CancelIcon}
                            alt='CancelIcon'
                            className={`secondary_icons_dimension ${isImageHovered5 ? 'hovered' : ''}`}
                          />
                          <Typography
                            onClick={() => {
                              setOpenSelectTimeDuration(false);
                            }}
                            sx={{ fontFamily: 'RobotoLight' }}
                            className='globalText'
                            onMouseEnter={handleHover5}
                            onMouseLeave={handleHover5}
                          >
                            CANCEL
                          </Typography>
                        </Box>
                      </span>
                    </Box>
                  </Box>
                </>
              ) : (
                ''
              )}

              {openScheduleAppiontment && !newAppointmentButton && !editButtonToOpenCalender ? (
                <>
                  <Box>
                    <Box>
                      <Typography className='button_bottom_space button_top_space'>
                        Date Selected : <span className='font_weight_bold'>{moment(value).local().format('MMMM DD, YYYY')}</span>
                      </Typography>

                      <Typography className='font_weight_bold font_size_1 button_bottom_space'>Select Time and Duration for the Appointment</Typography>
                    </Box>
                    <Box>
                      <ThemeProvider theme={theme}>
                        <Box align='left' className=''>
                          <FormControl onClick={() => setAppntTimeOpen(!appntTimeOpen)} className='formcontrol text_selectfield_upper margin_bottom'>
                            <Select
                              MenuProps={{
                                style: { zIndex: 100000 },
                              }}
                              size='small'
                              align='left'
                              select
                              onClose={() => setAppntTimeOpen(false)}
                              onOpen={() => setAppntTimeOpen(true)}
                              open={appntTimeOpen}
                              required
                              value={getAppointmentTime}
                              onChange={(e) => {
                                setGetAppointmentTime(e.target.value);
                              }}
                              displayEmpty
                              startAdornment={
                                <InputAdornment position='start'>
                                  Appointment Time <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                                  &nbsp;:&nbsp;
                                </InputAdornment>
                              }
                            >
                              <MenuItem value={''}>
                                <b>─ ─</b>
                              </MenuItem>
                              {timeOfAppointment.map((i) => {
                                return (
                                  <MenuItem value={i}>
                                    <b>{i}</b>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                      </ThemeProvider>

                      <Box align='left' className=''>
                        <FormControl onClick={() => setLengthOfAppointmentOpen(!lengthOfAppointmentOpen)} className='formcontrol text_selectfield_upper margin_top margin_bottom'>
                          <Select
                            MenuProps={{
                              style: { zIndex: 100000 },
                            }}
                            size='small'
                            align='left'
                            value={getAppointmentLenght}
                            select
                            onClose={() => setLengthOfAppointmentOpen(false)}
                            onOpen={() => setLengthOfAppointmentOpen(true)}
                            open={lengthOfAppointmentOpen}
                            onChange={(e) => {
                              setGetAppointmentLength(e.target.value);
                            }}
                            displayEmpty
                            startAdornment={<InputAdornment position='start'>Length of Appointment :&nbsp;</InputAdornment>}
                          >
                            <MenuItem value={''}>
                              <b>─ ─</b>
                            </MenuItem>
                            {lengthOfAppointment.map((i) => {
                              return (
                                <MenuItem value={i}>
                                  <b>{i} min</b>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>

                      <Box align='left' className=''>
                        <TextField
                          sx={{ backgroundColor: '#f4f4f4' }}
                          className='drawer_textfieldcolor formcontrol text_selectfield'
                          placeholder='Notes'
                          value={getAppointmentNotes}
                          onChange={(e) => {
                            setGetAppointmentNotes(e.target.value);
                          }}
                        />
                      </Box>

                      <hr />

                      {!(getAppointmentTime === '' || getAppointmentTime === undefined) && !(getAppointmentLenght === '' || getAppointmentLenght === undefined) ? (
                        <span
                          onClick={() => {
                            ScheduleAppointment();
                            setValue(tomorrow);
                            openNewAppointmentSelectDateFun();
                            setOpenSelectTimeDuration(false);
                            setOpenScheduleAppiontment(false);
                          }}
                          style={{ marginLeft: '6px !important' }}
                          className='button_span button_top_space button_bottom_space appointmentS_margin_left'
                          onMouseEnter={handleHoverFun3}
                          onMouseLeave={handleMouseLeaveFun3}
                        >
                          <Box className='globalIconMainBox'>
                            {isHovered3 ? <img className='primary_icons_dimension_1' src={CalenderHovIcon} alt='CalenderHovIcon' /> : <img className='primary_icons_dimension_1' src={calenderIconAdd} al='calenderIconAdd' />}

                            <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover6} onMouseLeave={handleHover6}>
                              SCHEDULE APPOINTMENT
                            </Typography>
                          </Box>
                        </span>
                      ) : (
                        <span className='button_span_disabled button_top_space button_bottom_space'>
                          <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                            <img className='primary_icons_dimension_disable' src={calenderIconAdd} alt='calenderIconAdd' />

                            <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                              SCHEDULE APPOINTMENT
                            </Typography>
                          </Box>
                        </span>
                      )}

                      <span
                        className='gray_button_span button_top_space'
                        style={{ paddingBottom: '30px', paddingLeft: '22px' }}
                        onClick={() => {
                          setOpenSelectTimeDuration(false);
                          setOpenScheduleAppiontment(false);
                        }}
                      >
                        <Box
                          onClick={() => {
                            setOpenSelectTimeDuration(false);
                            setOpenScheduleAppiontment(false);
                          }}
                          className='globalIconMainBox'
                        >
                          <img
                            onClick={() => {
                              setOpenSelectTimeDuration(false);
                              setOpenScheduleAppiontment(false);
                            }}
                            alt='CancelIcon'
                            src={CancelIcon}
                            className={`secondary_icons_dimension ${isImageHovered7 ? 'hovered' : ''}`}
                          />
                          <Typography
                            onClick={() => {
                              setOpenSelectTimeDuration(false);
                              setOpenScheduleAppiontment(false);
                            }}
                            sx={{ fontFamily: 'RobotoLight' }}
                            className='globalText'
                            onMouseEnter={handleHover7}
                            onMouseLeave={handleHover7}
                          >
                            CANCEL
                          </Typography>
                        </Box>
                      </span>
                    </Box>
                  </Box>
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}

          {/* edit component start  */}

          {!openDropDown ? (
            <>
              {editButtonToOpenCalender && !editOpenSelectAppointmentLenght ? (
                <>
                  <Box>
                    <Box>
                      <Box></Box>
                      <Box>
                        <Typography className='font_weight_bold button_top_space button_bottom_space'>Reschedule Appointment With</Typography>

                        <Box align='left' className=''>
                          <FormControl onClick={() => setSalesRepOpen2(!salesRepOpen2)} className='formcontrol text_selectfield_copy_upper'>
                            <Select
                              MenuProps={{
                                style: { zIndex: 100000 },
                              }}
                              size='small'
                              align='left'
                              select
                              onClose={() => setSalesRepOpen2(false)}
                              onOpen={() => setSalesRepOpen2(true)}
                              open={salesRepOpen2}
                              value={user}
                              onChange={(e) => {
                                setGetSalesRep(e.target.value);
                              }}
                              displayEmpty
                              startAdornment={<InputAdornment position='start'>Sales Rep : </InputAdornment>}
                            >
                              <MenuItem value={user}>
                                <b>{user}</b>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>

                      <hr className='button_bottom_space button_top_space' />
                      <Box>
                        <Box>
                          {!editCalenderOpen ? (
                            <Typography>
                              Date Selected : <span className='font_weight_bold'>{moment(EditTime).local().format('MMMM DD, YYYY')}</span>
                            </Typography>
                          ) : (
                            <Typography className='font_weight_bold'>Select a New Date for the Appointment</Typography>
                          )}

                          {/* change date  */}

                          {editCalenderOpen ? (
                            <Stack sx={{ width: '40%' }} component='form' noValidate spacing={3}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Stack sx={{ width: '50%' }} spacing={3}>
                                  <StaticDatePicker
                                    className={classes.StaticDatePicker}
                                    views={['day']}
                                    label='Select Date'
                                    inputFormat='MM/DD/YYYY'
                                    value={EditTime}
                                    disablePast
                                    onChange={editHandleChangeFun}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        inputProps={{
                                          ...params.inputProps,
                                          readOnly: true,
                                        }}
                                      />
                                    )}
                                    toolbarFormat='ddd DD MMMM'
                                    showToolbar={false}
                                    componentsProps={{
                                      actionBar: {
                                        actions: [''],
                                      },
                                    }}
                                    renderDay={(day, _value, DayComponentProps) => {
                                      let selected = false;
                                      const Calenderdate = moment(DayComponentProps.key).local().format('MM/DD/YYYY');
                                      getSalesRepAppointment.forEach((dates) => {
                                        if (Calenderdate === moment(dates.StartTime).local().format('MM/DD/YYYY')) {
                                          selected = true;
                                        }
                                      });
                                      return (
                                        <>
                                          {selected ? (
                                            <PickersDay
                                              sx={{
                                                backgroundColor: '#D1D1D1 !important',
                                                margin: '2px',
                                                borderRadius: '50px',
                                              }}
                                              {...DayComponentProps}
                                            />
                                          ) : (
                                            <PickersDay {...DayComponentProps} />
                                          )}
                                        </>
                                      );
                                    }}
                                  />
                                </Stack>
                              </LocalizationProvider>
                            </Stack>
                          ) : (
                            <>
                              <span
                                className='gray_button_span button_top_space'
                                style={{
                                  paddingBottom: '20px',
                                  paddingLeft: '21px',
                                }}
                              >
                                <Box
                                  onClick={() => {
                                    setEditCalenderOpen(true);
                                  }}
                                  className='globalIconMainBox'
                                >
                                  <img src={ChangeDateIcon} className={`secondary_icons_dimension ${isImageHovered8 ? 'hovered' : ''}`} alt='ChangeDateIcon' />
                                  <Typography
                                    onClick={() => {
                                      setEditCalenderOpen(true);
                                    }}
                                    sx={{ fontFamily: 'RobotoLight' }}
                                    className='globalText'
                                    onMouseEnter={handleHover8}
                                    onMouseLeave={handleHover8}
                                  >
                                    {' '}
                                    CHANGE DATE
                                  </Typography>
                                </Box>
                              </span>
                            </>
                          )}

                          {!editCalenderOpen ? (
                            ''
                          ) : (
                            <Box>
                              <Typography>
                                Date Selected : <span className='font_weight_bold'>{moment(EditTime).local().format('MMMM DD, YYYY')}</span>
                              </Typography>
                              <hr />
                            </Box>
                          )}

                          <Typography className='font_weight_bold font_size_1 button_bottom_space'>Select Time and Duration for the Appointment</Typography>
                        </Box>
                        <Box>
                          <ThemeProvider theme={theme}>
                            <Box align='left' className=''>
                              <FormControl onClick={() => setAppointmentTimeOpen(!appointmentTimeOpen)} className='formcontrol text_selectfield_copy_upper margin_bottom'>
                                <Select
                                  MenuProps={{
                                    style: { zIndex: 100000 },
                                  }}
                                  size='small'
                                  align='left'
                                  select
                                  onClose={() => setAppointmentTimeOpen(false)}
                                  onOpen={() => setAppointmentTimeOpen(true)}
                                  open={appointmentTimeOpen}
                                  required
                                  value={getAppointmentTime}
                                  onChange={(e) => {
                                    setGetAppointmentTime(e.target.value);
                                  }}
                                  displayEmpty
                                  startAdornment={
                                    <InputAdornment position='start'>
                                      Appointment Time <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                                      &nbsp;:&nbsp;
                                    </InputAdornment>
                                  }
                                >
                                  <MenuItem value={''}>
                                    <b>─ ─</b>
                                  </MenuItem>
                                  {timeOfAppointment.map((i) => {
                                    return (
                                      <MenuItem value={i}>
                                        <b>{i}</b>
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Box>
                          </ThemeProvider>

                          <Box align='left' className=''>
                            <FormControl onClick={() => setLengthOfAppointmentOpen2(!lengthOfAppointmentOpen2)} className='formcontrol text_selectfield_copy_upper margin_bottom margin_top'>
                              <Select
                                MenuProps={{
                                  style: { zIndex: 100000 },
                                }}
                                size='small'
                                align='left'
                                value={getAppointmentLenght}
                                select
                                onClose={() => setLengthOfAppointmentOpen2(false)}
                                onOpen={() => setLengthOfAppointmentOpen2(true)}
                                open={lengthOfAppointmentOpen2}
                                onChange={(e) => {
                                  setGetAppointmentLength(e.target.value);
                                }}
                                displayEmpty
                                startAdornment={<InputAdornment position='start'>Length of Appointment :&nbsp;</InputAdornment>}
                              >
                                <MenuItem value={''}>
                                  <b>─ ─</b>
                                </MenuItem>
                                {lengthOfAppointment.map((i) => {
                                  return (
                                    <MenuItem value={i}>
                                      <b>{i} min</b>
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Box>

                          <Box align='left' className=''>
                            <TextField
                              sx={{ backgroundColor: '#f4f4f4' }}
                              className='drawer_textfieldcolor formcontrol text_selectfield_copy_upper margin_top'
                              placeholder='Notes'
                              value={getAppointmentNotes}
                              onChange={(e) => {
                                setGetAppointmentNotes(e.target.value);
                              }}
                            />
                          </Box>
                          <hr />

                          <span
                            onClick={() => {
                              EditScheduleAppointment();
                              closeScheduleAppiontmentFun();
                              setEditButtonToOpenCalender(false);
                              setCloseAppointmentLenght(true);
                              setOpenScheduleAppiontment(false);
                              setCloseListAfterClickEdit(false);
                              setEditOpenSelectAppointmentLenght(false);
                              setCloseAppointmentLenght(false);
                              setEditCalenderOpen(false);
                            }}
                            className='button_span button_top_space button_bottom_space'
                            onMouseEnter={handleHoverFun3}
                            onMouseLeave={handleMouseLeaveFun3}
                          >
                            <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                              {isHovered3 ? <img className='primary_icons_dimension_1' src={CalenderHovIcon} alt='CalenderHovIcon' /> : <img className='primary_icons_dimension_1' src={calenderIconAdd} alt='calenderIconAdd' />}

                              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover9} onMouseLeave={handleHover9}>
                                SAVE CHANGES
                              </Typography>
                            </Box>
                          </span>

                          <Box>
                            <span
                              className='gray_button_span button_top_space appointment_cancel_icon_margin'
                              style={{
                                paddingBottom: '30px',
                                marginLeft: '19px !important',
                              }}
                              onClick={() => {
                                setCloseAppointmentLenght(true);
                                setCloseListAfterClickEdit(false);

                                setEditButtonToOpenCalender(false);
                                setEditOpenSelectAppointmentLenght(false);

                                setSetEditTime(valueincalendar);
                                setEditCalenderOpen(false);
                              }}
                            >
                              <Box className='globalIconMainBox'>
                                <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered10 ? 'hovered' : ''}`} alt='CancelIcon' />
                                <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover10} onMouseLeave={handleHover10}>
                                  CANCEL
                                </Typography>
                              </Box>
                            </span>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                ''
              )}

              {editOpenSelectAppointmentLenght && !closeAppointmentLenght ? (
                <>
                  <Box>
                    <Box>
                      <Typography>
                        Date Selected : <span className='font_weight_bold'>{moment(EditTime).local().format('MMMM DD, YYYY')}</span>
                      </Typography>
                      <Typography className='font_weight_bold font_size'>Select Time and Duration for the Appointment</Typography>
                    </Box>
                    <Box>
                      <ThemeProvider theme={theme}>
                        <Box align='center' className='filter-box'>
                          <FormControl onClick={() => setAppointmentTimeOpen2(!appointmentTimeOpen2)} className='formcontrol text_selectfield'>
                            <Select
                              MenuProps={{
                                style: { zIndex: 100000 },
                              }}
                              size='small'
                              align='left'
                              select
                              onClose={() => setAppointmentTimeOpen2(false)}
                              onOpen={() => setAppointmentTimeOpen2(true)}
                              open={appointmentTimeOpen2}
                              required
                              value={getAppointmentTime}
                              onChange={(e) => {
                                setGetAppointmentTime(e.target.value);
                              }}
                              displayEmpty
                              startAdornment={
                                <InputAdornment position='start'>
                                  Appointment Time <span style={{ color: '#DB3131' }}>&nbsp;*</span>
                                  &nbsp;:&nbsp;
                                </InputAdornment>
                              }
                            >
                              <MenuItem value={''}>
                                <b>─ ─</b>
                              </MenuItem>
                              {timeOfAppointment.map((i) => {
                                return (
                                  <MenuItem value={i}>
                                    <b>{i}</b>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                      </ThemeProvider>

                      <Box align='center' className='filter-box'>
                        <FormControl onClick={() => setLengthOfAppointmentOpen3(!lengthOfAppointmentOpen3)} className='formcontrol text_selectfield'>
                          <Select
                            MenuProps={{
                              style: { zIndex: 100000 },
                            }}
                            size='small'
                            align='left'
                            value={getAppointmentLenght}
                            select
                            onClose={() => setLengthOfAppointmentOpen3(false)}
                            onOpen={() => setLengthOfAppointmentOpen3(true)}
                            open={lengthOfAppointmentOpen3}
                            onChange={(e) => {
                              setGetAppointmentLength(e.target.value);
                            }}
                            displayEmpty
                            startAdornment={<InputAdornment position='start'>Length of Appointment :&nbsp;</InputAdornment>}
                          >
                            <MenuItem value={''}>
                              <b>─ ─</b>
                            </MenuItem>
                            {lengthOfAppointment.map((i) => {
                              return (
                                <MenuItem value={i}>
                                  <b>{i} min</b>
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>

                      <Box align='center' className='filter-box'>
                        <TextField
                          sx={{ backgroundColor: '#f4f4f4' }}
                          className='drawer_textfieldcolor formcontrol text_selectfield'
                          placeholder='Notes'
                          value={getAppointmentNotes}
                          onChange={(e) => {
                            setGetAppointmentNotes(e.target.value);
                          }}
                        />
                      </Box>
                      <hr />

                      <span
                        onClick={() => {
                          EditScheduleAppointment();
                          closeScheduleAppiontmentFun();
                          setEditButtonToOpenCalender(false);
                          setCloseAppointmentLenght(true);
                          setOpenScheduleAppiontment(false);
                          setCloseListAfterClickEdit(false);
                          setEditOpenSelectAppointmentLenght(false);
                          setCloseAppointmentLenght(false);
                        }}
                        className='button_span button_top_space'
                      >
                        <Box className='globalIconMainBox'>
                          <img className='primary_icons_dimension' src={calenderIcon} alt='calender' />
                          <Avatar color='primary' className='globalBadgeContent'>
                            <AddIcon className='globalAddContent' />
                          </Avatar>
                          <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                            SCHEDULE APPOINTMENT
                          </Typography>
                        </Box>
                      </span>

                      <span
                        onClick={() => {
                          setCloseAppointmentLenght(true);
                          setCloseListAfterClickEdit(false);

                          setEditButtonToOpenCalender(false);
                          setEditOpenSelectAppointmentLenght(false);
                          timer();
                          setSetEditTime(valueincalendar);
                        }}
                        className='gray_button_span padding_Cancel_Button_Proposal_Apply_Covering_Page button_top_space'
                      >
                        <Box className='globalIconMainBox'>
                          <img src={CancelIcon} className='secondary_icons_dimension' alt='Cancel' />
                          <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                            CANCEL
                          </Typography>
                        </Box>
                      </span>
                    </Box>
                  </Box>
                </>
              ) : (
                ''
              )}
            </>
          ) : null}
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default CustomerDetailsforOldCustomer;
