/** @format */

import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import CONFIG from '../config/config.js';

const { REACT_APP_CLIENT_ID, REACT_APP_SCOPE } = process.env;

export default function Settings() {
  const navigate = useNavigate();
  const user = sessionStorage.getItem('user');
  const token = sessionStorage.getItem('token');

  const REDIRECT_URI = `${window.location.origin}/tbm/ps/dashboard`;

  useEffect(() => {
    window.scrollTo(0, 0);
    getVerified();
  }, []);

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  return (
    <div align='center'>
      <h3>WELCOME</h3>
      <h2>
        <b>{user}</b>
      </h2>
      <h3>
        <Moment format='MM/DD/YY, h:mm a' />
      </h3>
      <hr />
      <Box className='landing_page_main_box'>
        <Box>
          <Button className='landing_page_sub_box' href={`https://theblindman.na1.echosign.com/public/oauth/v2?redirect_uri=${REDIRECT_URI}&response_type=code&client_id=${REACT_APP_CLIENT_ID}&scope=${REACT_APP_SCOPE}`} variant='contained'>
            Get Adobe Sign OAuth
          </Button>
        </Box>
      </Box>
    </div>
  );
}
