import React, { useState } from 'react';
import { List, ListItem, ListItemText, ButtonGroup, Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import TrashButton from '../../assets/icons/trash misc.svg';
import LongText from '../LongText';

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(blueGrey[50]),
  backgroundColor: blueGrey[50],
  borderColor: blueGrey[200],
  width: 1,
  '&:hover': {
    backgroundColor: blueGrey[100],
    borderColor: blueGrey[300],
  },
}));

const StyledInput = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#eee',
    '& fieldset': {
      borderRadius: 0,
      borderColor: blueGrey[200],
    },
    '&:hover fieldset': {
      borderColor: blueGrey[300],
    },
    '&.Mui-focused fieldset': {
      borderColor: blueGrey[500],
    },
    '& input': {
      textAlign: 'center',
      width: 20,
      color: blueGrey[700],
    },
  },
});

export default function AddOnList({ addOns, updateAddOns }) {
  const updateItem = (item) => {
    if (item.quantity <= 0) {
      return updateAddOns({
        action: `REMOVE_${item.type.split(' ').join('_').toUpperCase()}`,
        payload: item,
      });
    }
    return updateAddOns({
      action: `UPDATE_${item.type?.split(' ').join('_').toUpperCase()}`,
      payload: item,
    });
  };
  const updateQuantity = (item, { action, payload }) => {
    switch (action) {
      case 'INCREMENT':
        return updateItem({
          ...item,
          quantity: +item.quantity < 99 ? +item.quantity + 1 : item.quantity,
        });
      case 'DECREMENT':
        return updateItem({
          ...item,
          quantity: +item.quantity - 1,
        });
      case 'SET_QUANTITY':
        if (payload >= 99) {
          return updateItem({
            ...item,
            quantity: 99,
          });
        }
        if (payload < 99) {
          return updateItem({
            ...item,
            quantity: +payload,
          });
        }
      default:
        return updateItem({
          ...item,
          quantity: +item.quantity,
        });
    }
  };

  return (
    <List>
      {addOns.map((item) => (
        <AddOnListItem key={item.sku} handleChange={(action) => updateQuantity(item, action)} description={item.service_name || item.accessory_name} quantity={item.quantity} price={+item.dollar_retail_charge * +item.quantity} />
      ))}
    </List>
  );
}

function AddOnListItem({ handleChange, description, price, quantity }) {
  const [focus, setFocus] = useState(false);
  return (
    <ListItem
      sx={{
        width: '100%',
        color: '#eee',
        // display: {xs}
      }}
      secondaryAction={
        <ButtonGroup sx={{ justifySelf: 'center' }} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
          <StyledButton
            onClick={() =>
              handleChange({
                action: 'DECREMENT',
              })
            }
          >
            {quantity > 1 ? <RemoveIcon fontSize='small' /> : <img alt='Apply Measurement' src={TrashButton} className='toggle_icon' />}
          </StyledButton>
          <StyledInput
            type='number'
            size='small'
            onChange={({ target }) =>
              target.value < 99
                ? handleChange({
                    action: 'SET_QUANTITY',
                    payload: target.value,
                  })
                : null
            }
            value={quantity}
          />
          <StyledButton
            onClick={() => {
              handleChange({
                action: 'INCREMENT',
              });
            }}
          >
            <AddIcon fontSize='small' />
          </StyledButton>
        </ButtonGroup>
      }
    >
      <ListItemText
        sx={{
          color: '#eee',
          width: { xs: '50%', sm: '100%' },
          '& .MuiListItemText-primary': {
            textWrap: 'break-word',
          },
          '& .MuiListItemText-secondary': {
            color: '#eee',
            fontWeight: 'bold',
            fontSize: '1rem',
          },
        }}
        secondary={`$${price.toFixed(2)}`}
      >
        <LongText>{description}</LongText>
      </ListItemText>
    </ListItem>
  );
}
