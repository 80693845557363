import { Box } from '@mui/system';
import React, { useState } from 'react';
import SaveCustomerAdd from '../../assets/icons/primary/customer-complete.svg';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import { Button, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CONFIG from '../../config/config.js';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material';
import DrawerCancelIcon from '../../assets/icons/drawer-alert/2drw-cancel.svg';
import DrawerAlertIcon from '../../assets/icons/drawer-alert/drw-alert.svg';
import DrawerConfirmIcon from '../../assets/icons/drawer-alert/drw-confirm.svg';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import CustomerCheckIcon from '../../assets/icons/primary/WithHovIcon/customer-comp-hov.svg';

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
export default function EditCustomerFirstLastName() {
  const location = useLocation();
  const navigate = useNavigate();
  let locationState = [];

  if (location.state === null) {
    navigate('/pagenotfound');
  } else {
    locationState = location.state;
  }

  const vertical = 'top';
  const horizontal = 'center';
  const [husbandFirstName, setHusbandFirstName] = useState(locationState.firstName);
  const [wifeFirstName, setWifeFirstName] = useState(locationState.wifename);
  const [lastName, setLastName] = useState(locationState.lastname);
  const [showButton, setShowButton] = useState(false);
  const [errormsg, setErrorMsg] = React.useState(false);
  const [alertMsg, setAlertMsg] = useState(false);

  // error handling states
  const [UpdateCustomerNameErrorMsg, setUpdateCustomerNameErrorMsg] = useState(false);
  const [UpdateCustomerNameErrorMsgTxt, setUpdateCustomerNameErrorMsgTxt] = useState('');

  //  hover states

  const [isHovered, setIsHovered] = useState(false);

  const handleHoverFun = () => {
    setIsHovered(true);
  };

  const handleMouseLeaveFun = () => {
    setIsHovered(false);
  };

  //-----------------------------------------------Event_handlers & Functions--------------------------------
  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMsg(false);
  };

  const updateCustomerName = async () => {
    const token = sessionStorage.getItem('token');
    await fetch(`${CONFIG.API_URL}/cm/UpdateCustomerName`, {
      method: 'PUT',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
      body: JSON.stringify({
        customer_id: locationState.customer_id,
        h_firstname: husbandFirstName,
        w_firstname: wifeFirstName,
        last_name: lastName,
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        if (!data.error) {
          setErrorMsg(true);

          setTimeout(() => {
            navigate(`/tbm/ps/customerdetails`, {
              state: {
                Custdata: {
                  custid: locationState.customer_id,
                  Addressid: locationState.address_id,
                },
              },
            });
          }, 3000);
        } else {
          setUpdateCustomerNameErrorMsgTxt(data.error_message);
          setUpdateCustomerNameErrorMsg(true);
        }
      });
  };

  const [isImageHovered, setIsImageHovered] = useState(false);
  const handleHover = () => {
    setIsImageHovered(!isImageHovered);
  };

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => {
    setIsImageHovered2(!isImageHovered2);
  };

  //----------------------------------------------------------------------------------------------------
  return (
    <div align='center'>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={UpdateCustomerNameErrorMsg} anchorOrigin={{ vertical, horizontal }} onClose={() => setUpdateCustomerNameErrorMsg(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img
                onClick={() => {
                  setUpdateCustomerNameErrorMsg(false);
                }}
                src={RedDrawerCancelIcon}
                alt='RedDrawerCancelIcon'
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={RedExclamationIcon} alt='' className='primary_icons_dimension alertImageMargin' />
              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{UpdateCustomerNameErrorMsgTxt}</div>
            </div>
          </Alert>
        </Snackbar>

        <Snackbar className='snackbar_bar_postition' open={errormsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setErrorMsg(false)}>
          <Alert className='Success2 mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel_2'>
                <img onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={DrawerAlertIcon} className='primary_icons_dimension' style={{ margin: '0px auto' }} alt='DrawerAlertIcon' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Customer Name Updated Successfully..!</div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={14} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={alertMsg} anchorOrigin={{ vertical, horizontal }} autoHideDuration={3000} onClose={() => setAlertMsg(false)}>
          <Alert className='Success2 mainAlert' variant='filled' icon={false}>
            <>
              <div className='alertCancel_2'>
                <img onClick={handleErrorClose} src={DrawerCancelIcon} className='drawer_secondary_icons_dimension drawer_cancel_cursor' alt='DrawerCancelIcon' />
              </div>
              <div className='alertMain_2_4 confirmIconStyle1'>
                <img src={DrawerConfirmIcon} className='primary_icons_dimension' alt='DrawerConfirmIcon' />
                <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>Field cannot be empty..!</div>
              </div>
            </>
          </Alert>
        </Snackbar>
      </Stack>
      <Box className='button_top_space'>
        <ThemeProvider theme={theme}>
          <Box align='left' sx={{ display: 'flex', flexDirection: 'column' }} noValidate autoComplete='off'>
            <TextField
              sx={{
                input: {
                  color: 'black',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                },
              }}
              className='drawer_textfieldcolor_1 formcontrol text_selectfield font_style_of_menuitem '
              label='Husband First Name'
              value={husbandFirstName}
              onChange={(e) => {
                setHusbandFirstName(e.target.value);
                setShowButton(true);
              }}
            />
            <TextField
              className='drawer_textfieldcolor_1 formcontrol text_selectfield font_style_of_menuitem'
              label='Wife First Name'
              sx={{
                input: {
                  color: 'black',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                },
              }}
              onChange={(e) => {
                setWifeFirstName(e.target.value);
                setShowButton(true);
              }}
              value={wifeFirstName}
            />

            <TextField
              sx={{
                input: {
                  color: 'black',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                },
              }}
              className='drawer_textfieldcolor formcontrol text_selectfield font_style_of_menuitem'
              label='Last Name'
              required
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setShowButton(true);
              }}
            />
            <hr className='button_bottom_space' sx={{ paddingTop: '10px !important' }} />
            {showButton && lastName ? (
              <Box className='submitBox'>
                <Button sx={{ padding: '0px 8px !important' }} className='globalDrawerText search_details_button_1 search_details_button_right submit_button_customername' onClick={updateCustomerName} onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
                  {' '}
                  <Box className='globalIconMainBox'>
                    {isHovered ? <img className='primary_icons_dimension_1' src={CustomerCheckIcon} alt='CustomerCheckIcon' /> : <img className='primary_icons_dimension_1' src={SaveCustomerAdd} alt='SaveCustomerAdd' />}

                    <Typography onMouseEnter={handleHover} onMouseLeave={handleHover} sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
                      {' '}
                      SAVE CUSTOMER NAME
                    </Typography>
                  </Box>
                </Button>
              </Box>
            ) : (
              <Box className='submitBox'>
                <Button sx={{ padding: '0px 8px !important' }} className='disable_submit_box_button' disabled>
                  {' '}
                  <Box className='globalIconMainBox globalMarginLeftAddWindow'>
                    <img className='primary_icons_dimension_disable' src={SaveCustomerAdd} alt='SaveCustomerAdd' />
                    <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalTextDisabled'>
                      SAVE CUSTOMER NAME
                    </Typography>
                  </Box>
                </Button>
              </Box>
            )}
          </Box>
        </ThemeProvider>

        <span className='gray_button_span button_top_space' style={{ paddingBottom: '30px', paddingLeft: '21px' }}>
          <Box
            onClick={() =>
              navigate(`/tbm/ps/customerdetails`, {
                state: {
                  Custdata: {
                    custid: locationState.customer_id,
                    Addressid: locationState.address_id,
                  },
                },
              })
            }
            className='globalIconMainBox'
          >
            <img src={CancelIcon} className={`secondary_icons_dimension ${isImageHovered2 ? 'hovered' : ''}`} alt='CancelIcon' />

            <Typography
              onClick={() =>
                navigate(`/tbm/ps/customerdetails`, {
                  state: {
                    Custdata: {
                      custid: locationState.customer_id,
                      Addressid: locationState.address_id,
                    },
                  },
                })
              }
              sx={{ fontFamily: 'RobotoLight' }}
              onMouseEnter={handleHover2}
              onMouseLeave={handleHover2}
              className='globalText'
            >
              CANCEL
            </Typography>
          </Box>
        </span>
      </Box>
    </div>
  );
}
