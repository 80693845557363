/** @format */

import { Box } from "@mui/system";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ModeEditIcon from "../../img/edit.png";
import save from "../../img/save.png";
import Fab from "@mui/material/Fab";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const EC = [
  {
    value: "None",
    label: "None",
  },
  {
    value: "Customer Removal",
    label: "Customer Removal",
  },
  {
    value: "Remove & leave",
    label: "Remove & leave",
  },
  {
    value: "Remove & haul away",
    label: "Remove & haul away",
  },

  {
    value: "Specialty Removal",
    label: "Specialty Removal",
  },
];

//Mout Surface Select Options
const MS = [
  {
    value: "Drywall",
    label: "Drywall",
  },
  {
    value: "Door",
    label: "Door",
  },
  {
    value: "Tile",
    label: "Tile",
  },
  {
    value: "Aluminium",
    label: "Aluminium",
  },
  {
    value: "Steel",
    label: "Steel",
  },
  {
    value: "Cement",
    label: "Cement",
  },
  {
    value: "Plaster",
    label: "Plaster",
  },
];

//Build Type
const BT = [
  {
    value: "Inside",
    label: "Inside",
  },
  {
    value: "Overall",
    label: "Overall",
  },
  {
    value: "Fabric",
    label: "Fabric",
  },
];
// Ladder Select Options
const LT = [
  {
    value: "None",
    label: "None",
  },
  {
    value: "Step",
    label: "Step",
  },
  {
    value: "8'",
    label: "8 ft",
  },
  {
    value: "15'",
    label: "15 ft",
  },
  {
    value: "25'",
    label: "25 ft",
  },
  {
    value: "Specialty",
    label: "Specialty",
  },
];
export default function EditWindowDetailsInSalesOrder() {
  const location = useLocation();
  const navigate = useNavigate();

  const [ProposalName, setProposalName] = useState("Proposal " + `1`);
  const [measurementType, setMeasurementType] = useState("");
  const [Typemeasurement, setTypeMeasurement] = useState([]);
  const [outgoingmountsurface, setOutgoingMountsurface] = useState("");
  const [outgoingladder, setOutgoingLadder] = useState("");
  const [TypeHeadrail, setTypeHeadrail] = useState([]);
  const [valanceOpen, setValanceOpen] = useState(false);
  const [headrailOpen, setHeadrailOpen] = useState(false);
  const [cutoutsOpen, setCutoutsOpen] = useState(false);
  const [materialOpen, setMaterialOpen] = useState(false);
  const [tiltOpen, setTiltOpen] = useState(false);
  const [cordOpen, setCordOpen] = useState(false);

  return (
    <div align="center">
      <div className="heading-box">
        <h2
          className="clickable_header all_pages_h2_header"

        >
          {/* SALES ORDER */}
          {ProposalName}
        </h2>

        <h3
          className="clickable_header all_pages_h2_header"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            navigate(`/tbm/ps/customerdetails`, {
              state: {
                Custdata: {
                  custid:
                    location.state.CustomerDetails.customerdata.customer_id,
                  Addressid:
                    location.state.CustomerDetails.customerdata.address_id,
                },
              },
            });
          }}
        >
          {/* customer_id */}
          {location.state.CustomerDetails.customerdata.last_name} |{" "}
          {location.state.CustomerDetails.customerdata.city}
        </h3>
      </div>
      <hr />
      <Box>
        <Box align="left" className="filter-box padding_top">
          <TextField
            id="outlined-select-option"
            className="text_selectfield formcontrol "
            select
            size="small"
            required
            InputProps={{
              startAdornment: (
                <InputAdornment className="font_style_of_menuitem" position="start">
                  Measurement Type
                  <span style={{ color: "#DB3131" }}></span>&nbsp;:
                  {measurementType === "" ? <span>─ ─</span> : ""}
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value={""}>
              <b>─ ─</b>
            </MenuItem>
            {Typemeasurement.map((option) => (
              <MenuItem
                key={option.measurement_type}
                value={option.measurement_type}
              >
                <b
                  className="font_style_of_menuitem"
                  style={{ color: option.color_code }}
                >
                  {option.measurement_type}({option.short_code})
                </b>
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>

      <TextField
        className="formcontrol text_inputfield font_style_of_menuitem"
        id="filled-basic"
        label="Width X Height"
        variant="outlined"
        required
        InputProps={{
          style: { fontWeight: "bold" },
        }}
      />
      <hr />

      <TextField
        id="outlined-select-option"
        className="text_selectfield formcontrol font_style_of_menuitem"
        select
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment
              style={{ fontFamily: "Roboto !important" }}
              className="font_style_of_menuitem"
              position="start"
            >
              Existing Coverings :
            </InputAdornment>
          ),
        }}
        noValidate
        autoComplete="off"
      >
        <MenuItem value={""}>
          <b>─ ─</b>
        </MenuItem>
        {EC.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <b className="font_style_of_menuitem">{option.label}</b>
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="outlined-select-option"
        className="text_selectfield formcontrol font_style_of_menuitem"
        select
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment className="font_style_of_menuitem" position="start">
              Build Type :
            </InputAdornment>
          ),
        }}
        noValidate
        autoComplete="off"
      >
        <MenuItem value={""}>
          <b>─ ─</b>
        </MenuItem>
        {BT.map((options) => (
          <MenuItem key={options.value} value={options.value}>
            <b className="font_style_of_menuitem">{options.label}</b>
          </MenuItem>
        ))}
      </TextField>

      <TextField
        className="text_selectfield formcontrol"
        id="outlined-select-option"
        select
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment className="font_style_of_menuitem" position="start">
              Mount Surface :
              {outgoingmountsurface === "" ? <span>─ ─</span> : ""}
            </InputAdornment>
          ),
        }}
        noValidate
        autoComplete="off"
      >
        <MenuItem value={""}>
          <b>─ ─</b>
        </MenuItem>
        {MS.map((mountsurface) => {
          return (
            <MenuItem key={mountsurface.value} value={mountsurface.value}>
              <b className="font_style_of_menuitem">{mountsurface.label}</b>
            </MenuItem>
          );
        })}
      </TextField>

      <TextField
        className="text_selectfield formcontrol"
        id="outlined-select-option"
        select
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment className="font_style_of_menuitem" position="start">
              Ladder :{outgoingladder === "" ? <span>─ ─</span> : ""}
            </InputAdornment>
          ),
        }}
        noValidate
        autoComplete="off"
      >
        <MenuItem value={""}>
          <b>─ ─</b>
        </MenuItem>
        {LT.map((ladder) => {
          return (
            <MenuItem key={ladder.value} value={ladder.value}>
              <b className="font_style_of_menuitem">{ladder.label}</b>
            </MenuItem>
          );
        })}
      </TextField>

      <FormControl
        onClick={() => setValanceOpen(!valanceOpen)}
        className="formcontrol text_selectfield"
      >
        <Select
          required
          align="left"
          size="small"
          onClose={() => setValanceOpen(false)}
          onOpen={() => setValanceOpen(true)}
          open={valanceOpen}
          displayEmpty
          startAdornment={
            <InputAdornment position="start">
              Valance Style<span style={{ color: "#DB3131" }}>&nbsp;*</span>
              &nbsp;:
            </InputAdornment>
          }
        >
          <MenuItem value={""}>
            <b>─ ─</b>
          </MenuItem>
          {TypeHeadrail.map((option) => (
            <MenuItem key={option.type_headrail} value={option.type_headrail}>
              <b>{option.type_headrail}</b>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        onClick={() => setHeadrailOpen(!headrailOpen)}
        className="formcontrol text_selectfield"
      >
        <Select
          required
          align="left"
          size="small"
          onClose={() => setHeadrailOpen(false)}
          onOpen={() => setHeadrailOpen(true)}
          open={headrailOpen}
          displayEmpty
          startAdornment={
            <InputAdornment position="start">
              Window Configuration<span style={{ color: "#DB3131" }}>&nbsp;*</span>
              &nbsp;:
            </InputAdornment>
          }
        >
          <MenuItem value={""}>
            <b>─ ─</b>
          </MenuItem>
          {TypeHeadrail.map((option) => (
            <MenuItem key={option.type_headrail} value={option.type_headrail}>
              <b>{option.type_headrail}</b>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        className="short_textfield formcontrol"
        id="filled-basic"
        label="Left Shade W x H"
        variant="outlined"
        required
        InputProps={{
          style: { fontWeight: "bold" },
        }}
      />
      <TextField
        className="short_textfield formcontrol"
        id="filled-basic"
        label="Right Shade W x H"
        variant="outlined"
        required
        InputProps={{
          style: { fontWeight: "bold" },
        }}
      />

      <FormControl
        onClick={() => setCutoutsOpen(!cutoutsOpen)}
        className="formcontrol text_selectfield"
      >
        <Select
          required
          align="left"
          size="small"
          onClose={() => setCutoutsOpen(false)}
          onOpen={() => setCutoutsOpen(true)}
          open={cutoutsOpen}
          displayEmpty
          startAdornment={
            <InputAdornment position="start">
              Obstructions<span style={{ color: "#DB3131" }}>&nbsp;*</span>&nbsp;:
            </InputAdornment>
          }
        >
          <MenuItem value={""}>
            <b>─ ─</b>
          </MenuItem>
          <MenuItem value="None">
            {" "}
            <b>None</b>
          </MenuItem>
          <MenuItem value="1">
            {" "}
            <b>1</b>
          </MenuItem>
          <MenuItem value="2">
            {" "}
            <b>2</b>
          </MenuItem>
        </Select>
      </FormControl>

      <hr />
      <span>
        Blindman Premium Wood Blind 2 Inch Chart A (blanco, vivid, bone){" "}
      </span>

      <span
        style={{ paddingLeft: "-20px !important" }}
        className="edit_gray_button"
      >
        <Fab
          className="graybutton"
          aria-label="back"
       
        >
          <img
            src={ModeEditIcon}
            style={{
              width: "25px",
              height: "25px",
              position: "absolute",
            }}
            className="icons1"
            alt="edit"
          />
        </Fab>
        <span
          onClick={() => {
          }}
          className="gray_button_text"
        >
          EDIT SELECT PRODUCT
        </span>
      </span>
      <hr />

      <FormControl
        onClick={() => setMaterialOpen(!materialOpen)}
        className="formcontrol text_selectfield"
      >
        <Select
          required
          align="left"
          size="small"
          onClose={() => setMaterialOpen(false)}
          onOpen={() => setMaterialOpen(true)}
          open={materialOpen}
          displayEmpty
          startAdornment={
            <InputAdornment position="start">
              Material Color
              <span style={{ color: "#DB3131" }}>&nbsp;</span>&nbsp;:
            </InputAdornment>
          }
        >

          <MenuItem>- -</MenuItem>
        </Select>
      </FormControl>


      <FormControl
        onClick={() => setCordOpen(!cordOpen)}
        className="formcontrol text_selectfield"
      >
        <Select
          required
          align="left"
          size="small"
          onClose={() => setCordOpen(false)}
          onOpen={() => setCordOpen(true)}
          open={cordOpen}
          displayEmpty
          startAdornment={
            <InputAdornment position="start">
              Control Side
              <span style={{ color: "#DB3131" }}>&nbsp;</span>&nbsp;:
            </InputAdornment>
          }
        >
          <MenuItem>- -</MenuItem>
        </Select>
      </FormControl>
      <hr />

      <Box sx={{ display: "flex", textAlign: "start" }}>
        <Checkbox {...label} sx={{ width: "5%" }} />
        <Typography
          sx={{ marginTop: "10px", marginLeft: "10px", color: "#0ca8b0" }}
        >
          CONFIRM FOR SALES ORDER
        </Typography>
      </Box>
      <hr />

      <span className="button_span" style={{ paddingBottom: "10px" }}>
        <Fab disabled className="fab_button" aria-label="back">
          <img
            src={save}
            style={{
              width: "20px",
              height: "20px",
              position: "absolute",
            }}
            className="icons1"
            alt="edit"
          />
        </Fab>
        <span className="button_text">SAVE CHANGES</span>
      </span>
      <hr />
      <span
        className="gray_button_span button_top_space"
        style={{ paddingBottom: "30px" }}
      >
        <Fab
          className="graybutton"
          aria-label="cancel"
        >
          <CloseIcon className="icons" />
        </Fab>
        <span className="gray_button_text">CANCEL</span>
      </span>

      <br />
      <br />
    </div>
  );
}
