/** @format */

import 'react-loading-skeleton/dist/skeleton.css';
import React, { useState, useEffect, forwardRef } from 'react';
import { Alert as MuiAlert, Box, Button, Typography, Snackbar, Stack } from '@mui/material';

import CONFIG from '../../config/config.js';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';
import ProductGridViewIcon from '../../assets/icons/secondary/productGridView.svg';
import WindowsTable from './WindowTable';
import ProductGridView from './ProductGridView';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function ProposalTable({ commonData, windows, totalWindows, disabled }) {
  const vertical = 'top';
  const horizontal = 'center';

  const [openGridView, setOpenGridView] = useState(false);

  const token = sessionStorage.getItem('token');

  // Error Handling

  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <Box className='proposal_table_main_box button_top_space'>
        <Box className='proposal_table_sub_box'>
          <WindowsTable
            disabled={disabled}
            state={{
              data: commonData.windowsetdata,
              selectedJsonData: commonData.selectedJsonData,
              proposalID: commonData.id,
              totalWindows,
            }}
            windows={windows}
          />

          {/* Button to Display Product Grid View */}
          <Button
            onClick={() => setOpenGridView(!openGridView)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
              width: 'fit-content',
              contentAlign: 'center',
              justifyContent: 'left',
              paddingLeft: '1.5em',
            }}
          >
            <img alt='Product Grid View' src={ProductGridViewIcon} className={`secondary_icons_dimension ${isHovered ? 'hovered' : null}`} />
            <Typography sx={{ fontFamily: 'RobotoLight' }} className={`globalText ${isHovered ? 'globalTextDecoration' : ''}`}>
              {' '}
              Product Grid View
            </Typography>
          </Button>

          {/* Product Grid View */}
          {openGridView ? (
            <ProductGridView
              disabled={disabled}
              state={{
                data: commonData.windowsetdata,
                selectedJsonData: commonData.selectedJsonData,
                proposalID: commonData.id,
                totalWindows,
              }}
              windows={windows}
            />
          ) : null}
        </Box>
      </Box>
    </>
  );
}
