const { REACT_APP_BASE_URI, REACT_APP_BASE_API_URI, REACT_APP_VERSION, REACT_APP_NAME, REACT_APP_ENV, REACT_APP_BUILD } = process.env;

const config = {
  URL: REACT_APP_BASE_URI,
  API_URL: REACT_APP_BASE_API_URI,
  appVersion: REACT_APP_VERSION,
  env: REACT_APP_ENV,
  appReleasedDate: 'Nov 06, 2023',
  buildNumber: REACT_APP_BUILD,
  appCodename: REACT_APP_NAME.split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .normalize(),
  WindowsManagement: {
    WindowSetLimit: 5,
    RowperPageLimit: -1,
  },
  GlobalTruncateCharacterLimit: 30,
};

export default config;
