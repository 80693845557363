/** @format */

import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import fracty from 'fracty';
import Checkbox from '@mui/material/Checkbox';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ClearIcon from '@mui/icons-material/Clear';
import CONFIG from '../../config/config.js';

export default function SalesOrderAddingandConfirmwindows() {
  const location = useLocation();
  const navigate = useNavigate();
  const [salesOrderId, setSalesOrderId] = useState(location.state.salesOrderId);

  const [appliedclass, setAppliedClass] = React.useState('table-contentwsidwithcheckbox sticky-columnws-1');
  const [indexCheckboxClass, setIndexcheckboxclass] = useState('table-headerwsidwithcheckbox sticky-columnws-1');
  const [windowDetails, setWindowDetails] = useState([]);

  const [SalesOrderName, setSalesOrderName] = useState('Sales Order ' + `1`);
  const [showSA, setShowSA] = React.useState(true);

  const [WindowSetData, setWindowSetData] = useState(location.state.windowDetails);
  const rows = WindowSetData;
  const [selected, setSelected] = React.useState([]);
  const [checkedrowdata, setCheckedRowData] = useState([]);
  const [checkboxflag, setCheckboxFlag] = useState(false);

  const isSelected = (row) => selected.indexOf(row) !== -1;

  let isItemSelected;

  const handleClick = (event, row) => {
    if (event.target.checked === true) {
      setShowSA(true);
      const selectedIndex = selected.indexOf(row);

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      newSelected.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = true;
        }
      });

      for (var i = 0; i < newSelected.length; i++) {
        if (WindowSetData[i].selected === newSelected[i].selected) {
        }
      }

      setWindowDetails(newSelected);
      if (WindowSetData.length === newSelected.length) {
        setShowSA(false);
      }
      setCheckedRowData(newSelected);
      setSelected(newSelected);
    } else {
      // setSelected(WindowSetData)
      const selectedIndex = selected.indexOf(row);
      const selectedActualIndex = WindowSetData.indexOf(row);

      WindowSetData[selectedActualIndex].selected = false;

      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      if (WindowSetData.length > newSelected.length) {
        setShowSA(true);
      }

      setCheckedRowData(newSelected);
      setSelected(newSelected);
    }
  };
  const token = sessionStorage.getItem('token');

  const GetSaleOrderWindows = async () => {
    await fetch(`${CONFIG.API_URL}/so/salesorderdetails/${salesOrderId}`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    })
      .then((data) => data.json())
      .then((data) => {});
  };

  const handleSelectAllClick = (event) => {
    if (event === true) {
      const newSelecteds = rows.map((n) => n);

      setSelected(newSelecteds);
      setWindowDetails(newSelecteds);
      setCheckedRowData(newSelecteds);
      newSelecteds.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = true;
        }
      });
      return;
    } else {
      const newSelecteds = rows.map((n) => n);

      newSelecteds.forEach((item) => {
        const idx = WindowSetData.findIndex((e) => e === item);

        if (idx >= 0) {
          WindowSetData[idx].selected = false;
        }
      });
      setCheckedRowData([]);
      setSelected([]);
    }
  };

  const onSelectAllClick = () => {
    setShowSA(false);
    handleSelectAllClick(true);
  };

  const onDeSelectAllClick = () => {
    setShowSA(true);
    handleSelectAllClick(false);
    //
  };

  function EnhancedTableHead() {
    return (
      <TableHead>
        <TableRow>
          <TableCell className={indexCheckboxClass}>
            <span>#</span>
          </TableCell>
          <TableCell className='table-headerwsid sticky-columnws-2'>
            <span>Window Name</span>
          </TableCell>
          <TableCell className='table-header0'>Size</TableCell>
          <TableCell className='table-header1'>Build</TableCell>
          <TableCell className='table-header2'>Surface</TableCell>
          <TableCell className='table-header3'>Ladder</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <div align='center'>
      <div className='heading-box'>
        <h2 className='clickable_header all_pages_h2_header'>{SalesOrderName}</h2>

        <h3
          className='clickable_header all_pages_h2_header'
          style={{ textTransform: 'capitalize' }}
          onClick={() => {
            navigate(`/tbm/ps/customerdetails`, {
              state: {
                Custdata: {
                  custid: location.state.CustomerDetails.customerdata.customer_id,
                  Addressid: location.state.CustomerDetails.customerdata.address_id,
                },
              },
            });
          }}
        >
          {/* customer_id */}
          {location.state.CustomerDetails.customerdata.last_name} | {location.state.CustomerDetails.customerdata.city}
        </h3>
      </div>

      <Box className='prompt-box'>
        <Typography
          sx={{
            fontSize: '110%',
            color: 'white',
          }}
          variant='contained'
        >
          Select windows to add to sales order :
        </Typography>
      </Box>

      {!checkboxflag ? (
        <>
          {showSA ? (
            <span className='gray_button_span'>
              <Fab className='graybutton' aria-label='back' onClick={onSelectAllClick}>
                <CheckIcon className='icons' />
              </Fab>
              <span onClick={onSelectAllClick} className='gray_button_text'>
                SELECT ALL
              </span>
            </span>
          ) : (
            <span className='gray_button_span'>
              <Fab className='graybutton' aria-label='back' onClick={onDeSelectAllClick}>
                <ClearIcon className='icons' />
              </Fab>
              <span onClick={onDeSelectAllClick} className='gray_button_text'>
                DESELECT ALL
              </span>
            </span>
          )}
        </>
      ) : (
        ''
      )}

      <hr />
      <Box className='table-box'>
        <TableContainer sx={{ borderBottom: '1px solid #D6D6D6' }} className='table_padding_bottom'>
          <Table className='table-dimension' aria-labelledby='tableTitle' size='small'>
            <EnhancedTableHead rowCount={rows.length} />
            <TableBody>
              {rows.map((row, index) => {
                isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow hover className='rowhover' onClick={(event) => handleClick(event, row)} role='checkbox' aria-checked={isItemSelected} tabIndex={-1} key={row.window_id}>
                    <TableCell className={appliedclass} align='left'>
                      {' '}
                      <span>
                        <Checkbox
                          sx={{ zoom: 0.8, paddingTop: '0px' }}
                          color='primary'
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />

                        {rows.indexOf(row) + 1 >= 10 ? (
                          <>
                            <span style={{ paddingLeft: '7px' }}>{rows.indexOf(row) + 1}</span>
                          </>
                        ) : (
                          <>
                            <span style={{ paddingLeft: '8px' }}>{rows.indexOf(row) + 1}</span>
                          </>
                        )}
                      </span>
                    </TableCell>
                    <TableCell className='table-contentwswn sticky-columnws-2' align='left'>
                      {' '}
                      <Box sx={{ width: '100%!important' }}>
                        <Link className='link break' to='/tbm/ps/window'>
                          {row.window_name}
                        </Link>
                      </Box>
                    </TableCell>

                    <TableCell className='table-content' align='left'>
                      {fracty(row.window_width)}" x {fracty(row.window_height)}"{' '}
                      <span className={row.measurement_type.replace(/ /g, '')}>
                        {row.measurement_type === 'Quick'
                          ? '(' + 'QK' + ')'
                          : row.measurement_type === 'Shutters'
                          ? '(' + 'SH' + ')'
                          : row.measurement_type === 'Blinds'
                          ? '(' + 'BL' + ')'
                          : '(' +
                            row.measurement_type
                              .split(' ')
                              .map((word) => word[0])
                              .join('') +
                            ')'}
                      </span>
                    </TableCell>
                    <TableCell className='table-content' align='left'>
                      {row.build_type}
                    </TableCell>
                    <TableCell className='table-content' align='left'>
                      {row.mount_surface}
                    </TableCell>
                    <TableCell className='table-content' align='left'>
                      {row.ladder}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <hr />
      {checkedrowdata.length > 0 ? (
        <span
          className='button_span button_top_space button_bottom_space'
          onClick={() => {
            navigate('/tbm/ps/salesorder', {
              state: {
                CustomerDetails: location.state.CustomerDetails,
                ProposalDetails: location.state.ProposalDetails,
                windowDetails: windowDetails,
                data: location.state.data,
                remeasure: location.state.remeasure,
              },
            });
          }}
        >
          <Fab className='fab_button' aria-label='back'>
            <ArrowForwardIosIcon className='icons' />
          </Fab>
          <span className='button_text'>CONTINUE</span>
        </span>
      ) : (
        <span className='button_span_disabled' style={{ paddingBottom: '10px' }}>
          <Fab disabled className='fab_button_disabled' aria-label='back'>
            <ArrowForwardIosIcon className='icons-disabled' />
          </Fab>
          <span className='button_text_disabled'>CONTINUE</span>
        </span>
      )}
    </div>
  );
}
