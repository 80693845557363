import React from 'react';
import { Text, Page, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import * as moment from 'moment';

import CTSOHeader from './CTSOHeader';
import CTSOSubHeader from './CTSOSubHeader';
import CTSOBody from './CTSOBody';
import CustomerPurchaseAgreement from './CustomerPurchaseAgreement';
import CustomerAuthorization from './CustomerAuthorization';
import WarrantyInformation from './WarrantyInformation';
import ProductSafety from './ProductSafety';

import SalesOrderInitials from '../../../assets/icons/primary/SO-Initials.png';
import SalesOrderSignature from '../../../assets/icons/primary/SO-SIGN.png';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 10,
    flexDirection: 'col',
    paddingBottom: 100,
  },
  horizontalborder: {
    width: '100%',
    height: '2px',
    flexDirection: 'row',
    backgroundColor: '#7E7E7E',
  },
  horizontalblackborder: {
    width: '100%',
    height: '12px',
    flexDirection: 'row',
    backgroundColor: '#2D3030',
  },
  footerimg: {
    position: 'absolute',
    right: 100,
    bottom: 50,
    textAlign: 'center',
  },
  footerimgsign: {
    position: 'absolute',
    right: 142,
    bottom: 50,
    textAlign: 'center',
  },
});

const Pdf = ({ path, state, customer, notes }) => {
  const { salesOrder } = state;
  const salesOrderNumber = salesOrder?.sales_order_name?.replace('Sales Order', '');
  const date = moment(salesOrder?.sales_order_entry_date).format('L');
  const name = customer.first_name === 'NA' ? customer.last_name : `${customer.first_name} ${customer.wife === 'NA' ? null : `& ${customer.wife}`} ${customer.last_name}`;

  return (
    <Document>
      <Page size='A4' dpi='75' orientation='portrait' style={styles.page}>
        <CTSOHeader name={name} />
        <CTSOSubHeader date={date} customerDetails={customer} salesRep={salesOrder.sales_rep} orderNumber={salesOrderNumber} />

        <View style={styles.horizontalborder}>&nbsp;</View>
        <View style={styles.horizontalblackborder}>&nbsp;</View>

        <CTSOBody data={state} notes={notes} />
        <View style={styles.footerimg}>{path === 'PreviewSalesOrder' ? null : <Image src={SalesOrderInitials} style={{ width: 180, height: 50 }} alt='Sales Order Initials' fixed />}</View>
      </Page>
      <Page size='A4' dpi='75' orientation='portrait' style={styles.page}>
        <CustomerPurchaseAgreement />
        <View style={styles.footerimg}>{path === 'PreviewSalesOrder' ? null : <Image src={SalesOrderInitials} style={{ width: 180, height: 50 }} alt='Sales Order Initials' fixed />}</View>
      </Page>
      <Page size='A4' dpi='75' orientation='portrait' style={styles.page}>
        <WarrantyInformation />
        <View style={styles.footerimg}>{path === 'PreviewSalesOrder' ? null : <Image src={SalesOrderInitials} style={{ width: 180, height: 50 }} alt='Sales Order Initials' fixed />}</View>
      </Page>
      <Page size='A4' dpi='75' orientation='portrait' style={styles.page}>
        <ProductSafety />
        <View style={styles.footerimg}>{path === 'PreviewSalesOrder' ? null : <Image src={SalesOrderInitials} style={{ width: 180, height: 50 }} alt='Sales Order Initials' fixed />}</View>
      </Page>
      <Page size='A4' dpi='75' orientation='portrait' style={styles.page}>
        <CustomerAuthorization />
      </Page>
    </Document>
  );
};

export default Pdf;
