/** @format */

import React, { forwardRef, useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CONFIG from '../../config/config.js';
import ProposalTable from './ProposalTable';
import AddWindowIcon2 from '../../assets/icons/primary/window-add.svg';
import DeleteWindowHovIcon from '../../assets/icons/primary/WithHovIcon/window-delete-hov.svg';
import DeleteWindowIcon from '../../assets/icons/primary/window-delete.svg';
import ReorderWindowHovIcon from '../../assets/icons/primary/WithHovIcon/move-hov.svg';
import ReorderWindowIcon from '../../assets/icons/primary/window-move.svg';
import AddWindowHovIcon from '../../assets/icons/primary/WithHovIcon/window-add-hov.svg';
import AddMultipleLineIcon2 from '../../assets/icons/primary/windowset-add.svg';
import AddMultipleLineHovIcon from '../../assets/icons/primary/WithHovIcon/windowset-add-hov.svg';
import ShowProposalName from './ShowProposalName';
import CancelIcon from '../../assets/icons/secondary/2nd-cancel.svg';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import RedDrawerCancelIcon from '../../assets/icons/drawer-alert/red-cancel.svg';
import RedExclamationIcon from '../../assets/icons/drawer-alert/red-exclamation.svg';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

let checkedrow = [];

export default function EditProposalDetails() {
  const token = sessionStorage.getItem('token');
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  let coveringdetails;
  let selectedJsonData;
  let selectedwindows;
  let commonData;

  if (!location.state) {
    navigate('/pagenotfound');
  } else {
    coveringdetails = location.state.coveringdetails;
    selectedJsonData = location.state.jsonData;
    selectedwindows = location.state.jsonData;
    commonData = location.state.data;
  }

  const vertical = 'top';
  const horizontal = 'center';

  const [proposalName, setProposalName] = useState([]);
  const [windows, setWindows] = useState([]);
  const [coveringsApplied, setCoveringsAppliedWindows] = useState([]);
  const [WindowsSelected, setWindowsSelected] = useState([]);

  // Error Handling
  const [CoveringProposalDetailsErrorAlert, setCoveringProposalDetailsErrorAlert] = useState(false);
  const [CoveringProposalDetailsErrorMsg, setCoveringProposalDetailsErrorMsg] = useState('');

  //  hover states1
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);

  const handleHoverFun = () => setIsHovered(true);

  const handleMouseLeaveFun = () => setIsHovered(false);

  const handleHoverFun2 = () => setIsHovered2(true);

  const handleMouseLeaveFun2 = () => setIsHovered2(false);

  const handleHoverFun3 = () => setIsHovered3(true);

  const handleMouseLeaveFun3 = () => setIsHovered3(false);

  const handleHoverFun4 = () => setIsHovered4(true);

  const handleMouseLeaveFun4 = () => setIsHovered4(false);

  let proposaldetails = {
    customerdata: location.state.customerdata,
    proposal_id: location.state.proposalID,
    proposal_name: location.state.proposalName,
  };

  // To get window details
  const getProposalCoveringDetails = async () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: token,
      },
    };
    const response = await fetch(CONFIG.API_URL + `/pm/CoveringProposalDetails/${location.state.proposalID}`, requestOptions);
    const data = await response.json();
    if (data.error) {
      setCoveringProposalDetailsErrorMsg(data.error_message);
      return setCoveringProposalDetailsErrorAlert(true);
    }
    setCoveringsAppliedWindows(data.covering_proposal_details.filter((i) => i.covering_status !== 'not_consider'));
    setWindows(data.covering_proposal_details);
  };

  useEffect(() => {
    getVerified();
    window.scrollTo(0, 0);
    if (location.state) {
      setProposalName(location.state.proposalName);
      if (location.state.selectedwindows.length > 0) {
        checkedrow = location.state.selectedwindows.filter((element) => element.covering_status !== 'not_consider');
      }
      getProposalCoveringDetails();
    }
  }, []);

  const getTabledata = (tabledata) => {
    setWindowsSelected(tabledata);
  };

  const getVerified = async () => {
    const response = await fetch(`${CONFIG.API_URL}/am/Verify`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: token,
      },
    });
    const data = await response.json();
    if (!response.ok || data.error) {
      if (data.error.message === 'Token is not valid') {
        navigate(`/login`, {
          state: { error: 'Session Timeout! Please Relogin to Continue' },
        });
      }
    }
  };

  let selectedwindowsIDs = coveringsApplied;
  let windowsetdata = location.state.customerdata;
  let result = [];

  const navigateReorderPage = () =>
    navigate(`/tbm/ps/reordermode/${params.id}`, {
      state: {
        data: commonData,
        selectedwindows: checkedrow,
        customerdata: windowsetdata,
        totalwindows: location.state.totalwindows,
        proposalID: location.state.proposalID,
        proposalName: location.state.proposalName,
      },
    });

  const navigateProposalDetailsPage = () =>
    navigate(`/tbm/ps/proposal/${params.id}/details`, {
      state: {
        data: commonData,
        windowsselectednotnull: commonData.AppliedCoveringsWindows,
        selectedwindows: selectedwindowsIDs,
        customerdata: windowsetdata,
        unchecked: result,
        proposalID: location.state.proposalID,
        proposalName: location.state.proposalName,
        redirectwindowdetailspage: true,
        totalwindows: location.state.totalwindows,
        coveringdetails: coveringdetails,
      },
    });

  const navigateDeletePage = () =>
    navigate(`/tbm/ps/deletemode/${params.id}`, {
      state: {
        data: commonData,
        selectedwindows: selectedwindowsIDs,
        customerdata: windowsetdata,
        unchecked: result,
        path: 'delete',
        proposalID: location.state.proposalID,
        proposalName: location.state.proposalName,
        coveringdetails: coveringdetails,
      },
    });

  const navigatetoBatchEditProposalPage = () =>
    navigate(`/tbm/ps/proposal-batchedit/${params.id}`, {
      state: {
        data: commonData,
        selectedwindows: selectedwindowsIDs,
        customerdata: windowsetdata,
        unchecked: result,
        path: 'batch-edit',
        proposalID: location.state.proposalID,
        proposalName: location.state.proposalName,
        coveringdetails: coveringdetails,
      },
    });

  const navigateWindowDetailsPage = () =>
    navigate(`/tbm/ps/editingwsproposal/${params.id}`, {
      state: {
        data: location.state.data,
        selectedwindows: selectedwindowsIDs,
        customerdata: windowsetdata,
        totalwindows: location.state.totalwindows,
        proposalID: location.state.proposalID,
        proposalName: location.state.proposalName,
        coveringdetails: coveringdetails,
      },
    });

  const [isImageHovered2, setIsImageHovered2] = useState(false);
  const handleHover2 = () => setIsImageHovered2(!isImageHovered2);

  const [isImageHovered3, setIsImageHovered3] = useState(false);
  const handleHover3 = () => setIsImageHovered3(!isImageHovered3);

  const [isImageHovered4, setIsImageHovered4] = useState(false);
  const handleHover4 = () => setIsImageHovered4(!isImageHovered4);

  const [isImageHovered5, setIsImageHovered5] = useState(false);
  const handleHover5 = () => setIsImageHovered5(!isImageHovered5);

  const ProposalTablefetchdata = (Error) => {
    if (Error === 'Servererror: invalid input syntax for type uuid: "undefined"') {
      navigate('/pagenotfound');
    }
  };

  return (
    <>
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar className='snackbar_bar_postition' open={CoveringProposalDetailsErrorAlert} anchorOrigin={{ vertical, horizontal }} onClose={() => setCoveringProposalDetailsErrorAlert(false)}>
          <Alert className='Error1 mainAlert' variant='filled' icon={false}>
            <div className='alertCancel_2'>
              <img
                onClick={() => {
                  setCoveringProposalDetailsErrorAlert(false);
                }}
                src={RedDrawerCancelIcon}
                alt='CANCELICONCPDA'
                className='drawer_secondary_icons_dimension drawer_cancel_cursor'
              />
            </div>
            <div className='alertMain_2_4 confirmIconStyle1'>
              <img src={RedExclamationIcon} alt='CONFIRMICONCPDA' className='primary_icons_dimension alertImageMargin' />
              <div className='alertTextAlign alertLeftPadding alertSpacing alertSpacing1'>{CoveringProposalDetailsErrorMsg}</div>
            </div>
          </Alert>
        </Snackbar>
      </Stack>
      <ShowProposalName proposaldetails={proposaldetails} />
      <hr className='button_top_space' />

      <ProposalTable func={ProposalTablefetchdata} windows={windows} disabled={true} commonData={commonData} tabledata={getTabledata} />

      <hr className='button_top_space' />

      <Box display='flex' alignItems='center' justifyContent='center' className='add_Window_Button_margin_Bottom'>
        <span className='button_span button_top_space' onMouseEnter={handleHoverFun} onMouseLeave={handleMouseLeaveFun}>
          <Box onClick={navigatetoBatchEditProposalPage} className='globalIconMainBox globalMarginLeftAddWindow'>
            {isHovered ? <img alt='EDITMULTIPLELINESH' className='primary_icons_dimension_1' src={AddMultipleLineHovIcon} /> : <img alt='EDITMULTIPLELINES' className='primary_icons_dimension_1' src={AddMultipleLineIcon2} />}
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText'>
              EDIT MULTIPLE LINES
            </Typography>
          </Box>
        </span>
      </Box>

      <Box display='flex' alignItems='center' justifyContent='center' className='add_Window_Button_margin_Bottom'>
        <span onClick={() => navigateWindowDetailsPage()} className='button_span button_top_space' onMouseEnter={handleHoverFun2} onMouseLeave={handleMouseLeaveFun2}>
          <Box className='globalIconMainBox globalMarginLeftAddWindow'>
            {isHovered2 ? <img alt='ADDNEWWINDOWH' className='primary_icons_dimension_1' src={AddWindowHovIcon} /> : <img alt='ADDNEWWINDOW' className='primary_icons_dimension_1' src={AddWindowIcon2} />}
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover2} onMouseLeave={handleHover2}>
              ADD NEW WINDOW
            </Typography>
          </Box>
        </span>
      </Box>

      <Box display='flex' alignItems='center' justifyContent='center' className='add_Window_Button_margin_Bottom'>
        <span className='button_span button_top_space' onMouseEnter={handleHoverFun3} onMouseLeave={handleMouseLeaveFun3}>
          <Box onClick={navigateDeletePage} className='globalIconMainBox globalMarginLeftAddWindow'>
            {isHovered3 ? <img alt='REMOVEWINDOWSH' className='primary_icons_dimension_1' src={DeleteWindowHovIcon} /> : <img alt='REMOVEWINDOWS' className='primary_icons_dimension_1' src={DeleteWindowIcon} />}
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover3} onMouseLeave={handleHover3}>
              REMOVE WINDOWS
            </Typography>
          </Box>
        </span>
      </Box>

      <Box display='flex' alignItems='center' justifyContent='center' className='add_Window_Button_margin_Bottom'>
        <span className='button_span button_top_space button_bottom_space' onMouseEnter={handleHoverFun4} onMouseLeave={handleMouseLeaveFun4}>
          <Box onClick={navigateReorderPage} className='globalIconMainBox globalMarginLeftAddWindow'>
            {isHovered4 ? <img alt='REORDERWINDOWSH' className='primary_icons_dimension_1' src={ReorderWindowHovIcon} /> : <img alt='REORDERWINDOWS' className='primary_icons_dimension_1' src={ReorderWindowIcon} />}
            <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover4} onMouseLeave={handleHover4}>
              REORDER WINDOWS
            </Typography>
          </Box>
        </span>
      </Box>
      <hr />

      <Box sx={{ marginLeft: '15px' }}>
        <Box display='flex' alignItems='center' justifyContent='center' sx={{ marginLeft: '0px' }} className='add_Window_Button_margin_Bottom'>
          <span onClick={navigateProposalDetailsPage} style={{ paddingBottom: '30px' }} className='gray_button_span button_top_space'>
            <Box className='globalIconMainBox'>
              <img src={CancelIcon} alt='CANCEL' className={`secondary_icons_dimension ${isImageHovered5 ? 'hovered' : ''}`} />
              <Typography sx={{ fontFamily: 'RobotoLight' }} className='globalText' onMouseEnter={handleHover5} onMouseLeave={handleHover5}>
                CANCEL
              </Typography>
            </Box>
          </span>
        </Box>
      </Box>
    </>
  );
}
